import { Actions } from "../commission/commissionConstants";
import produce from "immer";

const initialState = {
  data: [],
  loading: false,
  selected: null,
  selectedLoading: false,
  operationResult: null,
  pCode1: { list: [], loading: false },
  pCode2: { list: [], loading: false },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.LIST_COMMISSION_RATES:
      case Actions.DELETE_COMMISSION_RATE:
        draft.loading = true;
        return draft;
      case Actions.LIST_COMMISSION_RATES_SUCCEEDED:
        draft.loading = false;
        draft.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.DELETE_COMMISSION_RATE_SUCCEEDED:
        draft.loading = false;

        if (action.payload.Operation.Outcome === "Success") {
          draft.data = action.payload.Data;
        } else {
          draft.operationResult = action.payload.Operation;
        }

        return draft;
      case Actions.CREATE_COMMISSION_RATE:
      case Actions.READ_COMMISSION_RATE:
      case Actions.UPDATE_COMMISSION_RATE:
        draft.selectedLoading = true;
        return draft;
      case Actions.READ_COMMISSION_RATE_SUCCEEDED:
        draft.selected = action.payload.Data;
        action.payload.Operation.Outcome !== "Success"
          ? action.payload.Operation
          : null;
        draft.selectedLoading = false;
        return draft;
      case Actions.CREATE_COMMISSION_RATE_SUCCEEDED:
      case Actions.UPDATE_COMMISSION_RATE_SUCCEEDED:
        draft.selectedLoading = false;
        draft.operationResult = action.payload.Operation;
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        }
        return draft;
      case Actions.CLEAR_SELECTED_COMMISSION_RATE:
        draft.operationResult = null;
        draft.selected = null;
        draft.selectedLoading = false;
        return draft;
      case Actions.LIST_PRODUCER_1_CODES:
        draft.pCode1.loading = true;
        return draft;
      case Actions.LIST_PRODUCER_2_CODES:
        draft.pCode2.loading = true;
        return draft;
      case Actions.LIST_PRODUCER_1_CODES_SUCCEEDED:
        draft.pCode1.loading = false;
        draft.pCode1.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.LIST_PRODUCER_2_CODES_SUCCEEDED:
        draft.pCode2.loading = false;
        draft.pCode2.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;

      default:
        return state;
    }
  });
};
