import { Button, Result, Typography } from "antd";

import React, { Component } from "react";
import { useLocation, useNavigate } from "react-router";
import { logError } from "../../store/ui/uiActions";
import { useSelector, useDispatch } from "react-redux";

function sendMail(to, subject, body) {
  window.location.href = `mailto:${to}?subject=${encodeURIComponent(
    subject
  )}&body=${body}`;
}

function cutEncodedString(str) {
  var encodedString = encodeURIComponent(str);
  var encodedLength = encodedString.length;
  if (encodedLength <= 1000) {
    return encodedString;
  }

  var cutPoint = 1000;
  while (cutPoint > 0 && encodedString[cutPoint - 1] !== "%") {
    cutPoint--;
  }

  return encodedString.substring(0, cutPoint - 1) + "[...]";
}

const ErrorBoundary = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    navigate("/home");
  };
  const userKey = useSelector((state) => state.actor.details.data.UserId);

  return (
    <MemoizedErrorBoundaryBody
      location={location.pathname}
      handleClick={handleClick}
      userKey={userKey}
    >
      {props.children}
    </MemoizedErrorBoundaryBody>
  );
};

class ErrorBoundaryBody extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    logError({
      Page: this.props.location,
      StackTrace: this.state.error.stack,
      UserKey: this.props.userKey,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.state.hasError === true
    ) {
      this.setState({ hasError: false, error: null, errorInfo: null });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title="Something went wrong"
          subTitle={
            <div>
              An error has been encountered while rendering this page.
              <Typography.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  const errorStack = this.state.error.stack;

                  const body = cutEncodedString(
                    `Hi,\n[Your message here]\n\n` + errorStack
                  );

                  sendMail(
                    "support@brokerlinksoftware.com",
                    "AutolinkPlus Crash Report",
                    body
                  );
                }}
              >
                {" "}
                Contact technical support
              </Typography.Link>
            </div>
          }
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={this.props.handleClick}
            >
              Navigate to the Home Page
            </Button>,
          ]}
        >
          {process.env.REACT_APP_ENV == "development" && (
            <div className="desc">
              <Typography.Paragraph>
                <Typography.Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  Error stack trace:
                </Typography.Text>
              </Typography.Paragraph>
              <Typography.Paragraph>
                <pre>{this.state.error.stack}</pre>
              </Typography.Paragraph>
            </div>
          )}
        </Result>
      );
    }
    return this.props.children;
  }
}

const MemoizedErrorBoundaryBody = React.memo(
  ErrorBoundaryBody,
  (prevProps, nextProps) => prevProps.location === nextProps.location
);

export default ErrorBoundary;
