import * as yup from "yup";
import {
  requiredMessage,
  dateRangeRequiredMessage,
} from "../../../components/shared/form/formValidatonRules";
import intentoryLogReportSelectSeriesType from "./intentoryLogReportSelectSeriesType";

export const inventoryAuditReportSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  TransactionDate: yup.array().required(dateRangeRequiredMessage).nullable(),
});

export const onHandInventoryReportSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
});

export const inventoryLogReportSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  TransactionDate: yup
    .array()
    .nullable()
    .when("SelectSeriesOption", {
      is: intentoryLogReportSelectSeriesType.SeriesActivityDates,
      then: yup.array().required(dateRangeRequiredMessage).nullable(),
    }),

  SelectedPlates: yup
    .string()
    .nullable()
    .when("SelectSeriesOption", {
      is: intentoryLogReportSelectSeriesType.Plates,
      then: yup.string().required(requiredMessage).nullable(),
    }),
});

export const roadAgentDailyStockSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  AllocatedToAgentKey: yup.number().required(requiredMessage),
  TransactionDate: yup
    .array()
    .nullable()
    .when("IncludeSalesLog", {
      is: true,
      then: yup.array().required(requiredMessage).nullable(),
    }),
  IncludeSignOutInSheet: yup
    .boolean()
    .test("atLeastOneSelected", "", function (value) {
      const { IncludeSalesLog, IncludeLogSheet } = this.parent;
      return IncludeSalesLog || IncludeLogSheet || value;
    }),
  IncludeLogSheet: yup.boolean(),
  IncludeSalesLog: yup.boolean(),
});

export const inventoryMovementSchema = yup.object().shape({
  IncludeLogSheet: yup.boolean().required(requiredMessage),
  NewPageForEachSeries: yup.boolean().required(requiredMessage),
});
