import { AutoComplete, Form, Input } from "antd";
import React from "react";

import { useController } from "react-hook-form";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";
import { isEmpty } from "lodash";

const CustomAutoComplete = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = React.memo(
  (props) => {
    const { field, error } = props;

    var inputProps = { ...props };
    delete inputProps.control;
    delete inputProps.formLabel;
    delete inputProps.layout;
    delete inputProps.required;
    delete inputProps.isTouched;
    delete inputProps.isDirty;
    delete inputProps.error;
    delete inputProps.field;
    delete inputProps.linkedValue;
    delete inputProps.formTooltip;
    delete inputProps.formStyle;
    delete inputProps.onChange;
    delete inputProps.onBlur;
    delete inputProps.afterChange;

    return (
      <Form.Item
        {...props.layout}
        label={props.formLabel}
        name={props.name}
        validateStatus={error != null && !props.disabled ? "error" : ""}
        help={error != null && !props.disabled ? error.message : null}
        required={props.required}
        tooltip={props.formTooltip}
        style={props.formStyle}
      >
        <div>
          <AutoComplete
            {...field}
            {...inputProps}
            placeholder={
              inputProps.loading
                ? "Items loading..."
                : isEmpty(inputProps.options)
                ? "No items available"
                : "Click to select an item"
            }
            disabled={inputProps.loading}
            onBlur={() => {
              if (props.onBlur != null) {
                props.onBlur();
              }
              field.onBlur();
            }}
            onChange={(v, o) => {
              if (props.onChange != null) {
                props.onChange(v);
              }
              field.onChange(v, o);
              if (props.afterChange != null) {
                props.afterChange(v);
              }
            }}
            virtual={false}
          />
        </div>
      </Form.Item>
    );
  },
  (p, n) =>
    checkHookFormFieldPropsEquality(p, n) &&
    p.options === n.options &&
    p.loading === n.loading
);
export default CustomAutoComplete;
