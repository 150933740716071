import * as yup from "yup";
import {
  requiredMessage,
  alphanumericRegex,
  alphanumericMessage,
  minMessage,
  maxMessage,
  numericRegex,
  numericMessage,
  dateRangeRequiredMessage,
} from "../../shared/form/formValidatonRules";

export const searchBatchesSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  ICBCBatch: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(7, minMessage)
    .max(7, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  AgencyBatch: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(1, minMessage)
    .max(4, maxMessage)
    .matches(numericRegex, numericMessage),
  SubmittedDate: yup.array().required(dateRangeRequiredMessage).nullable(),
});

export const createBatchStep1Schema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  SubmissionDate: yup.string().required(requiredMessage).nullable(),
  BatchType: yup.string().required(requiredMessage),
});

export const createBatchStep2Schema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  FromCreatedDate: yup.string().required(requiredMessage).nullable(),
  ToCreatedDate: yup.string().required(requiredMessage).nullable(),
  ICBCBatch: yup
    .string()
    .required(requiredMessage)
    .min(7, minMessage)
    .max(7, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  AgencyBatch: yup
    .string()
    .required(requiredMessage)
    .min(1, minMessage)
    .max(4, maxMessage)
    .matches(numericRegex, numericMessage),
});

export const updateBatchSchema = yup.object().shape({
  DateSubmitted: yup.string().required(requiredMessage).nullable(),
  ICBCBatchNo: yup
    .string()
    .required(requiredMessage)
    .min(7, minMessage)
    .max(7, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  AgencyBatchNo: yup
    .string()
    .required(requiredMessage)
    .min(1, minMessage)
    .max(4, maxMessage)
    .matches(numericRegex, numericMessage),
});
