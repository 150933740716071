import { customGet } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { renewalListReportTypes } from "../../components/retention/reportParameters";
import {
  findCurrentAgencies,
  findProducerNames,
} from "../report/reportActions";
import { Actions } from "./retentionConstants";

export const transformTextSummaryInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const Producer1Names = findProducerNames(state, 1, values.ProducerCode1Keys);
  const Producer2Names = findProducerNames(state, 2, values.ProducerCode2Keys);
  const expiryDates = [values.ExpiryDate[0], values.ExpiryDate[1]];
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FileName: `TextSummary_${agency.Number}`,
    ExpiryDate: expiryDates,
    FirstProducer: Producer1Names,
    SecondProducer: Producer2Names,
  };
};

export const transformBcarRetentionInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const Producer1Names = findProducerNames(state, 1, values.ProducerCode1Keys);
  const Producer2Names = findProducerNames(state, 2, values.ProducerCode2Keys);
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FileName: `BCautorenewRetention_${agency.Number}`,
    FirstProducer: Producer1Names,
    SecondProducer: Producer2Names,
  };
};

export const transformRetentionSummaryInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const Producer1Names = findProducerNames(state, 1, values.ProducerCode1Keys);
  const Producer2Names = findProducerNames(state, 2, values.ProducerCode2Keys);

  // Format policy status
  let policyStatus = "";
  if (values.PolicyStatusActive === true) {
    policyStatus = "A";
  }
  if (values.PolicyStatusExpired === true) {
    policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "E";
  }
  if (values.PolicyStatusCancelled === true) {
    policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "C";
  }
  if (values.PolicyStatusRenewed === true) {
    policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "R";
  }
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FileName: `RetentionSummary_${agency.Number}`,
    FirstProducer: Producer1Names,
    SecondProducer: Producer2Names,
    PolicyStatus: policyStatus,
  };
};

export const transformRenewalListInput =
  (values, vehicleTypes, policyProducts) => (state) => {
    var VehicleTypesDescriptionString = "";
    var PolicyProductsDescriptionString = "";

    if (vehicleTypes != null && policyProducts != null) {
      for (var i = 0; i < values.VehicleTypes.length; i++) {
        VehicleTypesDescriptionString +=
          vehicleTypes.find((v) => v.value === values.VehicleTypes[i]).label +
          (i < values.VehicleTypes.length - 1 ? "," : "");
      }

      for (var i = 0; i < values.ProductKeys.length; i++) {
        PolicyProductsDescriptionString +=
          policyProducts.find((v) => v.value === values.ProductKeys[i]).label +
          (i < values.ProductKeys.length - 1 ? "," : "");
      }
    }

    const agency = findCurrentAgencies(state, values.AgencyKeys);
    const Producer1Names = findProducerNames(
      state,
      1,
      values.ProducerCode1Keys
    );
    const Producer2Names = findProducerNames(
      state,
      2,
      values.ProducerCode2Keys
    );
    const reportName =
      values.ReportType === renewalListReportTypes.STATUS
        ? "RenewalStatus"
        : values.ReportType === renewalListReportTypes.MAILING_LABELS
        ? "MailingLabels"
        : `RenewalList_${values.ReportType}`;
    var VehicleTypesAsInt =
      values?.VehicleTypes?.length >= 1 ? values.VehicleTypes.map(Number) : [];

    // Format policy status
    let policyStatus = "";
    let policyStatusString = "";
    if (values.PolicyStatusActive === true) {
      policyStatus = "A";
      policyStatusString = "Active";
    }
    if (values.PolicyStatusExpired === true) {
      policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "E";
      policyStatusString =
        policyStatusString +
        (policyStatusString.length > 0 ? "," : "") +
        "Expired";
    }
    if (values.PolicyStatusCancelled === true) {
      policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "C";
      policyStatusString =
        policyStatusString +
        (policyStatusString.length > 0 ? "," : "") +
        "Cancelled";
    }
    if (values.PolicyStatusRenewed === true) {
      policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "R";
      policyStatusString =
        policyStatusString +
        (policyStatusString.length > 0 ? "," : "") +
        "Renewed";
    }

    return {
      ...values,
      AgencyNameAndNumber: agency.FullName,
      FileName: `${reportName}_${agency.Number}`,
      FirstProducer: Producer1Names,
      SecondProducer: Producer2Names,
      PolicyProductsDescriptionString: PolicyProductsDescriptionString,
      VehicleTypesDescriptionString: VehicleTypesDescriptionString,
      VehicleTypes: VehicleTypesAsInt,
      VehicleYearFrom: values.VehicleYearFrom ?? 0,
      VehicleYearTo: values.VehicleYearTo ?? 0,
      PolicyStatus: policyStatus.length > 0 ? policyStatus : "A",
      PolicyStatusString:
        policyStatusString.length > 0 ? policyStatusString : "Active",
    };
  };

export const transformRenewalLettersInput =
  (values, uploadName, isPreviewMode) => (state) => {
    console.log("transformRenewalLettersInput", values);
    const agency = findCurrentAgencies(state, values.AgencyKeys);
    const Producer1Names = findProducerNames(
      state,
      1,
      values.ProducerCode1Keys
    );
    const Producer2Names = findProducerNames(
      state,
      2,
      values.ProducerCode2Keys
    );
    const originalFileName =
      state?.ui?.uploads?.find((u) => u.name === uploadName)?.fileName ?? "";

    // Format policy status
    let policyStatus = "";
    if (values.PolicyStatusActive === true) {
      policyStatus = "A";
    }
    if (values.PolicyStatusExpired === true) {
      policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "E";
    }
    if (values.PolicyStatusCancelled === true) {
      policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "C";
    }
    if (values.PolicyStatusRenewed === true) {
      policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "R";
    }
    return {
      ...values,
      AgencyNameAndNumber: agency.FullName,
      FirstProducer: Producer1Names,
      SecondProducer: Producer2Names,
      FileName: originalFileName,
      IsPreviewMode: isPreviewMode,
      VehicleYearFrom: values.VehicleYearFrom ?? 0,
      VehicleYearTo: values.VehicleYearTo ?? 0,
      PolicyStatus: policyStatus.length > 0 ? policyStatus : "A",
    };
  };

export const listLabelFormats = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_LABEL_FORMATS });

  const payload = await customGet(entityType.RETENTION, "GetLabelFormatList");

  dispatch({ type: Actions.LIST_LABEL_FORMATS_SUCCEEDED, payload });
};

export const listPolicyProducts = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_POLICY_PRODUCTS });
  const payload = await customGet(entityType.PRODUCT, genericActionType.LIST);
  dispatch({ type: Actions.LIST_POLICY_PRODUCTS_SUCCEEDED, payload });
};
