export const securityRolesFilterFunction = (value, record) => {
  if (value === " " || value === "-" || value === "--") {
    return record.Roles == null || record.Roles.length === 0;
  }
  if (record.Roles == null || record.Roles.length === 0) {
    return false;
  }
  if (record.Roles.length === 1) {
    return record.Roles[0].toLowerCase().includes(value.toLowerCase());
  }
  if (
    record.Roles.length > 1 &&
    `${record.Roles.length} Roles`.toLowerCase().includes(value.toLowerCase())
  ) {
    return true;
  }
  return record.Roles.some((role) =>
    role.toLowerCase().includes(value.toLowerCase())
  );
};
