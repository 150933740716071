export const Actions = {
  CLEAR_ONLINE_RENEWALS: "CLEAR_ONLINE_RENEWALS",
  LIST_ONLINE_RENEWALS: "LIST_ONLINE_RENEWALS",
  LIST_ONLINE_RENEWALS_SUCCEEDED: "LIST_ONLINE_RENEWALS_SUCCEEDED",
  SET_SELECTED_ROWS: "SET_SELECTED_ROWS",
  CLEAR_SELECTED_ROWS: "CLEAR_SELECTED_ROWS",
  BULK_UPDATE_CODES: "BULK_UPDATE_CODES",
  BULK_UPDATE_CODES_SUCCEEDED: "BULK_UPDATE_CODES_SUCCEEDED",
  CLEAR_BULK_UPDATE_DATA: "CLEAR_BULK_UPDATE_DATA",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT"
};
