import dayjs from "dayjs";
import * as yup from "yup";
import {
  alphanumericMessage,
  alphanumericRegex,
  dateRangeRequiredMessage,
  lessOrEqualOneYearMessage,
  maxMessage,
  requiredMessage,
} from "../../../components/shared/form/formValidatonRules";

export const manageRenewalsSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  ExpiryDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  ShowAutoplanPolicies: yup.boolean().required(requiredMessage),
  ShowSpecialPolicies: yup.boolean().required(requiredMessage),
  PolicyStatusActive: yup.boolean().required(requiredMessage),
  PolicyStatusExpired: yup.boolean().required(requiredMessage),
  PolicyStatusCancelled: yup.boolean().required(requiredMessage),
  PolicyStatusRenewed: yup.boolean().required(requiredMessage),
  PolicyStatus: yup
    .boolean()
    .when(
      [
        "PolicyStatusActive",
        "PolicyStatusExpired",
        "PolicyStatusCancelled",
        "PolicyStatusRenewed",
      ],
      {
        is: false,
        then: yup
          .boolean()
          .required("At least one of the options is required."),
      }
    ),
  Show: yup.boolean().when(["ShowAutoplanPolicies", "ShowSpecialPolicies"], {
    is: false,
    then: yup.boolean().required("At least one of the options is required."),
  }),
});

export const manageRenewalsBulkUpdateSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  UpdatePC1: yup.boolean().required(),
  UpdatePC2: yup.boolean().required(),
});
