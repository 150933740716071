import { Badge, Form, Radio, Select, Space } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  listPendingBrokerShipmentsAction,
  listPendingICBCShipmentsAction,
  setSelectedPendingMovementKeyAction,
} from "../../../store/inventory/inventoryActions";
import { checkHookFormFieldPropsEquality } from "../../shared/form/formValidatonRules";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import { formatDate } from "../../shared/formatting";
import {
  newICBCShipment,
  pendingBrokerShipment,
  pendingICBCShipment,
} from "./receiveOptions";

const PendingShipments = ({
  agencyKey,
  inventoryMovementOption,
  layout,
  control,
  setValue,
}) => {
  //
  const dispatch = useDispatch();

  //
  const [pendingBrokerShipmentOptions, setPendingBrokerShipmentOptions] =
    useState(null);
  const [pendingICBCShipmentOptions, setPendingICBCShipmentOptions] =
    useState(null);

  //
  const pendingICBCShipments = useSelector(
    (state) => state.inventory.moveInventory.newMovement.pendingICBCShipments
  );
  const pendingBrokerShipments = useSelector(
    (state) => state.inventory.moveInventory.newMovement.pendingBrokerShipments
  );

  //
  useEffect(() => {
    if (agencyKey != null && agencyKey != "") {
      setPendingICBCShipmentOptions(null);
      setPendingBrokerShipmentOptions(null);
      dispatch(listPendingICBCShipmentsAction(agencyKey));
      dispatch(listPendingBrokerShipmentsAction(agencyKey));
    }
  }, [agencyKey]);

  useEffect(() => {
    if (pendingICBCShipments != null) {
      var pendingOptions = pendingICBCShipments.map((m) => {
        return {
          value: m.InventoryMovementKey,
          label: `Draft ICBC Shipment: ${
            isEmpty(m.StockCentreConfNo)
              ? "Changed by "
              : `Stock Confirmation \'${m.StockCentreConfNo}\' changed by `
          }\'${m.ChangedByUserName}\' on \'${formatDate(
            m.TransactionDate
          )}\' (${m.Quantity} items)`,
        };
      });
      setPendingICBCShipmentOptions(pendingOptions);
    } else {
      setPendingICBCShipmentOptions(null);
    }
  }, [pendingICBCShipments]);

  useEffect(() => {
    if (pendingBrokerShipments != null) {
      var pendingOptions = pendingBrokerShipments.map((m) => {
        return {
          value: m.InventoryMovementKey,
          label: `Broker Shipment: From \'${
            m.ChangedByUserName
          }\' at Agency \'${m.TransferredToAgencyNumber}\' on \'${formatDate(
            m.TransactionDate
          )}\' (${m.Quantity} item(s))`,
        };
      });
      setPendingBrokerShipmentOptions(pendingOptions);
    } else {
      setPendingBrokerShipmentOptions(null);
    }
  }, [pendingBrokerShipments]);

  useEffect(() => {
    if (
      pendingBrokerShipmentOptions != null &&
      pendingBrokerShipmentOptions.length > 0
    ) {
      setValue("InventoryMovementOption", pendingBrokerShipment.value);
      const firstValue = pendingBrokerShipmentOptions[0].value;
      setValue("PendingBrokerShipmentKey", firstValue);
      dispatch(setSelectedPendingMovementKeyAction(firstValue));
    } else if (
      pendingICBCShipmentOptions != null &&
      pendingICBCShipmentOptions.length > 0
    ) {
      setValue("InventoryMovementOption", pendingICBCShipment.value);
      const firstValue = pendingICBCShipmentOptions[0].value;
      setValue("PendingICBCShipmentKey", firstValue);
      dispatch(setSelectedPendingMovementKeyAction(firstValue));
    }
  }, [pendingBrokerShipmentOptions, pendingICBCShipmentOptions, dispatch]);

  return (
    <CustomRadioGroup
      control={control}
      layout={layout}
      formLabel="Shipment Type"
      required={true}
      name="InventoryMovementOption"
      defaultValue={newICBCShipment.value}
      customField1={pendingICBCShipmentOptions}
      customField2={pendingBrokerShipmentOptions}
    >
      <Space direction="vertical">
        <Radio value={newICBCShipment.value}>{newICBCShipment.label}</Radio>
        <Radio
          value={pendingICBCShipment.value}
          disabled={
            pendingICBCShipmentOptions === null ||
            isEmpty(pendingICBCShipmentOptions)
          }
        >
          <Badge
            count={
              pendingICBCShipmentOptions != null
                ? pendingICBCShipmentOptions.length
                : 0
            }
            offset={[12, 0]}
          >
            <CustomSearchSelect
              control={control}
              name="PendingICBCShipmentKey"
              placeholder={
                pendingICBCShipmentOptions &&
                pendingICBCShipmentOptions.length === 0
                  ? pendingICBCShipment.emptyLabel
                  : `Select ${pendingICBCShipment.label}`
              }
              options={pendingICBCShipmentOptions}
              disabled={
                inventoryMovementOption === newICBCShipment.value ||
                pendingICBCShipmentOptions === null ||
                isEmpty(pendingICBCShipmentOptions)
              }
              formStyle={{
                marginBottom: 0,
              }}
              allowClear={true}
              afterChange={(value) => {
                dispatch(setSelectedPendingMovementKeyAction(value));
              }}
            />
          </Badge>
        </Radio>
        <Radio
          value={pendingBrokerShipment.value}
          disabled={
            pendingBrokerShipmentOptions === null ||
            isEmpty(pendingBrokerShipmentOptions)
          }
        >
          <Badge
            count={
              pendingBrokerShipmentOptions != null
                ? pendingBrokerShipmentOptions.length
                : 0
            }
            offset={[12, 0]}
          >
            <CustomSearchSelect
              control={control}
              name="PendingBrokerShipmentKey"
              placeholder={
                pendingBrokerShipmentOptions &&
                pendingBrokerShipmentOptions.length === 0
                  ? pendingBrokerShipment.emptyLabel
                  : `Select ${pendingBrokerShipment.label}`
              }
              options={pendingBrokerShipmentOptions}
              disabled={
                inventoryMovementOption === newICBCShipment.value ||
                pendingBrokerShipmentOptions === null ||
                isEmpty(pendingBrokerShipmentOptions)
              }
              formStyle={{
                marginBottom: 0,
              }}
              afterChange={(value) => {
                dispatch(setSelectedPendingMovementKeyAction(value));
              }}
              allowClear={true}
            />
          </Badge>
        </Radio>
      </Space>
    </CustomRadioGroup>
  );
};

const CustomSearchSelect = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = React.memo(
  (props) => {
    const { field, error } = props;

    var selectProps = { ...props };
    delete selectProps.control;
    delete selectProps.formStyle;
    delete selectProps.formClassName;
    delete selectProps.field;
    delete selectProps.isTouched;
    delete selectProps.isDirty;
    delete selectProps.error;
    delete selectProps.afterChange;

    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Form.Item
          name={props.name}
          validateStatus={error != null ? "error" : ""}
          help={error != null && props.disabled == false ? error.message : null}
          style={props.formStyle}
          className="pending-shipment-class"
          // className="attemptds"
        >
          <div>
            <Select
              {...field}
              {...selectProps}
              virtual={false}
              showSearch
              optionFilterProp={"label"}
              filterOption={(input, option) => {
                const label = option.label ? option.label : option.children;
                return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              onChange={(v, o) => {
                field.onChange(v, o);
                if (props.afterChange != null) {
                  props.afterChange(v);
                }
              }}
            />
          </div>
        </Form.Item>
      </span>
    );
  },
  (prevProps, nextProps) =>
    checkHookFormFieldPropsEquality(prevProps, nextProps) &&
    prevProps.options === nextProps.options
);

export default PendingShipments;
