import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";

export const TableActionsDropdown = ({ items }) => {
  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="topLeft"
    >
      <Button size="small" tabIndex="-1" icon={<EllipsisOutlined />}></Button>
    </Dropdown>
  );
};
