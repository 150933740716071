import { isNull } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listTargetAgentsAction } from "../../../store/inventory/inventoryActions";
import SearchSelectEx from "../../shared/form/SearchSelectEx";

const TargetAgentSelect = (props) => {
  const { agencyKey } = props;
  const dispatch = useDispatch();

  const targetAgents = useSelector(
    (state) => state.inventory.moveInventory.newMovement.targetAgents.data
  );
  const targetAgentsLoading = useSelector(
    (state) => state.inventory.moveInventory.newMovement.targetAgents.loading
  );

  useEffect(() => {
    if (!isNull(agencyKey) && agencyKey != "") {
      dispatch(listTargetAgentsAction(agencyKey));
    }
  }, [agencyKey]);

  return (
    <SearchSelectEx
      layout={props.layout}
      formLabel="To Agent"
      required={true}
      control={props.control}
      name="ToAgentUserKey"
      loading={targetAgentsLoading}
      options={targetAgents}
      allowClear={true}
    />
  );
};

export default TargetAgentSelect;
