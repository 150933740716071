import { Actions } from "../retention/retentionConstants";
import produce from "immer";

const initialState = {
  labelFormats: null,
  policyProducts: [],
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_LABEL_FORMATS:
        draft.labelFormats = null;
        return draft;
      case Actions.LIST_LABEL_FORMATS_SUCCEEDED:
        draft.labelFormats = action.payload;
        return draft;
      case Actions.LIST_POLICY_PRODUCTS_SUCCEEDED:
        draft.policyProducts = action.payload;
        return draft;
      default:
        return state;
    }
  });
};
