import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewInventoryMovementAction,
  processInventoryMovementAction,
  setAllocateInventoryCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import AllocatePlatesForm from "../shared/AllocatePlatesForm";
import MoveInventoryItems from "../shared/MoveInventoryItems";
import { allocateInventorySchema } from "../shared/moveInventorySchemas";
import { movementOptions, types } from "../shared/movementTypes";

// Constants
const initialValues = {
  AgencyKey: "",
  InventoryMovementType: "",
  InventoryMovementKey: "",
  FromAgentUserKey: "",
  ToAgentUserKey: "",
  TransactionDate: "",
};

const AllocateInventory = () => {
  // Form
  const {
    handleSubmit,
    reset,
    getValues,
    control,
    trigger,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(allocateInventorySchema),
  });

  const transactionDate = watch("TransactionDate");

  //
  const dispatch = useDispatch();

  //
  const [currentDefaultValues, setCurrentDefaultValues] = useState(null);

  //
  const newMovement = useSelector(
    (state) => state.inventory.moveInventory.newMovement
  );
  const header = newMovement.entity && newMovement.entity.Header;
  const movementType =
    newMovement.entity && newMovement.entity.Header.InventoryMovementType;
  const movementTypeOption = movementOptions.filter(
    (o) => o.value === movementType
  )[0];
  const movementTypeDescription =
    movementTypeOption && movementTypeOption.label;
  const loading = newMovement.loading;
  const movementItems = newMovement.entity && newMovement.entity.Items;
  const selectedTargetAgentKey = newMovement.targetAgents.selected;
  const selectedSourceAgentKey = newMovement.sourceAgents.selected;
  const selectedTargetAgentName =
    selectedTargetAgentKey &&
    newMovement.targetAgents.data.filter(
      (a) => a.value === selectedTargetAgentKey
    )[0].label;
  const selectedSourceAgentName =
    selectedSourceAgentKey &&
    newMovement.sourceAgents.data.filter(
      (a) => a.value === selectedSourceAgentKey
    )[0].label;
  const showMoveAllItemsButton =
    header &&
    header.InventoryMovementType !== types.AllocationFromOfficeToRoadAgent;

  useEffect(() => {
    if (
      header != null &&
      (currentDefaultValues == null || isNull(currentDefaultValues))
    ) {
      setCurrentDefaultValues(header);
      reset({ ...header });
      trigger();
    }
  }, [header]);

  const onSubmitHandler = (values) => {
    dispatch(
      processInventoryMovementAction({
        AgencyKey: header.AgencyKey,
        InventoryMovementType: header.InventoryMovementType,
        InventoryMovementKey: newMovement.entity.Header.InventoryMovementKey,
        FromAgentUserKey: selectedSourceAgentKey,
        ToAgentUserKey: selectedTargetAgentKey,
        TransactionDate: values.TransactionDate,
      })
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" onFinish={() => {}}>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <Row>
              <Col span={10}>
                <Descriptions column={1}>
                  <DescriptionsItem label="Allocation Type">
                    {movementTypeDescription}
                  </DescriptionsItem>
                  {(movementType === types.ReturnFromRoadAgentToOffice ||
                    movementType ===
                      types.AllocationFromRoadAgentToRoadAgent) && (
                    <DescriptionsItem label="From Agent">
                      {selectedSourceAgentName}
                    </DescriptionsItem>
                  )}
                  {(movementType === types.AllocationFromOfficeToRoadAgent ||
                    movementType ===
                      types.AllocationFromRoadAgentToRoadAgent) && (
                    <DescriptionsItem label="To Agent">
                      {selectedTargetAgentName}
                    </DescriptionsItem>
                  )}
                  <DescriptionsItem label="Agency" style={{ padding: 0 }}>
                    {newMovement.entity &&
                      newMovement.entity.Header.AgencyNumberAndName}
                  </DescriptionsItem>
                </Descriptions>
              </Col>
              <Col span={12}>
                <CustomDatePicker
                  formItem={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                    required: true,
                    label: "Transaction Date",
                    style: { marginBottom: 0 },
                  }}
                  control={control}
                  name="TransactionDate"
                />
              </Col>
            </Row>

            <AllocatePlatesForm
              headerValues={{
                ...getValues(),
                FromAgentUserKey: selectedSourceAgentKey,
                ToAgentUserKey: selectedTargetAgentKey,
                TransactionDate: transactionDate,
              }}
            />
            <MoveInventoryItems showMoveAllButton={showMoveAllItemsButton} />
            <Row style={{ paddingTop: 16 }}>
              <Col span={8}>
                <Button
                  danger
                  size="small"
                  onClick={() => {
                    dispatch(clearNewInventoryMovementAction()).then(
                      dispatch(setAllocateInventoryCurrentStepAction(0))
                    );
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col offset={8} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        size="small"
                        onClick={() => {
                          dispatch(setAllocateInventoryCurrentStepAction(0));
                        }}
                      >
                        <LeftCircleOutlined />
                        Previous
                      </Button>

                      <Space>
                        <Popconfirm
                          title="This inventory movement will now be completed. Would you like to proceed?"
                          onConfirm={() => handleSubmit(onSubmitHandler)()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            id="processButton"
                            type="primary"
                            size="small"
                            disabled={
                              !isValid ||
                              (movementItems && movementItems.length === 0)
                            }
                            loading={loading}
                          >
                            Complete
                            <RightCircleOutlined />
                          </Button>
                        </Popconfirm>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AllocateInventory;
