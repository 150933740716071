import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  processInventoryMovementAction,
  setReceiveInventoryCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import MoveInventoryItems from "../shared/MoveInventoryItems";
import { receiveStockFromICBCSchema } from "../shared/moveInventorySchemas";

// Constants
const initialValues = {
  InventoryMovementKey: "",
  TransactionDate: "",
  StockCentreConfNo: "",
};

const ReceiveBrokerShipment = ({ newMovement, movementTypeDescription }) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(receiveStockFromICBCSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentDefaultValues, setCurrentDefaultValues] = useState(null);

  //
  const loading = newMovement.loading;
  const movementItems = newMovement.entity && newMovement.entity.Items;
  const header = newMovement.entity && newMovement.entity.Header;

  useEffect(() => {
    if (
      header != null &&
      (currentDefaultValues == null || isNull(currentDefaultValues))
    ) {
      setCurrentDefaultValues(header);
      reset({ ...header });
      trigger();
    }
  }, [header]);

  const onSubmitHandler = (values) => {
    dispatch(
      processInventoryMovementAction({
        InventoryMovementKey: newMovement.entity.Header.InventoryMovementKey,
        TransactionDate: values.TransactionDate,
        StockCentreConfNo: values.StockCentreConfNo,
      })
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" onFinish={() => {}}>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <Row>
              <Col span={8}>
                <Descriptions column={1}>
                  <DescriptionsItem label="Movement Type">
                    {movementTypeDescription}
                  </DescriptionsItem>
                  <DescriptionsItem label="Agency" style={{ padding: 0 }}>
                    {newMovement.entity &&
                      newMovement.entity.Header.AgencyNumberAndName}
                  </DescriptionsItem>
                </Descriptions>
              </Col>
              <Col span={12}>
                <CustomDatePicker
                  formItem={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                    required: true,
                    label: "Transaction Date",
                  }}
                  control={control}
                  name="TransactionDate"
                />
                <CustomInput
                  control={control}
                  layout={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                  }}
                  formLabel="Stock Center Confirmation"
                  name="StockCentreConfNo"
                  style={{ width: 150 }}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <CustomInputTextArea
                  control={control}
                  layout={{
                    labelCol: { span: 2 },
                    wrapperCol: { span: 22 },
                  }}
                  formLabel="Decline Notes"
                  name="Notes"
                />
              </Col>
            </Row> */}
            <MoveInventoryItems hideRemoveItemColumn={true} />
            <Row style={{ paddingTop: 16 }}>
              <Col span={8}>
                {/* <Button
                  size="small"
                  type="ghost"
                  danger
                  onClick={() => {
                    //saveAsPendingHandler();
                  }}
                  disabled={
                    !isValid || (movementItems && movementItems.length === 0)
                  }
                >
                  [Decline]
                </Button> */}
              </Col>
              <Col offset={8} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        size="small"
                        onClick={() => {
                          dispatch(setReceiveInventoryCurrentStepAction(0));
                        }}
                      >
                        <LeftCircleOutlined />
                        Previous
                      </Button>

                      <Space>
                        <Popconfirm
                          title="This inventory movement will now be completed. Would you like to proceed?"
                          onConfirm={() => handleSubmit(onSubmitHandler)()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            id="processButton"
                            type="primary"
                            size="small"
                            disabled={
                              !isValid ||
                              (movementItems && movementItems.length === 0)
                            }
                            loading={loading}
                          >
                            Complete
                            <RightCircleOutlined />
                          </Button>
                        </Popconfirm>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ReceiveBrokerShipment;
