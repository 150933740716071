import {
  Button,
  Col,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
  ConfigProvider,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tailLayout } from "../shared/form/formLayout";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { SaveOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import SearchSelectEx from "../shared/form/SearchSelectEx";
import AgencySelectEx from "../shared/form/AgencySelectEx";
import {
  listAgenciesCompactForSupport,
  listBrokersCompact,
  clearBrokerListCompact,
  clearListCompactForSupport,
} from "../../store/agency/agencyActions";
import {
  clearSwitchAccountOperationResult,
  switchBroker,
} from "../../store/actor/actorActions";
import * as yup from "yup";
import { agenciesListEqual } from "../shared/sorting";
import { getInitialAgency } from "../shared/form/getInitialAgency";

const switchSupportAccountSchema = yup.object().shape({
  AgencyKey: yup.number().required("Required").nullable(),
  BrokerId: yup.number().required("Required").nullable(),
});

export const SwitchBroker = ({ onCloseHandler }) => {
  const actorDetails = useSelector((state) => state.actor.details.data);
  const defaultValues = {
    AgencyKey: actorDetails?.AgencyKey,
    BrokerId: actorDetails?.BrokerId,
  };

  const defaultComponentConfig = {
    lineWidth: 2,
    colorBorder: "#d5dde6",
    colorTextDisabled: "rgba(0, 0, 0, 0.88)",
  };
  //
  const {
    getValues,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(switchSupportAccountSchema),
  });

  //
  const agencies = useSelector((state) => state.agency.agenciesForSupport.list);
  const agenciesLoading = useSelector(
    (state) => state.agency.agenciesForSupport.loading
  );

  const brokers = useSelector((state) => state.agency.broker.listCompact);
  const brokersLoading = useSelector((state) => state.agency.broker.loading);
  const operationResult = useSelector(
    (state) => state.actor.details.switchSupportAccount.operationResult
  );
  const loading = useSelector(
    (state) => state.actor.details.switchSupportAccount.loading
  );
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);

  const brokerId = watch("BrokerId");

  useEffect(() => {
    if (brokerId != null) {
      //setValue("AgencyKey", "");
      dispatch(listAgenciesCompactForSupport(brokerId));
    }
  }, [brokerId]);

  const [currentAgencies, setCurrentAgencies] = useState([]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesLoading &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);

      var initialAgency = getInitialAgency(homeAgency, agencies);
      setValue("AgencyKey", initialAgency);
    }
  }, [agencies, homeAgency, agenciesLoading]);

  useEffect(() => {
    dispatch(listBrokersCompact());
  }, []);

  //
  const dispatch = useDispatch();

  useEffect(() => {
    if (operationResult && operationResult.Outcome === "Success") {
      dispatch(clearSwitchAccountOperationResult()).then(() =>
        onCloseHandler()
      );
    } else {
      openNotificationOperationResult(operationResult, () =>
        dispatch(clearSwitchAccountOperationResult())
      );
    }
  }, [operationResult]);

  useEffect(() => {
    const cleanup = async () => {
      dispatch(clearListCompactForSupport());
      dispatch(clearBrokerListCompact());
    };
    cleanup();
  }, []);

  const submitHandler = () => {
    dispatch(switchBroker(getValues().AgencyKey, actorDetails.UserId));
  };

  return (
    <Form
      size="small"
      autoComplete="off"
      name="switchaccount"
      onFinish={() => handleSubmit(submitHandler)()}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#78a1cf",
            borderRadius: 8,
          },
          components: {
            Select: defaultComponentConfig,
            Button: {
              colorPrimaryHover: "#a2bfdb",
              colorPrimaryActive: "#597ba8",
            },
          },
        }}
      >
        <SearchSelectEx
          required={true}
          control={control}
          formLabel="Broker"
          name="BrokerId"
          loading={brokersLoading}
          options={brokers}
          allowClear={true}
          autoFocus={true}
        />
        <AgencySelectEx
          required
          formLabel="Agency"
          name="AgencyKey"
          agencies={agencies}
          control={control}
          displayEmptyAsLoading={false}
          loading={agenciesLoading}
          placeholder="No agencies available"
        />
        <Divider style={{ marginBottom: 12, marginTop: 0 }} />

        <Form.Item {...tailLayout} name="buttons">
          <Row>
            <Col span={24}>
              <Space>
                <Button
                  size="small"
                  type="primary"
                  id="saveButton"
                  htmlType="submit"
                  loading={loading}
                  disabled={!isValid || !isDirty}
                >
                  <SaveOutlined />
                  Save
                </Button>

                {isDirty ? (
                  <Popconfirm
                    title="You have unsaved changes. Discard these changes and close?"
                    onConfirm={() => onCloseHandler()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="dashed">Close</Button>
                  </Popconfirm>
                ) : (
                  <Button onClick={() => onCloseHandler()} type="dashed">
                    Close
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </ConfigProvider>
    </Form>
  );
};
