const Actions = {
  LIST_USERS: "LIST_USERS",
  LIST_USERS_SUCCEEDED: "LIST_USERS_SUCCEEDED",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCEEDED: "CREATE_USER_SUCCEEDED",
  RECEIVE_USER: "RECEIVE_USER",
  RESEND_EMAIL_CONFIRMATION: "RESEND_EMAIL_CONFIRMATION",
  RESEND_EMAIL_CONFIRMATION_SUCCEEDED: "RESEND_EMAIL_CONFIRMATION_SUCCEEDED",
  READ_USER: "READ_USER",
  READ_USER_SUCCEEDED: "READ_USER_SUCCEEDED",
  CLEAR_BULK_DELETE_OPERATION_RESULT: "CLEAR_BULK_DELETE_OPERATION_RESULT",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCEEDED: "DELETE_USER_SUCCEEDED",
  UNLOCK_USER: "UNLOCK_USER",
  UNLOCK_USER_SUCCEEDED: "UNLOCK_USER_SUCCEEDED",
  BULK_DELETE_USERS: "BULK_DELETE_USERS",
  BULK_DELETE_USERS_SUCCEEDED: "BULK_DELETE_USERS_SUCCEEDED",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCEEDED: "UPDATE_USER_SUCCEEDED",
  CLEAR_SELECTED_USER: "CLEAR_SELECTED_USER",
  CLEAR_DELETE_OPERATION_RESULTS: "CLEAR_DELETE_OPERATION_RESULTS",
  CLEAR_IDENTITY_OPERATION_RESULT: "CLEAR_IDENTITY_OPERATION_RESULT",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  LIST_LEGACY_USERS: "LIST_LEGACY_USERS",
  LIST_LEGACY_USERS_SUCCEEDED: "LIST_LEGACY_USERS_SUCCEEDED",
  CLEAR_LEGACY_OPERATION_RESULT: "CLEAR_LEGACY_OPERATION_RESULT",
  ACITVATE_LEGACY_USER: "ACITVATE_LEGACY_USER",
  ACITVATE_LEGACY_USER_SUCCEEDED: "ACITVATE_LEGACY_USER_SUCCEEDED",
};
export default Actions;
