import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../shared/BackButton";
import { commissionRateSchema } from "./ratesSchema";
import {
  listProducer1Codes,
  listProducer2Codes,
  clearSelectedCommissionRate,
} from "../../../store/commission/commissionActions";
import ProducerSingleSelect from "../../shared/form/ProducerSingleSelect";
import CustomInputNumber from "../../shared/form/reactHookForm/CustomInputNumber";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import { agenciesListEqual } from "../../shared/sorting";
import { listAgenciesCompactAction } from "../../../store/agency/agencyActions";
import { AdminTasks } from "../../shared/securityRoleTasks";
import { isEmpty } from "lodash";

// Constants
const layout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 11 },
    md: { span: 9 },
    lg: { span: 9 },
    xl: { span: 11 },
    xxl: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 13 },
    md: { span: 15 },
    lg: { span: 15 },
    xl: { span: 13 },
    xxl: { span: 15 },
  },
};

const agencyLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 11 },
    md: { span: 9 },
    lg: { span: 9 },
    xl: { span: 9 },
    xxl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 13 },
    md: { span: 15 },
    lg: { span: 15 },
    xl: { span: 15 },
    xxl: { span: 16 },
  },
};

const CommissionRateForm = ({
  initialValues,
  onSubmitHandler,
  handleParentRefresh,
  isNew = false,
}) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(commissionRateSchema),
  });

  //
  const dispatch = useDispatch();

  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const loading = useSelector((state) => state.commission.selectedLoading);
  const producerCode1Data = useSelector(
    (state) => state.commission?.pCode1?.data
  );
  const producerCode1DataLoading = useSelector(
    (state) => state.commission?.pCode1?.loading
  );

  const producerCode2Data = useSelector(
    (state) => state.commission?.pCode2?.data
  );
  const producerCode2DataLoading = useSelector(
    (state) => state.commission?.pCode2?.loading
  );

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [currentInitialValues, setCurrentInitialValues] = useState(null);

  //
  const agencies = useSelector((state) => state.agency.listCompact);
  const agenciesLoading = useSelector(
    (state) => state.agency.listCompact.loading
  );
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, AdminTasks));
    }
  }, [brokerId]);

  useEffect(() => {
    const elementFocus = isNew ? "ProducerCode1KeySelect" : "RatePercentInput";
    document.getElementById(elementFocus).focus();
  }, [isNew]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);

      var initialAgency = getInitialAgency(homeAgency, agencies);

      const updatedInitialValues = {
        ...initialValues,
        Agency1Key: initialValues.Agency1Key ?? initialAgency,
        Agency2Key: initialValues.Agency2Key ?? initialAgency,
      };
      setCurrentInitialValues(updatedInitialValues);
      reset(updatedInitialValues);

      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    if (currentInitialValues) {
      dispatch(listProducer1Codes(currentInitialValues.Agency1Key));
      dispatch(listProducer2Codes(currentInitialValues.Agency2Key));
    }
  }, [currentInitialValues]);

  const onGoBackHandler = () => {
    dispatch(clearSelectedCommissionRate());
  };

  const onSubmitHandlerEx = (values) => {
    onSubmitHandler(values);
    handleParentRefresh();
    reset(values);
  };

  return (
    <Form
      autoComplete="xyz"
      size="small"
      onFinish={() => handleSubmit(onSubmitHandlerEx)()}
    >
      <Row style={{ paddingBottom: 8 }}>
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <BackButton onGoBackHandler={onGoBackHandler} isDirty={isDirty} />
        </Col>
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <Button
            type="primary"
            loading={loading}
            disabled={!isValid || !isDirty}
            htmlType="submit"
          >
            <SaveOutlined />
            Save
          </Button>
        </Col>
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <Button
            size="small"
            type="dashed"
            disabled={!isDirty || loading}
            onClick={() => {
              reset();
              dispatch(listProducer1Codes(currentInitialValues.Agency1Key));
              dispatch(listProducer2Codes(currentInitialValues.Agency2Key));
              trigger();
            }}
          >
            <UndoOutlined />
            Reset Values
          </Button>
        </Col>
      </Row>
      <Card size="small">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={10}>
            <Form.Item name="ProducerCode1Key" label="Producer 1" {...layout}>
              <ProducerSingleSelect
                name="ProducerCode1Key"
                id="ProducerCode1KeySelect"
                setValue={setValue}
                control={control}
                data={producerCode1Data}
                loading={producerCode1DataLoading}
              />
            </Form.Item>
            <Form.Item name="ProducerCode2Key" label="Producer 2" {...layout}>
              <ProducerSingleSelect
                name="ProducerCode2Key"
                setValue={setValue}
                control={control}
                data={producerCode2Data}
                loading={producerCode2DataLoading}
              />
            </Form.Item>
            <CustomInputNumber
              layout={layout}
              formLabel="Commission Rate %"
              control={control}
              name="RatePercent"
              id="RatePercentInput"
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <AgencySelectEx
              layout={agencyLayout}
              formLabel="Filter Producer 1 codes by"
              name="Agency1Key"
              agencies={currentAgencies}
              isLoading={agenciesLoading || initialValues.AgencyKey === ""}
              control={control}
              onChange={(value) => {
                if (getValues().ProducerCode1Key.AgencyKey !== value) {
                  setValue("ProducerCode1Key", 0);
                }
                dispatch(listProducer1Codes(value));
              }}
            />
            <AgencySelectEx
              layout={agencyLayout}
              formLabel="Filter Producer 2 codes by"
              name="Agency2Key"
              agencies={currentAgencies}
              isLoading={agenciesLoading || initialValues.AgencyKey === ""}
              control={control}
              onChange={(value) => {
                if (getValues().ProducerCode2Key.AgencyKey !== value) {
                  setValue("ProducerCode2Key", 0);
                }
                return dispatch(listProducer2Codes(value));
              }}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default CommissionRateForm;
