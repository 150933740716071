import { PrinterOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import {
  clearOperationResultAction,
  transformLogInput,
} from "../../../store/inventory/inventoryActions";
import {
  clearOperationResult,
  fetchReport,
  resetReportState,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import PdfViewer from "../../shared/PdfViewer";
import ReportParamPanelHeader from "../../shared/ReportParamPanelHeader";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import { toEndOfDay, toStartOfDay } from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { ViewInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import AllocatedToAgentSelect from "./AllocatedToAgentSelect";
import intentoryLogReportSelectSeriesType from "./intentoryLogReportSelectSeriesType";
import { inventoryLogReportSchema } from "./reportSchemas";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKey: "",
  FixedDateRange: fixedDateRanges.MTD,
  AllocatedToAgentKey: 0,
  SelectSeriesOption: intentoryLogReportSelectSeriesType.SeriesActivityDates,
  TransactionDate: ["", ""],
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 },
};

const InventoryLogReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { isValid, isDirty, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(inventoryLogReportSchema),
  });

  const agencyKey = watch("AgencyKey");
  const selectSeriesOption = watch("SelectSeriesOption");

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const isFetching = useSelector((state) => state.report.isFetching);
  const isViewerVisible = useSelector((state) => state.report.isViewerVisible);
  const reportOperationResult = useSelector(
    (state) => state.report.operationResult
  );
  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);

      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKey: initialAgency,
        TransactionDate: [
          toStartOfDay(dayjs().startOf("month")),
          toEndOfDay(dayjs().endOf("day")),
        ],
      };

      setInitialDefaultValues(cloneDeep(fullInitialValues));

      reset(fullInitialValues);
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(reportOperationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [reportOperationResult, dispatch]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformLogInput(values),
        entityType.INVENTORY,
        "PrintLog",
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="InventoryReports">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col span={8}>
                      <AgencySelectEx
                        layout={layout}
                        formLabel="Agency"
                        name="AgencyKey"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={defaultValues.AgencyKey === ""}
                        control={control}
                        onSelect={(_) => {
                          setValue("AllocatedToAgentKey", null);
                          trigger();
                        }}
                      />

                      <AllocatedToAgentSelect
                        control={control}
                        layout={layout}
                        agencyKey={agencyKey}
                        allowClear={true}
                        includeAllOption={true}
                        setValue={setValue}
                      />
                    </Col>
                    <Col span={14}>
                      <CustomRadioGroup
                        control={control}
                        layout={{
                          labelCol: { span: 6 },
                          wrapperCol: { span: 18 },
                        }}
                        formLabel="Select Series based on"
                        name="SelectSeriesOption"
                        required={true}
                        afterChange={() => trigger()}
                        formStyle={{ marginBottom: 0 }}
                      >
                        <Space direction="vertical">
                          <Radio
                            value={
                              intentoryLogReportSelectSeriesType.SeriesActivityDates
                            }
                          >
                            <AdvancedRangePicker
                              formItem={{
                                style: { marginBottom: 0 },
                                label: "Activity Date",
                              }}
                              control={control}
                              name="TransactionDate"
                              setValue={setValue}
                            />
                          </Radio>
                          <Radio
                            value={intentoryLogReportSelectSeriesType.Plates}
                          >
                            <CustomInput
                              control={control}
                              formStyle={{ marginBottom: 0 }}
                              formLabel="Plates"
                              name="SelectedPlates"
                              style={{ width: 422 }}
                              placeholder="Separate each number with a comma"
                              disabled={
                                selectSeriesOption !==
                                intentoryLogReportSelectSeriesType.Plates
                              }
                            />
                          </Radio>
                          <Radio
                            value={
                              intentoryLogReportSelectSeriesType.NotFullyIssuedSeries
                            }
                          >
                            Not Fully Issued
                          </Radio>
                        </Space>
                      </CustomRadioGroup>
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          reset(initialDefaultValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default InventoryLogReport;
