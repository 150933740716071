import { Upload, UploadFile } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InboxOutlined } from "@ant-design/icons";
import { uploadFile, clearUploadedFile } from "../../store/ui/uiActions";

interface PreservableStateLinkProps {
  uploadName: string;
  accept: string;
  entity: string;
  action: string;
  customMessage?: string;
  children: React.ReactNode;
}

export const FileUpload: React.FC<PreservableStateLinkProps> = ({
  uploadName,
  accept,
  entity,
  action,
  customMessage,
}) => {
  const dispatch = useDispatch();
  const uploadedFile = useSelector((state: any) =>
    state?.ui?.uploads?.find((u: any) => u.name === uploadName)
  );
  const defaultFileList: UploadFile<any>[] = useMemo(
    () =>
      uploadedFile != null
        ? [
            {
              uid: "1",
              name: uploadedFile.fileName,
              status: uploadedFile.isUploaded === true ? "done" : "error",
              linkProps: '{"download": "file"}',
            },
          ]
        : [],
    []
  );

  return (
    <Upload.Dragger
      name="file"
      multiple={false}
      maxCount={1}
      accept={accept}
      listType="picture"
      isImageUrl={() => false}
      defaultFileList={defaultFileList}
      customRequest={({ file, onSuccess, onError }) => {
        dispatch(
          uploadFile(uploadName, file, entity, action, onSuccess, onError)
        );
      }}
      onRemove={() => {
        dispatch(clearUploadedFile(uploadName));
        return true;
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag a file to this area to upload
      </p>
      {customMessage && <p className="ant-upload-hint">{customMessage}</p>}
    </Upload.Dragger>
  );
};
