import React from "react";
import { Button, Tooltip, Typography } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { isEmpty, isNull } from "lodash";

const { Title, Text, Paragraph } = Typography;

const HelpContent = ({ actorData }) => {
  const customSupportMessage = actorData?.CustomSupportMessage;
  return (
    <>
      <Paragraph>
        <Text strong>
          <CaretRightOutlined />
          {"Explore answers to common questions in our"}
          <Button
            style={{ padding: 4 }}
            type="link"
            onClick={() =>
              window.open(
                "https://brokerlink.atlassian.net/l/cp/j2vpyt6A",
                "autolinkFAQ"
              )
            }
          >
            FAQ
          </Button>
          {"section"}
        </Text>
      </Paragraph>
      {isEmpty(customSupportMessage) || isNull(customSupportMessage) ? (
        <Paragraph>
          <Text strong>
            <CaretRightOutlined />
            {"For further assistance, please email our support team: "}
          </Text>
          <Text copyable>support@brokerlinksoftware.com</Text>
        </Paragraph>
      ) : (
        <Paragraph>
          <Text strong>
            <CaretRightOutlined />
            {customSupportMessage}
          </Text>
        </Paragraph>
      )}
    </>
  );
};

export default HelpContent;
