import { Select } from "antd";

const { Option } = Select;

export const sortGroupDrillDownFields = {
  AGENCY_NAME: "AgencyNameAndNo",
  PRODUCER_1_NAME: "ProducerName1",
  PRODUCER_2_NAME: "ProducerName2",
  PRODUCER_1_CODE: "ProducerCode1",
  PRODUCER_2_CODE: "ProducerCode2",
  MONTH: "ProdMonthName",
  DAY: "ProdDayName",
  HOUR: "ProdHourName",
  WEEK: "ProdWeekName",
};

export const drillDownSortFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Month", value: sortGroupDrillDownFields.MONTH },
  { label: "Week", value: sortGroupDrillDownFields.WEEK },
  {
    label: "Day of week",
    value: sortGroupDrillDownFields.DAY,
  },
  { label: "Hour", value: sortGroupDrillDownFields.HOUR },
  { label: "Producer 1 Name", value: sortGroupDrillDownFields.PRODUCER_1_NAME },
  { label: "Producer 2 Name", value: sortGroupDrillDownFields.PRODUCER_2_NAME },
  { label: "Producer 1 Code", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2 Code", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
];

export const dayOfWeek = {
  ALL_DAYS: 0,
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
};

export const dayOfWeekOptions = [
  <Option value={dayOfWeek.ALL_DAYS} key={0}>
    All days
  </Option>,
  <Option value={dayOfWeek.MONDAY} key={1}>
    Monday
  </Option>,
  <Option value={dayOfWeek.TUESDAY} key={2}>
    Tuesday
  </Option>,
  <Option value={dayOfWeek.WEDNESDAY} key={3}>
    Wednesday
  </Option>,
  <Option value={dayOfWeek.THURSDAY} key={4}>
    Thursday
  </Option>,
  <Option value={dayOfWeek.FRIDAY} key={5}>
    Friday
  </Option>,
  <Option value={dayOfWeek.SATURDAY} key={6}>
    Saturday
  </Option>,
  <Option value={dayOfWeek.SUNDAY} key={7}>
    Sunday
  </Option>,
];
