import { isEmpty, isNull, isUndefined } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import CustomInputCurrency from "../../../shared/form/reactHookForm/CustomInputCurrency";

const specialLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const PolicyCoverageAndPremiumsPanel = ({ control }) => {
  const selected = useSelector((state) => state.document.selected);
  const isDownloaded = selected && selected.IsDownloaded;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;
  const isBatched =
    selected &&
    !(isEmpty(selected.AgencyBatchNo) || isNull(selected.AgencyBatchNo));
  const CreditPrevAnnualPremium = selected && selected.CreditPrevAnnualPremium;

  return (
    <>
      <CustomInputCurrency
        layout={specialLayout}
        formLabel="Prorated Premium"
        control={control}
        name="ProratedPremium"
        isPrecision={true}
        disabled={isDownloaded || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        formLabel="Short Term Surcharge"
        control={control}
        name="ShortTermSurcharge"
        disabled={isDownloaded || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        formLabel="Total Fees"
        control={control}
        name="TotalFees"
        disabled={isDownloaded || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        formLabel="Fees and Premiums"
        control={control}
        name="TotalFeesAndPremiums"
        disabled={isDownloaded || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        formLabel="Payment Plan Fee"
        control={control}
        name="AutoplanAdminFee"
        disabled={isDownloaded || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        formLabel="HST(BC) Due"
        control={control}
        isPrecision={true}
        name="HSTBCAmt"
        disabled={isDownloaded || isBatched || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        control={control}
        formLabel="Adjustment to Annual Premium"
        parentName="CreditPrevAnnualPremiumLabel"
        name="CreditPrevAnnualPremium"
        isPrecision={true}
        value={
          isNull(CreditPrevAnnualPremium) ||
          isUndefined(CreditPrevAnnualPremium) ||
          isNaN(CreditPrevAnnualPremium)
            ? CreditPrevAnnualPremium
            : -1 * CreditPrevAnnualPremium
        }
        disabled={isDownloaded || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        formLabel="Amount Due"
        control={control}
        isPrecision={true}
        name="AmountDue"
        disabled={isBatched || !isUserAllowedToSave}
      />

      <CustomInputCurrency
        layout={specialLayout}
        formLabel="Refund Due"
        control={control}
        isPrecision={true}
        name="RefundDue"
        disabled={isBatched || !isUserAllowedToSave}
      />
    </>
  );
};

export default React.memo(PolicyCoverageAndPremiumsPanel);
