const renderRange = (inventoryRange, isFromNumber) => ({
  value: isFromNumber
    ? inventoryRange.ItemNumberFrom
    : inventoryRange.ItemNumberTo,
  label: `${inventoryRange.ItemNumberFrom} - ${inventoryRange.ItemNumberTo} (${inventoryRange.Quantity})`,
});

export const renderRangeOptions = (type, ranges, isFromNumber) => ({
  label: type,
  options: ranges
    ? ranges
        .filter((r) => r.ItemDescription === type)
        .map((r) => renderRange(r, isFromNumber))
    : [],
});

export const populateItemRange = (
  availableItemRanges,
  value,
  setFieldValue,
  fieldToUpdate,
  sourceItemField,
  targetItemField
) => {
  const targetItem =
    availableItemRanges &&
    availableItemRanges.filter((r) => r[sourceItemField] === value)[0];
  const targetValue = targetItem ? targetItem[targetItemField] : "";
  setFieldValue(fieldToUpdate, targetValue);
};

const getItemRangeValue = (
  availableItemRanges,
  value,
  sourceItemField,
  targetItemField
) => {
  const targetItem =
    availableItemRanges &&
    availableItemRanges.filter((r) => r[sourceItemField] === value)[0];
  const targetValue = targetItem ? targetItem[targetItemField] : "";
  return targetValue;
};

export const getItemNumberToFieldValue = (availableItemRanges, value) =>
  getItemRangeValue(
    availableItemRanges,
    value,
    "ItemNumberFrom",
    "ItemNumberTo"
  );

export const getItemNumberFromFieldValue = (availableItemRanges, value) =>
  getItemRangeValue(
    availableItemRanges,
    value,
    "ItemNumberTo",
    "ItemNumberFrom"
  );
