import { SnippetsOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Col, Drawer, Empty, Row, Button, Divider } from "antd";
import { isEmpty, isNull, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  clearListAction,
  clearOperationResultAction,
  clearSelectedAction,
  listDocumentsAction,
  setSearchValuesAction,
} from "../../../store/document/documentActions";
import { resetReportState } from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { PdfViewerModal } from "../../shared/PdfViewer";
import { PreservableStatePageWrapper } from "../../shared/PreservableStatePageWrapper";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { tableNames } from "../../shared/table/tableNames";
import QuickEditDocumentForm from "../form/QuickEditDocumentForm";
import UpdateDocumentForm from "../form/UpdateDocumentForm";
import SearchForm from "./SearchForm";
import { getDocumentColumns } from "./columns";
import { exportDataToExcel } from "../../shared/exportToExcel";

const Documents = () => {
  const dispatch = useDispatch();

  return (
    <PreservableStatePageWrapper
      onCleanup={() => {
        dispatch(clearListAction());
        dispatch(setSearchValuesAction(null));
      }}
      tableNames={[tableNames.DocumentSearch]}
    >
      <DocumentsBody />
    </PreservableStatePageWrapper>
  );
};
const DocumentsBody = () => {
  const [quickEdit, setQuickEdit] = useState(false);
  const dispatch = useDispatch();
  const documentState = useSelector((state) => state.document);

  const searchValues = documentState.searchValues;

  const quickEditOnCloseHandler = (clearSelected) => {
    setQuickEdit(false);
    if (clearSelected) {
      dispatch(clearSelectedAction());
    }
  };

  useEffect(() => {
    dispatch(resetReportState());
  }, []);

  const quickEditParentRefreshAction = () =>
    dispatch(listDocumentsAction(searchValues));

  return (
    <PermissionWrapper entityName="Documents">
      <div>
        <Drawer
          title={
            <>
              <SnippetsOutlined />
              Document Quick Edit
            </>
          }
          width={680}
          onClose={quickEditOnCloseHandler}
          open={quickEdit}
          bodyStyle={{ paddingTop: 4 }}
          destroyOnClose={true}
        >
          <QuickEditDocumentForm
            onCloseHandler={quickEditOnCloseHandler}
            parentRefreshAction={quickEditParentRefreshAction}
            tableName={tableNames.DocumentSearch}
          />
        </Drawer>
        <Routes>
          <Route
            path=""
            element={<DocumentsSearch setQuickEdit={setQuickEdit} />}
          />

          <Route
            path="updatedocumentform/:documentKey"
            element={<UpdateDocumentForm />}
          />
        </Routes>
        <PdfViewerModal />
      </div>
    </PermissionWrapper>
  );
};

const DocumentsSearch = ({ setQuickEdit }) => {
  const [clearAll, setClearAll] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const dispatch = useDispatch();
  const onSubmitHandler = (values) => dispatch(listDocumentsAction(values));
  const onResetHandler = () => dispatch(clearListAction());

  const documentState = useSelector((state) => state.document);
  const searchText =
    documentState.searchValues && documentState.searchValues.SearchValue;

  const data = documentState.data;
  const operationResult = documentState.operationResult;
  const list = data && data.List;
  const searchByColumns = data && data.SearchByColumns;
  const additionalColumns = data && data.AdditionalColumns;
  const loading = documentState.loading;
  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.DocumentSearch)
  );

  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const exportTableData = () => {
    setIsExporting(true);
    exportDataToExcel(
      tableData?.extra.currentDataSource,
      ["Id", "DocumentKey", "AgencyKey", "Void", "Decal", "PolicyNumberPC"],
      maskSensitiveData,
      "FindDocumentResults.csv",
      [],
      ["EntryDateTime", "PolicyEffectiveDate", "PolicyExpiryDate"]
    );
    setIsExporting(false);
  };

  const columns = getDocumentColumns(
    searchByColumns,
    additionalColumns,
    searchText,
    setQuickEdit
  );

  const exportButton = (
    <Button
      size={"small"}
      loading={isExporting}
      onClick={() => exportTableData()}
      disabled={isEmpty(tableData)}
    >
      <FileExcelOutlined />
      Export
    </Button>
  );

  useEffect(() => {
    openNotificationOperationResult(
      operationResult,
      () => dispatch(clearOperationResultAction()),
      false
    );
  }, [operationResult]);

  const getSearchByColumnTitles = () => {
    const result = [];
    searchByColumns.forEach((column) => {
      const columnToCopy = columns.find((c) => c.dataIndex === column);
      if (!isUndefined(columnToCopy)) result.push(columnToCopy.title);
    });
    return result.join(", ");
  };

  const emptyTableMessage = isNull(searchByColumns)
    ? "No data"
    : `Searched in ${getSearchByColumnTitles()} field(s). No matches found. Please review your search criteria and try again.`;

  return (
    <>
      <Row>
        <Col span={24}>
          <SearchForm
            onSubmitHandler={onSubmitHandler}
            onResetHandler={onResetHandler}
            loading={loading}
            setClearAll={setClearAll}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <Row>
        <Col>
          <SearchableTable
            tableName={tableNames.DocumentSearch}
            buttons={[exportButton]}
            keyColumn="DocumentKey"
            setClearAll={setClearAll}
            clearAll={clearAll}
            scroll={{ x: 2470, y: 550 }}
            sticky
            dataSource={list}
            columns={columns}
            loading={loading}
            size="small"
            rowKey={(r) => r.DocumentKey}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={emptyTableMessage}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default Documents;
