import React from "react";
import { useSelector } from "react-redux";
import { movementOptions, types } from "../shared/movementTypes";
import ReceiveICBCShipment from "./ReceiveICBCShipment";
import ReceiveBrokerShipment from "./ReceiveBrokerShipment";

const ReceiveInventoryStep2 = () => {
  const newMovement = useSelector(
    (state) => state.inventory.moveInventory.newMovement
  );

  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  const movementType =
    newMovement.entity && newMovement.entity.Header.InventoryMovementType;

  const movementTypeOption = movementOptions.filter(
    (o) => o.value === movementType
  )[0];

  const movementTypeDescription =
    movementTypeOption && movementTypeOption.label;

  return (
    <>
      {movementType === types.ReceiptFromICBC ? (
        <ReceiveICBCShipment
          newMovement={newMovement}
          movementTypeDescription={movementTypeDescription}
          operationResult={operationResult}
        />
      ) : movementType === types.ReceiptFromAnotherAgency ? (
        <ReceiveBrokerShipment
          newMovement={newMovement}
          movementTypeDescription={movementTypeDescription}
          operationResult={operationResult}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ReceiveInventoryStep2;
