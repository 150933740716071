export const Actions = {
  DELETE_DOCUMENT_SUCCEEDED: "DELETE_DOCUMENT_SUCCEEDED",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  LIST_DOCUMENTS: "LIST_DOCUMENTS",
  LIST_DOCUMENTS_SUCCEEDED: "LIST_DOCUMENTS_SUCCEEDED",
  LIST_DOCUMENTS_FAILED: "LIST_DOCUMENTS_FAILED",
  LIST_VEHICLE_TYPES: "LIST_VEHICLE_TYPES",
  LIST_VEHICLE_TYPES_SUCCEEDED: "LIST_VEHICLE_TYPES_SUCCEEDED",
  CLEAR_LIST: "CLEAR_LIST",
  READ_DOCUMENT_FOR_QUICK_EDIT: "READ_DOCUMENT_FOR_QUICK_EDIT",
  READ_DOCUMENT_FOR_QUICK_EDIT_SUCCEEDED:
    "READ_DOCUMENT_FOR_QUICK_EDIT_SUCCEEDED",
  READ_DOCUMENT: "READ_DOCUMENT",
  READ_DOCUMENT_SUCCEEDED: "READ_DOCUMENT_SUCCEEDED",
  CREATE_CERTIFICATE_COPY: "CREATE_CERTIFICATE_COPY",
  CREATE_CERTIFICATE_COPY_SUCCEEDED: "CREATE_CERTIFICATE_COPY_SUCCEEDED",
  CREATE_DOCUMENT_BY_PRODUCT: "CREATE_DOCUMENT_BY_PRODUCT",
  CREATE_DOCUMENT_BY_PRODUCT_SUCCEEDED: "CREATE_DOCUMENT_BY_PRODUCT_SUCCEEDED",
  CREATE_DOCUMENT_BY_PRODUCT_KEY: "CREATE_DOCUMENT_BY_PRODUCT_KEY",
  CREATE_DOCUMENT_BY_PRODUCT_KEY_SUCCEEDED:
    "CREATE_DOCUMENT_BY_PRODUCT_KEY_SUCCEEDED",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  CLEAR_SELECTED: "CLEAR_SELECTED_DOCUMENT",
  SET_SELECTED: "SET_SELECTED_DOCUMENT",
  UPDATE_QUICK_EDIT_CHANGES: "UPDATE_QUICK_EDIT_CHANGES",
  UPDATE_QUICK_EDIT_CHANGES_SUCCEEDED: "UPDATE_QUICK_EDIT_CHANGES_SUCCEEDED",
  CLEAR_SELECTED_WARNING_MESSAGE: "CLEAR_SELECTED_WARNING_MESSAGE",
  SET_IGNORE_SELECTED_WARNING: "SET_IGNORE_SELECTED_WARNING",
  SET_SEARCH_VALUES: "SET_SEARCH_VALUES",
  SET_EXPAND_SECTIONS: "SET_EXPAND_SECTIONS",
  SET_MANUAL_SETTINGS: "SET_DOCUMENT_MANUAL_SETTINGS",
  CLEAR_MANUAL_SETTINGS: "CLEAR_DOCUMENT_MANUAL_SETTINGS",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  UPDATE_DOCUMENT_SUCCEEDED: "UPDATE_DOCUMENT_SUCCEEDED",
  LIST_TRANSACTION_TYPES: "LIST_DOCUMENT_TRANSACTION_TYPES",
  LIST_TRANSACTION_TYPES_SUCCEEDED: "LIST_DOCUMENT_TRANSACTION_TYPES_SUCCEEDED",
  LIST_POLICY_PRODUCTS: "LIST_POLICY_PRODUCTS",
  LIST_POLICY_PRODUCTS_SUCCEEDED: "LIST_POLICY_PRODUCTS_SUCCEEDED",
  SET_REFRESH_LIST_FLAG: "SET_REFRESH_LIST_FLAG",
};
