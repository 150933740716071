import { useSelector } from "react-redux";
import { columnTypes } from "../../shared/table/columnTypes";
import { compareStrings } from "../../shared/sorting";
import { matchSorter } from "match-sorter";

export const manageRenewalsColumns = () => {
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  return [
    {
      title: "Expiry Date",
      dataIndex: "PolicyExpiryDate",
      type: columnTypes.DATE,
      width: 95,
    },
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      width: 85,
    },
    {
      title: "Plate",
      dataIndex: "PlateNumber",
      type: columnTypes.TEXT,
      width: 72,
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: 250,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
    },
    {
      title: "Status",
      dataIndex: "PolicyStatusDesc",
      type: columnTypes.TEXT,
      width: 105,
    },
    {
      title: "PC1",
      dataIndex: "ProducerCode1",
      type: columnTypes.TEXT,
      width: 65,
    },
    {
      title: "Producer Name 1",
      dataIndex: "ProducerName1",
      type: columnTypes.TEXT,
      width: 175,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
    },
    {
      title: "PC2",
      dataIndex: "ProducerCode2",
      type: columnTypes.TEXT,
      width: 65,
    },
    {
      title: "Producer Name 2",
      dataIndex: "ProducerName2",
      type: columnTypes.TEXT,
      width: 175,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
    },
    {
      title: "Email",
      dataIndex: "CustomerEmail",
      type: columnTypes.TEXT,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
      width: 220,
    },
    {
      title: "Home Phone",
      dataIndex: "CustomerHomePhone",
      type: columnTypes.PHONE,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
      width: 95,
    },
    {
      title: "Mobile Phone",
      dataIndex: "CustomerMobilePhone",
      type: columnTypes.PHONE,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
      width: 95,
    },
    {
      title: "Effective Date",
      dataIndex: "PolicyEffectiveDate",
      type: columnTypes.DATE,
      width: 95,
    },
    {
      title: "Consent",
      dataIndex: "CustomerConsentYN",
      width: 85,
      type: columnTypes.TEXT,
    },
    {
      title: "Vehicle",
      dataIndex: "Vehicle",
      type: columnTypes.TEXT,
      width: 190,
    },
    {
      title: "Reg Num",
      dataIndex: "RegNumber",
      type: columnTypes.TEXT,
      width: 75,
    },
    {
      title: "Product",
      dataIndex: "ProductCode",
      type: columnTypes.TEXT,
      width: 85,
    },
    {
      title: "Agency",
      dataIndex: "AgencyNumberAndName",
      type: columnTypes.TEXT,
      width: 270,
    },
  ];
};
