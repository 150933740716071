import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { Actions } from "./moveDocumentsConstants";

export const clearMoveDocuments = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_MOVE_DOCUMENTS,
  });
};

export const listMoveDocuments = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.LIST_MOVE_DOCUMENTS });

  const payload = await customGet(entityType.DOCUMENT, "ListMoveDocuments", {
    params: {
      agencyKey: agencyKey,
    },
  });

  dispatch({ type: Actions.LIST_MOVE_DOCUMENTS_SUCCEEDED, payload });
};

export const moveDocumentsToAgency = (values) => async (dispatch) => {
  dispatch({ type: Actions.MOVE_DOCUMENTS_TO_AGENCY });

  const payload = await customPost(
    entityType.DOCUMENT,
    "MoveDocumentsToAgency",
    values
  );

  dispatch({ type: Actions.MOVE_DOCUMENTS_TO_AGENCY_SUCCEEDED, payload });
};

export const clearSelectedMoveDocumentsRows = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_ROWS,
  });
};

export const setSelectedMoveDocumentsRows = (values) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_ROWS,
    payload: { selectedRows: values },
  });
};

export const clearMoveDocumentsOperationResults = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};
