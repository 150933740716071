import {
  findCurrentAgencies,
  findProducerNames,
} from "../report/reportActions";

export const transformProductionByDayHourInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const producer1Names = findProducerNames(state, 1, values.ProducerCode1Keys);
  const producer2Names = findProducerNames(state, 2, values.ProducerCode2Keys);
  const transactionDates = [
    values.TransactionDate[0],
    values.TransactionDate[1],
  ];
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FirstProducer: producer1Names,
    SecondProducer: producer2Names,
    TransactionDate: transactionDates,
    FileName: `ProductionSummaryByDayHour_${agency.Number}`,
  };
};
