import entityType from "../../../api//entityType";
import { customGet, customPost } from "../../../api/customApi";
import Actions from "./securityConstants";

export const listUserAgencyRolesAction =
  (userKey, brokerId) => async (dispatch) => {
    dispatch({ type: Actions.LIST_USER_AGENCY_ROLES });
    const payload = await customGet(
      entityType.SECURITY,
      "ListUserAgencyRoles",
      { params: { userKey: userKey, brokerId: brokerId } }
    );
    dispatch({ type: Actions.LIST_USER_AGENCY_ROLES_SUCCEEDED, payload });
  };

export const listBrokerAgenciesByUserRoleAction =
  (roleId, userKey, brokerId) => async (dispatch) => {
    dispatch({ type: Actions.LIST_BROKER_AGENCIES_BY_USER_ROLE });
    const params = { roleId: roleId, userKey: userKey, brokerId: brokerId };
    const payload = await customGet(
      entityType.SECURITY,
      "ListBrokerAgenciesByUserRole",
      { params: params }
    );
    dispatch({
      type: Actions.LIST_BROKER_AGENCIES_BY_USER_ROLE_SUCCEEDED,
      payload,
    });
  };

export const enableRoleForAgenciesAction =
  (data, omitSuccessAlert = false) =>
  async (dispatch) => {
    dispatch({ type: Actions.ENABLE_ROLE_FOR_AGENCIES });
    const payload = await customPost(
      entityType.SECURITY,
      "EnableRoleForAgencies",
      data
    );
    dispatch({
      type: Actions.ENABLE_ROLE_FOR_AGENCIES_SUCCEEDED,
      payload: { ...payload, omitSuccessAlert },
    });
  };

export const disableRoleForAgenciesAction =
  (data, omitSuccessAlert = false) =>
  async (dispatch) => {
    dispatch({ type: Actions.DISABLE_ROLE_FOR_AGENCIES });
    const payload = await customPost(
      entityType.SECURITY,
      "DisableRoleForAgencies",
      data
    );

    dispatch({
      type: Actions.DISABLE_ROLE_FOR_AGENCIES_SUCCEEDED,
      payload: { ...payload, omitSuccessAlert },
    });
  };

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};
