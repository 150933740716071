import { Col, Form, Radio, Row, Typography } from "antd";
import { isNull, isUndefined } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  hitAndRunDeductible,
  incomeTupLimit,
  louLimit,
  offHighwayTplLimit,
  tplLimit,
} from "../selectData";

import { Controller, useFormContext } from "react-hook-form";
import SearchSelectEx from "../../../shared/form/SearchSelectEx";
import { checkHookFormFieldPropsEquality } from "../../../shared/form/formValidatonRules";
import CustomInputCurrency from "../../../shared/form/reactHookForm/CustomInputCurrency";
import CustomInputCurrencyEx from "../../../shared/form/reactHookForm/CustomInputCurrencyEx";

const { Text } = Typography;
const specialLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tableHeaderLayout = { wrapperCol: { offset: 8, span: 16 } };

const headerLabelStyle = {
  display: "inline-block",
  paddingLeft: 8,
};

const formItemStyle = {
  display: "inline-block",
  width: "calc(50%)",
  marginBottom: 4,
};
const inputStyle = {
  width: 120,
  textAlign: "right",
};

const PolicyCoverageTable = ({ control }) => {
  const selected = useSelector((state) => state.document.selected);
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;
  const LOUDayLimit = selected && selected.LOUDayLimit;
  const isDownloaded = selected && selected.IsDownloaded;

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            {...tableHeaderLayout}
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item style={formItemStyle}>
              <Text strong>Limit / Deductible</Text>
            </Form.Item>
            <Form.Item style={formItemStyle}>
              <Text strong style={headerLabelStyle}>
                Premium
              </Text>
            </Form.Item>
          </Form.Item>

          <CustomInputCurrencyEx
            layout={specialLayout}
            formStyle={formItemStyle}
            control={control}
            formLabel="Basic"
            parentName="Basic"
            emptyName="BasicEmpty"
            name="BasicPremium"
            style={inputStyle}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <Form.Item
            {...specialLayout}
            name="TPLLPremium"
            label="Third Party Liability"
            style={{
              marginBottom: 0,
            }}
          >
            <SearchSelectEx
              control={control}
              formStyle={formItemStyle}
              name="TPLLLimit"
              style={inputStyle}
              options={tplLimit}
              disabled={isDownloaded || !isUserAllowedToSave}
              showArrow={false}
            />

            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              name="TPLLPremium"
              style={inputStyle}
              disabled={isDownloaded || !isUserAllowedToSave}
            />
          </Form.Item>

          <Form.Item
            {...specialLayout}
            name="Collision"
            label="Collision"
            style={{
              marginBottom: 0,
            }}
          >
            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              style={inputStyle}
              name="CollisionDeductible"
              disabled={isDownloaded || !isUserAllowedToSave}
            />

            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              name="CollisionPremium"
              style={inputStyle}
              disabled={isDownloaded || !isUserAllowedToSave}
            />
          </Form.Item>
          <Controller
            control={control}
            name="SpecifiedPerilsIndicator"
            render={({ field, fieldState: { error } }) => (
              <SpecifiedPerilsIndicator
                field={field}
                error={error}
                isDownloaded={isDownloaded}
                isUserAllowedToSave={isUserAllowedToSave}
              />
            )}
          />
          <Form.Item
            {...specialLayout}
            name="Comprehensive"
            label="Comprehensive"
            style={{
              marginBottom: 0,
            }}
          >
            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              style={inputStyle}
              name="ComprDeductible"
              disabled={isDownloaded || !isUserAllowedToSave}
            />

            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              name="ComprPremium"
              style={inputStyle}
              disabled={isDownloaded || !isUserAllowedToSave}
            />
          </Form.Item>
          <Form.Item
            {...specialLayout}
            name="LossOfUse"
            label="Loss Of Use"
            style={{
              marginBottom: 0,
            }}
          >
            <SearchSelectEx
              control={control}
              formStyle={formItemStyle}
              options={louLimit}
              style={inputStyle}
              name="LOUTotalLimit"
              disabled={isDownloaded || !isUserAllowedToSave}
              showArrow={false}
            />

            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              name="LOUPremium"
              style={inputStyle}
              disabled={isDownloaded || !isUserAllowedToSave}
            />
          </Form.Item>

          <LossOfUseDailyLimit LOUDayLimit={LOUDayLimit} />

          <Form.Item
            {...specialLayout}
            name="IncomeTopUp"
            label="Income Top Up"
            style={{
              marginBottom: 0,
            }}
          >
            <SearchSelectEx
              control={control}
              formStyle={formItemStyle}
              options={incomeTupLimit}
              style={inputStyle}
              name="IncomeTUPLimit"
              disabled={isDownloaded || !isUserAllowedToSave}
              showArrow={false}
            />

            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              name="IncomeTUPPremium"
              style={inputStyle}
              disabled={isDownloaded || !isUserAllowedToSave}
            />
          </Form.Item>

          <Form.Item
            {...specialLayout}
            name="HitAndRun"
            label="Hit and Run"
            style={{
              marginBottom: 0,
            }}
          >
            <SearchSelectEx
              control={control}
              formStyle={formItemStyle}
              options={hitAndRunDeductible}
              style={inputStyle}
              name="HitAndRunDeductible"
              disabled={isDownloaded || !isUserAllowedToSave}
              showArrow={false}
            />

            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              name="HitAndRunPremium"
              style={inputStyle}
              disabled={isDownloaded || !isUserAllowedToSave}
            />
          </Form.Item>

          <Form.Item
            {...specialLayout}
            name="OffHwyTpl"
            label="Off Highway TPL"
            style={{
              marginBottom: 0,
            }}
          >
            <SearchSelectEx
              control={control}
              formStyle={formItemStyle}
              options={offHighwayTplLimit}
              style={inputStyle}
              name="OffHwyTPLLimit"
              disabled={isDownloaded || !isUserAllowedToSave}
              showArrow={false}
            />

            <CustomInputCurrency
              formStyle={formItemStyle}
              control={control}
              name="OffHwyTPLPremium"
              style={inputStyle}
              disabled={isDownloaded || !isUserAllowedToSave}
            />
          </Form.Item>

          <Controller
            control={control}
            name="RCLDNVRIndicator"
            render={({ field, fieldState: { error } }) => (
              <RCLDNVRIndicator
                field={field}
                error={error}
                isDownloaded={isDownloaded}
                isUserAllowedToSave={isUserAllowedToSave}
              />
            )}
          />

          <CustomInputCurrencyEx
            layout={specialLayout}
            formStyle={formItemStyle}
            control={control}
            formLabel="RC / LD / NVR+ Collision"
            parentName="RCLDCollision"
            emptyName="RCLDCollisionEmpty"
            name="RCLDCollisionPremuim"
            style={inputStyle}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInputCurrencyEx
            layout={specialLayout}
            formStyle={formItemStyle}
            control={control}
            emptyLayout={tableHeaderLayout}
            formLabel="RC / LD / NVR+ Comprehensive"
            parentName="RCLDCompr"
            emptyName="RCLDComprEmpty"
            name="RCLDComprPremuim"
            style={inputStyle}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <Controller
            control={control}
            name="RoadStarRoadSidePlusIndicator"
            render={({ field, fieldState: { error } }) => (
              <RoadStarRoadSidePlusIndicator
                field={field}
                error={error}
                isDownloaded={isDownloaded}
                isUserAllowedToSave={isUserAllowedToSave}
              />
            )}
          />

          <RoadStarRoadSidePlusValue
            isDownloaded={isDownloaded}
            isUserAllowedToSave={isUserAllowedToSave}
          />

          <CustomInputCurrencyEx
            layout={specialLayout}
            formStyle={formItemStyle}
            control={control}
            formLabel="Total Discount / Surcharge"
            parentName="TotalDiscountSurchargeLabel"
            emptyName="TotalDiscountSurchargeEmpty"
            style={inputStyle}
            name="TotalDiscountSurcharge"
            isPrecision={true}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInputCurrencyEx
            layout={specialLayout}
            formStyle={formItemStyle}
            control={control}
            formLabel="Annual Insurance Total"
            parentName="AnnualPremiumLabel"
            emptyName="AnnualPremiumEmpty"
            style={inputStyle}
            name="AnnualPremium"
            isPrecision={true}
            disabled={isDownloaded || !isUserAllowedToSave}
          />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(PolicyCoverageTable);

const RoadStarRoadSidePlusIndicator = React.memo(
  (props) => (
    <Form.Item
      {...tableHeaderLayout}
      name="RoadStarRoadSidePlusIndicator"
      style={{ marginBottom: 4 }}
      validateStatus={props.error != null ? "error" : ""}
      help={props.error != null ? props.error.message : null}
    >
      <div>
        <Radio.Group
          {...props.field}
          name="RoadStarRoadSidePlusIndicator"
          disabled={props.isDownloaded || !props.isUserAllowedToSave}
          options={[
            { label: "Road Star", value: 4 },
            { label: "Road Side Plus", value: 5 },
          ]}
        ></Radio.Group>
      </div>
    </Form.Item>
  ),
  checkHookFormFieldPropsEquality
);

const SpecifiedPerilsIndicator = React.memo(
  (props) => (
    <Form.Item
      {...specialLayout}
      label="Specified Perils"
      name="SpecifiedPerilsIndicator"
      style={{ marginBottom: 4 }}
      validateStatus={props.error != null ? "error" : ""}
      help={props.error != null ? props.error.message : null}
    >
      <div>
        <Radio.Group
          {...props.field}
          style={inputStyle}
          name="SpecifiedPerilsIndicator"
          disabled={props.isDownloaded || !props.isUserAllowedToSave}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        ></Radio.Group>
      </div>
    </Form.Item>
  ),
  checkHookFormFieldPropsEquality
);

const RCLDNVRIndicator = React.memo(
  (props) => (
    <Form.Item
      {...tableHeaderLayout}
      name="RCLDNVRIndicator"
      style={{ marginBottom: 4 }}
      validateStatus={props.error != null ? "error" : ""}
      help={props.error != null ? props.error.message : null}
    >
      <div>
        <Radio.Group
          {...props.field}
          name="RCLDNVRIndicator"
          disabled={props.isDownloaded || !props.isUserAllowedToSave}
          options={[
            { label: "RC", value: 1 },
            { label: "LD", value: 2 },
            { label: "NVR+", value: 3 },
          ]}
        ></Radio.Group>
      </div>
    </Form.Item>
  ),
  checkHookFormFieldPropsEquality
);

const LossOfUseDailyLimit = ({ LOUDayLimit }) => {
  const { control, watch } = useFormContext();
  const LOUTotalLimit = watch("LOUTotalLimit");

  return (
    <CustomInputCurrency
      layout={specialLayout}
      formLabel="Loss Of Use Daily Limit"
      formStyle={{ marginBottom: 4 }}
      control={control}
      style={inputStyle}
      name="LOUDayLimit"
      value={
        isNull(LOUDayLimit) || isUndefined(LOUDayLimit)
          ? LOUTotalLimit / 20
          : LOUDayLimit
      }
      disabled={true}
    />
  );
};

const RoadStarRoadSidePlusValue = ({ isDownloaded, isUserAllowedToSave }) => {
  const { control, watch } = useFormContext();
  const RoadStarRoadSidePlusIndicator = watch("RoadStarRoadSidePlusIndicator");
  return (
    <CustomInputCurrencyEx
      layout={specialLayout}
      formStyle={formItemStyle}
      control={control}
      formLabel="Road Star / Road Side Plus"
      parentName="RoadStarParent"
      emptyName="RoadStarPremiumEmpty"
      name="RoadStarAndRoadSidePlusPremium"
      style={inputStyle}
      disabled={
        isDownloaded ||
        !isUserAllowedToSave ||
        isNull(RoadStarRoadSidePlusIndicator) ||
        (RoadStarRoadSidePlusIndicator != 4 &&
          RoadStarRoadSidePlusIndicator != 5)
      }
    />
  );
};
