import { Col, Row } from "antd";
import { isNull } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  mediumInputStyle,
  shortInputStyle,
} from "../../../shared/form/formInputStyles";
import CustomDatePicker from "../../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../../shared/form/reactHookForm/CustomInput";
import CustomInputNumber from "../../../shared/form/reactHookForm/CustomInputNumber";
import CustomRadioGroup from "../../../shared/form/reactHookForm/CustomRadioGroup";
import SearchSelectEx from "../../../shared/form/SearchSelectEx";
import { dateFormat } from "../../../shared/formatting";

const specialLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 10 },
};

export const defaultLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const VehicleRegistrationVehiclePanel = ({ control }) => {
  const selected = useSelector((state) => state.document.selected);
  const isDownloaded = selected && selected.IsDownloaded;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;
  const vehicleTypes = useSelector((state) => state.vehicle.data);

  return (
    <>
      <Row>
        <Col span={10}>
          <CustomInput
            layout={specialLayout}
            formLabel="Registration"
            control={control}
            name="RegNumber"
            disabled={!isUserAllowedToSave}
            maxLength={8}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="VIN"
            control={control}
            name="VIN"
            disabled={!isUserAllowedToSave}
            maxLength={17}
          />

          <SearchSelectEx
            control={control}
            layout={specialLayout}
            formLabel="Type"
            name="VehicleType"
            options={vehicleTypes}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInputNumber
            layout={specialLayout}
            formLabel="Year"
            control={control}
            name="VehicleYear"
            maxLength={4}
            disabled={isDownloaded || !isUserAllowedToSave}
            style={shortInputStyle}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Make"
            control={control}
            name="Make"
            disabled={isDownloaded || !isUserAllowedToSave}
            maxLength={10}
            style={shortInputStyle}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Model"
            control={control}
            name="Model"
            disabled={isDownloaded || !isUserAllowedToSave}
            maxLength={5}
            style={shortInputStyle}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Color"
            control={control}
            name="VehicleColorCode"
            disabled={isDownloaded || !isUserAllowedToSave}
            maxLength={3}
            style={shortInputStyle}
          />

          <CustomRadioGroup
            control={control}
            layout={specialLayout}
            formLabel="Anti-Theft"
            name="AntiTheftCode"
            disabled={isDownloaded || !isUserAllowedToSave}
            options={[
              { label: "Yes", value: "I" },
              { label: "No", value: "" },
            ]}
          ></CustomRadioGroup>
        </Col>
        <Col span={10}>
          <CustomInput
            layout={defaultLayout}
            formLabel="Fleet Unit"
            control={control}
            name="FleetUnitNumber"
            disabled={isDownloaded || !isUserAllowedToSave}
            maxLength={5}
            style={shortInputStyle}
          />

          <CustomInputNumber
            layout={defaultLayout}
            formLabel="Net Weight (kg)"
            control={control}
            name="VehicleNetWeight"
            style={shortInputStyle}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInputNumber
            layout={defaultLayout}
            formLabel="G.V.W (kg)"
            control={control}
            style={shortInputStyle}
            name="VehicleGrossWeight"
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Body Style"
            control={control}
            name="VehicleBodyCode"
            disabled={isDownloaded || !isUserAllowedToSave}
            maxLength={5}
            style={shortInputStyle}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="VIC"
            control={control}
            name="VehicleIDCode"
            disabled={isDownloaded || !isUserAllowedToSave}
            style={shortInputStyle}
            maxLength={6}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Inspection Decal"
            control={control}
            name="VehicleInspectionDecalNo"
            disabled={isDownloaded || !isUserAllowedToSave}
            maxLength={7}
            style={mediumInputStyle}
          />

          <CustomDatePicker
            formItem={{
              layout: defaultLayout,
              label: "Inspection Expiry",
            }}
            format={dateFormat}
            control={control}
            name="VehicleInspectionExpiryDate"
            style={mediumInputStyle}
            disabled={isDownloaded || !isUserAllowedToSave}
          />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(VehicleRegistrationVehiclePanel);
