import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCopyAddInitialValues,
  clearSelectedProducerCode,
  clearUsersForProducerCodes,
  listAgenciesForProducerCodes,
  listUsersForProducerCodes,
  setSaveAndAddFlag,
} from "../../store/producer/producerActions";
import { BackButton } from "../shared/BackButton";
import AgencySelectEx from "../shared/form/AgencySelectEx";
import CustomCheckbox from "../shared/form/reactHookForm/CustomCheckbox";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import CustomRadioGroup from "../shared/form/reactHookForm/CustomRadioGroup";
import CustomSelect from "../shared/form/reactHookForm/CustomSelect";
import { AdminTasks } from "../shared/securityRoleTasks";
import { producerCodeSchema } from "./producerCodeSchema";

// Constants
const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 17 },
    xl: { span: 10 },
    xxl: { span: 10 },
  },
};

const shortLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 3 },
    sm: { span: 3 },
    md: { span: 3 },
    lg: { span: 3 },
    xl: { span: 3 },
    xxl: { span: 3 },
  },
};

const ProducerCodeForm = ({
  initialValues,
  onSubmitHandler,
  handleParentRefresh,
  isNew = false,
}) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(producerCodeSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const loading = useSelector((state) => state.producer.selectedLoading);
  const agencies = useSelector((state) => state.producer.agencies);
  const agenciesLoading = useSelector(
    (state) => state.producer.agenciesLoading
  );
  const users = useSelector((state) => state.producer.users);
  const usersLoading = useSelector((state) => state.producer.usersLoading);
  const copyAddInitialValues = useSelector(
    (state) => state.producer.copyAddInitialValues
  );

  const [usersOptions, setUsersOptions] = useState([]);
  const [enableEpicCode, setEnableEpicCode] = useState(false);
  const [agencyKey, setAgencyKey] = useState(initialValues.AgencyKey);

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesForProducerCodes(brokerId, AdminTasks));
    }
  }, [brokerId]);

  //
  useEffect(() => {
    if (agencyKey != null) {
      dispatch(listUsersForProducerCodes(brokerId));
    }
  }, [brokerId]);

  useEffect(() => {
    if (agencyKey != null) {
      const agency = agencies.find((a) => a.Id === agencyKey);
      setEnableEpicCode(agency && agency.EnableEPICCodesForProducers);
    }
  }, [agencyKey]);

  useEffect(() => {
    if (copyAddInitialValues) {
      reset(copyAddInitialValues);
      dispatch(clearCopyAddInitialValues());
    }
  }, [copyAddInitialValues]);

  useEffect(() => {
    const currentUserKey = getValues().UserKey;
    const options = users
      ? users
          .filter((u) => !u.Deleted || u.UserKey === currentUserKey)
          .map((u) => {
            return {
              label: (
                <div
                  className={maskSensitiveData === true ? "blurry-text" : ""}
                >
                  {u.Deleted
                    ? `${u.FirstName} ${u.LastName} (Deleted User)`
                    : u.Active
                    ? `${u.FirstName} ${u.LastName}`
                    : `${u.FirstName} ${u.LastName} (Inactive)`}
                </div>
              ),
              value: u.UserKey,
              filter: `${u.FirstName} ${u.LastName}`,
              disabled: !u.Active,
            };
          })
      : [];
    setUsersOptions(options);
  }, [users]);

  const onGoBackHandler = () => {
    dispatch(clearSelectedProducerCode());
    dispatch(clearUsersForProducerCodes());
  };

  const onSubmitHandlerEx = (values) => {
    onSubmitHandler(values);
    handleParentRefresh();
    reset(values);
  };

  const onSubmitAndAddHandler = (values) => {
    dispatch(setSaveAndAddFlag(true));
    onSubmitHandler(values);
    handleParentRefresh();
    reset(values);
  };

  return (
    <Form
      autoComplete="xyz"
      size="small"
      onFinish={() => handleSubmit(onSubmitHandlerEx)()}
    >
      <Row style={{ paddingBottom: 8 }}>
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <BackButton onGoBackHandler={onGoBackHandler} isDirty={isDirty} />
        </Col>
        {isNew === true && (
          <Col style={{ marginRight: 5, paddingTop: 5 }}>
            <Button
              type="primary"
              loading={loading}
              disabled={!isValid || !isDirty}
              onClick={() => {
                onSubmitAndAddHandler(getValues());
                document.getElementById("ProducerCodeInput")?.focus();
              }}
            >
              <SaveOutlined />
              Save & New
            </Button>
          </Col>
        )}
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <Button
            type={isNew === true ? "default" : "primary"}
            loading={loading}
            id="processButton"
            htmlType="submit"
            disabled={!isValid || !isDirty}
          >
            <SaveOutlined />
            Save
          </Button>
        </Col>
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <Button
            size="small"
            type="dashed"
            disabled={!isDirty || loading}
            onClick={() => {
              reset();
              trigger();
            }}
          >
            <UndoOutlined />
            Reset Values
          </Button>
        </Col>
      </Row>
      <Card size="small">
        <Row style={{ paddingBottom: 16 }}>
          <Col span={24}>
            <CustomRadioGroup
              control={control}
              formLabel="Code #"
              name="ProducerNumber"
              layout={layout}
              required={true}
              autoFocus={true}
              afterChange={() => trigger()}
            >
              <Radio value={1}>1</Radio>
              <Radio value={2}>2</Radio>
            </CustomRadioGroup>

            <CustomInput
              control={control}
              layout={shortLayout}
              autoFocus={true}
              required={true}
              formLabel="Code"
              name="ProducerCode"
              id="ProducerCodeInput"
              maxLength={3}
            />
            <AgencySelectEx
              layout={layout}
              formLabel="Home Agency"
              name="AgencyKey"
              required={true}
              agencies={agencies}
              isLoading={agenciesLoading}
              onChange={(value) => setAgencyKey(value)}
              control={control}
            />

            <CustomCheckbox
              control={control}
              layout={layout}
              formLabel="Applies in all agencies"
              name="AppliesInAllAgencies"
            />

            <CustomCheckbox
              control={control}
              layout={layout}
              formLabel="This code is linked to user"
              name="IsLinkedToUser"
            />
            {!getValues().IsLinkedToUser && (
              <CustomInput
                control={control}
                layout={layout}
                formLabel="Description"
                name="Description"
                required={true}
                maxLength={25}
                className={
                  !isNew && maskSensitiveData === true ? "blurry-text" : ""
                }
              />
            )}
            {getValues().IsLinkedToUser && (
              <CustomSelect
                formLabel="User"
                layout={layout}
                control={control}
                name="UserKey"
                options={usersOptions}
                loading={usersLoading}
                required={true}
                showSearch={true}
                filterOption={(input, option) =>
                  option.filter.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.filter
                    .toLowerCase()
                    .localeCompare(optionB.filter.toLowerCase())
                }
              />
            )}
            {enableEpicCode && (
              <CustomInput
                control={control}
                layout={layout}
                formLabel="EPIC Code"
                name="EPICCode"
                maxLength={25}
              />
            )}
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ProducerCodeForm;
