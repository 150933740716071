import {
  OidcUserStatus,
  TokenRenewMode,
  useOidc,
  useOidcUser,
} from "@axa-fr/react-oidc";
import { useDispatch, useSelector } from "react-redux";
import { setSessionExpiring } from "../../../store/actor/actorActions";
import { logCustomMessage } from "../../../store/ui/uiActions";

export const Stored = {
  LAST_LOGIN_TIMESTAMP: "last_login_timestamp",
  PRESSED_LOGOUT: "has_pressed_logout",
  LOAD_ACTOR: "should_load_actor",
  REATTEMPT_LOGIN: "should_reattempt_login",
  DEFAULT_VALUE: "true",
};

// Don't forget to change warmup-qa.ps1 & warmup-prod.ps1 accordingly
export const identityServerConfiguration = {
  client_id: "autolink_plus_react_client",
  redirect_uri: `${process.env.REACT_APP_URI}/callback`,
  silent_redirect_uri: `${process.env.REACT_APP_URI}/silent-renew`,
  scope: "openid profile autolink_plus_api IdentityServerApi offline_access",
  authority: `${process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY}`,
  service_worker_only: true,
  service_worker_relative_url: `${process.env.REACT_APP_BASENAME}OidcServiceWorker.js`,
  monitor_session: false,
  refresh_time_before_tokens_expiration_in_second: 120,
  extras: { prompt: "login" },
  token_renew_mode: TokenRenewMode.access_token_or_id_token_invalid,
};

// To use instead of useOidc because the logout function is overwritten to suit our needs
export const useCustomOidc = () => {
  //
  const dispatch = useDispatch();
  const {
    isAuthenticated: isOidcAuthenticated,
    logout: legacyLogout,
    login: legacyLogin,
    renewTokens,
  } = useOidc();
  const { oidcUserLoadingState, oidcUser } = useOidcUser();

  //
  const sessionExpiring = useSelector(
    (state) => state.actor.details.sessionExpiring
  );
  const isAuthenticated =
    isOidcAuthenticated === true &&
    oidcUserLoadingState !== OidcUserStatus.Unauthenticated;

  //
  const logout = async ({
    hasPressedLogout = false,
    stopSessionExpiring = false,
    extras = undefined,
  }) => {
    if (stopSessionExpiring === true && sessionExpiring === true) {
      if (!isAuthenticated) {
        return;
      }
      dispatch(setSessionExpiring(false));
    }
    if (hasPressedLogout === true) {
      console.log("User has pressed logout button");
      localStorage.setItem(Stored.PRESSED_LOGOUT, Stored.DEFAULT_VALUE);
    } else {
      console.log("User has been logged out automatically (time out)");
      localStorage.removeItem(Stored.PRESSED_LOGOUT);
    }

    //
    dispatch(
      logCustomMessage(
        "LogUserLogoutEvent",
        oidcUser?.sub,
        "User logout initiated",
        `hasUserPressedLogout: ${hasPressedLogout}`
      )
    );

    await legacyLogout(`${process.env.REACT_APP_URI}/logout`, extras);

    //
    dispatch({ type: "CLEAR_REDUX_STORE" });
  };

  //
  const login = async (
    url = undefined,
    extras = undefined,
    silentLoginOnly = undefined
  ) => {
    const callbackPath = `${process.env.REACT_APP_URI}${url ?? "/home"}`;
    await legacyLogin(callbackPath, extras, silentLoginOnly);
  };

  return {
    login,
    logout,
    isAuthenticated,
    renewTokens,
    isOidcAuthenticated,
    oidcUserLoadingState,
  };
};
