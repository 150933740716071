const entityType = {
  BROKER: "Broker",
  SECURITY: "Security",
  IDENTITY: "Identity",
  ACTOR: "Actor",
  USER: "User",
  AGENCY: "Agency",
  DOCUMENT: "Document",
  BATCH: "Batch",
  INVENTORY: "Inventory",
  COMMISSION: "Commission",
  PRODUCTION: "Production",
  COVERAGE: "Coverage",
  DEALER_BILLING: "DealerBilling",
  DATA_COLLECTION: "DataCollection",
  RETENTION: "Retention",
  FLEET: "Fleet",
  PRODUCER: "Producer",
  ANONYMOUS_USER: "UserAnon",
  VEHICLE: "Vehicle",
  TERRITORY: "Territory",
  PRODUCT: "Product",
  INITIALIZATION: "Initialization",
  RATE_CLASS: "RateClass",
  UI: "UI",
  ANNOUNCEMENT: "ANNOUNCEMENT",
  APP_LOG: "AppLog",
  FLEET_POLICY_CUSTOM_MAPPING: "FleetPolicyCustomMapping",
  LOAN_CALCULATOR: "LoanCalculator",
};

export default entityType;
