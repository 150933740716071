import { Form, Select } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";

const CustomSelect = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = React.memo(
  (props) => {
    const { field, error } = props;

    var selectProps = { ...props };
    delete selectProps.control;
    delete selectProps.layout;
    delete selectProps.formLabel;
    delete selectProps.formValidateStatus;
    delete selectProps.formHelp;
    delete selectProps.hasFeedback;
    delete selectProps.formStyle;
    delete selectProps.field;
    delete selectProps.isTouched;
    delete selectProps.isDirty;
    delete selectProps.error;
    delete selectProps.required;
    delete selectProps.linkedValue;
    delete selectProps.afterChange;
    delete selectProps.hideErrorsOnDisabled;

    return (
      <Form.Item
        {...props.layout}
        label={props.formLabel}
        name={props.name}
        validateStatus={error != null ? "error" : ""}
        help={
          error != null &&
          (props.hideErrorsOnDisabled != true || props.disabled == false)
            ? error.message
            : null
        }
        style={props.formStyle}
        hasFeedback={props.hasFeedback}
        required={props.required}
      >
        <div>
          <Select
            {...field}
            {...selectProps}
            onChange={(v, o) => {
              field.onChange(v, o);
              if (props.afterChange != null) {
                props.afterChange(v);
              }
            }}
            virtual={false}
          />
        </div>
      </Form.Item>
    );
  },
  (prevProps, nextProps) =>
    checkHookFormFieldPropsEquality(prevProps, nextProps) &&
    prevProps.options === nextProps.options
);
export default CustomSelect;
