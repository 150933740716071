import * as yup from "yup";
import { requiredMessage, maxMessage } from "../shared/form/formValidatonRules";

export const mappingSchema = yup.object().shape({
  ICBCNameOfInsured: yup.string().required(requiredMessage).max(60, maxMessage),
  FleetOrPolicyNumber: yup
    .string()
    .matches(
      /^(?:\d{6}|[A-Za-z\d]{2}\.\w{3})$/,

      "Must be 6 characters numeric only or alphanumeric in the format AA.AAA"
    )

    .required(),
  TAMClientCode: yup.string().required(requiredMessage).max(15, maxMessage),
  TAMProducerCode: yup.string().required(requiredMessage).max(10, maxMessage),
  TAMProducerName: yup.string().required(requiredMessage).max(50, maxMessage),
  NewPolicyIndicator: yup.boolean().required(requiredMessage),
});
