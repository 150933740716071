import * as yup from "yup";
import {
  numericRegex,
  numericMessage,
  minMessage,
  maxMessage,
  requiredMessage,
  numericRegexOrEmpty,
} from "../../shared/form/formValidatonRules";

import {
  CertificateReplacement,
  DuplicateForSignature,
  Policy,
} from "./manualDocumentTypes";

const manualDocumentSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  Timestamp: yup
    .string()
    .nullable()
    .when("Product", {
      is: (val) =>
        val === CertificateReplacement || val === DuplicateForSignature,
      then: (schema) =>
        schema
          .required(requiredMessage)
          .matches(numericRegexOrEmpty, numericMessage)
          .min(14, minMessage)
          .max(14, maxMessage),
    }),
  Registration: yup
    .string()
    .nullable()
    .when("Product", {
      is: (val) =>
        val === CertificateReplacement || val === DuplicateForSignature,
      then: (schema) =>
        schema.required(requiredMessage).min(8, minMessage).max(8, maxMessage),
    }),
  ProductKey: yup
    .number()
    .nullable()
    .when("Product", {
      is: Policy,
      then: (schema) => schema.required(requiredMessage),
    }),
  Product: yup.number().required(requiredMessage),
});

export default manualDocumentSchema;
