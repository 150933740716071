import Actions from "./securityConstants";
import produce from "immer";

const initialState = {
  loading: false,
  selectedUserAgencyRoles: [],
  selectedUserRoleAgencies: [],
  operationResult: null,
};

export const reducer = (state = initialState, action) =>
  //state = state || initialState;
  {
    return produce(state, (draft) => {
      switch (action.type) {
        case Actions.LIST_USER_AGENCY_ROLES:
          draft.loading = true;
          return draft;

        case Actions.LIST_USER_AGENCY_ROLES_SUCCEEDED:
          draft.selectedUserAgencyRoles = action.payload;
          draft.loading = false;
          return draft;

        case Actions.LIST_BROKER_AGENCIES_BY_USER_ROLE:
          draft.loading = true;
          return draft;

        case Actions.LIST_BROKER_AGENCIES_BY_USER_ROLE_SUCCEEDED:
          draft.selectedUserRoleAgencies = action.payload;
          draft.loading = false;
          return draft;

        case Actions.ENABLE_ROLE_FOR_AGENCIES:
        case Actions.DISABLE_ROLE_FOR_AGENCIES:
          draft.loading = true;
          return draft;

        case Actions.ENABLE_ROLE_FOR_AGENCIES_SUCCEEDED:
        case Actions.DISABLE_ROLE_FOR_AGENCIES_SUCCEEDED:
          draft.selectedUserRoleAgencies = action.payload.Data;
          draft.operationResult = action.payload.omitSuccessAlert
            ? null
            : action.payload.Operation;
          draft.loading = false;
          return draft;

        case Actions.CLEAR_OPERATION_RESULT:
          draft.operationResult = null;
          return draft;

        default:
          return state;
      }
    });
  };
