import { StopOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import AnnouncementFeed from "../announcement/AnnouncementFeed";
import AnnouncementAlert from "../announcement/AnnouncementModal";
import Dashboard from "../dashboard/Dashboard";
import HelpContent from "./HelpContent";

const { Title } = Typography;

const Home = () => {
  //
  const actorData = useSelector((state) => state.actor.details.data);
  const operationResult = useSelector(
    (state) => state.actor.details.operationResult
  );

  return operationResult && operationResult.Outcome === "Success" ? (
    <>
      <Title level={3}>Welcome to Autolink</Title>
      <AnnouncementAlert />
      <div
        style={{
          minHeight: "68vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row style={{ marginTop: 12 }}>
          <Col span={24}>
            <Dashboard />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <AnnouncementFeed />
          </Col>
        </Row>

        <Row
          style={{ marginTop: "auto" }}
          justify="space-between"
          align="middle"
        >
          <Col span={24}>
            <HelpContent actorData={actorData} />
          </Col>
        </Row>
      </div>
    </>
  ) : (
    <Title type="danger" level={5}>
      <Space>
        <StopOutlined />
        {operationResult != null && operationResult.Message != null
          ? operationResult.Message
          : "Something went wrong, please refresh this page or login again!"}
      </Space>
    </Title>
  );
};
export default Home;
