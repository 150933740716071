import * as yup from "yup";

const agencySchema = yup.object().shape(
  {
    AgencyNumber: yup
      .string()
      .required("Required")
      .max(5, "Longer than 5 characters"),
    TAMBranchNumber: yup.string().max(5, "Longer than 5 characters"),
    AgencyName: yup
      .string()
      .max(50, "Longer than 50 characters")
      .required("Required"),
    Address1: yup
      .string()
      .max(50, "Longer than 50 characters")
      .required("Required"),
    City: yup
      .string()
      .max(25, "Longer than 25 characters")
      .required("Required"),
    StateProvinceCode: yup
      .string()
      .max(2, "Longer than 2 characters")
      .required("Required"),
    ZipPostalCode: yup.string().required("Required"),
    Active: yup.string().required("Required"),
    AutomatedDownload: yup.boolean().required("Required"),
    SmuserId: yup.string().when(["AutomatedDownload", "ExtranetPassword"], {
      is: (AutomatedDownload, ExtranetPassword) =>
        (ExtranetPassword && ExtranetPassword.trim().length > 0) ||
        AutomatedDownload,
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),
    ExtranetPassword: yup.string().when(["AutomatedDownload", "SmuserId"], {
      is: (AutomatedDownload, SmuserId) =>
        (SmuserId && SmuserId.trim().length > 0) || AutomatedDownload,
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),
  },
  ["SmuserId", "ExtranetPassword"]
);

export default agencySchema;
