import { Button, Col, Divider, Form, Popconfirm, Row, Space, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mappingSchema } from "./mappingSchema";
import { layout, tailLayout } from "../shared/form/formLayout";
import { SaveOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNull } from "lodash";
import { useForm } from "react-hook-form";
import CustomCheckbox from "../shared/form/reactHookForm/CustomCheckbox";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import {
  updateMapping,
  createMapping,
  clearSelected,
} from "../../store/fleetPolicyCustomMappping/fleetPolicyCustomMappingActions";

const FleetPolicyToClientMappingForm = ({
  onCloseHandler,
  handleParentRefresh,
}) => {
  //
  const selected = useSelector((state) => state.fleetPolicyCustomMap.selected);
  const loading = useSelector(
    (state) => state.fleetPolicyCustomMap.selectedLoading
  );

  //
  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: selected,
    resolver: yupResolver(mappingSchema),
  });

  //
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNull(selected)) {
      reset(selected);
    }
  }, [selected]);

  useEffect(() => {
    const cleanup = async () => {
      dispatch(clearSelected());
    };
    cleanup();
  }, []);

  const onSubmitHandler = (values) => {
    isNull(values?.Id)
      ? dispatch(createMapping(values))
      : dispatch(updateMapping(values));
    reset(values);
  };

  return (
    <Spin spinning={loading} delay={500}>
      <Form
        size="small"
        autoComplete="off"
        name="quickEdit"
        onFinish={() => handleSubmit(onSubmitHandler)()}
      >
        <CustomInput
          control={control}
          layout={layout}
          required={true}
          formLabel="Fleet / Policy Number"
          name="FleetOrPolicyNumber"
        />
        <CustomCheckbox
          control={control}
          layout={layout}
          required
          formLabel="New Policy"
          name="NewPolicyIndicator"
        />
        <CustomInput
          control={control}
          layout={layout}
          required={true}
          formLabel="Epic Client Code"
          name="TAMClientCode"
        />
        <CustomInput
          control={control}
          layout={layout}
          required={true}
          formLabel="Name Of Insured"
          name="ICBCNameOfInsured"
        />

        <CustomInput
          control={control}
          layout={layout}
          required={true}
          formLabel="Epic Producer Code"
          name="TAMProducerCode"
        />
        <CustomInput
          control={control}
          layout={layout}
          required={true}
          formLabel="Epic Producer Name"
          name="TAMProducerName"
        />

        <Divider style={{ marginBottom: 12, marginTop: 0 }} />

        <Form.Item {...tailLayout} name="buttons">
          <Row>
            <Col span={24}>
              <Space>
                <Button
                  size="small"
                  type="primary"
                  id="qeSaveButton"
                  htmlType="submit"
                  loading={loading}
                  disabled={!isDirty || !isValid}
                >
                  <SaveOutlined />
                  Save
                </Button>

                {isDirty ? (
                  <Popconfirm
                    title="You have unsaved changes. Discard these changes and close?"
                    onConfirm={() => onCloseHandler()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="dashed">Close</Button>
                  </Popconfirm>
                ) : (
                  <Button onClick={() => onCloseHandler()} type="dashed">
                    Close
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FleetPolicyToClientMappingForm;
