import React, { useEffect, useState } from "react";
import { Button, Space, Modal, Divider } from "antd";
import {
  readAnnouncementForAlert,
  acknowledgeAnnouncement,
} from "../../store/announcement/announcementActions";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormat } from "../shared/formatting";
import dayjs from "dayjs";
import { announcementType } from "./announcementType";
import { WarningIcon, InfoIcon, ErrorIcon } from "./announcementIcons";

const AnnouncementModal = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    dispatch(readAnnouncementForAlert());
  }, []);

  const selectedForAlert = useSelector(
    (state) => state.announcement.selectedForAlert
  );

  return (
    <>
      {selectedForAlert && (
        <Modal
          open={isModalOpen}
          title={
            selectedForAlert.Type === announcementType.Error ? (
              <Space>
                <ErrorIcon />
                {selectedForAlert.Subject}
              </Space>
            ) : selectedForAlert.Type === announcementType.Warning ? (
              <Space>
                <WarningIcon />
                {selectedForAlert.Subject}
              </Space>
            ) : (
              <Space>
                <InfoIcon />
                {selectedForAlert.Subject}
              </Space>
            )
          }
          footer={
            <>
              {selectedForAlert.Link && (
                <Button
                  type="link"
                  onClick={() =>
                    window.open(
                      selectedForAlert.Link,
                      selectedForAlert.LinkText
                    )
                  }
                  style={{ padding: 2 }}
                >
                  {selectedForAlert.LinkText
                    ? selectedForAlert.LinkText
                    : selectedForAlert.Link}
                </Button>
              )}
              <Button
                type="primary"
                onClick={() => {
                  setIsModalOpen(false);
                  dispatch(acknowledgeAnnouncement(selectedForAlert.Id));
                }}
              >
                {" "}
                Got It!{" "}
              </Button>
            </>
          }
          closable={false}
        >
          <Divider style={{ margin: 4 }}></Divider>
          {`[${dayjs(selectedForAlert.AnnounceOnDate).format(
            dateTimeFormat
          )}]: ${selectedForAlert.Description}`}

          <Divider style={{ margin: 4 }}></Divider>
        </Modal>
      )}
    </>
  );
};

export default AnnouncementModal;
