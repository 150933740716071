import React from "react";
import { Layout } from "antd";
import "./MainLayout.css";

const { Content } = Layout;

const LayoutUserLoggedOut = (props) => {
  return (
    <Layout style={{ height: "100%" }}>
      <Content className="logged-out-content">{props.children}</Content>
    </Layout>
  );
};

export default LayoutUserLoggedOut;
