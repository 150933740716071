import {
  EditOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Empty,
  Form,
  Row,
  Space,
} from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedRenewalDocumentsRows,
  listOnlineRenewals,
  setSelectedRenewalDocumentsRows,
} from "../../../store/onlineRenewals/onlineRenewalsActions";
import { setTablePreserveOnReload } from "../../../store/ui/uiActions";
import { exportDataToExcel } from "../../shared/exportToExcel";
import AgencyMultiSelectEx from "../../shared/form/AgencyMultiSelectEx";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toEndOfDay, toStartOfDay } from "../../shared/formatting";
import { agenciesListEqual } from "../../shared/sorting";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { tableNames } from "../../shared/table/tableNames";
import OnlineRenewalsBulkUpdateForm from "./OnlineRenewalsBulkUpdateForm";
import { onlineRenewalsColumns } from "./onlineRenewalsColumns";
import { onlineRenewalsSchema } from "./onlineRenewalsSchemas";

//
const initialValues = {
  AgencyKeys: [],
  FixedDateRange: fixedDateRanges.LAST_X_DAYS,
  TransactionDate: ["", ""],
  ShowOpenDocuments: true,
  ShowClosedDocuments: true,
  NumberOfDays: 10,
};

const firstLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 5 },
    xl: { span: 5 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 19 },
    xl: { span: 19 },
    xxl: { span: 19 },
  },
};

const secondLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 5 },
    xl: { span: 7 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 19 },
    xl: { span: 17 },
    xxl: { span: 19 },
  },
};

const OnlineRenewalsForm = ({ agencies }) => {
  // Form
  const {
    reset,
    control,
    setValue,
    trigger,
    getValues,
    formState: { defaultValues, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(onlineRenewalsSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [viewDetails, setViewDetails] = useState(false);
  const timerRef = useRef(null);
  const preserveSelectedKeys = useRef(false);

  //
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector((state) => state.onlineRenewals.loading);
  const list = useSelector((state) => state.onlineRenewals.data);
  const selectedRows = useSelector(
    (state) => state.onlineRenewals.selectedRows
  );

  //
  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      resetFieldsToDefault();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    if (list != null) {
      if (preserveSelectedKeys.current === true) {
        preserveSelectedKeys.current = false;
      } else {
        dispatch(clearSelectedRenewalDocumentsRows());
      }
    }
  }, [list]);

  //
  const resetFieldsToDefault = () => {
    var initialAgency = getInitialAgency(homeAgency, agencies);
    var newValues = {
      ...initialValues,
      AgencyKeys: [initialAgency],
      TransactionDate: [
        toStartOfDay(dayjs().endOf("day").subtract(9, "days")),
        toEndOfDay(dayjs().endOf("day")),
      ],
    };
    reset(newValues);
    trigger();
    refreshData(newValues);
  };

  const refreshData = (values, preserveKeys) => {
    // Clear the previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (preserveKeys === true) {
      preserveSelectedKeys.current = true;
    }

    //
    dispatch(listOnlineRenewals(values));
  };

  const dispatchWithDelay = () => {
    // Clear the previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Schedule a new dispatch after 750 milliseconds
    timerRef.current = setTimeout(() => {
      trigger().then((e) => e && dispatch(listOnlineRenewals(getValues())));
    }, 750);
  };

  const viewDetailsOnCloseHandler = (shouldReload) => {
    setViewDetails(false);
    if (shouldReload === true) {
      dispatch(setTablePreserveOnReload(tableNames.OnlineRenewals, true));
      refreshData(getValues(), true);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    dispatch(setSelectedRenewalDocumentsRows(selectedRowKeys));
  };

  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.OnlineRenewals)
  );

  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const [isExporting, setIsExporting] = useState(false);

  const exportTableData = () => {
    setIsExporting(true);
    exportDataToExcel(
      tableData?.extra.currentDataSource,
      [
        "CompletionReasonKey",
        "AgencyKey",
        "DocumentKey",
        "AgencyNumber",
        "AgencyName",
        "Id",
        "DocumentKey",
      ],
      maskSensitiveData,
      "OnlineRenewalTransactions.csv"
    );
    setIsExporting(false);
  };

  const tableCurrentDataSource = tableData?.extra?.currentDataSource;

  useEffect(() => {
    //if a filter is applied when multiple rows selected, set selectedRows only for the records visible on ui
    let currentDataSourceSelectedRowKeys = [];
    if (
      !isEmpty(tableCurrentDataSource) &&
      list?.length !== tableCurrentDataSource?.length
    ) {
      const currentDataSourceRowKeys = tableCurrentDataSource.map((r) => r.Id);
      currentDataSourceSelectedRowKeys = selectedRows.filter((r) =>
        currentDataSourceRowKeys.includes(r)
      );
    }
    dispatch(setSelectedRenewalDocumentsRows(currentDataSourceSelectedRowKeys));
  }, [tableCurrentDataSource, list]);

  return (
    <div>
      <Drawer
        title={
          <>
            <FileTextOutlined />
            {` Bulk Update ${selectedRows.length.toString()} ${
              selectedRows.length === 1 ? "Document" : "Documents"
            }`}
          </>
        }
        width={400}
        onClose={() => viewDetailsOnCloseHandler(false)}
        open={viewDetails}
        bodyStyle={{ paddingTop: 4 }}
        destroyOnClose={true}
      >
        <OnlineRenewalsBulkUpdateForm
          onCloseHandler={viewDetailsOnCloseHandler}
        />
      </Drawer>
      <Form
        size="small"
        autoComplete="off"
        onFinish={() => {}}
        style={{ marginTop: "16px" }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12}>
            <AgencyMultiSelectEx
              layout={firstLayout}
              formLabel="Agency"
              required={true}
              agencies={currentAgencies}
              isLoading={isEmpty(defaultValues.AgencyKeys)}
              setValue={setValue}
              control={control}
              name="AgencyKeys"
              onChange={(newAgencyKeys) => {
                if (newAgencyKeys != null && newAgencyKeys.length > 0) {
                  const currentValues = getValues();
                  refreshData({
                    ...currentValues,
                    AgencyKeys: newAgencyKeys,
                  });
                }
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={12}>
            <AdvancedRangePicker
              formItem={{
                layout: secondLayout,
                required: true,
                label: "Transaction Date",
              }}
              control={control}
              setValue={setValue}
              allowClear={false}
              disableFutureDates={true}
              name="TransactionDate"
              format={dateFormat}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
              afterChange={() => dispatchWithDelay()}
            />
            <Form.Item
              {...secondLayout}
              label="Show"
              name="ICBC Review Status"
              required
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Space direction="vertical">
                <Controller
                  control={control}
                  name="ShowOpenDocuments"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        const currentValues = getValues();
                        const openDocuments = e.target.checked;
                        const closedDocuments =
                          !currentValues.ShowClosedDocuments &&
                          !e.target.checked
                            ? true
                            : currentValues.ShowClosedDocuments;

                        setValue("ShowOpenDocuments", openDocuments, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        if (
                          currentValues.ShowClosedDocuments != closedDocuments
                        ) {
                          setValue("ShowClosedDocuments", closedDocuments, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }

                        refreshData({
                          ...currentValues,
                          ShowOpenDocuments: openDocuments,
                          ShowClosedDocuments: closedDocuments,
                        });
                      }}
                      inputRef={field.ref}
                      name="ShowOpenDocuments"
                    >
                      Open
                    </Checkbox>
                  )}
                />
                <Controller
                  control={control}
                  name="ShowClosedDocuments"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      name="ShowClosedDocuments"
                      onChange={(e) => {
                        const currentValues = getValues();
                        const closedDocuments = e.target.checked;
                        const openDocuments =
                          !currentValues.ShowOpenDocuments && !e.target.checked
                            ? true
                            : currentValues.ShowOpenDocuments;

                        setValue("ShowClosedDocuments", closedDocuments, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        if (currentValues.ShowOpenDocuments != openDocuments) {
                          setValue("ShowOpenDocuments", openDocuments, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }

                        refreshData({
                          ...currentValues,
                          ShowOpenDocuments: openDocuments,
                          ShowClosedDocuments: closedDocuments,
                        });
                      }}
                      inputRef={field.ref}
                    >
                      Closed
                    </Checkbox>
                  )}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
      <Row style={{ paddingTop: "16px" }}>
        <Col>
          <SearchableTable
            tableName={tableNames.OnlineRenewals}
            keyColumn="DocumentKey"
            scroll={{ y: 550, x: 1805 }}
            sticky
            dataSource={list}
            columns={onlineRenewalsColumns()}
            loading={loading}
            size="small"
            buttons={[
              <Button
                size={"small"}
                disabled={!isValid || isExporting}
                onClick={() => {
                  refreshData(getValues());
                }}
              >
                <ReloadOutlined />
                Refresh Table
              </Button>,
              <Button
                type="primary"
                size={"small"}
                disabled={isEmpty(selectedRows) || isExporting}
                loading={false}
                onClick={() => setViewDetails(true)}
              >
                <EditOutlined />
                Bulk Update
              </Button>,
              <Button
                size={"small"}
                disabled={!isValid}
                loading={isExporting}
                onClick={() => exportTableData()}
              >
                <FileExcelOutlined />
                Export
              </Button>,
            ]}
            rowSelection={{
              columnWidth: 30,
              selectedRowKeys: selectedRows,
              onChange: onSelectChange,
              fixed: true,
              hideSelectAll: false,
            }}
            rowKey={(r) => r.Id}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No data"}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default OnlineRenewalsForm;
