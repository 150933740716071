export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const shortLabelLongInputLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const shortInputLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6 },
};

export const longLabelLayout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 8 },
};
export const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const toTheRightLayout = {
  wrapperCol: { offset: 16, span: 8 },
};
