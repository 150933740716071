const genericActionType = {
  LIST: "List",
  READ: "Read",
  UPDATE: "Update",
  CREATE: "Create",
  DELETE: "Delete",
  LIST_COMPACT: "ListCompact",
  FIND: "Find",
};

export default genericActionType;
