import { Button, Card, Col, Form, Row, Space } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedAction,
  listAgenciesAction,
} from "../../store/agency/agencyActions";
import { UndoOutlined, SaveOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { setTablePreserveOnReload } from "../../store/ui/uiActions";
import { BackButton } from "../shared/BackButton";
import CustomCheckbox from "../shared/form/reactHookForm/CustomCheckbox";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import CustomInputPassword from "../shared/form/reactHookForm/CustomInputPassword";
import { tableNames } from "../shared/table/tableNames";
import agencySchema from "./agencySchema";

export const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const AgencyForm = ({ initialValues, onSubmitHandler, isNew = false }) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(agencySchema),
  });

  //
  const dispatch = useDispatch();

  //
  const operationResult = useSelector((state) => state.agency.operationResult);
  const loading = useSelector((state) => state.agency.loading);
  const selected = useSelector((state) => state.agency.selected);
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const displayFleetGarageCommission = useSelector(
    (state) => state.actor.details.data.DisplayFleetGarageCommission
  );
  const isAdmin = useSelector((state) => state.actor.details.data.IsAdmin);

  //
  useEffect(() => {
    if (operationResult != null && operationResult.Outcome === "Success") {
      reset(selected);
    }
  }, [operationResult]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedAction());
    };
  }, []);

  //
  const parentRefreshHandler = () => {
    dispatch(setTablePreserveOnReload(tableNames.Agencies, true));
    brokerId && dispatch(listAgenciesAction(brokerId));
  };

  const onSubmitHandlerEx = (values) => {
    onSubmitHandler(values);
  };

  return (
    <Form onFinish={() => handleSubmit(onSubmitHandlerEx)()} size="small">
      <Row style={{ paddingBottom: 4 }}>
        <Col span={16}>
          <Row>
            <Col>
              <Space>
                <BackButton
                  onGoBackHandler={() => {
                    parentRefreshHandler();
                    dispatch(clearSelectedAction());
                  }}
                  isDirty={isDirty}
                />
                <Button
                  size="small"
                  disabled={!isDirty || !isValid}
                  loading={loading}
                  autoFocus={true}
                  id="processButton"
                  type="primary"
                  htmlType="submit"
                >
                  <SaveOutlined />
                  Save
                </Button>

                <Button
                  size="small"
                  type="dashed"
                  disabled={!isDirty || loading}
                  onClick={() => {
                    reset();
                    trigger();
                  }}
                >
                  <UndoOutlined />
                  Reset Values
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ paddingBottom: 16 }}>
        <Col span={24}>
          <Card
            title="Agency Information"
            size="small"
            bordered={true}
            style={{ marginTop: 4 }}
          >
            <CustomInput
              control={control}
              layout={layout}
              hasFeedback={isAdmin === true}
              required={true}
              disabled={isAdmin !== true}
              formLabel="Agency Number"
              name="AgencyNumber"
            />

            <CustomInput
              control={control}
              layout={layout}
              hasFeedback={true}
              required={true}
              formLabel="Agency Name"
              name="AgencyName"
            />

            {displayFleetGarageCommission && (
              <Row>
                <Col span={8}>
                  <CustomInput
                    control={control}
                    layout={{
                      labelCol: { span: 15 },
                      wrapperCol: { span: 8 },
                    }}
                    hasFeedback={true}
                    formLabel="EPIC Branch Number"
                    name="TAMBranchNumber"
                  />
                </Col>
                <Col span={8}>
                  <CustomInput
                    control={control}
                    layout={{
                      labelCol: { span: 8 },
                      wrapperCol: { span: 16 },
                    }}
                    hasFeedback={true}
                    formLabel="EPIC Branch Name"
                    name="TAMBranchName"
                  />
                </Col>
              </Row>
            )}
            <CustomInput
              control={control}
              layout={layout}
              hasFeedback={true}
              required={true}
              formLabel="Address 1"
              name="Address1"
            />

            <CustomInput
              control={control}
              layout={layout}
              hasFeedback={true}
              formLabel="Address 2"
              name="Address2"
            />

            <Row>
              <Col span={12}>
                <CustomInput
                  control={control}
                  layout={{ labelCol: { span: 10 }, wrapperCol: { span: 14 } }}
                  hasFeedback={true}
                  required={true}
                  formLabel="City"
                  name="City"
                />
              </Col>
              <Col span={5}>
                <CustomInput
                  control={control}
                  layout={{ labelCol: { span: 17 }, wrapperCol: { span: 7 } }}
                  hasFeedback={true}
                  required={true}
                  formLabel="Province"
                  name="StateProvinceCode"
                />
              </Col>
              <Col span={7}>
                <CustomInput
                  control={control}
                  layout={{ labelCol: { span: 15 }, wrapperCol: { span: 9 } }}
                  hasFeedback={true}
                  required={true}
                  formLabel="Postal Code"
                  name="ZipPostalCode"
                />
              </Col>
            </Row>

            <CustomCheckbox
              control={control}
              layout={layout}
              hasFeedback={true}
              required={false}
              formLabel="Active"
              name="Active"
            />

            <CustomCheckbox
              formStyle={{ marginBottom: 8 }}
              control={control}
              layout={layout}
              hasFeedback={true}
              required={false}
              formLabel="Mailing Labels Attributes"
              name="ShowExpiryDateOnLabels"
            >
              Show Expiry Date
            </CustomCheckbox>

            <Row>
              <Col offset={5} span={19}>
                <CustomCheckbox
                  formStyle={{ marginBottom: 8 }}
                  control={control}
                  layout={layout}
                  hasFeedback={true}
                  required={true}
                  formLabel=""
                  name="ShowPlateNumberOnLabels"
                >
                  Show Plate Number
                </CustomCheckbox>
              </Col>
            </Row>

            <Row>
              <Col offset={5} span={19}>
                <CustomCheckbox
                  control={control}
                  layout={layout}
                  hasFeedback={true}
                  required={true}
                  formLabel=""
                  name="ShowLessorNameOnLabels"
                >
                  Show Lessor Name
                </CustomCheckbox>
              </Col>
            </Row>
          </Card>
          <Card
            title="ICBC Data Download"
            size="small"
            bordered={true}
            style={{ marginTop: 8 }}
          >
            <CustomInput
              control={control}
              layout={{
                labelCol: { span: 5 },
                wrapperCol: { span: 11 },
              }}
              hasFeedback={true}
              formLabel="User Id"
              name="SmuserId"
              afterChange={() => {
                trigger("ExtranetPassword");
              }}
            />

            <CustomInputPassword
              control={control}
              layout={{
                labelCol: { span: 5 },
                wrapperCol: { span: 11 },
              }}
              hasFeedback={true}
              formLabel="Password"
              name="ExtranetPassword"
              afterChange={() => {
                trigger("SmuserId");
              }}
            />

            <CustomCheckbox
              control={control}
              layout={layout}
              hasFeedback={true}
              required={false}
              formLabel="Enable Automated Daily Downloads"
              name="AutomatedDownload"
              afterChange={() => {
                trigger(["SmuserId", "ExtranetPassword"]);
              }}
            />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default AgencyForm;
