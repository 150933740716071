const Actions = {
  READ_ACTOR: "READ_ACTOR",
  READ_ACTOR_SUCCEEDED: "READ_ACTOR_SUCEEDED",
  SET_SESSION_EXPIRING: "SET_SESSION_EXPIRING",
  SET_DARK_MODE: "SET_DARK_MODE",
  CLEAR_ACTOR_DATA: "CLEAR_ACTOR_DATA",
  CLEAR_SWITCH_ACCOUNT_OPERATION_RESULT:
    "CLEAR_SWITCH_ACCOUNT_OPERATION_RESULT",
  SWITCH_SUPPORT_ACCOUNT: "SWITCH_SUPPORT_ACCOUNT",
  SWITCH_SUPPORT_ACCOUNT_SUCCEEDED: "SWITCH_SUPPORT_ACCOUNT_SUCCEEDED",
};

export default Actions;
