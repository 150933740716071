import dayjs from "dayjs";
import * as yup from "yup";
import {
  dateRangeRequiredMessage,
  fleetGarageCommaListMessage,
  fleetGarageCommaListRegexOrEmptyString,
  lessOrEqualOneYearMessage,
  requiredMessage,
} from "../../components/shared/form/formValidatonRules";

export const productionReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  TransactionDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  SortGroupFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  Include: yup
    .boolean()
    .when(
      [
        "IncludeCancellations",
        "IncludeNonFleetNonGarage",
        "IncludeFleetGarage",
      ],
      {
        is: false,
        then: yup
          .boolean()
          .required("At least one of the options is required."),
      }
    ),
  FleetGaragePolicyNumbers: yup
    .string()
    .matches(
      fleetGarageCommaListRegexOrEmptyString,
      fleetGarageCommaListMessage
    ),
});
