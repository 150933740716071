import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import { clearOnlineRenewals } from "../../../store/onlineRenewals/onlineRenewalsActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { UpdateDocuments } from "../../shared/securityRoleTasks";
import OnlineRenewalsForm from "./OnlineRenewalsForm";

const OnlineRenewals = () => {
  //
  const dispatch = useDispatch();

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const agenciesLoading = useSelector((state) => state.agency.loading);

  //
  const pageLoaded = brokerId != null && agencies != null && !agenciesLoading;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, UpdateDocuments));
    }
  }, [brokerId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearListCompactAction());
      dispatch(clearOnlineRenewals());
    };
  }, [dispatch]);

  return (
    <PermissionWrapper entityName="OnlineRenewals">
      <Spin spinning={!pageLoaded} delay={500}>
        {pageLoaded && <OnlineRenewalsForm agencies={agencies} />}
      </Spin>
    </PermissionWrapper>
  );
};
export default OnlineRenewals;
