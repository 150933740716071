import { FilterOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Space } from "antd";
import { get, isEmpty } from "lodash";

export const getCurrencySearchProps = (dataIndex, title, columnRefArray, i) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Row gutter={8}>
          <Col>
            <Select
              value={selectedKeys[0]?.type ?? 1}
              style={{
                width: 130,
              }}
              onChange={(e) => {
                const selectedKeysValue = selectedKeys[0]?.value ?? "";
                setSelectedKeys([{ value: selectedKeysValue, type: e }]);
              }}
            >
              <Select.Option value={1}>Equal to</Select.Option>
              <Select.Option value={2}>Greater than</Select.Option>
              <Select.Option value={3}>Less than</Select.Option>
            </Select>
          </Col>
          <Col>
            <Input
              ref={(el) => (columnRefArray.current[i] = el)}
              placeholder={title}
              value={selectedKeys[0]?.value ?? ""}
              onChange={(e) => {
                const selectedKeysType = selectedKeys[0]?.type ?? 1;
                setSelectedKeys(
                  e.target.value
                    ? [{ value: e.target.value, type: selectedKeysType }]
                    : [{ value: "", type: selectedKeysType }]
                );
              }}
              onKeyDown={(e) => e.stopPropagation()}
              onPressEnter={() => {
                confirm();
              }}
              style={{ width: 130, marginBottom: 8, display: "block" }}
            />
          </Col>
        </Row>

        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([{ value: "", type: 1 }]);
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    onFilter: (v, record) => {
      var value = v?.value ?? "";
      const comparisonType = v?.type ?? 1;
      if (value == null || isEmpty(value)) {
        return true;
      } else if (comparisonType == 2) {
        // Greater than
        var actual = parseFloat(get(record, dataIndex).toString());
        value = value.replace(/\$\s?|(,*)/g, "");
        var compar = parseFloat(value);
        if (actual == null || compar == null) {
          return false;
        }
        return actual > compar;
      } else if (comparisonType == 3) {
        // Less than
        var actual = parseFloat(get(record, dataIndex).toString());
        value = value.replace(/\$\s?|(,*)/g, "");
        var compar = parseFloat(value);
        if (actual == null || compar == null) {
          return false;
        }
        return actual < compar;
      } else {
        // Equal to
        var actual = parseFloat(get(record, dataIndex).toString());
        value = value.replace(/\$\s?|(,*)/g, "");
        var compar = parseFloat(value);
        return actual == compar;
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (columnRefArray.current[i] != null) {
            columnRefArray.current[i].select();
          }
        }, 100);
      }
    },
  };
};
