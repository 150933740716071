import { Actions } from "../document/documentConstants";
import produce from "immer";

const initialState = {
  data: null,
  selected: null,
  isDeleted: null,
  searchValues: null,
  refreshListFlag: false,
  operationResult: null,
  loading: false,
  selectedLoading: false,
  selectedWarning: { message: null, ignore: false },
  selectedUi: { expandSections: null },
  manualSettings: { type: null, agencyKey: "", agencyNumberAndName: null },
  transactionTypes: null,
  policyProducts: null,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_DOCUMENTS:
        draft.loading = true;
        return draft;
      case Actions.READ_DOCUMENT_FOR_QUICK_EDIT:
      case Actions.READ_DOCUMENT:
      case Actions.UPDATE_QUICK_EDIT_CHANGES:
      case Actions.UPDATE_DOCUMENT:
      case Actions.CREATE_CERTIFICATE_COPY:
      case Actions.CREATE_DOCUMENT_BY_PRODUCT:
      case Actions.CREATE_DOCUMENT_BY_PRODUCT_KEY:
        draft.selectedLoading = true;
        return draft;
      case Actions.LIST_DOCUMENTS_SUCCEEDED:
        draft.loading = false;
        draft.data = action.payload;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.LIST_DOCUMENTS_FAILED:
        draft.loading = false;
        draft.data = null;
        draft.operationResult = {
          Outcome: "Error",
          Message:
            "Something went wrong while executing this request. If this problem persists, please attempt logging out and then logging back in.",
        };
        return draft;
      case Actions.UPDATE_QUICK_EDIT_CHANGES_SUCCEEDED:
      case Actions.UPDATE_DOCUMENT_SUCCEEDED:
        draft.selected =
          action.payload.Operation.Outcome === "Success"
            ? action.payload.Data
            : draft.selected;
        draft.operationResult = action.payload.Operation;
        draft.selectedLoading = false;
        draft.selectedWarning =
          action.payload.Operation.Outcome === "Warning"
            ? { message: action.payload.Operation.Message, ignore: false }
            : { message: null, ignore: false };
        return draft;
      case Actions.CREATE_CERTIFICATE_COPY_SUCCEEDED:
        draft.selected =
          action.payload.Operation.Outcome === "Success" || "Information"
            ? action.payload.Data
            : draft.selected;
        draft.operationResult =
          action.payload.Operation.Outcome === "Error" || "Information"
            ? action.payload.Operation
            : null;
        draft.selectedLoading = false;
        draft.selectedWarning =
          action.payload.Operation.Outcome === "Warning"
            ? { message: action.payload.Operation.Message }
            : { message: null, ignore: false };
        return draft;
      case Actions.READ_DOCUMENT_FOR_QUICK_EDIT_SUCCEEDED:
      case Actions.READ_DOCUMENT_SUCCEEDED:
      case Actions.CREATE_DOCUMENT_BY_PRODUCT_SUCCEEDED:
      case Actions.CREATE_DOCUMENT_BY_PRODUCT_KEY_SUCCEEDED:
        draft.selected = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.selectedLoading = false;
        return draft;
      case Actions.DELETE_DOCUMENT_SUCCEEDED:
        draft.operationResult = action.payload.Operation;
        draft.isDeleted = action.payload.Operation.Outcome == "Success";
        return draft;
      case Actions.SET_SELECTED:
        draft.selected = action.payload.Data;
        return draft;
      case Actions.LIST_TRANSACTION_TYPES_SUCCEEDED:
        let options = action.payload ?? [];
        options.forEach((option) => {
          if (option.value === 0) {
            option.label = "(Select)";
          }
        });
        draft.transactionTypes = options;
        return draft;
      case Actions.LIST_POLICY_PRODUCTS_SUCCEEDED:
        draft.policyProducts = action.payload;
        return draft;
      case Actions.CLEAR_LIST:
        draft.data = null;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        draft.isDeleted = null;
        return draft;
      case Actions.CLEAR_SELECTED:
        draft.operationResult = null;
        draft.selected = null;
        draft.selectedWarning = { message: null, ignore: false };
        draft.selectedInformation = null;
        draft.selectedUi = { expandSections: null };
        draft.isDeleted = null;
        return draft;
      case Actions.CLEAR_SELECTED_WARNING_MESSAGE:
        draft.selectedWarning.message = null;
        return draft;
      case Actions.SET_IGNORE_SELECTED_WARNING:
        draft.selectedWarning.ignore = action.payload.ignore;
        return draft;
      case Actions.SET_SEARCH_VALUES:
        draft.searchValues = action.payload.searchValues;
        return draft;
      case Actions.SET_MANUAL_SETTINGS:
        draft.manualSettings = action.payload.manualSettings;
        return draft;
      case Actions.CLEAR_MANUAL_SETTINGS:
        draft.manualSettings = { type: null, agencyKey: null };
        return draft;
      case Actions.SET_EXPAND_SECTIONS:
        draft.selectedUi.expandSections = action.payload.expandSections;
        return draft;
      case Actions.SET_REFRESH_LIST_FLAG:
        draft.refreshListFlag = action.payload.Flag;
        return draft;
      default:
        return state;
    }
  });
};
