export const Actions = {
  LIST_COMMISSION_RATES: "LIST_COMMISSION_RATES",
  LIST_COMMISSION_RATES_SUCCEEDED: "LIST_COMMISSION_RATES_SUCCEEDED",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  DELETE_COMMISSION_RATE: "DELETE_COMMISSION_RATE",
  DELETE_COMMISSION_RATE_SUCCEEDED: "DELETE_COMMISSION_RATE_SUCCEEDED",
  READ_COMMISSION_RATE: "READ_COMMISSION_RATE",
  READ_COMMISSION_RATE_SUCCEEDED: "READ_COMMISSION_RATE_SUCCEEDED",
  CLEAR_SELECTED_COMMISSION_RATE: "CLEAR_SELECTED_COMMISSION_RATE",
  UPDATE_COMMISSION_RATE: "UPDATE_COMMISSION_RATE",
  UPDATE_COMMISSION_RATE_SUCCEEDED: "UPDATE_COMMISSION_RATE_SUCCEEDED",
  CREATE_COMMISSION_RATE: "CREATE_COMMISSION_RATE",
  CREATE_COMMISSION_RATE_SUCCEEDED: "CREATE_COMMISSION_RATE_CODE_SUCCEEDED",
  LIST_PRODUCER_1_CODES: "LIST_PRODUCER_1_CODES",
  LIST_PRODUCER_1_CODES_SUCCEEDED: "LIST_PRODUCER_1_CODES_SUCCEEDED",
  LIST_PRODUCER_2_CODES: "LIST_PRODUCER_2_CODES",
  LIST_PRODUCER_2_CODES_SUCCEEDED: "LIST_PRODUCER_2_CODES_SUCCEEDED",
};
