export class SortGroupField {
  constructor(
    name = "",
    sortAscending = true,
    toGroup = false,
    newPage = false
  ) {
    this.Name = name;
    this.SortAscending = sortAscending;
    this.ToGroup = toGroup;
    this.NewPage = newPage;
  }
}
