import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Input, Popconfirm, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  listDocumentsAvailableToAddAction,
  removeDocumentFromBatchAction,
  setFirstElementKeyAction,
} from "../../../store/batching/batchingActions";
import {
  documentTransformWrapper,
  readForQuickEditAction,
  transformDocumentInput,
} from "../../../store/document/documentActions";
import {
  fetchReport,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import { setTableRowBookmark } from "../../../store/ui/uiActions";
import {
  PreservableStateLink,
  usePreservableNavigate,
} from "../../shared/PreservableStatePageWrapper";
import { TableActionsDropdown } from "../../shared/table/TableActionsDropdown";
import { columnTypes } from "../../shared/table/columnTypes";
import "./BatchTable.css";
import BatchTableActions from "./BatchTableActions";
const shortColumn = 80;
const mediumColumn = 100;
const mediumPlusColumn = 150;
const longColumn = 220;
const iconColumn = 30;

const NewBatchDocumentsActionsMenu = React.memo(
  ({ record, setQuickEdit, saveChanges }) => {
    const dispatch = useDispatch();
    const preservableNavigate = usePreservableNavigate();

    const items = [
      {
        key: "1",
        onClick: () => {
          dispatch(readForQuickEditAction(record.DocumentKey));
          setQuickEdit(true);
        },
        icon: <EditOutlined />,
        label: "Quick Edit",
      },
      { type: "divider" },
      {
        key: "2",
        icon: <FileOutlined />,
        onClick: () => {
          saveChanges();
          preservableNavigate(
            `/documents/updatedocumentform/${record.DocumentKey}`
          );
        },
        label: "View Details",
      },
      {
        key: "3",
        icon: <FileOutlined />,
        onClick: () =>
          window.open(
            `${process.env.REACT_APP_BASENAME}documents/updatedocumentform/${record.DocumentKey}`,
            `${record.DocumentKey}`
          ),
        label: "View Details in New Tab",
      },
      { type: "divider" },
      {
        key: "4",
        icon: <PrinterOutlined />,
        onClick: () => {
          dispatch(
            documentTransformWrapper(record.DocumentKey, (val) =>
              dispatch(
                fetchReport(
                  transformDocumentInput(val),
                  entityType.DOCUMENT,
                  "PrintDocument"
                )
              )
            )
          );
          dispatch(setReportModalVisibility(true));
        },
        label: "Preview",
      },
    ];

    return <TableActionsDropdown items={items} />;
  },
  (prevProps, nextProps) =>
    prevProps.record.DocumentKey === nextProps.record.DocumentKey
);

const BatchDocumentsActionsMenu = ({
  record,
  setQuickEdit,
  isUserAllowedToSave,
}) => {
  const dispatch = useDispatch();
  const preservableNavigate = usePreservableNavigate();

  const removeFromBatchItem = {
    key: "5",
    disabled: !isUserAllowedToSave,
    icon: <DeleteOutlined />,
    label: (
      <Popconfirm
        placement="bottom"
        title="Remove selected document?"
        onConfirm={() => {
          dispatch(
            removeDocumentFromBatchAction(
              record.DocumentBatchKey,
              record.DocumentKey
            )
          )
            .then(
              dispatch(
                listDocumentsAvailableToAddAction(record.DocumentBatchKey)
              )
            )
            .then(handleParentRefresh());
        }}
        okText="Yes"
        cancelText="No"
      >
        Remove from Batch
      </Popconfirm>
    ),
  };

  const userAllowedToViewItems = [
    {
      key: "1",
      onClick: () => {
        dispatch(readForQuickEditAction(record.DocumentKey));
        setQuickEdit(true);
      },
      icon: <EditOutlined />,
      label: "Quick Edit",
    },
    { type: "divider" },
    {
      key: "2",
      icon: <FileOutlined />,
      onClick: () =>
        preservableNavigate(
          `/documents/updatedocumentform/${record.DocumentKey}`
        ),
      label: "View Details",
    },
    {
      key: "3",
      icon: <FileOutlined />,
      onClick: () =>
        window.open(
          `${process.env.REACT_APP_BASENAME}documents/updatedocumentform/${record.DocumentKey}`,
          `${record.DocumentKey}`
        ),
      label: "View Details in New Tab",
    },
    { type: "divider" },
    {
      key: "4",
      icon: <PrinterOutlined />,
      onClick: () => {
        dispatch(
          documentTransformWrapper(record.DocumentKey, (val) =>
            dispatch(
              fetchReport(
                transformDocumentInput(val),
                entityType.DOCUMENT,
                "PrintDocument"
              )
            )
          )
        );
        dispatch(setReportModalVisibility(true));
      },
      label: "Preview",
    },
  ];

  const items = isUserAllowedToSave
    ? [...userAllowedToViewItems, { type: "divider" }, removeFromBatchItem]
    : userAllowedToViewItems;

  return <TableActionsDropdown items={items} />;
};

export const batchColumns = (searchCriteria) => {
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const columns = [
    {
      title: "ICBC Batch",
      dataIndex: "ICBCBatchNo",
      type: columnTypes.TEXT,
      width: 70,
      fixed: "left",
      render: (value, record) => (
        <PreservableStateLink
          to={`/batches/updatebatchform/${record.DocumentBatchKey}`}
        >
          {value}
        </PreservableStateLink>
      ),
    },
    {
      title: "Agency Batch",
      dataIndex: "AgencyBatchNo",
      type: columnTypes.TEXT,
      width: 85,
      fixed: "left",
      render: (value, record) => (
        <PreservableStateLink
          to={`/batches/updatebatchform/${record.DocumentBatchKey}`}
        >
          {value}
        </PreservableStateLink>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "DateSubmitted",
      type: columnTypes.DATE,
      width: 95,
    },
    {
      title: "Doc Count",
      dataIndex: "DocCount",
      type: columnTypes.NUMBER,
      width: shortColumn,
      align: "right",
    },
    {
      title: "Amount Due",
      dataIndex: "TotalAmountDue",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Cash / Cheque",
      dataIndex: "TotalCashChequePayment",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Financed",
      dataIndex: "TotalAmountFinanced",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Debit / Credit",
      dataIndex: "TotalDebitCreditPayment",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Online Credit",
      dataIndex: "TotalOnlineCreditPayment",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Created",
      dataIndex: "DateCreated",
      type: columnTypes.DATE,
      width: 90,
    },
    {
      title: "Created By",
      dataIndex: "UserCreated",
      type: columnTypes.TEXT,
      width: 100,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <BatchTableActions record={record} searchCriteria={searchCriteria} />
      ),
      fixed: "right",
      width: 36,
      align: "center",
    },
  ];
  return columns;
};

export const newBatchDocumentColumns = (
  hideExcluded,
  selectedDocumentKeys,
  documentsItemNumbers,
  setQuickEdit,
  saveChanges
) => {
  const dispatch = useDispatch();
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  return [
    {
      filters: [
        { text: "Included", value: false },
        { text: "Excluded", value: true },
      ],
      type: columnTypes.ACTION_WITHOUT_FILTER,
      key: "DocumentKey",
      defaultFilteredValue: ["Included"],
      filteredValue: ["Included"],
      filterResetToDefaultFilteredValue: true,
      filtered: true,
      filterIcon: <div></div>,
      render: (_, record, index) => {
        if (index == 0) {
          dispatch(setFirstElementKeyAction(record.DocumentKey.toString()));
        }
        return <div className="unique-filter-column"></div>;
      },
      onFilter: (_, record) =>
        !hideExcluded ||
        selectedDocumentKeys.includes(record["DocumentKey"]) === true,
      fixed: "left",
      width: "0.00001%",
    },
    {
      title: "Item",
      dataIndex: "ItemNumber",
      type: columnTypes.NUMBER_NO_FILTER,
      width: 50,
      fixed: "left",
      align: "right",
      render: (text, record, _) => {
        return (
          <Button
            tabIndex="-1"
            type="link"
            size="small"
            style={{ fontSize: "13px" }}
            onClick={() => {
              dispatch(readForQuickEditAction(record.DocumentKey));
              setQuickEdit(true);
            }}
          >
            {documentsItemNumbers[record.DocumentKey] ?? text}
          </Button>
        );
      },
    },
    {
      title: "PC1",
      dataIndex: "ProducerCode1",
      type: columnTypes.TEXT,
      width: 60,
      editable: (record) => record.IsUserAllowedToSave,
      inputProps: {
        name: "ProducerCode1",
        formLabel: "",
        maxLength: 3,
      },
    },
    {
      title: "PC2",
      dataIndex: "ProducerCode2",
      type: columnTypes.TEXT,
      width: 60,
      editable: (record) => record.IsUserAllowedToSave,
      inputProps: {
        name: "ProducerCode2",
        formLabel: "",
        maxLength: 3,
      },
    },
    {
      title: "Timestamp",
      dataIndex: "DCID",
      type: columnTypes.TEXT,
      width: 115,
      inputProps: {
        name: "DCID",
        formLabel: "",
        maxLength: 14,
        initialValue: "9679",
      },
      editable: (record) =>
        record.IsUserAllowedToSave &&
        (!record.IsDownloaded || record.IsFinancial) &&
        record.ProductCode != "APV7",
    },
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      width: 72,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.PolicyNumber != prevRecord.PolicyNumber,
    },
    {
      title: "Product",
      dataIndex: "ProductCode",
      type: columnTypes.TEXT,
      width: 85,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.ProductCode != prevRecord.ProductCode,
    },
    {
      title: "Fleet",
      dataIndex: "FleetNumber",
      type: columnTypes.TEXT,
      width: 65,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.FleetNumber != prevRecord.FleetNumber,
    },
    {
      title: "Reg Num",
      dataIndex: "RegNumber",
      type: columnTypes.TEXT,
      width: 75,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.RegNumber != prevRecord.RegNumber,
    },
    {
      title: "Plate",
      dataIndex: "PlateNumber",
      type: columnTypes.TEXT,
      width: 72,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.PlateNumber != prevRecord.PlateNumber,
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: 210,
      ellipsis: true,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.InsuredName != prevRecord.InsuredName,
    },
    {
      title: "Cash / Cheque",
      dataIndex: "CashChequePayment",
      type: columnTypes.CURRENCY,
      width: 95,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.CashChequePayment != prevRecord.CashChequePayment,
    },
    {
      title: "Debit / Credit",
      dataIndex: "DebitCreditPayment",
      type: columnTypes.CURRENCY,
      width: 95,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.DebitCreditPayment != prevRecord.DebitCreditPayment,
    },
    {
      title: "Online Credit",
      dataIndex: "OnlineCreditPayment",
      type: columnTypes.CURRENCY,
      width: 95,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.OnlineCreditPayment != prevRecord.OnlineCreditPayment,
    },
    {
      title: "Financed",
      dataIndex: "FinancedAmt",
      type: columnTypes.CURRENCY,
      width: 95,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.FinancedAmt != prevRecord.FinancedAmt,
    },
    {
      title: "Amount Due",
      dataIndex: "AmountDue",
      type: columnTypes.CURRENCY,
      width: 95,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.AmountDue != prevRecord.AmountDue,
    },
    {
      title: "Channel",
      dataIndex: "Channel",
      type: columnTypes.TEXT,
      width: mediumColumn,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.Channel != prevRecord.Channel,
    },
    {
      title: "Transaction Date",
      dataIndex: "EntryDateTime",
      type: columnTypes.DATETIME,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.EntryDateTime != prevRecord.EntryDateTime,
      width: 170,
    },
    {
      className: "pinned-style",
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <NewBatchDocumentsActionsMenu
          setQuickEdit={setQuickEdit}
          record={record}
          saveChanges={saveChanges}
        />
      ),
      fixed: "right",
      width: 44,
      align: "center",
    },
  ];
};

export const batchDocumentColumns = (
  setQuickEdit,
  isUserAllowedToSave,
  tableName
) => {
  const dispatch = useDispatch();
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  return [
    {
      title: "Item",
      dataIndex: "ItemNumber",
      type: columnTypes.NUMBER_NO_FILTER,
      width: 50,
      fixed: "left",
      align: "right",
      render: (text, record, _) => (
        <Button
          type="link"
          size="small"
          style={{ fontSize: "13px" }}
          onClick={() => {
            dispatch(readForQuickEditAction(record.DocumentKey));
            setQuickEdit(true);
          }}
          onFocus={() =>
            dispatch(setTableRowBookmark(tableName, record.DocumentKey))
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: "PC1",
      dataIndex: "ProducerCode1",
      type: columnTypes.TEXT,
      width: 60,
    },
    {
      title: "PC2",
      dataIndex: "ProducerCode2",
      type: columnTypes.TEXT,
      width: 60,
    },
    {
      title: "Timestamp",
      dataIndex: "DCID",
      type: columnTypes.TEXT,
      width: 115,
    },
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      width: 72,
    },
    {
      title: "Product",
      dataIndex: "ProductCode",
      type: columnTypes.TEXT,
      width: 85,
    },
    {
      title: "Fleet",
      dataIndex: "FleetNumber",
      type: columnTypes.TEXT,
      width: 65,
    },
    {
      title: "Reg Num",
      dataIndex: "RegNumber",
      type: columnTypes.TEXT,
      width: 75,
    },
    {
      title: "Plate",
      dataIndex: "PlateNumber",
      type: columnTypes.TEXT,
      width: 72,
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: 210,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Channel",
      dataIndex: "Channel",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Cash / Cheque",
      dataIndex: "CashChequePayment",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Debit / Credit",
      dataIndex: "DebitCreditPayment",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Online Credit",
      dataIndex: "OnlineCreditPayment",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Financed",
      dataIndex: "FinancedAmt",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Amount Due",
      dataIndex: "AmountDue",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Transaction Date",
      dataIndex: "EntryDateTime",
      type: columnTypes.DATETIME,
      width: 170,
    },
    {
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <BatchDocumentsActionsMenu
          setQuickEdit={setQuickEdit}
          record={record}
          isUserAllowedToSave={isUserAllowedToSave}
        />
      ),
      fixed: "right",
      width: 36,
      align: "center",
    },
  ];
};

export const multiLicencePoliciesColumns = () => {
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  return [
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      width: 65,
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: longColumn,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Fleet",
      dataIndex: "FleetNumber",
      type: columnTypes.TEXT,
      width: 60,
    },
    {
      title: "Document Count",
      dataIndex: "DocCount",
      type: columnTypes.NUMBER,
      width: 90,
      align: "right",
    },
    {
      title: "Payment Count",
      dataIndex: "PaymentCount",
      type: columnTypes.NUMBER,
      width: 80,
      align: "right",
    },
  ];
};

export const multiLicencePoliciesColumnsEditable = (setQuickEdit) => {
  const dispatch = useDispatch();
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  return [
    {
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <Tooltip title="Quick Edit">
          <Button
            type="link"
            size="small"
            onClick={() => {
              dispatch(readForQuickEditAction(record.DocumentKey));
              setQuickEdit(true);
            }}
            style={{ padding: 0 }}
          >
            <EditOutlined />
          </Button>
        </Tooltip>
      ),
      width: iconColumn,
      fixed: "left",
    },
    {
      title: "Timestamp",
      name: "DCID",
      dataIndex: "DCID",
      //type: columnTypes.TEXT,
      render: (text, record, i) => (
        <Input style={{ border: "none" }} name={`PaymentDocuments.${i}.name`} />
      ),
      width: mediumColumn,
      fixed: "left",
    },
    {
      title: "Transaction Date",
      dataIndex: "EntryDateTime",
      type: columnTypes.DATE,
      width: mediumColumn,
    },
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      width: shortColumn,
    },
    {
      title: "Fleet",
      dataIndex: "FleetNumber",
      type: columnTypes.TEXT,
      width: shortColumn,
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: longColumn,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Cash / Cheque",
      dataIndex: "CashChequePayment",
      type: columnTypes.CURRENCY,
      width: shortColumn,
    },
    {
      title: "Debit / Credit",
      dataIndex: "DebitPayment",
      type: columnTypes.CURRENCY,
      width: shortColumn,
    },
    {
      title: "Financed",
      dataIndex: "FinancedAmt",
      type: columnTypes.CURRENCY,
      width: shortColumn,
    },
    {
      title: "Amount Due",
      dataIndex: "AmountDue",
      type: columnTypes.CURRENCY,
      width: shortColumn,
    },
  ];
};
