import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Popover, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { listUserAgencyRolesAction } from "../../store/user/security/securityActions";
import UserAgencyRole from "./UserAgencyRole";
import { SearchableTable } from "../shared/table/SearchableTable";
import { tableNames } from "../shared/table/tableNames";
import { isArray } from "lodash";

const { Text } = Typography;

const UserAgencyRoles = () => {
  //todo, temp hard code the user id, should be replaced by user id from identity server
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const navigate = useNavigate();
  const selected = useSelector((state) => state.user.general.selected);
  const fullName =
    selected &&
    `${selected.Account.FirstName} ${
      selected.Account.MiddleName ? selected.Account.MiddleName : ""
    } ${selected.Account.LastName}`;
  const [editRole, setEditRole] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [roleName, setRoleName] = useState("");
  let { userKey } = useParams();
  const dispatch = useDispatch();
  const roles = useSelector(
    (state) => state.user.security.selectedUserAgencyRoles
  );
  const loading = useSelector((state) => state.user.security.loading);

  useEffect(() => {
    dispatch(listUserAgencyRolesAction(userKey, brokerId));
  }, [userKey, brokerId, dispatch]);

  const columns = [
    {
      title: "Role",
      dataIndex: "RoleName",
      key: "RoleName",
      width: 160,
    },
    {
      title: "Description",
      dataIndex: "RoleDescription",
      key: "RoleDescription",
      width: 280,
    },
    {
      title: "Tasks",
      dataIndex: "Tasks",
      key: "Tasks",
      render: (_, record) => {
        return record.Tasks != null &&
          isArray(record.Tasks) &&
          record.Tasks.length > 0 ? (
          <Popover
            content={record.Tasks.map((a) => (
              <p>{a}</p>
            ))}
          >
            <Button size="small" type="link" key={`Tasks${record.RoleId}`}>
              {record.Tasks.length}
            </Button>
          </Popover>
        ) : (
          <Button
            size="small"
            type="link"
            disabled
            key={`Tasks${record.RoleId}`}
          >
            0
          </Button>
        );
      },
      width: 70,
    },
    {
      title: "Agencies",
      dataIndex: "Agencies",
      key: "Agencies",
      render: (_, record) => {
        return record.Agencies != null &&
          isArray(record.Agencies) &&
          record.Agencies.length > 0 ? (
          <Popover
            content={record.Agencies.map((a) => (
              <p>{a}</p>
            ))}
          >
            <Button size="small" type="link" key={`Agencies${record.RoleId}`}>
              {record.Agencies.length}
            </Button>
          </Popover>
        ) : (
          <Button
            size="small"
            type="link"
            key={`Agencies${record.RoleId}`}
            disabled
          >
            0
          </Button>
        );
      },
      width: 75,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Button
          style={{ fontSize: "13px" }}
          size="small"
          type="link"
          onClick={() => {
            setEditRole(true);
            setRoleId(record.RoleId);
            setRoleName(record.RoleName);
          }}
        >
          Manage Access
        </Button>
      ),
      width: 115,
    },
  ];

  return (
    <div>
      <Drawer
        title={
          <Space>
            <UserOutlined />
            {`${fullName}: ${roleName} Role`}
          </Space>
        }
        width={720}
        onClose={() => {
          setEditRole(false);
          dispatch(listUserAgencyRolesAction(userKey, brokerId));
        }}
        open={editRole}
      >
        <UserAgencyRole
          roleId={roleId}
          userKey={userKey}
          parentRefreshHandler={() =>
            dispatch(listUserAgencyRolesAction(userKey, brokerId))
          }
        />
      </Drawer>
      <Row style={{ paddingBottom: 8 }}>
        <Col>
          <Space>
            <Button
              onClick={() => navigate("/users")}
              size="small"
              type="dashed"
            >
              <TeamOutlined />
              Go To Users
            </Button>
            <Button
              onClick={() => navigate("/legacyUsers")}
              size="small"
              type="dashed"
            >
              <TeamOutlined />
              Go To Legacy Users
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SearchableTable
            tableName={tableNames.UserAgencyRoles}
            hideClearFiltersButtons={true}
            bordered={true}
            pagination={false}
            scroll={{ y: 550, x: 705 }}
            dataSource={roles}
            columns={columns}
            loading={loading}
            size="small"
            rowKey={(r) => r.RoleId}
            title={() => (
              <Space>
                <UserOutlined />
                <Text strong>{`${fullName} Security Roles`}</Text>
              </Space>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
export default UserAgencyRoles;
