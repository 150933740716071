import { customGet } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { Actions } from "./rateClassConstants";

export const listRateClassesAction = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_RATE_CLASSES });

  const payload = await customGet(
    entityType.RATE_CLASS,
    genericActionType.LIST
  );

  dispatch({ type: Actions.LIST_RATE_CLASSES_SUCCEEDED, payload });
};
