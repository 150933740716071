import { Select } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

const ProducerSingleSelect = (props) => {
  const { data, loading, control } = props;

  //
  const [options, setOptions] = useState([]);

  // The producer codes list comes from redux
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  useEffect(() => {
    // Update the list of options to choose from in the <Select /> component
    var updatedOptions = !isEmpty(data)
      ? data.map((p) => {
          return {
            label:
              p.Description != null ? (
                maskSensitiveData === true ? (
                  <div style={{ display: "inline" }}>
                    {p.ProducerCode} -
                    <div
                      style={{
                        color: "transparent",
                        textShadow: "0 0 8px #000",
                        userSelect: "none",
                        display: "inline",
                      }}
                    >
                      {" "}
                      {p.Description}
                    </div>
                  </div>
                ) : (
                  `${p.ProducerCode} - ${p.Description}`
                )
              ) : (
                `${p.ProducerCode}`
              ),
            value: p.ProducerCodeKey,
            filter:
              p.Description != null
                ? `${p.ProducerCode} - ${p.Description}`
                : `${p.ProducerCode}`,
          };
        })
      : [];
    updatedOptions.unshift({
      label: "(Any Producer Code)",
      value: 0,
      filter: "(Any Producer Code)",
    });
    setOptions(updatedOptions);
  }, [data]);

  return (
    <>
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Controller
          control={control}
          name={props.name}
          render={({ field }) => (
            <Select
              {...field}
              name={props.name}
              id={props.id}
              showSearch
              optionFilterProp="filter"
              filterOption={(input, option) => {
                const filter = option.filter ? option.filter : option.children;
                return filter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              options={options}
              loading={loading}
              virtual={false}
            />
          )}
        />
      </span>
    </>
  );
};
export default ProducerSingleSelect;
