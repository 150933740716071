import {
  findCurrentAgencies,
  findProducerNames,
} from "../report/reportActions";

export const transformDataCollectionInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const Producer1Names = findProducerNames(state, 1, values.ProducerCode1Keys);
  const Producer2Names = findProducerNames(state, 2, values.ProducerCode2Keys);
  const transactionDates = [
    values.TransactionDate[0],
    values.TransactionDate[1],
  ];
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FileName: `CustomerDataCollection_${agency.Number}`,
    TransactionDate: transactionDates,
    FirstProducer: Producer1Names,
    SecondProducer: Producer2Names,
  };
};
