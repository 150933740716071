import { Form, InputNumber } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";
import "./CustomInputCurrency.css";

Number.prototype.roundToTwo = function () {
  return Number(Math.round(this + "e2") + "e-2");
};

const CustomInputCurrencyEx = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = React.memo((props) => {
  const { field, error } = props;

  var propsToUse = { ...props };
  delete propsToUse.control;
  delete propsToUse.formLabel;
  delete propsToUse.layout;
  delete propsToUse.isPrecision;
  delete propsToUse.formStyle;
  delete propsToUse.hasFeedback;
  delete propsToUse.parentName;
  delete propsToUse.emptyName;
  delete propsToUse.emptyLayout;
  delete propsToUse.field;
  delete propsToUse.isTouched;
  delete propsToUse.isDirty;
  delete propsToUse.error;

  const parser = (value) => value.replace(/\$\s?|(,*)/g, "");
  return (
    <Form.Item
      {...props.layout}
      label={props.formLabel}
      name={props.parentName}
      hasFeedback={props.hasFeedback}
      style={{
        marginBottom: 0,
      }}
      validateStatus={error != null ? "error" : ""}
      help={error != null ? error.message : null}
    >
      <Form.Item
        {...props.emptyLayout}
        name={props.emptyName}
        style={props.formStyle}
      />
      <Form.Item
        name={props.name}
        hasFeedback={props.hasFeedback}
        style={props.formStyle}
      >
        <div>
          <InputNumber
            {...field}
            formatter={(value, info) => {
              if (value == null || value == "") {
                return "";
              } else {
                var roundedValue = Number.parseFloat(value);
                if (Number.isNaN(roundedValue)) {
                  return props.isPrecision ? "$ 0.00" : "$ 0";
                }

                if (info.userTyping === false) {
                  if (props.isPrecision) {
                    roundedValue = roundedValue.toFixed(2);
                  } else {
                    roundedValue = roundedValue.roundToTwo();
                  }
                  var valueToString = roundedValue.toString();
                  if (valueToString[valueToString.length - 2] == ".") {
                    valueToString = valueToString + "0";
                  }

                  return `$ ${valueToString}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  );
                }
                var valueToReturn = roundedValue.toString();
                var delimitIndex = valueToReturn.indexOf(".");
                if (delimitIndex == -1) {
                  return `$ ${valueToReturn}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  );
                } else {
                  var part1 = `$ ${valueToReturn.slice(
                    0,
                    delimitIndex
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                  var part2 = valueToReturn.slice(delimitIndex);

                  return part1 + part2;
                }
              }
            }}
            controls={false}
            parser={parser}
            {...propsToUse}
          />
        </div>
      </Form.Item>
    </Form.Item>
  );
}, checkHookFormFieldPropsEquality);

export default CustomInputCurrencyEx;
