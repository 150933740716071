import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewInventoryMovementAction,
  listAvailableItemRangesAction,
  processInventoryMovementAction,
  selectHeader,
  selectNewMovementEntity,
  setReturnToICBCCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import AllocatePlatesForm from "../shared/AllocatePlatesForm";
import MoveInventoryItems from "../shared/MoveInventoryItems";
import { returnStockToICBCSchema } from "../shared/moveInventorySchemas";
import { movementOptions, types } from "../shared/movementTypes";

// Constants
const initialValues = {
  AgencyKey: "",
  InventoryMovementType: "",
  InventoryMovementKey: "",
  TransactionDate: "",
  StockCentreConfNo: "",
};

const ReturnToICBCStep2 = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    watch,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(returnStockToICBCSchema),
  });

  const transactionDate = watch("TransactionDate");

  //
  const dispatch = useDispatch();

  //
  const [currentDefaultValues, setCurrentDefaultValues] = useState(null);

  //
  const movementType = useSelector(
    (state) => selectHeader(state).InventoryMovementType
  );
  const movementTypeOption = movementOptions.filter(
    (o) => o.value === movementType
  )[0];
  const movementTypeDescription =
    movementTypeOption && movementTypeOption.label;
  const loading = useSelector(
    (state) => state.inventory.moveInventory.newMovement.loading
  );
  const header = useSelector(selectHeader);
  const agencyKey = header.AgencyKey;
  const type = header.InventoryMovementType;
  const movementItems = useSelector((state) => {
    const entity = selectNewMovementEntity(state);
    if (entity == null) return {};
    else return entity.Items;
  });
  const agencyNumberAndName = header.AgencyNumberAndName;
  const movementKey = header.InventoryMovementKey;

  useEffect(() => {
    dispatch(listAvailableItemRangesAction(agencyKey, type));
  }, [agencyKey, type]);

  useEffect(() => {
    if (
      header != null &&
      (currentDefaultValues == null || isNull(currentDefaultValues))
    ) {
      setCurrentDefaultValues(header);
      reset({ ...header });
      trigger();
    }
  }, [header]);

  const onSubmitHandler = (values) => {
    let inventoryMovement = {
      AgencyKey: agencyKey,
      InventoryMovementType: type,
      InventoryMovementKey: movementKey,
      TransactionDate: values.TransactionDate,
      StockCentreConfNo: values.StockCentreConfNo,
    };

    if (movementType === types.UnusedStockReturn) {
      inventoryMovement["TransferredToAgencyNumber"] =
        values.TransferredToAgencyNumber;
    }
    dispatch(processInventoryMovementAction(inventoryMovement));
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" onFinish={() => {}}>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <Row>
              <Col span={8}>
                <Descriptions column={1}>
                  <DescriptionsItem label="Movement Type">
                    {movementTypeDescription}
                  </DescriptionsItem>
                  <DescriptionsItem label="Agency" style={{ padding: 0 }}>
                    {agencyNumberAndName}
                  </DescriptionsItem>
                </Descriptions>
              </Col>
              <Col span={12}>
                <CustomDatePicker
                  formItem={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                    required: true,
                    label: "Transaction Date",
                  }}
                  control={control}
                  name="TransactionDate"
                />
                <CustomInput
                  control={control}
                  layout={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                  }}
                  formLabel="Stock Center Confirmation"
                  name="StockCentreConfNo"
                  style={{ width: 150, marginBottom: 0 }}
                  formStyle={{ marginBottom: 0 }}
                />
              </Col>
            </Row>
            {(movementType === types.UnusedStockReturn ||
              movementType === types.DefectiveStockReturn) && ( // If it's unused or defective
              <AllocatePlatesForm
                focusPlateFromInput={true}
                headerValues={{
                  ...getValues(),
                  TransactionDate: transactionDate,
                }}
              />
            )}
            <MoveInventoryItems
              showMoveAllButton={movementType === types.UnusedStockReturn}
            />
            <Row style={{ paddingTop: 16 }}>
              <Col span={8}>
                <Button
                  danger
                  size="small"
                  onClick={() => {
                    dispatch(clearNewInventoryMovementAction()).then(
                      dispatch(setReturnToICBCCurrentStepAction(0))
                    );
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col offset={8} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        size="small"
                        onClick={() => {
                          dispatch(setReturnToICBCCurrentStepAction(0));
                        }}
                      >
                        <LeftCircleOutlined />
                        Previous
                      </Button>

                      <Space>
                        <Popconfirm
                          title="This inventory movement will now be completed. Would you like to proceed?"
                          onConfirm={() => handleSubmit(onSubmitHandler)()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            id="processButton"
                            type="primary"
                            size="small"
                            disabled={
                              !isValid ||
                              (movementItems && movementItems.length === 0)
                            }
                            loading={loading}
                          >
                            Complete
                            <RightCircleOutlined />
                          </Button>
                        </Popconfirm>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ReturnToICBCStep2;
