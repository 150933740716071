import {
  CloseOutlined,
  FilterOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button, Col, Tooltip } from "antd";
import { memo } from "react";

export const ClearFilters = memo(
  ({ clearAllFilters, isNotFiltered, includeSorting }) => (
    <Col style={{ marginRight: 5 }}>
      <Tooltip
        title={includeSorting ? "Clear Filters and Sorting" : "Clear Filters"}
      >
        <Button
          size={"small"}
          type="primary"
          onClick={() => {
            clearAllFilters();
          }}
          disabled={isNotFiltered}
        >
          <CloseOutlined />
          <FilterOutlined />
          {includeSorting && <SortAscendingOutlined />}
        </Button>
      </Tooltip>
    </Col>
  ),
  (prevProps, nextProps) => prevProps.isNotFiltered === nextProps.isNotFiltered
);
