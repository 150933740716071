import { PrinterOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Collapse, Divider, Form, Row, Space, Spin } from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import {
  clearOperationResultAction,
  transformAgentDailyInput,
} from "../../../store/inventory/inventoryActions";
import {
  clearOperationResult,
  fetchReport,
  resetReportState,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import PdfViewer from "../../shared/PdfViewer";
import ReportParamPanelHeader from "../../shared/ReportParamPanelHeader";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomCheckbox from "../../shared/form/reactHookForm/CustomCheckbox";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import { dateFormat, toTZNeutralFormat } from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { ViewInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import AllocatedToAgentSelect from "./AllocatedToAgentSelect";
import { roadAgentDailyStockSchema } from "./reportSchemas";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKey: "",
  TransactionDate: ["", ""],
  NewPageForEachSeries: true,
  IncludeSignOutInSheet: true,
  IncludeLogSheet: true,
  IncludeSalesLog: false,
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const RoadAgentDailyStock = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    trigger,
    formState: { isValid, isDirty, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(roadAgentDailyStockSchema),
  });

  const agencyKey = watch("AgencyKey");
  const includeLogSheet = watch("IncludeLogSheet");
  const includeSalesLog = watch("IncludeSalesLog");

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const isFetching = useSelector((state) => state.report.isFetching);
  const isViewerVisible = useSelector((state) => state.report.isViewerVisible);
  const reportOperationResult = useSelector(
    (state) => state.report.operationResult
  );
  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKey: initialAgency,
        TransactionDate: [
          toTZNeutralFormat(dayjs().startOf("day")),
          toTZNeutralFormat(dayjs().endOf("day")),
        ],
      };
      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    openNotificationOperationResult(reportOperationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [reportOperationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformAgentDailyInput(values),
        entityType.INVENTORY,
        "PrintAgentDaily",
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="InventoryReports">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col span={16}>
                      <AgencySelectEx
                        layout={layout}
                        formLabel="Agency"
                        name="AgencyKey"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={defaultValues.AgencyKey === ""}
                        control={control}
                        onSelect={(_) => {
                          setValue("AllocatedToAgentKey", null);
                          trigger();
                        }}
                      />

                      <AllocatedToAgentSelect
                        control={control}
                        layout={layout}
                        required={true}
                        agencyKey={agencyKey}
                        allowClear={true}
                        includeAllOption={false}
                        formLabel="Road Agent"
                        setValue={setValue}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} offset={4}>
                      <CustomCheckbox
                        formLabel=""
                        control={control}
                        name="IncludeSignOutInSheet"
                        style={{ marginBottom: 0 }}
                      >
                        Generate Daily Stock Sign Out / In Sheet
                      </CustomCheckbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={4}>
                      <CustomCheckbox
                        formLabel=""
                        control={control}
                        name="IncludeLogSheet"
                        style={{ marginBottom: 0 }}
                      >
                        Generate Inventory Log Sheet
                      </CustomCheckbox>
                    </Col>
                    <Col>
                      {includeLogSheet && (
                        <CustomCheckbox
                          formLabel=""
                          control={control}
                          name="NewPageForEachSeries"
                          disabled={!includeLogSheet}
                          style={{ marginBottom: 0, marginLeft: 10 }}
                        >
                          New Page For Each Series
                        </CustomCheckbox>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={4}>
                      <CustomCheckbox
                        formLabel=""
                        control={control}
                        name="IncludeSalesLog"
                      >
                        Generate Agent's Sales Log For:
                      </CustomCheckbox>
                    </Col>
                    <Col span={10}>
                      <CustomDatePicker.RangePicker
                        formItem={{
                          layout: layout,
                          label: "",
                          required: true,
                        }}
                        control={control}
                        allowClear={true}
                        name="TransactionDate"
                        format={dateFormat}
                        disabled={includeSalesLog}
                        disabledDate={(current) =>
                          current &&
                          (current > dayjs().endOf("day") ||
                            current <=
                              dayjs().subtract(1, "month").startOf("day"))
                        }
                      />
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>

                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          reset(initialDefaultValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default RoadAgentDailyStock;
