import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  readCommissionRate,
  updateCommissionRAte,
} from "../../../store/commission/commissionActions";
import CommissionRateForm from "./CommissionRateForm";

const UpdateCommissionRate = ({ parentRefreshHandler }) => {
  const { key } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readCommissionRate(key));
  }, [key, dispatch]);

  const loading = useSelector((state) => state.commission.selectedLoading);
  const onSubmitHandler = (values) => dispatch(updateCommissionRAte(values));
  const selectedRate = useSelector((state) => state.commission.selected);

  return (
    <Spin spinning={loading} delay={500}>
      {selectedRate && (
        <CommissionRateForm
          onSubmitHandler={onSubmitHandler}
          initialValues={selectedRate}
          handleParentRefresh={parentRefreshHandler}
        />
      )}
    </Spin>
  );
};

export default UpdateCommissionRate;
