import { customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { Actions } from "./onlineRenewalsConstants";

export const clearOnlineRenewals = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_ONLINE_RENEWALS,
  });
};

const transformOnlineRenewalsSearchValues = (values) => {
  if (values == null) return null;
  let transformedValues = values;
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    transformedValues = {
      ...transformedValues,
      AgencyKeys: !agencies ? [] : agencies.map((a) => a.Id),
    };
  }
  return transformedValues;
};

export const listOnlineRenewals = (values) => async (dispatch) => {
  const searchValues = transformOnlineRenewalsSearchValues(values);

  dispatch({ type: Actions.LIST_ONLINE_RENEWALS });

  const payload = await customPost(
    entityType.DOCUMENT,
    "ListOnlineRenewals",
    searchValues
  );

  dispatch({ type: Actions.LIST_ONLINE_RENEWALS_SUCCEEDED, payload });
};

export const clearSelectedRenewalDocumentsRows = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_ROWS,
  });
};

export const setSelectedRenewalDocumentsRows = (values) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_ROWS,
    payload: { selectedRows: values },
  });
};

export const bulkUpdateOnlineRenewalsCodes =
  (values) => async (dispatch, getState) => {
    const newValues = {
      ...values,
      DocumentKeys: getState().onlineRenewals.selectedRows,
    };
    dispatch({ type: Actions.BULK_UPDATE_CODES });

    const payload = await customPost(
      entityType.DOCUMENT,
      "BulkUpdateProducerCodes",
      newValues
    );

    dispatch({ type: Actions.BULK_UPDATE_CODES_SUCCEEDED, payload });
  };

export const clearBulkUpdateData = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_BULK_UPDATE_DATA,
  });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};
