import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import {
  Controller,
  useFieldArray,
  useFormState,
  useWatch,
} from "react-hook-form";
import { disableSelectOptions } from "./selectHelpers";
import { SortField } from "./SortField";

const SortFieldSelectEx = ({
  fieldOptions,
  extraDisableOptions,
  control,
  getValues,
  trigger,
  isSubmitted,
}) => {
  const { errors } = useFormState({ control });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "SortFields",
  });

  const sortFields = useWatch({
    control,
    name: "SortFields",
  });

  const prevIsSubmittedRef = useRef(false);

  useEffect(() => {
    if (!isSubmitted && prevIsSubmittedRef.current) {
      sortFields.map((item, index) => {
        update(index, new SortField(item.Name, item.SortAscending));
      });
    }
    prevIsSubmittedRef.current = isSubmitted;
  }, [isSubmitted]);

  const extraSmallFormItemStyle = {
    display: "inline-block",
    width: 60,
    marginBottom: 0,
  };

  return (
    <>
      {!isEmpty(sortFields) && (
        <Divider orientation="left" plain style={{ marginTop: 0 }}>
          Sorting
        </Divider>
      )}
      <div>
        {fields.map((item, index) => (
          <div key={item.id}>
            <Form.Item
              labelCol={{
                xs: { span: 4 },
                sm: { span: 3 },
                md: { span: 3 },
                lg: { span: 3 },
                xl: { span: 2 },
                xxl: { span: 2 },
              }}
              wrapperCol={{
                xs: { span: 20 },
                sm: { span: 21 },
                md: { span: 21 },
                lg: { span: 21 },
                xl: { span: 22 },
                xxl: { span: 22 },
              }}
              label={index === 0 ? "By" : "Then By"}
              name={`SortFields[${index}].Name`}
              style={{ marginBottom: 4 }}
              required
            >
              <Row>
                <Col xs={24} sm={24} md={10} lg={10} xl={6} xxl={5}>
                  <Form.Item
                    name={`${index}`}
                    style={{
                      width: "97%",
                      marginBottom: 0,
                    }}
                    validateStatus={
                      errors != null &&
                      errors.SortFields != null &&
                      errors.SortFields[index] != null &&
                      errors.SortFields[index].Name != null
                        ? "error"
                        : ""
                    }
                    help={
                      errors != null &&
                      errors.SortFields != null &&
                      errors.SortFields[index] != null &&
                      errors.SortFields[index].Name != null &&
                      errors.SortFields[index].Name.message != null
                        ? errors.SortFields[index].Name.message
                        : ""
                    }
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Controller
                        control={control}
                        name={`SortFields[${index}].Name`}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onBlur={() => {
                              field.onBlur();
                              if (trigger != null) {
                                trigger(`SortFields[${index}].Name`);
                              }
                            }}
                            name={`SortFields[${index}].Name`}
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="label"
                            virtual={false}
                            filterOption={(input, option) => {
                              const label = option.label
                                ? option.label
                                : option.children;
                              return (
                                label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {disableSelectOptions(
                              fieldOptions,
                              sortFields,
                              extraDisableOptions
                            )}
                          </Select>
                        )}
                      />
                    </span>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={7} xxl={6}>
                  <Form.Item
                    name={`SortFields[${index}].SortAscending`}
                    style={{ marginBottom: 0 }}
                  >
                    <Controller
                      control={control}
                      name={`SortFields[${index}].SortAscending`}
                      render={({ field }) => (
                        <Radio.Group
                          {...field}
                          name={`SortFields[${index}].SortAscending`}
                          options={[
                            {
                              value: true,
                              label: "Ascending",
                            },
                            { value: false, label: "Descending" },
                          ]}
                          disabled={!getValues().SortFields[index].Name}
                        ></Radio.Group>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={13}>
                  <Space wrap={true}>
                    <Form.Item
                      name={`Buttons[${index}]`}
                      style={extraSmallFormItemStyle}
                    >
                      <Row>
                        <Col>
                          <Tooltip title="Delete Sorting">
                            <Button
                              type="text"
                              onClick={() => remove(index)}
                              style={{ padding: 0 }}
                            >
                              <MinusCircleOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                        {sortFields.length === index + 1 &&
                          sortFields.length < 4 && (
                            <Col>
                              <Tooltip title="Add Sorting">
                                <Button
                                  type="text"
                                  disabled={isEmpty(
                                    getValues().SortFields[index].Name
                                  )}
                                  onClick={() => {
                                    append(new SortField());
                                    if (trigger != null) {
                                      trigger("SortFields");
                                    }
                                  }}
                                >
                                  <PlusCircleOutlined />
                                </Button>
                              </Tooltip>
                            </Col>
                          )}
                      </Row>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form.Item>
          </div>
        ))}
        {isEmpty(sortFields) && (
          <Divider plain orientation="left" style={{ marginTop: 0 }}>
            <Button
              onClick={() => {
                append(new SortField());
                if (trigger != null) {
                  trigger("SortFields");
                }
              }}
              type="dashed"
            >
              <PlusOutlined /> Add Sorting
            </Button>
          </Divider>
        )}
      </div>
    </>
  );
};

export default SortFieldSelectEx;
