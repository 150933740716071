import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import { transformDealerBillingInput } from "../../store/dealerBilling/dealerBillingActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../store/report/reportActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import PdfViewer from "../shared/PdfViewer";
import ReportParamPanelHeader from "../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import BatchRangeSelectEx from "../shared/form/BatchRangeSelectEx";
import { DrillDownSortField } from "../shared/form/DrillDownSortField";
import DrillDownSortFieldSelectEx from "../shared/form/DrillDownSortFieldSelectEx";
import ExtraReportFilterContainer from "../shared/form/ExtraReportFiltersContainer";
import ProducerSelectEx from "../shared/form/ProducerSelectEx";
import { SortGroupField } from "../shared/form/SortGroupField";
import SortGroupFieldSelectEx from "../shared/form/SortGroupFieldSelectEx";
import { fixedDateRanges } from "../shared/form/fixedDateRanges";
import { fleetGarageCommaListMessage } from "../shared/form/formValidatonRules";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import { dateFormat, toEndOfDay, toStartOfDay } from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { ViewDealerBillingData } from "../shared/securityRoleTasks";
import { agenciesListEqual } from "../shared/sorting";
import { dealerBillingReportSchema } from "./dealerBillingSchemas";
import {
  drillDownSortFieldOptions,
  reportTypeOptions,
  reportTypes,
  sortGroupDrillDownFields,
  sortGroupFieldOptions,
} from "./reportParameters";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKeys: [],
  ReportType: reportTypes.DETAIL,
  FixedDateRange: fixedDateRanges.LAST_MONTH,
  TransactionDate: ["", ""],
  SortGroupFields: [
    {
      Name: sortGroupDrillDownFields.BATCH_NUMBER,
      SortAscending: true,
      ToGroup: false,
      NewPage: false,
    },
  ],
  DrillDownSortFields: [
    {
      Name: sortGroupDrillDownFields.AGENCY_NAME,
      SortAscending: true,
    },
  ],
  FleetGaragePolicyNumbers: "",
  Customer: "",
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
  BatchNumberFrom: null,
  BatchNumberTo: null,
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const specialLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 5 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 19 },
    xxl: { span: 19 },
  },
};

const DealerBillingReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { isValid, isDirty, errors, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(dealerBillingReportSchema),
  });

  const reportType = watch("ReportType");
  const transactionDate = watch("TransactionDate");

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const agencies = useSelector((state) => state.agency.listCompact);
  const reportState = useSelector((state) => state.report);
  const isExporting = reportState.isExporting;
  const operationResult = reportState.operationResult;
  const isViewerVisible = reportState.isViewerVisible;
  const isFetching = reportState.isFetching;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewDealerBillingData));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: [initialAgency],
        TransactionDate: [
          toStartOfDay(dayjs().subtract(1, "month").startOf("month")),
          toEndOfDay(dayjs().subtract(1, "month").endOf("month")),
        ],
      };
      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();

      // Reset default options for drilldown
      getExtraDisableDefaultOptions([initialAgency], getValues().ReportType);
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const getExtraDisableDefaultOptions = (agencyKeys, reportType) => {
    const fieldName =
      reportType === reportTypes.DETAIL
        ? "SortGroupFields"
        : "DrillDownSortFields";
    let fieldValue =
      reportType === reportTypes.DETAIL
        ? new SortGroupField()
        : new DrillDownSortField();
    if (agencyKeys.length === 1 && agencyKeys[0] !== 0) {
      if (reportType === reportTypes.DETAIL) {
        fieldValue.Name = sortGroupDrillDownFields.BATCH_NUMBER;
        setValue(fieldName, [fieldValue]);
      } else {
        setValue(fieldName, []);
      }
    } else if (
      (agencyKeys.length === 1 && agencyKeys[0] === 0) ||
      agencyKeys.length > 1
    ) {
      fieldValue.Name = sortGroupDrillDownFields.AGENCY_NAME;
      setValue(fieldName, [fieldValue]);
    }
  };

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformDealerBillingInput(values),
        entityType.DEALER_BILLING,
        `Print${values.ReportType}`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="DealerBilling">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <Form.Item
                        {...defaultLayout}
                        label="Report Type"
                        name="ReportType"
                        required
                      >
                        <Controller
                          control={control}
                          name={`ReportType`}
                          render={({ field }) => (
                            <Radio.Group {...field} name="ReportType">
                              {reportTypeOptions}
                            </Radio.Group>
                          )}
                        />
                      </Form.Item>

                      <AgencyMultiSelectEx
                        layout={defaultLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                        onChange={(value) => {
                          var oldLength = getValues().AgencyKeys.length;
                          var newLength = value.length;
                          // We only need to update default options in some cases
                          if (
                            newLength != oldLength &&
                            ((oldLength < 2 && newLength > oldLength) ||
                              newLength < 2)
                          ) {
                            getExtraDisableDefaultOptions(
                              value,
                              getValues().ReportType
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Transaction Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        disableFutureDates={true}
                        name="TransactionDate"
                        format={dateFormat}
                        disabledDate={
                          (current) => current > dayjs().endOf("day") //disable future dates
                        }
                      />
                    </Col>
                  </Row>
                  <ExtraReportFilterContainer>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={10} xl={11} xxl={12}>
                        <Form.Item
                          name="ProducerCode1Keys"
                          label="Producer 1"
                          {...specialLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode1Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={1}
                            taskKey={ViewDealerBillingData}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ProducerCode2Keys"
                          label="Producer 2"
                          {...specialLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode2Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={2}
                            taskKey={ViewDealerBillingData}
                          />
                        </Form.Item>
                        <Form.Item
                          name="BatcNumber"
                          label="Batch"
                          {...specialLayout}
                        >
                          <BatchRangeSelectEx
                            agencyKeys={getValues().AgencyKeys}
                            transactionDate={getValues().TransactionDate}
                            setValue={setValue}
                            control={control}
                            useICBCStmtLogic={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14} xl={13} xxl={12}>
                        <Form.Item
                          name="Customer"
                          label="Customer"
                          labelCol={{
                            xs: { span: 5 },
                            sm: { span: 5 },
                            md: { span: 5 },
                            lg: { span: 9 },
                            xl: { span: 9 },
                            xxl: { span: 7 },
                          }}
                          wrapperCol={{
                            xs: { span: 19 },
                            sm: { span: 19 },
                            md: { span: 19 },
                            lg: { span: 15 },
                            xl: { span: 15 },
                            xxl: { span: 17 },
                          }}
                        >
                          <Controller
                            control={control}
                            name="Customer"
                            render={({ field }) => (
                              <Input {...field} name="Customer" />
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          tooltip={fleetGarageCommaListMessage}
                          name="FleetGaragePolicyNumbers"
                          label="Fleet # or Garage Policy #"
                          labelCol={{
                            xs: { span: 7 },
                            sm: { span: 10 },
                            md: { span: 8 },
                            lg: { span: 9 },
                            xl: { span: 9 },
                            xxl: { span: 7 },
                          }}
                          wrapperCol={{
                            xs: { span: 17 },
                            sm: { span: 14 },
                            md: { span: 16 },
                            lg: { span: 15 },
                            xl: { span: 15 },
                            xxl: { span: 17 },
                          }}
                          validateStatus={
                            errors && errors["FleetGaragePolicyNumbers"]
                              ? "error"
                              : ""
                          }
                          help={errors.FleetGaragePolicyNumbers?.message}
                        >
                          <Controller
                            control={control}
                            name="FleetGaragePolicyNumbers"
                            render={({ field }) => (
                              <Input
                                {...field}
                                name="FleetGaragePolicyNumbers"
                              />
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row></Row>
                  </ExtraReportFilterContainer>

                  <Row>
                    <Col span={24}>
                      {reportType === reportTypes.DETAIL ? (
                        <SortGroupFieldSelectEx
                          fieldOptions={sortGroupFieldOptions}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      ) : (
                        <DrillDownSortFieldSelectEx
                          fieldOptions={drillDownSortFieldOptions}
                          control={control}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      )}
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformDealerBillingInput(getValues()),
                              `${entityType.DEALER_BILLING}${
                                getValues().ReportType
                              }Report`,
                              entityType.DEALER_BILLING
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          const reportType = getValues().ReportType;
                          const resetValues = {
                            ...initialDefaultValues,
                            ReportType: reportType,
                          };
                          reset(resetValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default DealerBillingReport;
