import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import { transformProductionInput } from "../../store/production/productionActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../store/report/reportActions";
import { listPolicyProducts } from "../../store/retention/retentionActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import PdfViewer from "../shared/PdfViewer";
import ReportParamPanelHeader from "../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import BatchRangeSelectEx from "../shared/form/BatchRangeSelectEx";
import { DrillDownSortField } from "../shared/form/DrillDownSortField";
import DrillDownSortFieldSelectEx from "../shared/form/DrillDownSortFieldSelectEx";
import ExtraReportFilterContainer from "../shared/form/ExtraReportFiltersContainer";
import ProducerSelectEx from "../shared/form/ProducerSelectEx";
import SearchMultiSelectEx from "../shared/form/SearchMultiSelectEx";
import { SortGroupField } from "../shared/form/SortGroupField";
import SortGroupFieldSelectEx from "../shared/form/SortGroupFieldSelectEx";
import { fixedDateRanges } from "../shared/form/fixedDateRanges";
import { fleetGarageCommaListMessage } from "../shared/form/formValidatonRules";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toEndOfDay, toStartOfDay } from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import {
  ViewProductivityDataFullAccess,
  ViewProductivityDataLimitedAccess,
} from "../shared/securityRoleTasks";
import { agenciesListEqual } from "../shared/sorting";
import { productionReportSchema } from "./productionSchemas";
import {
  comparisonDrillDownFieldOptions,
  drillDownSortFieldOptions,
  reportTypeOptions,
  reportTypes,
  salesAndCondensedDrillDownFieldOptions,
  sortGroupDrillDownFields,
  sortGroupFieldOptions,
} from "./reportParameters";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKeys: [],
  ReportType: reportTypes.SUMMARY,
  FixedDateRange: fixedDateRanges.MTD,
  TransactionDate: ["", ""],
  IncludeNonFleetNonGarage: true,
  IncludeFleetGarage: true,
  IncludeCancellations: false,
  SortGroupFields: [],
  DrillDownSortFields: [],
  FleetGaragePolicyNumbers: "",
  Customer: "",
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
  BatchNumberFrom: null,
  BatchNumberTo: null,
  ProductKeys: [],
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const specialLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 5 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 19 },
    xxl: { span: 19 },
  },
};

const ProductionReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, errors, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(productionReportSchema),
  });

  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [extraDisableOptions, setExtraDisableOptions] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);
  const [initialDefaultAgency, setInitialDefaultAgency] = useState(null);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const actorTasks = useSelector((state) => state.actor.details.data.Tasks);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const isExporting = useSelector((state) => state.report.isExporting);
  const operationResult = useSelector((state) => state.report.operationResult);
  const isFetching = useSelector((state) => state.report.isFetching);
  const isViewerVisible = useSelector((state) => state.report.isViewerVisible);
  const taskKey = actorTasks.includes(ViewProductivityDataFullAccess)
    ? ViewProductivityDataFullAccess
    : ViewProductivityDataLimitedAccess;
  const policyProducts = useSelector((state) => state.retention.policyProducts);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(
        listAgenciesCompactAction(brokerId, ViewProductivityDataLimitedAccess)
      );
    }
  }, [brokerId]);

  useEffect(() => {
    if (isEmpty(policyProducts)) {
      dispatch(listPolicyProducts());
    }
  }, [policyProducts]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      setInitialDefaultAgency(initialAgency);

      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: [initialAgency],
        TransactionDate: [
          toStartOfDay(dayjs().startOf("month")),
          toEndOfDay(dayjs().endOf("day")),
        ],
      };

      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();

      // Reset default options for drilldown
      getExtraDisableDefaultOptions([initialAgency], getValues().ReportType);
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  // Review what should happen when fields are already pre-selected and we change something
  const getExtraDisableDefaultOptions = (agencyKeys, reportType) => {
    const fieldName =
      reportType === reportTypes.DETAIL
        ? "SortGroupFields"
        : "DrillDownSortFields";
    let fieldValue =
      reportType === reportTypes.DETAIL
        ? new SortGroupField()
        : new DrillDownSortField();
    if (agencyKeys.length === 1 && agencyKeys[0] !== 0) {
      if (reportType === reportTypes.DETAIL) {
        fieldValue.Name = sortGroupDrillDownFields.BATCH_NUMBER;
        setValue(fieldName, [fieldValue]);
      } else {
        setValue(fieldName, []);
      }
    } else if (
      (agencyKeys.length === 1 && agencyKeys[0] === 0) ||
      agencyKeys.length > 1
    ) {
      fieldValue.Name = sortGroupDrillDownFields.AGENCY_NAME;
      setValue(fieldName, [fieldValue]);
    }
  };

  const disableFleetGarageOption = (reportType, includeFleetGarage) => {
    if (
      reportType === reportTypes.DETAIL ||
      reportType === reportTypes.SUMMARY
    ) {
      if (!includeFleetGarage) {
        setExtraDisableOptions([
          ...extraDisableOptions,
          sortGroupDrillDownFields.FLEET_GARAGE_NUMBER,
        ]);
      } else {
        const index = extraDisableOptions.indexOf(
          sortGroupDrillDownFields.FLEET_GARAGE_NUMBER
        );
        if (index > -1) {
          let newOptions = extraDisableOptions.splice(index, 1); // 2nd parameter means remove one item only
          setExtraDisableOptions(newOptions);
        }
      }
    }
  };

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformProductionInput(values, policyProducts),
        entityType.PRODUCTION,
        `Print${values.ReportType}`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="ProductionReports">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <Form.Item
                        {...defaultLayout}
                        label="Report Type"
                        name="ReportType"
                        required
                        validateStatus={
                          errors && errors["ReportType"] ? "error" : ""
                        }
                        help={errors.ReportType?.message}
                      >
                        <Controller
                          control={control}
                          name="ReportType"
                          render={({ field }) => (
                            <Select
                              {...field}
                              virtual={false}
                              onChange={(v, o) => {
                                if (
                                  getValues().ReportType === reportTypes.DETAIL
                                ) {
                                  setValue("SortGroupFields", []);
                                } else if (v === reportTypes.DETAIL) {
                                  setValue("DrillDownSortFields", []);
                                }
                                field.onChange(v, o);
                                getExtraDisableDefaultOptions(
                                  getValues().AgencyKeys,
                                  v
                                );
                              }}
                              name="ReportType"
                            >
                              {reportTypeOptions}
                            </Select>
                          )}
                        />
                      </Form.Item>

                      <AgencyMultiSelectEx
                        layout={defaultLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                        onChange={(value) => {
                          var oldLength = getValues().AgencyKeys.length;
                          var newLength = value.length;
                          // We only need to update default options in some cases
                          if (
                            newLength != oldLength &&
                            ((oldLength < 2 && newLength > oldLength) ||
                              newLength < 2)
                          ) {
                            getExtraDisableDefaultOptions(
                              value,
                              getValues().ReportType
                            );
                          }
                        }}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Transaction Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        disableFutureDates={true}
                        name="TransactionDate"
                        format={dateFormat}
                        disabledDate={(
                          current // Should we allow users to select over a year?
                        ) => current && current > dayjs().endOf("day")}
                      />

                      <Form.Item
                        {...defaultLayout}
                        label="Include"
                        name="Include"
                        required
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Space direction="vertical">
                          <Controller
                            control={control}
                            name="IncludeNonFleetNonGarage"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  setValue(
                                    "IncludeNonFleetNonGarage",
                                    e.target.checked,
                                    { shouldValidate: true, shouldDirty: true }
                                  );
                                  if (
                                    !getValues().IncludeFleetGarage &&
                                    !e.target.checked
                                  ) {
                                    setValue("IncludeFleetGarage", true, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                }}
                                inputRef={field.ref}
                                name="IncludeNonFleetNonGarage"
                              >
                                Non-Fleet and Non-Garage Policies
                              </Checkbox>
                            )}
                          />
                          <Controller
                            control={control}
                            name="IncludeFleetGarage"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                name="IncludeFleetGarage"
                                onChange={(e) => {
                                  setValue(
                                    "IncludeFleetGarage",
                                    e.target.checked,
                                    { shouldValidate: true, shouldDirty: true }
                                  );
                                  if (
                                    !getValues().IncludeNonFleetNonGarage &&
                                    !e.target.checked
                                  ) {
                                    setValue("IncludeNonFleetNonGarage", true, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                  disableFleetGarageOption(
                                    getValues().ReportType,
                                    e.target.checked
                                  );
                                }}
                                inputRef={field.ref}
                              >
                                Fleet and Garage Policies
                              </Checkbox>
                            )}
                          />
                          <Controller
                            control={control}
                            name="IncludeCancellations"
                            render={({ field }) => (
                              <Checkbox
                                inputRef={field.ref}
                                checked={field.value}
                                name="IncludeCancellations"
                                onChange={(e) => {
                                  setValue(
                                    "IncludeCancellations",
                                    e.target.checked,
                                    { shouldValidate: true, shouldDirty: true }
                                  );
                                }}
                              >
                                Cancellations
                              </Checkbox>
                            )}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <ExtraReportFilterContainer>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={10} xl={11} xxl={12}>
                        <Form.Item
                          name="ProducerCode1Keys"
                          label="Producer 1"
                          {...specialLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode1Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={1}
                            taskKey={taskKey}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ProducerCode2Keys"
                          label="Producer 2"
                          {...specialLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode2Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={2}
                            taskKey={taskKey}
                          />
                        </Form.Item>
                        <Form.Item
                          name="BatcNumber"
                          label="Batch"
                          {...specialLayout}
                        >
                          <BatchRangeSelectEx
                            disabled={
                              getValues().ReportType === reportTypes.COMPARISON
                            }
                            agencyKeys={getValues().AgencyKeys}
                            transactionDate={getValues().TransactionDate}
                            setValue={setValue}
                            control={control}
                            useICBCStmtLogic={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14} xl={13} xxl={12}>
                        <SearchMultiSelectEx
                          formLabel="ICBC Product(s)"
                          layout={{
                            labelCol: {
                              xs: { span: 5 },
                              sm: { span: 5 },
                              md: { span: 5 },
                              lg: { span: 9 },
                              xl: { span: 9 },
                              xxl: { span: 7 },
                            },
                            wrapperCol: {
                              xs: { span: 19 },
                              sm: { span: 19 },
                              md: { span: 19 },
                              lg: { span: 15 },
                              xl: { span: 15 },
                              xxl: { span: 17 },
                            },
                          }}
                          control={control}
                          name="ProductKeys"
                          data={policyProducts}
                          setValue={setValue}
                        />
                        <Form.Item
                          name="Customer"
                          label="Customer"
                          labelCol={{
                            xs: { span: 5 },
                            sm: { span: 5 },
                            md: { span: 5 },
                            lg: { span: 9 },
                            xl: { span: 9 },
                            xxl: { span: 7 },
                          }}
                          wrapperCol={{
                            xs: { span: 19 },
                            sm: { span: 19 },
                            md: { span: 19 },
                            lg: { span: 15 },
                            xl: { span: 15 },
                            xxl: { span: 17 },
                          }}
                        >
                          <Controller
                            control={control}
                            name="Customer"
                            render={({ field }) => (
                              <Input {...field} name="Customer" />
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          tooltip={fleetGarageCommaListMessage}
                          name="FleetGaragePolicyNumbers"
                          label="Fleet # or Garage Policy #"
                          labelCol={{
                            xs: { span: 7 },
                            sm: { span: 10 },
                            md: { span: 8 },
                            lg: { span: 9 },
                            xl: { span: 9 },
                            xxl: { span: 7 },
                          }}
                          wrapperCol={{
                            xs: { span: 17 },
                            sm: { span: 14 },
                            md: { span: 16 },
                            lg: { span: 15 },
                            xl: { span: 15 },
                            xxl: { span: 17 },
                          }}
                          validateStatus={
                            errors && errors["FleetGaragePolicyNumbers"]
                              ? "error"
                              : ""
                          }
                          help={errors.FleetGaragePolicyNumbers?.message}
                        >
                          <Controller
                            control={control}
                            name="FleetGaragePolicyNumbers"
                            render={({ field }) => (
                              <Input
                                {...field}
                                name="FleetGaragePolicyNumbers"
                                disabled={!getValues().IncludeFleetGarage}
                              />
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </ExtraReportFilterContainer>

                  <Row>
                    <Col span={24}>
                      {getValues().ReportType === reportTypes.DETAIL ? (
                        <SortGroupFieldSelectEx
                          fieldOptions={sortGroupFieldOptions}
                          extraDisableOptions={extraDisableOptions}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      ) : (
                        <DrillDownSortFieldSelectEx
                          fieldOptions={
                            getValues().ReportType === reportTypes.SUMMARY
                              ? drillDownSortFieldOptions
                              : getValues().ReportType ===
                                  reportTypes.SALES_SUMMARY ||
                                getValues().ReportType ===
                                  reportTypes.SUMMARY_CONDENSED
                              ? salesAndCondensedDrillDownFieldOptions
                              : comparisonDrillDownFieldOptions
                          }
                          extraDisableOptions={extraDisableOptions}
                          control={control}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      )}
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformProductionInput(getValues()),
                              `Production${getValues().ReportType}Report`,
                              entityType.PRODUCTION
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          const reportType = getValues().ReportType;
                          const resetValues = {
                            ...initialDefaultValues,
                            ReportType: reportType,
                          };
                          reset(resetValues);
                          // Reset default options for drilldown
                          {
                            /* getExtraDisableDefaultOptions(
                            [initialDefaultAgency],
                            getValues().ReportType
                          ); */
                          }
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default ProductionReport;
