import { Steps } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewInventoryMovementAction,
  clearOperationResultAction,
  initializeInventoryMovementUI,
  setAllocateInventoryCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import MoveInventorySummary from "../shared/MoveInventorySummary";
import AllocateInventoryStep1 from "./AllocateInventoryStep1";
import AllocateInventoryStep2 from "./AllocateInventoryStep2";
import { PreservableStatePageWrapper } from "../../shared/PreservableStatePageWrapper";

// Constants
const stepsContent = {
  padding: 8,
};

const AllocateInventorySteps = () => {
  //
  const dispatch = useDispatch();

  //
  const currentStep = useSelector(
    (state) => state.inventory.moveInventory.ui.allocateInventoryCurrentStep
  );
  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  useEffect(() => {
    openNotificationOperationResult(
      operationResult,
      () => dispatch(clearOperationResultAction()),
      false
    );
    if (
      operationResult &&
      operationResult.Name === "ProcessMovement" &&
      operationResult.Outcome === "Success"
    ) {
      dispatch(setAllocateInventoryCurrentStepAction(2));
    }
  }, [operationResult]);

  return (
    <PermissionWrapper entityName="MoveInventory">
      <PreservableStatePageWrapper
        onCleanup={() => {
          dispatch(clearNewInventoryMovementAction());
          dispatch(initializeInventoryMovementUI());
        }}
      >
        <Steps
          current={currentStep}
          size="small"
          items={[
            { title: "Select Allocation Type" },
            { title: "Define Details" },
            { title: "Confirmation" },
          ]}
        />

        <div style={stepsContent}>
          {currentStep === 0 && <AllocateInventoryStep1 />}
          {currentStep === 1 && <AllocateInventoryStep2 />}
          {currentStep === 2 && (
            <MoveInventorySummary
              returnButtonTitle="Allocate More Inventory"
              resetAction={() => {
                dispatch(setAllocateInventoryCurrentStepAction(0));
              }}
            />
          )}
        </div>
      </PreservableStatePageWrapper>
    </PermissionWrapper>
  );
};

export default AllocateInventorySteps;
