import { Form, Select } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useController, useWatch } from "react-hook-form";
import { checkHookFormFieldPropsEquality } from "./formValidatonRules";
import InfoForMultiSelect from "./InfoForMultiSelect";

const onMultiSelectHandler = (
  value,
  list,
  fieldName,
  onSelect,
  setValue,
  useValueLabelPair = true
) => {
  let newValue = value;
  if (value === 0) {
    newValue = list
      ? list.map((i) => {
          return useValueLabelPair ? i.value : i.Id;
        })
      : [];
    setValue(fieldName, newValue);
  }
  onSelect && onSelect(newValue);
};

const SearchMultiSelectEx = (props) => {
  const { data, isLoading } = props;

  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  const propToWatch = useWatch({ control: props.control, name: props.name });

  const onSelectHandler = (value) => {
    onMultiSelectHandler(
      value,
      data,
      props.name,
      props.onSelect,
      props.setValue
    );
  };

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
      isLoading={isLoading}
      data={data}
      onSelectHandler={onSelectHandler}
      propToWatch={propToWatch}
    >
      {props.children}
    </Content>
  );
};

const Content = React.memo(
  (props) => {
    const { field, error, data, isLoading } = props;

    var inputProps = { ...props };
    delete inputProps.control;
    delete inputProps.formLabel;
    delete inputProps.layout;
    delete inputProps.required;
    delete inputProps.isTouched;
    delete inputProps.isDirty;
    delete inputProps.error;
    delete inputProps.field;
    delete inputProps.children;
    delete inputProps.formStyle;
    delete inputProps.autoFocus;
    delete inputProps.isLoading;
    delete inputProps.data;
    delete inputProps.onSelectHandler;
    delete inputProps.propToWatch;
    delete inputProps.setValue;

    const options = [...data];

    if (data && data.length > 1) {
      options.unshift({
        label: `(Select all ${data.length} items)`,
        value: 0,
      });
    }

    return (
      <Form.Item
        {...props.layout}
        label={props.formLabel}
        name={props.name}
        validateStatus={error != null ? "error" : ""}
        help={error != null ? error.message : null}
        required={props.required}
        autoFocus={props.autoFocus}
        style={props.formStyle}
      >
        <div>
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Select
              {...field}
              {...inputProps}
              name={props.name}
              onChange={(v, o) => {
                if (props.onChange != null) {
                  props.onChange(v);
                }
                field.onChange(v, o);
              }}
              options={options}
              loading={isLoading || isEmpty(data)}
              onSelect={props.onSelectHandler}
              maxTagCount={4}
              allowClear={true}
              mode="multiple"
              showSearch
              virtual={false}
              optionFilterProp="label"
              filterOption={(input, option) => {
                const label = option.label ? option.label : option.children;
                return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
          </span>
          <InfoForMultiSelect data={data} currentValue={props.propToWatch} />
        </div>
      </Form.Item>
    );
  },
  (prevProps, nextProps) =>
    checkHookFormFieldPropsEquality(prevProps, nextProps) &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.data.length === nextProps.data.length &&
    prevProps.propToWatch === nextProps.propToWatch
);

export default SearchMultiSelectEx;
