const Actions = {
    LIST_USER_AGENCY_ROLES: 'LIST_USER_AGENCY_ROLES',
    LIST_USER_AGENCY_ROLES_SUCCEEDED: 'LIST_USER_AGENCY_ROLES_SUCCEEDED',
    LIST_BROKER_AGENCIES_BY_USER_ROLE: 'LIST_BROKER_AGENCIES_BY_USER_ROLE',
    LIST_BROKER_AGENCIES_BY_USER_ROLE_SUCCEEDED: 'LIST_BROKER_AGENCIES_BY_USER_ROLE_SUCCEEDED',
    ENABLE_ROLE_FOR_AGENCIES: 'ENABLE_ROLE_FOR_AGENCIES',
    ENABLE_ROLE_FOR_AGENCIES_SUCCEEDED: 'ENABLE_ROLE_FOR_AGENCIES_SUCCEEDED', 
    DISABLE_ROLE_FOR_AGENCIES: 'DISABLE_ROLE_FOR_AGENCIES',
    DISABLE_ROLE_FOR_AGENCIES_SUCCEEDED: 'DISABLE_ROLE_FOR_AGENCIES_SUCCEEDED',
    CLEAR_OPERATION_RESULT: 'CLEAR_OPERATION_RESULT'
}

export default Actions;