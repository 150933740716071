import React from "react";
import { PreservableStateLink } from "../../shared/PreservableStatePageWrapper";
import { columnTypes } from "../../shared/table/columnTypes";
import MovementTableActions from "./MovementTableActions";
import { useSelector } from "react-redux";
import { types } from "../shared/movementTypes";

export const movementTableColumns = (searchCriteria, modal) => {
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  return [
    {
      title: "Type",
      dataIndex: "InventoryMovementTypeDesc",
      type: columnTypes.TEXT,
      width: 240,
    },
    {
      title: "From - To",
      dataIndex: "FromTo",
      type: columnTypes.TEXT,
      width: 240,
      render: (value, record) => {
        const fromToValue = deriveFromToColumnValue(record);
        return (
          <PreservableStateLink to={`/movement/${record.InventoryMovementKey}`}>
            {fromToValue}
          </PreservableStateLink>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "StatusDescritpion",
      type: columnTypes.TEXT,
      width: 105,
    },
    {
      title: "Txn Date",
      dataIndex: "TransactionDate",
      type: columnTypes.DATE,
      width: 95,
    },

    {
      title: "Quantity",
      dataIndex: "Quantity",
      type: columnTypes.NUMBER,
      width: 85,
      align: "right",
    },
    {
      title: "Changed By",
      dataIndex: "ChangedByUserName",
      type: columnTypes.TEXT,
      width: 180,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Stock Centre Confirmation",
      dataIndex: "StockCentreConfNo",
      type: columnTypes.TEXT,
      width: 115,
    },
    {
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <MovementTableActions
          record={record}
          searchCriteria={searchCriteria}
          modal={modal}
        />
      ),
      fixed: "right",
      width: 36,
      align: "center",
    },
  ];
};

const deriveFromToColumnValue = (record) => {
  let fromValue = "";
  let toValue = "";

  switch (record.InventoryMovementType) {
    case types.AllocationFromOfficeToRoadAgent:
      fromValue = record.AgencyNumber;
      toValue = record.ToAgentUserName;
      break;
    case types.AllocationFromRoadAgentToRoadAgent:
      fromValue = record.FromAgentUserName;
      toValue = record.ToAgentUserName;
      break;
    case types.DefectiveStockReturn:
      fromValue = record.AgencyNumber;
      toValue = "ICBC";
      break;
    case types.ReceiptFromAnotherAgency:
      fromValue = record.TransferredToAgencyNumber;
      toValue = record.AgencyNumber;
      break;
    case types.ReceiptFromICBC:
      fromValue = "ICBC";
      toValue = record.AgencyNumber;
      break;
    case types.ReturnFromRoadAgentToOffice:
      fromValue = record.FromAgentUserName;
      toValue = record.AgencyNumber;
      break;
    case types.TransferToAnotherAgency:
      fromValue = record.AgencyNumber;
      toValue = record.TransferredToAgencyNumber;
      break;
    case types.UnusedStockReturn:
      fromValue = record.AgencyNumber;
      toValue = "ICBC";
      break;
    default:
      fromValue = "";
      toValue = "";
      break;
  }
  return `${fromValue} - ${toValue}`;
};
