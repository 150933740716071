import React from "react";
import { Typography, Space } from "antd";
import { StopOutlined } from "@ant-design/icons";

const { Title } = Typography;

const NotFoundPage = () => (
  <Title type="danger" level={5}>
    <Space>
      <StopOutlined />
      The specified page was not found
    </Space>
  </Title>
);

export default NotFoundPage;
