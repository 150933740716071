import { utils, writeFile } from "xlsx";
import { formatDate } from "./formatting";

export const exportToExcel = (data, fileName) => {
  // Convert data to worksheet
  const ws = utils.json_to_sheet(data);

  // Create a workbook with a single worksheet
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Sheet1");

  // Save the workbook as a file
  writeFile(wb, fileName);
};

export const exportDataToExcel = (
  data,
  columnsToRemove,
  maskSensitiveData,
  fileName,
  boolenColumns = [],
  dateColumns = [],
  columnMapping = {}
) => {
  const cleanExportData = data?.map((d) =>
    copyAndRemoveProperties(d, columnsToRemove)
  );
  let exportData = maskSensitiveData
    ? maskColumns(cleanExportData)
    : cleanExportData;

  exportData = formatBooleanColumns(exportData, boolenColumns);
  exportData = formatDateColumns(exportData, dateColumns);
  exportData = renameColumnHeaders(exportData, columnMapping);

  exportToExcel(exportData, fileName);
};

const columnsToMask = [
  "InsuredName",
  "LessorName",
  "ProducerName1",
  "ProducerName2",
  "AgentName",
  "AllocatedToUserName",
  "ChangedByUserName",
  "FromAgentUserName",
  "ToAgentUserName",
  "CustomerName",
  "CustomerName1",
  "CustomerName2",
  "CustomerAddr1",
  "CustomerAddr2",
  "CustomerAddr3",
  "PrincipalOperator",
  "PrincipleOperatorName",
  "CustomerHomePhone",
  "CustomerWorkPhone",
  "CustomerMobilePhone",
  "CustomerEmail",
  "AgentNameForGrouping",
  "TAMProducerName",
  "AgentComments",
  "RenewedByProducerName1",
  "RenewedByProducerName2",
  "Description1",
  "Description2",
  "Description",
  "ICBCReviewCompletedBy",
  "OriginalProducerName1",
  "OriginalProducerName2",
  "NameFirst",
  "NameLast",
  "Email",
];

const formatBooleanColumns = (array, boolProperties) => {
  if (!Array.isArray(array) || !Array.isArray(boolProperties)) {
    throw new Error("Both parameters must be arrays.");
  }

  return array.map((item) => {
    const convertedItem = { ...item };

    boolProperties.forEach((property) => {
      if (convertedItem.hasOwnProperty(property)) {
        convertedItem[property] = convertedItem[property] ? "Yes" : "No";
      }
    });

    return convertedItem;
  });
};

const formatDateColumns = (array, dateProperties) => {
  if (!Array.isArray(array) || !Array.isArray(dateProperties)) {
    throw new Error("Both parameters must be arrays.");
  }

  return array.map((item) => {
    const convertedItem = { ...item };

    dateProperties.forEach((property) => {
      if (convertedItem.hasOwnProperty(property)) {
        convertedItem[property] = formatDate(convertedItem[property]);
      }
    });

    return convertedItem;
  });
};

// Rename columns to user frinedly names
const renameColumnHeaders = (data, columnMapping) => {
  return data.map((item) => {
    let transformedItem = {};
    for (let key in item) {
      transformedItem[columnMapping[key] || key] = item[key];
    }
    return transformedItem;
  });
};

export const maskColumns = (data) => {
  // Check if the data is an array
  if (!Array.isArray(data)) {
    console.error("Invalid data format. Expecting an array.");
    return data;
  }

  // Check if columnsToMask is an array
  if (!Array.isArray(columnsToMask)) {
    console.error("Invalid columnsToMask format. Expecting an array.");
    return data;
  }
  // Iterate through each row in the data
  const maskedData = data.map((row) => {
    // Iterate through each column to mask
    columnsToMask.forEach((columnName) => {
      // Check if the column exists in the row
      if (row.hasOwnProperty(columnName)) {
        // Extract the value of the column
        const columnValue = row[columnName];

        // Check if the column value is a string and not null or empty
        if (typeof columnValue === "string" && columnValue.trim() !== "") {
          // Mask the column value
          const maskedValue =
            columnValue.length > 1
              ? columnValue.charAt(0) +
                "*".repeat(Math.max(columnValue.length - 1, 0))
              : columnValue.charAt(0);
          row[columnName] = maskedValue;
        } else {
          console.warn(`Skipping non-string value in column ${columnName}.`);
        }
      } else {
        console.warn(`Column ${columnName} not found in row.`);
      }
    });

    return row;
  });

  return maskedData;
};

export const copyAndRemoveProperties = (source, propertiesToRemove) => {
  // Shallow copy the source object
  const copy = { ...source };

  // Remove the specified properties from the copy
  if (Array.isArray(propertiesToRemove)) {
    propertiesToRemove.forEach((property) => {
      if (copy?.hasOwnProperty(property)) {
        delete copy[property];
      } else {
        console.warn(`Property ${property} not found in object.`);
      }
    });
  } else {
    console.error("Invalid propertiesToRemove format. Expecting an array.");
  }

  return copy;
};
