import { isNull } from "lodash";
import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { movementStatus } from "../../components/inventory/shared/movementStatus";
import { types } from "../../components/inventory/shared/movementTypes";
import {
  findAllocatedToAgent,
  findCurrentAgency,
} from "../report/reportActions";
import { Actions } from "./inventoryConstants";

export const initializeInventoryMovementUI = () => async (dispatch) => {
  dispatch({
    type: Actions.INITIALIZE_INVENTORY_MOVEMENT_UI,
  });
};

export const setInventoryMovementCurrentStepAction =
  (value) => async (dispatch) => {
    dispatch({
      type: Actions.SET_INVENTORY_MOVEMENT_CURRENT_STEP,
      payload: { value: value },
    });
  };

export const setReceiveInventoryCurrentStepAction =
  (value) => async (dispatch) => {
    dispatch({
      type: Actions.SET_RECEIVE_INVENTORY_CURRENT_STEP,
      payload: { value: value },
    });
  };

export const setTransferInventoryCurrentStepAction =
  (value) => async (dispatch) => {
    dispatch({
      type: Actions.SET_TRANSFER_INVENTORY_CURRENT_STEP,
      payload: { value: value },
    });
  };

export const setSelectedTargetAgentAction = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_TARGET_AGENT,
    payload: { value: value },
  });
};

export const clearSelectedTargetAgentAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_TARGET_AGENT,
  });
};

export const setSelectedSourceAgentAction = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_SOURCE_AGENT,
    payload: { value: value },
  });
};

export const clearSelectedSourceAgentAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_SOURCE_AGENT,
  });
};

export const setReturnToICBCCurrentStepAction = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_RETURN_TO_ICBC_CURRENT_STEP,
    payload: { value: value },
  });
};

export const setAllocateInventoryCurrentStepAction =
  (value) => async (dispatch) => {
    dispatch({
      type: Actions.SET_ALLOCATE_INVENTORY_CURRENT_STEP,
      payload: { value: value },
    });
  };

export const getNewInventoryMovementAction =
  (values, setInventoryStep = null, extras) =>
  async (dispatch) => {
    dispatch({ type: Actions.GET_INVENTORY_MOVEMENT });
    const payload = await customGet(entityType.INVENTORY, "GetNewMovement", {
      params: {
        agencyKey: values.AgencyKey,
        type: values.InventoryMovementType,
      },
    });

    dispatch({ type: Actions.GET_INVENTORY_MOVEMENT_SUCCEEDED, payload });
    if (setInventoryStep === "RECEIVE_INVENTORY") {
      dispatch(setReceiveInventoryCurrentStepAction(1));
    } else if (setInventoryStep === "TRANSFER_INVENTORY") {
      dispatch(setTransferInventoryCurrentStepAction(1));
    } else if (setInventoryStep === "RETURN_ICBC") {
      dispatch(setReturnToICBCCurrentStepAction(1));
    } else if (setInventoryStep === "ALLOCATE_INVENTORY") {
      if (!isNull(extras.FromAgentUserKey)) {
        dispatch(setSelectedSourceAgentAction(extras.FromAgentUserKey));
      }

      if (!isNull(extras.ToAgentUserKey)) {
        dispatch(setSelectedTargetAgentAction(extras.ToAgentUserKey));
      }

      dispatch(
        listAvailableItemRangesAction(
          extras.AgencyKey,
          extras.InventoryMovementType,
          extras.FromAgentUserKey
        )
      );
      dispatch(setAllocateInventoryCurrentStepAction(1));
    }
  };

export const processInventoryMovementAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.PROCESS_INVENTORY_MOVEMENT });
  const payload = await customPost(
    entityType.INVENTORY,
    "ProcessMovement",
    values
  );
  dispatch({ type: Actions.PROCESS_INVENTORY_MOVEMENT_SUCCEEDED, payload });
};

export const listPlateTypesActions = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_PLATE_TYPES });
  const payload = await customGet(entityType.INVENTORY, "ListPlateTypes");
  dispatch({ type: Actions.LIST_PLATE_TYPES_SUCCEEDED, payload });
};

export const addItemInventoryMovementAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.ADD_ITEM_TO_INVENTORY_MOVEMENT });
  const payload = await customPost(
    entityType.INVENTORY,
    "AddItemRangeToMovement",
    values,
    {
      params: {
        agencyKey: values.AgencyKey,
        type: values.InventoryMovementType,
      },
    }
  );
  dispatch({
    type: Actions.ADD_ITEM_TO_INVENTORY_MOVEMENT_SUCCEEDED,
    payload,
  });
};

export const addItemsInventoryMovementAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.ADD_ITEMS_TO_INVENTORY_MOVEMENT });
  const payload = await customPost(
    entityType.INVENTORY,
    "AddItemRangesToMovement",
    values.SelectedItems,
    {
      params: {
        agencyKey: values.AgencyKey,
        type: values.InventoryMovementType,
        movementKey: values.InventoryMovementKey,
      },
    }
  );
  dispatch({
    type: Actions.ADD_ITEMS_TO_INVENTORY_MOVEMENT_SUCCEEDED,
    payload,
  });
};

export const listPendingICBCShipmentsAction =
  (agencyKey) => async (dispatch) => {
    dispatch({ type: Actions.LIST_PENDING_ICBC_SHIPMENTS });
    const payload = await customPost(entityType.INVENTORY, "ListMovements", {
      AgencyKey: agencyKey,
      Status: movementStatus.Pending,
      MovementType: types.ReceiptFromICBC,
    });
    dispatch({
      type: Actions.LIST_PENDING_ICBC_SHIPMENTS_SUCCEEDED,
      payload,
    });
  };

export const listPendingBrokerShipmentsAction =
  (agencyKey) => async (dispatch) => {
    dispatch({ type: Actions.LIST_PENDING_BROKER_SHIPMENTS });
    const payload = await customPost(entityType.INVENTORY, "ListMovements", {
      AgencyKey: agencyKey,
      Status: movementStatus.Pending,
      MovementType: types.ReceiptFromAnotherAgency,
    });
    dispatch({
      type: Actions.LIST_PENDING_BROKER_SHIPMENTS_SUCCEEDED,
      payload,
    });
  };

export const searchMovementsAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.SEARCH_MOVEMENTS });

  try {
    const payload = await customPost(
      entityType.INVENTORY,
      "SearchMovements",
      values
    );

    if (payload == null) throw "Payload is empty";

    dispatch({
      type: Actions.SEARCH_MOVEMENTS_SUCCEEDED,
      payload,
    });
  } catch (e) {
    dispatch({ type: Actions.SEARCH_MOVEMENTS_FAILED });
  }
};

export const clearNewInventoryMovementAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_NEW_INVENTORY_MOVEMENT,
  });
};

export const setSelectedPendingMovementKeyAction =
  (key) => async (dispatch) => {
    dispatch({
      type: Actions.SET_SELECTED_PENDING_MOVEMENT_KEY,
      payload: { key: key },
    });
  };

export const setSelectedItemKeysAction = (keys) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_ITEM_KEYS,
    payload: { keys: keys },
  });
};

export const setMovementOptionAction = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_INVENTORY_MOVEMENT_OPTION,
    payload: { value: value },
  });
};

export const readAction =
  (key, setInventoryStep = null) =>
  async (dispatch) => {
    dispatch({ type: Actions.READ_INVENTORY_MOVEMENT });
    const payload = await customGet(entityType.INVENTORY, "ReadMovement", {
      params: {
        key: key,
      },
    });
    dispatch({ type: Actions.READ_INVENTORY_MOVEMENT_SUCCEEDED, payload });
    if (setInventoryStep === "RECEIVE_INVENTORY") {
      dispatch(setReceiveInventoryCurrentStepAction(1));
      dispatch(setSelectedPendingMovementKeyAction(key));
    }
  };

export const undoAction = (key) => async (dispatch) => {
  dispatch({ type: Actions.UNDO_MOVEMENT });
  const payload = await customGet(entityType.INVENTORY, "UndoMovement", {
    params: {
      key: key,
    },
  });
  dispatch({ type: Actions.UNDO_MOVEMENT_SUCCEEDED, payload });
};

export const removeInventoryMovementItemAction =
  (key, itemKey) => async (dispatch) => {
    dispatch({ type: Actions.REMOVE_INVENTORY_MOVEMENT_ITEM });
    const payload = await customGet(
      entityType.INVENTORY,
      "RemoveMovementItem",
      {
        params: {
          key: key,
          itemKey: itemKey,
        },
      }
    );
    dispatch({
      type: Actions.REMOVE_INVENTORY_MOVEMENT_ITEM_SUCCEEDED,
      payload,
    });
  };

export const validateAgencyForTransferAction =
  (agencyNumber) => async (dispatch) => {
    dispatch({ type: Actions.VALIDATE_AGENCY_FOR_TRANSFER });
    const payload = await customGet(entityType.AGENCY, "ValidateForTransfer", {
      params: {
        agencyNumber: agencyNumber,
      },
    });
    dispatch({
      type: Actions.VALIDATE_AGENCY_FOR_TRANSFER_SUCCEEDED,
      payload,
    });
  };

export const removeInventoryMovementAction = (key) => async (dispatch) => {
  dispatch({ type: Actions.REMOVE_INVENTORY_MOVEMENT });
  const payload = await customGet(entityType.INVENTORY, "RemoveMovement", {
    params: {
      key: key,
    },
  });
  dispatch({
    type: Actions.REMOVE_INVENTORY_MOVEMENT_SUCCEEDED,
    payload,
  });
};

export const listAvailableItemRangesAction =
  (agencyKey, movementType, sourceRoadAgentUserKey) => async (dispatch) => {
    dispatch({ type: Actions.LIST_AVAILABLE_INVENTORY_ITEM_RANGES });
    const payload = await customPost(
      entityType.INVENTORY,
      "ListAvailableItemRanges",
      {
        AgencyKey: agencyKey,
        MovementType: movementType,
        SourceRoadAgentUserKey: sourceRoadAgentUserKey,
      }
    );
    dispatch({
      type: Actions.LIST_AVAILABLE_INVENTORY_ITEM_RANGES_SUCCEEDED,
      payload,
    });
  };

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const findInventoryAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.FIND_INVENTORY });

  try {
    const payload = await customPost(entityType.INVENTORY, "Find", values);

    if (payload == null) throw "Payload is empty";

    dispatch({ type: Actions.FIND_INVENTORY_SUCCEEDED, payload });
  } catch (e) {
    dispatch({ type: Actions.FIND_INVENTORY_FAILED });
  }
};

export const clearItemsListAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_ITEMS_LIST });
};

export const setItemsSearchCriteriaAction = (values) => async (dispatch) => {
  dispatch({
    type: Actions.SET_ITEMS_SEARCH_CRITERIA,
    payload: { values: values },
  });
};

export const listTargetAgentsAction = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.LIST_TARGET_AGENTS });
  const payload = await customGet(entityType.INVENTORY, "ListTargetAgents", {
    params: {
      agencyKey: agencyKey,
    },
  });
  dispatch({
    type: Actions.LIST_TARGET_AGENTS_SUCCEEDED,
    payload,
  });
};

export const listSourceAgentsAction = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.LIST_SOURCE_AGENTS });
  const payload = await customGet(entityType.INVENTORY, "ListSourceAgents", {
    params: {
      agencyKey: agencyKey,
    },
  });
  dispatch({
    type: Actions.LIST_SOURCE_AGENTS_SUCCEEDED,
    payload,
  });
};

export const listAllocatedToAgentsAction = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.LIST_ALLOCATED_TO_AGENTS });
  const payload = await customGet(entityType.INVENTORY, "ListSourceAgents", {
    params: {
      agencyKey: agencyKey,
    },
  });
  dispatch({
    type: Actions.LIST_ALLOCATED_TO_AGENTS_SUCCEEDED,
    payload,
  });
};

export const setInventorySheetShouldPrint = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_INVENTORY_SHEET_SHOULD_PRINT,
    payload: { value: value },
  });
};

export const setInventorySheetShouldBreak = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_INVENTORY_SHEET_SHOULD_BREAK,
    payload: { value: value },
  });
};

export const clearInventorySheet = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_INVENTORY_SHEET,
  });
};

export const setMovementsSearchCriteriaAction =
  (searchCriteria) => async (dispatch) => {
    dispatch({
      type: Actions.SET_MOVEMENTS_SEARCH_CRITERIA,
      payload: { searchCriteria: searchCriteria },
    });
  };

export const clearMovementsListAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_MOVEMENTS_LIST,
  });
};

export const clearSelectedInventoryItemAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_INVENTORY_ITEM,
  });
};

export const setSelectedInventoryItemAction = (values) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_INVENTORY_ITEM,
    payload: values,
  });
};

export const listItemHistoryAction = (itemKey) => async (dispatch) => {
  dispatch({ type: Actions.LIST_ITEM_HISTORY });
  const payload = await customGet(entityType.INVENTORY, "ListItemHistory", {
    params: {
      itemKey: itemKey,
    },
  });
  dispatch({
    type: Actions.LIST_ITEM_HISTORY_SUCCEEDED,
    payload,
  });
};

export const markAsIssuedAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.MARK_AS_ISSUED });
  const payload = await customPost(
    entityType.INVENTORY,
    "MarkAsIssued",
    values
  );
  dispatch({
    type: Actions.MARK_AS_ISSUED_SUCCEEDED,
    payload,
  });
};

export const undoMarkAsIssuedAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.UNDO_MARK_AS_ISSUED });
  const payload = await customPost(
    entityType.INVENTORY,
    "UndoMarkAsIssued",
    values
  );
  dispatch({
    type: Actions.UNDO_MARK_AS_ISSUED_SUCCEEDED,
    payload,
  });
};

export const markAsLostStolenAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.MARK_AS_LOST_STOLEN });
  const payload = await customPost(
    entityType.INVENTORY,
    "MarkAsLostStolen",
    values
  );
  dispatch({
    type: Actions.MARK_AS_LOST_STOLEN_SUCCEEDED,
    payload,
  });
};

export const undoMarkAsLostStolenAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.UNDO_MARK_AS_LOST_STOLEN });
  const payload = await customPost(
    entityType.INVENTORY,
    "UndoMarkAsLostStolen",
    values
  );
  dispatch({
    type: Actions.UNDO_MARK_AS_LOST_STOLEN_SUCCEEDED,
    payload,
  });
};

export const removeFromInventoryAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.REMOVE_FROM_INVENTORY });
  const payload = await customPost(
    entityType.INVENTORY,
    "RemoveFromInventory",
    values
  );
  dispatch({
    type: Actions.REMOVE_FROM_INVENTORY_SUCCEEDED,
    payload,
  });
};

export const listMaintainInventoryItemRangesAction =
  (values) => async (dispatch) => {
    dispatch({ type: Actions.LIST_MAINTAIN_INVENTORY_ITEM_RANGES });
    const payload = await customPost(
      entityType.INVENTORY,
      "ListMaintainInventoryItemRanges",
      values
    );
    dispatch({
      type: Actions.LIST_MAINTAIN_INVENTORY_ITEM_RANGES_SUCCEEDED,
      payload,
    });
  };

// Selectors
export const selectNewMovementEntity = (state) =>
  state.inventory.moveInventory.newMovement.entity;
export const selectHeader = (state) => {
  const entity = selectNewMovementEntity(state);
  if (entity == null) return {};
  else return entity.Header;
};

export const transformMovementInput =
  ({
    movementKey,
    inventoryMovementTypeDesc,
    transactionDate,
    newPageForEachSeries,
    includeLogSheet,
  }) =>
  (_) => {
    return {
      MovementKey: movementKey,
      InventoryMovementTypeDesc: inventoryMovementTypeDesc,
      TransactionDate: transactionDate,
      NewPageForEachSeries: newPageForEachSeries,
      IncludeLogSheet: includeLogSheet,
      FileName: `Inventory_Movement_${movementKey.toString()}`,
    };
  };

export const transformAuditInput = (values) => (state) => {
  const agency = findCurrentAgency(state, values.AgencyKey);
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FileName: `InventoryAudit_${agency.Number}`,
  };
};

export const transformLogInput = (values) => (state) => {
  const agency = findCurrentAgency(state, values.AgencyKey);
  const agentName = findAllocatedToAgent(
    state,
    values.AllocatedToAgentKey ? values.AllocatedToAgentKey : 0
  );
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    AllocatedToAgentName: agentName,
    AllocatedToAgentKey: values.AllocatedToAgentKey
      ? values.AllocatedToAgentKey
      : 0,
    FileName: `InventoryLog_${agency.Number}`,
  };
};

export const transformOnHandInput = (values) => (state) => {
  const agency = findCurrentAgency(state, values.AgencyKey);
  //if no value defined, run for all agents
  const allocatedToAgentKey =
    values.AllocatedToAgentKey !== null ? values.AllocatedToAgentKey : 0;
  const agentName = findAllocatedToAgent(state, allocatedToAgentKey);
  return {
    ...values,
    AllocatedToAgentKey: allocatedToAgentKey,
    AgencyNameAndNumber: agency.FullName,
    AllocatedToAgentName: agentName,
    FileName: `OnHandInventory_${agency.Number}`,
  };
};

export const transformAgentDailyInput = (values) => (state) => {
  const agency = findCurrentAgency(state, values.AgencyKey);
  const agentName = findAllocatedToAgent(state, values.AllocatedToAgentKey);
  return {
    ...values,
    AgencyName: agency.FullName,
    AgentName: agentName,
    FileName: `AgentDaily_${agency.Number}`,
  };
};
