import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  readProducerCode,
  updateProducerCode,
} from "../../store/producer/producerActions";
import ProducerCodeForm from "./ProducerCodeForm";

const UpdateProducerCode = ({ parentRefreshHandler }) => {
  const { codeKey } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readProducerCode(codeKey));
  }, [codeKey, dispatch]);

  const loading = useSelector((state) => state.producer.selectedLoading);
  const onSubmitHandler = (values) => dispatch(updateProducerCode(values));
  const selectedProducerCode = useSelector((state) => state.producer.selected);

  return (
    <Spin spinning={loading} delay={500}>
      {selectedProducerCode && (
        <ProducerCodeForm
          onSubmitHandler={onSubmitHandler}
          initialValues={selectedProducerCode}
          handleParentRefresh={parentRefreshHandler}
        />
      )}
    </Spin>
  );
};

export default UpdateProducerCode;
