import React from "react";
import { Alert, Space, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  clearSelectedWarningMessageAction,
  setIgnoreSelectedWarningAction,
} from "../../../store/document/documentActions";

const EditWarningAlert = ({ warning }) => {
  const dispatch = useDispatch();
  return (
    <Alert
      showIcon
      message={warning}
      type="warning"
      action={
        <Space>
          <Button
            size="small"
            onClick={() => {
              dispatch(setIgnoreSelectedWarningAction(true));
              dispatch(clearSelectedWarningMessageAction());
            }}
            type="ghost"
          >
            Ignore
          </Button>
        </Space>
      }
    />
  );
};

export default React.memo(EditWarningAlert);
