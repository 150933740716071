import { Actions } from "./fleetPolicyCustomMappingConstants";
import produce from "immer";

const initialState = {
  list: [],
  loading: false,
  selected: null,
  selectedLoading: false,
  operationResult: null,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_MAPPING:
        draft.loading = true;
        return draft;

      case Actions.LIST_MAPPING_SUCCESS:
        draft.loading = false;
        draft.list = action.payload;
        return draft;

      case Actions.SET_SELECTED_MAPPING:
        draft.selected = action.payload;
        draft.selectedLoading = false;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;

      case Actions.CLEAR_SELECTED_MAPPING:
        draft.operationResult = null;
        draft.selected = null;
        return draft;

      case Actions.UPDATE_MAPPING:
      case Actions.CREATE_MAPPING:
        draft.selectedLoading = true;
        return draft;

      case Actions.UPDATE_MAPPING_SUCCESS:
      case Actions.CREATE_MAPPING_SUCCESS:
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        }
        draft.operationResult = action.payload.Operation;
        draft.selectedLoading = false;
        return draft;

      case Actions.DELETE_MAPPING:
        draft.loading = true;
        return draft;

      case Actions.DELETE_MAPPING_SUCCESS:
        draft.loading = false;

        if (action.payload.Operation.Outcome === "Success") {
          draft.list = action.payload.Data;
        } else {
          draft.operationResult = action.payload.Operation;
        }
        return draft;

      default:
        return state;
    }
  });
};
