import { isEmpty } from "lodash";
import CustomInput from "../../form/reactHookForm/CustomInput";
import "./EditableTableCell.css";

export const EditableTableCell = ({
  children,
  editable,
  control,
  inputProps,
  isEditing,
  setEditingRow,
  record,
  onPressEnter,
  onPressEscape,
}) => {
  return editable ? (
    isEditing === record.DocumentKey ? (
      <div className="editable-cell-value">
        <CustomInput
          control={control}
          showHelpMessage={false}
          {...inputProps}
          id={`inlineEditInput_${inputProps.name}_${record.DocumentKey}`}
          className="input-test-class"
          onKeyDown={(e: any) => {
            if (e.key === "Enter" && onPressEnter != null) {
              onPressEnter();
            } else if (e.key === "Escape" && onPressEscape != null) {
              onPressEscape();
            }
          }}
          onBlur={(e: any) => {
            const tagName = e?.relatedTarget?.tagName;
            const targetId = e?.relatedTarget?.id;
            if (
              !(
                tagName === "INPUT" &&
                targetId?.startsWith("inlineEditInput_") === true
              ) &&
              !(
                tagName === "SPAN" &&
                targetId?.startsWith("inlineEditButton_") === true
              )
            ) {
              onPressEscape();
            }
          }}
        />
      </div>
    ) : (
      <div
        className={
          record[inputProps.name] == null || isEmpty(record[inputProps.name])
            ? "editable-cell-value-wrap-empty"
            : "editable-cell-value-wrap"
        }
        //   style={{ paddingRight: 24 }}
        onClick={setEditingRow}
      >
        <div className="editable-cell-child-class">{children}</div>
      </div>
    )
  ) : (
    children
  );
};
