export const Actions = {
  SET_INVENTORY_MOVEMENT_CURRENT_STEP: "SET_INVENTORY_MOVEMENT_CURRENT_STEP",
  GET_INVENTORY_MOVEMENT: "GET_INVENTORY_MOVEMENT",
  GET_INVENTORY_MOVEMENT_SUCCEEDED: "GET_INVENTORY_MOVEMENT_SUCCEEDED",
  ADD_ITEM_TO_INVENTORY_MOVEMENT: "ADD_ITEM_TO_INVENTORY_MOVEMENT",
  ADD_ITEM_TO_INVENTORY_MOVEMENT_SUCCEEDED:
    "ADD_ITEM_TO_INVENTORY_MOVEMENT_SUCCEEDED",
  ADD_ITEMS_TO_INVENTORY_MOVEMENT: "ADD_ITEMS_TO_INVENTORY_MOVEMENT",
  ADD_ITEMS_TO_INVENTORY_MOVEMENT_SUCCEEDED:
    "ADD_ITEMS_TO_INVENTORY_MOVEMENT_SUCCEEDED",
  LIST_PLATE_TYPES: "LIST_PLATE_TYPES",
  LIST_PLATE_TYPES_SUCCEEDED: "LIST_PLATE_TYPES_SUCCEEDED",
  PROCESS_INVENTORY_MOVEMENT: "PROCESS_INVENTORY_MOVEMENT",
  PROCESS_INVENTORY_MOVEMENT_SUCCEEDED: "PROCESS_INVENTORY_MOVEMENT_SUCCEEDED",
  CLEAR_NEW_INVENTORY_MOVEMENT: "CLEAR_NEW_INVENTORY_MOVEMENT",
  LIST_PENDING_ICBC_SHIPMENTS: "LIST_PENDING_ICBC_SHIPMENTS",
  LIST_PENDING_ICBC_SHIPMENTS_SUCCEEDED:
    "LIST_PENDING_ICBC_SHIPMENTS_SUCCEEDED",
  LIST_PENDING_BROKER_SHIPMENTS: "LIST_PENDING_BROKER_SHIPMENTS",
  LIST_PENDING_BROKER_SHIPMENTS_SUCCEEDED:
    "LIST_PENDING_BROKER_SHIPMENTS_SUCCEEDED",
  SET_SELECTED_PENDING_MOVEMENT_KEY: "SET_SELECTED_PENDING_MOVEMENT_KEY",
  READ_INVENTORY_MOVEMENT: "READ_INVENTORY_MOVEMENT",
  READ_INVENTORY_MOVEMENT_SUCCEEDED: "READ_INVENTORY_MOVEMENT_SUCCEEDED",
  REMOVE_INVENTORY_MOVEMENT_ITEM: "REMOVE_INVENTORY_MOVEMENT_ITEM",
  REMOVE_INVENTORY_MOVEMENT_ITEM_SUCCEEDED:
    "REMOVE_INVENTORY_MOVEMENT_ITEM_SUCCEEDED",
  REMOVE_INVENTORY_MOVEMENT: "REMOVE_INVENTORY_MOVEMENT",
  REMOVE_INVENTORY_MOVEMENT_SUCCEEDED: "REMOVE_INVENTORY_MOVEMENT_SUCCEEDED",
  SET_INVENTORY_MOVEMENT_OPTION: "SET_INVENTORY_MOVEMENT_OPTION",
  LIST_AVAILABLE_INVENTORY_ITEM_RANGES: "LIST_AVAILABLE_INVENTORY_ITEM_RANGES",
  LIST_AVAILABLE_INVENTORY_ITEM_RANGES_SUCCEEDED:
    "LIST_AVAILABLE_INVENTORY_ITEM_RANGES_SUCCEEDED",
  SET_SELECTED_ITEM_KEYS: "SET_SELECTED_ITEM_KEYS",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  CLEAR_ITEMS_LIST: "CLEAR_ITEMS_LIST",
  SET_ITEMS_SEARCH_CRITERIA: "SET_ITEMS_SEARCH_CRITERIA",
  FIND_INVENTORY: "FIND_INVENTORY",
  FIND_INVENTORY_SUCCEEDED: "FIND_INVENTORY_SUCCEEDED",
  FIND_INVENTORY_FAILED: "FIND_INVENTORY_FAILED",
  LIST_TARGET_AGENTS: "LIST_TARGET_AGENTS",
  LIST_TARGET_AGENTS_SUCCEEDED: "LIST_TARGET_AGENTS_SUCCEEDED",
  LIST_SOURCE_AGENTS: "LIST_SOURCE_AGENTS",
  LIST_SOURCE_AGENTS_SUCCEEDED: "LIST_SOURCE_AGENTS_SUCCEEDED",
  UNDO_MOVEMENT: "UNDO_MOVEMENT",
  UNDO_MOVEMENT_SUCCEEDED: "UNDO_MOVEMENT_SUCCEEDED",
  INITIALIZE_INVENTORY_MOVEMENT_UI: "INITIALIZE_INVENTORY_MOVEMENT_UI",
  SET_RECEIVE_INVENTORY_CURRENT_STEP: "SET_RECEIVE_INVENTORY_CURRENT_STEP",
  SET_TRANSFER_INVENTORY_CURRENT_STEP: "SET_TRANSFER_INVENTORY_CURRENT_STEP",
  SET_RETURN_TO_ICBC_CURRENT_STEP: "SET_RETURN_TO_ICBC_CURRENT_STEP",
  SET_ALLOCATE_INVENTORY_CURRENT_STEP: "SET_ALLOCATE_INVENTORY_CURRENT_STEP",
  SET_SELECTED_TARGET_AGENT: "SET_SELECTED_TARGET_AGENT",
  CLEAR_SELECTED_TARGET_AGENT: "CLEAR_SELECTED_TARGET_AGENT",
  SET_SELECTED_SOURCE_AGENT: "SET_SELECTED_SOURCE_AGENT",
  CLEAR_SELECTED_SOURCE_AGENT: "CLEAR_SELECTED_SOURCE_AGENT",
  VALIDATE_AGENCY_FOR_TRANSFER: "VALIDATE_AGENCY_FOR_TRANSFER",
  VALIDATE_AGENCY_FOR_TRANSFER_SUCCEEDED:
    "VALIDATE_AGENCY_FOR_TRANSFER_SUCCEEDED",
  SET_MOVEMENTS_SEARCH_CRITERIA: "SET_MOVEMENTS_SEARCH_CRITERIA",
  CLEAR_MOVEMENTS_LIST: "CLEAR_MOVEMENTS_LIST",
  SEARCH_MOVEMENTS: "SEARCH_MOVEMENTS",
  SEARCH_MOVEMENTS_SUCCEEDED: "SEARCH_MOVEMENTS_SUCCEEDED",
  SEARCH_MOVEMENTS_FAILED: "SEARCH_MOVEMENTS_FAILED",
  SET_SELECTED_INVENTORY_ITEM: "SET_SELECTED_INVENTORY_ITEM",
  CLEAR_SELECTED_INVENTORY_ITEM: "CLEAR_SELECTED_INVENTORY_ITEM",
  LIST_ITEM_HISTORY: "LIST_ITEM_HISTORY",
  LIST_ITEM_HISTORY_SUCCEEDED: "LIST_ITEM_HISTORY_SUCCEEDED",
  REMOVE_FROM_INVENTORY: "REMOVE_FROM_INVENTORY",
  REMOVE_FROM_INVENTORY_SUCCEEDED: "REMOVE_FROM_INVENTORY_SUCCEEDED",
  UNDO_MARK_AS_ISSUED: "UNDO_MARK_AS_ISSUED",
  UNDO_MARK_AS_ISSUED_SUCCEEDED: "UNDO_MARK_AS_ISSUED_SUCCEEDED",
  MARK_AS_ISSUED: "UNDO_MARK_AS_ISSUED",
  MARK_AS_ISSUED_SUCCEEDED: "MARK_AS_ISSUED_SUCCEEDED",
  MARK_AS_LOST_STOLEN: "MARK_AS_LOST_STOLEN",
  MARK_AS_LOST_STOLEN_SUCCEEDED: "MARK_AS_LOST_STOLEN_SUCCEEDED",
  UNDO_MARK_AS_LOST_STOLEN: "UNDO_MARK_AS_LOST_STOLEN",
  UNDO_MARK_AS_LOST_STOLEN_SUCCEEDED: "UNDO_MARK_AS_LOST_STOLEN_SUCCEEDED",
  LIST_MAINTAIN_INVENTORY_ITEM_RANGES: "LIST_MAINTAIN_INVENTORY_ITEM_RANGES",
  LIST_MAINTAIN_INVENTORY_ITEM_RANGES_SUCCEEDED:
    "LIST_MAINTAIN_INVENTORY_ITEM_RANGES_SUCCEEDED",
  LIST_ALLOCATED_TO_AGENTS: "LIST_ALLOCATED_TO_AGENTS",
  LIST_ALLOCATED_TO_AGENTS_SUCCEEDED: "LIST_ALLOCATED_TO_AGENTS_SUCCEEDED",
  SET_INVENTORY_SHEET_SHOULD_PRINT: "SET_INVENTORY_SHEET_SHOULD_PRINT",
  SET_INVENTORY_SHEET_SHOULD_BREAK: "SET_INVENTORY_SHEET_SHOULD_BREAK",
};
