import dayjs from "dayjs";
import * as yup from "yup";

export const userSchema = yup
  .object()
  .shape({
    Details: yup
      .object()
      .shape({
        AgencyKey: yup.number().required("Required").nullable(),
        NameFirst: yup.string().required("Required").nullable(),
        NameLast: yup.string().required("Required").nullable(),
        AccessEnabled: yup.boolean().required("Required").nullable(),
        EmploymentStartDate: yup.string().nullable(),
        EmploymentEndDate: yup.string().nullable(),
        Comment: yup.string().nullable().max(500, "Longer than 500 characters"),
      })
      .test(
        "end-date-after-start-date",
        "Employment End Date must be after Employment Start Date",
        function (value) {
          const { EmploymentStartDate, EmploymentEndDate } = value;
          if (EmploymentStartDate && EmploymentEndDate) {
            if (
              dayjs(EmploymentEndDate).isBefore(
                dayjs(EmploymentStartDate)
              )
            ) {
              return this.createError({
                path: "Details.EmploymentEndDate",
                message:
                  "Employment End Date must be after Employment Start Date",
              });
            }
          }
          return true;
        }
      ),
  })
  .test(
    "email-required-if-access-enabled",
    "Email is required and must be valid if 'AccessEnabled' is true",
    function (value) {
      const { Account, Details } = value || {};
      if (Details && Details.AccessEnabled) {
        if (!Account || !Account.Email) {
          return this.createError({
            path: "Account.Email",
            message: "Email is required if 'Enable Autolink Access' is 'Yes'",
          });
        }

        // Validate email format manually since we can't use .when() directly
        const emailSchema = yup.string().email("Invalid email");
        if (!emailSchema.isValidSync(Account.Email)) {
          return this.createError({
            path: "Account.Email",
            message: "Invalid email",
          });
        }
      }
      return true;
    }
  );

export const legacyUsersSchema = yup.object().shape({
  AgencyKeys: yup.array().required("Required").min(1, "Required").nullable(),
});

export const usersSchema = yup.object().shape({
  AgencyKeys: yup.array().required("Required").min(1, "Required").nullable(),
});
