export const Actions = {
  LIST_LOAN_CALCULATOR_AGENCIES: "LIST_LOAN_CALCULATOR_AGENCIES",
  LIST_LOAN_CALCULATOR_AGENCIES_SUCCEEDED:
    "LIST_LOAN_CALCULATOR_AGENCIES_SUCCEEDED",
  CLEAR_LOAN_CALCULATOR_OPERATION_RESULT:
    "CLEAR_LOAN_CALCULATOR_OPERATION_RESULT",
  CLEAR_LOAN_CALCULATOR: "CLEAR_LOAN_CALCULATOR",
  GET_LOAN_PAYMENT_DETAILS: "GET_LOAN_PAYMENT_DETAILS",
  GET_LOAN_PAYMENT_DETAILS_SUCCEEDED: "GET_LOAN_PAYMENT_DETAILS_SUCCEEDED",
};
