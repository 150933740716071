import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import {
  findInventoryAction,
  markAsIssuedAction,
  markAsLostStolenAction,
  removeFromInventoryAction,
  undoMarkAsIssuedAction,
  undoMarkAsLostStolenAction,
} from "../../../store/inventory/inventoryActions";
import { TableActionsDropdown } from "../../shared/table/TableActionsDropdown";
import { columnTypes } from "../../shared/table/columnTypes";

export const inventoryItemColumns = (
  searchText,
  setViewHistory,
  viewHistoryHandler
) => {
  const searchTextTransform = (text) => {
    if (text == null) return "";
    var currentText = text.toString();
    var startsWithAsterisk = currentText.startsWith("*");
    var endsWithAsterisk = currentText.endsWith("*");

    if (
      startsWithAsterisk === true &&
      endsWithAsterisk === true &&
      currentText.length > 2
    ) {
      return currentText.substring(1, currentText.length - 1);
    } else if (startsWithAsterisk === true && currentText.length > 1) {
      return currentText.substring(1);
    } else if (endsWithAsterisk === true && currentText.length > 1) {
      return currentText.substring(0, currentText.length - 1);
    } else {
      return currentText;
    }
  };
  const renderSearchByColumn = (text, record) => (
    <Button
      style={{ fontSize: "13px" }}
      type="link"
      onClick={() => {
        setViewHistory(true);
        viewHistoryHandler(record);
      }}
    >
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchTextTransform(searchText)]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    </Button>
  );

  const getMaintainInventoryInputModel = (record) => {
    return {
      AgencyKey: record.AgencyKey,
      ItemNumberFrom: record.ItemNumber,
      ItemNumberTo: record.ItemNumber,
    };
  };

  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const inventoryState = useSelector((state) => state.inventory);
  const searchCriteria = inventoryState.inventoryItems.searchCriteria;

  const columns = [
    {
      title: "Item",
      dataIndex: "ItemNumber",
      type: columnTypes.TEXT,
      width: 75,
      render: (text, record) => renderSearchByColumn(text, record),
    },
    {
      title: "Description",
      dataIndex: "ItemDescription",
      type: columnTypes.TEXT,
      width: 220,
    },
    {
      title: "Status",
      dataIndex: "ItemStatusDescription",
      type: columnTypes.TEXT,
      width: 190,
    },
    {
      title: "Allocated To",
      dataIndex: "AllocatedToUserName",
      type: columnTypes.TEXT,
      width: 180,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Customer",
      dataIndex: "CustomerName",
      type: columnTypes.TEXT,
      width: 180,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Batch",
      dataIndex: "BatchNo",
      type: columnTypes.TEXT,
      width: 75,
    },
    {
      title: "Agency",
      dataIndex: "AgencyNumberAndName",
      type: columnTypes.TEXT,
      width: 270,
    },
    {
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <InventoryItemsActions
          record={record}
          searchCriteria={searchCriteria}
          getMaintainInventoryInputModel={getMaintainInventoryInputModel}
          setViewHistory={setViewHistory}
          viewHistoryHandler={viewHistoryHandler}
        ></InventoryItemsActions>
      ),
      fixed: "right",
      width: 36,
    },
  ];

  return columns;
};

const InventoryItemsActions = ({
  record,
  searchCriteria,
  getMaintainInventoryInputModel,
  setViewHistory,
  viewHistoryHandler,
}) => {
  const dispatch = useDispatch();

  const changeStatusMenuItems = [
    {
      key: "1",
      disabled: record.ItemStatus === "I" || record.ItemStatus === "L",
      label: `Mark as "Issued"`,
      onClick: () =>
        dispatch(
          markAsIssuedAction(getMaintainInventoryInputModel(record))
        ).then(() => dispatch(findInventoryAction(searchCriteria))),
    },
    { type: "divider" },
    {
      key: "2",
      disabled: record.ItemStatus === "U" || record.ItemStatus === "L",
      label: `Undo "Mark As Issued"`,
      onClick: () =>
        dispatch(
          undoMarkAsIssuedAction(getMaintainInventoryInputModel(record))
        ).then(() => dispatch(findInventoryAction(searchCriteria))),
    },
    { type: "divider" },
    {
      key: "3",
      disabled: record.ItemStatus === "I" || record.ItemStatus === "L",
      label: `Mark as "Lost or Stolen"`,
      onClick: () =>
        dispatch(
          markAsLostStolenAction(getMaintainInventoryInputModel(record))
        ).then(() => dispatch(findInventoryAction(searchCriteria))),
    },
    { type: "divider" },
    {
      key: "4",
      disabled: record.ItemStatus === "I" || record.ItemStatus === "U",
      label: `Undo "Mark as "Lost or Stolen"`,
      onClick: () =>
        dispatch(
          undoMarkAsLostStolenAction(getMaintainInventoryInputModel(record))
        ).then(() => dispatch(findInventoryAction(searchCriteria))),
    },
  ];

  const viewHistoryItem = {
    key: "2",
    icon: <FileTextOutlined />,
    label: "View History",
    onClick: () => {
      setViewHistory(true);
      viewHistoryHandler(record);
    },
  };

  const items = record.IsUserAllowedToSave
    ? [
        {
          key: "1",
          icon: <EditOutlined />,
          label: "Change Status",
          children: changeStatusMenuItems,
        },
        { type: "divider" },
        viewHistoryItem,
        { type: "divider" },
        {
          key: "3",
          icon: <DeleteOutlined />,
          label: (
            <Popconfirm
              title="Permanently delete the invetory item?"
              onConfirm={() => {
                dispatch(
                  removeFromInventoryAction(
                    getMaintainInventoryInputModel(record)
                  )
                ).then(() => dispatch(findInventoryAction(searchCriteria)));
              }}
              okText="Yes"
              cancelText="No"
            >
              Delete Item
            </Popconfirm>
          ),
          disabled: record.ItemStatus === "I" || record.ItemStatus === "L",
        },
      ]
    : [viewHistoryItem];

  return <TableActionsDropdown items={items}></TableActionsDropdown>;
};
