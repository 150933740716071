import { FilterOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { get } from "lodash";
import { matchSorter } from "match-sorter";

export const getColumnSearchProps = (dataIndex, title, columnRefArray, i) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(el) => (columnRefArray.current[i] = el)}
          placeholder={`Filter ${title} By`}
          value={selectedKeys[0] ?? ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => e.stopPropagation()}
          onPressEnter={() => {
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) => {
      return (
        matchSorter([get(record, dataIndex)], value ?? "", {
          threshold: matchSorter.rankings.CONTAINS,
        }).length > 0
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (columnRefArray.current[i] != null) {
            columnRefArray.current[i].select();
          }
        }, 100);
      }
    },
  };
};
