import { Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearSelectedUserAction,
  readUserAction,
  updateUserAction,
} from "../../store/user/general/userActions";
import UserAgencyRoles from "./UserAgencyRoles";
import UserForm from "./UserForm";

const UpdateUserForm = ({ agencyKeys }) => {
  const { userKey } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(readUserAction(userKey));
  }, [userKey, dispatch]);
  const loading = useSelector((state) => state.user.general.loading);
  const onSubmitHandler = (values) => dispatch(updateUserAction(values));
  const selectedUser = useSelector((state) => state.user.general.selected);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedUserAction());
    };
  }, []);

  const items = selectedUser?.Details.AccessEnabled
    ? [
        {
          key: "1",
          label: `General`,
          children: (
            <>
              {selectedUser && (
                <UserForm
                  onSubmitHandler={onSubmitHandler}
                  initialValues={selectedUser}
                  agencyKeys={agencyKeys}
                />
              )}
            </>
          ),
        },
        {
          key: "2",
          label: `Security Roles`,
          children: <UserAgencyRoles />,
        },
      ]
    : [
        {
          key: "1",
          label: `General`,
          children: (
            <>
              {selectedUser && (
                <UserForm
                  onSubmitHandler={onSubmitHandler}
                  initialValues={selectedUser}
                  agencyKeys={agencyKeys}
                />
              )}
            </>
          ),
        },
      ];

  return (
    <Spin spinning={loading} delay={500}>
      <Tabs defaultActiveKey="1" type="card" size="small" items={items} />
    </Spin>
  );
};

export default UpdateUserForm;
