import React, { useEffect } from "react";
import { readAction, updateAction } from "../../store/agency/agencyActions";
import { useDispatch, useSelector } from "react-redux";
import AgencyForm from "./AgencyForm";
import { Spin } from "antd";
import { useParams } from "react-router-dom";

const UpdateAgencyForm = () => {
  const { agencyKey } = useParams();

  //
  const dispatch = useDispatch();

  //
  const onSubmitHandler = (values) => dispatch(updateAction(values));
  const selected = useSelector((state) => state.agency.selected);

  //
  useEffect(() => {
    if (agencyKey != null) {
      dispatch(readAction(agencyKey));
    }
  }, [agencyKey, dispatch]);

  return (
    <Spin spinning={!selected} delay={500}>
      {selected != null && (
        <AgencyForm
          onSubmitHandler={onSubmitHandler}
          initialValues={selected}
        />
      )}
    </Spin>
  );
};

export default UpdateAgencyForm;
