import Actions from "./actorConstants";
import produce from "immer";

const initialState = {
  loading: false,
  data: null,
  sessionExpiring: false,
  darkMode: false,
  operationResult: null,
  switchSupportAccount: { operationResult: null, loading: false },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.READ_ACTOR:
        draft.loading = true;
        return draft;

      case Actions.READ_ACTOR_SUCCEEDED:
        draft.data = action.payload.Data;
        draft.operationResult = action.payload.Operation;
        draft.loading = false;
        return draft;
      case Actions.SET_SESSION_EXPIRING:
        draft.sessionExpiring = action.expiring;
        return draft;
      case Actions.SET_DARK_MODE:
        draft.darkMode = action.mode;
        return draft;
      case Actions.CLEAR_ACTOR_DATA:
        draft.data = null;
        draft.loading = false;
        return draft;
      case Actions.CLEAR_SWITCH_ACCOUNT_OPERATION_RESULT:
        draft.switchSupportAccount.operationResult = null;
        return draft;
      case Actions.SWITCH_SUPPORT_ACCOUNT:
        draft.switchSupportAccount.loading = true;
        return draft;
      case Actions.SWITCH_SUPPORT_ACCOUNT_SUCCEEDED:
        if (action.payload.Operation.Outcome === "Success") {
          draft.data.BrokerId = action.payload.Data.BrokerId;
          draft.data.BrokerName = action.payload.Data.BrokerName;
          draft.data.AgencyKey = action.payload.Data.AgencyKey;
          draft.data.AgencyFullName = action.payload.Data.AgencyFullName;
          draft.data.DisplayFleetGarageCommission =
            action.payload.Data.DisplayFleetGarageCommission;
        }
        draft.switchSupportAccount.operationResult = action.payload.Operation;
        draft.switchSupportAccount.loading = false;
        return draft;
      default:
        return state;
    }
  });
};
