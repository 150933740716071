import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { listAgenciesCompactAction } from "../../../store/agency/agencyActions";
import {
  createCertificateCopyAction,
  createDocumentByProductAction,
  createDocumentByProductKeyAction,
  listPolicyProductsAction,
} from "../../../store/document/documentActions";
import { resetReportState } from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { PdfViewerModal } from "../../shared/PdfViewer";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import SearchSelectEx from "../../shared/form/SearchSelectEx";
import { mediumInputStyle } from "../../shared/form/formInputStyles";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import { ManageDocuments } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import CreateDocumentForm from "./CreateDocumentForm";
import manualDocumentSchema from "./manualDocumentSchema";
import {
  CertificateReplacement,
  Debt,
  DuplicateForSignature,
  Payment,
  Policy,
} from "./manualDocumentTypes";

const { Text } = Typography;

// Constants
const initialValues = {
  AgencyKey: "",
  Product: CertificateReplacement,
  Timestamp: null,
  Registration: null,
  ProductKey: null,
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddDocumentForm = () => {
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    watch,
    formState: { isValid, isDirty, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(manualDocumentSchema),
  });

  const product = watch("Product");

  //
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const policyProducts = useSelector((state) => state.document.policyProducts);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ManageDocuments));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);

      reset({ ...initialValues, AgencyKey: initialAgency });

      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    if (isNull(policyProducts)) {
      dispatch(listPolicyProductsAction());
    }
  }, [policyProducts]);

  useEffect(() => {
    dispatch(resetReportState());
  }, []);

  const onSubmitHandler = (values) => {
    let action;
    switch (values.Product) {
      case CertificateReplacement:
      case DuplicateForSignature:
        action = createCertificateCopyAction;
        break;
      case Payment:
      case Debt:
        action = createDocumentByProductAction;
        break;
      case Policy:
        action = createDocumentByProductKeyAction;
        break;
      default:
        action = createDocumentByProductKeyAction;
        break;
    }
    dispatch(action(values)).then(navigate(`createdocumentform`));
  };
  return (
    <Row>
      <Col span={24}>
        <Form
          size="small"
          autoComplete="off"
          requiredMark={true}
          onFinish={() => handleSubmit(onSubmitHandler)()}
        >
          <Card
            title={
              <Row>
                <Col span={8}>
                  <Text>
                    Enter Agency, Product and Additional Details (if applicable)
                  </Text>
                </Col>
                <Col span={4} offset={12}>
                  <Space>
                    <Button
                      disabled={
                        product === CertificateReplacement ||
                        product === DuplicateForSignature
                          ? !isValid || !isDirty
                          : !isValid
                      }
                      autoFocus={true}
                      id="processButton"
                      type="primary"
                      htmlType="submit"
                    >
                      Next
                    </Button>
                    <Button
                      type="dashed"
                      onClick={() => {
                        reset();
                        trigger();
                      }}
                    >
                      Reset Values
                    </Button>
                  </Space>
                </Col>
              </Row>
            }
            size="small"
            bordered={true}
          >
            <Row>
              <Col span={10}>
                <AgencySelectEx
                  layout={layout}
                  formLabel="Agency"
                  name="AgencyKey"
                  required={true}
                  formStyle={{ marginBottom: 0 }}
                  agencies={currentAgencies}
                  isLoading={defaultValues.AgencyKey === ""}
                  control={control}
                />
              </Col>
            </Row>
            <Divider stlyle={{ marginTop: 0, marginBottom: 8 }} />
            <Row>
              <Col span={10}>
                <CustomRadioGroup
                  control={control}
                  layout={layout}
                  formLabel="Product"
                  name="Product"
                  required={true}
                  autoFocus={true}
                  afterChange={() => trigger()}
                >
                  <Space direction="vertical">
                    <Radio value={CertificateReplacement}>
                      Certificate Replacement
                    </Radio>
                    <Radio value={DuplicateForSignature}>
                      Duplicate For Signature
                    </Radio>
                    <Radio value={Payment}>Payment Plan</Radio>
                    <Radio value={Debt}>Debt Receipt</Radio>
                    <Radio value={Policy}>Other Policy Types</Radio>
                  </Space>
                </CustomRadioGroup>
              </Col>
              <Col span={12}>
                {(product === CertificateReplacement ||
                  product === DuplicateForSignature) && (
                  <>
                    <CustomInput
                      control={control}
                      layout={layout}
                      required={true}
                      formLabel="Original Transaction Timestamp"
                      name="Timestamp"
                      maxLength={14}
                      style={mediumInputStyle}
                      formTooltip={{
                        title:
                          "Enter the timestamp of the original document, not of the replacement/duplicate.",
                      }}
                    />

                    <CustomInput
                      control={control}
                      layout={layout}
                      required={true}
                      formLabel="Registration"
                      name="Registration"
                      maxLength={8}
                      style={mediumInputStyle}
                    />
                  </>
                )}
                {product === Policy && (
                  <SearchSelectEx
                    formLabel="ICBC Product"
                    layout={layout}
                    control={control}
                    name="ProductKey"
                    options={policyProducts}
                    required={true}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

const ManualDocument = () => {
  // Form

  return (
    <PermissionWrapper entityName="AddDocument">
      <Routes>
        <Route path="" element={<AddDocumentForm />} />

        <Route path="createdocumentform" element={<CreateDocumentForm />} />
      </Routes>
      <PdfViewerModal />
    </PermissionWrapper>
  );
};
export default ManualDocument;
