// In-house adaptation of Print.js (crabbly)
const FRAME_ID = "printer-frame";

const Browser = {
  // Internet Explorer 6-11
  isIE: () => {
    return (
      navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode
    );
  },

  // Edge 20+
  isEdge: () => {
    return !Browser.isIE() && !!window.StyleMedia;
  },

  // Chrome 1+
  isChrome: (context = window) => {
    return !!context.chrome;
  },
};

function cleanUp(params) {
  //
  window.URL.revokeObjectURL(params.printable);

  // Run onPrintDialogClose callback
  let event = "mouseover";

  if (Browser.isChrome()) {
    // Ps.: Firefox will require an extra click in the document to fire the focus event.
    event = "focus";
  }

  const handler = () => {
    // Make sure the event only happens once.
    window.removeEventListener(event, handler);

    params.onPrintDialogClose();

    // Remove iframe from the DOM
    const iframe = document.getElementById(FRAME_ID);

    if (iframe) {
      iframe.remove();
    }
  };

  window.addEventListener(event, handler);
}

function performPrint(iframeElement, params) {
  try {
    iframeElement.focus();

    // If Edge
    if (Browser.isEdge()) {
      try {
        var result = iframeElement.contentWindow.document.execCommand(
          "print",
          false,
          null
        );
        if (!result) {
          params.onError("iframe print command did not execute");
        }
      } catch (e) {
        params.onError(e);
      }
    } else {
      // Other browsers
      setTimeout(function () {
        iframeElement.contentWindow.print();
      }, 1000);
    }
  } catch (error) {
    params.onError(error);
  } finally {
    cleanUp(params);
  }
}

export function printPdfFile(params) {
  //
  params.onLoadingStart();

  // To prevent duplication and issues, remove any used printFrame from the DOM
  const usedFrame = document.getElementById(FRAME_ID);
  if (usedFrame) {
    usedFrame.parentNode.removeChild(usedFrame);
  }

  // Create a new iframe for the print job
  const printFrame = document.createElement("iframe");
  printFrame.setAttribute(
    "style",
    "visibility: hidden; height: 0; width: 0; position: absolute; border: 0"
  );
  printFrame.setAttribute("id", FRAME_ID);

  // print pdf
  if (params.printable.indexOf(",") !== -1) {
    //If pdf base64 start with `data:application/pdf;base64,`,Excute the atob function will throw an error.So we get the content after `,`
    params.printable = params.printable.split(",")[1];
  }
  const bytesArray = Uint8Array.from(atob(params.printable), (c) =>
    c.charCodeAt(0)
  );

  let localPdf = new window.Blob([bytesArray], { type: "application/pdf" });
  localPdf = window.URL.createObjectURL(localPdf);
  printFrame.setAttribute("src", localPdf);

  // Append iframe element to document body
  document.getElementsByTagName("body")[0].appendChild(printFrame);

  // Get iframe element
  const iframeElement = document.getElementById(FRAME_ID);

  if (iframeElement) {
    // Wait for iframe to load all content
    iframeElement.onload = () => {
      performPrint(iframeElement, params);
      return;
    };
  } else {
    params.onError("Iframe not found (couldn't append to the body?)");
  }
}
