import { FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Space } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import { dateFormat } from "../../formatting";
const isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
export const getDateColumnSearchProps = (dataIndex) => {
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Row>
          <Col>
            <DatePicker.RangePicker
              format={dateFormat}
              value={selectedKeys[0]}
              onChange={(dates) => setSelectedKeys(dates ? [dates] : [])}
              style={{ marginBottom: 8 }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                }}
                onPressEnter={() => {
                  confirm();
                }}
                icon={<FilterOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Filter
              </Button>
              <Button
                onClick={() => {
                  handleReset(clearFilters, confirm);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    ),
    // filterIcon: (filtered) => (
    //   <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    // ),
    onFilter: (value, record) =>
      get(record, dataIndex) != null
        ? dayjs([get(record, dataIndex)]).isBetween(
            value[0].format("YYYY-MM-DD"),
            value[1].format("YYYY-MM-DD"),
            "day",
            "[]"
          )
        : false,
  };
};
