export const drillDownFields = {
  AGENCY_NAME: "AgencyNameAndNo",
  PRODUCER_1_NAME: "ProducerName1",
  PRODUCER_2_NAME: "ProducerName2",
  PRODUCER_1_CODE: "ProducerCode1",
  PRODUCER_2_CODE: "ProducerCode2",
  MONTH: "ProdMonthName",
};

export const drillDownSortFieldOptions = [
  { label: "Agency", value: drillDownFields.AGENCY_NAME },
  { label: "Producer 1 Name", value: drillDownFields.PRODUCER_1_NAME },
  { label: "Producer 2 Name", value: drillDownFields.PRODUCER_2_NAME },
  { label: "Producer 1 Code", value: drillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2 Code", value: drillDownFields.PRODUCER_2_CODE },
  { label: "Month", value: drillDownFields.MONTH },
];
