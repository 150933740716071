import { Col, Row, Spin } from "antd";
import React from "react";
import LayoutUserLoggedOut from "../LayoutUserLoggedOut";
import logo from "../alplus-logo.png";

export const LoadingComponent = ({ type = 0 }) => {
  const typeToString = (type) => {
    if (type === 1) return "LoadActorDataComponent (actor null)";
    else if (type === 2) return "CallbackSuccessComponent";
    else if (type === 3) return "PersistGate";
    return "Default LoadingComponent";
  };
  console.log("Show loading component, type", typeToString(type));
  return (
    <LayoutUserLoggedOut>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={4} offset={10}>
              <div className="login-image-sizing">
                <img className="logoImageLoading" src={logo} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="login-sub-message">
              The preferred auto insurance management software by brokers in
              British Columbia
            </Col>
          </Row>
          <Row style={{ marginTop: 14 }}>
            <Col span={24} className="centered-box">
              <div className="fixed-sized-box loading-indicator-style loading-autolink-spin">
                <Spin tip={"Loading"} size="large" style={{ color: "#1c4658" }}>
                  <div />
                </Spin>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutUserLoggedOut>
  );
};
