import { Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createUserAction } from "../../store/user/general/userActions";
import UserForm from "./UserForm";

const { TabPane } = Tabs;

const CreateUserForm = ({ agencyKeys }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const initialValues = {
    Account: { Email: "" },
    Details: { AccessEnabled: false, Comment: "" },
  };
  const user = useSelector((state) => state.user.general.selected);

  const onSubmitHandler = (values) => {
    dispatch(createUserAction(values));
  };

  useEffect(() => {
    user &&
      navigate(`../updateuserform/${user.Details.UserKey}`, {
        state: location.state,
        replace: true,
      });
  }, [user, navigate]);

  return (
    <Tabs defaultActiveKey="1" type="card" size="small">
      <TabPane tab="General" tabKey="1">
        <UserForm
          onSubmitHandler={onSubmitHandler}
          initialValues={initialValues}
          isNew={true}
          agencyKeys={agencyKeys}
        />
      </TabPane>
      <TabPane tab="Security" key="2" disabled></TabPane>
    </Tabs>
  );
};

export default CreateUserForm;
