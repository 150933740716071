import { notification } from "antd";
import { isEmpty } from "lodash";

export const openNotification = (
  message,
  description,
  type,
  onCloseHandler,
  keep = false
) => {
  notification[type]({
    message,
    description,
    onClose: onCloseHandler,
    duration:
      type === "success" && keep === true
        ? 0
        : type === "success" && isEmpty(description)
        ? 2.5
        : type === "success" && !isEmpty(description)
        ? 10
        : 0,
  });
};
