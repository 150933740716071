import { Alert, Col, Row, Space } from "antd";
import { isEmpty, isEqual } from "lodash";
import React from "react";

import { useFormContext } from "react-hook-form";
import { getFieldsErrorDescriptionForDocumentForm } from "./documentFormItems";
import EditWarningAlert from "../form/EditWarningAlert";
import NoEditPermissionAlert from "../form/NoEditPermissionAlert";

const AllAlerts = (props) => {
  //
  const { isUserAllowedToSave, warning, hasPressedSave } = props;

  //
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Row style={{ paddingBottom: 4 }}>
      <Col span={24}>
        {!isUserAllowedToSave && <NoEditPermissionAlert />}
        {warning && <EditWarningAlert warning={warning} />}
        {hasPressedSave && !isEmpty(errors) && (
          <ErrorsAlertBody errors={{ ...errors }} />
        )}
      </Col>
    </Row>
  );
};

const ErrorsAlertBody = React.memo(
  ({ errors }) => {
    const errorItems = getFieldsErrorDescriptionForDocumentForm(errors);

    return (
      <Alert
        style={{ padding: 8 }}
        showIcon
        message="Please fix the validation errors for the fields:"
        description={
          <Space.Compact direction="vertical">{errorItems}</Space.Compact>
        }
        type="error"
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

export default AllAlerts;
