import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AnnouncementForm from "./AnnouncementForm";
import {
  readAnnouncement,
  updateAnnouncement,
} from "../../store/announcement/announcementActions";

const UpdateAnnouncement = ({ parentRefreshHandler }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readAnnouncement(id));
  }, [id, dispatch]);

  const loading = useSelector((state) => state.announcement.selectedLoading);
  const onSubmitHandler = (values) => dispatch(updateAnnouncement(values));
  const selected = useSelector((state) => state.announcement.selected);

  return (
    <Spin spinning={loading} delay={500}>
      {selected && (
        <AnnouncementForm
          onSubmitHandler={onSubmitHandler}
          initialValues={selected}
          handleParentRefresh={parentRefreshHandler}
        />
      )}
    </Spin>
  );
};

export default UpdateAnnouncement;
