import {
  DownloadOutlined,
  InfoCircleTwoTone,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Checkbox, Col, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  searchMovementsAction,
  setInventorySheetShouldBreak,
  setInventorySheetShouldPrint,
  transformMovementInput,
  undoAction,
} from "../../../store/inventory/inventoryActions";
import {
  downloadReport,
  fetchReport,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import { TableActionsDropdown } from "../../shared/table/TableActionsDropdown";

const AdditionalParametersModal = () => {
  const dispatch = useDispatch();
  const shouldBreak = useSelector(
    (state) => state.inventory.inventorySheet.shouldBreak
  );
  const shouldPrint = useSelector(
    (state) => state.inventory.inventorySheet.shouldPrint
  );

  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col>Please fill out the following parameters:</Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <Checkbox
            defaultChecked={true}
            checked={shouldPrint}
            onChange={() => {
              dispatch(setInventorySheetShouldPrint(!shouldPrint));
            }}
          >
            Include Log Sheet in Report
          </Checkbox>
        </Col>

        {shouldPrint && (
          <Col>
            <Checkbox
              defaultChecked={shouldBreak}
              checked={shouldBreak}
              onChange={() => {
                dispatch(setInventorySheetShouldBreak(!shouldBreak));
              }}
            >
              New Page For Each Series
            </Checkbox>
          </Col>
        )}
      </Row>
    </>
  );
};

const MovementTableActions = ({ record, searchCriteria, modal }) => {
  const dispatch = useDispatch();

  //
  const [openedModalInstance, setOpenedModalInstance] = useState({
    instance: null,
    isPreview: false,
  });

  //
  const isDownloading = useSelector((state) => state.report.isDownloading);
  const currentMovementKey = useSelector(
    (state) => state.report.input != null && state.report.input.MovementKey
  );
  const shouldBreak = useSelector(
    (state) => state.inventory.inventorySheet.shouldBreak
  );
  const shouldPrint = useSelector(
    (state) => state.inventory.inventorySheet.shouldPrint
  );

  useEffect(() => {
    if (openedModalInstance.instance != null) {
      const customInputAction = transformMovementInput({
        movementKey: record.InventoryMovementKey,
        inventoryMovementTypeDesc: record.InventoryMovementTypeDesc,
        transactionDate: record.TransactionDate,
        newPageForEachSeries: shouldBreak == null ? false : shouldBreak,
        includeLogSheet: shouldPrint == null ? false : shouldPrint,
      });
      if (openedModalInstance.isPreview === true) {
        openedModalInstance.instance.update({
          onOk: async () => {
            return new Promise((resolve, _) => {
              setTimeout(() => {
                dispatch(
                  fetchReport(
                    customInputAction,
                    entityType.INVENTORY,
                    "PrintMovement"
                  )
                );
                dispatch(setReportModalVisibility(true));

                resolve();
              }, 100);
            }).catch(() => console.log("Something went wrong!"));
          },
        });
      } else {
        openedModalInstance.instance.update({
          onOk: async () => {
            return new Promise((resolve, _) => {
              setTimeout(() => {
                dispatch(
                  downloadReport({
                    inputAction: customInputAction,
                    entity: entityType.INVENTORY,
                    action: "PrintMovement",
                  })
                );

                resolve();
              }, 100);
            }).catch(() => console.log("Something went wrong!"));
          },
        });
      }
    }
  }, [shouldBreak, shouldPrint]);

  const items = [
    {
      key: "1",
      icon: <UndoOutlined />,
      disabled: record.Status === 2,
      label: (
        <Popconfirm
          title="Undo movement?"
          onConfirm={() => {
            dispatch(undoAction(record.InventoryMovementKey)).then(() =>
              dispatch(searchMovementsAction(searchCriteria))
            );
          }}
          okText="Yes"
          cancelText="No"
        >
          Undo Movement
        </Popconfirm>
      ),
    },
    { type: "divider" },
    {
      key: "2",
      icon: <PrinterOutlined />,
      label: "Preview",
      onClick: () => {
        var instance = modal.confirm({
          title: "Additional parameters",
          width: 600,
          content: <AdditionalParametersModal />,
          closable: true,
          okText: "Continue",
          icon: <InfoCircleTwoTone />,
          onCancel() {
            setOpenedModalInstance({ instance: null, isPreview: false });
          },
          async onOk() {
            return new Promise((resolve, _) => {
              setTimeout(() => {
                dispatch(
                  fetchReport(
                    transformMovementInput({
                      movementKey: record.InventoryMovementKey,
                      agencyKey: record.AgencyKey,
                      newPageForEachSeries:
                        shouldBreak == null ? false : shouldBreak,
                      includeLogSheet:
                        shouldPrint == null ? false : shouldPrint,
                    }),
                    entityType.INVENTORY,
                    "PrintMovement"
                  )
                );
                dispatch(setReportModalVisibility(true));
                resolve();
              }, 30);
            }).catch(() => console.log("Something went wrong!"));
          },
        });
        setOpenedModalInstance({ instance: instance, isPreview: true });
      },
    },
    {
      key: "3",
      icon: <DownloadOutlined />,
      label: "Download",
      loading:
        isDownloading && record.InventoryMovementKey == currentMovementKey,
      onClick: () => {
        if (!isDownloading) {
          var instance = modal.confirm({
            title: "Additional parameters",
            width: 600,
            content: <AdditionalParametersModal />,
            closable: true,
            okText: "Continue",
            icon: <InfoCircleTwoTone />,
            onCancel() {
              setOpenedModalInstance({ instance: null, isPreview: false });
            },
            async onOk() {
              return new Promise((resolve, _) => {
                setTimeout(() => {
                  dispatch(
                    downloadReport({
                      inputAction: transformMovementInput({
                        movementKey: record.InventoryMovementKey,
                        agencyKey: record.AgencyKey,
                        newPageForEachSeries:
                          shouldBreak == null ? false : shouldBreak,
                        includeLogSheet:
                          shouldPrint == null ? false : shouldPrint,
                      }),
                      entity: entityType.INVENTORY,
                      action: "PrintMovement",
                    })
                  );
                  resolve();
                }, 30);
              }).catch(() => console.log("Something went wrong!"));
            },
          });
          setOpenedModalInstance({ instance: instance, isPreview: false });
        }
      },
    },
  ];

  return <TableActionsDropdown items={items}></TableActionsDropdown>;
};

export default MovementTableActions;
