import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import { transformScheduleOfVehiclesInput } from "../../store/fleet/fleetActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../store/report/reportActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import PdfViewer from "../shared/PdfViewer";
import ReportParamPanelHeader from "../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import SortFieldSelectEx from "../shared/form/SortFieldSelectEx";
import { fleetCommaListMessage } from "../shared/form/formValidatonRules";
import CustomDatePicker from "../shared/form/reactHookForm/CustomDatePicker";
import {
  dateFormat,
  toEndOfDay,
  toStartOfDay,
  toTZNeutralFormat,
} from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { ReportScheduleOfVehicles } from "../shared/securityRoleTasks";
import { agenciesListEqual } from "../shared/sorting";
import RecipientInsuredDetails from "./RecipientInsuredDetails";
import {
  searchByFields,
  scheduleOfVehiclesSortFieldOptions as sortFieldOptions,
} from "./reportParameters";
import { scheduleOfVehiclesSchema } from "./reportSchemas";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";

const { Panel } = Collapse;

const layoutConfig = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
    md: { span: 7 },
    lg: { span: 7 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
    md: { span: 17 },
    lg: { span: 17 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

// Constants
const partialInitialValues = {
  AgencyKeys: [],
  EffectiveAsOfDate: "",
  ExpiryDate: ["", ""],
  FleetNumbers: "",
  Customer: "",
  ExcludeCancellations: false,
  InsuredName: "",
  InsuredAddress: "",
  ReportRecipient: "",
  SortFields: [],
  SearchBy: searchByFields.FLEET_NUMBERS,
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 },
};

const ScheduleOfVehiclesReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { isValid, isDirty, errors, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(scheduleOfVehiclesSchema),
  });

  const searchByValue = watch("SearchBy");
  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const agencies = useSelector((state) => state.agency.listCompact);
  const reportState = useSelector((state) => state.report);
  const isExporting = reportState.isExporting;
  const operationResult = reportState.operationResult;
  const isViewerVisible = reportState.isViewerVisible;
  const isFetching = reportState.isFetching;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ReportScheduleOfVehicles));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var allAgenciesId = agencies.map((i) => i.Id); // Select all agencies by default
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: allAgenciesId,
        EffectiveAsOfDate: toStartOfDay(dayjs()),
        ExpiryDate: [
          toStartOfDay(dayjs().add(1, "day").startOf("day")),
          toEndOfDay(dayjs().add(1, "day").add(1, "year").endOf("day")),
        ],
      };

      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const reportName = "ScheduleOfVehiclesReport";

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformScheduleOfVehiclesInput(values),
        entityType.FLEET,
        `PrintScheduleOfVehicles`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="ScheduleOfVehicles">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={12}>
                      <CustomDatePicker
                        formItem={{
                          layout: layoutConfig,
                          label: "Effective As of Date",
                          required: true,
                        }}
                        format={dateFormat}
                        control={control}
                        name="EffectiveAsOfDate"
                        timeOfDay="start"
                      />

                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 8 },
                            sm: { span: 7 },
                            md: { span: 7 },
                            lg: { span: 7 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 16 },
                            sm: { span: 17 },
                            md: { span: 17 },
                            lg: { span: 17 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Expiry Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        name="ExpiryDate"
                        format={dateFormat}
                      />

                      <AgencyMultiSelectEx
                        layout={{
                          labelCol: {
                            xs: { span: 8 },
                            sm: { span: 7 },
                            md: { span: 7 },
                            lg: { span: 7 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 16 },
                            sm: { span: 17 },
                            md: { span: 17 },
                            lg: { span: 17 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                        }}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12}>
                      <Form.Item
                        name="SearchBy"
                        label="Search By"
                        labelCol={{
                          xs: { span: 8 },
                          sm: { span: 7 },
                          md: { span: 7 },
                          lg: { span: 7 },
                          xl: { span: 5 },
                          xxl: { span: 4 },
                        }}
                        wrapperCol={{
                          xs: { span: 16 },
                          sm: { span: 17 },
                          md: { span: 17 },
                          lg: { span: 17 },
                          xl: { span: 19 },
                          xxl: { span: 20 },
                        }}
                      >
                        <Controller
                          control={control}
                          name="SearchBy"
                          render={({ field }) => (
                            <Radio.Group
                              {...field}
                              name="SearchBy"
                              onChange={(e) => {
                                field.onChange(e);
                                e.target.value === searchByFields.FLEET_NUMBERS
                                  ? setValue(searchByFields.CUSTOMER_NAME, "")
                                  : setValue(searchByFields.FLEET_NUMBERS, "");
                                trigger(e.target.value);
                              }}
                            >
                              <Radio
                                value={searchByFields.FLEET_NUMBERS}
                                key="0"
                              >
                                Fleet Number(s)
                              </Radio>
                              <Radio
                                value={searchByFields.CUSTOMER_NAME}
                                key="1"
                              >
                                Customer Name
                              </Radio>
                            </Radio.Group>
                          )}
                        />
                      </Form.Item>
                      {searchByValue === searchByFields.CUSTOMER_NAME && (
                        <Form.Item
                          name="Customer"
                          label="Customer Name"
                          required
                          labelCol={{
                            xs: { span: 8 },
                            sm: { span: 8 },
                            md: { span: 7 },
                            lg: { span: 7 },
                            xl: { span: 9 },
                            xxl: { span: 6 },
                          }}
                          wrapperCol={{
                            xs: { span: 16 },
                            sm: { span: 16 },
                            md: { span: 17 },
                            lg: { span: 17 },
                            xl: { span: 15 },
                            xxl: { span: 18 },
                          }}
                          validateStatus={
                            errors && errors["Customer"] ? "error" : ""
                          }
                          help={errors.Customer?.message}
                        >
                          <Controller
                            control={control}
                            name="Customer"
                            render={({ field }) => (
                              <Input {...field} name="Customer" />
                            )}
                          />
                        </Form.Item>
                      )}
                      {searchByValue === searchByFields.FLEET_NUMBERS && (
                        <Form.Item
                          labelCol={{
                            xs: { span: 8 },
                            sm: { span: 8 },
                            md: { span: 7 },
                            lg: { span: 7 },
                            xl: { span: 9 },
                            xxl: { span: 6 },
                          }}
                          wrapperCol={{
                            xs: { span: 16 },
                            sm: { span: 16 },
                            md: { span: 17 },
                            lg: { span: 17 },
                            xl: { span: 15 },
                            xxl: { span: 18 },
                          }}
                          tooltip={fleetCommaListMessage}
                          name="FleetNumbers"
                          label="Fleet Number(s)"
                          required
                          validateStatus={
                            errors && errors["FleetNumbers"] ? "error" : ""
                          }
                          help={errors.FleetNumbers?.message}
                        >
                          <Controller
                            control={control}
                            name="FleetNumbers"
                            render={({ field }) => (
                              <Input {...field} name="FleetNumbers" />
                            )}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        labelCol={{
                          xs: { span: 8 },
                          sm: { span: 8 },
                          md: { span: 7 },
                          lg: { span: 7 },
                          xl: { span: 9 },
                          xxl: { span: 6 },
                        }}
                        wrapperCol={{
                          xs: { span: 16 },
                          sm: { span: 16 },
                          md: { span: 17 },
                          lg: { span: 17 },
                          xl: { span: 15 },
                          xxl: { span: 18 },
                        }}
                        label="Exclude Cancellations"
                        name="ExcludeCancellations"
                      >
                        <Controller
                          control={control}
                          name="ExcludeCancellations"
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              onChange={(e) => {
                                setValue(
                                  "ExcludeCancellations",
                                  e.target.checked,
                                  { shouldValidate: true, shouldDirty: true }
                                );
                              }}
                              inputRef={field.ref}
                              name="ExcludeCancellations"
                            ></Checkbox>
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <RecipientInsuredDetails layout={layout} control={control} />
                  <Row>
                    <Col span={24}>
                      <SortFieldSelectEx
                        getValues={getValues}
                        control={control}
                        fieldOptions={sortFieldOptions}
                        trigger={trigger}
                        isSubmitted={isSubmitted}
                      />
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformScheduleOfVehiclesInput(getValues()),
                              reportName,
                              entityType.FLEET
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          reset(initialDefaultValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default ScheduleOfVehiclesReport;
