import { PrinterOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Form,
  Popconfirm,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { isEqual, isNull } from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearIsUndone,
  clearSelectedAction,
  transformBatchInput,
  undoBatchAction,
} from "../../../store/batching/batchingActions";
import {
  fetchReport,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import { BackButton } from "../../shared/BackButton";
import { usePreservableState } from "../../shared/PreservableStatePageWrapper";
import {
  mediumInputStyle,
  shortInputStyle,
} from "../../shared/form/formInputStyles";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import {
  dateFormat,
  formatCurrency,
  formatDate,
} from "../../shared/formatting";
import BatchDocuments from "../table/BatchDocuments";
import NoBatchEditPermissionAlert from "./NoBatchEditPermissionAlert";
import { updateBatchSchema } from "./batchSchemas";
import { useLocation, useNavigate } from "react-router";

const { Panel } = Collapse;

// Constants
const initialValues = {
  ICBCBatchNo: "",
  DateSubmitted: "",
  AgencyBatchNo: "",
};

const descriptionsStyle = { padding: 0 };
const formItemStyle = { marginBottom: 0 };

const BatchForm = ({ onSubmitHandler, handleParentRefresh }) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(updateBatchSchema),
  });

  //
  const dispatch = useDispatch();
  const { isRestorable, restorePreviousPage } = usePreservableState();
  const navigate = useNavigate();
  const location = useLocation();

  //
  const batchingState = useSelector((state) => state.batching);
  const selectedBatch = useSelector(
    (state) => state.batching.selected && state.batching.selected.BatchHeader
  );
  const selectedBatchDocuments = useSelector(
    (state) => state.batching.selected && state.batching.selected.Documents
  );
  const loading = batchingState.selectedLoading;
  const searchCriteria = batchingState.searchCriteria;
  const isFetching = useSelector((state) => state.report.isFetching);
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const isUndone = useSelector((state) => state.batching.isUndone);

  //
  useEffect(() => {
    return () => {
      dispatch(clearIsUndone());
    };
  }, []);

  useEffect(() => {
    if (!isNull(selectedBatch)) {
      var newValues = {
        ICBCBatchNo: selectedBatch.ICBCBatchNo ?? "",
        DateSubmitted: selectedBatch.DateSubmitted ?? "",
        AgencyBatchNo: selectedBatch.AgencyBatchNo ?? "",
      };

      if (!isEqual(newValues, defaultValues)) {
        reset(newValues);
        trigger();
      }
    }
  }, [selectedBatch]);

  useEffect(() => {
    if (isUndone) {
      if (location.key !== "default" && isRestorable === true) {
        restorePreviousPage();
        handleParentRefresh && handleParentRefresh();
        dispatch(clearSelectedAction());
        navigate(-1);
      } else {
        handleParentRefresh && handleParentRefresh();
        dispatch(clearSelectedAction());
        navigate("/home", { replace: true });
      }
    }
  }, [isUndone]);

  //
  const onGoBackHandler = () => {
    if (isDirty === true && handleParentRefresh != null) {
      handleParentRefresh();
    }
    dispatch(clearSelectedAction());
  };

  const onSubmitHandlerEx = (values) => {
    onSubmitHandler(values);
    handleParentRefresh();
    reset(values);
  };

  return (
    <Spin spinning={loading} delay={500}>
      <Form
        size="small"
        autoComplete="off"
        scrollToFirstError
        onFinish={() => handleSubmit(onSubmitHandlerEx)()}
      >
        <Row style={{ height: 30 }}>
          <Col span={16}>
            <Row>
              <Col>
                <Space>
                  <BackButton
                    onGoBackHandler={onGoBackHandler}
                    isDirty={isDirty}
                  />
                  <Button
                    disabled={
                      !isValid ||
                      !isDirty ||
                      (selectedBatch && !selectedBatch.IsUserAllowedToSave)
                    }
                    autoFocus={true}
                    id="processButton"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    <SaveOutlined />
                    Save
                  </Button>
                  <Popconfirm
                    title="Undo batch?"
                    onConfirm={() => {
                      dispatch(
                        undoBatchAction(
                          selectedBatch.DocumentBatchKey,
                          searchCriteria
                        )
                      );
                      handleParentRefresh && handleParentRefresh();
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      disabled={
                        selectedBatch && !selectedBatch.IsUserAllowedToSave
                      }
                      icon={<UndoOutlined />}
                    >
                      Undo
                    </Button>
                  </Popconfirm>
                  <Button
                    loading={isFetching}
                    icon={<PrinterOutlined />}
                    onClick={() => {
                      dispatch(
                        fetchReport(
                          transformBatchInput(selectedBatch),
                          entityType.BATCH,
                          "PrintBatch"
                        )
                      );
                      dispatch(setReportModalVisibility(true));
                    }}
                  >
                    Preview
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>

        {selectedBatch && !selectedBatch.IsUserAllowedToSave && (
          <NoBatchEditPermissionAlert />
        )}

        <div style={{ overflowY: "auto" }}>
          <Collapse
            defaultActiveKey={[1, 2]}
            //activeKey={activeKeys}
            type="card"
            size="small"
          >
            <Panel
              header={
                <Descriptions
                  column={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3 }}
                >
                  <Descriptions.Item label="Agency" style={descriptionsStyle}>
                    {selectedBatch && selectedBatch.AgencyNumberAndName}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Processed On"
                    style={descriptionsStyle}
                  >
                    {selectedBatch && formatDate(selectedBatch.DateCreated)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Processed By"
                    style={descriptionsStyle}
                  >
                    <div
                      className={
                        maskSensitiveData === true ? "blurry-text" : ""
                      }
                    >
                      {selectedBatch && selectedBatch.UserCreated}
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              }
              key={1}
            >
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <CustomDatePicker
                    formItem={{
                      labelCol: {
                        xs: { span: 24 },
                        sm: { span: 7 },
                        md: { span: 10 },
                        lg: { span: 8 },
                        xl: { span: 11 },
                        xxl: { span: 10 },
                      },
                      wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 17 },
                        md: { span: 14 },
                        lg: { span: 16 },
                        xl: { span: 13 },
                        xxl: { span: 14 },
                      },
                      label: "Submitted",
                      required: true,
                      style: { marginBottom: 0 },
                    }}
                    control={control}
                    name="DateSubmitted"
                    disabled={
                      selectedBatch && !selectedBatch.IsUserAllowedToSave
                    }
                    format={dateFormat}
                    style={mediumInputStyle}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <CustomInput
                    control={control}
                    layout={{
                      labelCol: {
                        xs: { span: 24 },
                        sm: { span: 7 },
                        md: { span: 14 },
                        lg: { span: 8 },
                        xl: { span: 11 },
                        xxl: { span: 10 },
                      },
                      wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 17 },
                        md: { span: 10 },
                        lg: { span: 16 },
                        xl: { span: 13 },
                        xxl: { span: 14 },
                      },
                    }}
                    formLabel="ICBC Batch"
                    name="ICBCBatchNo"
                    formStyle={formItemStyle}
                    style={shortInputStyle}
                    maxLength={7}
                    disabled={
                      selectedBatch && !selectedBatch.IsUserAllowedToSave
                    }
                    onBlur={() =>
                      setValue(
                        "ICBCBatchNo",
                        getValues().ICBCBatchNo.toUpperCase(),
                        { shouldValidate: true, shouldDirty: true }
                      )
                    }
                    required={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <CustomInput
                    control={control}
                    layout={{
                      labelCol: {
                        xs: { span: 24 },
                        sm: { span: 7 },
                        md: { span: 12 },
                        lg: { span: 8 },
                        xl: { span: 11 },
                        xxl: { span: 10 },
                      },
                      wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 17 },
                        md: { span: 12 },
                        lg: { span: 16 },
                        xl: { span: 13 },
                        xxl: { span: 14 },
                      },
                    }}
                    formLabel="Agency Batch"
                    name="AgencyBatchNo"
                    formStyle={formItemStyle}
                    maxLength={4}
                    style={shortInputStyle}
                    disabled={
                      selectedBatch && !selectedBatch.IsUserAllowedToSave
                    }
                    required={true}
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <Descriptions
                  column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
                >
                  <Descriptions.Item
                    label="Document Count"
                    style={descriptionsStyle}
                  >
                    {selectedBatch && selectedBatch.DocCount}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Cash / Cheque"
                    style={descriptionsStyle}
                  >
                    {selectedBatch &&
                      formatCurrency(selectedBatch.TotalCashChequePayment)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Debit / Credit"
                    style={descriptionsStyle}
                  >
                    {selectedBatch &&
                      formatCurrency(selectedBatch.TotalDebitCreditPayment)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Online Credit"
                    style={descriptionsStyle}
                  >
                    {selectedBatch &&
                      formatCurrency(selectedBatch.TotalOnlineCreditPayment)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Financed" style={descriptionsStyle}>
                    {selectedBatch &&
                      formatCurrency(selectedBatch.TotalAmountFinanced)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Amount Due"
                    style={descriptionsStyle}
                  >
                    {selectedBatch &&
                      formatCurrency(selectedBatch.TotalAmountDue)}
                  </Descriptions.Item>
                </Descriptions>
              }
              key={2}
            >
              <BatchDocuments
                loading={loading}
                list={selectedBatchDocuments}
                handleParentRefresh={handleParentRefresh}
              />
            </Panel>
          </Collapse>
        </div>
      </Form>
    </Spin>
  );
};

export default BatchForm;
