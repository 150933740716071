import { SearchOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row, Space } from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import {
  listDocumentsAction,
  setRefreshListFlagAction,
  setSearchValuesAction,
} from "../../../store/document/documentActions";
import { setTablePreserveOnReload } from "../../../store/ui/uiActions";
import AgencyMultiSelectEx from "../../shared/form/AgencyMultiSelectEx";
import SearchSelectEx from "../../shared/form/SearchSelectEx";
import { shortLabelLongInputLayout as layout } from "../../shared/form/formLayout";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import { ViewDocuments } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import SearchTip from "./SearchTip";
import searchByColumns from "./searchByColumns";
import searchFormSchema from "./searchFormSchema";
import { tableNames } from "../../shared/table/tableNames";

const initialValues = {
  SearchValue: "",
  Column: "Autodetect",
  AgencyKeys: [], // by default select all agencies
};

const SearchForm = ({
  onSubmitHandler,
  onResetHandler,
  loading,
  setClearAll,
}) => {
  const searchValues = useSelector((state) => state.document.searchValues);
  const refreshListFlag = useSelector(
    (state) => state.document.refreshListFlag
  );

  // Form
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: searchValues != null ? searchValues : initialValues,
    resolver: yupResolver(searchFormSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewDocuments));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      if (searchValues == null) {
        resetFieldsToDefault();
      } else {
        reset({ ...searchValues });
      }
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    // Refresh search results
    if (refreshListFlag === true) {
      if (!isNull(searchValues)) {
        dispatch(setTablePreserveOnReload(tableNames.DocumentSearch, true));
        dispatch(listDocumentsAction(searchValues));
      }
      dispatch(setRefreshListFlagAction(false));
    }
  }, [refreshListFlag]);

  useEffect(() => {
    return () => {
      // Clear the list of all agencies
      dispatch(clearListCompactAction());
    };
  }, []);

  const resetFieldsToDefault = () => {
    var initialAgency = getInitialAgency(homeAgency, agencies);
    reset({
      ...initialValues,
      AgencyKeys: [initialAgency],
    });
    trigger();
    dispatch(setSearchValuesAction(null));
  };

  const onSubmitHandlerEx = (values) => {
    // Save search criteria
    dispatch(setSearchValuesAction(values));
    onSubmitHandler(values);
    reset(values);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandlerEx)()}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={10}>
                <SearchSelectEx
                  control={control}
                  layout={layout}
                  formLabel="Search By"
                  required={true}
                  name="Column"
                  options={searchByColumns}
                  allowClear={true}
                  maxTagCount={4}
                  afterChange={(e) => {
                    // if selection is getting cleared
                    if (isNull(e) || e == undefined) {
                      setValue("Column", "Autodetect", {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }
                  }}
                />
                <AgencyMultiSelectEx
                  formStyle={{ marginBottom: 0 }}
                  layout={layout}
                  formLabel="Agency"
                  required={true}
                  agencies={currentAgencies}
                  isLoading={isEmpty(defaultValues.AgencyKeys)}
                  setValue={setValue}
                  control={control}
                  name="AgencyKeys"
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={10}>
                <CustomInput
                  layout={{
                    labelCol: { span: 6, lg: 8 },
                    wrapperCol: { span: 18, lg: 16 },
                  }}
                  control={control}
                  formLabel="Search For"
                  formTooltip={SearchTip}
                  required={true}
                  name="SearchValue"
                  placeholder="Enter search criteria, for more details click on help button"
                  autoFocus={true}
                />
                <Row>
                  <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={0}>
                    <SearchButtons
                      onClick={() => {
                        resetFieldsToDefault();
                        onResetHandler();
                        setClearAll(true);
                      }}
                      isValid={isValid}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={4}>
                <SearchButtons
                  onClick={() => {
                    resetFieldsToDefault();
                    onResetHandler();
                    setClearAll(true);
                  }}
                  isValid={isValid}
                  loading={loading}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const SearchButtons = (props) => {
  const { isValid, loading, onClick } = props;
  return (
    <Row justify="center" gutter={8}>
      <Col>
        <Button
          disabled={!isValid}
          loading={loading}
          autoFocus={true}
          id="processButton"
          type="primary"
          htmlType="submit"
        >
          <SearchOutlined />
          Search
        </Button>
      </Col>
      <Col>
        <Button onClick={onClick}>Clear Results</Button>
      </Col>
    </Row>
  );
};
export default SearchForm;
