import { useEffect } from "react";
import { LoadingComponent } from "./LoadingComponent";
import { Stored } from "./customOidcUtils";

export const CallbackSuccessComponent = () => {
  //
  useEffect(() => {
    sessionStorage.setItem(Stored.LOAD_ACTOR, Stored.DEFAULT_VALUE);

    const timer = setTimeout(() => {
      console.log("Manually redirecting to home page from callback component");
      window.location.replace(`${process.env.REACT_APP_URI}/home`);
    }, 18000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  console.log("Callback success component. Login successful.");

  return <LoadingComponent type={2} />;
};
