import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import getMenuItemVisibility from "../navigation/getMenuItemsVisibility";
import UnbatchedDocsCollapse from "./batching/UnbatchedDocsCollapse";
//import ProductionCollapse from "./production/ProductionCollapse";

const Dashboard = () => {
  const actorDetails = useSelector((state) => state.actor.details.data);
  const menuVisibility = getMenuItemVisibility(actorDetails);

  return (
    <>
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          {menuVisibility.AddBatch && <UnbatchedDocsCollapse />}
        </Col>
      </Row>
      {/* <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          {menuVisibility.ProductionReports && <ProductionCollapse />}
        </Col>
      </Row> */}
    </>
  );
};

export default Dashboard;
