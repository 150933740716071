import * as yup from "yup";
import {
  alphanumericRegex,
  alphanumericMessage,
  maxMessage,
  requiredMessage,
} from "../shared/form/formValidatonRules";

export const producerCodeSchema = yup.object().shape({
  ProducerNumber: yup.number().required("Required"),
  ProducerCode: yup
    .string()
    .required(requiredMessage)
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),

  AgencyKey: yup.number().required(requiredMessage),
  Description: yup
    .string()
    .nullable()
    .when("IsLinkedToUser", {
      is: false,
      then: yup.string().required(requiredMessage).nullable(),
    }),
  UserKey: yup
    .number()
    .nullable()
    .when("IsLinkedToUser", {
      is: true,
      then: yup.number().required(requiredMessage).nullable(),
    }),
});

export const codesSchema = yup.object().shape({
  AgencyKeys: yup
    .array()
    .required(requiredMessage)
    .min(1, requiredMessage)
    .nullable(),
  CodeNumber: yup.number().required(requiredMessage).nullable(),
});
