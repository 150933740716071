import { openNotification } from "./openNotification";

const openNotificationOperationResult = (
  result,
  onCloseHandler,
  showSuccessMessage = true,
  multiLine = false
) => {
  if (result !== null && result !== undefined) {
    let message = "";
    let description = "";
    let type = "";

    if (result.Outcome === "Success") {
      message = "Operation Successful";
      type = "success";
      description = result.Message;
      showSuccessMessage
        ? openNotification(
            message,
            description,
            type,
            onCloseHandler,
            result.Keep
          )
        : onCloseHandler();
    } else if (result.Outcome === "Error") {
      message = "Operation Failed";
      description = result.Message;
      if (multiLine == true) {
        description = (
          <div
            dangerouslySetInnerHTML={{
              __html: description.replace(/(?:\r\n|\r|\n|\\n)/g, "<br />"),
            }}
          />
        );
      }
      type = "error";
      openNotification(message, description, type, onCloseHandler);
    } else if (result.Outcome === "Warning") {
      message = "Operation could not be completed";
      description = `Please fix the warning(s) and try again.`;
      type = "warning";
      openNotification(message, description, type, onCloseHandler);
    } else if (result.Outcome === "Information") {
      message = "Review Addtional Information";
      description = result.Message;
      type = "info";
      openNotification(message, description, type, onCloseHandler);
    } else {
      message = "Operation Failed";
      description = "An unexpected error occurred. ";
      type = "error";
      openNotification(message, description, type, onCloseHandler);
    }
  }
};

export default openNotificationOperationResult;
