import React, { useState } from "react";
import { Button, Divider, Row, Col, Input, Form } from "antd";
import { Controller } from "react-hook-form";

const defaultLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
    md: { span: 8 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
    md: { span: 16 },
    lg: { span: 18 },
    xl: { span: 18 },
    xxl: { span: 18 },
  },
};

const RecipientInsuredDetails = ({ layout, control }) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  const headerLabel = `${
    displayDetails ? "Hide" : "Show"
  } Recipient / Insured Details`;

  return (
    <>
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Button
          onClick={() => setDisplayDetails(!displayDetails)}
          type="dashed"
        >
          {headerLabel}
        </Button>
      </Divider>

      {displayDetails && (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
            <Form.Item name="InsuredName" label="Name of Insured" {...defaultLayout}>
              <Controller
                control={control}
                name="InsuredName"
                render={({ field }) => <Input {...field} name="InsuredName" />}
              />
            </Form.Item>
            <Form.Item
              name="InsuredAddress"
              label="Address of Insured"
              {...defaultLayout}
            >
              <Controller
                control={control}
                name="InsuredAddress"
                render={({ field }) => (
                  <Input.TextArea {...field} name="InsuredAddress" rows={4} />
                )}
              />
            </Form.Item>
            <Form.Item
              name="ReportRecipient"
              label="Report Recipient"
              {...defaultLayout}
            >
              <Controller
                control={control}
                name="ReportRecipient"
                render={({ field }) => (
                  <Input.TextArea {...field} name="ReportRecipient" rows={4} />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default RecipientInsuredDetails;
