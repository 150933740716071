import { PrinterOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
  Spin,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import entityType from "../../../api/entityType";
import {
  clearNewInventoryMovementAction,
  clearOperationResultAction,
  initializeInventoryMovementUI,
  readAction,
  searchMovementsAction,
  transformMovementInput,
  undoAction,
} from "../../../store/inventory/inventoryActions";
import {
  clearOperationResult,
  fetchReport,
  resetReportState,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import pageTitleMap from "../../navigation/pageTitleMap";
import { BackButton } from "../../shared/BackButton";
import { PdfViewerModal } from "../../shared/PdfViewer";
import { usePreservableState } from "../../shared/PreservableStatePageWrapper";
import CustomCheckbox from "../../shared/form/reactHookForm/CustomCheckbox";
import { formatDate } from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { inventoryMovementSchema } from "../reports/reportSchemas";
import MoveInventoryItems from "../shared/MoveInventoryItems";
import { types } from "../shared/movementTypes";

// Constants
const initialValues = {
  NewPageForEachSeries: false,
  IncludeLogSheet: false,
};

const Movement = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(inventoryMovementSchema),
  });

  const includeLogSheet = watch("IncludeLogSheet");

  //
  const { movementKey } = useParams();
  const dispatch = useDispatch();
  const { getPreviousPageName, isRestorable, restorePreviousPage } =
    usePreservableState();
  const navigate = useNavigate();

  //
  const isUndoingMovement = useRef(false);

  //
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const newMovement = useSelector(
    (state) => state.inventory.moveInventory.newMovement
  );
  const selected = newMovement.entity && newMovement.entity.Header;
  const searchCriteria = useSelector(
    (state) => state.inventory.inventoryMovements.searchCriteria
  );
  const movementLoading = useSelector(
    (state) => state.inventory.inventoryMovements.loading
  );

  const loading = newMovement.loading;
  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );
  const reportOperationResult = useSelector(
    (state) => state.report.operationResult
  );

  //
  useEffect(() => {
    dispatch(readAction(movementKey));
  }, [movementKey]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult]);

  useEffect(() => {
    if (operationResult != null && movementLoading == false) {
      if (isUndoingMovement.current === true) {
        // If undoing was successful
        if (operationResult.Outcome === "Information") {
          dispatch(clearOperationResultAction());
          if (getPreviousPageName() !== pageTitleMap["/movements"].name) {
            dispatch(clearNewInventoryMovementAction());
            dispatch(initializeInventoryMovementUI());
          } else {
            dispatch(searchMovementsAction(searchCriteria));
          }
          isRestorable && restorePreviousPage();
          navigate(-1);
        }
        isUndoingMovement.current = false;
      }
    }
  }, [operationResult, movementLoading]);

  useEffect(() => {
    dispatch(resetReportState());
  }, []);

  useEffect(() => {
    openNotificationOperationResult(reportOperationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [reportOperationResult]);

  //
  const handleUndo = () => {
    isUndoingMovement.current = true;
    dispatch(undoAction(selected.InventoryMovementKey));
  };

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformMovementInput({
          movementKey: selected.InventoryMovementKey,
          inventoryMovementTypeDesc: selected.InventoryMovementTypeDesc,
          transactionDate: selected.TransactionDate,
          newPageForEachSeries: values.NewPageForEachSeries,
          includeLogSheet: values.IncludeLogSheet,
        }),
        entityType.INVENTORY,
        "PrintMovement"
      )
    );
    dispatch(setReportModalVisibility(true));
    reset(values);
  };

  return (
    <PermissionWrapper entityName="MoveInventory">
      <Spin spinning={loading} delay={500}>
        <Form
          size="small"
          autoComplete="off"
          onFinish={() => handleSubmit(onSubmitHandler)()}
        >
          <Row style={{ height: 30 }}>
            <Col span={24}>
              <Row>
                <Col style={{ paddingRight: 30 }}>
                  <Space>
                    <BackButton
                      onGoBackHandler={() => {
                        // If we're going back to a movement (instead of Search page)
                        if (
                          getPreviousPageName() !==
                          pageTitleMap["/movements"].name
                        ) {
                          dispatch(clearNewInventoryMovementAction());
                          dispatch(initializeInventoryMovementUI());
                        }
                        dispatch(clearOperationResultAction());
                      }}
                    />
                    <Popconfirm
                      title="Undo movement?"
                      onConfirm={() => handleUndo()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button disabled={selected && selected.Status === 2}>
                        <UndoOutlined />
                        Undo
                      </Button>
                    </Popconfirm>
                    <Button
                      disabled={!isValid}
                      autoFocus={true}
                      id="processButton"
                      type="primary"
                      htmlType="submit"
                    >
                      <PrinterOutlined />
                      Preview
                    </Button>
                  </Space>
                </Col>
                <Col style={{ paddingRight: 20 }}>
                  <CustomCheckbox
                    control={control}
                    // layout={layout}
                    hasFeedback={true}
                    required={false}
                    formLabel=""
                    name="IncludeLogSheet"
                  >
                    Include Log Sheet in Report
                  </CustomCheckbox>
                </Col>
                {includeLogSheet && (
                  <>
                    <Col style={{ paddingRight: 20 }}>
                      <CustomCheckbox
                        control={control}
                        // layout={layout}
                        hasFeedback={true}
                        required={false}
                        formLabel=""
                        name="NewPageForEachSeries"
                      >
                        New Page For Each Series
                      </CustomCheckbox>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <Divider
            orientation="left"
            plain
            style={{ marginTop: 8, marginBottom: 8 }}
          >
            {/* <Typography.Text strong>Information</Typography.Text> */}
          </Divider>
          <Row>
            <Col span={24}>
              <Descriptions column={3}>
                <DescriptionsItem label="Agency">
                  {selected && selected.AgencyNumberAndName}
                </DescriptionsItem>
                <DescriptionsItem label="Stock Center Confirmation">
                  {selected && selected.StockCentreConfNo}
                </DescriptionsItem>
                <DescriptionsItem label="Changed By">
                  {selected && (
                    <div
                      className={
                        maskSensitiveData === true ? "blurry-text" : ""
                      }
                    >
                      {selected.ChangedByUserName}
                    </div>
                  )}
                </DescriptionsItem>
                <DescriptionsItem label="Type">
                  {selected && selected.InventoryMovementTypeDesc}
                </DescriptionsItem>
                <DescriptionsItem label="Quantity">
                  {selected && selected.Quantity}
                </DescriptionsItem>
                <DescriptionsItem label="Transaction Date">
                  {selected && formatDate(selected.TransactionDate)}
                </DescriptionsItem>
                <DescriptionsItem label="Status">
                  {selected && selected.StatusDescritpion}
                </DescriptionsItem>
                {selected &&
                  (selected.InventoryMovementType ===
                    types.ReceiptFromAnotherAgency ||
                    selected.InventoryMovementType ===
                      types.TransferToAnotherAgency) && (
                    <DescriptionsItem
                      label={
                        selected.InventoryMovementType ===
                        types.ReceiptFromAnotherAgency
                          ? "Received From Agency"
                          : "Transferred To Agency"
                      }
                    >
                      {selected &&
                        `${selected.TransferredToAgencyNumber} - ${selected.TransferredToAgencyName}`}
                    </DescriptionsItem>
                  )}
                {selected &&
                  (selected.InventoryMovementType ===
                    types.AllocationFromRoadAgentToRoadAgent ||
                    selected.InventoryMovementType ===
                      types.ReturnFromRoadAgentToOffice) && (
                    <DescriptionsItem label="From">
                      {selected && selected.FromAgentUserName}
                    </DescriptionsItem>
                  )}

                {selected &&
                  (selected.InventoryMovementType ===
                    types.AllocationFromRoadAgentToRoadAgent ||
                    selected.InventoryMovementType ===
                      types.AllocationFromOfficeToRoadAgent) && (
                    <DescriptionsItem label="To">
                      {selected && selected.ToAgentUserName}
                    </DescriptionsItem>
                  )}
              </Descriptions>
            </Col>
          </Row>
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          <MoveInventoryItems hideRemoveItemColumn={true} />
        </Form>
        <PdfViewerModal />
      </Spin>
    </PermissionWrapper>
  );
};

export default Movement;
