import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewInventoryMovementAction,
  listAvailableItemRangesAction,
  processInventoryMovementAction,
  setTransferInventoryCurrentStepAction,
  validateAgencyForTransferAction,
} from "../../../store/inventory/inventoryActions";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import AllocatePlatesForm from "../shared/AllocatePlatesForm";
import MoveInventoryItems from "../shared/MoveInventoryItems";
import { transferStockToAnotherBrokerSchema } from "../shared/moveInventorySchemas";
import { movementOptions } from "../shared/movementTypes";

// Constants
const initialValues = {
  AgencyKey: "",
  InventoryMovementType: "",
  InventoryMovementKey: "",
  TransactionDate: "",
  TransferredToAgencyNumber: "",
  StockCentreConfNo: "",
};

const TransferShipment = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    watch,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(transferStockToAnotherBrokerSchema),
  });

  const transactionDate = watch("TransactionDate");

  //
  const dispatch = useDispatch();

  //
  const [currentTransferAgencyNumber, setCurrentTransferAgencyNumber] =
    useState("");
  const [currentDefaultValues, setCurrentDefaultValues] = useState(null);

  //
  const newMovement = useSelector(
    (state) => state.inventory.moveInventory.newMovement
  );
  const movementType =
    newMovement.entity && newMovement.entity.Header.InventoryMovementType;

  const movementTypeOption = movementOptions.filter(
    (o) => o.value === movementType
  )[0];
  const movementTypeDescription =
    movementTypeOption && movementTypeOption.label;
  const loading = newMovement.loading;
  const header = newMovement.entity && newMovement.entity.Header;
  const agencyKey = header && header.AgencyKey;
  const type = header && header.InventoryMovementType;
  const movementItems = newMovement.entity && newMovement.entity.Items;

  useEffect(() => {
    dispatch(listAvailableItemRangesAction(agencyKey, type));
  }, [agencyKey, type]);

  useEffect(() => {
    if (
      header != null &&
      (currentDefaultValues == null || isNull(currentDefaultValues))
    ) {
      setCurrentDefaultValues(header);
      reset({ ...header });
      trigger();
    }
  }, [header]);

  const onSubmitHandler = (values) => {
    dispatch(
      processInventoryMovementAction({
        AgencyKey: header.AgencyKey,
        InventoryMovementType: header.InventoryMovementType,
        InventoryMovementKey: newMovement.entity.Header.InventoryMovementKey,
        TransactionDate: values.TransactionDate,
        TransferredToAgencyNumber: values.TransferredToAgencyNumber,
        StockCentreConfNo: values.StockCentreConfNo,
      })
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" onFinish={() => {}}>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <Row>
              <Col span={7}>
                <Descriptions column={1}>
                  <DescriptionsItem label="Movement Type">
                    {movementTypeDescription}
                  </DescriptionsItem>
                  <DescriptionsItem label="Agency" style={{ padding: 0 }}>
                    {newMovement.entity &&
                      newMovement.entity.Header.AgencyNumberAndName}
                  </DescriptionsItem>
                </Descriptions>
              </Col>
              <Col span={8}>
                <CustomInput
                  autoFocus={true}
                  control={control}
                  layout={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                  }}
                  formLabel="Receiving Agency"
                  name="TransferredToAgencyNumber"
                  style={{ width: 150 }}
                  required={true}
                  onBlur={() => {
                    var agencyNum = getValues().TransferredToAgencyNumber;

                    if (
                      agencyNum != null &&
                      currentTransferAgencyNumber != "" &&
                      agencyNum.length != 5
                    ) {
                      setCurrentTransferAgencyNumber("");
                    }
                    if (
                      header.AgencyNumber !== agencyNum &&
                      agencyNum != null &&
                      agencyNum.length === 5 &&
                      currentTransferAgencyNumber != agencyNum
                    ) {
                      setCurrentTransferAgencyNumber(agencyNum);
                      agencyNum &&
                        dispatch(validateAgencyForTransferAction(agencyNum));
                    }
                  }}
                />

                <CustomInput
                  hidden={true}
                  control={control}
                  layout={{
                    labelCol: { span: 0 },
                    wrapperCol: { span: 0 },
                  }}
                  formLabel=""
                  name="AgencyNumber"
                  formStyle={{ display: "none" }}
                  style={{ width: 0 }}
                  type="hidden"
                />

                <CustomDatePicker
                  formItem={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                    required: true,
                    style: { marginBottom: 0 },
                    label: "Transaction Date",
                  }}
                  control={control}
                  name="TransactionDate"
                />
              </Col>
              <Col span={9}>
                <CustomInput
                  control={control}
                  layout={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 10 },
                  }}
                  formLabel="Stock Center Confirmation"
                  name="StockCentreConfNo"
                  style={{ width: 150 }}
                />
              </Col>
            </Row>
            <AllocatePlatesForm
              headerValues={{
                ...getValues(),
                TransactionDate: transactionDate,
              }}
            />
            <MoveInventoryItems />
            <Row style={{ paddingTop: 16 }}>
              <Col span={8}>
                <Button
                  danger
                  size="small"
                  onClick={() => {
                    dispatch(clearNewInventoryMovementAction()).then(
                      dispatch(setTransferInventoryCurrentStepAction(0))
                    );
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col offset={8} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        size="small"
                        onClick={() => {
                          dispatch(setTransferInventoryCurrentStepAction(0));
                        }}
                      >
                        <LeftCircleOutlined />
                        Previous
                      </Button>

                      <Space>
                        <Popconfirm
                          title="This inventory movement will now be completed. Would you like to proceed?"
                          onConfirm={() => handleSubmit(onSubmitHandler)()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            id="processButton"
                            type="primary"
                            size="small"
                            disabled={
                              !isValid ||
                              (movementItems && movementItems.length === 0)
                            }
                            loading={loading}
                          >
                            Complete
                            <RightCircleOutlined />
                          </Button>
                        </Popconfirm>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default TransferShipment;
