export enum tableNames {
  Agencies = "Agencies",
  BatchView = "BatchView",
  BatchSearch = "BatchSearch",
  BatchCreateStep1 = "BatchCreateStep1",
  BatchCreateStep2 = "BatchCreateStep2",
  DocumentSearch = "DocumentSearch",
  InventoryItemsSearch = "InventoryItemSearch",
  ItemHistoryTable = "ItemHistoryTable",
  MovementSearch = "MovementSearch",
  MoveInventoryItems = "MoveInventoryItems",
  LegacyUsers = "LegacyUsers",
  InventoryAllocationUsers = "InventoryAllocationUsers",
  UserAgencyRole = "UserAgencyRole",
  UserAgencyRoles = "UserAgencyRoles",
  Users = "Users",
  Producers = "Producers",
  CommissionRates = "CommissionRates",
  DataDownloadLog = "DataDownloadLog",
  DownloadDetails = "DownloadDetails",
  OnlineRenewals = "OnlineRenewals",
  Announcements = "Announcements",
  ManageRenewals = "ManageRenewals",
  UnbatchedDocs = "UnbatchedDocs",
  ProductionSummaryData = "ProductionSummaryData",
  AppLogs = "AppLogs",
  FleetPolicyToClientMap = "FleetPolicyToClientMap",
  MoveDocuments = "MoveDocuments",
  PaymentSchedule = "PaymentSchedule",
}
