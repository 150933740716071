import { Skeleton } from "antd";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  addPageToPreserve,
  addTableToPreserve,
  clearAllPreservables,
  removePageToPreserve,
} from "../../store/ui/uiActions";
import pageTitleMap from "../navigation/pageTitleMap";
import { Link } from "react-router-dom";

interface PreservableStatePageWrapperProps {
  onCleanup: () => void;
  children: React.ReactNode;
  pageName?: string;
  tableNames?: string[];
}

interface PreservableStateLinkProps {
  to: string;
  children: React.ReactNode;
}

interface LocationPreserveHook {
  restorePreviousPage: () => void;
  getPreviousPageName: () => string;
  getPreviousPagePath: () => string;
  isRestorable: boolean;
}

interface PreservableStatePageWrapperContextProps {
  pageName?: string;
  tableNames?: string[];
}

type Nullable<T> = T | null;

type Preservable = {
  page: string;
  tables?: string[];
};

type NavigationPreserveHook = (route: string) => void;

//
const PreservableStatePageWrapperContext: React.Context<
  Nullable<PreservableStatePageWrapperContextProps>
> = createContext<Nullable<PreservableStatePageWrapperContextProps>>(null);

/*******
 *
 * In this component, we only care about preserving pages.
 * All other preservable components' preservations are managed at component level
 * Here, we manage pages and the global preservation state (for ex. clearing all preservables)
 *
 *******/
export const PreservableStatePageWrapper: React.FC<
  PreservableStatePageWrapperProps
> = (props) => {
  const { pageName: customPageName, onCleanup, tableNames } = props;

  //
  const dispatch = useDispatch();
  const { key, pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  //
  const pagesToPreserve = useSelector(
    (state: any) => state?.ui?.preservable?.pages
  );

  //
  const pageName =
    customPageName != null
      ? `${customPageName}__${key}`
      : `${pathname}__${key}`;

  //
  useEffect(() => {
    if (isLoading !== true) return;

    // Clean up if we don't need to preserve
    const shouldPreserve =
      pagesToPreserve && pagesToPreserve.includes(pageName);
    if (shouldPreserve !== true) {
      onCleanup();
      // If this page was not preserved, it's safe to assume
      // that all other preservables should be cleared
      // (because they should be associated with this page, if there is any)
      dispatch(clearAllPreservables());
    }

    // Display the UI
    setIsLoading(false);
  }, [pageName]);

  // When we leave the page, we assume that we don't need to preserve it anymore
  useEffect(() => {
    return () => {
      dispatch(removePageToPreserve(pageName));
    };
  }, []);

  if (isLoading === true) {
    return <Skeleton active />;
  } else {
    return (
      <PreservableStatePageWrapperContext.Provider
        value={{ pageName: pageName, tableNames: tableNames }}
      >
        <div>{props.children}</div>
      </PreservableStatePageWrapperContext.Provider>
    );
  }
};

/*******
 *
 * This should be used instead of the regular Link component
 *
 *******/
export const PreservableStateLink: React.FC<PreservableStateLinkProps> = ({
  to,
  children,
}) => {
  const preservableData = useContext(PreservableStatePageWrapperContext);
  return (
    <Link
      to={to}
      state={
        preservableData != null &&
        preservableData.pageName != null &&
        preservableData.pageName !== ""
          ? {
              preservable: {
                page: preservableData.pageName,
                tables: preservableData.tableNames,
              },
            }
          : null
      }
    >
      {children}
    </Link>
  );
};

/*******
 *
 * We must add preservation logic for all components here
 * For example, if we want to preserve pages and tables, then
 * restorePreviousPage should take care of preserving both
 *
 *******/
export const usePreservableState = (): LocationPreserveHook => {
  const dispatch = useDispatch();
  const location = useLocation();
  const preservableData: Preservable | undefined = location?.state?.preservable;

  const isRestorable = preservableData != null;

  const restorePreviousPage = useCallback(() => {
    if (isRestorable) {
      dispatch(addPageToPreserve(preservableData.page));
      if (preservableData.tables != null) {
        const pageNameForTable =
          preservableData.page.split("__")[1] ?? preservableData.page;

        preservableData.tables.forEach((t) => {
          // Each table will be associated to a page
          let tableName = `${pageNameForTable}_${t}`;
          dispatch(addTableToPreserve(tableName));
        });
      }
    }
  }, [preservableData]);

  const getPreviousPagePath: () => string = useCallback(() => {
    if (preservableData != null) {
      var previousPageName = preservableData.page.split("__")[0];
      return previousPageName;
    }
    return "";
  }, [preservableData]);

  const getPreviousPageName: () => string = useCallback(() => {
    if (preservableData != null) {
      var previousPageName = preservableData.page.split("__")[0];
      // Transform previousPageName, ex: /batches/update/1 becomes /batches/update
      const parts = previousPageName.split("/");
      const processedPreviousPageName = parts.slice(0, 3).join("/");
      return (
        pageTitleMap[processedPreviousPageName]?.name ??
        processedPreviousPageName
      );
    }
    return "";
  }, [preservableData]);

  return {
    restorePreviousPage,
    isRestorable,
    getPreviousPageName,
    getPreviousPagePath,
  };
};

/*******
 *
 * With this hook we can navigate while making sure the page is restorable
 * It will have to be restored with the usePreservableState hook
 *
 *******/
export const usePreservableNavigate = (): NavigationPreserveHook => {
  const navigate = useNavigate();
  const preservableData = useContext(PreservableStatePageWrapperContext);

  const preservableNavigate = (route: string) =>
    navigate(route, {
      state:
        preservableData != null &&
        preservableData.pageName != null &&
        preservableData.pageName !== ""
          ? {
              preservable: {
                page: preservableData.pageName,
                tables: preservableData.tableNames,
              },
            }
          : null,
    });

  return preservableNavigate;
};
