export const columnTypes = {
  NONE: "NONE",
  TEXT: "TEXT",
  TEXT_MULTI_FILTER: "TEXT_MULTI_FILTER",
  TEXT_CUSTOM_SORTER: "TEXT_CUSTOM_SORTER",
  BOOLEAN: "BOOLEAN",
  ACTION: "ACTION",
  PHONE: "PHONE",
  DATE: "DATE",
  DATETIME: "DATETIME",
  CURRENCY: "CURRENCY",
  ACTION_WITHOUT_FILTER: "ACTION_WITHOUT_FILTER",
  NUMBER: "NUMBER",
  NUMBER_NO_FILTER: "NUMBER_NO_FILTER",
  STATUS: "STATUS",
};
