import {
  DeleteOutlined,
  FileExcelOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Divider,
  Form,
  Popconfirm,
  Popover,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import {
  bulkDeleteUsers,
  clearBulkDeleteOperationResults,
  clearIdentityOperationResult,
  listUsersAction,
  resendEmailConfirmationAction,
  unlockUserAccount,
} from "../../store/user/general/userActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import {
  PreservableStateLink,
  PreservableStatePageWrapper,
  usePreservableNavigate,
} from "../shared/PreservableStatePageWrapper";
import { exportDataToExcel } from "../shared/exportToExcel";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { AdminTasks } from "../shared/securityRoleTasks";
import { agenciesListEqual, compareArrayOfStrings } from "../shared/sorting";
import { SearchableTable } from "../shared/table/SearchableTable";
import { columnTypes } from "../shared/table/columnTypes";
import { tableNames } from "../shared/table/tableNames";
import CreateUserForm from "./CreateUserForm";
import UpdateUserForm from "./UpdateUserForm";
import { securityRolesFilterFunction } from "./securityRolesFilterFunction";
import { usersSchema } from "./userSchema";

const { Link, Paragraph, Text } = Typography;

const layout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const Users = () => {
  return (
    <PreservableStatePageWrapper
      onCleanup={() => {}}
      tableNames={[tableNames.Users]}
    >
      <UsersBody />
    </PreservableStatePageWrapper>
  );
};

const UsersBody = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const preservableNavigate = usePreservableNavigate();
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const list = useSelector((state) => state.user.general.list);
  const loading = useSelector((state) => state.user.general.loading);
  const [selectedRows, setSelectedRows] = useState([]);
  const temporarySelectedRows = useState(null);
  const isBulkDeleting = useSelector(
    (state) => state.user.general.isBulkDeleting
  );
  const identityResult = useSelector(
    (state) => state.user.general.identityResult
  );
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const bulkDeleteOperationResult = useSelector(
    (state) => state.user.general.bulkDeleteOperationResult
  );
  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.Users)
  );
  const tableCurrentDataSource = tableData?.extra?.currentDataSource;

  const { reset, control, setValue, getValues, trigger } = useForm({
    mode: "onChange",
    resolver: yupResolver(usersSchema),
  });

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, AdminTasks));
    }
  }, [brokerId]);

  useEffect(() => {
    return () => {
      // Clear the list of all agencies
      dispatch(clearListCompactAction());
      dispatch(clearBulkDeleteOperationResults());
      dispatch(clearIdentityOperationResult());
    };
  }, []);

  const [currentAgencies, setCurrentAgencies] = useState([]);

  useEffect(() => {
    if (bulkDeleteOperationResult != null) {
      openNotificationOperationResult(bulkDeleteOperationResult, () =>
        dispatch(clearBulkDeleteOperationResults())
      );
      dispatch(clearBulkDeleteOperationResults());
      dispatch(listUsersAction(getValues().AgencyKeys));
    }
  }, [bulkDeleteOperationResult]);

  useEffect(() => {
    if (identityResult != null) {
      openNotificationOperationResult(identityResult, () =>
        dispatch(clearIdentityOperationResult())
      );
      dispatch(clearIdentityOperationResult());
      dispatch(listUsersAction(getValues().AgencyKeys));
    }
  }, [identityResult]);

  useEffect(() => {
    if (list != null) {
      if (!isEmpty(selectedRows)) {
        const currentDataSourceRowKeys = new Set(list.map((r) => r.UserKey));
        var newSelectedRows = selectedRows.filter((r) =>
          currentDataSourceRowKeys.has(r)
        );
        setSelectedRows(newSelectedRows ?? []);
        temporarySelectedRows.current = newSelectedRows;
      }
    } else {
      if (!isEmpty(selectedRows)) {
        setSelectedRows([]);
        temporarySelectedRows.current = [];
      }
    }
  }, [list]);

  useEffect(() => {
    var currentSelectedRows =
      temporarySelectedRows.current == null
        ? selectedRows
        : temporarySelectedRows.current;

    if (
      !isNull(tableCurrentDataSource) &&
      !isEmpty(currentSelectedRows) &&
      list?.length > tableCurrentDataSource?.length
    ) {
      const currentDataSourceRowKeys = new Set(
        tableCurrentDataSource.map((r) => r.UserKey)
      );
      const currentDataSourceSelectedRowKeys = currentSelectedRows.filter((r) =>
        currentDataSourceRowKeys.has(r)
      );
      setSelectedRows(currentDataSourceSelectedRowKeys ?? []);
    }
    temporarySelectedRows.current = null;
  }, [tableCurrentDataSource, list]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);

      var initialAgency = getInitialAgency(homeAgency, agencies);
      const initialValues = {
        AgencyKeys: [initialAgency],
      };

      initialAgency && dispatch(listUsersAction(initialValues?.AgencyKeys));

      reset(initialValues);

      trigger();
    }
  }, [agencies, homeAgency]);

  const onSelectedRowsChange = (keys, _) => {
    setSelectedRows(keys ?? []);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "NameFirst",
      type: columnTypes.TEXT,
      render: (value, record) => (
        <PreservableStateLink to={`updateuserform/${record.UserKey}`}>
          <div className={maskSensitiveData === true ? "blurry-text-link" : ""}>
            {value}
          </div>
        </PreservableStateLink>
      ),
      width: 120,
    },

    {
      title: "Last Name",
      dataIndex: "NameLast",
      type: columnTypes.TEXT,
      render: (value, record) => (
        <PreservableStateLink to={`updateuserform/${record.UserKey}`}>
          <div className={maskSensitiveData === true ? "blurry-text-link" : ""}>
            {value}
          </div>
        </PreservableStateLink>
      ),
      width: 130,
    },

    {
      title: "Home Agency",
      dataIndex: "AgencyFullName",
      type: columnTypes.TEXT,
      width: 180,
    },
    {
      title: "Access Enabled",
      dataIndex: "AccessEnabled",
      type: columnTypes.BOOLEAN,
      width: 100,
    },
    {
      title: "Security Roles",
      dataIndex: "Roles",
      key: "Roles",
      sorter: (a, b) => compareArrayOfStrings(a.Roles, b.Roles),
      onFilter: securityRolesFilterFunction,
      type: columnTypes.TEXT_CUSTOM_SORTER,
      render: (_, record) => {
        return record.AccessEnabled && (record.Roles?.length ?? 0) > 1 ? (
          <Popover
            content={
              <>
                {record.Roles.map((a) => (
                  <p>{a}</p>
                ))}
              </>
            }
          >
            <Link style={{ fontSize: "13px" }}>{`${
              record.Roles?.length ?? 0
            } Roles`}</Link>
          </Popover>
        ) : record.AccessEnabled && (record.Roles?.length ?? 0) === 1 ? (
          record.Roles[0]
        ) : (
          <></>
        );
      },
      width: 100,
    },

    {
      title: "User Account Email",
      dataIndex: "Email",
      type: columnTypes.TEXT,
      render: (text, record) =>
        record.Id ? (
          <div className={maskSensitiveData === true ? "blurry-text-link" : ""}>
            {text}
          </div>
        ) : (
          <></>
        ),
      width: 220,
    },
    {
      title: "Email Confirmed",
      dataIndex: "EmailConfirmed",
      type: columnTypes.BOOLEAN,
      render: (text, record) =>
        !record.EmailConfirmed && record.AccessEnabled ? (
          <Popconfirm
            title="Resend Email Confirmation?"
            onConfirm={() => dispatch(resendEmailConfirmationAction(record.Id))}
          >
            <Button style={{ fontSize: "13px" }} size="small" type="link">
              No, Resend
            </Button>
          </Popconfirm>
        ) : record.EmailConfirmed && record.AccessEnabled ? (
          <div style={{ padding: "0 7px" }}>Yes</div>
        ) : (
          <></>
        ),
      width: 100,
    },
    {
      title: "Password Set",
      dataIndex: "PasswordSet",
      type: columnTypes.BOOLEAN,
      render: (text, record) =>
        record.EmailConfirmed && record.Id && !record.PasswordSet ? (
          <div style={{ padding: "0 7px" }}>
            <Tooltip title="This user's email is confirmed, but the password is not set. Please advise the user to set their password using the 'Forgot Password?' link on the login page.">
              <Text type="danger">
                <Space size={4}>
                  No
                  <QuestionCircleOutlined />
                </Space>
              </Text>
            </Tooltip>
          </div>
        ) : !record.PasswordSet && record.AccessEnabled ? (
          <div style={{ padding: "0 7px" }}>No</div>
        ) : record.PasswordSet && record.AccessEnabled ? (
          <div style={{ padding: "0 7px" }}>Yes</div>
        ) : (
          <></>
        ),
      width: 100,
    },
    {
      title: "Lockout Expiry",
      dataIndex: "LockoutEnd",
      type: columnTypes.DATETIME,
      width: 100,
      defaultFilteredValue: null,
      filteredValue: null,
      filterResetToDefaultFilteredValue: true,
      filtered: false,
      filterIcon: <div></div>,
      filterDropdown: () => <div></div>,
      render: (value, record) => {
        var dateString = "";
        if (value != null) {
          const eventTimestamp = new Date(value).getTime();
          const currentTimestamp = new Date().getTime();

          if (eventTimestamp > currentTimestamp) {
            const differenceInMinutes =
              (eventTimestamp - currentTimestamp) / (1000 * 60);

            if (differenceInMinutes > 1) {
              dateString = `Ends in ${Math.floor(
                differenceInMinutes
              ).toString()} minute(s). Unlock?`;
            } else {
              const differenceInSeconds = Math.floor(differenceInMinutes * 60);
              dateString = `Ends in ${differenceInSeconds.toString()} seconds. Unlock?`;
            }
          }
        }

        return isEmpty(dateString) || !record.AccessEnabled ? (
          <></>
        ) : (
          <Popconfirm
            title="Unlock User Account"
            okText="Unlock"
            description={
              <>
                This account is temporarily locked out because of too
                <br /> many failed login attempts. Do you wish to unlock it?
              </>
            }
            onConfirm={() => {
              dispatch(unlockUserAccount(record.Id));
            }}
          >
            <Link style={{ fontSize: "13px" }}>{dateString}</Link>
          </Popconfirm>
        );
      },
    },

    {
      title: "Employment End Date",
      dataIndex: "EmploymentEndDate",
      type: columnTypes.DATE,
      width: 100,
    },
  ];

  const [isExporting, setIsExporting] = useState(false);

  const exportTableData = () => {
    setIsExporting(true);
    exportDataToExcel(
      tableData?.extra.currentDataSource,
      ["Id", "ClientId", "UserKey", "Roles", "AccessFailedCount", "LockoutEnd"],
      maskSensitiveData,
      "Users.csv",
      [
        "PasswordSet",
        "EmailConfirmed",
        "IsAllocatedInventory",
        "IsPaidCommission",
        "AccessEnabled",
      ],
      ["EmploymentStartDate", "EmploymentEndDate"],
      {
        NameFirst: "First Name",
        NameLast: "Last Name",
        AgencyFullName: "Home Agency",
        AccessEnabled: "Access Enabled",
        Email: "User Account Email",
        EmailConfirmed: "Email Confirmed",
        PasswordSet: "Password Set",
        EmploymentStartDate: "Employment Start Date",
        EmploymentEndDate: "Employment End Date",
        Comment: "Note",
        IsAllocatedInventory: "Road Agent",
        IsPaidCommission: "Commissionable Agent",
      }
    );
    setIsExporting(false);
  };

  const exportButton = (
    <Button
      size={"small"}
      loading={isExporting}
      onClick={() => exportTableData()}
    >
      <FileExcelOutlined />
      Export
    </Button>
  );

  const addButton = (
    <Button
      size={"small"}
      type="primary"
      onClick={() => {
        preservableNavigate(`createuserform`);
      }}
    >
      <UserAddOutlined />
      Add New
    </Button>
  );

  const migrateButton = (
    <Button
      size={"small"}
      type="dashed"
      onClick={() => {
        navigate(`/legacyUsers`);
      }}
    >
      <TeamOutlined />
      Go To Legacy Users
    </Button>
  );

  const deleteButton = (
    <Popconfirm
      title="Delete account"
      description={
        <>
          <Paragraph>
            You are about to delete {selectedRows?.length ?? 0} user account(s).
            <br />
            This action cannot be undone.
            <br />
            Would you like to proceed?
          </Paragraph>
        </>
      }
      onCancel={() => {
        dispatch(
          bulkDeleteUsers({
            UserKeys: selectedRows,
            IsFromLegacyPage: false,
          })
        );
      }}
      cancelText="Yes, Delete"
      okText="No, Cancel Delete"
    >
      <Button
        type="default"
        danger={true}
        size={"small"}
        disabled={isEmpty(selectedRows) || isBulkDeleting}
        loading={isBulkDeleting}
        onClick={() => {}}
        style={{ marginLeft: "105px" }}
      >
        <DeleteOutlined />
        Bulk Delete{" "}
        {!isEmpty(selectedRows)
          ? `(${(selectedRows?.length ?? 0).toString()})`
          : ""}
      </Button>
    </Popconfirm>
  );

  return (
    <PermissionWrapper entityName="Admin">
      <Routes>
        <Route
          path=""
          element={
            <Spin delay={500} spinning={isBulkDeleting}>
              <Row>
                <Col span={24}>
                  <Form size="small" autoComplete="off">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                        <AgencyMultiSelectEx
                          layout={layout}
                          formLabel="Agency"
                          agencies={currentAgencies}
                          isLoading={isEmpty(agencies)}
                          setValue={setValue}
                          control={control}
                          name="AgencyKeys"
                          required={true}
                          onChange={(newAgencyKeys) =>
                            dispatch(listUsersAction(newAgencyKeys))
                          }
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0 }} />
              <Row>
                <Col span={24}>
                  <SearchableTable
                    size="small"
                    tableName={tableNames.Users}
                    dataSource={list}
                    columns={columns}
                    loading={loading}
                    rowKey={(r) => r.UserKey}
                    buttons={[
                      addButton,
                      migrateButton,
                      exportButton,
                      deleteButton,
                    ]}
                    scroll={{ y: 650, x: 1260 }}
                    rowSelection={{
                      columnWidth: 30,
                      selectedRowKeys: selectedRows,
                      onChange: onSelectedRowsChange,
                      fixed: true,
                      hideSelectAll: false,
                    }}
                    pageSize={100}
                  />
                </Col>
              </Row>
            </Spin>
          }
        />

        <Route
          path="createuserform"
          element={<CreateUserForm agencyKeys={getValues().AgencyKeys} />}
        />

        <Route
          path="updateuserform/:userKey"
          element={<UpdateUserForm agencyKeys={getValues().AgencyKeys} />}
        />
      </Routes>
    </PermissionWrapper>
  );
};
export default Users;
