import { Steps } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewInventoryMovementAction,
  clearOperationResultAction,
  initializeInventoryMovementUI,
  setReturnToICBCCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { PreservableStatePageWrapper } from "../../shared/PreservableStatePageWrapper";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import MoveInventorySummary from "../shared/MoveInventorySummary";
import ReturnToICBCStep1 from "./ReturnToICBCStep1";
import ReturnToICBCStep2 from "./ReturnToICBCStep2";

// Constants
const stepsContent = {
  padding: 8,
};

const ReturnToICBCSteps = () => {
  //
  const dispatch = useDispatch();

  //
  const currentStep = useSelector(
    (state) => state.inventory.moveInventory.ui.returnToICBCCurrentStep
  );
  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  //
  useEffect(() => {
    openNotificationOperationResult(
      operationResult,
      () => dispatch(clearOperationResultAction()),
      false
    );

    if (
      operationResult &&
      operationResult.Name === "ProcessMovement" &&
      operationResult.Outcome === "Success"
    ) {
      dispatch(setReturnToICBCCurrentStepAction(2));
    }
  }, [operationResult]);

  return (
    <PermissionWrapper entityName="MoveInventory">
      <PreservableStatePageWrapper
        onCleanup={() => {
          dispatch(clearNewInventoryMovementAction());
          dispatch(initializeInventoryMovementUI());
        }}
      >
        <Steps
          current={currentStep}
          size="small"
          items={[
            { title: "Select Return Type" },
            { title: "Define Details" },
            { title: "Confirmation" },
          ]}
        />

        <div style={stepsContent}>
          {currentStep === 0 && <ReturnToICBCStep1 />}
          {currentStep === 1 && <ReturnToICBCStep2 />}
          {currentStep === 2 && (
            <MoveInventorySummary
              returnButtonTitle="Return More Inventory"
              resetAction={() => {
                dispatch(setReturnToICBCCurrentStepAction(0));
              }}
            />
          )}
        </div>
      </PreservableStatePageWrapper>
    </PermissionWrapper>
  );
};

export default ReturnToICBCSteps;
