import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import { transformProductionByDayHourInput } from "../../store/productionByDayHour/productionByDayHourActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../store/report/reportActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import PdfViewer from "../shared/PdfViewer";
import ReportParamPanelHeader from "../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import { DrillDownSortField } from "../shared/form/DrillDownSortField";
import DrillDownSortFieldSelectEx from "../shared/form/DrillDownSortFieldSelectEx";
import ExtraReportFilterContainer from "../shared/form/ExtraReportFiltersContainer";
import ProducerSelectEx from "../shared/form/ProducerSelectEx";
import { fixedDateRanges } from "../shared/form/fixedDateRanges";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toEndOfDay, toStartOfDay } from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { ViewProductivityDataFullAccess } from "../shared/securityRoleTasks";
import { agenciesListEqual } from "../shared/sorting";
import { productionByDayHourReportSchema } from "./productionByDayHourSchemas";
import {
  dayOfWeekOptions,
  drillDownSortFieldOptions,
  sortGroupDrillDownFields,
} from "./reportParameters";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKeys: [],
  FixedDateRange: fixedDateRanges.MTD,
  TransactionDate: ["", ""],
  IncludeNonFleetNonGarage: true,
  IncludeFleetGarage: true,
  IncludeCancellations: false,
  DrillDownSortFields: [
    new DrillDownSortField(sortGroupDrillDownFields.AGENCY_NAME),
  ],
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
  DayOfWeek: 0,
  HourOfDay: [6, 23],
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const ProductionByDayHourReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, errors, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(productionByDayHourReportSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [extraDisableOptions, setExtraDisableOptions] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const isExporting = useSelector((state) => state.report.isExporting);
  const operationResult = useSelector((state) => state.report.operationResult);
  const isFetching = useSelector((state) => state.report.isFetching);
  const isViewerVisible = useSelector((state) => state.report.isViewerVisible);

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(
        listAgenciesCompactAction(brokerId, ViewProductivityDataFullAccess)
      );
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: [initialAgency],
        TransactionDate: [
          toStartOfDay(dayjs().startOf("month")),
          toEndOfDay(dayjs().endOf("day")),
        ],
      };

      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();

      // Reset default options for drilldown
      getExtraDisableDefaultOptions([initialAgency]);
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const getExtraDisableDefaultOptions = (agencyKeys) => {
    const fieldName = "DrillDownSortFields";
    let fieldValue = new DrillDownSortField();
    if (agencyKeys.length === 1 && agencyKeys[0] !== 0) {
      setValue(fieldName, []);
    } else if (
      (agencyKeys.length === 1 && agencyKeys[0] === 0) ||
      agencyKeys.length > 1
    ) {
      fieldValue.Name = sortGroupDrillDownFields.AGENCY_NAME;
      setValue(fieldName, [fieldValue]);
    }
  };

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformProductionByDayHourInput(values),
        entityType.PRODUCTION,
        `PrintSummaryByDayHour`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="ProductionSummaryByDayHourReport">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <AgencyMultiSelectEx
                        layout={defaultLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                        onChange={(value) => {
                          var oldLength = getValues().AgencyKeys.length;
                          var newLength = value.length;
                          // We only need to update default options in some cases
                          if (
                            newLength != oldLength &&
                            ((oldLength < 2 && newLength > oldLength) ||
                              newLength < 2)
                          ) {
                            getExtraDisableDefaultOptions(value);
                          }
                        }}
                      />

                      <Form.Item
                        {...defaultLayout}
                        label="Day of week"
                        name="DayOfWeek"
                        required
                        validateStatus={
                          errors && errors["DayOfWeek"] ? "error" : ""
                        }
                        help={errors.DayOfWeek?.message}
                      >
                        <Controller
                          control={control}
                          name="DayOfWeek"
                          render={({ field }) => (
                            <Select {...field} name="DayOfWeek" virtual={false}>
                              {dayOfWeekOptions}
                            </Select>
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        {...defaultLayout}
                        label="Hour of day"
                        name="HourOfDay"
                        required
                      >
                        <Controller
                          control={control}
                          name="HourOfDay"
                          render={({ field }) => (
                            <TimePicker.RangePicker
                              ref={field.ref}
                              use12Hours
                              format="h A"
                              value={
                                field.value &&
                                !isEmpty(field.value) &&
                                field.value.length === 2
                                  ? [
                                      dayjs().hour(field.value[0]),
                                      dayjs().hour(field.value[1]),
                                    ]
                                  : []
                              }
                              onChange={(time) => {
                                if (time != null && time.length == 2) {
                                  field.onChange([
                                    dayjs(time[0]).hour(),
                                    dayjs(time[1]).hour(),
                                  ]);
                                } else {
                                  field.onChange([]);
                                }
                              }}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Transaction Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        disableFutureDates={true}
                        name="TransactionDate"
                        format={dateFormat}
                        disabledDate={(current) => {
                          current > dayjs().endOf("day");
                        }}
                      />

                      <Form.Item
                        {...defaultLayout}
                        label="Include"
                        name="Include"
                        required
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Space direction="vertical">
                          <Controller
                            control={control}
                            name="IncludeNonFleetNonGarage"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  setValue(
                                    "IncludeNonFleetNonGarage",
                                    e.target.checked,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    }
                                  );
                                  if (
                                    !getValues().IncludeFleetGarage &&
                                    !e.target.checked
                                  ) {
                                    setValue("IncludeFleetGarage", true, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                }}
                                inputRef={field.ref}
                                name="IncludeNonFleetNonGarage"
                              >
                                Non-Fleet and Non-Garage Policies
                              </Checkbox>
                            )}
                          />
                          <Controller
                            control={control}
                            name="IncludeFleetGarage"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                name="IncludeFleetGarage"
                                onChange={(e) => {
                                  setValue(
                                    "IncludeFleetGarage",
                                    e.target.checked,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    }
                                  );
                                  if (
                                    !getValues().IncludeNonFleetNonGarage &&
                                    !e.target.checked
                                  ) {
                                    setValue("IncludeNonFleetNonGarage", true, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                }}
                                inputRef={field.ref}
                              >
                                Fleet and Garage Policies
                              </Checkbox>
                            )}
                          />
                          <Controller
                            control={control}
                            name="IncludeCancellations"
                            render={({ field }) => (
                              <Checkbox
                                inputRef={field.ref}
                                checked={field.value}
                                name="IncludeCancellations"
                                onChange={(e) => {
                                  setValue(
                                    "IncludeCancellations",
                                    e.target.checked,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                              >
                                Cancellations
                              </Checkbox>
                            )}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <ExtraReportFilterContainer>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="ProducerCode1Keys"
                          label="Producer 1"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode1Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={1}
                            taskKey={ViewProductivityDataFullAccess}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ProducerCode2Keys"
                          label="Producer 2"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode2Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={2}
                            taskKey={ViewProductivityDataFullAccess}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row></Row>
                  </ExtraReportFilterContainer>

                  <Row>
                    <Col span={24}>
                      <DrillDownSortFieldSelectEx
                        fieldOptions={drillDownSortFieldOptions}
                        extraDisableOptions={extraDisableOptions}
                        control={control}
                        trigger={trigger}
                        isSubmitted={isSubmitted}
                      />
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformProductionByDayHourInput(getValues()),
                              `ProductionSummaryByDayHourReport`,
                              entityType.PRODUCTION
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          reset(initialDefaultValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default ProductionByDayHourReport;
