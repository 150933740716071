export const sortFields = {
  AGENCY: "AgencyNumber",
  POLICY_NUMBER: "PolicyNumber",
  PLATE_NUMBER: "PlateNumber",
  REGISTRATION_NUMBER: "RegNumber",
  FLEET_NUMBER: "FleetNumber",
  FLEET_UNIT_NUMBER: "FleetUnitNumber",
};

export const sortFieldOptions = [
  { label: "Policy Number", value: sortFields.POLICY_NUMBER },
  { label: "Plate Number", value: sortFields.PLATE_NUMBER },
  { label: "Fleet Number", value: sortFields.FLEET_NUMBER },
  { label: "Fleet Unit Number", value: sortFields.FLEET_UNIT_NUMBER },
];

export const scheduleOfVehiclesSortFieldOptions = [
  { label: "Agency", value: sortFields.AGENCY },
].concat(sortFieldOptions);

export const searchByFields = {
  FLEET_NUMBERS: "FleetNumbers",
  CUSTOMER_NAME: "Customer",
};
