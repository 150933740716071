import React from "react";
import { Typography, Space } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";

const { Text } = Typography;

const InfoForMultiSelect = ({ data, currentValue }) => {
  return (
    <>
      {!!data && !!currentValue && !isEmpty(currentValue) && (
        <Text type="secondary">
          <Space>
            <InfoCircleOutlined />
            {`Selected: ${currentValue.length} out of ${data.length}`}
          </Space>
        </Text>
      )}
    </>
  );
};
export default InfoForMultiSelect;
