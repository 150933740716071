import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useRef } from "react";

import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import {
  Controller,
  useFieldArray,
  useFormState,
  useWatch,
} from "react-hook-form";
import { disableSelectOptions } from "./selectHelpers";
import { SortGroupField } from "./SortGroupField";

const SortGroupFieldSelectEx = ({
  fieldOptions,
  extraDisableOptions,
  control,
  setValue,
  getValues,
  trigger,
  isSubmitted,
  hideGroupingFor = null,
}) => {
  const { errors } = useFormState({ control });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "SortGroupFields",
  });

  const sortGroupFields = useWatch({
    control,
    name: "SortGroupFields",
  });

  const prevIsSubmittedRef = useRef(false);

  useEffect(() => {
    if (!isSubmitted && prevIsSubmittedRef.current) {
      sortGroupFields.map((item, index) => {
        update(
          index,
          new SortGroupField(
            item.Name,
            item.SortAscending,
            item.ToGroup,
            item.NewPage
          )
        );
      });
    }
    prevIsSubmittedRef.current = isSubmitted;
  }, [isSubmitted]);

  const extraSmallFormItemStyle = {
    display: "inline-block",
    width: 60,
    marginBottom: 0,
  };

  return (
    <>
      {!isEmpty(sortGroupFields) && (
        <Divider orientation="left" plain style={{ marginTop: 0 }}>
          Sorting / Grouping
        </Divider>
      )}
      <div>
        {fields.map((item, index) => {
          var fieldName = sortGroupFields[index]?.Name ?? "";
          var shouldHideGrouping =
            hideGroupingFor != null && hideGroupingFor.includes(fieldName);

          return (
            <div key={item.id}>
              <Form.Item
                labelCol={{
                  xs: { span: 4 },
                  sm: { span: 3 },
                  md: { span: 3 },
                  lg: { span: 3 },
                  xl: { span: 2 },
                  xxl: { span: 2 },
                }}
                wrapperCol={{
                  xs: { span: 20 },
                  sm: { span: 21 },
                  md: { span: 21 },
                  lg: { span: 21 },
                  xl: { span: 22 },
                  xxl: { span: 22 },
                }}
                label={index === 0 ? "By" : "Then By"}
                name={`SortGroupFields[${index}].Name`}
                style={{ marginBottom: 4 }}
                required
              >
                <Row>
                  <Col xs={24} sm={24} md={10} lg={10} xl={6} xxl={5}>
                    <Form.Item
                      name={`${index}`}
                      style={{
                        width: "97%",
                        marginBottom: 0,
                      }}
                      validateStatus={
                        errors != null &&
                        errors.SortGroupFields != null &&
                        errors.SortGroupFields[index] != null &&
                        errors.SortGroupFields[index].Name != null
                          ? "error"
                          : ""
                      }
                      help={
                        errors != null &&
                        errors.SortGroupFields != null &&
                        errors.SortGroupFields[index] != null &&
                        errors.SortGroupFields[index].Name != null &&
                        errors.SortGroupFields[index].Name.message != null
                          ? errors.SortGroupFields[index].Name.message
                          : ""
                      }
                    >
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Controller
                          control={control}
                          name={`SortGroupFields[${index}].Name`}
                          render={({ field }) => (
                            <Select
                              {...field}
                              onBlur={() => {
                                field.onBlur();
                                if (trigger != null) {
                                  trigger(`SortGroupFields[${index}].Name`);
                                }
                              }}
                              name={`SortGroupFields[${index}].Name`}
                              showSearch
                              virtual={false}
                              style={{ width: "100%" }}
                              optionFilterProp="label"
                              filterOption={(input, option) => {
                                const label = option.label
                                  ? option.label
                                  : option.children;
                                return (
                                  label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {disableSelectOptions(
                                fieldOptions,
                                sortGroupFields,
                                extraDisableOptions
                              )}
                            </Select>
                          )}
                        />
                      </span>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={7} xxl={6}>
                    <Form.Item
                      name={`SortGroupFields[${index}].SortAscending`}
                      style={{ marginBottom: 0 }}
                    >
                      <Controller
                        control={control}
                        name={`SortGroupFields[${index}].SortAscending`}
                        render={({ field }) => (
                          <Radio.Group
                            {...field}
                            name={`SortGroupFields[${index}].SortAscending`}
                            options={[
                              {
                                value: true,
                                label: "Ascending",
                              },
                              { value: false, label: "Descending" },
                            ]}
                            disabled={!getValues().SortGroupFields[index].Name}
                          ></Radio.Group>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={13}>
                    <Space wrap={true}>
                      {shouldHideGrouping ? (
                        <></>
                      ) : (
                        <>
                          <Form.Item
                            name={`SortGroupFields[${index}].ToGroup`}
                            style={extraSmallFormItemStyle}
                          >
                            <Controller
                              control={control}
                              name={`SortGroupFields[${index}].ToGroup`}
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    setValue(
                                      `SortGroupFields[${index}].ToGroup`,
                                      e.target.checked,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      }
                                    );
                                  }}
                                  inputRef={field.ref}
                                  name={`SortGroupFields[${index}].ToGroup`}
                                >
                                  Group
                                </Checkbox>
                              )}
                            />
                          </Form.Item>

                          <Form.Item
                            name={`SortGroupFields[${index}].NewPage`}
                            style={{
                              textOverflow: "ellipsis",
                              marginBottom: 0,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            <Controller
                              control={control}
                              name={`SortGroupFields[${index}].NewPage`}
                              render={({ field }) => (
                                <Checkbox
                                  checked={
                                    !getValues().SortGroupFields[index].ToGroup
                                      ? false
                                      : field.value
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `SortGroupFields[${index}].NewPage`,
                                      e.target.checked,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      }
                                    );
                                  }}
                                  inputRef={field.ref}
                                  name={`SortGroupFields[${index}].NewPage`}
                                  disabled={
                                    !getValues().SortGroupFields[index].ToGroup
                                  }
                                >
                                  Start each group on a new page
                                </Checkbox>
                              )}
                            />
                          </Form.Item>
                        </>
                      )}

                      <Form.Item
                        name={`Buttons[${index}]`}
                        style={extraSmallFormItemStyle}
                      >
                        <Row>
                          <Col>
                            <Tooltip title="Delete Sorting / Grouping">
                              <Button
                                type="text"
                                onClick={() => remove(index)}
                                style={{ padding: 0 }}
                              >
                                <MinusCircleOutlined />
                              </Button>
                            </Tooltip>
                          </Col>
                          {sortGroupFields.length === index + 1 &&
                            sortGroupFields.length < 4 && (
                              <Col>
                                <Tooltip title="Add Sorting / Grouping">
                                  <Button
                                    type="text"
                                    disabled={isEmpty(
                                      getValues().SortGroupFields[index].Name
                                    )}
                                    onClick={() => {
                                      append(new SortGroupField());
                                      if (trigger != null) {
                                        trigger("SortGroupFields");
                                      }
                                    }}
                                  >
                                    <PlusCircleOutlined />
                                  </Button>
                                </Tooltip>
                              </Col>
                            )}
                        </Row>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          );
        })}
        {isEmpty(sortGroupFields) && (
          <Divider plain orientation="left" style={{ marginTop: 0 }}>
            <Button
              onClick={() => {
                append(new SortGroupField());
                if (trigger != null) {
                  trigger("SortGroupFields");
                }
              }}
              type="dashed"
            >
              <PlusOutlined /> Add Sorting / Grouping
            </Button>
          </Divider>
        )}
      </div>
    </>
  );
};

export default SortGroupFieldSelectEx;
