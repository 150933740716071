export const Actions = {
  SET_TABLE_ROW_BOOKMARK: "SET_TABLE_ROW_BOOKMARK",
  CLEAR_TABLE_ROW_BOOKMARK: "CLEAR_TABLE_ROW_BOOKMARK",
  ADD_PAGE_TO_PRESERVE: "ADD_PAGE_TO_PRESERVE",
  REMOVE_PAGE_TO_PRESERVE: "REMOVE_PAGE_TO_PRESERVE",
  ADD_TABLE_TO_PRESERVE: "ADD_TABLE_TO_PRESERVE",
  REMOVE_TABLE_TO_PRESERVE: "REMOVE_TABLE_TO_PRESERVE",
  CLEAR_ALL_PRESERVABLES: "CLEAR_ALL_PRESERVABLES",
  SET_TABLE_RESTORABLE_DATA: "SET_TABLE_RESTORABLE_DATA",
  CLEAR_TABLE_RESTORABLE_DATA: "CLEAR_TABLE_RESTORABLE_DATA",
  SET_TABLE_PRESERVE_ON_RELOAD: "SET_TABLE_PRESERVE_ON_RELOAD",
  SET_UPLOADED_FILE: "SET_UPLOADED_FILE",
  CLEAR_UPLOADED_FILE: "CLEAR_UPLOADED_FILE",
  LOG_ERROR: "LOG_ERROR",
};
