import dayjs from "dayjs";

export const dateFormat = [
  "DD-MMM-YYYY",
  "MMM-DD-YYYY",
  "MM-DD-YY",
  "MM/DD/YY",
  "MMDDYY",
  "MM-DD-YYYY",
  "MM/DD/YYYY",
  "MMDDYYYY",
];
export const unspecifiedTZFormat = "YYYY-MM-DDTHH:mm:ss";
export const monthFormat = "MMM-YYYY";
export const timeFormat = "hh:mm:ss A";
export const dateTimeFormat = "DD-MMM-YYYY hh:mm:ss A";
export const yearFormat = "YYYY";
export const toEndOfDay = (value) => {
  return !value ? "" : dayjs(value).endOf("day").format(unspecifiedTZFormat);
};
export const toStartOfDay = (value) => {
  return !value ? "" : dayjs(value).startOf("day").format(unspecifiedTZFormat);
};
export const toTZNeutralFormat = (value) => {
  return !value ? "" : dayjs(value).format(unspecifiedTZFormat);
};
const timeStampFormate = "YYYYMMDD";

export const formatDate = (value) =>
  value ? dayjs(value).format(dateFormat[0]) : "";

export const formatDateTime = (value) =>
  value ? dayjs(value).format(dateTimeFormat) : "";

export const formatCurrency = (value) => {
  return `$${Number(value).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const formatDateAsTimestamp = (value) =>
  value ? dayjs(value).format(timeStampFormate) : "";

export const formatPhone = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};

export const parsePhone = (value) => value.replace(/[^\d]/g, "");

export const formatBoolean = (value) => {
  if (value === true || value === 1) {
    return "Yes";
  } else if (value === false || value === 0) {
    return "No";
  } else {
    return "No";
  }
};
