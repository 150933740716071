import React from "react";
import { useSelector } from "react-redux";
import { movementOptions } from "../shared/movementTypes";
import AllocateInventory from "./AllocateInventory";

const AllocateInventoryStep2 = () => {
  const newMovement = useSelector(
    (state) => state.inventory.moveInventory.newMovement
  );

  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  const movementType =
    newMovement.entity && newMovement.entity.Header.InventoryMovementType;

  const movementTypeOption = movementOptions.filter(
    (o) => o.value === movementType
  )[0];

  const movementTypeDescription =
    movementTypeOption && movementTypeOption.label;

  return (
    <AllocateInventory
      newMovement={newMovement}
      movementTypeDescription={movementTypeDescription}
      operationResult={operationResult}
    />
  );
};

export default AllocateInventoryStep2;
