import { isNull } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listSourceAgentsAction } from "../../../store/inventory/inventoryActions";
import SearchSelectEx from "../../shared/form/SearchSelectEx";

const SourceAgentSelect = (props) => {
  const { agencyKey } = props;
  const dispatch = useDispatch();

  const sourceAgents = useSelector(
    (state) => state.inventory.moveInventory.newMovement.sourceAgents.data
  );
  const sourceAgentsLoading = useSelector(
    (state) => state.inventory.moveInventory.newMovement.sourceAgents.loading
  );

  useEffect(() => {
    if (!isNull(agencyKey) && agencyKey != "") {
      dispatch(listSourceAgentsAction(agencyKey));
    }
  }, [agencyKey]);

  return (
    <SearchSelectEx
      layout={props.layout}
      required={true}
      control={props.control}
      formLabel="From Agent"
      name="FromAgentUserKey"
      loading={sourceAgentsLoading}
      options={sourceAgents}
      allowClear={true}
    />
  );
};

export default SourceAgentSelect;
