import React, { useEffect, useState } from "react";
import { columnTypes } from "../shared/table/columnTypes";
import { tableNames } from "../shared/table/tableNames";
import { useDispatch, useSelector } from "react-redux";
import {
  listMappings,
  setSelected,
  deleteMapping,
  clearOperationResult,
} from "../../store/fleetPolicyCustomMappping/fleetPolicyCustomMappingActions";
import { PreservableStatePageWrapper } from "../shared/PreservableStatePageWrapper";
import { SearchableTable } from "../shared/table/SearchableTable";
import { Button, Drawer, Tooltip, Popconfirm, Typography } from "antd";
import PermissionWrapper from "../navigation/PermissionWrapper";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import FleetPolicyToClientMappingForm from "./FleetPolicyToClientMappingForm";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";

const { Paragraph } = Typography;

const FleetPolicyToClientMap = () => {
  return (
    <PreservableStatePageWrapper
      onCleanup={() => {}}
      tableNames={[tableNames.FleetPolicyToClientMap]}
    >
      <FleetPolicyToClientMapBody />
    </PreservableStatePageWrapper>
  );
};

const FleetPolicyToClientMapBody = React.memo(({}) => {
  const [quickEdit, setQuickEdit] = useState(false);

  const columns = [
    {
      title: "Policy / Fleet #",
      dataIndex: "FleetOrPolicyNumber",
      type: columnTypes.TEXT,
      width: 60,
      render: (value, record) => (
        <Button
          type="link"
          size="small"
          style={{ padding: 0 }}
          onClick={() => {
            dispatch(setSelected(record));
            setQuickEdit(true);
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      title: "New Policy",
      dataIndex: "NewPolicyIndicator",
      type: columnTypes.BOOLEAN,
      width: 60,
    },
    {
      title: "Epic Client Code",
      dataIndex: "TAMClientCode",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Name of Insured",
      dataIndex: "ICBCNameOfInsured",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Epic Producer Code",
      dataIndex: "TAMProducerCode",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Epic Producer Name",
      dataIndex: "TAMProducerName",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      type: columnTypes.ACTION,
      align: "center",
      render: (record) => (
        <Popconfirm
          title={
            <>
              <Paragraph>This will delete the mapping.</Paragraph>
              <Paragraph style={{ marginBottom: 0 }}>
                Are you sure you want to proceed?
              </Paragraph>
            </>
          }
          onConfirm={() =>
            dispatch(deleteMapping(record.CompanyKey, record.Id))
          }
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" size="small" style={{ padding: 0 }}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      fixed: "right",
      width: 20,
    },
  ];
  //
  const dispatch = useDispatch();

  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listMappings(brokerId));
    }
  }, [brokerId, dispatch]);

  const list = useSelector((state) => state.fleetPolicyCustomMap.list);

  const loading = useSelector((state) => state.fleetPolicyCustomMap.loading);

  const operationResult = useSelector(
    (state) => state.fleetPolicyCustomMap.operationResult
  );

  useEffect(() => {
    if (operationResult && operationResult.Outcome === "Success") {
      dispatch(listMappings(brokerId));
    }
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult]);

  const addButton = (
    <Button
      size={"small"}
      onClick={() => {
        dispatch(
          setSelected({
            Id: null,
            CompanyKey: brokerId,
            FleetOrPolicyNumber: "",
            NewPolicyIndicator: false,
            TAMClientCode: "",
            ICBCNameOfInsured: "",
            TAMProducerCode: "",
            TAMProducerNumber: "",
          })
        );
        setQuickEdit(true);
      }}
    >
      Add New Mapping
    </Button>
  );

  return (
    <PermissionWrapper entityName="Admin">
      <Drawer
        title="Mapping"
        width={600}
        onClose={() => setQuickEdit(false)}
        open={quickEdit}
        destroyOnClose={true}
      >
        <FleetPolicyToClientMappingForm
          onCloseHandler={() => setQuickEdit(false)}
          handleParentRefresh={() => dispatch(listMappings(brokerId))}
        />
      </Drawer>
      <SearchableTable
        tableName={tableNames.FleetPolicyToClientMap}
        scroll={{ y: 650, x: 1480 }}
        sticky
        dataSource={list}
        columns={columns}
        loading={loading}
        pageSize={100}
        size="small"
        rowKey={(r) => r.Id}
        buttons={[addButton]}
      />
    </PermissionWrapper>
  );
});

export default FleetPolicyToClientMap;
