export const Actions = {
  LIST_ANNOUNCEMENTS: "LIST_ANNOUNCEMENTS",
  LIST_ANNOUNCEMENTS_SUCCEEDED: "LIST_ANNOUNCEMENTS_SUCCEEDED",
  LIST_ANNOUNCEMENTS_FOR_ADMIN: "LIST_ANNOUNCEMENTS_FOR_ADMIN",
  LIST_ANNOUNCEMENTS_FOR_ADMIN_SUCCEEDED:
    "LIST_ANNOUNCEMENTS_FOR_ADMIN_SUCCEEDED",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  DELETE_ANNOUNCEMENT: "DELETE_ANNOUNCEMENT",
  DELETE_ANNOUNCEMENT_SUCCEEDED: "DELETE_ANNOUNCEMENT_SUCCEEDED",
  READ_ANNOUNCEMENT: "READ_ANNOUNCEMENT",
  READ_ANNOUNCEMENT_SUCCEEDED: "READ_ANNOUNCEMENT_SUCCEEDED",
  READ_ANNOUNCEMENT_FOR_ALERT: "READ_ANNOUNCEMENT_FOR_ALERT",
  READ_ANNOUNCEMENT_FOR_ALERT_SUCCEEDED:
    "READ_ANNOUNCEMENT_FOR_ALERT_SUCCEEDED",
  CLEAR_SELECTED_ANNOUNCEMENT: "CLEAR_SELECTED_ANNOUNCEMENT",
  UPDATE_ANNOUNCEMENT: "UPDATE_ANNOUNCEMENT",
  UPDATE_ANNOUNCEMENT_SUCCEEDED: "UPDATE_ANNOUNCEMENT_SUCCEEDED",
  CREATE_ANNOUNCEMENT: "CREATE_ANNOUNCEMENT",
  CREATE_ANNOUNCEMENT_SUCCEEDED: "CREATE_ANNOUNCEMENT_CODE_SUCCEEDED",
  ACKNOWLEDGE_ANNOUNCEMENT: "ACKNOWLEDGE_NNOUNCEMENT",
  ACKNOWLEDGE_ANNOUNCEMENT_SUCCEEDED: "ACKNOWLEDGE_NNOUNCEMENT_SUCCEEDED",
};
