import { Alert, Button, Col, Row, Space } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearNewInventoryMovementAction,
  selectHeader,
  transformMovementInput,
} from "../../../store/inventory/inventoryActions";
import { fetchReport } from "../../../store/report/reportActions";
import PdfViewer from "../../shared/PdfViewer";
import { usePreservableNavigate } from "../../shared/PreservableStatePageWrapper";

const status = "success";
const title = "Inventory movement completed succesfully";

const MoveInventorySummary = (props) => {
  const preservableNavigate = usePreservableNavigate();
  const dispatch = useDispatch();
  const newMovement = useSelector((state) => selectHeader(state));
  useEffect(() => {
    if (newMovement != null) {
      dispatch(
        fetchReport(
          transformMovementInput({
            movementKey: newMovement.InventoryMovementKey,
            inventoryMovementTypeDesc: newMovement.InventoryMovementTypeDesc,
            transactionDate: newMovement.TransactionDate,
            newPageForEachSeries: true,
            includeLogSheet: true,
          }),
          entityType.INVENTORY,
          "PrintMovement"
        )
      );
    }
  }, [newMovement]);

  return (
    <div style={{ padding: 4 }}>
      <Row style={{ paddingBottom: 8 }}>
        <Col span={24}>
          <Alert message={title} type={status} showIcon />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <Space>
            <Button
              size="small"
              onClick={() => {
                if (props.resetAction != null) {
                  props.resetAction();
                }
                dispatch(clearNewInventoryMovementAction());
              }}
            >
              {props.returnButtonTitle}
            </Button>
            <Button
              size="small"
              onClick={() => {
                preservableNavigate(
                  `/movement/${newMovement.InventoryMovementKey}`
                );
              }}
            >
              View More Details
            </Button>
          </Space>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <PdfViewer />
        </Col>
      </Row>
    </div>
  );
};

export default MoveInventorySummary;
