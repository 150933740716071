import { Col, Row } from "antd";
import { isNull } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { shortInputStyle } from "../../../shared/form/formInputStyles";
import CustomInput from "../../../shared/form/reactHookForm/CustomInput";

export const defaultLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const VehicleCustomerPanel = ({ control, isNew = false }) => {
  const selected = useSelector((state) => state.document.selected);
  const maskSensitiveData =
    useSelector((state) => state.actor?.details?.data?.MaskSensitiveData) &&
    !isNew;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;

  return (
    <>
      <Row>
        <Col span={12}>
          <CustomInput
            layout={defaultLayout}
            formLabel="Customer Name 1"
            required={true}
            control={control}
            name="CustomerName1"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Customer Name 2"
            control={control}
            name="CustomerName2"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Driver's License"
            control={control}
            name="DriversLicense"
            maxLength={8}
            style={shortInputStyle}
            disabled={!isUserAllowedToSave}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Primary Phone"
            control={control}
            name="CustomerHomePhone"
            //maxLength={3} tbd
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Email"
            control={control}
            name="CustomerEmail"
            maxLength={60}
            disabled={!isUserAllowedToSave}
            className={maskSensitiveData === true ? "blurry-text" : ""}
            autoComplete="off"
          />
        </Col>

        <Col span={10}>
          <CustomInput
            layout={defaultLayout}
            formLabel="Address 1"
            control={control}
            name="CustomerAddr1"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Address 2"
            control={control}
            name="CustomerAddr2"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Address 3"
            control={control}
            name="CustomerAddr3"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="City"
            control={control}
            maxLength={27}
            name="City"
            disabled={!isUserAllowedToSave}
            autoComplete="off"
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Province"
            control={control}
            name="ProvinceCode"
            maxLength={2}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Postal Code"
            control={control}
            name="PostalCode"
            maxLength={6}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
          />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(VehicleCustomerPanel);
