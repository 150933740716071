import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  clearSelectedAction,
  updateAction,
} from "../../../store/document/documentActions";
import DocumentForm from "./DocumentForm";

const CreateDocumentForm = () => {
  //
  const dispatch = useDispatch();

  const onSubmitHandler = (values, ignoreWarning) =>
    dispatch(updateAction(values, ignoreWarning, true));

  useEffect(() => {
    dispatch(clearSelectedAction());
  }, []);

  return (
    <DocumentForm
      isCreateDocument={true}
      onSubmitHandler={onSubmitHandler}
      onCloseHandler={() => {}}
    />
  );
};

export default CreateDocumentForm;
