import {
  DownloadOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  transformBatchInput,
  undoBatchAction,
} from "../../../store/batching/batchingActions";
import {
  downloadReport,
  fetchReport,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import { TableActionsDropdown } from "../../shared/table/TableActionsDropdown";

export const BatchTableActions = ({ record, searchCriteria }) => {
  const dispatch = useDispatch();

  const isDownloading = useSelector((state) => state.report.isDownloading);

  const undoBatchItem = {
    key: "1",
    label: (
      <Popconfirm
        title="Undo batch?"
        onConfirm={() => {
          dispatch(undoBatchAction(record.DocumentBatchKey, searchCriteria));
        }}
        okText="Yes"
        cancelText="No"
      >
        Undo Batch
      </Popconfirm>
    ),
    icon: <UndoOutlined />,
    disabled: !record.IsUserAllowedToSave,
  };

  const printBatchItems = [
    {
      key: "2",
      label: "Preview",
      icon: <PrinterOutlined />,
      onClick: () => {
        dispatch(
          fetchReport(
            transformBatchInput(record),
            entityType.BATCH,
            "PrintBatch"
          )
        );
        dispatch(setReportModalVisibility(true));
      },
    },
    {
      key: "3",
      icon: <DownloadOutlined />,
      onClick: () => {
        if (!isDownloading) {
          dispatch(
            downloadReport({
              inputAction: transformBatchInput(record),
              entity: entityType.BATCH,
              action: "PrintBatch",
            })
          );
        }
      },
      label: "Download",
    },
  ];

  const items = record.IsUserAllowedToSave
    ? [undoBatchItem, { type: "divider" }, ...printBatchItems]
    : printBatchItems;

  return <TableActionsDropdown items={items}></TableActionsDropdown>;
};

export default BatchTableActions;
