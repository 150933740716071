import { Actions } from "../producer/producerConstants";
import produce from "immer";

const initialState = {
  listCompact: {
    pCode1: { data: [], loading: false },
    pCode2: { data: [], loading: false },
  },
  data: [],
  selectedCodeNumber: "1",
  loading: false,
  selected: null,
  selectedLoading: false,
  operationResult: null,
  users: [],
  usersLoading: false,
  agencies: [],
  agenciesLoading: false,
  saveAndAddFlag: false,
  copyAddInitialValues: null,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_PCODE1_FOR_REPORT_PARAMETERS:
        draft.listCompact.pCode1.loading = true;
        return draft;
      case Actions.LIST_PCODE1_FOR_REPORT_PARAMETERS_SUCCEEDED:
        draft.listCompact.pCode1.loading = false;
        draft.listCompact.pCode1.data = action.payload.Data;
        return draft;
      case Actions.LIST_PCODE2_FOR_REPORT_PARAMETERS:
        draft.listCompact.pCode2.loading = true;
        return draft;
      case Actions.LIST_PCODE2_FOR_REPORT_PARAMETERS_SUCCEEDED:
        draft.listCompact.pCode2.loading = false;
        draft.listCompact.pCode2.data = action.payload.Data;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.LIST_PRODUCER_CODES:
      case Actions.DELETE_PRODUCER_CODE:
        draft.loading = true;
        return draft;
      case Actions.LIST_PRODUCER_CODES_SUCCEEDED:
        draft.loading = false;
        draft.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.DELETE_PRODUCER_CODE_SUCCEEDED:
        draft.loading = false;

        if (action.payload.Operation.Outcome === "Success") {
          draft.data = action.payload.Data;
        } else {
          draft.operationResult = action.payload.Operation;
        }

        return draft;
      case Actions.CREATE_PRODUCER_CODE:
      case Actions.READ_PRODUCER_CODE:
      case Actions.UPDATE_PRODUCER_CODE:
        draft.selectedLoading = true;
        return draft;
      case Actions.READ_PRODUCER_CODE_SUCCEEDED:
        draft.selected = action.payload.Data;
        action.payload.Operation.Outcome !== "Success"
          ? action.payload.Operation
          : null;
        draft.selectedLoading = false;
        return draft;
      case Actions.UPDATE_PRODUCER_CODE_SUCCEEDED:
        draft.selectedLoading = false;
        draft.operationResult = action.payload.Operation;
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        }
        return draft;

      case Actions.CREATE_PRODUCER_CODE_SUCCEEDED:
        draft.selectedLoading = false;
        draft.operationResult = action.payload.Operation;
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        } else if (action.payload.Operation.Outcome === "Error") {
          draft.saveAndAddFlag = false;
        }
        return draft;
      case Actions.CLEAR_SELECTED_PRODUCER_CODE:
        draft.operationResult = null;
        draft.selected = null;
        draft.selectedLoading = false;
        return draft;
      case Actions.CLEAR_USERS_FOR_PRODUCER_CODES:
        draft.users = [];
        draft.usersLoading = false;
        return draft;
      case Actions.LIST_USERS_FOR_PRODUCER_CODES:
        draft.usersLoading = true;
        return draft;
      case Actions.LIST_USERS_FOR_PRODUCER_CODES_SUCCEEDED:
        draft.usersLoading = false;
        draft.users = action.payload.Data;
        return draft;
      case Actions.LIST_AGENCIES_FOR_PRODUCER_CODES:
        draft.agenciesLoading = true;
        return draft;
      case Actions.LIST_AGENCIES_FOR_PRODUCER_CODES_SUCCEEDED:
        draft.agenciesLoading = false;
        draft.agencies = action.payload;
        return draft;
      case Actions.SET_SAVE_AND_ADD_FLAG:
        draft.saveAndAddFlag = action.flag;
        return draft;
      case Actions.CLEAR_COPY_ADD_INITIAL_VALUES:
        draft.copyAddInitialValues = null;
        return draft;
      case Actions.SET_COPY_ADD_INITIAL_VALUES:
        draft.copyAddInitialValues = action.values;
        return draft;
      case Actions.SET_SELECTED_CODE_NUMBER:
        draft.selectedCodeNumber = action.number;
        return draft;
      default:
        return state;
    }
  });
};
