import produce from "immer";
import { Actions } from "./inventoryConstants";

const agentsInitialState = { data: [], loading: false, selected: null };
const newMovementInitialState = {
  loading: false,
  entity: null,
  pendingICBCShipments: [],
  pendingBrokerShipments: [],
  selectedPendingMovementKey: null,
  movementOption: null,
  availableItemRanges: [],
  availableItemRangesLoading: false,
  targetAgents: agentsInitialState,
  sourceAgents: agentsInitialState,
};

const intentoryItemsInitialState = {
  data: [],
  searchCriteria: null,
  loading: false,
  selected: { item: null, history: [] },
};

const allocatedToAgentsInitialState = { data: [], loading: false };

const inventoryMovementsInitialState = {
  data: [],
  loading: false,
  selected: null,
  searchCriteria: null,
};

const initialState = {
  operationResult: null,
  allocatedToAgents: allocatedToAgentsInitialState,
  inventoryItems: intentoryItemsInitialState,
  maintainInventory: {
    loading: false,
    itemRanges: [],
    itemRangesLoading: false,
    allocatedToAgents: [],
  },
  inventoryMovements: inventoryMovementsInitialState,
  inventorySheet: {
    shouldPrint: false,
    shouldBreak: false,
  },
  moveInventory: {
    ui: {
      currentStep: 0,
      receiveInventoryCurrentStep: 0,
      transferInventoryCurrentStep: 0,
      returnToICBCCurrentStep: 0,
      allocateInventoryCurrentStep: 0,
    },
    plateTypes: { loading: false, list: [] },
    newMovement: newMovementInitialState,
  },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.INITIALIZE_INVENTORY_MOVEMENT_UI:
        draft.moveInventory.ui.receiveInventoryCurrentStep = 0;
        draft.moveInventory.ui.transferInventoryCurrentStep = 0;
        draft.moveInventory.ui.returnToICBCCurrentStep = 0;
        draft.moveInventory.ui.allocateInventoryCurrentStep = 0;
        draft.moveInventory.newMovement = newMovementInitialState;
        return draft;
      case Actions.SET_RECEIVE_INVENTORY_CURRENT_STEP:
        draft.moveInventory.ui.receiveInventoryCurrentStep =
          action.payload.value;
        return draft;
      case Actions.SET_TRANSFER_INVENTORY_CURRENT_STEP:
        draft.moveInventory.ui.transferInventoryCurrentStep =
          action.payload.value;
        return draft;
      case Actions.SET_RETURN_TO_ICBC_CURRENT_STEP:
        draft.moveInventory.ui.returnToICBCCurrentStep = action.payload.value;
        return draft;
      case Actions.SET_ALLOCATE_INVENTORY_CURRENT_STEP:
        draft.moveInventory.ui.allocateInventoryCurrentStep =
          action.payload.value;
        return draft;
      case Actions.SET_INVENTORY_MOVEMENT_CURRENT_STEP:
        draft.moveInventory.ui.allocateInventoryCurrentStep =
          action.payload.value;
        return draft;
      case Actions.SET_SELECTED_TARGET_AGENT:
        draft.moveInventory.newMovement.targetAgents.selected =
          action.payload.value;
        return draft;
      case Actions.CLEAR_SELECTED_TARGET_AGENT:
        draft.moveInventory.newMovement.targetAgents.selected = null;
        return draft;
      case Actions.SET_SELECTED_SOURCE_AGENT:
        draft.moveInventory.newMovement.sourceAgents.selected =
          action.payload.value;
        return draft;
      case Actions.CLEAR_SELECTED_SOURCE_AGENT:
        draft.moveInventory.newMovement.sourceAgents.selected = null;
        return draft;
      case Actions.GET_INVENTORY_MOVEMENT:
      case Actions.REMOVE_INVENTORY_MOVEMENT:
      case Actions.UNDO_MOVEMENT:
        draft.inventoryMovements.loading = true;
        return draft;
      case Actions.VALIDATE_AGENCY_FOR_TRANSFER:
        draft.moveInventory.newMovement.loading = true;
        return draft;
      case Actions.READ_INVENTORY_MOVEMENT:
        draft.moveInventory.newMovement.loading = true;
        return draft;
      case Actions.GET_INVENTORY_MOVEMENT_SUCCEEDED:
      case Actions.REMOVE_INVENTORY_MOVEMENT_ITEM_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        draft.moveInventory.newMovement.entity = action.payload.Data;
        draft.moveInventory.newMovement.selectedItemKeys = action.payload.Data
          ? action.payload.Data.Items.map((i) => i.ItemNumberFrom)
          : [];

        return draft;
      case Actions.READ_INVENTORY_MOVEMENT_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        draft.moveInventory.newMovement.entity = action.payload.Data;
        draft.moveInventory.newMovement.selectedItemKeys = action.payload.Data
          ? action.payload.Data.Items.map((i) => i.ItemNumberFrom)
          : [];
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;

      case Actions.UNDO_MOVEMENT_SUCCEEDED:
        draft.inventoryMovements.loading = false;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.VALIDATE_AGENCY_FOR_TRANSFER_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        draft.operationResult = action.payload.Operation;
        return draft;
      case Actions.REMOVE_INVENTORY_MOVEMENT_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        if (action.payload.Operation.Outcome === "Success") {
          draft.moveInventory.ui.receiveInventoryCurrentStep = 0;
        }
        return draft;
      case Actions.LIST_PENDING_ICBC_SHIPMENTS:
      case Actions.LIST_PENDING_ICBC_SHIPMENTS:
        draft.moveInventory.newMovement.loading = true;
        return draft;
      case Actions.LIST_PENDING_ICBC_SHIPMENTS_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        draft.moveInventory.newMovement.pendingICBCShipments =
          action.payload.Data;
        return draft;
      case Actions.LIST_PENDING_BROKER_SHIPMENTS_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        draft.moveInventory.newMovement.pendingBrokerShipments =
          action.payload.Data;
        return draft;
      case Actions.ADD_ITEM_TO_INVENTORY_MOVEMENT:
      case Actions.ADD_ITEMS_TO_INVENTORY_MOVEMENT:
      case Actions.REMOVE_INVENTORY_MOVEMENT_ITEM:
        draft.moveInventory.newMovement.loading = true;
        return draft;
      case Actions.ADD_ITEM_TO_INVENTORY_MOVEMENT_SUCCEEDED:
      case Actions.ADD_ITEMS_TO_INVENTORY_MOVEMENT_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        if (action.payload.Operation.Outcome === "Success") {
          draft.moveInventory.newMovement.entity = action.payload.Data;
          draft.operationResult = action.payload.Operation;
        } else {
          draft.operationResult = action.payload.Operation;
        }
        return draft;
      case Actions.PROCESS_INVENTORY_MOVEMENT:
        draft.moveInventory.newMovement.loading = true;
        return draft;
      case Actions.PROCESS_INVENTORY_MOVEMENT_SUCCEEDED:
        draft.moveInventory.newMovement.loading = false;
        if (action.payload.Operation.Outcome === "Success") {
          draft.moveInventory.newMovement.entity = action.payload.Data;
        }
        draft.operationResult = action.payload.Operation;

        return draft;
      case Actions.LIST_PLATE_TYPES:
        draft.moveInventory.plateTypes.loading = true;
        return draft;
      case Actions.LIST_PLATE_TYPES_SUCCEEDED:
        draft.moveInventory.plateTypes.loading = false;
        draft.moveInventory.plateTypes.list = action.payload.Data;
        return draft;
      case Actions.CLEAR_NEW_INVENTORY_MOVEMENT:
        draft.operationResult = null;
        draft.moveInventory.newMovement = newMovementInitialState;
        return draft;
      case Actions.SET_SELECTED_PENDING_MOVEMENT_KEY:
        draft.moveInventory.newMovement.selectedPendingMovementKey =
          action.payload.key;
        return draft;
      case Actions.SET_INVENTORY_MOVEMENT_OPTION:
        draft.moveInventory.newMovement.movementOption = action.payload.value;
        return draft;
      case Actions.LIST_AVAILABLE_INVENTORY_ITEM_RANGES:
        draft.moveInventory.newMovement.availableItemRangesLoading = true;
        return draft;
      case Actions.LIST_AVAILABLE_INVENTORY_ITEM_RANGES_SUCCEEDED:
        draft.moveInventory.newMovement.availableItemRangesLoading = false;
        draft.moveInventory.newMovement.availableItemRanges =
          action.payload.Data;
        return draft;
      case Actions.SET_SELECTED_ITEM_KEYS:
        draft.moveInventory.newMovement.selectedItemKeys = action.payload.keys;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.CLEAR_ITEMS_LIST:
        draft.inventoryItems = intentoryItemsInitialState;
        return draft;
      case Actions.SET_ITEMS_SEARCH_CRITERIA:
        draft.inventoryItems.searchCriteria = action.payload.values;
        return draft;
      case Actions.FIND_INVENTORY:
      case Actions.LIST_ITEM_HISTORY:
        draft.inventoryItems.loading = true;
        return draft;
      case Actions.FIND_INVENTORY_SUCCEEDED:
        draft.inventoryItems.loading = false;
        draft.inventoryItems.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.FIND_INVENTORY_FAILED:
        draft.inventoryItems.loading = false;
        draft.inventoryItems.data = null;
        draft.operationResult = {
          Outcome: "Error",
          Message:
            "Something went wrong while executing this request. If this problem persists, please attempt logging out and then logging back in.",
        };
        return draft;
      case Actions.LIST_ITEM_HISTORY_SUCCEEDED:
        draft.inventoryItems.loading = false;
        draft.inventoryItems.selected.history = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.CLEAR_SELECTED_INVENTORY_ITEM:
        draft.inventoryItems.selected = { item: null, history: [] };
      case Actions.SET_SELECTED_INVENTORY_ITEM:
        draft.inventoryItems.selected.item = action.payload;
      case Actions.SEARCH_MOVEMENTS:
        draft.inventoryMovements.loading = true;
        return draft;
      case Actions.SEARCH_MOVEMENTS_SUCCEEDED:
        draft.inventoryMovements.loading = false;
        draft.inventoryMovements.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.SEARCH_MOVEMENTS_FAILED:
        draft.inventoryMovements.loading = false;
        draft.inventoryMovements.data = null;
        draft.operationResult = {
          Outcome: "Error",
          Message:
            "Something went wrong while executing this request. If this problem persists, please attempt logging out and then logging back in.",
        };
        return draft;
      case Actions.LIST_TARGET_AGENTS:
        draft.moveInventory.newMovement.targetAgents.loading = true;
        return draft;
      case Actions.LIST_TARGET_AGENTS_SUCCEEDED:
        draft.moveInventory.newMovement.targetAgents.loading = false;
        draft.moveInventory.newMovement.targetAgents.data = action.payload.Data;
        draft.moveInventory.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.LIST_SOURCE_AGENTS:
        draft.moveInventory.newMovement.sourceAgents.loading = true;
        return draft;
      case Actions.SET_MOVEMENTS_SEARCH_CRITERIA:
        draft.inventoryMovements.searchCriteria = action.payload.searchCriteria;
        return draft;
      case Actions.CLEAR_MOVEMENTS_LIST:
        draft.inventoryMovements = inventoryMovementsInitialState;
        return draft;
      case Actions.LIST_SOURCE_AGENTS_SUCCEEDED:
        draft.moveInventory.newMovement.sourceAgents.loading = false;
        draft.moveInventory.newMovement.sourceAgents.data = action.payload.Data;
        draft.moveInventory.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.REMOVE_FROM_INVENTORY:
      case Actions.MARK_AS_ISSUED:
      case Actions.UNDO_MARK_AS_ISSUED:
      case Actions.MARK_AS_LOST_STOLEN:
      case Actions.UNDO_MARK_AS_LOST_STOLEN:
        draft.maintainInventory.loading = true;
        return draft;
      case Actions.REMOVE_FROM_INVENTORY_SUCCEEDED:
      case Actions.MARK_AS_ISSUED_SUCCEEDED:
      case Actions.UNDO_MARK_AS_ISSUED_SUCCEEDED:
      case Actions.MARK_AS_LOST_STOLEN_SUCCEEDED:
      case Actions.UNDO_MARK_AS_LOST_STOLEN_SUCCEEDED:
        draft.maintainInventory.loading = false;
        draft.operationResult = action.payload;
        return draft;
      case Actions.LIST_MAINTAIN_INVENTORY_ITEM_RANGES:
        draft.maintainInventory.itemRangesLoading = true;
        return draft;
      case Actions.LIST_ALLOCATED_TO_AGENTS:
        draft.allocatedToAgents.loading = true;
        return draft;
      case Actions.LIST_MAINTAIN_INVENTORY_ITEM_RANGES_SUCCEEDED:
        draft.maintainInventory.itemRangesLoading = false;
        draft.maintainInventory.itemRanges = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.LIST_ALLOCATED_TO_AGENTS_SUCCEEDED:
        draft.allocatedToAgents.loading = false;
        draft.allocatedToAgents.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.SET_INVENTORY_SHEET_SHOULD_PRINT:
        draft.inventorySheet.shouldPrint = action.payload.value;
        return draft;
      case Actions.SET_INVENTORY_SHEET_SHOULD_BREAK:
        draft.inventorySheet.shouldBreak = action.payload.value;
        return draft;
      case Actions.CLEAR_INVENTORY_SHEET:
        draft.inventorySheet.shouldPrint = false;
        draft.inventorySheet.shouldBreak = false;
        return draft;

      default:
        return state;
    }
  });
};
