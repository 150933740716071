import { QuestionCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import "./MainLayout.css";
import pageTitleMap from "./pageTitleMap";

const { Title } = Typography;

const PageTitle = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const urlHasParameter = !isNaN(pathSnippets[pathSnippets.length - 1]);
  var currentPath = urlHasParameter
    ? location.pathname.slice(0, location.pathname.lastIndexOf("/"))
    : location.pathname;

  var correctedPath =
    currentPath.endsWith("/") && currentPath !== "/" && currentPath.length > 1
      ? currentPath.slice(0, -1)
      : currentPath;

  var currentPage = pageTitleMap[correctedPath] ?? pageTitleMap[currentPath];

  return (
    <>
      <Title level={5} style={{ marginLeft: 32 }}>
        {currentPage != null ? (
          <div>
            {currentPage.icon} {currentPage?.name ?? "Unknown"}
          </div>
        ) : (
          <div>
            <QuestionCircleOutlined /> {currentPage?.name ?? "Unknown"}
          </div>
        )}
      </Title>
    </>
  );
};

export default PageTitle;
