import React from "react";
import { Typography, Space } from "antd";
import { StopOutlined } from "@ant-design/icons";
import getMenuItemVisibility from "./getMenuItemsVisibility";
import { useSelector } from "react-redux";

const { Title } = Typography;

const PermissionWrapper = ({ children, entityName }) => {
  const actorDetails = useSelector((state) => state.actor.details.data);
  const menuVisibility = getMenuItemVisibility(actorDetails);

  const hasPermission =
    menuVisibility &&
    menuVisibility[entityName] &&
    actorDetails.EnableLogin &&
    actorDetails.IsUserOrBrokerageDisabled === false;
  return (
    <>
      {hasPermission ? (
        children
      ) : (
        <Title type="danger" level={5}>
          <Space>
            <StopOutlined />
            You do not have permission to access this feature.
          </Space>
        </Title>
      )}
    </>
  );
};
export default PermissionWrapper;
