import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { Actions } from "./announcementConstants";

export const listAnnouncements = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_ANNOUNCEMENTS });
  const payload = await customGet(
    entityType.ANNOUNCEMENT,
    genericActionType.LIST
  );
  dispatch({ type: Actions.LIST_ANNOUNCEMENTS_SUCCEEDED, payload });
};

export const listAnnouncementsForAdmin = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_ANNOUNCEMENTS_FOR_ADMIN });
  const payload = await customGet(entityType.ANNOUNCEMENT, "ListForAdmin");
  dispatch({ type: Actions.LIST_ANNOUNCEMENTS_FOR_ADMIN_SUCCEEDED, payload });
};

export const clearOperationResult = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const deleteAnnouncement = (id) => async (dispatch) => {
  dispatch({ type: Actions.DELETE_ANNOUNCEMENT });
  const payload = await customGet(
    entityType.ANNOUNCEMENT,
    genericActionType.DELETE,
    {
      params: {
        id: id,
      },
    }
  );

  dispatch({
    type: Actions.DELETE_ANNOUNCEMENT_SUCCEEDED,
    payload,
  });
};

export const readAnnouncement = (id) => async (dispatch) => {
  dispatch({ type: Actions.READ_ANNOUNCEMENT });
  const payload = await customGet(
    entityType.ANNOUNCEMENT,
    genericActionType.READ,
    {
      params: {
        id: id,
      },
    }
  );

  dispatch({
    type: Actions.READ_ANNOUNCEMENT_SUCCEEDED,
    payload,
  });
};

export const readAnnouncementForAlert = () => async (dispatch) => {
  dispatch({ type: Actions.READ_ANNOUNCEMENT_FOR_ALERT });
  const payload = await customGet(entityType.ANNOUNCEMENT, "ReadForAlert");

  dispatch({
    type: Actions.READ_ANNOUNCEMENT_FOR_ALERT_SUCCEEDED,
    payload,
  });
};

export const acknowledgeAnnouncement = (id) => async (dispatch) => {
  dispatch({ type: Actions.ACKNOWLEDGE_ANNOUNCEMENT });
  const payload = await customGet(
    entityType.ANNOUNCEMENT,
    "AcknowledgeAnnouncement",
    {
      params: {
        id: id,
      },
    }
  );

  dispatch({
    type: Actions.ACKNOWLEDGE_ANNOUNCEMENT_SUCCEEDED,
    payload,
  });
};

export const updateAnnouncement = (values) => async (dispatch) => {
  dispatch({ type: Actions.UPDATE_ANNOUNCEMENT });
  const payload = await customPost(
    entityType.ANNOUNCEMENT,
    genericActionType.UPDATE,
    values
  );
  dispatch({ type: Actions.UPDATE_ANNOUNCEMENT_SUCCEEDED, payload });
};

export const createAnnouncement = (values) => async (dispatch) => {
  dispatch({ type: Actions.CREATE_ANNOUNCEMENT });
  const payload = await customPost(
    entityType.ANNOUNCEMENT,
    genericActionType.CREATE,
    values
  );
  dispatch({ type: Actions.CREATE_ANNOUNCEMENT_SUCCEEDED, payload });
};

export const clearSelectedAnnouncement = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_ANNOUNCEMENT });
};
