import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import {
  findCurrentAgencies,
  findCurrentBatchLabel,
  findCurrentBrokerId,
  findProducerNames,
} from "../report/reportActions";
import { Actions } from "./commissionConstants";

export const transformCommissionInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const batchFromLabel = findCurrentBatchLabel(state, values.BatchNumberFrom);
  const batchToLabel = findCurrentBatchLabel(state, values.BatchNumberTo);
  const Producer1Names = findProducerNames(state, 1, values.ProducerCode1Keys);
  const Producer2Names = findProducerNames(state, 2, values.ProducerCode2Keys);
  const transactionDates = [
    values.TransactionDate[0],
    values.TransactionDate[1],
  ];

  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FirstProducer: Producer1Names,
    SecondProducer: Producer2Names,
    BatchFromLabel: batchFromLabel,
    TransactionDate: transactionDates,
    BatchToLabel: batchToLabel,
    FileName: `Commission${values.ReportType}_${agency.Number}`,
  };
};

export const transformFleetGarageCommissionInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const brokerId = findCurrentBrokerId(state);
  const transactionDates = [
    values.TransactionDate[0],
    values.TransactionDate[1],
  ];

  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    BrokerId: brokerId,
    TransactionDate: transactionDates,
    FileName: `FleetGarageCommission${values.ReportType}_${agency.Number}`,
  };
};

export const listCommissionRates = (values) => async (dispatch, getState) => {
  dispatch({ type: Actions.LIST_COMMISSION_RATES });

  let trasformedValues = values;
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    trasformedValues = !agencies ? [] : agencies.map((a) => a.Id);
  }
  const payload = await customPost(
    entityType.COMMISSION,
    genericActionType.LIST,
    trasformedValues
  );
  dispatch({ type: Actions.LIST_COMMISSION_RATES_SUCCEEDED, payload });
};

export const readCommissionRate = (key) => async (dispatch) => {
  dispatch({ type: Actions.READ_COMMISSION_RATE });
  const payload = await customGet(
    entityType.COMMISSION,
    genericActionType.READ,
    {
      params: {
        key: key,
      },
    }
  );
  dispatch({
    type: Actions.READ_COMMISSION_RATE_SUCCEEDED,
    payload,
  });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const listProducer1Codes = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.LIST_PRODUCER_1_CODES });
  const codeNumber = 1;
  const payload = await customGet(entityType.PRODUCER, "ListByAgency", {
    params: {
      agencyKey,
      codeNumber,
    },
  });

  dispatch({ type: Actions.LIST_PRODUCER_1_CODES_SUCCEEDED, payload });
};

export const listProducer2Codes = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.LIST_PRODUCER_2_CODES });
  const codeNumber = 2;
  const payload = await customGet(entityType.PRODUCER, "ListByAgency", {
    params: {
      agencyKey,
      codeNumber,
    },
  });
  dispatch({ type: Actions.LIST_PRODUCER_2_CODES_SUCCEEDED, payload });
};

export const createCommissionRate = (values) => async (dispatch) => {
  dispatch({ type: Actions.CREATE_COMMISSION_RATE });
  const payload = await customPost(
    entityType.COMMISSION,
    genericActionType.CREATE,
    values
  );
  dispatch({ type: Actions.CREATE_COMMISSION_RATE_SUCCEEDED, payload });
};

export const updateCommissionRAte = (values) => async (dispatch) => {
  dispatch({ type: Actions.UPDATE_COMMISSION_RATE });
  const payload = await customPost(
    entityType.COMMISSION,
    genericActionType.UPDATE,
    values
  );
  dispatch({ type: Actions.UPDATE_COMMISSION_RATE_SUCCEEDED, payload });
};

export const clearSelectedCommissionRate = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_COMMISSION_RATE });
};

export const deleteCommissionRate = (values) => async (dispatch) => {
  dispatch({ type: Actions.DELETE_COMMISSION_RATE });
  let trasformedValues = values;
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    trasformedValues = !agencies ? [] : agencies.map((a) => a.Id);
  }
  const payload = await customPost(
    entityType.COMMISSION,
    genericActionType.DELETE,
    trasformedValues
  );
  dispatch({
    type: Actions.DELETE_COMMISSION_RATE_SUCCEEDED,
    payload,
  });
};
