import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMovementsListAction,
  clearOperationResultAction,
} from "../../../store/inventory/inventoryActions";
import {
  clearOperationResult,
  resetReportState,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { PdfViewerModal } from "../../shared/PdfViewer";
import { PreservableStatePageWrapper } from "../../shared/PreservableStatePageWrapper";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { tableNames } from "../../shared/table/tableNames";
import MovementTable from "./MovementTable";
import SearchMovementsForm from "./SearchMovementsForm";

const Movements = () => {
  const [clearAll, setClearAll] = useState(false);
  const dispatch = useDispatch();

  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  const reportOperationResult = useSelector(
    (state) => state.report.operationResult
  );

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
  }, []);

  useEffect(() => {
    openNotificationOperationResult(reportOperationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [reportOperationResult, dispatch]);

  return (
    <PreservableStatePageWrapper
      onCleanup={() => {
        dispatch(clearMovementsListAction());
      }}
      tableNames={[tableNames.MovementSearch]}
    >
      <PermissionWrapper entityName="SearchMovements">
        <Row>
          <Col span={24}>
            <SearchMovementsForm setClearAll={setClearAll} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <MovementTable setClearAll={setClearAll} clearAll={clearAll} />
          </Col>
        </Row>
        <PdfViewerModal />
      </PermissionWrapper>
    </PreservableStatePageWrapper>
  );
};

export default Movements;
