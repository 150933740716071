import { Col, Row } from "antd";
import { isNull } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  dateTimeInputStyle,
  mediumInputStyle,
  shortInputStyle,
} from "../../../shared/form/formInputStyles";
import CustomDatePicker from "../../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../../shared/form/reactHookForm/CustomInput";
import CustomInputCurrency from "../../../shared/form/reactHookForm/CustomInputCurrency";
import CustomInputTextArea from "../../../shared/form/reactHookForm/CustomInputTextArea";
import { dateFormat, dateTimeFormat } from "../../../shared/formatting";
import { ProducerCodeAndName } from "../ProducerCodeAndName";

const specialLayout = {
  labelCol: { xxl: 12, xl: 12, lg: 8, md: 10, sm: 10, xs: 10 },
  wrapperCol: { xxl: 12, xl: 12, lg: 16, md: 14, sm: 14, xs: 14 },
};

const VehicleDocumentPanel = ({ control, isNew = false }) => {
  const selected = useSelector((state) => state.document.selected);
  const maskSensitiveData =
    useSelector((state) => state.actor?.details?.data?.MaskSensitiveData) &&
    !isNew;
  const isDownloaded = selected && selected.IsDownloaded;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;
  const isUserAllowedToViewCommission =
    selected && selected.IsUserAllowedToViewCommission;

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
          <ProducerCodeAndName
            layout={{
              labelCol: { xxl: 8, xl: 8, lg: 8, md: 10, sm: 10, xs: 10 },
              wrapperCol: { xxl: 16, xl: 16, lg: 16, md: 14, sm: 14, xs: 14 },
            }}
            control={control}
            disabled={!isUserAllowedToSave}
            selected={selected}
            isNew={isNew}
            maskSensitiveData={maskSensitiveData}
            code={1}
          />

          <ProducerCodeAndName
            layout={{
              labelCol: { xxl: 8, xl: 8, lg: 8, md: 10, sm: 10, xs: 10 },
              wrapperCol: { xxl: 16, xl: 16, lg: 16, md: 14, sm: 14, xs: 14 },
            }}
            control={control}
            disabled={!isUserAllowedToSave}
            selected={selected}
            isNew={isNew}
            maskSensitiveData={maskSensitiveData}
            code={2}
          />

          {isUserAllowedToViewCommission === true && (
            <CustomInputCurrency
              layout={{
                labelCol: { xxl: 8, xl: 8, lg: 8, md: 10, sm: 10, xs: 10 },
                wrapperCol: { xxl: 16, xl: 16, lg: 16, md: 14, sm: 14, xs: 14 },
              }}
              formLabel="Commission Amt"
              control={control}
              name="CommTotalAmt"
              disabled={true}
              isPrecision={true}
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
          <CustomDatePicker
            formItem={{
              layout: specialLayout,
              label: "Effective",
            }}
            control={control}
            style={mediumInputStyle}
            name="PolicyEffectiveDate"
            format={dateFormat}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Timestamp"
            control={control}
            required={!isDownloaded}
            style={mediumInputStyle}
            name="DCID"
            maxLength={14}
            disabled={
              (isDownloaded && !selected.IsFinancial) || !isUserAllowedToSave
            }
          />

          <CustomDatePicker
            formItem={{
              layout: specialLayout,
              label: "Transaction Date",
            }}
            control={control}
            style={dateTimeInputStyle}
            showTime={true}
            name="EntryDateTime"
            format={dateTimeFormat}
            disabled={true}
            placeholder={""}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
          <CustomInput
            layout={specialLayout}
            formLabel="Document"
            control={control}
            name="DocumentNumber"
            maxLength={8}
            style={shortInputStyle}
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Dealer"
            control={control}
            name="DealerNumber"
            maxLength={5}
            disabled={isDownloaded || !isUserAllowedToSave}
            style={shortInputStyle}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Number of Owners"
            control={control}
            name="NumberOfOwners"
            disabled={isDownloaded || !isUserAllowedToSave}
            style={shortInputStyle}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomInputTextArea
            control={control}
            layout={{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
            formLabel="Agent Comments"
            name="AgentComments"
            id="AgentComments"
            disabled={!isUserAllowedToSave}
            maxLength={255}
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(VehicleDocumentPanel);
