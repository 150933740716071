import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import { clearManageRenewals } from "../../../store/manageRenewals/manageRenewalsActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { UpdateRenewalData } from "../../shared/securityRoleTasks";
import ManageRenewalsForm from "./ManageRenewalsForm";

const ManageRenewals = () => {
  //
  const dispatch = useDispatch();

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const agenciesLoading = useSelector((state) => state.agency.loading);

  //
  const pageLoaded = brokerId != null && agencies != null && !agenciesLoading;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, UpdateRenewalData));
    }
  }, [brokerId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearListCompactAction());
      dispatch(clearManageRenewals());
    };
  }, [dispatch]);

  return (
    <PermissionWrapper entityName="ManageRenewals">
      <Spin spinning={!pageLoaded} delay={500}>
        {pageLoaded && <ManageRenewalsForm agencies={agencies} />}
      </Spin>
    </PermissionWrapper>
  );
};
export default ManageRenewals;
