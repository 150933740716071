import { Col, Collapse, Form, Row, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { isNull } from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUnbatchedDocSummary } from "../../../store/dashboard/dashboardActions";
import { mediumInputStyle } from "../../shared/form/formInputStyles";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import { dateFormat, toEndOfDay } from "../../shared/formatting";
import UnbatchedDocsTable from "./UnbatchedDocsTable";

const { Text } = Typography;

const initialValues = {
  SubmissionDate: toEndOfDay(dayjs()),
};

const UnbatchedDocsCollapse = () => {
  // Form
  const { control, watch } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
  });

  const submissionDate = watch("SubmissionDate");

  //
  const dispatch = useDispatch();

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const data = useSelector(
    (state) => state.dashboard.unbatchedDocsSummary.data
  );
  const loading = useSelector(
    (state) => state.dashboard.unbatchedDocsSummary.loading
  );

  useEffect(() => {
    if (!isNull(brokerId) && !isNull(submissionDate)) {
      dispatch(getUnbatchedDocSummary(brokerId, submissionDate));
    }
  }, [brokerId, submissionDate]);

  const totalRegularUnbatched = isNull(data)
    ? 0
    : data.reduce((sum, item) => sum + item.RegularDocumentsCount, 0);
  const totalMultiLicenseUnbatched = isNull(data)
    ? 0
    : data.reduce((sum, item) => sum + item.MultiLicenseDocumentsCount, 0);

  const panelHeader = (
    <Row>
      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
        <Space size="small">
          <Typography>
            <Text strong> Unbatched Documents </Text>
            as of:
          </Typography>
          <CustomDatePicker
            formItem={{
              label: "",
              style: { marginBottom: 0 },
              onClick: (e) => {
                e.stopPropagation();
              },
            }}
            control={control}
            name="SubmissionDate"
            disabled={loading}
            allowClear={false}
            style={mediumInputStyle}
            format={dateFormat}
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
          />
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
        <Text>
          Regular:{" "}
          {loading ? (
            <Spin size="small" />
          ) : (
            <Text strong>{totalRegularUnbatched.toLocaleString()}</Text>
          )}
        </Text>
      </Col>
      <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
        <Text>
          Multi-licence:{" "}
          {loading ? (
            <Spin size="small" />
          ) : (
            <Text strong>{totalMultiLicenseUnbatched.toLocaleString()}</Text>
          )}
        </Text>
      </Col>
    </Row>
  );

  const items = [
    {
      children: (
        <Row>
          <Col span={24}>
            <UnbatchedDocsTable
              data={data}
              loading={loading}
              refreshDataHandler={() =>
                dispatch(getUnbatchedDocSummary(agencyKeys))
              }
            />
          </Col>
        </Row>
      ),
      key: "1",
      // label: "test",
      label: panelHeader,
      size: "small",
      showArrow: true,
    },
  ];

  return (
    <div>
      <Form size="small" autoComplete="off">
        <Collapse items={items}></Collapse>
      </Form>
    </div>
  );
};

export default UnbatchedDocsCollapse;
