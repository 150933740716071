import { SnippetsOutlined } from "@ant-design/icons";
import { Col, Drawer, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { readAction } from "../../../store/batching/batchingActions";
import { clearSelectedAction } from "../../../store/document/documentActions";
import QuickEditDocumentForm from "../../document/form/QuickEditDocumentForm";
import UpdateDocumentForm from "../../document/form/UpdateDocumentForm";
import { SearchableTable } from "../../shared/table/SearchableTable";
import AddDocumentsToBatch from "../form/AddDocumentsToBatch";
import { batchDocumentColumns } from "./batchingColumns";
import { tableNames } from "../../shared/table/tableNames";

const BatchDocuments = ({ handleParentRefresh }) => {
  const [quickEdit, setQuickEdit] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const dispatch = useDispatch();

  const quickEditOnCloseHandler = (clearSelected) => {
    setQuickEdit(false);
    if (clearSelected) {
      dispatch(clearSelectedAction);
    }
  };

  const { batchKey } = useParams();
  const quickEditParentRefresAction = () => dispatch(readAction(batchKey));

  const batchingState = useSelector((state) => state.batching);
  const data = batchingState.selected;
  const list = data && data.Documents;
  const loading = batchingState.selectedLoading;
  const isUserAllowedToSave = data && data.BatchHeader.IsUserAllowedToSave;

  const columns = batchDocumentColumns(
    setQuickEdit,
    isUserAllowedToSave,
    tableNames.BatchView
  );

  return (
    <div>
      <Drawer
        title={
          <>
            <SnippetsOutlined />
            Document Quick Edit
          </>
        }
        width={680}
        onClose={quickEditOnCloseHandler}
        open={quickEdit}
        bodyStyle={{ paddingTop: 4 }}
        destroyOnClose={true}
      >
        <QuickEditDocumentForm
          onCloseHandler={quickEditOnCloseHandler}
          parentRefreshAction={quickEditParentRefresAction}
          tableName={tableNames.BatchView}
        />
      </Drawer>
      <Routes>
        <Route
          path=""
          element={
            <Row>
              <Col>
                <SearchableTable
                  bordered
                  setClearAll={setClearAll}
                  clearAll={clearAll}
                  scroll={{ y: 450, x: 1745 }}
                  sticky
                  dataSource={list}
                  columns={columns}
                  loading={loading}
                  size="small"
                  rowKey={(r) => r.DocumentKey}
                  shouldButtonsFillWidth={true}
                  pageSize={1000}
                  buttons={
                    isUserAllowedToSave
                      ? [
                          <AddDocumentsToBatch
                            batchKey={data && data.BatchHeader.DocumentBatchKey}
                            handleParentRefresh={handleParentRefresh}
                            isUserAllowedToSave={isUserAllowedToSave}
                          />,
                        ]
                      : []
                  }
                  tableName={tableNames.BatchView}
                />
              </Col>
            </Row>
          }
        />

        <Route
          path="updatedocumentform/:documentKey"
          element={
            <UpdateDocumentForm handleParentRefresh={handleParentRefresh} />
          }
        />
      </Routes>
    </div>
  );
};
export default BatchDocuments;
