import { customGet } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import Actions from "./actorConstants";

export const readActorAction = (id4UserId) => async (dispatch) => {
  dispatch({ type: Actions.READ_ACTOR });
  const payload = await customGet(entityType.ACTOR, genericActionType.READ, {
    params: { id4UserId: id4UserId },
  });
  dispatch({ type: Actions.READ_ACTOR_SUCCEEDED, payload });
};

export const switchBroker = (agencyKey, userKey) => async (dispatch) => {
  dispatch({ type: Actions.SWITCH_SUPPORT_ACCOUNT });
  const payload = await customGet(entityType.ACTOR, "SwitchBroker", {
    params: { agencyKey: agencyKey, userKey: userKey },
  });
  dispatch({ type: Actions.SWITCH_SUPPORT_ACCOUNT_SUCCEEDED, payload });
};

export const setSessionExpiring = (expiring) => async (dispatch) => {
  dispatch({ type: Actions.SET_SESSION_EXPIRING, expiring });
};

export const clearActorData = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_ACTOR_DATA });
};

export const setDarkMode = (mode) => async (dispatch) => {
  dispatch({ type: Actions.SET_DARK_MODE, mode });
};

export const prewarmServer = (id4UserId) => async (dispatch, getState) => {
  const userKey = getState().actor?.details?.data?.UserId ?? 0;
  dispatch({ type: "initialization/UserPrewarmServer" });

  const payload = await customGet(
    entityType.INITIALIZATION,
    "UserPrewarmServer",
    {
      params: { id4UserId: id4UserId, userKey: userKey },
    }
  );

  dispatch({
    type: "initialization/UserPrewarmServerSuccess",
    payload,
  });
};

export const clearSwitchAccountOperationResult = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SWITCH_ACCOUNT_OPERATION_RESULT });
};
