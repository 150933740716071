import { Button, Row, Col, Popconfirm, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { SearchableTable } from "../shared/table/SearchableTable";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { tableNames } from "../shared/table/tableNames";
import {
  PreservableStatePageWrapper,
  usePreservableNavigate,
  PreservableStateLink,
} from "../shared/PreservableStatePageWrapper";
import PermissionWrapper from "../navigation/PermissionWrapper";
import {
  deleteAnnouncement,
  listAnnouncementsForAdmin,
  clearOperationResult,
} from "../../store/announcement/announcementActions";
import UpdateAnnouncement from "./UpdateAnnouncement";
import CreateAnnouncement from "./CreateAnnouncement";
import { columnTypes } from "../shared/table/columnTypes";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { announcementType } from "./announcementType";
import { InfoIcon, WarningIcon, ErrorIcon } from "./announcementIcons";

const { Paragraph } = Typography;

const Announcements = () => {
  return (
    <PreservableStatePageWrapper
      onCleanup={() => {}}
      tableNames={[tableNames.Announcements]}
    >
      <AnnouncementsBody />
    </PreservableStatePageWrapper>
  );
};

const AnnouncementsBody = () => {
  const data = useSelector((state) => state.announcement.dataForAdmin);
  const loading = useSelector((state) => state.announcement.loading);
  const operationResult = useSelector(
    (state) => state.announcement.operationResult
  );
  const dispatch = useDispatch();
  const preservableNavigate = usePreservableNavigate();

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult]);

  useEffect(() => {
    dispatch(listAnnouncementsForAdmin());
  }, []);

  const addButton = (
    <Button
      size={"small"}
      type="primary"
      onClick={() => {
        preservableNavigate(`createannouncement`);
      }}
    >
      <PlusOutlined />
      Add New
    </Button>
  );

  const toolbarButtons = [addButton];

  const columns = [
    {
      title: "Type",
      dataIndex: "Type",
      type: columnTypes.NONE,
      align: "center",
      width: 30,
      render: (value, record) => {
        const icon =
          value === announcementType.Error ? (
            <ErrorIcon />
          ) : value === announcementType.Warning ? (
            <WarningIcon />
          ) : (
            <InfoIcon />
          );
        return icon;
      },
    },
    {
      title: "Subject",
      dataIndex: "Subject",
      type: columnTypes.TEXT,
      render: (value, record) => (
        <PreservableStateLink to={`updateannouncement/${record.Id}`}>
          {value}
        </PreservableStateLink>
      ),
      width: 100,
    },
    {
      title: "Description",
      dataIndex: "Description",
      width: 280,
      type: columnTypes.TEXT,
    },
    {
      title: "Link",
      dataIndex: "Link",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Link Text",
      dataIndex: "LinkText",
      type: columnTypes.TEXT,
      width: 60,
    },
    {
      title: "Announce on",
      dataIndex: "AnnounceOnDate",
      type: columnTypes.DATETIME,
      width: 100,
    },
    {
      title: "Expiry",
      dataIndex: "ExpiryDate",
      type: columnTypes.DATETIME,
      width: 100,
    },
    {
      type: columnTypes.ACTION,
      render: (record) => (
        <Popconfirm
          title={
            <>
              <Paragraph>This will delete the announcement.</Paragraph>
              <Paragraph style={{ marginBottom: 0 }}>
                Are you sure you want to proceed?
              </Paragraph>
            </>
          }
          onConfirm={() => dispatch(deleteAnnouncement(record.Id))}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" size="small" style={{ padding: 0 }}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      fixed: "right",
      width: 25,
    },
  ];

  return (
    <PermissionWrapper entityName="Announcements">
      <Routes>
        <Route
          path=""
          element={
            <>
              <Row>
                <Col span={24}>
                  <SearchableTable
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    buttons={toolbarButtons}
                    rowKey={(r) => r.Id}
                    tableName={tableNames.Announcements}
                    pageSize={100}
                    size="small"
                    scroll={{ y: 650, x: 820 }}
                  />
                </Col>
              </Row>
            </>
          }
        />

        <Route
          path="createannouncement"
          element={
            <CreateAnnouncement
              parentRefreshHandler={() => dispatch(listAnnouncementsForAdmin())}
            />
          }
        />

        <Route
          path="updateannouncement/:id"
          element={
            <UpdateAnnouncement
              parentRefreshHandler={() => dispatch(listAnnouncementsForAdmin())}
            />
          }
        />
      </Routes>
    </PermissionWrapper>
  );
};
export default Announcements;
