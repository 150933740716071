import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useController } from "react-hook-form";

export const ProducerCodeAndName = (props) => {
  //
  const code = props.code.toString();
  const name = `ProducerCode${code}`;
  const producerNameAsString = `ProducerName${code}`;
  const producerName = props?.selected?.[producerNameAsString] ?? "";

  //
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      name={name}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
      producerName={producerName}
      code={code}
    />
  );
};

const Content = React.memo(
  (props) => {
    const {
      field,
      name,
      layout,
      code,
      producerName,
      maskSensitiveData,
      isNew,
    } = props;

    //
    var inputProps = { ...props };
    delete inputProps.control;
    delete inputProps.isTouched;
    delete inputProps.isDirty;
    delete inputProps.error;
    delete inputProps.field;
    delete inputProps.name;
    delete inputProps.layout;
    delete inputProps.code;
    delete inputProps.selected;
    delete inputProps.maskSensitiveData;
    delete inputProps.isNew;

    return (
      <Form.Item {...layout} label={`Producer Code ${code}`} name={name}>
        <div>
          <Row wrap={false} gutter={4}>
            <Col flex="65px">
              <Input {...field} maxLength={3} {...inputProps} />
            </Col>
            {!isNew && (
              <Col flex="auto">
                <Input
                  value={producerName}
                  disabled={true}
                  style={{ width: "100%" }}
                  className={maskSensitiveData === true ? "blurry-text" : ""}
                />
              </Col>
            )}
          </Row>
        </div>
      </Form.Item>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isDirty === nextProps.isDirty &&
      prevProps.isTouched === nextProps.isTouched &&
      prevProps.field.value === nextProps.field.value &&
      prevProps.value === nextProps.value &&
      prevProps.error === nextProps.error &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.maskSensitiveData === nextProps.maskSensitiveData &&
      prevProps.isNew === nextProps.isNew &&
      prevProps.producerName === nextProps.producerName
    );
  }
);
