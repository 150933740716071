import * as yup from "yup";
import {
  requiredMessage,
  minMessage,
  maxMessage,
  alphanumericRegex,
  alphanumericMessage,
} from "../../../components/shared/form/formValidatonRules";

const maintainInventorySchema = yup.object().shape({
  Task: yup.string().required(requiredMessage),
  ItemNumberFrom: yup
    .string()
    .required(requiredMessage)
    .min(4, minMessage)
    .max(8, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ItemNumberTo: yup
    .string()
    .required(requiredMessage)
    .min(4, minMessage)
    .max(8, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
});
export default maintainInventorySchema;
