import { useSelector } from "react-redux";
import { columnTypes } from "../../shared/table/columnTypes";

export const moveDocumentsColumns = () => {
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  return [
    {
      title: "PC1",
      dataIndex: "ProducerCode1",
      type: columnTypes.TEXT,
      width: 60,
      editable: (record) => record.IsUserAllowedToSave,
      inputProps: {
        name: "ProducerCode1",
        formLabel: "",
        maxLength: 3,
      },
    },
    {
      title: "PC2",
      dataIndex: "ProducerCode2",
      type: columnTypes.TEXT,
      width: 60,
      editable: (record) => record.IsUserAllowedToSave,
      inputProps: {
        name: "ProducerCode2",
        formLabel: "",
        maxLength: 3,
      },
    },
    {
      title: "Timestamp",
      dataIndex: "DCID",
      type: columnTypes.TEXT,
      width: 115,
      inputProps: {
        name: "DCID",
        formLabel: "",
        maxLength: 14,
        initialValue: "9679",
      },
      editable: (record) =>
        record.IsUserAllowedToSave &&
        (!record.IsDownloaded || record.IsFinancial),
    },
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      width: 72,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.PolicyNumber != prevRecord.PolicyNumber,
    },
    {
      title: "Product",
      dataIndex: "ProductCode",
      type: columnTypes.TEXT,
      width: 85,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.ProductCode != prevRecord.ProductCode,
    },
    {
      title: "Fleet",
      dataIndex: "FleetNumber",
      type: columnTypes.TEXT,
      width: 65,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.FleetNumber != prevRecord.FleetNumber,
    },
    {
      title: "Reg Num",
      dataIndex: "RegNumber",
      type: columnTypes.TEXT,
      width: 75,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.RegNumber != prevRecord.RegNumber,
    },
    {
      title: "Plate",
      dataIndex: "PlateNumber",
      type: columnTypes.TEXT,
      width: 72,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.PlateNumber != prevRecord.PlateNumber,
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: 210,
      ellipsis: true,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.InsuredName != prevRecord.InsuredName,
    },
    {
      title: "Amount Due",
      dataIndex: "AmountDue",
      type: columnTypes.CURRENCY,
      width: 95,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.AmountDue != prevRecord.AmountDue,
    },
    {
      title: "Channel",
      dataIndex: "Channel",
      type: columnTypes.TEXT,
      width: 100,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.Channel != prevRecord.Channel,
    },
    {
      title: "Transaction Date",
      dataIndex: "EntryDateTime",
      type: columnTypes.DATETIME,
      shouldCellUpdate: (record, prevRecord) =>
        record.DocumentKey != prevRecord.DocumentKey ||
        record.EntryDateTime != prevRecord.EntryDateTime,
      width: 170,
    },
  ];
};
