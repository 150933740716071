const searchByColumns = [
  {
    value: "Autodetect",
    label: "Autodetect",
  },
  {
    value: "InsuredName",
    label: "Insured",
  },
  { value: "PlateNumber", label: "Plate" },
  { value: "PolicyNumber", label: "Policy" },
  { value: "DCID", label: "Timestamp" },
  { value: "RegNumber", label: "Registration" },
  { value: "DriversLicense", label: "Driver's License" },
  { value: "DocumentNumber", label: "Document Number" },
  { value: "VIN", label: "Vin" },
  { value: "FleetNumber", label: "Fleet" },
  { value: "FleetUnitNumber", label: "Fleet Unit" },
  { value: "LessorName", label: "Lessor" },

];

export default searchByColumns;
