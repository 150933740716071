import { Col, Collapse, Row } from "antd";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setExpandSectionsAction } from "../../../../store/document/documentActions";
import { listRateClassesAction } from "../../../../store/rateClass/rateClassActions";
import { listTerritoriesAction } from "../../../../store/territory/territoryActions";
import { listVehicleTypesAction } from "../../../../store/vehicle/vehicleActions";
import DocumentSummary from "../DocumentSummary";
import PolicyCoverageAndPremiumsPanel from "./PolicyCoverageAndPremiumsPanel";
import PolicyCoverageTable from "./PolicyCoverageTable";
import PolicyCustomerPanel from "./PolicyCustomerPanel";
import PolicyDocumentPanel from "./PolicyDocumentPanel";
import PolicyPaymentPanel from "./PolicyPaymentPanel";
import PolicyVehiclePanel from "./PolicyVehiclePanel";

const { Panel } = Collapse;

const PolicyTransactionView = ({ isNew }) => {
  const { control } = useFormContext();
  const dispatch = useDispatch();

  const [activeKeys, setActiveKeys] = useState(
    isNew ? ["1", "2", "3", "4", "5", "6"] : ["1", "2", "3"]
  );

  const vehicleTypes = useSelector((state) => state.vehicle.data);
  const rateClasses = useSelector((state) => state.rateClass.data);
  const territories = useSelector((state) => state.territory.data);
  const expandSections = useSelector(
    (state) => state.document.selectedUi.expandSections
  );

  useEffect(() => {
    if (isNull(vehicleTypes)) {
      dispatch(listVehicleTypesAction());
    }
  }, [vehicleTypes, dispatch]);

  useEffect(() => {
    if (isNull(rateClasses)) {
      dispatch(listRateClassesAction());
    }
  }, [rateClasses, dispatch]);

  useEffect(() => {
    if (isNull(territories)) {
      dispatch(listTerritoriesAction());
    }
  }, [territories, dispatch]);

  useEffect(() => {
    if (!isNull(expandSections)) {
      dispatch(setExpandSectionsAction(null));
      setActiveKeys(expandSections ? ["1", "2", "3", "4", "5", "6"] : []);
    }
  }, [expandSections]);

  const onActiveKeyChangeHandler = (keys) => {
    setActiveKeys(keys);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Collapse
            activeKey={activeKeys}
            onChange={onActiveKeyChangeHandler}
            type="card"
            size="small"
          >
            <Panel header="Summary" key={1}>
              <DocumentSummary isNew={isNew} />
            </Panel>

            <Panel header="Document" key={2}>
              <PolicyDocumentPanel control={control} isNew={isNew} />
            </Panel>
            <Panel header="Customer" key={3}>
              <PolicyCustomerPanel isNew={isNew} control={control} />
            </Panel>
            <Panel header="Vehicle" key={4}>
              <PolicyVehiclePanel control={control} />
            </Panel>
            <Panel header="Coverage & Premiums" key={5}>
              <Row>
                <Col span={12}>
                  <PolicyCoverageTable control={control} />
                </Col>
                <Col span={12}>
                  <PolicyCoverageAndPremiumsPanel control={control} />
                </Col>
              </Row>
            </Panel>
            <Panel header="Payment" key={6}>
              <PolicyPaymentPanel control={control} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(PolicyTransactionView);
