import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { isEmpty, isNull, isUndefined } from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  clearOperationResultAction,
  clearSelectedAction,
  clearSelectedWarningMessageAction,
  setIgnoreSelectedWarningAction,
  updateQuickEditChangesAction,
} from "../../../store/document/documentActions";
import { setTablePreserveOnReload } from "../../../store/ui/uiActions";
import { PreservableStateLink } from "../../shared/PreservableStatePageWrapper";
import {
  dateTimeInputStyle,
  mediumInputStyle,
  shortInputStyle,
} from "../../shared/form/formInputStyles";
import { tailLayout } from "../../shared/form/formLayout";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import CustomInputCurrency from "../../shared/form/reactHookForm/CustomInputCurrency";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import { dateFormat, dateTimeFormat } from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import EditWarningAlert from "./EditWarningAlert";
import NoEditPermissionAlert from "./NoEditPermissionAlert";
import {
  quickEditDebtSchema,
  quickEditPaymentSchema,
  quickEditRegistrationSchema,
  quickEditSchema,
} from "./documentSchemas";
import specialViewProductKeys from "./specialViewProductKeys";

// Constants
const layoutLeft = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

const layoutRight = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
const emptySchema = yup.object().shape({});

const QuickEditDocumentForm = ({
  onCloseHandler,
  parentRefreshAction,
  saveChanges,
  tableName,
}) => {
  return (
    <QuickEditDocumentFormBody
      onCloseHandler={onCloseHandler}
      parentRefreshAction={parentRefreshAction}
      saveChanges={saveChanges}
      tableName={tableName}
    />
  );
};

const QuickEditDocumentFormBody = ({
  onCloseHandler,
  parentRefreshAction,
  saveChanges,
  tableName,
}) => {
  //
  const documentState = useSelector((state) => state.document);
  const selected = documentState.selected;
  const validationSchema = isNull(selected)
    ? emptySchema
    : selected.ProductKey === specialViewProductKeys.DEBT
    ? quickEditDebtSchema
    : selected.ProductKey === specialViewProductKeys.PAYMENT
    ? quickEditPaymentSchema
    : selected.ProductKey === specialViewProductKeys.VEHICLE_REGISTRATION
    ? quickEditRegistrationSchema
    : quickEditSchema;

  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: selected,
    resolver: yupResolver(validationSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const warning =
    documentState.selectedWarning && documentState.selectedWarning.message;
  const ignoreWarning =
    documentState.selectedWarning && documentState.selectedWarning.ignore;
  const operationResult = documentState.operationResult;
  const loading = documentState.selectedLoading;
  const isBatched =
    selected &&
    !(isEmpty(selected.AgencyBatchNo) || isNull(selected.AgencyBatchNo));
  const isDownloaded = isNull(selected) ? false : selected.IsDownloaded;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;

  const icbcBatch =
    isNull(selected && selected.ICBCBatchNo) ||
    isUndefined(selected && selected.ICBCBatchNo)
      ? "No ICBC Batch"
      : selected.ICBCBatchNo;

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult]);

  useEffect(() => {
    const cleanup = async () => {
      dispatch(clearSelectedAction());
    };
    cleanup();
  }, []);

  useEffect(() => {
    if (!isNull(selected)) {
      reset(selected);
      trigger();
    }
  }, [selected]);

  const onSubmitHandler = (values) => {
    if (tableName != null) {
      dispatch(setTablePreserveOnReload(tableName, true));
    }

    const updateTimestamp =
      selected.DCID != values.DCID && (values.DCID?.length ?? 0) > 8;
    const DCID =
      values.ProductKey != 41 && // ALPLUS-2396 Set Timestamp to empty string before calling the Update function (for APV7 documents)
      (updateTimestamp ||
        (selected.DCID == values.DCID && (values.DCID?.length ?? 0) > 8))
        ? values.DCID
        : "";

    const updateEntryDate =
      values.ProductKey === 41 &&
      !dayjs(selected.EntryDateTime).isSame(values.EntryDateTime);

    dispatch(
      updateQuickEditChangesAction(
        { ...values, DCID: DCID },
        ignoreWarning,
        parentRefreshAction,
        updateTimestamp,
        updateEntryDate
      )
    );
  };

  return (
    <Spin spinning={loading} delay={500}>
      <Form
        size="small"
        autoComplete="off"
        name="quickEdit"
        onFinish={() => {}}
      >
        <Row>
          <Col span={24}>
            <Card
              size="small"
              bordered={false}
              title={
                <>
                  {selected && (
                    <>
                      <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Insured">
                          <div
                            className={
                              maskSensitiveData === true ? "blurry-text" : ""
                            }
                          >
                            {selected.InsuredName}
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Product">
                          {selected.Product}
                        </Descriptions.Item>
                        {selected.OnlinePortalIndicator && (
                          <Descriptions.Item label="Channel">
                            {selected.Channel}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Agency">
                          {selected.AgencyNumberAndName}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions size="small" column={2}>
                        <Descriptions.Item label="Document Origin">
                          {isDownloaded ? "ICBC Download" : "Manual Entry"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Effective">
                          {selected.PolicyEffectiveDate
                            ? dayjs(selected.PolicyEffectiveDate).format(
                                dateFormat[0]
                              )
                            : "N/A"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Batch">
                          {isBatched
                            ? `${selected.AgencyBatchNo}/${icbcBatch} ${dayjs(
                                selected.BatchSubmissionDate
                              ).format(dateFormat[0])}`
                            : "Not batched"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Expiry">
                          {selected.PolicyExpiryDate
                            ? dayjs(selected.PolicyExpiryDate).format(
                                dateFormat[0]
                              )
                            : "No Expiry"}
                        </Descriptions.Item>
                      </Descriptions>
                    </>
                  )}
                </>
              }
            >
              <Row>
                <Col span={24} style={{ paddingBottom: 12 }}>
                  {!isUserAllowedToSave && <NoEditPermissionAlert />}
                  {warning && <EditWarningAlert warning={warning} />}
                </Col>
              </Row>
              <Row>
                <Col span={13}>
                  <CustomInput
                    layout={layoutLeft}
                    formLabel="Producer Code 1"
                    control={control}
                    name="ProducerCode1"
                    maxLength={3}
                    disabled={!isUserAllowedToSave}
                    style={shortInputStyle}
                    autoComplete="off"
                  />
                  <CustomInput
                    layout={layoutLeft}
                    formLabel="Producer Code 2"
                    control={control}
                    name="ProducerCode2"
                    maxLength={3}
                    disabled={!isUserAllowedToSave}
                    style={shortInputStyle}
                  />
                  {selected && selected.ProductKey !== 41 && (
                    <CustomInput
                      layout={layoutLeft}
                      formLabel="Timestamp"
                      control={control}
                      required={!isDownloaded}
                      style={mediumInputStyle}
                      name="DCID"
                      maxLength={14}
                      disabled={
                        (isDownloaded && !selected.IsFinancial) ||
                        !isUserAllowedToSave
                      }
                    />
                  )}
                  <CustomDatePicker
                    formItem={{
                      layout: layoutLeft,
                      label: "Transaction Date",
                      required: selected && selected.ProductKey === 41,
                    }}
                    control={control}
                    style={
                      selected && selected.ProductKey === 41
                        ? mediumInputStyle
                        : dateTimeInputStyle
                    }
                    showTime={!(selected && selected.ProductKey === 41)}
                    name="EntryDateTime"
                    format={
                      selected && selected.ProductKey === 41
                        ? dateFormat
                        : dateTimeFormat
                    }
                    disabledDate={
                      (current) => current && current > dayjs().endOf("day") // Disable future dates
                    }
                    disabled={!(selected && selected.ProductKey === 41)}
                    placeholder={""}
                  />
                  {selected &&
                    !(selected.IsRegistration || selected.IsDebt) && (
                      <CustomInput
                        layout={layoutLeft}
                        formLabel="Policy"
                        control={control}
                        name="PolicyNumber"
                        maxLength={6}
                        style={shortInputStyle}
                        disabled={isDownloaded || !isUserAllowedToSave}
                      />
                    )}
                  {selected?.IsPlatedPolicy && (
                    <CustomInput
                      layout={layoutLeft}
                      //if APV16, show PermitNumber
                      formLabel={
                        selected?.ProductKey === 17 ? "Permit" : "Plate"
                      }
                      control={control}
                      name="PlateNumber"
                      maxLength={6}
                      style={shortInputStyle}
                      disabled={!isUserAllowedToSave}
                    />
                  )}
                  {selected && selected.PolicyExpiryDate && (
                    <CustomRadioGroup
                      control={control}
                      layout={layoutLeft}
                      formLabel="Renewal Consent"
                      name="CustomerConsentYN"
                      disabled={!isUserAllowedToSave}
                      options={[
                        { label: "Yes", value: "Y" },
                        { label: "No", value: "N" },
                        { label: "Blank", value: "" },
                      ]}
                    />
                  )}
                </Col>
                <Col span={11}>
                  {selected && !selected.IsPayment && (
                    <CustomInputCurrency
                      layout={layoutRight}
                      formLabel="ICBC Debt Amount"
                      control={control}
                      isPrecision={true}
                      name="ICBCDebtAmt"
                      disabled={isBatched || !isUserAllowedToSave}
                    />
                  )}

                  {selected && !selected.IsPayment && (
                    <CustomInputCurrency
                      layout={layoutRight}
                      formLabel="MVB Debt Amount"
                      control={control}
                      isPrecision={true}
                      name="MVBDebtAmt"
                      disabled={isBatched || !isUserAllowedToSave}
                    />
                  )}
                  <CustomRadioGroup
                    control={control}
                    layout={layoutRight}
                    formLabel="Bill to Dealer"
                    name="BillToDealerIndicator"
                    disabled={!isUserAllowedToSave}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  ></CustomRadioGroup>

                  <CustomInputCurrency
                    layout={layoutRight}
                    formLabel="Amount Due"
                    control={control}
                    isPrecision={true}
                    name="AmountDue"
                    disabled={isBatched || !isUserAllowedToSave}
                  />

                  <CustomInputCurrency
                    layout={layoutRight}
                    formLabel="Amount Financed"
                    control={control}
                    isPrecision={true}
                    name="FinancedAmt"
                    disabled={isBatched || !isUserAllowedToSave}
                  />

                  <CustomInputCurrency
                    layout={layoutRight}
                    formLabel="Cash / Cheque"
                    control={control}
                    isPrecision={true}
                    name="CashChequePayment"
                    disabled={isBatched || !isUserAllowedToSave}
                  />

                  <CustomInputCurrency
                    layout={layoutRight}
                    formLabel="VISA"
                    control={control}
                    isPrecision={true}
                    name="VisaPayment"
                    disabled={isBatched || !isUserAllowedToSave}
                  />

                  <CustomInputCurrency
                    layout={layoutRight}
                    formLabel="Mastercard"
                    control={control}
                    isPrecision={true}
                    name="MCPayment"
                    disabled={isBatched || !isUserAllowedToSave}
                  />

                  <CustomInputCurrency
                    layout={layoutRight}
                    formLabel="AMEX"
                    control={control}
                    isPrecision={true}
                    name="AmexPayment"
                    disabled={isBatched || !isUserAllowedToSave}
                  />

                  <CustomInputCurrency
                    layout={layoutRight}
                    formLabel="Debit"
                    control={control}
                    isPrecision={true}
                    name="DebitPayment"
                    disabled={isBatched || !isUserAllowedToSave}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 12, marginTop: 0 }} />

        <Form.Item {...tailLayout} name="buttons">
          <Row>
            <Col span={24}>
              <Space>
                <Button
                  size="small"
                  type="primary"
                  id="qeSaveButton"
                  onClick={() => handleSubmit(onSubmitHandler)()}
                  loading={loading}
                  disabled={!isDirty || !isValid}
                  form="quickEdit"
                >
                  <SaveOutlined />
                  Save
                </Button>
                <Button
                  size="small"
                  type="dashed"
                  disabled={!isDirty || loading}
                  onClick={() => {
                    dispatch(setIgnoreSelectedWarningAction(false));
                    dispatch(clearSelectedWarningMessageAction());
                    reset();
                    trigger();
                  }}
                >
                  Reset Values
                </Button>

                {isDirty ? (
                  <Popconfirm
                    title="You have unsaved changes. Discard these changes and close?"
                    onConfirm={() => onCloseHandler(true)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="dashed">Close</Button>
                  </Popconfirm>
                ) : (
                  <Button onClick={() => onCloseHandler(true)} type="dashed">
                    Close
                  </Button>
                )}
                <Button
                  onClick={() => {
                    onCloseHandler(false);
                    if (saveChanges != null) {
                      saveChanges();
                    }
                  }}
                  type="link"
                  size="small"
                >
                  <PreservableStateLink
                    to={
                      selected
                        ? `/documents/updatedocumentform/${selected.DocumentKey}`
                        : "/"
                    }
                  >
                    <Space>
                      <EditOutlined />
                      View Details
                    </Space>
                  </PreservableStateLink>
                </Button>
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default QuickEditDocumentForm;
