import React, { useState } from "react";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { columnTypes } from "../../shared/table/columnTypes";
import { tableNames } from "../../shared/table/tableNames";
import { useSelector } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";

const DownloadDetailsTable = () => {
  //
  const [clearAll, setClearAll] = useState(false);

  //
  const data = useSelector((state) => state.dataDownload.details.data);
  const loading = useSelector((state) => state.dataDownload.details.loading);

  const columns = [
    {
      title: "Event",
      dataIndex: "StatusMsg",
      type: columnTypes.TEXT,
      width: 53,
    },
    {
      title: "Date and Time",
      dataIndex: "DateCreated",
      type: columnTypes.DATETIME,
      width: 32,
    },
    {
      title: "Status",
      dataIndex: "Failed",
      type: columnTypes.NONE,
      width: 15,
      align: "center",
      render: (value) => (
        <Button
          disabled={true}
          shape="circle"
          size="small"
          className={value === true ? "failed-request" : "complete-request"}
          style={{ padding: 0 }}
        >
          {value === true ? <CloseOutlined /> : <CheckOutlined />}
        </Button>
      ),
    },
  ];

  return (
    <SearchableTable
      tableName={tableNames.DownloadDetails}
      setClearAll={setClearAll}
      clearAll={clearAll}
      scroll={{ y: 650, x: 105 }}
      sticky
      dataSource={data}
      columns={columns}
      loading={loading}
      size="small"
      rowKey={(r) => r.Id}
    />
  );
};

export default DownloadDetailsTable;
