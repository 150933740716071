import produce from "immer";
import { Actions } from "./manageRenewalsConstants";

const initialBulkUpdate = {
  loading: false,
  operationResult: null,
};

const initialState = {
  data: null,
  loading: false,
  operationResult: null,
  selectedRows: [],
  producerCodes1List: [],
  producerCodes2List: [],
  producerCodes1Loading: false,
  producerCodes2Loading: false,
  bulkUpdate: { ...initialBulkUpdate },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_MANAGE_RENEWALS:
        draft = initialState;
        return draft;
      case Actions.CLEAR_DATA_DOWNLOAD_DETAILS:
        draft.details = initialDetails;
        return draft;
      case Actions.LIST_MANAGE_RENEWALS:
        draft.loading = true;
        if (action.returnProducerLists === true) {
          draft.producerCodes1Loading = true;
          draft.producerCodes2Loading = true;
        }
        return draft;
      case Actions.LIST_MANAGE_RENEWALS_SUCCEEDED:
        draft.data = action.payload.payload.Data;
        draft.operationResult =
          action.payload.payload.Operation.Outcome !== "Success"
            ? action.payload.payload.Operation
            : null;
        draft.loading = false;
        if (action.payload.returnProducerLists === true) {
          draft.producerCodes1Loading = false;
          draft.producerCodes2Loading = false;
          draft.producerCodes1List = action.payload.payload.ProducerCodes1;
          draft.producerCodes2List = action.payload.payload.ProducerCodes2;
        }
        return draft;
      case Actions.SET_SELECTED_ROWS:
        draft.selectedRows = action.payload.selectedRows;
        return draft;
      case Actions.CLEAR_SELECTED_ROWS:
        draft.selectedRows = [];
        return draft;
      case Actions.BULK_UPDATE_MANAGE_CODES:
        draft.bulkUpdate.loading = true;
        return draft;
      case Actions.BULK_UPDATE_MANAGE_CODES_SUCCEEDED:
        draft.bulkUpdate.loading = false;
        draft.bulkUpdate.operationResult = action.payload.Operation;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.bulkUpdate.operationResult = null;
        return draft;
      case Actions.CLEAR_BULK_UPDATE_MANAGE_DATA:
        draft.bulkUpdate = initialBulkUpdate;
        return draft;
      default:
        return state;
    }
  });
};
