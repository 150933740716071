import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router";
import LayoutUserLoggedOut from "../LayoutUserLoggedOut";
import logo from "../alplus-logo.png";
import { Stored, useCustomOidc } from "./customOidcUtils";

export const SessionLostComponent = ({ isAuthenticationError = false }) => {
  //
  const { isAuthenticated } = useCustomOidc();
  const location = useLocation();

  //
  const onStorageUpdate = (e) => {
    const { key, oldValue, newValue } = e;
    if (
      key === Stored.LAST_LOGIN_TIMESTAMP &&
      oldValue !== newValue &&
      newValue != null
    ) {
      console.log(
        "User login detected. Refreshing web page. Timestamp:",
        newValue
      );

      sessionStorage.setItem(Stored.LOAD_ACTOR, Stored.DEFAULT_VALUE);

      // Navigate to the home page
      window.location.replace(`${process.env.REACT_APP_URI}/home`);
    }
  };

  //
  useEffect(() => {
    if (isAuthenticated !== true) {
      console.log("Not authenticated so registering storage listeners");
      window.addEventListener("storage", onStorageUpdate);
      return () => {
        window.removeEventListener("storage", onStorageUpdate);
      };
    }
  }, [isAuthenticated]);

  // Move to /logout if SessionLostComponent is displayed but the page is not /logout
  if (location.pathname !== "/logout") {
    return <Navigate to="/logout" replace={true} />;
  }
  return (
    <LayoutUserLoggedOut>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={4} offset={10}>
              <div className="login-image-sizing">
                <img className="logoImageLoading" src={logo} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="login-sub-message">
              The preferred auto insurance management software by brokers in
              British Columbia
            </Col>
          </Row>
          <Row style={{ marginTop: 14 }}>
            <Col span={24} className="centered-box">
              <div className="tab-box-parent fixed-sized-box">
                <div className="tab-box-child">
                  <div className="tab-box-container">
                    {isAuthenticationError === true ? "Error" : "Logged Out"}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 36 }}>
            <Col span={24} className="centered-box">
              <div className="fixed-sized-box instructions-message centered-message">
                {isAuthenticationError === true ? (
                  "Something went wrong while authenticating."
                ) : localStorage.getItem(Stored.PRESSED_LOGOUT) ===
                  Stored.DEFAULT_VALUE ? (
                  <>You have logged out of your session</>
                ) : (
                  <>
                    You have been logged out of your session due to inactivity
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 36 }}>
            <Col span={24} className="centered-box">
              <Button
                type="primary"
                className="fixed-sized-box login-button"
                onClick={() => {
                  window.location.replace(`${process.env.REACT_APP_URI}/home`);
                }}
              >
                Log Back In
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutUserLoggedOut>
  );
};
