import { Steps } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewInventoryMovementAction,
  clearOperationResultAction,
  initializeInventoryMovementUI,
  setTransferInventoryCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { PreservableStatePageWrapper } from "../../shared/PreservableStatePageWrapper";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import MoveInventorySummary from "../shared/MoveInventorySummary";
import TransferInventoryStep1 from "./TransferInventoryStep1";
import TransferInventoryStep2 from "./TransferInventoryStep2";

// Constants
const stepsContent = {
  padding: 8,
};

const TransferInventorySteps = () => {
  //
  const dispatch = useDispatch();

  //
  const currentStep = useSelector(
    (state) => state.inventory.moveInventory.ui.transferInventoryCurrentStep
  );
  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  //
  useEffect(() => {
    if (
      operationResult &&
      operationResult.Name === "ProcessMovement" &&
      operationResult.Outcome === "Success"
    ) {
      dispatch(setTransferInventoryCurrentStepAction(2));
    }
    openNotificationOperationResult(
      operationResult,
      () => dispatch(clearOperationResultAction()),
      false
    );
  }, [operationResult]);

  return (
    <PermissionWrapper entityName="MoveInventory">
      <PreservableStatePageWrapper
        onCleanup={() => {
          dispatch(clearNewInventoryMovementAction());
          dispatch(initializeInventoryMovementUI());
        }}
      >
        <Steps
          current={currentStep}
          size="small"
          items={[
            { title: "Select Agency" },
            { title: "Define Details" },
            { title: "Confirmation" },
          ]}
        />

        <div style={stepsContent}>
          {currentStep === 0 && <TransferInventoryStep1 />}
          {currentStep === 1 && <TransferInventoryStep2 />}
          {currentStep === 2 && (
            <MoveInventorySummary
              returnButtonTitle="Transfer More Inventory"
              resetAction={() => {
                dispatch(setTransferInventoryCurrentStepAction(0));
              }}
            />
          )}
        </div>
      </PreservableStatePageWrapper>
    </PermissionWrapper>
  );
};

export default TransferInventorySteps;
