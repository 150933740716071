export const Actions = {
  LIST_BATCHES: "LIST_BATCHES",
  LIST_BATCHES_SUCCEEDED: "LIST_BATCHES_SUCCEEDED",
  LIST_BATCHES_FAILED: "LIST_BATCHES_FAILED",
  CLEAR_BATCH_LIST: "CLEAR_BATCH_LIST",
  READ_BATCH: "READ_BATCH",
  READ_BATCH_SUCCEEDED: "READ_BATCH_SUCCEEDED",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  CLEAR_INLINE_EDIT_OPERATION_RESULT: "CLEAR_INLINE_EDIT_OPERATION_RESULT",
  SET_INLINE_EDIT_OPERATION_RESULT: "SET_INLINE_EDIT_OPERATION_RESULT",
  CLEAR_SELECTED: "CLEAR_SELECTED_BATCH",
  SET_SELECTED: "SET_SELECTED_BATCH",
  BACKUP_BATCHING_DATA: "BACKUP_BATCHING_DATA",
  RESTORE_BATCHING_DATA: "RESTORE_BATCHING_DATA",
  SET_SEARCH_CRITERIA: "SET_SEARCH_CRITERIA",
  UPDATE_BATCH: "UPDATE_BATCH",
  UPDATE_BATCH_SUCCEEDED: "UPDATE_BATCH_SUCCEEDED",
  UPDATE_INLINE_EDIT_CHANGES: "UPDATE_INLINE_EDIT_CHANGES",
  UPDATE_INLINE_EDIT_CHANGES_SUCCEEDED: "UPDATE_INLINE_EDIT_CHANGES_SUCCEEDED",
  GET_NEW_BATCH: "GET_NEW_BATCH",
  GET_NEW_BATCH_SUCCEEDED: "GET_NEW_BATCH_SUCCEEDED",
  UPDATE_INCLUSION_EXCLUSION: "UPDATE_INCLUSION_EXCLUSION",
  UPDATE_INCLUSION_EXCLUSION_SUCCEEDED: "UPDATE_INCLUSION_EXCLUSION_SUCCEEDED",
  GET_NEXT_BATCH_NUMBER: "GET_NEXT_BATCH_NUMBER",
  GET_NEXT_BATCH_NUMBER_SUCCEEDED: "GET_NEXT_BATCH_NUMBER_SUCCEEDED",
  LIST_MULTILICENCE_POLICIES: "LIST_MULTILICENCE_POLICIES",
  LIST_MULTILICENCE_POLICIES_SUCCEEDED: "LIST_MULTILICENCE_POLICIES_SUCCEEDED",
  CLEAR_MULTILICENCE_POLICIES: "CLEAR_MULTILICENCE_POLICIES",
  SET_SELECTED_MULTILICENCE_POLICIES: "SET_SELECTED_MULTILICENCE_POLICIES",
  CLEAR_SELECTED_MULTILICENCE_POLICIES: "CLEAR_SELECTED_MULTILICENCE_POLICIES",
  SET_NEW_BATCH_CRITERIA: "SET_NEW_BATCH_CRITERIA",
  CLEAR_NEW_BATCH: "CLEAR_NEW_BATCH",
  CLEAR_NEW_BATCH_DATA: "CLEAR_NEW_BATCH_DATA",
  SET_DOCUMENTS_INCLUSION_STATE: "SET_DOCUMENTS_INCLUSION_STATE",
  SET_DOCUMENTS_ITEM_NUMBERS: "SET_DOCUMENTS_ITEM_NUMBERS",
  SET_FIRST_ELEMENT_KEY: "SET_FIRST_ELEMENT_KEY",
  CLEAR_DOCUMENTS_INCLUSION_STATE: "CLEAR_DOCUMENTS_INCLUSION_STATE",
  SET_SELECTED_BATCH_DOCUMENT_KEYS: "SET_SELECTED_BATCH_DOCUMNET_KEYS",
  CLEAR_SELECTED_BATCH_DOCUMENT_KEYS: "CLEAR_SELECTED_BATCH_DOCUMNET_KEYS",
  CLOSE_BATCH: "CLOSE_BATCH",
  CLOSE_BATCH_SUCCEEDED: "CLOSE_BATCH_SUCCEEDED",
  CLEAR_CLOSE_BATCH_OPERATION_RESULT: "CLEAR_CLOSE_BATCH_OPERATION_RESULT",
  SET_PROCESS_BATCH_CURRENT_STEP: "SET_PROCESS_BATCH_CURRENT_STEP",
  REMOVE_DOCUMENT_FROM_BATCH: "REMOVE_DOCUMENT_FROM_BATCH",
  REMOVE_DOCUMENT_FROM_BATCH_SUCCEEDED: "REMOVE_DOCUMENT_FROM_BATCH_SUCCEEDED",
  ADD_DOCUMENTS_TO_BATCH: "ADD_DOCUMENTS_TO_BATCH",
  ADD_DOCUMENTS_TO_BATCH_SUCCEEDED: "ADD_DOCUMENTS_TO_BATCH_SUCCEEDED",
  UNDO_BATCH: "UNDO_BATCH",
  UNDO_BATCH_SUCCEEDED: "UNDO_BATCH_SUCCEEDED",
  CLEAR_IS_UNDONE: "CLEAR_IS_UNDONE",
  UPDATE_BATCH_HEADER: "UPDATE_BATCH_HEADER",
  UPDATE_BATCH_HEADER_SUCCEEDED: "UPDATE_BATCH_HEADER_SUCCEEDED",
  LIST_DOCUMENTS_AVAILABLE_TO_ADD: "LIST_DOCUMENTS_AVAILABLE_TO_ADD",
  LIST_DOCUMENTS_AVAILABLE_TO_ADD_SUCCEEDED:
    "LIST_DOCUMENTS_AVAILABLE_TO_ADD_SUCCEEDEED",
  LIST_BATCHES_FOR_REPORTING: "LIST_BATCHES_FOR_REPORTING",
  LIST_BATCHES_FOR_REPORTING_SUCCEEDED: "LIST_BATCHES_FOR_REPORTING_SUCCEEDED",
  RESET_REPORTING_DATA: "RESET_REPORTING_DATA",
};
