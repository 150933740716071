import dayjs from "dayjs";
import { isEmpty, isNull } from "lodash";
import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { toStartOfDay } from "../../components/shared/formatting";
import { Actions } from "./documentConstants";

export const listDocumentsAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.LIST_DOCUMENTS });
  try {
    const payload = await customPost(
      entityType.DOCUMENT,
      genericActionType.FIND,
      values
    );

    if (payload == null) throw "Payload is empty";

    dispatch({ type: Actions.LIST_DOCUMENTS_SUCCEEDED, payload });
  } catch (e) {
    dispatch({ type: Actions.LIST_DOCUMENTS_FAILED });
  }
};

export const clearListAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_LIST });
};

export const setRefreshListFlagAction = (flag) => async (dispatch) => {
  dispatch({ type: Actions.SET_REFRESH_LIST_FLAG, payload: { Flag: flag } });
};

export const readForQuickEditAction = (documentKey) => async (dispatch) => {
  dispatch({ type: Actions.READ_DOCUMENT_FOR_QUICK_EDIT });
  const payload = await customGet(entityType.DOCUMENT, "ReadForQuickEdit", {
    params: {
      documentKey: documentKey,
    },
  });
  dispatch({ type: Actions.READ_DOCUMENT_FOR_QUICK_EDIT_SUCCEEDED, payload });
};

export const readAction = (documentKey, action) => async (dispatch) => {
  dispatch({ type: Actions.READ_DOCUMENT });
  const payload = await customGet(entityType.DOCUMENT, genericActionType.READ, {
    params: {
      documentKey: documentKey,
    },
  });
  dispatch({ type: Actions.READ_DOCUMENT_SUCCEEDED, payload });
  if (action != null) {
    action();
  }
};

export const deleteAction = (documentKey) => async (dispatch) => {
  dispatch({ type: Actions.DELETE_DOCUMENT });
  const payload = await customGet(
    entityType.DOCUMENT,
    genericActionType.DELETE,
    {
      params: {
        documentKey: documentKey,
      },
    }
  );
  dispatch({ type: Actions.DELETE_DOCUMENT_SUCCEEDED, payload });
};

export const updateQuickEditChangesAction =
  (
    values,
    ignoreWarnings,
    parentRefreshAction,
    updateTimestamp,
    updateEntryDate
  ) =>
  async (dispatch, getState) => {
    const userKey = getState().actor.details.data.UserId;
    dispatch({ type: Actions.UPDATE_QUICK_EDIT_CHANGES });

    // Prepare entry date time
    var entryDateTime = values.EntryDateTime;
    if (updateEntryDate) {
      entryDateTime = toStartOfDay(entryDateTime);
    }

    const payload = await customPost(
      entityType.DOCUMENT,
      "UpdateQuickEditChanges",
      { ...values, EntryDateTime: entryDateTime },
      {
        params: {
          userKey: userKey,
          validatedInventoryStatus: !ignoreWarnings,
          updateTimestamp: updateTimestamp,
          updateEntryDate: updateEntryDate,
        },
      }
    );
    dispatch({ type: Actions.UPDATE_QUICK_EDIT_CHANGES_SUCCEEDED, payload });

    payload.Operation.Outcome === "Success" && dispatch(parentRefreshAction);
  };

export const updateAction =
  (values, ignoreWarnings, isCreateDocument) => async (dispatch, getState) => {
    const state = getState();
    const userKey = state.actor.details.data.UserId;
    const selected = state.document.selected;
    const updateTimestamp =
      selected.DCID != values.DCID && (values.DCID?.length ?? 0) > 8;
    const isNew =
      (isNull(selected) ? false : selected.DocumentKey === 0) ||
      isCreateDocument === true;

    const isNewAPV7 =
      isNew && !values.DCID && values.EntryDateTime && values.ProductKey === 41;

    const DCID =
      updateTimestamp ||
      (selected.DCID == values.DCID && (values.DCID?.length ?? 0) > 8)
        ? values.DCID
        : "";

    const updateEntryDate =
      selected.ProductKey === 41 &&
      !dayjs(selected.EntryDateTime).isSame(values.EntryDateTime);

    // Prepare entry date time
    var entryDateTime = values.EntryDateTime;
    if (isNewAPV7 || updateEntryDate) {
      entryDateTime = toStartOfDay(entryDateTime);
    }

    // If it's a registration & user tried to make regnumber blank, revert it
    const RegNumber =
      selected.IsRegistration &&
      (values.RegNumber == "" || values.RegNumber == null) &&
      selected.RegNumber != null &&
      selected.RegNumber != ""
        ? selected.RegNumber
        : values.RegNumber;

    dispatch({ type: Actions.UPDATE_DOCUMENT });
    const payload = await customPost(
      entityType.DOCUMENT,
      "Update",
      {
        ...values,
        DCID: DCID,
        RegNumber: RegNumber,
        EntryDateTime: entryDateTime,
      },
      {
        params: {
          userKey: userKey,
          validatedInventoryStatus: !ignoreWarnings,
          updateTimestamp: updateTimestamp,
          updateEntryDate: updateEntryDate,
          isNewAPV7: isNewAPV7,
        },
      }
    );
    dispatch({ type: Actions.UPDATE_DOCUMENT_SUCCEEDED, payload });
  };

export const createCertificateCopyAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.CREATE_CERTIFICATE_COPY });
  const payload = await customPost(
    entityType.DOCUMENT,
    "CreateCertificateCopy",
    values
  );
  dispatch({ type: Actions.CREATE_CERTIFICATE_COPY_SUCCEEDED, payload });
};

export const createDocumentByProductAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.CREATE_DOCUMENT_BY_PRODUCT });
  const payload = await customPost(
    entityType.DOCUMENT,
    "CreateInstanceByProduct",
    values
  );
  dispatch({
    type: Actions.CREATE_DOCUMENT_BY_PRODUCT_SUCCEEDED,
    payload,
  });
};

export const createDocumentByProductKeyAction =
  (values) => async (dispatch) => {
    dispatch({ type: Actions.CREATE_DOCUMENT_BY_PRODUCT_KEY });
    const payload = await customPost(
      entityType.DOCUMENT,
      "CreateInstanceByProductKey",
      values
    );
    dispatch({
      type: Actions.CREATE_DOCUMENT_BY_PRODUCT_KEY_SUCCEEDED,
      payload,
    });
  };

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const clearSelectedAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED });
};

export const setSelectedAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.SET_SELECTED, payload: { Data: values } });
};

export const clearSelectedWarningMessageAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_WARNING_MESSAGE });
};

export const setIgnoreSelectedWarningAction = (ignore) => async (dispatch) => {
  dispatch({
    type: Actions.SET_IGNORE_SELECTED_WARNING,
    payload: { ignore: ignore },
  });
};

export const setSearchValuesAction = (values) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SEARCH_VALUES,
    payload: { searchValues: values },
  });
};

export const setExpandSectionsAction = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_EXPAND_SECTIONS,
    payload: { expandSections: value },
  });
};

export const setManualDocumentSettingsAction = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_MANUAL_SETTINGS,
    payload: { manualSettings: value },
  });
};

export const clearManualDocumentSettingsAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_MANUAL_SETTINGS,
  });
};

export const listTransactionTypesAction = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_TRANSACTION_TYPES });
  const payload = await customPost(entityType.DOCUMENT, "ListTransactionTypes");
  dispatch({ type: Actions.LIST_TRANSACTION_TYPES_SUCCEEDED, payload });
};

export const listPolicyProductsAction = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_POLICY_PRODUCTS });
  const payload = await customGet(entityType.PRODUCT, genericActionType.LIST);
  dispatch({ type: Actions.LIST_POLICY_PRODUCTS_SUCCEEDED, payload });
};

export const documentTransformWrapper = (docKey, action) => async () => {
  const values = await customGet(entityType.DOCUMENT, genericActionType.READ, {
    params: {
      documentKey: docKey,
    },
  });
  action(values.Data);
};

export const transformDocumentInput = (values) => (_) => {
  return {
    IsUserAllowedToViewCommission: values.IsUserAllowedToViewCommission,
    IsNew: values.DocumentKey === 0,
    IsRegistration: values.IsRegistration,
    HasFleetNumber: !isEmpty(values.FleetNumber),
    DocumentKey: values.DocumentKey,
    FileName: `Document_${values.DocumentKey.toString()}`,
  };
};
