import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  listBatchesForReporting,
  resetReportingData,
} from "../../../store/batching/batchingActions";
import { isEmpty } from "lodash";
import { Controller } from "react-hook-form";
import { Form, Select } from "antd";

const BatchRangeSelectEx = (props) => {
  const batchFromName = "BatchNumberFrom";
  const batchToName = "BatchNumberTo";
  const {
    transactionDate,
    setValue,
    control,
    disabled,
    agencyKeys,
    useICBCStmtLogic,
  } = props;
  const dispatch = useDispatch();
  const reportingState = useSelector((state) => state.batching.reporting);

  const list = reportingState.batches.data;
  const loading = reportingState.batches.loading;
  const batchFormItemStyle = {
    display: "inline-block",
    marginBottom: 0,
  };

  useEffect(() => {
    //if agency list gets empty or multiple agencies are selected, clear the selection
    if (isEmpty(agencyKeys) || agencyKeys.length !== 1) {
      dispatch(resetReportingData());
      setValue(batchFromName, null);
      setValue(batchToName, null);
    } else {
      dispatch(
        listBatchesForReporting({
          AgencyKey: agencyKeys[0],
          TransactionDate: transactionDate,
          UseICBCStmtLogic: useICBCStmtLogic,
        })
      );
    }
  }, [agencyKeys, transactionDate, dispatch]);

  useEffect(() => {
    dispatch(resetReportingData());
  }, []);

  return (
    <>
      <Form.Item style={batchFormItemStyle} name={batchFromName}>
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Controller
            control={control}
            name={batchFromName}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) => {
                  const label = option.label ? option.label : option.children;
                  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                options={list}
                loading={loading}
                allowClear={true}
                disabled={disabled || agencyKeys.length !== 1}
                name={batchFromName}
                style={{ width: 170 }}
                placeholder="Select greather than"
                virtual={false}
              />
            )}
          />
        </span>
      </Form.Item>
      <span
        style={{
          display: "inline-block",
          width: "24px",
          textAlign: "center",
        }}
      >
        -
      </span>
      <Form.Item name={batchToName} style={batchFormItemStyle}>
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Controller
            control={control}
            name={batchToName}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) => {
                  const label = option.label ? option.label : option.children;
                  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                allowClear={true}
                options={list}
                loading={loading}
                disabled={disabled || agencyKeys.length !== 1}
                name={batchToName}
                style={{ width: 170 }}
                placeholder="Select less than"
                virtual={false}
              />
            )}
          />
        </span>
      </Form.Item>
    </>
  );
};
export default BatchRangeSelectEx;
