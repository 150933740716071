import React from "react";
import { Navigate } from "react-router";
import { SessionLostComponent } from "./oidc/SessionLostComponent";
import { useCustomOidc } from "./oidc/customOidcUtils";

export const Logout = () => {
  //
  const { isOidcAuthenticated } = useCustomOidc();

  if (isOidcAuthenticated === true) {
    return <Navigate to="/home" />;
  }
  return <SessionLostComponent />;
};
