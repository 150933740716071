import dayjs from "dayjs";
import * as yup from "yup";
import {
  dateRangeRequiredMessage,
  lessOrEqualOneYearMessage,
  requiredMessage,
} from "../../../components/shared/form/formValidatonRules";

export const downloadRequestSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  FromDate: yup.string().required(requiredMessage).nullable(),
  ToDate: yup.string().required(requiredMessage).nullable(),
});

export const dataDownloadLogSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  TransactionDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  ShowIncompleteRequests: yup.boolean().required(requiredMessage),
  ShowCompleteRequests: yup.boolean().required(requiredMessage),
  Show: yup.boolean().when(["ShowIncompleteRequests", "ShowCompleteRequests"], {
    is: false,
    then: yup.boolean().required("At least one of the options is required."),
  }),
});
