import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { Actions } from "./dataDownloadConstants";

export const clearDataDownloadDetails = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_DATA_DOWNLOAD_DETAILS,
  });
};

export const clearDataDownloadLog = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_DATA_DOWNLOAD_LOG,
  });
};

const transformDataDownloadLogSearchValues = (values) => {
  if (values == null) return null;
  let transformedValues = values;
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    transformedValues = !agencies ? [] : agencies.map((a) => a.Id);
  }
  return transformedValues;
};

export const listDataDownloadLog = (values) => async (dispatch, getState) => {
  const searchValues =
    transformDataDownloadLogSearchValues(values) ??
    getState().dataDownload.searchValues;

  dispatch({ type: Actions.LIST_DATA_DOWNLOAD_LOG, searchValues });

  const payload = await customPost(
    entityType.DOCUMENT,
    "ListDataDownloadLog",
    searchValues
  );

  dispatch({ type: Actions.LIST_DATA_DOWNLOAD_LOG_SUCCEEDED, payload });
};

export const submitDownloadRequest = (values) => async (dispatch) => {
  dispatch({ type: Actions.SUBMIT_DOWNLOAD_REQUEST, values });

  const payload = await customPost(
    entityType.DOCUMENT,
    "SubmitDataDownloadRequest",
    values
  );

  dispatch({ type: Actions.SUBMIT_DOWNLOAD_REQUEST_SUCCEEDED, payload });
};

export const getDataDownloadDetails = (fileDownloadKey) => async (dispatch) => {
  dispatch({ type: Actions.GET_DATA_DOWNLOAD_DETAILS });

  const payload = await customGet(
    entityType.DOCUMENT,
    "GetDownloadRequestDetails",
    {
      params: {
        fileDownloadKey: fileDownloadKey,
      },
    }
  );

  dispatch({ type: Actions.GET_DATA_DOWNLOAD_DETAILS_SUCCEEDED, payload });
};

export const getLatestDateRanges = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.GET_LATEST_DATE_RANGES });

  const payload = await customGet(
    entityType.DOCUMENT,
    "GetDefaultDateRangeForDownloadRequest",
    {
      params: {
        agencyKey: agencyKey,
      },
    }
  );

  dispatch({ type: Actions.GET_LATEST_DATE_RANGES_SUCCEEDED, payload });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const clearDownloadRequestTab = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_DOWNLOAD_REQUEST_TAB });
};
