import { SearchOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Form, Row } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import {
  searchMovementsAction,
  setMovementsSearchCriteriaAction,
} from "../../../store/inventory/inventoryActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import { dateFormat, toTZNeutralFormat } from "../../shared/formatting";
import { ManageInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import { searchMovementsSchema } from "../shared/moveInventorySchemas";

//
const initialValues = {
  AgencyKey: "",
  StockCentreConfNo: "",
  FixedDateRange: fixedDateRanges.LAST_X_DAYS,
  NumberOfDays: 14,
  TransactionDate: ["", ""],
};

const layout = {
  labelCol: { span: 8, lg: 8, xl: 6, xxl: 5 },
  wrapperCol: { span: 15, lg: 15, xl: 17, xxl: 18 },
};

const secondLayout = {
  labelCol: { span: 8, lg: 12, xl: 12, xxl: 12 },
  wrapperCol: { span: 15, lg: 11, xl: 11, xxl: 11 },
};

const SearchMovementsForm = ({ setClearAll }) => {
  const searchCriteria = useSelector(
    (state) => state.inventory.inventoryMovements.searchCriteria
  );

  // Form
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: searchCriteria != null ? searchCriteria : initialValues,
    resolver: yupResolver(searchMovementsSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector(
    (state) => state.inventory.inventoryMovements.loading
  );

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ManageInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      if (searchCriteria == null || searchCriteria == false) {
        resetFieldsToDefault();
      } else {
        reset({ ...searchCriteria });
      }
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const resetFieldsToDefault = () => {
    var initialAgency = getInitialAgency(homeAgency, agencies);

    reset({
      ...initialValues,
      AgencyKey: initialAgency,
      TransactionDate: [
        toTZNeutralFormat(dayjs().endOf("day").subtract(2, "weeks")),
        toTZNeutralFormat(dayjs().endOf("day")),
      ],
    });
    trigger();
    dispatch(setMovementsSearchCriteriaAction(null));
  };

  const onSubmitHandler = (values) => {
    dispatch(searchMovementsAction(values));
    dispatch(setMovementsSearchCriteriaAction(values));
    reset(values);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandler)()}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <AgencySelectEx
                  layout={layout}
                  formLabel="Agency"
                  name="AgencyKey"
                  required={true}
                  agencies={currentAgencies}
                  isLoading={defaultValues.AgencyKey === ""}
                  control={control}
                />

                <AdvancedRangePicker
                  formItem={{
                    layout: layout,
                    required: true,
                    label: "Transaction Date",
                  }}
                  control={control}
                  setValue={setValue}
                  allowClear={true}
                  disableFutureDates={true}
                  name="TransactionDate"
                  format={dateFormat}
                  disabledDate={(
                    current // Should we allow users to select over a year?
                  ) => current && current > dayjs().endOf("day")}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                <CustomInput
                  control={control}
                  layout={secondLayout}
                  formLabel="Stock Centre Confirmation"
                  name="StockCentreConfNo"
                />
                <Row>
                  <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={0}>
                    <SearchButtons
                      onClick={() => {
                        setClearAll(true);
                        resetFieldsToDefault();
                      }}
                      isValid={isValid}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={4}>
                <SearchButtons
                  onClick={() => {
                    setClearAll(true);
                    resetFieldsToDefault();
                  }}
                  isValid={isValid}
                  loading={loading}
                />
              </Col>
            </Row>
            <Row></Row>
          </Form>
        </Col>
      </Row>
      <Divider style={{ marginTop: 16, marginBottom: 8 }} />
    </div>
  );
};

const SearchButtons = (props) => {
  const { isValid, loading, onClick } = props;
  return (
    <Row justify="center" gutter={8}>
      <Col>
        <Button
          disabled={!isValid}
          loading={loading}
          autoFocus={true}
          id="processButton"
          type="primary"
          htmlType="submit"
        >
          <SearchOutlined />
          Search
        </Button>
      </Col>
      <Col>
        <Button onClick={onClick}>Clear Results</Button>
      </Col>
    </Row>
  );
};

export default SearchMovementsForm;
