import * as yup from "yup";
import { requiredMessage } from "../shared/form/formValidatonRules";
import dayjs from "dayjs";
import { loanTypes } from "./constants";

export const loanInformationPanelSchema = yup.object().shape({
  LoanType: yup.string().required(requiredMessage),
  ProcessingBranch: yup.number().required(requiredMessage),
  ClientName: yup.string().nullable(),
  RepresentativeName: yup.string().nullable(),
  ProducerName: yup.string().nullable(),
  LoanDescription: yup.string().nullable(),
  ContractEffectiveDate: yup.string().required(requiredMessage),
  FirstPaymentDate: yup
    .string()
    .required(requiredMessage)
    .test(
      "secondOrSixteenth",
      "The day must be 2nd or 16th",
      (value) =>
        value && (dayjs(value).date() == 2 || dayjs(value).date() == 16)
    ),
  InterestRate: yup.number().required(requiredMessage),
  LoanPeriod: yup.number().required(requiredMessage),
  PolicyFees: yup.number().required(requiredMessage),
  TotalPremiumAmount: yup
    .number()
    .typeError(requiredMessage)
    .required(requiredMessage),
  ProcessingCharge: yup.string().when("LoanType", {
    is: (LoanType) => LoanType == loanTypes.NEW,
    then: (schema) => schema.required(requiredMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  CurrentLoanBalance: yup.number().when("LoanType", {
    is: (LoanType) => LoanType == loanTypes.CHANGE,
    then: (schema) =>
      schema.typeError(requiredMessage).required(requiredMessage),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const popAuthorizationFormSchema = yup.object().shape({
  Email: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .email("Invalid email"),
});
