import { Select } from "antd";
import { isEmpty } from "lodash";

const { Option } = Select;

export const disableSelectOptions = (options, selectedValues, extraOptions) =>
  options.map((o) => (
    <Option
      key={o.value}
      value={o.value}
      disabled={
        (!!selectedValues &&
          !isEmpty(selectedValues.filter((f) => f.Name === o.value))) ||
        (!!extraOptions && !isEmpty(extraOptions.filter((f) => f === o.value)))
      }
    >
      {o.label}
    </Option>
  ));
