import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import { transformDataCollectionInput } from "../../store/dataCollection/dataCollectionActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../store/report/reportActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import PdfViewer from "../shared/PdfViewer";
import ReportParamPanelHeader from "../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import DrillDownSortFieldSelectEx from "../shared/form/DrillDownSortFieldSelectEx";
import ExtraReportFilterContainer from "../shared/form/ExtraReportFiltersContainer";
import ProducerSelectEx from "../shared/form/ProducerSelectEx";
import { fixedDateRanges } from "../shared/form/fixedDateRanges";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toEndOfDay, toStartOfDay } from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { ReportCustomerDataCollection } from "../shared/securityRoleTasks";
import { agenciesListEqual } from "../shared/sorting";
import { dataCollectionReportSchema } from "./dataCollectionReportSchema";
import { drillDownSortFieldOptions } from "./reportParameters";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKeys: [],
  FixedDateRange: fixedDateRanges.LAST_MONTH,
  TransactionDate: ["", ""],
  IncludeMidTermChanges: false,
  IncludeTempPermits: false,
  Keyword: "",
  DrillDownSortFields: [],
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const DataCollectionReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(dataCollectionReportSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const agencies = useSelector((state) => state.agency.listCompact);
  const reportState = useSelector((state) => state.report);
  const isExporting = reportState.isExporting;
  const operationResult = reportState.operationResult;
  const isViewerVisible = reportState.isViewerVisible;
  const isFetching = reportState.isFetching;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(
        listAgenciesCompactAction(brokerId, ReportCustomerDataCollection)
      );
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: [initialAgency],
        TransactionDate: [
          toStartOfDay(dayjs().subtract(1, "month").startOf("month")),
          toEndOfDay(dayjs().subtract(1, "month").endOf("month")),
        ],
      };
      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const reportName = "CustomerDataCollectionReport";

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformDataCollectionInput(values),
        entityType.DATA_COLLECTION,
        `PrintDataCollection`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="DataCollectionReport">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <AgencyMultiSelectEx
                        layout={defaultLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Transaction Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        disableFutureDates={true}
                        name="TransactionDate"
                        format={dateFormat}
                        disabledDate={(current) =>
                          current && current > dayjs().endOf("day")
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={18} xl={14} xxl={10}>
                      <Form.Item
                        name="Keyword"
                        label="Keyword in Agent Comments"
                      >
                        <Controller
                          control={control}
                          name="Keyword"
                          render={({ field }) => (
                            <Input {...field} name="Keyword" />
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <ExtraReportFilterContainer>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="ProducerCode1Keys"
                          label="Producer 1"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode1Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={1}
                            taskKey={ReportCustomerDataCollection}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ProducerCode2Keys"
                          label="Producer 2"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode2Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={2}
                            taskKey={ReportCustomerDataCollection}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          {...layout}
                          label="Include"
                          name="Include"
                          style={{ marginBottom: 0 }}
                        >
                          <Space direction="vertical">
                            <Controller
                              control={control}
                              name="IncludeMidTermChanges"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    setValue(
                                      "IncludeMidTermChanges",
                                      e.target.checked,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      }
                                    );
                                  }}
                                  inputRef={field.ref}
                                  name="IncludeMidTermChanges"
                                >
                                  Midterm Changes
                                </Checkbox>
                              )}
                            />
                            <Controller
                              control={control}
                              name="IncludeTempPermits"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    setValue(
                                      "IncludeTempPermits",
                                      e.target.checked,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      }
                                    );
                                  }}
                                  inputRef={field.ref}
                                  name="IncludeTempPermits"
                                >
                                  Temporary Permits
                                </Checkbox>
                              )}
                            />
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                  </ExtraReportFilterContainer>

                  <Row>
                    <Col span={24}>
                      <DrillDownSortFieldSelectEx
                        fieldOptions={drillDownSortFieldOptions}
                        control={control}
                        trigger={trigger}
                        isSubmitted={isSubmitted}
                      />
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformDataCollectionInput(getValues()),
                              reportName,
                              entityType.DATA_COLLECTION
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          reset(initialDefaultValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default DataCollectionReport;
