import { isEqual } from "lodash";

export const compareStrings = (a, b) => {
  return a ? a.localeCompare(b) : -1;
};

export const compareDates = (a, b) => {
  let result;
  if (a === b) result = 0;
  else if (a === null && b) result = -1;
  else if (a && b === null) result = 1;
  else result = new Date(a) > new Date(b) ? 1 : -1;

  return result;
};

export const compareNumbers = (a, b) => {
  let result;
  if (a === b) result = 0;
  else if (a === null && b) result = -1;
  else if (a && b === null) result = 1;
  else result = a > b ? 1 : -1;

  return result;
};

export const compareArrayOfStrings = (a, b) => {
  let result;
  if (isEqual(a, b) && a.length !== 0) result = 0;
  else if (a === null && b) result = -1;
  else if (a && b === null) result = 1;
  else if (a.length === 1 && b.length === 1) {
    result = a[0].localeCompare(b[0]);
  } else result = a.length > b.length ? 1 : -1;

  return result;
};

export const agenciesListEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every(
    (i) => o1[i].Id === o2[i].Id && o1[i].FullName === o2[i].FullName
  );
