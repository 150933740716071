import { SearchOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Radio, Row, Space } from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { listAgenciesCompactAction } from "../../../store/agency/agencyActions";
import {
  listAllocatedToAgentsAction,
  setItemsSearchCriteriaAction,
} from "../../../store/inventory/inventoryActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import SearchSelectEx from "../../shared/form/SearchSelectEx";
import { ViewInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import searchByFormSchema from "./searchByFormSchema";

//
const initialValues = {
  AgencyKey: "",
  SearchValue: "",
  SearchAllInventory: false,
  AllocatedToAgentKey: null,
};

const firstLayout = {
  labelCol: { span: 5, lg: 7, xl: 7, xxl: 6 },
  wrapperCol: { span: 18, lg: 16, xl: 16, xxl: 17 },
};

const secondLayout = {
  labelCol: { span: 5, lg: 0, xl: 0, xxl: 0 },
  wrapperCol: { span: 18, lg: 24, xl: 24, xxl: 24 },
};
const thirdLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};

const SearchByForm = ({
  onSubmitHandler,
  onResetHandler,
  loading,
  setClearAll,
}) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    getValues,
    formState: { isValid, isDirty, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(searchByFormSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const searchValue = watch("SearchValue");

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [maskedAllocatedToAgents, setMaskedAllocatedToAgents] = useState([]);

  //
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const allocatedToAgents = useSelector(
    (state) => state.inventory.allocatedToAgents.data
  );
  const allocatedToAgentsLoading = useSelector(
    (state) => state.inventory.allocatedToAgents.loading
  );

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      dispatch(listAllocatedToAgentsAction(initialAgency));
      reset({ ...initialValues, AgencyKey: initialAgency });
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    if (allocatedToAgents != null && maskSensitiveData === true) {
      const newList = allocatedToAgents.map((item) => {
        return {
          label: <div className="blurry-text">{item.label}</div>,
          filter: item.label,
          value: item.value,
        };
      });
      setMaskedAllocatedToAgents(newList);
    }
  }, [allocatedToAgents]);

  const onSubmitHandlerEx = (_) => {
    var values = getValues();
    onSubmitHandler(values);
    dispatch(setItemsSearchCriteriaAction(values));
    reset(values);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandlerEx)()}
          >
            <Row>
              <Col span={24} lg={10} xl={8} xxl={8}>
                <AgencySelectEx
                  required={true}
                  isLoading={defaultValues.AgencyKey === ""}
                  agencies={currentAgencies}
                  layout={firstLayout}
                  formLabel="Agency"
                  formStyle={{ marginBottom: 0 }}
                  control={control}
                  name="AgencyKey"
                  includeSelectAll={true}
                  onSelect={(value) => {
                    setValue("AllocatedToAgentKey", null);
                    dispatch(listAllocatedToAgentsAction(value));
                    trigger();
                  }}
                />
              </Col>

              <Col span={24} lg={5} xl={4} xxl={4}>
                <CustomRadioGroup
                  required={true}
                  formLabel="Type"
                  layout={secondLayout}
                  control={control}
                  formStyle={{ marginBottom: 0 }}
                  name="SearchAllInventory"
                >
                  <Space direction="vertical">
                    <Radio value={true}>All Inventory</Radio>
                    <Radio value={false}>On-hand Inventory</Radio>
                  </Space>
                </CustomRadioGroup>
                {/* </Col>
                </Row> */}
              </Col>
              <Col span={24} lg={8} xl={6} xxl={7}>
                <CustomInput
                  layout={thirdLayout}
                  formStyle={{ marginBottom: 0 }}
                  formLabel="Search"
                  control={control}
                  name="SearchValue"
                  placeholder="Enter search criteria"
                  autoFocus={true}
                />
              </Col>
              <Col xs={0} sm={0} md={0} lg={0} xl={6} xxl={4}>
                <SearchButtons
                  onClick={() => {
                    onResetHandler();
                    setClearAll(true);
                    reset();
                    var initialAgency = getInitialAgency(homeAgency, agencies);
                    dispatch(listAllocatedToAgentsAction(initialAgency));
                    trigger();
                  }}
                  isValid={isValid}
                  loading={loading}
                  isDirty={isDirty}
                  searchValue={searchValue}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} lg={10} xl={8} xxl={8}>
                <SearchSelectEx
                  control={control}
                  formLabel="Allocated To"
                  layout={firstLayout}
                  formStyle={{ marginBottom: 0 }}
                  name="AllocatedToAgentKey"
                  disabled={
                    isNull(allocatedToAgents) || isEmpty(allocatedToAgents)
                  }
                  placeholder={
                    isNull(allocatedToAgents) || isEmpty(allocatedToAgents)
                      ? "No Agent Found"
                      : "Select an agent"
                  }
                  loading={allocatedToAgentsLoading}
                  options={
                    maskSensitiveData === true
                      ? maskedAllocatedToAgents
                      : allocatedToAgents
                  }
                  filterOption={(input, option) => {
                    const label =
                      maskSensitiveData === true ? option.filter : option.label;
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                  afterChange={() => trigger()}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
                <Row justify="center" gutter={8} style={{ marginTop: 16 }}>
                  <Col>
                    <SearchButtons
                      onClick={() => {
                        onResetHandler();
                        setClearAll(true);
                        reset();
                        var initialAgency = getInitialAgency(
                          homeAgency,
                          agencies
                        );
                        dispatch(listAllocatedToAgentsAction(initialAgency));
                        trigger();
                      }}
                      isValid={isValid}
                      loading={loading}
                      isDirty={isDirty}
                      searchValue={searchValue}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const SearchButtons = (props) => {
  const { isValid, loading, onClick, isDirty, searchValue } = props;
  return (
    <Row justify="center" gutter={8}>
      <Col>
        <Button
          disabled={(!isDirty && !searchValue) || !isValid}
          loading={loading}
          autoFocus={true}
          id="processButton"
          type="primary"
          htmlType="submit"
        >
          <SearchOutlined />
          Search
        </Button>
      </Col>
      <Col>
        <Button onClick={onClick}>Clear Results</Button>
      </Col>
    </Row>
  );
};
export default SearchByForm;
