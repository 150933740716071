import * as yup from "yup";
import { maxMessage, requiredMessage } from "../shared/form/formValidatonRules";

export const announcementSchema = yup.object().shape({
  Subject: yup.string().required(requiredMessage).max(100, maxMessage),

  Description: yup.string().required(requiredMessage).max(500, maxMessage),
  Link: yup.string().nullable().max(250, maxMessage),
  LinkText: yup.string().nullable().max(250, maxMessage),
  AnnounceOnDate: yup.string().required(requiredMessage),
  ExpiryDate: yup.string().required(requiredMessage),
});
