import { Form, Input } from "antd";
import React from "react";

import { useController } from "react-hook-form";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";

const CustomInputPassword = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = React.memo((props) => {
  const { field, error } = props;

  var inputProps = { ...props };
  delete inputProps.control;
  delete inputProps.formLabel;
  delete inputProps.layout;
  delete inputProps.required;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  delete inputProps.error;
  delete inputProps.field;
  delete inputProps.linkedValue;
  delete inputProps.formTooltip;
  delete inputProps.formStyle;
  delete inputProps.hasFeedback;
  delete inputProps.onChange;
  delete inputProps.afterChange;

  return (
    <Form.Item
      {...props.layout}
      label={props.formLabel}
      name={props.name}
      validateStatus={error != null ? "error" : ""}
      help={error != null ? error.message : null}
      required={props.required}
      tooltip={props.formTooltip}
      style={props.formStyle}
      hasFeedback={props.hasFeedback}
    >
      <div>
        <Input.Password
          {...field}
          onChange={(e) => {
            if (props.onChange != null) {
              props.onChange(e);
            }
            if (e != null) {
              field.onChange(e);
            }
            if (props.afterChange != null) {
              props.afterChange(e);
            }
          }}
          {...inputProps}
        />
      </div>
    </Form.Item>
  );
}, checkHookFormFieldPropsEquality);
export default CustomInputPassword;
