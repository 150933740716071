import { Radio } from "antd";
export const sortGroupDrillDownFields = {
  AGENCY_NAME: "AgencyName",
  BATCH_NUMBER: "BatchNo",
  PRODUCER_1_NAME: "ProducerName1",
  PRODUCER_2_NAME: "ProducerName2",
  PRODUCER_1_CODE: "ProducerCode1",
  PRODUCER_2_CODE: "ProducerCode2",
};

export const sortGroupDrillDownFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Batch Number", value: sortGroupDrillDownFields.BATCH_NUMBER },
  { label: "Producer 1 Name", value: sortGroupDrillDownFields.PRODUCER_1_NAME },
  { label: "Producer 2 Name", value: sortGroupDrillDownFields.PRODUCER_2_NAME },
  { label: "Producer 1 Code", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2 Code", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
];

export const commissionReportTypes = {
  SUMMARY: "Summary",
  DETAIL: "Detail",
};

export const commissionReportTypeOptions = [
  <Radio value={commissionReportTypes.DETAIL} key="0">
    Detail
  </Radio>,
  <Radio value={commissionReportTypes.SUMMARY} key="1">
    Summary
  </Radio>,
];

export const fleetGarageFields = {
  AGENCY_NAME: "TAMBranchNumber",
  PRODUCER_CODE: "TAMProducerCode",
  PRODUCER_NAME: "TAMProducerName",
  PERIOD_YEAR_MONTH: "PeriodYearMonth",
  POLICY_TYPE: "PolicyType",
  FLEET_GARAGE_POLICY_NUMBER: "PolicyOrFleetNumber",
};

export const fleetGarageDrillDownOptions = [
  { label: "Agency", value: fleetGarageFields.AGENCY_NAME },
  { label: "Producer Code", value: fleetGarageFields.PRODUCER_CODE },
  { label: "Producer Name", value: fleetGarageFields.PRODUCER_NAME },
  {
    label: "Period ( Month + Year )",
    value: fleetGarageFields.PERIOD_YEAR_MONTH,
  },
  {
    label: "Policy Type (Fleet or Garage)",
    value: fleetGarageFields.POLICY_TYPE,
  },
  {
    label: "Fleet / Garage Policy number",
    value: fleetGarageFields.FLEET_GARAGE_POLICY_NUMBER,
  },
];

export const fleetGarageSortGroupOptions = [
  { label: "Agency", value: fleetGarageFields.AGENCY_NAME },
  {
    label: "Period ( Month + Year )",
    value: fleetGarageFields.PERIOD_YEAR_MONTH,
  },
  {
    label: "Policy Type (Fleet or Garage)",
    value: fleetGarageFields.POLICY_TYPE,
  },
  { label: "Producer Code", value: fleetGarageFields.PRODUCER_CODE },
  { label: "Producer Name", value: fleetGarageFields.PRODUCER_NAME },
];
