import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { Actions } from "./appLogConstants";

export const listAppLogs = (model) => async (dispatch) => {
  dispatch({ type: Actions.LIST_APP_LOGS });
  const payload = await customPost(
    entityType.APP_LOG,
    genericActionType.LIST,
    model
  );
  dispatch({ type: Actions.LIST_APP_LOGS_SUCCEEDED, payload });
};

export const clearOperationResult = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const readAppLog = (id) => async (dispatch) => {
  dispatch({ type: Actions.READ_APP_LOGS });
  const payload = await customGet(entityType.APP_LOG, genericActionType.READ, {
    params: {
      id: id,
    },
  });

  dispatch({
    type: Actions.READ_APP_LOGS_SUCCEEDED,
    payload,
  });
};

export const clearSelectedAppLog = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_APP_LOG });
};
