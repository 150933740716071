import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearSelectedAction,
  readAction,
  updateAction,
} from "../../../store/document/documentActions";
import DocumentForm from "./DocumentForm";
import { Spin } from "antd";

const UpdateDocumentForm = ({ handleParentRefresh }) => {
  //
  const { documentKey } = useParams();

  //
  const dispatch = useDispatch();

  //
  const [isLoading, setIsLoading] = useState(true);

  //
  useEffect(() => {
    dispatch(clearSelectedAction());
    dispatch(
      readAction(documentKey, () => {
        setIsLoading(false);
      })
    );
  }, [documentKey, dispatch]);

  //
  const onSubmitHandler = (values, ignoreWarning) =>
    dispatch(updateAction(values, ignoreWarning));

  const onCloseHandler = () => {
    handleParentRefresh && handleParentRefresh();
  };

  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <DocumentForm
          isCreateDocument={false}
          onSubmitHandler={onSubmitHandler}
          onCloseHandler={onCloseHandler}
        />
      )}
    </Spin>
  );
};

export default UpdateDocumentForm;
