import * as yup from "yup";
import {
  dateRangeRequiredMessage,
  fleetGarageCommaListMessage,
  fleetGarageCommaListRegexOrEmptyString,
  requiredMessage,
} from "../shared/form/formValidatonRules";

export const dealerBillingReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  TransactionDate: yup.array().required(dateRangeRequiredMessage).nullable(),
  SortGroupFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  FleetGaragePolicyNumbers: yup
    .string()
    .matches(
      fleetGarageCommaListRegexOrEmptyString,
      fleetGarageCommaListMessage
    ),
});
