import { useSelector } from "react-redux";
import { columnTypes } from "../../shared/table/columnTypes";

export const onlineRenewalsColumns = () => {
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  return [
    {
      title: "Transaction Date",
      dataIndex: "EntryDateTime",
      type: columnTypes.DATETIME,
      fixed: "left",
      width: 170,
    },
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      width: 85,
    },
    {
      title: "Plate",
      dataIndex: "PlateNumber",
      type: columnTypes.TEXT,
      width: 72,
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: 200,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
    },
    {
      title: "Review Status",
      dataIndex: "ICBCReviewStatusDescription",
      type: columnTypes.TEXT,
      width: 200,
    },
    {
      title: "Review Closed By",
      dataIndex: "ICBCReviewCompletedBy",
      type: columnTypes.TEXT,
      width: 200,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
    },
    {
      title: "PC1",
      dataIndex: "ProducerCode1",
      type: columnTypes.TEXT,
      width: 65,
    },
    {
      title: "Producer Name 1",
      dataIndex: "ProducerName1",
      type: columnTypes.TEXT,
      width: 175,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
    },
    {
      title: "PC2",
      dataIndex: "ProducerCode2",
      type: columnTypes.TEXT,
      width: 65,
    },
    {
      title: "Producer Name 2",
      dataIndex: "ProducerName2",
      type: columnTypes.TEXT,
      width: 175,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          text
        ),
    },
    {
      title: "Effective Date",
      dataIndex: "PolicyEffectiveDate",
      type: columnTypes.DATE,
      width: 95,
    },
    {
      title: "Agency",
      dataIndex: "AgencyNumberAndName",
      type: columnTypes.TEXT,
      width: 270,
    },
  ];
};
