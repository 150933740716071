import { WarningTwoTone } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const ReportParamPanelHeader = ({ paramsDirty }) => {
  const pageHeaderWarning = (
    <Space>
      <Text>Report Parameters</Text>
      <Text type="warning">
        <WarningTwoTone twoToneColor="#faad14"></WarningTwoTone> Report shown
        does not match selected parameters. Click{" "}
        <Text type="warning" strong>
          "Preview"
        </Text>{" "}
        button to refresh.
      </Text>
    </Space>
  );
  const pageHeader = <Text>Report Parameters</Text>;

  return <>{paramsDirty ? pageHeaderWarning : pageHeader}</>;
};
export default ReportParamPanelHeader;
