import { OidcProvider } from "@axa-fr/react-oidc";
import { useDispatch } from "react-redux";
import { logCustomMessage } from "../../../store/ui/uiActions";
import { AuthenticatingComponent } from "./AuthenticatingComponent";
import { CallbackSuccessComponent } from "./CallbackSuccessComponent";
import { LoadingComponent } from "./LoadingComponent";
import { ServiceWorkerNotSupportedComponent } from "./ServiceWorkerNotSupportedComponent";
import { SessionLostComponent } from "./SessionLostComponent";
import { Stored, identityServerConfiguration } from "./customOidcUtils";

// This custom version of OidcProvider refreshes logged out pages when a login event occurs
export const CustomOidcProvider = (props) => {
  console.log("CustomOidcProvider component rendered");
  const dispatch = useDispatch();
  if (!localStorage["oidc.login.default"]) {
    console.log("Oidc profile not found in local storage, creating new entry");
    localStorage["oidc.login.default"] = JSON.stringify({
      callbackPath: `${process.env.REACT_APP_URI}/`,
      extras: null,
    });
  }

  return (
    <OidcProvider
      serviceWorkerNotSupportedComponent={ServiceWorkerNotSupportedComponent}
      loadingComponent={LoadingComponent}
      callbackSuccessComponent={CallbackSuccessComponent}
      authenticatingComponent={AuthenticatingComponent}
      authenticatingErrorComponent={() => (
        <SessionLostComponent isAuthenticationError={true} />
      )}
      sessionLostComponent={SessionLostComponent}
      configuration={identityServerConfiguration}
      onEvent={(_, name, data) => {
        if (
          name === "loginCallbackAsync_error" ||
          name === "loginAsync_error"
        ) {
          dispatch(
            logCustomMessage(
              "LogLoginError",
              null,
              "Login error occurred",
              (name + " - " + data.toString()).slice(0, 160)
            )
          );
        }
        if (name === "loginCallbackAsync_end") {
          const currentTimestamp = Math.floor(Date.now() / 1000);
          localStorage.setItem(
            Stored.LAST_LOGIN_TIMESTAMP,
            currentTimestamp.toString()
          );
          console.log(
            "User logged in successfully. Timestamp:",
            currentTimestamp
          );
        }
      }}
    >
      {props.children}
    </OidcProvider>
  );
};
