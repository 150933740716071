import { Col, Row } from "antd";
import { isEmpty, isNull } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import CustomInputCurrency from "../../../shared/form/reactHookForm/CustomInputCurrency";
import CustomRadioGroup from "../../../shared/form/reactHookForm/CustomRadioGroup";

const specialLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 10 },
};

const VehiclePaymentPanel = ({ control }) => {
  const selected = useSelector((state) => state.document.selected);
  const isDownloaded = selected && selected.IsDownloaded;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;
  const isBatched =
    selected &&
    !(isEmpty(selected.AgencyBatchNo) || isNull(selected.AgencyBatchNo));

  return (
    <>
      <Row>
        <Col span={10}>
          <CustomInputCurrency
            layout={specialLayout}
            formLabel="Total Fees"
            control={control}
            name="TotalFees"
            disabled={isDownloaded || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="ICBC Debt Amount"
            control={control}
            isPrecision={true}
            name="ICBCDebtAmt"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="MVB Debt Amount"
            control={control}
            isPrecision={true}
            name="MVBDebtAmt"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomRadioGroup
            control={control}
            layout={specialLayout}
            formLabel="MVB Eligible"
            name="MVBIndicator"
            disabled={isDownloaded || !isUserAllowedToSave}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          ></CustomRadioGroup>

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="HST(BC) Due"
            control={control}
            isPrecision={true}
            name="HSTBCAmt"
            disabled={isDownloaded || isBatched || !isUserAllowedToSave}
          />

          <CustomRadioGroup
            control={control}
            layout={specialLayout}
            formLabel="Bill to Dealer"
            name="BillToDealerIndicator"
            disabled={!isUserAllowedToSave}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          ></CustomRadioGroup>
        </Col>

        <Col span={10}>
          <CustomInputCurrency
            layout={specialLayout}
            formLabel="Amount Due"
            control={control}
            isPrecision={true}
            name="AmountDue"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="Amount Financed"
            control={control}
            isPrecision={true}
            name="FinancedAmt"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="Cash / Cheque"
            control={control}
            isPrecision={true}
            name="CashChequePayment"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="VISA"
            control={control}
            isPrecision={true}
            name="VisaPayment"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="Mastercard"
            control={control}
            isPrecision={true}
            name="MCPayment"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="AMEX"
            control={control}
            isPrecision={true}
            name="AmexPayment"
            disabled={isBatched || !isUserAllowedToSave}
          />

          <CustomInputCurrency
            layout={specialLayout}
            formLabel="Debit"
            control={control}
            isPrecision={true}
            name="DebitPayment"
            disabled={isBatched || !isUserAllowedToSave}
          />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(VehiclePaymentPanel);
