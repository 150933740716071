export const Actions = {
  LIST_MOVE_DOCUMENTS: "LIST_MOVE_DOCUMENTS",
  LIST_MOVE_DOCUMENTS_SUCCEEDED: "LIST_MOVE_DOCUMENTS_SUCCEEDED",
  CLEAR_MOVE_DOCUMENTS: "CLEAR_MOVE_DOCUMENTS",
  SET_SELECTED_ROWS: "SET_SELECTED_ROWS",
  CLEAR_SELECTED_ROWS: "CLEAR_SELECTED_ROWS",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  MOVE_DOCUMENTS_TO_AGENCY: "MOVE_DOCUMENTS_TO_AGENCY",
  MOVE_DOCUMENTS_TO_AGENCY_SUCCEEDED: "MOVE_DOCUMENTS_TO_AGENCY_SUCCEEDED",
};
