import {
  MinusOutlined,
  TeamOutlined,
  FileExcelOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Popconfirm,
  Popover,
  Row,
  Space,
  Typography,
  Spin,
} from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import {
  clearLegacyOperationResultAction as clearOperationResultAction,
  listLegacyUsersAction,
  bulkDeleteUsers,
  clearBulkDeleteOperationResults,
  activateLegacyUser,
} from "../../store/user/general/userActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import { PreservableStatePageWrapper } from "../shared/PreservableStatePageWrapper";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { AdminTasks } from "../shared/securityRoleTasks";
import { agenciesListEqual, compareArrayOfStrings } from "../shared/sorting";
import { SearchableTable } from "../shared/table/SearchableTable";
import { columnTypes } from "../shared/table/columnTypes";
import { tableNames } from "../shared/table/tableNames";
import { securityRolesFilterFunction } from "./securityRolesFilterFunction";
import { legacyUsersSchema } from "./userSchema";
import { exportDataToExcel } from "../shared/exportToExcel";

const { Link, Text, Paragraph } = Typography;

const layout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const LegacyUsers = () => {
  return (
    <PreservableStatePageWrapper
      onCleanup={() => {}}
      tableNames={[tableNames.LegacyUsers]}
    >
      <LegacyUsersBody />
    </PreservableStatePageWrapper>
  );
};

const LegacyUsersBody = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [migrableRows, setMigrableRows] = useState([]);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const list = useSelector((state) => state.user.general.legacy.list);
  const loading = useSelector((state) => state.user.general.legacy.loading);
  const operationResult = useSelector(
    (state) => state.user.general.legacy.operationResult
  );

  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const isBulkDeleting = useSelector(
    (state) => state.user.general.isBulkDeleting
  );
  const bulkDeleteOperationResult = useSelector(
    (state) => state.user.general.bulkDeleteOperationResult
  );
  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.LegacyUsers)
  );
  const tableCurrentDataSource = tableData?.extra?.currentDataSource;
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const dispatch = useDispatch();
  const {
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(legacyUsersSchema),
  });

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, AdminTasks));
    }
  }, [brokerId]);

  useEffect(() => {
    return () => {
      // Clear the list of all agencies
      dispatch(clearListCompactAction());
      dispatch(clearBulkDeleteOperationResults());
    };
  }, []);

  const [currentAgencies, setCurrentAgencies] = useState([]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);

      var initialAgency = getInitialAgency(homeAgency, agencies);
      const initialValues = {
        AgencyKeys: [initialAgency],
      };

      initialAgency && dispatch(listLegacyUsersAction(initialValues));

      reset(initialValues);

      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    if (operationResult !== null && operationResult.Outcome === "Success") {
      dispatch(listLegacyUsersAction(getValues()));
    }
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult]);

  useEffect(() => {
    if (bulkDeleteOperationResult != null) {
      openNotificationOperationResult(bulkDeleteOperationResult, () =>
        dispatch(clearBulkDeleteOperationResults())
      );
      dispatch(clearBulkDeleteOperationResults());
      dispatch(listLegacyUsersAction(getValues()));
    }
  }, [bulkDeleteOperationResult]);

  useEffect(() => {
    if (list != null) {
      if (!isEmpty(selectedRows)) {
        const currentDataSourceRowKeys = list.map((r) => r.UserKey);
        var newSelectedRows = selectedRows.filter((r) =>
          currentDataSourceRowKeys.includes(r)
        );
        setSelectedRows(newSelectedRows);
        var newMigrableRows = migrableRows.filter((r) =>
          currentDataSourceRowKeys.includes(r)
        );
        setMigrableRows(newMigrableRows);
      }
    } else {
      if (!isEmpty(selectedRows)) {
        setSelectedRows([]);
        setMigrableRows([]);
      }
    }
  }, [list]);

  useEffect(() => {
    //if a filter is applied when multiple rows selected, set selectedRows only for the records visible on ui
    if (
      !isNull(tableCurrentDataSource) &&
      !isEmpty(tableCurrentDataSource) &&
      list?.length > tableCurrentDataSource?.length &&
      !isEmpty(selectedRows)
    ) {
      const currentDataSourceRowKeys = tableCurrentDataSource.map(
        (r) => r.UserKey
      );
      var currentDataSourceSelectedRowKeys = selectedRows.filter((r) =>
        currentDataSourceRowKeys.includes(r)
      );
      setSelectedRows(currentDataSourceSelectedRowKeys);
      var newMigrableRows = migrableRows.filter((r) =>
        currentDataSourceRowKeys.includes(r)
      );
      setMigrableRows(newMigrableRows);
    }
  }, [tableCurrentDataSource, list]);

  const [isExporting, setIsExporting] = useState(false);

  const exportTableData = () => {
    setIsExporting(true);
    exportDataToExcel(
      tableData?.extra.currentDataSource,
      ["UserKey", "IsAdmin", "AgencyKey", "Roles"],
      maskSensitiveData,
      "LegacyUsers.csv",
      []
    );
    setIsExporting(false);
  };

  const usersButton = (
    <Button
      size={"small"}
      type="dashed"
      onClick={() => {
        navigate(`/users`);
      }}
    >
      <TeamOutlined />
      Go To Users
    </Button>
  );

  const exportButton = (
    <Button
      size={"small"}
      loading={isExporting}
      onClick={() => exportTableData()}
      disabled={isEmpty(list)}
    >
      <FileExcelOutlined />
      Export
    </Button>
  );

  const onSelectedRowsChange = (keys, rows) => {
    setSelectedRows(keys);
  };

  const deleteButton = (
    <Popconfirm
      title="Delete account"
      description={
        <>
          <Paragraph>
            You are about to delete {selectedRows.length} user accounts.
            <br />
            This action cannot be undone.
            <br />
            Would you like to proceed?
          </Paragraph>
        </>
      }
      onCancel={() => {
        dispatch(
          bulkDeleteUsers({
            UserKeys: selectedRows,
            IsFromLegacyPage: true,
          })
        );
      }}
      cancelText="Yes, Delete"
      okText="No, Cancel Delete"
    >
      <Button
        type="default"
        danger={true}
        size={"small"}
        disabled={isEmpty(selectedRows) || isBulkDeleting}
        loading={isBulkDeleting}
        onClick={() => {}}
      >
        <DeleteOutlined />
        Bulk Delete{" "}
        {!isEmpty(selectedRows) ? `(${selectedRows.length.toString()})` : ""}
      </Button>
    </Popconfirm>
  );

  const columns = [
    {
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <Button
          type="link"
          size="small"
          style={{ padding: 0 }}
          onClick={() => {
            dispatch(activateLegacyUser(record.UserKey));
          }}
        >
          Activate
        </Button>
      ),
      fixed: "left",
      width: 60,
    },

    {
      title: "User",
      dataIndex: "UserId",
      type: columnTypes.TEXT,
      width: 120,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "EmailAddress",
      type: columnTypes.TEXT,
      width: 200,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "First Name",
      dataIndex: "NameFirst",
      type: columnTypes.TEXT,
      width: 120,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },

    {
      title: "Last Name",
      width: 130,
      dataIndex: "NameLast",
      type: columnTypes.TEXT,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },

    {
      title: "Home Agency",
      dataIndex: "AgencyFullName",
      type: columnTypes.TEXT,
      width: 270,
    },
    {
      title: "Security Roles",
      dataIndex: "Roles",
      key: "Roles",
      type: columnTypes.TEXT_CUSTOM_SORTER,
      sorter: (a, b) => compareArrayOfStrings(a.Roles, b.Roles),
      onFilter: securityRolesFilterFunction,
      render: (_, record) => {
        return record.Roles.length > 1 ? (
          <Popover
            content={
              <>
                {record.Roles.map((a) => (
                  <p>{a}</p>
                ))}
                <Alert
                  message={
                    <>
                      <Text strong>Security Roles</Text> can be updated once the
                      user is activated.
                    </>
                  }
                  showIcon
                ></Alert>
              </>
            }
          >
            <Link>{`${record.Roles.length} Roles`}</Link>
          </Popover>
        ) : record.Roles.length === 1 ? (
          record.Roles[0]
        ) : (
          <MinusOutlined />
        );
      },
      width: 100,
    },
  ];

  const infoContent = (
    <Space direction="vertical" size={4}>
      <Text strong>
        This is the list of all inactive users from the legacy Autolink. Please
        review the list and perform one of the following actions:
      </Text>
      <Text>
        - If a user record is no longer needed, please delete the record using
        the 'Bulk Delete' button.
      </Text>
      <Text>
        - If a user record is still required, please 'Activate' it to move it to
        the current list of users under 'Administration/Users'.
      </Text>
    </Space>
  );

  return (
    <PermissionWrapper entityName="Admin">
      <Spin delay={500} spinning={isBulkDeleting}>
        <Row>
          <Col span={24}>
            <Form size="small" autoComplete="off">
              <Row>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                  <AgencyMultiSelectEx
                    layout={layout}
                    formLabel="Agency"
                    agencies={currentAgencies}
                    isLoading={isEmpty(agencies)}
                    setValue={setValue}
                    control={control}
                    name="AgencyKeys"
                    required={true}
                    onChange={(newAgencyKeys) =>
                      dispatch(
                        listLegacyUsersAction({
                          ...getValues(),
                          AgencyKeys: newAgencyKeys,
                        })
                      )
                    }
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Divider style={{ marginTop: 0 }} />
        <Row style={{ paddingBottom: 4 }}>
          <Col span={24}>
            <Alert message={infoContent} type="info" closable={true} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SearchableTable
              size="small"
              tableName={tableNames.LegacyUsers}
              dataSource={list}
              columns={columns}
              loading={loading}
              rowKey={(r) => r.UserKey}
              buttons={[usersButton, deleteButton, exportButton]}
              scroll={{ y: 650, x: 1480 }}
              rowSelection={{
                selectedRowKeys: selectedRows,
                columnWidth: 30,
                onChange: onSelectedRowsChange,
                fixed: true,
                hideSelectAll: false,
              }}
            />
          </Col>
        </Row>
      </Spin>
    </PermissionWrapper>
  );
};
export default LegacyUsers;
