import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { moveInventoryItemsColumns } from "./moveInventoryItemsColumns";
import { addItemsInventoryMovementAction } from "../../../store/inventory/inventoryActions";
import { isEmpty, isNull } from "lodash";
import { tableNames } from "../../shared/table/tableNames";

const MoveInventoryItems = ({ showMoveAllButton, hideRemoveItemColumn }) => {
  const [clearAll, setClearAll] = useState(false);
  const dispatch = useDispatch();

  const newMovement = useSelector(
    (state) => state.inventory.moveInventory.newMovement
  );
  const list = newMovement.entity && newMovement.entity.Items;
  const loading = newMovement.loading;

  const header = newMovement.entity && newMovement.entity.Header;
  const columns = moveInventoryItemsColumns(
    header && header.InventoryMovementKey,
    hideRemoveItemColumn
  );

  const availableItemRanges = newMovement.availableItemRanges;

  const moveAllIntentoryItems = () => {
    dispatch(
      addItemsInventoryMovementAction({
        AgencyKey: header.AgencyKey,
        InventoryMovementKey: header.InventoryMovementKey,
        InventoryMovementType: header.InventoryMovementType,
        SelectedItems: availableItemRanges,
      })
    );
  };

  const moveAllButton = (
    <Button
      size="small"
      disabled={
        availableItemRanges == null ||
        isNull(availableItemRanges) ||
        isEmpty(availableItemRanges)
      }
      onClick={() => moveAllIntentoryItems()}
      loading={loading}
    >
      Add All Items
    </Button>
  );

  return (
    <div>
      <Row>
        <Col>
          <SearchableTable
            bordered
            setClearAll={setClearAll}
            clearAll={clearAll}
            scroll={{ y: 500, x: 560 }}
            sticky
            dataSource={list}
            columns={columns}
            loading={loading}
            size="small"
            rowKey={(r) => r.InventoryMovementDetailKey}
            buttons={showMoveAllButton ? [moveAllButton] : []}
            tableName={tableNames.MoveInventoryItems}
          />
        </Col>
      </Row>
    </div>
  );
};
export default MoveInventoryItems;
