import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { Actions } from "./fleetPolicyCustomMappingConstants";

export const clearOperationResult = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const clearSelected = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_MAPPING });
};

export const listMappings = (brokerId) => async (dispatch) => {
  dispatch({ type: Actions.LIST_MAPPING });

  const payload = await customGet(
    entityType.FLEET_POLICY_CUSTOM_MAPPING,
    "List",
    {
      params: { brokerId: brokerId },
    }
  );

  dispatch({
    type: Actions.LIST_MAPPING_SUCCESS,
    payload,
  });
};

export const setSelected = (mapping) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_MAPPING,
    payload: mapping,
  });
};

export const updateMapping = (values) => async (dispatch) => {
  dispatch({ type: Actions.UPDATE_MAPPING });
  const payload = await customPost(
    entityType.FLEET_POLICY_CUSTOM_MAPPING,
    genericActionType.UPDATE,
    values
  );
  dispatch({ type: Actions.UPDATE_MAPPING_SUCCESS, payload });
};

export const createMapping = (values) => async (dispatch) => {
  dispatch({ type: Actions.CREATE_MAPPING });
  const payload = await customPost(
    entityType.FLEET_POLICY_CUSTOM_MAPPING,
    genericActionType.CREATE,
    values
  );
  dispatch({ type: Actions.CREATE_MAPPING_SUCCESS, payload });
};

export const deleteMapping = (brokerId, mappingId) => async (dispatch) => {
  dispatch({ type: Actions.DELETE_MAPPING });
  const payload = await customGet(
    entityType.FLEET_POLICY_CUSTOM_MAPPING,
    genericActionType.DELETE,
    {
      params: { brokerId: brokerId, mappingId: mappingId },
    }
  );
  dispatch({
    type: Actions.DELETE_MAPPING_SUCCESS,
    payload,
  });
};
