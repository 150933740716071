import {
  findCurrentAgencies,
  findCurrentBatchLabel,
  findProducerNames,
} from "../report/reportActions";

export const transformProductionInput = (values, policyProducts) => (state) => {
  var PolicyProductsDescriptionString = "";
  if (policyProducts != null) {
    for (var i = 0; i < values.ProductKeys.length; i++) {
      PolicyProductsDescriptionString +=
        policyProducts.find((v) => v.value === values.ProductKeys[i]).label +
        (i < values.ProductKeys.length - 1 ? "," : "");
    }
  }

  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const batchFromLabel = findCurrentBatchLabel(state, values.BatchNumberFrom);
  const batchToLabel = findCurrentBatchLabel(state, values.BatchNumberTo);
  const producer1Names = findProducerNames(state, 1, values.ProducerCode1Keys);
  const producer2Names = findProducerNames(state, 2, values.ProducerCode2Keys);
  const transactionDates = [
    values.TransactionDate[0],
    values.TransactionDate[1],
  ];
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FirstProducer: producer1Names,
    SecondProducer: producer2Names,
    BatchFromLabel: batchFromLabel,
    BatchToLabel: batchToLabel,
    TransactionDate: transactionDates,
    PolicyProductsDescriptionString: PolicyProductsDescriptionString,
    FileName: `Production${values.ReportType}_${agency.Number}`,
  };
};
