import produce from "immer";
import { Actions } from "./batchingConstants";

const initialBatch = {
  data: null,
  loading: false,
  closingLoading: false,
  closingResult: null,
  criteria: null,
  nextBatchNumber: null,
  multiLicencePolicies: { loading: false, data: null, selectedRows: [] },
  ui: {
    documentsInclusionState: {},
    inclusionStateSnapshot: {},
    selectedDocumentKeys: [],
    processBatchCurrentStep: 0,
    processBatchReverseStep: false,
    documentsItemNumbers: {},
    firstElementKey: "",
    allKeysList: [],
  },
};

const initialReporting = { batches: { data: [], loading: false } };

const initialState = {
  data: null,
  newBatch: initialBatch,
  newBatchBackup: initialBatch,
  selected: null,
  searchCriteria: null,
  operationResult: null,
  inlineEditOperationResult: null,
  loading: false,
  selectedLoading: false,
  isUndone: false,
  availableDocumentsToAdd: { data: [], loading: false },
  reporting: initialReporting,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_DOCUMENTS_AVAILABLE_TO_ADD:
        draft.availableDocumentsToAdd.loading = true;
        return draft;
      case Actions.LIST_DOCUMENTS_AVAILABLE_TO_ADD_SUCCEEDED:
        draft.availableDocumentsToAdd.loading = false;
        draft.availableDocumentsToAdd.data = action.payload;
        return draft;
      case Actions.UNDO_BATCH:
        draft.loading = true;
        return draft;
      case Actions.CLEAR_IS_UNDONE: {
        draft.isUndone = false;
        return draft;
      }
      case Actions.UNDO_BATCH_SUCCEEDED:
        draft.loading = false;
        draft.operationResult = action.payload.Operation;
        draft.data = action.payload.Data;
        draft.isUndone =
          action.payload.Operation.Outcome !== "Success" ? false : true;
        return draft;
      case Actions.ADD_DOCUMENTS_TO_BATCH:
        draft.selectedLoading = true;
        return draft;
      case Actions.ADD_DOCUMENTS_TO_BATCH_SUCCEEDED:
        draft.selectedLoading = false;
        draft.operationResult = action.payload.Operation;
        draft.selected = action.payload.Data;
        return draft;
      case Actions.UPDATE_INLINE_EDIT_CHANGES:
        draft.newBatch.loading = true;
        return draft;
      case Actions.UPDATE_INLINE_EDIT_CHANGES_SUCCEEDED:
        draft.inlineEditOperationResult = action.payload.Operation;
        // If it failed, we stop the loading animation
        // Otherwise, it will stop on its own after we're done refreshing the table data
        // if (action.payload.Operation.Outcome !== "Success") {
        //   draft.newBatch.loading = false;
        // }
        // Tentative fix: Refresh data no matter the operation result
        return draft;
      case Actions.BACKUP_BATCHING_DATA:
        draft.newBatchBackup = draft.newBatch;
        return draft;
      case Actions.RESTORE_BATCHING_DATA:
        draft.newBatch = draft.newBatchBackup;
        draft.newBatchBackup = initialBatch;
        return draft;
      case Actions.UPDATE_BATCH_HEADER_SUCCEEDED:
        draft.selectedLoading = false;
        draft.operationResult = action.payload.Operation;
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        }
        return draft;
      case Actions.LIST_BATCHES:
        draft.loading = true;
        return draft;
      case Actions.LIST_MULTILICENCE_POLICIES:
        draft.newBatch.multiLicencePolicies.loading = true;
        return draft;
      case Actions.LIST_MULTILICENCE_POLICIES_SUCCEEDED:
        draft.newBatch.multiLicencePolicies.loading = false;
        draft.newBatch.multiLicencePolicies.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.GET_NEXT_BATCH_NUMBER_SUCCEEDED:
        draft.newBatch.nextBatchNumber = action.payload.Data;
        return draft;
      case Actions.READ_BATCH:
      case Actions.REMOVE_DOCUMENT_FROM_BATCH:
      case Actions.UPDATE_BATCH_HEADER:
        draft.selectedLoading = true;
        return draft;
      case Actions.GET_NEW_BATCH:
        draft.newBatch.loading = true;
        return draft;
      case Actions.CLEAR_NEW_BATCH_DATA:
        draft.newBatch.data = null;
        draft.newBatch.ui.firstElementKey = "";
        return draft;
      case Actions.GET_NEW_BATCH_SUCCEEDED:
        draft.newBatch.loading = false;
        draft.newBatch.data = action.payload.Data;

        let newSelectedKeys = [];
        let newMap = {};
        let itemNumberMap = {};
        let allKeys = [];
        action.payload.Data.Documents.forEach((d) => {
          newMap[d.DocumentKey] = !d.IsExcluded;
          itemNumberMap[d.DocumentKey] = d.ItemNumber;
          allKeys.push(d.DocumentKey);
          if (!d.IsExcluded) {
            newSelectedKeys.push(d.DocumentKey);
          }
        });
        draft.newBatch.ui.documentsItemNumbers = itemNumberMap;
        draft.newBatch.ui.documentsInclusionState = newMap;
        draft.newBatch.ui.inclusionStateSnapshot = newMap;
        draft.newBatch.ui.selectedDocumentKeys = newSelectedKeys;
        if (action.payload.UpdateAllKeysList === true) {
          draft.newBatch.ui.allKeysList = allKeys;
        }
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.UPDATE_INCLUSION_EXCLUSION_SUCCEEDED:
        if (action.payload.Operation.Outcome !== "Success") {
          draft.operationResult = action.payload.Operation;
        } else {
          let copy = { ...draft.newBatch.ui.inclusionStateSnapshot };
          if (action.payload.Included != null) {
            for (var i = 0; i < action.payload.Included.length; i++) {
              copy[action.payload.Included[i]] = true;
            }
          }
          if (action.payload.Excluded != null) {
            for (var i = 0; i < action.payload.Excluded.length; i++) {
              copy[action.payload.Excluded[i]] = false;
            }
          }

          draft.newBatch.ui.inclusionStateSnapshot = copy;
          draft.operationResult = null;
        }

        return draft;
      case Actions.LIST_BATCHES_SUCCEEDED:
        draft.loading = false;
        draft.data = action.payload;
        return draft;
      case Actions.LIST_BATCHES_FAILED:
        draft.loading = false;
        draft.data = null;
        draft.operationResult = {
          Outcome: "Error",
          Message:
            "Something went wrong while executing this request. If this problem persists, please attempt logging out and then logging back in.",
        };
        return draft;
      case Actions.READ_BATCH_SUCCEEDED:
      case Actions.REMOVE_DOCUMENT_FROM_BATCH_SUCCEEDED:
        draft.selected = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.selectedLoading = false;
        return draft;
      case Actions.SET_SELECTED:
        draft.selected = action.payload.Data;
        return draft;
      case Actions.CLEAR_BATCH_LIST:
        draft.data = null;
        draft.searchCriteria = null;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.CLEAR_INLINE_EDIT_OPERATION_RESULT:
        draft.inlineEditOperationResult = null;
        return draft;
      case Actions.SET_INLINE_EDIT_OPERATION_RESULT:
        draft.inlineEditOperationResult = action.payload.data;
        return draft;
      case Actions.CLEAR_CLOSE_BATCH_OPERATION_RESULT:
        draft.newBatch.closingResult = null;
        return draft;
      case Actions.CLEAR_SELECTED:
        draft.operationResult = null;
        draft.selected = null;
        return draft;
      case Actions.SET_SEARCH_CRITERIA:
        draft.searchCriteria = action.payload.searchCriteria;
        return draft;
      case Actions.SET_SELECTED_MULTILICENCE_POLICIES:
        draft.newBatch.multiLicencePolicies.selectedRows =
          action.payload.selectedRows;
        return draft;
      case Actions.SET_NEW_BATCH_CRITERIA:
        draft.newBatch.criteria = action.payload.newBatchCriteria;
        return draft;
      case Actions.SET_DOCUMENTS_INCLUSION_STATE:
        draft.newBatch.ui.documentsInclusionState = action.payload.keys;
        return draft;
      case Actions.SET_FIRST_ELEMENT_KEY:
        draft.newBatch.ui.firstElementKey = action.payload.key;
        return draft;
      case Actions.CLEAR_DOCUMENTS_INCLUSION_STATE:
        draft.newBatch.ui.documentsInclusionState = {};
        return draft;
      case Actions.SET_DOCUMENTS_ITEM_NUMBERS:
        draft.newBatch.ui.documentsItemNumbers = action.payload.keys;
        return draft;
      case Actions.SET_SELECTED_BATCH_DOCUMENT_KEYS:
        draft.newBatch.ui.selectedDocumentKeys = action.payload.keys;
        return draft;
      case Actions.CLEAR_SELECTED_BATCH_DOCUMENT_KEYS:
        draft.newBatch.ui.selectedDocumentKeys = [];
        return draft;
      case Actions.CLOSE_BATCH:
        draft.newBatch.closingLoading = true;
        return draft;
      case Actions.SET_PROCESS_BATCH_CURRENT_STEP:
        draft.newBatch.ui.processBatchCurrentStep = action.payload.value;
        draft.newBatch.ui.processBatchReverseStep = true;
        return draft;
      case Actions.CLOSE_BATCH_SUCCEEDED:
        draft.newBatch.closingLoading = false;
        draft.newBatch.closingResult = action.payload.Operation;
        if (action.payload.Operation.Outcome === "Success") {
          draft.newBatch.data = action.payload.Data;
        }
        return draft;
      case Actions.CLEAR_NEW_BATCH:
        draft.newBatch = initialBatch;
        return draft;
      case Actions.CLEAR_MULTILICENCE_POLICIES:
        draft.newBatch.multiLicencePolicies = {
          loading: false,
          data: null,
          selectedRows: [],
        };
        return draft;
      case Actions.RESET_REPORTING_DATA:
        draft.reporting = initialReporting;
        return draft;
      case Actions.LIST_BATCHES_FOR_REPORTING:
        draft.reporting.batches.loading = true;
        return draft;
      case Actions.LIST_BATCHES_FOR_REPORTING_SUCCEEDED:
        draft.reporting.batches.loading = false;
        if (action.payload.Operation.Outcome === "Success") {
          draft.reporting.batches.data = action.payload.Data;
        }
        return draft;
      default:
        return state;
    }
  });
};
