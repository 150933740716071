import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { Actions } from "./uiContants";

export const setTableRowBookmark = (tableName, rowKey) => async (dispatch) => {
  dispatch({
    type: Actions.SET_TABLE_ROW_BOOKMARK,
    payload: { RowKey: rowKey, TableName: tableName },
  });
};

export const setTablePreserveOnReload =
  (tableName, preserve) => async (dispatch) => {
    dispatch({
      type: Actions.SET_TABLE_PRESERVE_ON_RELOAD,
      payload: { Preserve: preserve, TableName: tableName },
    });
  };

export const clearTableRowBookmark = (tableName) => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_TABLE_ROW_BOOKMARK,
    payload: { TableName: tableName },
  });
};

export const setTableRestorableData = (tableName, data) => async (dispatch) => {
  dispatch({
    type: Actions.SET_TABLE_RESTORABLE_DATA,
    payload: { data: data, TableName: tableName },
  });
};

export const clearTableRestorableData = (tableName) => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_TABLE_RESTORABLE_DATA,
    payload: { TableName: tableName },
  });
};

export const addPageToPreserve = (page) => async (dispatch) => {
  dispatch({
    type: Actions.ADD_PAGE_TO_PRESERVE,
    payload: { page: page },
  });
};

export const addTableToPreserve = (table) => async (dispatch) => {
  dispatch({
    type: Actions.ADD_TABLE_TO_PRESERVE,
    payload: { table: table },
  });
};

export const removePageToPreserve = (page) => async (dispatch) => {
  dispatch({
    type: Actions.REMOVE_PAGE_TO_PRESERVE,
    payload: { page: page },
  });
};

export const removeTableToPreserve = (table) => async (dispatch) => {
  dispatch({
    type: Actions.REMOVE_TABLE_TO_PRESERVE,
    payload: { table: table },
  });
};

export const clearAllPreservables = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_ALL_PRESERVABLES,
  });
};

export const setUploadedFile =
  (uploadName, fileName, isUploaded) => async (dispatch) => {
    dispatch({
      type: Actions.SET_UPLOADED_FILE,
      payload: {
        uploadName: uploadName,
        fileName: fileName,
        isUploaded: isUploaded,
      },
    });
  };

export const clearUploadedFile = (uploadName) => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_UPLOADED_FILE,
    payload: { uploadName: uploadName },
  });
};

export const uploadFile =
  (uploadName, data, entity, action, onSuccess, onError) =>
  async (dispatch) => {
    try {
      const request = await customPost(entity, action, data, {
        file: true,
        shouldThrow: true,
      });

      if (!request.ok) {
        throw new Error();
      }
      onSuccess("");
      const fileName = data.name;

      return dispatch({
        type: Actions.SET_UPLOADED_FILE,
        payload: {
          uploadName: uploadName,
          fileName: fileName,
          isUploaded: true,
        },
      });
    } catch (e) {
      console.log(e);
      onError();
      return dispatch({
        type: Actions.CLEAR_UPLOADED_FILE,
        payload: { uploadName: uploadName },
      });
    }
  };

export const logError = async (errorModel) => {
  customPost(entityType.UI, "LogError", errorModel);
};

export const logCustomMessage =
  (action, id4UserId, message, additionalInfo) =>
  async (dispatch, getState) => {
    dispatch({ type: "ui/LogCustomMessage" });
    const userKey = getState().actor?.details?.data?.UserId ?? -1;
    const guid =
      id4UserId == null || id4UserId == "" || id4UserId == "0"
        ? "00000000-0000-0000-0000-000000000000"
        : id4UserId;

    const payload = await customGet(entityType.UI, "LogCustomMessage", {
      params: {
        action: action,
        userKey: userKey,
        id4UserId: guid,
        message: message,
        additionalInfo: additionalInfo,
      },
    });

    dispatch({
      type: "ui/LogCustomMessageSuccess",
      payload,
    });
  };

export const logPrintDialogError = (message) => async (dispatch, getState) => {
  const userKey = getState().actor?.details?.data?.UserId ?? -1;
  await customGet(entityType.UI, "LogPrintDialogError", {
    params: {
      userKey: userKey,
      message: message,
    },
  });

  dispatch({
    type: "ui/LogPrintDialogError",
  });
};
