import { EditOutlined, FileOutlined, PrinterOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import entityType from "../../../api/entityType";
import {
  documentTransformWrapper,
  readForQuickEditAction,
  transformDocumentInput,
} from "../../../store/document/documentActions";
import {
  fetchReport,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import { usePreservableNavigate } from "../../shared/PreservableStatePageWrapper";
import { TableActionsDropdown } from "../../shared/table/TableActionsDropdown";

export const MoreActionsMenu = ({ record, setQuickEdit }) => {
  const dispatch = useDispatch();
  const preservableNavigate = usePreservableNavigate();

  const items = [
    {
      key: "1",
      icon: <EditOutlined />,
      onClick: () => {
        dispatch(readForQuickEditAction(record.DocumentKey));
        setQuickEdit(true);
      },
      label: "Quick Edit",
    },
    { type: "divider" },
    {
      key: "2",
      icon: <FileOutlined />,
      onClick: () =>
        preservableNavigate(
          `/documents/updatedocumentform/${record.DocumentKey}`
        ),
      label: "View Details",
    },
    {
      key: "3",
      icon: <FileOutlined />,
      onClick: () =>
        window.open(
          `${process.env.REACT_APP_BASENAME}documents/updatedocumentform/${record.DocumentKey}`,
          `${record.DocumentKey}`
        ),
      label: "View Details in New Tab",
    },
    { type: "divider" },
    {
      key: "4",
      icon: <PrinterOutlined />,
      onClick: () => {
        dispatch(
          documentTransformWrapper(record.DocumentKey, (val) =>
            dispatch(
              fetchReport(
                transformDocumentInput(val),
                entityType.DOCUMENT,
                "PrintDocument"
              )
            )
          )
        );
        dispatch(setReportModalVisibility(true));
      },
      label: "Preview",
    },
  ];

  return <TableActionsDropdown items={items} />;
};
