import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommissionRateForm from "./CommissionRateForm";
import { Spin } from "antd";
import { createCommissionRate } from "../../../store/commission/commissionActions";

const CreateCommissionRate = ({ parentRefreshHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const initialValues = {
    ProducerCode1Key: 0,
    ProducerCode2Key: 0,
    RatePercent: 0,
  };

  const onSubmitHandler = (values) => {
    dispatch(createCommissionRate(values));
  };

  const rate = useSelector((state) => state.commission.selected);
  const loading = useSelector((state) => state.commission.selectedLoading);

  useEffect(() => {
    if (rate) {
      navigate(`../updatecommissionrate/${rate.Id}`, {
        state: location.state,
        replace: true,
      });
    }
  }, [rate, navigate]);

  return (
    <Spin spinning={loading} delay={500}>
      <CommissionRateForm
        onSubmitHandler={onSubmitHandler}
        initialValues={initialValues}
        handleParentRefresh={parentRefreshHandler}
        isNew={true}
      />
    </Spin>
  );
};

export default CreateCommissionRate;
