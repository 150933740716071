import { Button } from "antd";
import { useDispatch } from "react-redux";
import { getDataDownloadDetails } from "../../../store/dataDownload/dataDownloadActions";
import { formatDateTime } from "../../shared/formatting";
import { columnTypes } from "../../shared/table/columnTypes";

export const dataDownloadColumns = (setViewDetails) => {
  const dispatch = useDispatch();

  return [
    {
      title: "Date and Time",
      dataIndex: "DateRequested",
      type: columnTypes.DATETIME,
      fixed: "left",
      width: 170,
      render: (value, record) => {
        return (
          <Button
            className="no-left-padding"
            type="link"
            style={{ fontSize: "13px" }}
            onClick={() => {
              setViewDetails(true);
              dispatch(getDataDownloadDetails(record.FileDownloadKey));
            }}
          >
            {formatDateTime(value)}
          </Button>
        );
      },
    },
    {
      title: "Download Type",
      dataIndex: "DownloadType",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "DownloadStatus",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Period Start",
      dataIndex: "DatePeriodStart",
      type: columnTypes.DATETIME,
      width: 170,
    },
    {
      title: "Period End",
      dataIndex: "DatePeriodEnd",
      type: columnTypes.DATETIME,
      width: 170,
    },
    {
      title: "Txn Count",
      dataIndex: "RecordsAdded",
      type: columnTypes.NUMBER,
      align: "right",
      width: 75,
    },
    {
      title: "Agency",
      dataIndex: "AgencyNumberAndName",
      type: columnTypes.TEXT,
      width: 270,
    },
  ];
};
