import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Spin } from "antd";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import * as yup from "yup";
import {
  clearOperationResultAction,
  listTransactionTypesAction,
  setIgnoreSelectedWarningAction,
  setRefreshListFlagAction,
} from "../../../store/document/documentActions";
import { usePreservableState } from "../../shared/PreservableStatePageWrapper";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import AllAlerts from "./AllAlerts";
import AllTopRowButtons from "./AllTopRowButtons";
import DebtTransactionView from "./debtTransaction/DebtTransactionView";
import { debtSchema, documentSchema, paymentSchema } from "./documentSchemas";
import PaymentTransactionView from "./paymentTransaction/PaymentTransactionView";
import PolicyTransactionView from "./policyTransaction/PolicyTransactionView";
import specialViewProductKeys from "./specialViewProductKeys";
import VehicleRegistrationView from "./vehicleRegistration/VehicleRegistrationView";

const emptySchema = yup.object().shape({});

const DocumentForm = ({
  onSubmitHandler,
  onCloseHandler,
  isCreateDocument,
}) => {
  const documentState = useSelector((state) => state.document);
  const selected = documentState.selected;
  const validationSchema = isNull(selected)
    ? emptySchema
    : selected.ProductKey === specialViewProductKeys.DEBT
    ? debtSchema
    : selected.ProductKey === specialViewProductKeys.PAYMENT
    ? paymentSchema
    : documentSchema;

  // Form
  const form = useForm({
    mode: "onTouched",
    defaultValues: selected,
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset, getValues } = form;

  const dispatch = useDispatch();
  const { isRestorable, restorePreviousPage } = usePreservableState();
  const navigate = useNavigate();
  const location = useLocation();

  const [hasPressedSave, setHasPressedSave] = useState(false);

  const isDeleted = documentState.isDeleted;
  const warning =
    documentState.selectedWarning && documentState.selectedWarning.message;
  const ignoreWarning =
    documentState.selectedWarning && documentState.selectedWarning.ignore;
  const loading = documentState.selectedLoading;

  const operationResult = documentState.operationResult;

  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;

  const isNew =
    (isNull(selected) ? false : selected.DocumentKey === 0) ||
    isCreateDocument === true;

  useEffect(() => {
    const transactionTypes = documentState.transactionTypes;
    if (isNull(transactionTypes)) dispatch(listTransactionTypesAction());
  }, [dispatch]);

  useEffect(() => {
    if (!isNull(selected)) {
      var newDefaults = { ...selected };

      // Setup RoadStarAndRoadSidePlusPremium field
      if (newDefaults.RoadStarRoadSidePlusIndicator === 4) {
        newDefaults.RoadStarAndRoadSidePlusPremium =
          newDefaults.RoadStarPremium;
      } else if (newDefaults.RoadStarRoadSidePlusIndicator === 5) {
        newDefaults.RoadStarAndRoadSidePlusPremium =
          newDefaults.RoadSidePlusPremium;
      } else if (newDefaults.RoadStarPremium > 0) {
        newDefaults.RoadStarAndRoadSidePlusPremium =
          newDefaults.RoadStarPremium;
      } else if (newDefaults.RoadSidePlusPremium > 0) {
        newDefaults.RoadStarAndRoadSidePlusPremium =
          newDefaults.RoadSidePlusPremium;
      } else {
        newDefaults.RoadStarAndRoadSidePlusPremium = 0;
      }

      // If it's APV7 - ManualCancellation
      if (selected.ProductKey === 41 && isNew) {
        // We set transaction type to Cancellation (8 is the id)
        newDefaults.TransactionType = 8;
      }

      reset(newDefaults);
    }
  }, [selected]);

  const onSubmit = (_) => {
    var values = getValues();

    // Adjust output for Road Star / Road Side Plus
    if (values.RoadStarRoadSidePlusIndicator === 4) {
      // Road Star
      values.RoadStarPremium = values.RoadStarAndRoadSidePlusPremium;
      values.RoadSidePlusPremium = 0;
    } else if (values.RoadStarRoadSidePlusIndicator === 5) {
      // Road Side Plus
      values.RoadStarPremium = 0;
      values.RoadSidePlusPremium = values.RoadStarAndRoadSidePlusPremium;
    }

    // ALPLUS-2396 Set Timestamp to empty string before calling the Update function (for APV7 documents)
    if (values.ProductKey === 41) {
      values.DCID = "";
    }

    onSubmitHandler(values, ignoreWarning);
    dispatch(setIgnoreSelectedWarningAction(false));
    dispatch(setRefreshListFlagAction(true));
  };

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult]);

  useEffect(() => {
    if (isDeleted) {
      if (location.key !== "default" && isRestorable === true) {
        dispatch(setRefreshListFlagAction(true));
        restorePreviousPage();
        onCloseHandler && onCloseHandler();
        navigate(-1);
      } else {
        navigate("/home", { replace: true });
      }
    }
  }, [isDeleted]);

  return (
    <Spin spinning={loading} delay={500}>
      <Form
        size="small"
        autoComplete="off"
        scrollToFirstError
        onFinish={() => handleSubmit(onSubmit)()}
      >
        <FormProvider {...form}>
          <AllTopRowButtons
            onCloseHandler={onCloseHandler}
            hasPressedSave={hasPressedSave}
            selected={selected}
            setSaved={() => setHasPressedSave(true)}
            isNew={isNew}
          />
          {/* <Divider style={{ marginTop: 0, marginBottom: 0 }} /> */}
          <AllAlerts
            warning={warning}
            isUserAllowedToSave={isUserAllowedToSave}
            hasPressedSave={hasPressedSave}
          />

          <div style={{ overflowY: "auto", height: "76vh" }}>
            {selected && selected.ProductKey === specialViewProductKeys.DEBT ? (
              <DebtTransactionView isNew={isNew} />
            ) : selected &&
              selected.ProductKey === specialViewProductKeys.PAYMENT ? (
              <PaymentTransactionView isNew={isNew} />
            ) : selected && selected.IsRegistration ? (
              <VehicleRegistrationView isNew={isNew} />
            ) : (
              <PolicyTransactionView isNew={isNew} />
            )}
          </div>
        </FormProvider>
      </Form>
    </Spin>
  );
};

export default DocumentForm;
