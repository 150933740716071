import { Form, Radio } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";

const CustomRadioGroup = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    >
      {props.children}
    </Content>
  );
};

const Content = React.memo(
  (props) => {
    const { field, error } = props;

    var inputProps = { ...props };
    delete inputProps.control;
    delete inputProps.formLabel;
    delete inputProps.layout;
    delete inputProps.required;
    delete inputProps.isTouched;
    delete inputProps.isDirty;
    delete inputProps.error;
    delete inputProps.field;
    delete inputProps.children;
    delete inputProps.formStyle;
    delete inputProps.afterChange;
    delete inputProps.customField1;
    delete inputProps.customField2;
    delete inputProps.linkedValue;

    return (
      <Form.Item
        {...props.layout}
        label={props.formLabel}
        name={props.name}
        validateStatus={error != null ? "error" : ""}
        help={error != null ? error.message : null}
        required={props.required}
        style={props.formStyle}
      >
        <div>
          <Radio.Group
            {...field}
            {...inputProps}
            onChange={(v, o) => {
              field.onChange(v, o);
              if (props.afterChange != null) {
                props.afterChange(v);
              }
            }}
          >
            {props.children}
          </Radio.Group>
        </div>
      </Form.Item>
    );
  },
  (p, n) =>
    checkHookFormFieldPropsEquality(p, n) &&
    p.customField1 === n.customField1 &&
    p.customField2 === n.customField2
);
export default CustomRadioGroup;
