import { Actions } from "./vehicleConstants";
import { customGet } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";

export const listVehicleTypesAction = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_VEHICLE_TYPES });
  
  const payload = await customGet(entityType.VEHICLE, genericActionType.LIST);

  dispatch({ type: Actions.LIST_VEHICLE_TYPES_SUCCEEDED, payload });
};
