import React from "react";
import {
  InfoCircleFilled,
  WarningFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

export const InfoIcon = () => (
  <InfoCircleFilled style={{ color: "#1677ff", fontSize: 16 }} />
);

export const WarningIcon = () => (
  <WarningFilled style={{ color: "#faad14", fontSize: 16 }} />
);

export const ErrorIcon = () => (
  <CloseCircleFilled style={{ color: "#ff4d4f", fontSize: 16 }} />
);
