import produce from "immer";
import { Actions } from "./uiContants";
import lodash from "lodash";

const initialState = {
  // used to store what we could potentially restore
  preservable: {
    pages: [],
    tables: [],
  },
  // used to store data related to tables, also useful for restoring
  tables: [],
  uploads: [],
};

const initialTableState = {
  name: "",
  bookmarkedRowKey: null,
  preserveOnReload: false,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // Preservable items
      case Actions.ADD_PAGE_TO_PRESERVE:
        draft.preservable.pages.push(action.payload.page);
        return draft;
      case Actions.ADD_TABLE_TO_PRESERVE:
        draft.preservable.tables.push(action.payload.table);
        return draft;
      case Actions.REMOVE_PAGE_TO_PRESERVE:
        // Keep all pages that are not the page in the payload
        draft.preservable.pages = draft.preservable.pages.filter(
          (key) => key !== action.payload.page
        );
        return draft;
      case Actions.REMOVE_TABLE_TO_PRESERVE:
        draft.preservable.tables = draft.preservable.tables.filter(
          (key) => key !== action.payload.table
        );
        return draft;
      case Actions.CLEAR_ALL_PRESERVABLES:
        draft.preservable = initialState.preservable;
        return draft;

      //
      case Actions.SET_UPLOADED_FILE:
        //
        var index = lodash.findIndex(
          draft.uploads,
          (b) => b.name === action.payload.uploadName
        );

        // Nothing was found
        if (index === -1) {
          draft.uploads.push({
            name: action.payload.uploadName,
            fileName: action.payload.fileName,
            isUploaded: action.payload.isUploaded,
          });
        } else {
          draft.uploads[index].fileName = action.payload.fileName;
          draft.uploads[index].isUploaded = action.payload.isUploaded;
        }

        return draft;

      case Actions.CLEAR_UPLOADED_FILE:
        //
        var indexToClean = lodash.findIndex(
          draft.uploads,
          (b) => b.name === action.payload.uploadName
        );

        if (indexToClean !== -1) {
          draft.uploads.splice(indexToClean, 1);
        }

        return draft;

      //
      case Actions.SET_TABLE_ROW_BOOKMARK:
        // Look for an existing table with TableName
        var index = lodash.findIndex(
          draft.tables,
          (b) => b.name === action.payload.TableName
        );

        // Nothing was found
        if (index === -1) {
          draft.tables.push({
            ...initialTableState,
            bookmarkedRowKey: action.payload.RowKey,
            name: action.payload.TableName,
          });
        } else {
          draft.tables[index].bookmarkedRowKey = action.payload.RowKey;
        }

        return draft;
      case Actions.SET_TABLE_PRESERVE_ON_RELOAD:
        var index = lodash.findIndex(
          draft.tables,
          (b) => b.name === action.payload.TableName
        );

        if (index === -1) {
          draft.tables.push({
            ...initialTableState,
            preserveOnReload: action.payload.Preserve,
            name: action.payload.TableName,
          });
        } else {
          draft.tables[index].preserveOnReload = action.payload.Preserve;
        }

        return draft;
      case Actions.CLEAR_TABLE_ROW_BOOKMARK:
        var indexToClean = lodash.findIndex(
          draft.tables,
          (b) => b.name === action.payload.TableName
        );

        if (indexToClean !== -1) {
          draft.tables[index].bookmarkedRowKey = null;
        }
        return draft;
      case Actions.SET_TABLE_RESTORABLE_DATA:
        var index = lodash.findIndex(
          draft.tables,
          (b) => b.name === action.payload.TableName
        );

        if (index === -1) {
          draft.tables.push({
            ...initialTableState,
            name: action.payload.TableName,
            ...action.payload.data,
          });
        } else {
          draft.tables[index] = {
            ...draft.tables[index],
            ...action.payload.data,
          };
        }

        return draft;
      case Actions.CLEAR_TABLE_RESTORABLE_DATA:
        var indexToClean = lodash.findIndex(
          draft.tables,
          (b) => b.name === action.payload.TableName
        );

        if (indexToClean !== -1) {
          draft.tables.splice(indexToClean, 1);
        }
        return draft;
      default:
        return state;
    }
  });
};
