import React, { useEffect } from "react";
import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import { isEmpty } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemInventoryMovementAction,
  listPlateTypesActions,
} from "../../../store/inventory/inventoryActions";
import { addPlatesSchema } from "../shared/moveInventorySchemas";
import { yupResolver } from "@hookform/resolvers/yup";

const AddPlatesForm = ({ headerValues, operationResult }) => {
  const emptyInitialValues = {
    PlateItemNumberFrom: "",
    PlateItemNumberTo: "",
    PlateTypeKey: "",
  };
  const {
    handleSubmit,
    control,
    reset,
    setFocus,
    formState: { isValid, errors, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: emptyInitialValues,
    resolver: yupResolver(addPlatesSchema),
  });

  const moveInventoryState = useSelector(
    (state) => state.inventory.moveInventory
  );
  const newMovementEntity = moveInventoryState.newMovement.entity;
  const loading = moveInventoryState.newMovement.loading;
  const plateTypes = moveInventoryState.plateTypes.list;
  const plateTypesLoading = moveInventoryState.plateTypes.loading;

  const dispatch = useDispatch();
  const onAddItemsHandler = (values) => {
    dispatch(
      addItemInventoryMovementAction({
        ItemNumberFrom: values.PlateItemNumberFrom.toUpperCase(),
        ItemNumberTo: values.PlateItemNumberTo.toUpperCase(),
        Quantity: values.PlateItemQuantity,
        PlateTypeKey: values.PlateTypeKey,
        AgencyKey: newMovementEntity.Header.AgencyKey,
        InventoryMovementType: newMovementEntity.Header.InventoryMovementType,
        InventoryMovementKey: newMovementEntity.Header.InventoryMovementKey,
        TransactionDate: headerValues.TransactionDate,
        StockCentreConfNo: headerValues.StockCentreConfNo,
      })
    );
    setFocus("PlateItemNumberFrom");
  };

  useEffect(() => {
    if (isEmpty(plateTypes)) {
      dispatch(listPlateTypesActions());
    }
  }, [dispatch]);

  useEffect(() => {
    setFocus("PlateItemNumberFrom");
  }, [setFocus]);

  useEffect(() => {
    if (
      operationResult &&
      operationResult.Name === "AddItemRange" &&
      operationResult.Outcome === "Success"
    ) {
      reset();
    }
  }, [operationResult]);

  return (
    <div>
      <Row style={{ marginTop: 8 }}>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            layout="inline"
            onFinish={() => {}}
          >
            <Divider style={{ marginTop: 8, marginBottom: 16 }} />
            <Row>
              <Controller
                control={control}
                name="PlateItemNumberFrom"
                render={({ field }) => (
                  <Form.Item
                    label="Plate Series From"
                    style={{ marginBottom: 0 }}
                    required
                    validateStatus={
                      errors &&
                      errors["PlateItemNumberFrom"] &&
                      field.value != null &&
                      field.value != ""
                        ? "error"
                        : ""
                    }
                    help={errors.PlateItemNumberFrom?.message}
                  >
                    <Input
                      {...field}
                      name="PlateItemNumberFrom"
                      style={{ width: 100 }}
                    />
                  </Form.Item>
                )}
              />

              <Controller
                control={control}
                name="PlateItemNumberTo"
                render={({ field }) => (
                  <Form.Item
                    label="To"
                    style={{ marginBottom: 0 }}
                    required
                    validateStatus={
                      errors &&
                      errors["PlateItemNumberTo"] &&
                      field.value != null &&
                      field.value != ""
                        ? "error"
                        : ""
                    }
                    help={errors.PlateItemNumberTo?.message}
                  >
                    <Input
                      {...field}
                      name="PlateItemNumberTo"
                      style={{ width: 100 }}
                    />
                  </Form.Item>
                )}
              />

              <Controller
                control={control}
                name="PlateTypeKey"
                render={({ field }) => (
                  <Form.Item
                    label="Plate Type"
                    style={{ marginBottom: 0 }}
                    required
                    validateStatus={
                      errors && errors["PlateTypeKey"] ? "error" : ""
                    }
                    help={errors.PlateTypeKey?.message}
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Select
                        {...field}
                        name="PlateTypeKey"
                        style={{ width: 380 }}
                        options={plateTypes}
                        loading={plateTypesLoading}
                        showSearch
                        virtual={false}
                        optionFilterProp="label"
                        filterOption={(input, option) => {
                          const label = option.label
                            ? option.label
                            : option.children;
                          return (
                            label.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                      />
                    </span>
                  </Form.Item>
                )}
              />

              <Form.Item name="AddPlateButton">
                <Button
                  size="small"
                  disabled={!isValid || !isDirty}
                  onClick={() => handleSubmit(onAddItemsHandler)()}
                  loading={loading}
                >
                  Add Plates
                </Button>
              </Form.Item>
            </Row>
            <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AddPlatesForm;
