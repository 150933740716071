import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { toTZNeutralFormat } from "../../components/shared/formatting";
import { findCurrentAgency } from "../report/reportActions";
import { Actions } from "./batchingConstants";

export const listAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.LIST_BATCHES });

  try {
    const payload = await customPost(
      entityType.BATCH,
      genericActionType.FIND,
      values
    );

    if (payload == null) throw "Payload is empty";

    dispatch({ type: Actions.LIST_BATCHES_SUCCEEDED, payload });
  } catch (e) {
    dispatch({ type: Actions.LIST_BATCHES_FAILED });
  }
};

export const clearBatchListAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_BATCH_LIST });
};

export const readAction = (batchKey) => async (dispatch) => {
  dispatch({ type: Actions.READ_BATCH });
  const payload = await customGet(entityType.BATCH, genericActionType.READ, {
    params: {
      batchKey: batchKey,
    },
  });
  dispatch({ type: Actions.READ_BATCH_SUCCEEDED, payload });
};

export const listDocumentsAvailableToAddAction =
  (batchKey) => async (dispatch) => {
    dispatch({ type: Actions.LIST_DOCUMENTS_AVAILABLE_TO_ADD });
    const payload = await customGet(
      entityType.BATCH,
      "ListDocumentsAvailableToAdd",
      {
        params: {
          batchKey: batchKey,
        },
      }
    );
    dispatch({
      type: Actions.LIST_DOCUMENTS_AVAILABLE_TO_ADD_SUCCEEDED,
      payload,
    });
  };

export const removeDocumentFromBatchAction =
  (batchKey, documentKey) => async (dispatch) => {
    dispatch({ type: Actions.REMOVE_DOCUMENT_FROM_BATCH });
    const payload = await customGet(entityType.BATCH, "RemoveDocument", {
      params: {
        batchKey: batchKey,
        documentKey: documentKey,
      },
    });
    dispatch({ type: Actions.REMOVE_DOCUMENT_FROM_BATCH_SUCCEEDED, payload });
  };

export const undoBatchAction =
  (batchKey, searchCriteria) => async (dispatch) => {
    dispatch({ type: Actions.UNDO_BATCH });
    const payload = await customPost(
      entityType.BATCH,
      "UndoBatch",
      searchCriteria,
      {
        params: {
          batchKey: batchKey,
        },
      }
    );
    dispatch({ type: Actions.UNDO_BATCH_SUCCEEDED, payload });
  };

const getIncludeExcludeDocumentKeys = (preventInclusionRefresh, state) => {
  let includedValues = [];
  let excludedValues = [];
  if (!preventInclusionRefresh) {
    const substate = state.batching.newBatch.ui.documentsInclusionState;
    const currentState = state.batching.newBatch.ui.inclusionStateSnapshot;
    for (const key in substate) {
      if (substate[key] === true && substate[key] != currentState[key]) {
        includedValues.push(key);
      } else if (
        substate[key] === false &&
        substate[key] != currentState[key]
      ) {
        excludedValues.push(key);
      }
    }
  }
  return { includedValues, excludedValues };
};

export const updateTableWithExclusionAndProceed =
  (action1, action2) => async (dispatch) => {
    if (action1 != null && action2 != null) {
      dispatch(action1);
    }
    await dispatch(updateInclusionExclusionStateAction());
    if (action2 != null) {
      dispatch(action2);
    } else {
      dispatch(action1);
    }
  };

export const getNewBatchFromCriteriaFiltered =
  (values) => async (dispatch, getState) => {
    dispatch({ type: Actions.GET_NEW_BATCH });
    const state = getState();
    const criteria = state.batching.newBatch.criteria;
    const { includedValues, excludedValues } = getIncludeExcludeDocumentKeys(
      values.PreventInclusionRefresh === true,
      state
    );

    await customPost(entityType.BATCH, "GetNewBatch", {
      ...criteria,
      ICBCBatch: values.ICBCBatch,
      AgencyBatch: values.AgencyBatch,
      HideExcludedDocuments: values.HideExcludedDocuments,
      FromCreatedDate: toTZNeutralFormat(values.FromCreatedDate),
      ToCreatedDate: toTZNeutralFormat(values.ToCreatedDate),
      IncludedDocumentKeys: includedValues,
      ExcludedDocumentKeys: excludedValues,
    });

    const payload = await customPost(entityType.BATCH, "GetNewBatch", {
      ...criteria,
      ...values,
      FromCreatedDate: toTZNeutralFormat(values.FromCreatedDate),
      ToCreatedDate: toTZNeutralFormat(values.ToCreatedDate),
    });
    dispatch({ type: Actions.GET_NEW_BATCH_SUCCEEDED, payload });
  };

export const getNewBatchFromCriteriaAction =
  (values) => async (dispatch, getState) => {
    dispatch({ type: Actions.GET_NEW_BATCH });
    const state = getState();
    const criteria = state.batching.newBatch.criteria;
    const { includedValues, excludedValues } = getIncludeExcludeDocumentKeys(
      values.PreventInclusionRefresh === true,
      state
    );

    const payload = await customPost(
      entityType.BATCH,
      "GetNewBatch",
      values.PreventInclusionRefresh === true
        ? {
            ...criteria,
            ...values,
            FromCreatedDate: toTZNeutralFormat(values.FromCreatedDate),
            ToCreatedDate: toTZNeutralFormat(values.ToCreatedDate),
          }
        : {
            ...criteria,
            ...values,
            FromCreatedDate: toTZNeutralFormat(values.FromCreatedDate),
            ToCreatedDate: toTZNeutralFormat(values.ToCreatedDate),
            IncludedDocumentKeys: includedValues,
            ExcludedDocumentKeys: excludedValues,
          }
    );
    dispatch({ type: Actions.GET_NEW_BATCH_SUCCEEDED, payload });
  };

export const updateInclusionExclusionStateAction =
  () => async (dispatch, getState) => {
    const { includedValues, excludedValues } = getIncludeExcludeDocumentKeys(
      false,
      getState()
    );

    dispatch({ type: Actions.UPDATE_INCLUSION_EXCLUSION });

    if (includedValues.length > 0 || excludedValues.length > 0) {
      const payload = await customPost(
        entityType.BATCH,
        "UpdateDocInclusionExclusionState",
        {
          IncludedDocumentKeys: includedValues,
          ExcludedDocumentKeys: excludedValues,
        }
      );

      let values = {
        ...payload,
        Included: includedValues,
        Excluded: excludedValues,
      };

      dispatch({
        type: Actions.UPDATE_INCLUSION_EXCLUSION_SUCCEEDED,
        payload: values,
      });
    }
  };

export const getNewBatchAction = (values) => async (dispatch, getState) => {
  dispatch({ type: Actions.GET_NEW_BATCH });

  const { includedValues, excludedValues } = getIncludeExcludeDocumentKeys(
    values.PreventInclusionRefresh === true,
    getState()
  );

  const payload = await customPost(
    entityType.BATCH,
    "GetNewBatch",
    values.PreventInclusionRefresh === true
      ? {
          ...values,
          FromCreatedDate: toTZNeutralFormat(values.FromCreatedDate),
          ToCreatedDate: toTZNeutralFormat(values.ToCreatedDate),
        }
      : {
          ...values,
          FromCreatedDate: toTZNeutralFormat(values.FromCreatedDate),
          ToCreatedDate: toTZNeutralFormat(values.ToCreatedDate),
          IncludedDocumentKeys: includedValues,
          ExcludedDocumentKeys: excludedValues,
        }
  );

  let result = {
    ...payload,
    UpdateAllKeysList: values.UpdateAllKeysList ?? false,
  };
  //console.log(result);
  dispatch({ type: Actions.GET_NEW_BATCH_SUCCEEDED, payload: result });
};

export const addDocumentsToBatchAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.ADD_DOCUMENTS_TO_BATCH });
  const payload = await customPost(
    entityType.BATCH,
    "AddDocumentsToBatch",
    values
  );
  dispatch({ type: Actions.ADD_DOCUMENTS_TO_BATCH_SUCCEEDED, payload });
};

export const closeBatchAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.CLOSE_BATCH });
  const payload = await customPost(entityType.BATCH, "CloseBatch", values);
  dispatch({ type: Actions.CLOSE_BATCH_SUCCEEDED, payload });
};

export const updateBatchHeaderAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.UPDATE_BATCH_HEADER });
  const payload = await customPost(entityType.BATCH, "UpdateHeader", values);
  dispatch({ type: Actions.UPDATE_BATCH_HEADER_SUCCEEDED, payload });
};

export const getNextBatchNumberAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.GET_NEXT_BATCH_NUMBER });
  const payload = await customPost(
    entityType.BATCH,
    "GetNextBatchNumber",
    values
  );
  dispatch({ type: Actions.GET_NEXT_BATCH_NUMBER_SUCCEEDED, payload });
};

export const listMultiLicencePoliciesAction = (values) => async (dispatch) => {
  //
  dispatch({ type: Actions.LIST_MULTILICENCE_POLICIES });
  const payload = await customPost(
    entityType.BATCH,
    "ListUnbatchedMultiLicensePolicies",
    values
  );
  dispatch({ type: Actions.LIST_MULTILICENCE_POLICIES_SUCCEEDED, payload });
};

export const clearMultiLicencePoliciesAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_MULTILICENCE_POLICIES });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const clearInlineEditOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_INLINE_EDIT_OPERATION_RESULT });
};

export const setInlineEditOperationResultAction =
  (data) => async (dispatch) => {
    dispatch({
      type: Actions.SET_INLINE_EDIT_OPERATION_RESULT,
      payload: { data: data },
    });
  };

export const updateInlineEditChangesAction =
  (values, ignoreWarnings, updateTimestamp, parentRefreshAction) =>
  async (dispatch, getState) => {
    const userKey = getState().actor.details.data.UserId;
    dispatch({ type: Actions.UPDATE_INLINE_EDIT_CHANGES });
    const payload = await customPost(
      entityType.DOCUMENT,
      "UpdateInlineEditChanges",
      values,
      {
        params: {
          userKey: userKey,
          validatedInventoryStatus: !ignoreWarnings,
          updateTimestamp: updateTimestamp,
        },
      }
    );
    dispatch({ type: Actions.UPDATE_INLINE_EDIT_CHANGES_SUCCEEDED, payload });
    dispatch(parentRefreshAction);
  };

export const clearCloseBatchOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_CLOSE_BATCH_OPERATION_RESULT });
};

export const clearSelectedAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED });
};

export const clearIsUndone = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_IS_UNDONE });
};

export const backupBatchingData = () => async (dispatch) => {
  dispatch({ type: Actions.BACKUP_BATCHING_DATA });
};

export const restoreBatchingData = () => async (dispatch) => {
  dispatch({ type: Actions.RESTORE_BATCHING_DATA });
};

export const setSelectedAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.SET_SELECTED, payload: { Data: values } });
};

export const setMultiLicencePoliciesSelectedRowsAction =
  (values) => async (dispatch) => {
    dispatch({
      type: Actions.SET_SELECTED_MULTILICENCE_POLICIES,
      payload: { selectedRows: values },
    });
  };

export const setSearchCriteriaAction = (searchCriteria) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SEARCH_CRITERIA,
    payload: { searchCriteria: searchCriteria },
  });
};

export const setNewBatchCriteriaAction =
  (newBatchCriteria) => async (dispatch) => {
    dispatch({
      type: Actions.SET_NEW_BATCH_CRITERIA,
      payload: { newBatchCriteria: newBatchCriteria },
    });
  };

export const updateBatchCriteriaWithAction =
  (values) => async (dispatch, getState) => {
    const criteria = getState().batching.newBatch.criteria;
    dispatch({
      type: Actions.SET_NEW_BATCH_CRITERIA,
      payload: { newBatchCriteria: { ...criteria, ...values } },
    });
  };

export const clearNewBatchAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_NEW_BATCH,
  });
};

export const clearNewBatchDataAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_NEW_BATCH_DATA,
  });
};

export const setDocumentsInclusionStateAction =
  (documentKey, checked) => async (dispatch, getState) => {
    const documentsInclusionState =
      getState().batching.newBatch.ui.documentsInclusionState;

    let newInclusions = { ...documentsInclusionState };
    newInclusions[documentKey] = !checked;

    dispatch({
      type: Actions.SET_DOCUMENTS_INCLUSION_STATE,
      payload: { keys: newInclusions },
    });
  };

export const setFirstElementKeyAction = (key) => async (dispatch) => {
  dispatch({
    type: Actions.SET_FIRST_ELEMENT_KEY,
    payload: { key: key },
  });
};

export const setDocumentsItemNumbers =
  (documentKey) => async (dispatch, getState) => {
    const documentsItemNumbers =
      getState().batching.newBatch.ui.documentsItemNumbers;
    const removedVal = documentsItemNumbers[documentKey];

    let newItemNumbers = {};
    for (let [key, value] of Object.entries(documentsItemNumbers)) {
      if (value > removedVal) {
        newItemNumbers[key] = value - 1;
      } else {
        newItemNumbers[key] = value;
      }
    }
    dispatch({
      type: Actions.SET_DOCUMENTS_ITEM_NUMBERS,
      payload: { keys: newItemNumbers },
    });
  };

export const clearDocumentsInclusionStateAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_DOCUMENTS_INCLUSION_STATE,
  });
};

export const setSelectedBatchDocumentKeysAction =
  (documentKey, checked) => async (dispatch, getState) => {
    const selectedDocumentKeys =
      getState().batching.newBatch.ui.selectedDocumentKeys;
    var newKeys = [...selectedDocumentKeys];
    if (!checked) {
      newKeys.push(documentKey);
    } else {
      newKeys = newKeys.filter(function (item) {
        return item !== documentKey;
      });
    }

    dispatch({
      type: Actions.SET_SELECTED_BATCH_DOCUMENT_KEYS,
      payload: { keys: newKeys },
    });
  };

export const setProcessBatchCurrentStepAction = (value) => async (dispatch) => {
  dispatch({
    type: Actions.SET_PROCESS_BATCH_CURRENT_STEP,
    payload: { value: value },
  });
};

export const clearSelectedBatchDocumentKeysAction = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_BATCH_DOCUMENT_KEYS,
  });
};

export const transformBatchInput = (values) => (_) => {
  return {
    BatchKey: values.DocumentBatchKey,
    FileName: `Batch_${values.AgencyBatchNo.toString()}_${values.ICBCBatchNo.toString()}`,
  };
};

export const transformDraftInput = (values) => (state) => {
  const agency = findCurrentAgency(state, values.AgencyKey);
  return {
    ...values,
    TransactionDate: [values.FromCreatedDate, values.ToCreatedDate],
    AgencyNameAndNumber: agency.FullName,
    FileName: `BatchDraft_${
      values.AgencyBatch != null ? values.AgencyBatch.toString() : ""
    }`,
  };
};

export const listBatchesForReporting = (values) => async (dispatch) => {
  dispatch({ type: Actions.LIST_BATCHES_FOR_REPORTING });
  const payload = await customPost(
    entityType.BATCH,
    "ListBatchesForReporting",
    values
  );
  dispatch({ type: Actions.LIST_BATCHES_FOR_REPORTING_SUCCEEDED, payload });
};

export const resetReportingData = () => async (dispatch) => {
  dispatch({
    type: Actions.RESET_REPORTING_DATA,
  });
};
