import { Steps } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNewBatchAction } from "../../../store/batching/batchingActions";
import { PreservableStatePageWrapper } from "../../shared/PreservableStatePageWrapper";
import CreateBatchStep1 from "./CreateBatchStep1";
import CreateBatchStep2 from "./CreateBatchStep2";
import CreateBatchStep3 from "./CreateBatchStep3";
import { tableNames } from "../../shared/table/tableNames";
import PermissionWrapper from "../../navigation/PermissionWrapper";

const stepsContent = {
  padding: 8,
};

const CreateBatchForm = () => {
  const dispatch = useDispatch();
  const onCleanup = useCallback(() => dispatch(clearNewBatchAction()), []);

  return (
    <PreservableStatePageWrapper
      onCleanup={onCleanup}
      tableNames={[tableNames.BatchCreateStep2]}
    >
      <CreateBatchFormBody />
    </PreservableStatePageWrapper>
  );
};

const CreateBatchFormBody = () => {
  const currentStep = useSelector(
    (state) => state.batching.newBatch.ui.processBatchCurrentStep
  );

  return (
    <PermissionWrapper entityName="AddBatch">
      <Steps
        current={currentStep}
        size="small"
        items={[
          {
            title: "Enter Batch Criteria",
          },
          {
            title: "Define Batch Details",
          },
          {
            title: "Confirmation",
          },
        ]}
      />
      <div style={stepsContent}>
        {currentStep === 0 && <CreateBatchStep1 />}
        {currentStep === 1 && <CreateBatchStep2 />}
        {currentStep === 2 && <CreateBatchStep3 />}
      </div>
    </PermissionWrapper>
  );
};

export default CreateBatchForm;
