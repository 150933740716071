import { Col, Modal, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { movementTableColumns } from "./movementTableColumns";
import { tableNames } from "../../shared/table/tableNames";

const MovementTable = ({ setClearAll, clearAll }) => {
  const inventoryMovements = useSelector(
    (state) => state.inventory.inventoryMovements
  );
  const list = inventoryMovements.data;
  const loading = inventoryMovements.loading;
  const [modal, contextHolder] = Modal.useModal();

  const columns = movementTableColumns(
    inventoryMovements.searchCriteria,
    modal
  );

  return (
    <div>
      <Row>
        <Col>
          <SearchableTable
            setClearAll={setClearAll}
            clearAll={clearAll}
            scroll={{ y: 550, x: 860 }}
            sticky
            dataSource={list}
            columns={columns}
            loading={loading}
            size="small"
            rowKey={(r) => r.InventoryMovementKey}
            tableName={tableNames.MovementSearch}
          />
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
};
export default MovementTable;
