import { Col, Row } from "antd";
import { isNull } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  dateTimeInputStyle,
  mediumInputStyle,
} from "../../../shared/form/formInputStyles";
import CustomDatePicker from "../../../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../../../shared/form/reactHookForm/CustomInput";
import CustomInputCurrency from "../../../shared/form/reactHookForm/CustomInputCurrency";
import CustomInputTextArea from "../../../shared/form/reactHookForm/CustomInputTextArea";
import { dateTimeFormat } from "../../../shared/formatting";
import { ProducerCodeAndName } from "../ProducerCodeAndName";

const specialLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const DebtDocumentPanel = ({ control, isNew = false }) => {
  const selected = useSelector((state) => state.document.selected);
  const maskSensitiveData =
    useSelector((state) => state.actor?.details?.data?.MaskSensitiveData) &&
    !isNew;
  const isDownloaded = selected && selected.IsDownloaded;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;
  const isUserAllowedToViewCommission =
    selected && selected.IsUserAllowedToViewCommission;

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <ProducerCodeAndName
            layout={specialLayout}
            control={control}
            disabled={!isUserAllowedToSave}
            selected={selected}
            isNew={isNew}
            maskSensitiveData={maskSensitiveData}
            code={1}
          />

          <ProducerCodeAndName
            layout={specialLayout}
            control={control}
            disabled={!isUserAllowedToSave}
            selected={selected}
            isNew={isNew}
            maskSensitiveData={maskSensitiveData}
            code={2}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Timestamp"
            control={control}
            required={!isDownloaded}
            style={mediumInputStyle}
            name="DCID"
            maxLength={14}
            disabled={
              (isDownloaded && !selected.IsFinancial) || !isUserAllowedToSave
            }
          />

          <CustomDatePicker
            formItem={{
              layout: specialLayout,
              label: "Transaction Date",
            }}
            control={control}
            style={dateTimeInputStyle}
            showTime={true}
            name="EntryDateTime"
            format={dateTimeFormat}
            disabled={true}
            placeholder={""}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <CustomInput
            layout={specialLayout}
            formLabel="Customer Name 1"
            required={true}
            control={control}
            name="CustomerName1"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Customer Name 2"
            control={control}
            name="CustomerName2"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          {isUserAllowedToViewCommission === true && (
            <CustomInputCurrency
              layout={specialLayout}
              formLabel="Commission Amount"
              control={control}
              name="CommTotalAmt"
              disabled={true}
              isPrecision={true}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomInputTextArea
            control={control}
            layout={{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
            formLabel="Agent Comments"
            name="AgentComments"
            id="AgentComments"
            disabled={!isUserAllowedToSave}
            maxLength={255}
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(DebtDocumentPanel);
