import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "antd";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocumentsToBatchAction,
  listDocumentsAvailableToAddAction,
} from "../../../store/batching/batchingActions";
import CustomSelect from "../../shared/form/reactHookForm/CustomSelect";

const AddDocumentsToBatch = ({
  batchKey,
  handleParentRefresh,
  isUserAllowedToSave,
}) => {
  // Form
  const {
    reset,
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: { DocumentsToAdd: [] },
  });

  //
  const dispatch = useDispatch();

  //
  const [previousLength, setPreviousLength] = useState(-1);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [maskedAvailableDocuments, setMaskedAvailableDocuments] = useState([]);

  //
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const availableDocuments = useSelector(
    (state) => state.batching.availableDocumentsToAdd.data
  );
  const availableDocumentsLoading = useSelector(
    (state) => state.batching.availableDocumentsToAdd.loading
  );
  const selected = useSelector((state) => state.batching.selected);

  //
  useEffect(() => {
    if (
      !isNull(batchKey) &&
      !isNull(selected) &&
      !isNull(selected.Documents) &&
      selected.Documents.length != previousLength &&
      (shouldRefresh === true || selected.Documents.length < previousLength)
    ) {
      setPreviousLength(selected.Documents.length);
      setShouldRefresh(false);
      dispatch(listDocumentsAvailableToAddAction(batchKey));
      handleParentRefresh();
    }
  }, [selected]);

  useEffect(() => {
    if (!isNull(batchKey)) {
      dispatch(listDocumentsAvailableToAddAction(batchKey));
      if (
        !isNull(selected) &&
        !isNull(selected.Documents) &&
        selected.Documents.length != previousLength
      ) {
        setPreviousLength(selected.Documents.length);
      }
    }
  }, [batchKey]);

  useEffect(() => {
    if (availableDocuments != null && maskSensitiveData === true) {
      const newList = availableDocuments.map((item) => {
        const parts = item.label.split(" | ");
        const lastPart = parts.pop();
        const list1 = [];
        const list2 = [];
        list1.push(parts.join(" | ") + " | ");
        list2.push(lastPart);

        return {
          label: (
            <div style={{ display: "inline" }}>
              {list1}
              <div
                style={{
                  color: "transparent",
                  textShadow: "0 0 8px #000",
                  userSelect: "none",
                  display: "inline",
                }}
              >
                {list2}
              </div>
            </div>
          ),
          filter: item.label,
          value: item.value,
        };
      });
      setMaskedAvailableDocuments(newList);
    }
  }, [availableDocuments]);

  //
  const addDocumentsHandler = () => {
    if (!isNull(batchKey)) {
      setShouldRefresh(true);
      dispatch(
        addDocumentsToBatchAction({
          BatchKey: batchKey,
          DocumentKeys: getValues().DocumentsToAdd,
        })
      );
      reset();
    }
  };

  //
  return (
    <Form
      size="small"
      autoComplete="off"
      scrollToFirstError
      onFinish={() => {}}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={21} xl={21} xxl={21}>
          <CustomSelect
            control={control}
            name="DocumentsToAdd"
            style={{ width: "100%" }}
            options={
              maskSensitiveData === true
                ? maskedAvailableDocuments
                : availableDocuments
            }
            loading={availableDocumentsLoading}
            allowClear
            filterOption={(input, option) => {
              const label =
                maskSensitiveData === true ? option.filter : option.label;
              return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            filterSort={(optionA, optionB) => {
              const labelA =
                maskSensitiveData === true ? optionA.filter : optionA.label;
              const labelB =
                maskSensitiveData === true ? optionB.filter : optionB.label;
              labelA.toLowerCase().localeCompare(labelB.toLowerCase());
            }}
            mode="multiple"
            placeholder="Select documents to add to the batch"
            disabled={!isUserAllowedToSave}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 2, offset: 1 }}
          xl={{ span: 2, offset: 1 }}
          xxl={{ span: 2, offset: 1 }}
        >
          <Button
            size="small"
            disabled={!isDirty || !isUserAllowedToSave}
            type="default"
            onClick={addDocumentsHandler}
          >
            <PlusOutlined /> Add
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddDocumentsToBatch;
