import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Radio, Row, DatePicker } from "antd";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../shared/BackButton";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import CustomRadioGroup from "../shared/form/reactHookForm/CustomRadioGroup";
import CustomInputTextArea from "../shared/form/reactHookForm/CustomInputTextArea";
import { announcementSchema } from "./announcementSchema";
import { announcementType } from "./announcementType";
import { clearSelectedAnnouncement } from "../../store/announcement/announcementActions";
import { dateTimeInputStyle } from "../shared/form/formInputStyles";
import { dateTimeFormat } from "../shared/formatting";
import dayjs from "dayjs";
import CustomDatePicker from "../shared/form/reactHookForm/CustomDatePicker";

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
    md: { span: 14 },
    lg: { span: 14 },
    xl: { span: 14 },
    xxl: { span: 14 },
  },
};

const AnnouncementForm = ({
  initialValues,
  onSubmitHandler,
  handleParentRefresh,
  isNew = false,
}) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { isValid, isDirty, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(announcementSchema),
  });

  //
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.announcement.selectedLoading);

  const onGoBackHandler = () => {
    dispatch(clearSelectedAnnouncement());
  };

  const onSubmitHandlerEx = (values) => {
    onSubmitHandler(values);
    handleParentRefresh();
    reset(values);
  };

  return (
    <Form
      autoComplete="xyz"
      size="small"
      onFinish={() => handleSubmit(onSubmitHandlerEx)()}
    >
      <Row style={{ paddingBottom: 8 }}>
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <BackButton onGoBackHandler={onGoBackHandler} isDirty={isDirty} />
        </Col>

        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <Button
            type={isNew === true ? "default" : "primary"}
            loading={loading}
            id="processButton"
            htmlType="submit"
            disabled={!isValid || !isDirty}
          >
            <SaveOutlined />
            Save
          </Button>
        </Col>
        <Col style={{ marginRight: 5, paddingTop: 5 }}>
          <Button
            size="small"
            type="dashed"
            disabled={!isDirty || loading}
            onClick={() => {
              reset();
              trigger();
            }}
          >
            <UndoOutlined />
            Reset Values
          </Button>
        </Col>
      </Row>
      <Card size="small">
        <Row style={{ paddingBottom: 16 }}>
          <Col span={24}>
            <CustomRadioGroup
              control={control}
              formLabel="Type"
              name="Type"
              layout={layout}
              required={true}
              autoFocus={true}
            >
              <Radio value={announcementType.Information}>Information</Radio>
              <Radio value={announcementType.Warning}>Warning</Radio>
              <Radio value={announcementType.Error}>Error</Radio>
            </CustomRadioGroup>

            <CustomInput
              control={control}
              layout={layout}
              required={true}
              formLabel="Subject"
              name="Subject"
              maxLength={100}
            />
            <CustomInputTextArea
              control={control}
              required={true}
              layout={layout}
              formLabel="Description"
              name="Description"
              maxLength={500}
              rows={5}
            />

            <CustomDatePicker
              formItem={{
                labelCol: {
                  xs: { span: 24 },
                  sm: { span: 7 },
                  md: { span: 6 },
                  lg: { span: 6 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                },
                wrapperCol: {
                  xs: { span: 24 },
                  sm: { span: 17 },
                  md: { span: 18 },
                  lg: { span: 18 },
                  xl: { span: 18 },
                  xxl: { span: 18 },
                },
                label: "Announce on",
                required: true,
              }}
              control={control}
              name="AnnounceOnDate"
              showTime={true}
              style={dateTimeInputStyle}
              format={dateTimeFormat}
              allowClear={false}
            />

            <CustomDatePicker
              formItem={{
                labelCol: {
                  xs: { span: 24 },
                  sm: { span: 7 },
                  md: { span: 6 },
                  lg: { span: 6 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                },
                wrapperCol: {
                  xs: { span: 24 },
                  sm: { span: 17 },
                  md: { span: 18 },
                  lg: { span: 18 },
                  xl: { span: 18 },
                  xxl: { span: 18 },
                },
                label: "Expiry Date",
                required: true,
              }}
              control={control}
              name="ExpiryDate"
              showTime={true}
              style={dateTimeInputStyle}
              format={dateTimeFormat}
              allowClear={false}
            />

            <CustomInput
              control={control}
              layout={layout}
              formLabel="Link"
              name="Link"
              maxLength={250}
            />

            <CustomInput
              control={control}
              layout={layout}
              formLabel="Link Text"
              name="LinkText"
              maxLength={100}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default AnnouncementForm;
