import { Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import DataDownloadLog from "./DataDownloadLog";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import { DownloadDocuments } from "../../shared/securityRoleTasks";
import {
  clearDataDownloadLog,
  clearDownloadRequestTab,
} from "../../../store/dataDownload/dataDownloadActions";
import SubmitDownloadRequest from "./SubmitDownloadRequest";

const Downloads = () => {
  //
  const dispatch = useDispatch();

  //
  const [downloadsActiveKey, setDownloadsActiveKey] = useState("1");

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const agenciesLoading = useSelector((state) => state.agency.loading);

  //
  const pageLoaded = brokerId != null && agencies != null && !agenciesLoading;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, DownloadDocuments));
    }
  }, [brokerId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearListCompactAction());
      dispatch(clearDataDownloadLog());
    };
  }, [dispatch]);

  const items = [
    {
      key: "1",
      label: `Data Download Log`,
      children: <DataDownloadLog agencies={agencies} />,
    },
    {
      key: "2",
      label: `Submit Download Request`,
      children: (
        <SubmitDownloadRequest
          agencies={agencies}
          resetActiveKey={() => {
            setDownloadsActiveKey("1");
          }}
        />
      ),
    },
  ];

  return (
    <PermissionWrapper entityName="DataDownload">
      <Spin spinning={!pageLoaded} delay={500}>
        {pageLoaded && (
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="small"
            items={items}
            onChange={(activeKey) => {
              if (activeKey == "1") {
                dispatch(clearDownloadRequestTab());
              }
              setDownloadsActiveKey(activeKey);
            }}
            activeKey={downloadsActiveKey}
          />
        )}
      </Spin>
    </PermissionWrapper>
  );
};
export default Downloads;
