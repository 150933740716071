import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnnouncementForm from "./AnnouncementForm";
import { Spin } from "antd";
import { announcementType } from "./announcementType";
import dayjs from "dayjs";
import { createAnnouncement } from "../../store/announcement/announcementActions";
import { useNavigate, useLocation } from "react-router";
import { toTZNeutralFormat } from "../shared/formatting";

const CreateAnnouncement = ({ parentRefreshHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    Type: announcementType.Information,
    Subject: "",
    Description: "",
    Link: "",
    LinkText: "",
    AnnounceOnDate: toTZNeutralFormat(dayjs()),
    ExpiryDate: toTZNeutralFormat(dayjs().add(1, "day")),
  };

  const announcement = useSelector((state) => state.announcement.selected);
  const loading = useSelector((state) => state.announcement.selectedLoading);

  const onSubmitHandler = (values) => {
    dispatch(createAnnouncement(values));
  };

  useEffect(() => {
    if (announcement) {
      navigate(`../updateannouncement/${announcement.Id}`, {
        state: location.state,
        replace: true,
      });
    }
  }, [announcement, navigate]);

  return (
    <Spin spinning={loading} delay={500}>
      <AnnouncementForm
        onSubmitHandler={onSubmitHandler}
        initialValues={initialValues}
        handleParentRefresh={parentRefreshHandler}
        isNew={true}
      />
    </Spin>
  );
};

export default CreateAnnouncement;
