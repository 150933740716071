import { Radio } from "antd";

export const basicRetentionReportFields = {
  AGENCY_NAME: "AgencyNameAndNo",
  PRODUCER_1_NAME: "ProducerName1",
  PRODUCER_2_NAME: "ProducerName2",
  PRODUCER_1_CODE: "ProducerCode1",
  PRODUCER_2_CODE: "ProducerCode2",
};

export const basicRetentionReportFieldOptions = [
  { label: "Agency", value: basicRetentionReportFields.AGENCY_NAME },
  {
    label: "Producer 1 Name",
    value: basicRetentionReportFields.PRODUCER_1_NAME,
  },
  {
    label: "Producer 2 Name",
    value: basicRetentionReportFields.PRODUCER_2_NAME,
  },
  {
    label: "Producer 1 Code",
    value: basicRetentionReportFields.PRODUCER_1_CODE,
  },
  {
    label: "Producer 2 Code",
    value: basicRetentionReportFields.PRODUCER_2_CODE,
  },
];

const retentionSummaryDrillDownFields = {
  CITY: "City",
  FLEET_GARAGE_NUMBER: "FleetGarageNumber",
};

export const renewalReportFields = {
  AGENCY_NAME: "AgencyName",
  PRODUCER_1_NAME: "ProducerName1",
  PRODUCER_2_NAME: "ProducerName2",
  PRODUCER_1_CODE: "ProducerCode1",
  PRODUCER_2_CODE: "ProducerCode2",
  CITY: "City",
  FLEET_GARAGE_NUMBER: "FleetGarageNumber",
  POSTAL_CODE_1ST_3_LETTERS: "PostalCode1st3",
  POSTAL_CODE: "PostalCode",
  RATE_CLASS: "RateClass",
  EXPIRY_DATE: "PolicyExpiryDate",
  STATUS: "PolicyStatusDesc",
  CUSTOMER_NAME: "CustomerName",
  PLATE_NUMBER: "PlateNumber",
  VEHICLE_MAKE: "Make",
  VEHICLE_YEAR: "VehicleYear",
  EFFECTIVE_DATE: "PolicyEffectiveDate",
};

const retentionSummaryExtraOptions = [
  {
    label: "Fleet/Garage Number",
    value: retentionSummaryDrillDownFields.FLEET_GARAGE_NUMBER,
  },
  {
    label: "City",
    value: retentionSummaryDrillDownFields.CITY,
  },
];

export const renewalReportSortGroupOptions = [
  { label: "Agency", value: renewalReportFields.AGENCY_NAME },
  {
    label: "Producer 1 Name",
    value: renewalReportFields.PRODUCER_1_NAME,
  },
  {
    label: "Producer 2 Name",
    value: renewalReportFields.PRODUCER_2_NAME,
  },
  {
    label: "Producer 1 Code",
    value: renewalReportFields.PRODUCER_1_CODE,
  },
  {
    label: "Producer 2 Code",
    value: renewalReportFields.PRODUCER_2_CODE,
  },
  {
    label: "Fleet/Garage Number",
    value: renewalReportFields.FLEET_GARAGE_NUMBER,
  },
  {
    label: "City",
    value: renewalReportFields.CITY,
  },
  {
    label: "Postal Code (1st 3 letters)",
    value: renewalReportFields.POSTAL_CODE_1ST_3_LETTERS,
  },
  {
    label: "Postal Code (Full)",
    value: renewalReportFields.POSTAL_CODE,
  },
  {
    label: "Rate Class",
    value: renewalReportFields.RATE_CLASS,
  },
  {
    label: "Expiry Date",
    value: renewalReportFields.EXPIRY_DATE,
  },
  {
    label: "Customer Name",
    value: renewalReportFields.CUSTOMER_NAME,
  },
  {
    label: "Plate Number ",
    value: renewalReportFields.PLATE_NUMBER,
  },
  {
    label: "Effective Date",
    value: renewalReportFields.EFFECTIVE_DATE,
  },
  {
    label: "Vehicle Year",
    value: renewalReportFields.VEHICLE_YEAR,
  },
  {
    label: "Vehicle Make",
    value: renewalReportFields.VEHICLE_MAKE,
  },
];

export const renewalStatusReportSortGroupOptions = [
  { label: "Agency", value: renewalReportFields.AGENCY_NAME },
  {
    label: "Status",
    value: renewalReportFields.STATUS,
  },
  {
    label: "Producer 1 Name",
    value: renewalReportFields.PRODUCER_1_NAME,
  },
  {
    label: "Producer 2 Name",
    value: renewalReportFields.PRODUCER_2_NAME,
  },
  {
    label: "Producer 1 Code",
    value: renewalReportFields.PRODUCER_1_CODE,
  },
  {
    label: "Producer 2 Code",
    value: renewalReportFields.PRODUCER_2_CODE,
  },
  {
    label: "Fleet/Garage Number",
    value: renewalReportFields.FLEET_GARAGE_NUMBER,
  },
  {
    label: "City",
    value: renewalReportFields.CITY,
  },
  {
    label: "Postal Code (1st 3 letters)",
    value: renewalReportFields.POSTAL_CODE_1ST_3_LETTERS,
  },
  {
    label: "Postal Code (Full)",
    value: renewalReportFields.POSTAL_CODE,
  },
  {
    label: "Rate Class",
    value: renewalReportFields.RATE_CLASS,
  },
  {
    label: "Expiry Date",
    value: renewalReportFields.EXPIRY_DATE,
  },
  {
    label: "Customer Name",
    value: renewalReportFields.CUSTOMER_NAME,
  },
  {
    label: "Plate Number ",
    value: renewalReportFields.PLATE_NUMBER,
  },
  {
    label: "Effective Date",
    value: renewalReportFields.EFFECTIVE_DATE,
  },
  {
    label: "Vehicle Year",
    value: renewalReportFields.VEHICLE_YEAR,
  },
  {
    label: "Vehicle Make",
    value: renewalReportFields.VEHICLE_MAKE,
  },
];

export const retentionSummaryDrillDownSortOptions =
  basicRetentionReportFieldOptions.concat(retentionSummaryExtraOptions);

export const renewalListReportTypes = {
  DETAIL: "Detail",
  COMPACT: "Compact",
  STATUS: "Status",
  MAILING_LABELS: "MailingLabels",
};

export const renewalListReportTypeOptions = [
  <Radio value={renewalListReportTypes.DETAIL} key="0">
    Detail
  </Radio>,
  <Radio value={renewalListReportTypes.COMPACT} key="1">
    Compact
  </Radio>,
  <Radio value={renewalListReportTypes.STATUS} key="2">
    Status
  </Radio>,
  <Radio value={renewalListReportTypes.MAILING_LABELS} key="3">
    Mailing Labels
  </Radio>,
];
