import * as yup from "yup";
import {
  requiredMessage,
  dateRangeRequiredMessage,
  lessOrEqualOneYearMessage,
} from "../../../components/shared/form/formValidatonRules";
import dayjs from "dayjs";

export const commissionReportSchema = yup.object().shape({
  AgencyKeys: yup
    .array()
    .required(requiredMessage)
    .min(1, requiredMessage)
    .nullable(),
  TransactionDate: yup
    .array()
    .required(dateRangeRequiredMessage)
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .nullable(),
  SortGroupFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
});
