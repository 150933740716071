import { customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { Actions } from "./manageRenewalsConstants";

export const clearManageRenewals = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_MANAGE_RENEWALS,
  });
};

const transformManageRenewalsSearchValues = (values, returnProducerLists) => {
  if (values == null) return null;
  let transformedValues = values;

  // Transform agency keys
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    transformedValues = {
      ...transformedValues,
      AgencyKeys: !agencies ? [] : agencies.map((a) => a.Id),
    };
  }

  // Format policy status
  let policyStatus = "";
  if (values.PolicyStatusActive === true) {
    policyStatus = "A";
  }
  if (values.PolicyStatusExpired === true) {
    policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "E";
  }
  if (values.PolicyStatusCancelled === true) {
    policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "C";
  }
  if (values.PolicyStatusRenewed === true) {
    policyStatus = policyStatus + (policyStatus.length > 0 ? "," : "") + "R";
  }

  transformedValues = {
    ...values,
    ReturnProducerLists: returnProducerLists,
    PolicyStatus: policyStatus.length > 0 ? policyStatus : "A",
  };
  return transformedValues;
};

export const listManageRenewals =
  (values, returnProducerLists) => async (dispatch) => {
    const searchValues = transformManageRenewalsSearchValues(
      values,
      returnProducerLists
    );

    dispatch({
      type: Actions.LIST_MANAGE_RENEWALS,
      payload: returnProducerLists,
    });

    const payload = await customPost(
      entityType.RETENTION,
      "ListManageRenewals",
      searchValues
    );
    const obj = {
      payload: payload,
      returnProducerLists: returnProducerLists,
    };

    dispatch({ type: Actions.LIST_MANAGE_RENEWALS_SUCCEEDED, payload: obj });
  };

export const clearSelectedManageRenewalsRows = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_ROWS,
  });
};

export const setSelectedManageRenewalsRows = (values) => async (dispatch) => {
  dispatch({
    type: Actions.SET_SELECTED_ROWS,
    payload: { selectedRows: values },
  });
};

export const bulkUpdateManageRenewalsCodes =
  (values) => async (dispatch, getState) => {
    const newValues = {
      ...values,
      RenewalKeys: getState().manageRenewals.selectedRows,
    };
    dispatch({ type: Actions.BULK_UPDATE_MANAGE_CODES });

    const payload = await customPost(
      entityType.RETENTION,
      "BulkUpdateProducerCodes",
      newValues
    );

    dispatch({ type: Actions.BULK_UPDATE_MANAGE_CODES_SUCCEEDED, payload });
  };

export const clearBulkUpdateManageRenewalsData = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_BULK_UPDATE_MANAGE_DATA,
  });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};
