import { Col, Descriptions, Row } from "antd";
import dayjs from "dayjs";
import { isEmpty, isNull, isUndefined } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { dateFormat } from "../../shared/formatting";

const NoExpiryDocumentSummary = ({ isNew = false }) => {
  const selected = useSelector((state) => state.document.selected);
  const maskSensitiveData =
    useSelector((state) => state.actor?.details?.data?.MaskSensitiveData) &&
    !isNew;

  const isBatched =
    selected &&
    !(isEmpty(selected.AgencyBatchNo) || isNull(selected.AgencyBatchNo));

  const icbcBatch =
    isNull(selected && selected.ICBCBatchNo) ||
    isUndefined(selected && selected.ICBCBatchNo)
      ? "No ICBC Batch"
      : selected.ICBCBatchNo;
  return (
    <Row>
      <Col span={24}>
        {selected && (
          <>
            <Descriptions size="small" column={2}>
              <Descriptions.Item label="Customer">
                <div
                  className={maskSensitiveData === true ? "blurry-text" : ""}
                >
                  {selected.InsuredName}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Product">
                {selected.Product}
              </Descriptions.Item>
              <Descriptions.Item label="Agency">
                {selected.AgencyNumberAndName}
              </Descriptions.Item>
              <Descriptions.Item label="Effective">
                {selected.PolicyEffectiveDate
                  ? dayjs(selected.PolicyEffectiveDate).format(dateFormat[0])
                  : "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Batch">
                {isBatched
                  ? `${selected.AgencyBatchNo}/${icbcBatch} ${dayjs(
                      selected.BatchSubmissionDate
                    ).format(dateFormat[0])}`
                  : "Not batched"}
              </Descriptions.Item>
              <Descriptions.Item label="Document Origin">
                {selected.IsDownloaded ? "ICBC Download" : "Manual Entry"}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Col>
    </Row>
  );
};

export default NoExpiryDocumentSummary;
