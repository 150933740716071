interface CustomExtrasProps {
  shouldThrow?: boolean;
  params?: URLSearchParams;
  identity?: boolean;
  blob?: boolean;
  file?: boolean;
}

type CustomGet = (
  entity: string,
  action: string,
  extras?: CustomExtrasProps
) => Promise<any>;

type CustomPost = (
  entity: string,
  action: string,
  data: any,
  extras?: CustomExtrasProps
) => Promise<any>;

export const customGet: CustomGet = async (entity, action, extras) => {
  const {
    shouldThrow = false,
    params = null,
    identity = false,
    blob = false,
  } = extras || {};

  const url =
    identity === true
      ? `${process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY}`
      : `${process.env.REACT_APP_API_URI}`;
  const parameters =
    params != null ? `?${new URLSearchParams(params).toString()}` : "";
  const uri = `${url}/${entity}/${action}${parameters}`;

  var result: any;
  try {
    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": blob === true ? "application/blob" : "application/json",
      },
    };

    const response = await fetch(uri, init);

    if (blob === true) {
      return response;
    } else {
      result = await response.json();
      return result;
    }
  } catch (e) {
    console.log(e);
    if (shouldThrow === true) {
      throw e;
    }
  }
  return result;
};

export const customPost: CustomPost = async (entity, action, data, extras) => {
  const {
    shouldThrow = false,
    params = null,
    identity = false,
    blob = false,
    file = false,
  } = extras || {};

  const url =
    identity === true
      ? `${process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY}`
      : `${process.env.REACT_APP_API_URI}`;
  const parameters =
    params != null ? `?${new URLSearchParams(params).toString()}` : "";
  const uri = `${url}/${entity}/${action}${parameters}`;

  var result: any;
  var transformedBody: any;
  try {
    if (file === true) {
      transformedBody = new FormData();
      transformedBody.append("file", data);
    } else {
      transformedBody = JSON.stringify(data);
    }

    const init: RequestInit = {
      method: "POST",
      headers:
        file === true
          ? undefined
          : {
              "Content-Type": "application/json",
            },
      body: transformedBody,
    };

    const response = await fetch(uri, init);

    if (blob === true || file === true) {
      return response;
    } else {
      result = await response.json();
      return result;
    }
  } catch (e) {
    console.log(e);
    if (shouldThrow === true) {
      throw e;
    }
  }
  return result;
};
