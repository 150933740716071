import React, { useState } from "react";
import { Button } from "antd";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { columnTypes } from "../../shared/table/columnTypes";
import { tableNames } from "../../shared/table/tableNames";
import {
  ReloadOutlined,
  FileExcelOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import {
  copyAndRemoveProperties,
  exportToExcel,
} from "../../shared/exportToExcel";
import { useNavigate } from "react-router";

const UnbatchedDocsTable = ({ data, loading, refreshDataHandler }) => {
  const navigate = useNavigate();
  const [exporting, setIsExporting] = useState(false);

  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.UnbatchedDocs)
  );

  const exportDataToExcel = () => {
    const cleanExportData = tableData?.extra.currentDataSource?.map((d) =>
      copyAndRemoveProperties(d, ["AgencyKey", "AgencyName", "AgencyNumber"])
    );
    setIsExporting(true);

    exportToExcel(cleanExportData, "UnbatchedDocs.csv");
    setIsExporting(false);
  };

  const columns = [
    {
      title: "Agency",
      dataIndex: "AgencyNumberAndName",
      type: columnTypes.TEXT_MULTI_FILTER,
      width: 80,
      filterOptions: data?.map((d) => d.AgencyNumberAndName),
    },
    {
      title: "Regular",
      dataIndex: "RegularDocumentsCount",
      type: columnTypes.NUMBER,
      width: 20,
      align: "right",
      render: (value) => value.toLocaleString(),
    },
    {
      title: "Multi-licence",
      dataIndex: "MultiLicenseDocumentsCount",
      type: columnTypes.NUMBER,
      width: 20,
      align: "right",
      render: (value) => value.toLocaleString(),
    },
  ];
  return (
    <SearchableTable
      sticky
      scroll={{
        y: 550,
      }}
      buttons={[
        <Button
          size={"small"}
          disabled={loading}
          onClick={() => refreshDataHandler()}
        >
          <ReloadOutlined />
          Refresh Table
        </Button>,
        <Button
          size={"small"}
          disabled={loading || isEmpty(data)}
          loading={exporting}
          onClick={() => exportDataToExcel()}
        >
          <FileExcelOutlined />
          Export
        </Button>,
        <Button
          size={"small"}
          disabled={loading || isEmpty(data)}
          loading={exporting}
          onClick={() => navigate("/createBatchForm")}
        >
          <PlusSquareOutlined />
          Process New Batch
        </Button>,
      ]}
      dataSource={data}
      bordered={true}
      columns={columns}
      loading={loading}
      size="small"
      rowKey={(r) => r.AgencyKey}
      tableName={tableNames.UnbatchedDocs}
    />
  );
};

export default UnbatchedDocsTable;
