import * as yup from "yup";
import { requiredMessage } from "../../../components/shared/form/formValidatonRules";

const searchByFormSchema = yup.object().shape({
  SearchValue: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(2, "Shorter than 2 characters")
    .max(6, "Longer than 6 characters")
    .when("AllocatedToAgentKey", {
      is: (val) => val == null,
      then: (schema) => schema.required(requiredMessage),
    }),
  AllocatedToAgentKey: yup.number().nullable(),
});

export default searchByFormSchema;
