import { FileTextOutlined } from "@ant-design/icons";
import { Col, Divider, Drawer, Empty, Row, Space } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  clearItemsListAction,
  clearOperationResultAction,
  clearSelectedInventoryItemAction,
  findInventoryAction,
  listItemHistoryAction,
  setSelectedInventoryItemAction,
} from "../../../store/inventory/inventoryActions";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { inventoryItemColumns } from "./inventoryItemColumns";
import ItemHistoryTable from "./ItemHistoryTable";
import SearchByForm from "./SearchByForm";
import { tableNames } from "../../shared/table/tableNames";

const InventoryItems = () => {
  const [clearAll, setClearAll] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const dispatch = useDispatch();
  const onSubmitHandler = (values) => dispatch(findInventoryAction(values));
  const onResetHandler = () => dispatch(clearItemsListAction());
  const inventoryState = useSelector((state) => state.inventory);
  const operationResult = inventoryState.operationResult;
  const searchText =
    inventoryState.inventoryItems.searchCriteria &&
    inventoryState.inventoryItems.searchCriteria.SearchValue;
  const list = inventoryState.inventoryItems.data;
  const loading = inventoryState.inventoryItems.loading;
  const selected = inventoryState.inventoryItems.selected.item;
  const selectedHistory = inventoryState.inventoryItems.selected.history;

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult, dispatch]);

  const emptyTableMessage = isEmpty(searchText)
    ? "No data"
    : `No matches found. Please review your search criteria and try again.`;

  useEffect(() => {
    const cleanup = async () => {
      dispatch(clearItemsListAction());
    };
    cleanup();
  }, [dispatch]);

  const viewHistoryOnCloseHandler = () => {
    setViewHistory(false);
    dispatch(clearSelectedInventoryItemAction());
  };

  const viewHistoryHandler = (record) => {
    dispatch(setSelectedInventoryItemAction(record));
    dispatch(listItemHistoryAction(record.InventoryItemKey));
  };

  return (
    <div>
      <Drawer
        title={
          <Space>
            <FileTextOutlined />
            History:
            {selected &&
              `Item ${selected.ItemNumber} - ${selected.ItemDescription}`}
          </Space>
        }
        width={640}
        onClose={viewHistoryOnCloseHandler}
        open={viewHistory}
        bodyStyle={{ paddingTop: 4 }}
      >
        <ItemHistoryTable list={selectedHistory} loading={loading} />
      </Drawer>
      <Routes>
        <Route
          path=""
          element={
            <>
              <Row style={{ paddingTop: 4 }}>
                <Col span={24}>
                  <SearchByForm
                    onSubmitHandler={onSubmitHandler}
                    onResetHandler={onResetHandler}
                    loading={loading}
                    setClearAll={setClearAll}
                  />
                </Col>
              </Row>
              <Divider style={{ marginBottom: 8 }} />
              <Row>
                <Col>
                  <SearchableTable
                    tableName={tableNames.InventoryItemsSearch}
                    setClearAll={setClearAll}
                    clearAll={clearAll}
                    scroll={{ y: 550, x: 1230 }}
                    sticky
                    dataSource={list}
                    columns={inventoryItemColumns(
                      searchText,
                      setViewHistory,
                      viewHistoryHandler
                    )}
                    loading={loading}
                    size="small"
                    rowKey={(r) => r.InventoryItemKey}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={emptyTableMessage}
                        />
                      ),
                    }}
                  />
                </Col>
              </Row>
            </>
          }
        />
      </Routes>
    </div>
  );
};
export default InventoryItems;
