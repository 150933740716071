import { Col, Row, Tabs } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLoanCalculator } from "../../store/loanCalculator/loanCalculatorActions";
import {
  clearOperationResult,
  resetReportState,
} from "../../store/report/reportActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import { PdfViewerModal } from "../shared/PdfViewer";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import LoanInformationPanel from "./LoanInformationPanel";
import { POPAuthorizationForm } from "./POPAuthorizationForm";
import PaymentSchedule from "./PaymentSchedule";
import PaymentTable from "./PaymentTable";
import { loanTypes } from "./constants";

const LoanCalculator = () => {
  //
  const dispatch = useDispatch();

  //
  const reportOperationResult = useSelector(
    (state) => state.report.operationResult
  );
  const calculateLoanData = useSelector(
    (state) => state.loanCalculator.paymentDetails?.header
  );

  //
  useEffect(() => {
    dispatch(clearLoanCalculator());
    dispatch(resetReportState());
    return () => {
      dispatch(clearLoanCalculator());
    };
  }, []);

  useEffect(() => {
    openNotificationOperationResult(reportOperationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [reportOperationResult, dispatch]);

  //
  const tabsItems = useMemo(
    () => [
      {
        key: "1",
        label: `Payment Details`,
        children: <PaymentSchedule />,
      },
      calculateLoanData?.LoanType === loanTypes.NEW
        ? {
            key: "2",
            label: `POP Authorization Form`,
            children: <POPAuthorizationForm />,
          }
        : null,
    ],
    [calculateLoanData]
  );

  return (
    <PermissionWrapper entityName="LoanCalculator">
      <Row>
        <Col span={24}>
          <LoanInformationPanel />
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {calculateLoanData && (
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="small"
              items={tabsItems}
            />
          )}
        </Col>
      </Row>
      <PdfViewerModal />
    </PermissionWrapper>
  );
};
export default LoanCalculator;
