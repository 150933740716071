import LoanCalculator from "../loanCalculator/LoanCalculator";
import * as Roles from "../shared/securityRoleTasks";

const getMenuItemVisibility = (actor) => {
  let visibility = {};

  if (actor) {
    visibility = {
      Documents:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ManageDocuments) ||
        actor.Tasks.includes(Roles.ViewDocuments),
      AddDocument: actor.IsAdmin || actor.Tasks.includes(Roles.ManageDocuments),
      Commission:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ManageCommissions) ||
        actor.Tasks.includes(Roles.ViewCommissionsFullAccess) ||
        actor.Tasks.includes(Roles.ViewCommissionsLimitedAccess),
      FleetGarageCommission:
        actor.IsAdmin ||
        (actor.DisplayFleetGarageCommission &&
          actor.Tasks.includes(Roles.ViewCommissionsFullAccess)),
      FleetPolicyToClientMap:
        actor.IsAdmin ||
        (actor.DisplayFleetGarageCommission &&
          actor.Tasks.includes(Roles.AdminTasks)),
      Batching:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ManageDocuments) ||
        actor.Tasks.includes(Roles.ViewDocuments),
      AddBatch: actor.IsAdmin || actor.Tasks.includes(Roles.ManageDocuments),
      Inventory:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ManageInventory) ||
        actor.Tasks.includes(Roles.ViewInventory),
      SearchInventory:
        actor.IsAdmin || actor.Tasks.includes(Roles.ViewInventory),
      MoveInventory:
        actor.IsAdmin || actor.Tasks.includes(Roles.ManageInventory),
      SearchMovements:
        actor.IsAdmin || actor.Tasks.includes(Roles.ManageInventory),
      MaintainInventory:
        actor.IsAdmin || actor.Tasks.includes(Roles.ManageInventory),
      InventoryReports:
        actor.IsAdmin || actor.Tasks.includes(Roles.ViewInventory),
      AuditInventoryReport:
        actor.IsAdmin || actor.Tasks.includes(Roles.ManageInventory),
      Renewals:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ViewRenewalData) ||
        actor.Tasks.includes(Roles.ViewRetentionData),
      RenewalsReport:
        actor.IsAdmin || actor.Tasks.includes(Roles.ViewRenewalData),
      RetentionReport:
        actor.IsAdmin || actor.Tasks.includes(Roles.ViewRetentionData),
      Reports:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ViewProductivityDataFullAccess) ||
        actor.Tasks.includes(Roles.ViewProductivityDataLimitedAccess) ||
        actor.Tasks.includes(Roles.ViewDealerBillingData) ||
        actor.Tasks.includes(Roles.ReportCustomerDataCollection) ||
        actor.Tasks.includes(Roles.ViewRetentionData) ||
        actor.Tasks.includes(Roles.ReportScheduleOfVehicles) ||
        actor.Tasks.includes(Roles.ReportConfirmationOfInsurance),
      ProductionReports:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ViewProductivityDataFullAccess) ||
        actor.Tasks.includes(Roles.ViewProductivityDataLimitedAccess),
      ProductionSummaryByDayHourReport:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ViewProductivityDataFullAccess),
      CoverageSummaryReport:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ViewProductivityDataFullAccess),
      DealerBilling:
        actor.IsAdmin || actor.Tasks.includes(Roles.ViewDealerBillingData),
      DataCollectionReport:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ReportCustomerDataCollection),
      TextSummaryByReplyType:
        actor.IsAdmin || actor.Tasks.includes(Roles.ViewRetentionData),
      ScheduleOfVehicles:
        actor.IsAdmin || actor.Tasks.includes(Roles.ReportScheduleOfVehicles),
      ConfirmationOfInsurance:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ReportConfirmationOfInsurance),
      Admin:
        actor.IsAdmin ||
        actor.Tasks.includes(Roles.ManageAgencies) ||
        actor.Tasks.includes(Roles.AdminTasks),
      Agencies: actor.IsAdmin || actor.Tasks.includes(Roles.ManageAgencies),
      CommissionRates: actor.IsAdmin || actor.Tasks.includes(Roles.AdminTasks),
      Producers: actor.IsAdmin || actor.Tasks.includes(Roles.AdminTasks),
      DataDownload:
        actor.IsAdmin || actor.Tasks.includes(Roles.DownloadDocuments),
      OnlineRenewals:
        actor.IsAdmin || actor.Tasks.includes(Roles.UpdateDocuments),
      Announcements: actor.IsAdmin,
      AppLogs: actor.IsAdmin,
      ManageRenewals:
        actor.IsAdmin || actor.Tasks.includes(Roles.UpdateRenewalData),
      MoveDocuments: actor.IsAdmin || actor.DisplayMoveDocuments,
      LoanCalculator:
        actor.IsAdmin || actor.Tasks.includes(Roles.RunLoanCalculator),
    };
  }

  return visibility;
};

export default getMenuItemVisibility;
