import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Row,
  Space,
  Spin,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../store/report/reportActions";
import { transformRetentionSummaryInput } from "../../store/retention/retentionActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import PdfViewer from "../shared/PdfViewer";
import ReportParamPanelHeader from "../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import DrillDownSortFieldSelectEx from "../shared/form/DrillDownSortFieldSelectEx";
import ExtraReportFilterContainer from "../shared/form/ExtraReportFiltersContainer";
import ProducerSelectEx from "../shared/form/ProducerSelectEx";
import { fixedDateRanges } from "../shared/form/fixedDateRanges";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import CustomCheckbox from "../shared/form/reactHookForm/CustomCheckbox";
import { dateFormat, toTZNeutralFormat } from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { ViewRetentionData } from "../shared/securityRoleTasks";
import { agenciesListEqual } from "../shared/sorting";
import { retentionSummaryDrillDownSortOptions } from "./reportParameters";
import { retentionSummaryReportSchema } from "./reportSchema";
import { listVehicleTypesAction } from "../../store/vehicle/vehicleActions";
import SearchMultiSelectEx from "../shared/form/SearchMultiSelectEx";
import { isNull } from "lodash";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKeys: [],
  FixedDateRange: fixedDateRanges.LAST_MONTH,
  ExpiryDate: ["", ""],
  IncludeLateRenewals: false,
  IncludeNonFleetNonGarage: true,
  IncludeFleetGarage: false,
  DrillDownSortFields: [],
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
  PolicyStatusActive: true,
  PolicyStatusExpired: true,
  PolicyStatusCancelled: true,
  PolicyStatusRenewed: true,
  IncludeAutoplanPolicies: true,
  IncludeSpecialCoveragePolicies: true,
  VehicleTypes: [],
};

const colSize = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 12,
  xxl: 12,
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const specialLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 9 },
    xl: { span: 7 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 15 },
    xl: { span: 17 },
    xxl: { span: 19 },
  },
};

const reportName = "RetentionSummaryReport";

const RetentionSummaryReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(retentionSummaryReportSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const reportState = useSelector((state) => state.report);
  const isExporting = reportState.isExporting;
  const operationResult = reportState.operationResult;
  const isViewerVisible = reportState.isViewerVisible;
  const isFetching = reportState.isFetching;

  const vehicleTypes = useSelector((state) => state.vehicle.data);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewRetentionData));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: [initialAgency],
        ExpiryDate: [
          toTZNeutralFormat(dayjs().subtract(1, "month").startOf("month")),
          toTZNeutralFormat(dayjs().subtract(1, "month").endOf("month")),
        ],
      };

      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  useEffect(() => {
    if (isNull(vehicleTypes)) {
      dispatch(listVehicleTypesAction());
    }
  }, [vehicleTypes]);

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformRetentionSummaryInput(values),
        entityType.RETENTION,
        `PrintRetentionSummary`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="RetentionReport">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col {...colSize}>
                      <AgencyMultiSelectEx
                        layout={specialLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                      />
                    </Col>
                    <Col {...colSize}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        disableFutureDates={true}
                        name="ExpiryDate"
                        format={dateFormat}
                        disabledDate={(
                          current // Should we allow users to select over a year?
                        ) => current && current > dayjs().endOf("day")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSize}>
                      <Form.Item
                        labelCol={{
                          xs: { span: 5 },
                          sm: { span: 7 },
                          md: { span: 6 },
                          lg: { span: 9 },
                          xl: { span: 7 },
                          xxl: { span: 5 },
                        }}
                        wrapperCol={{
                          xs: { span: 19 },
                          sm: { span: 17 },
                          md: { span: 18 },
                          lg: { span: 15 },
                          xl: { span: 17 },
                          xxl: { span: 19 },
                        }}
                        label="Policy Type"
                        name="PolicyType"
                        required
                      >
                        <Space direction="vertical">
                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            formStyle={{ marginBottom: 0 }}
                            name="IncludeAutoplanPolicies"
                            onChange={(e) => {
                              if (
                                !e.target.checked &&
                                !getValues().IncludeSpecialCoveragePolicies
                              ) {
                                setValue(
                                  "IncludeSpecialCoveragePolicies",
                                  true,
                                  {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  }
                                );
                              }
                            }}
                          >
                            Autoplan Policies
                          </CustomCheckbox>

                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            formStyle={{ marginBottom: 0 }}
                            name="IncludeSpecialCoveragePolicies"
                            onChange={(e) => {
                              if (
                                !e.target.checked &&
                                !getValues().IncludeAutoplanPolicies
                              ) {
                                setValue("IncludeAutoplanPolicies", true, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }
                            }}
                          >
                            Special Coverage Policies
                          </CustomCheckbox>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col {...colSize}>
                      <Form.Item
                        {...specialLayout}
                        label="Include"
                        name="Include"
                        required
                      >
                        <Space direction="vertical">
                          <CustomCheckbox
                            formStyle={{ marginBottom: 0 }}
                            control={control}
                            formLabel=""
                            name="IncludeNonFleetNonGarage"
                            onChange={(e) => {
                              if (
                                !e.target.checked &&
                                !getValues().IncludeFleetGarage
                              ) {
                                setValue("IncludeFleetGarage", true, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }
                            }}
                          >
                            Non-Fleet and Non-Garage Policies
                          </CustomCheckbox>

                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            formStyle={{ marginBottom: 0 }}
                            name="IncludeFleetGarage"
                            onChange={(e) => {
                              if (
                                !e.target.checked &&
                                !getValues().IncludeNonFleetNonGarage
                              ) {
                                setValue("IncludeNonFleetNonGarage", true, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }
                            }}
                          >
                            Fleet and Garage Policies
                          </CustomCheckbox>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSize}>
                      <CustomCheckbox
                        control={control}
                        layout={defaultLayout}
                        formLabel="Include"
                        name="IncludeLateRenewals"
                        formTooltip="Include renewals processed within 30 days after policy expiry date"
                        disabled={dayjs(getValues().ExpiryDate[1]) > dayjs()}
                      >
                        Late Renewals (up to 30 days)
                      </CustomCheckbox>
                    </Col>
                    <Col {...colSize}>
                      <Form.Item
                        labelCol={{
                          xs: { span: 5 },
                          sm: { span: 7 },
                          md: { span: 6 },
                          lg: { span: 8 },
                          xl: { span: 6 },
                          xxl: { span: 5 },
                        }}
                        wrapperCol={{
                          xs: { span: 19 },
                          sm: { span: 17 },
                          md: { span: 18 },
                          lg: { span: 16 },
                          xl: { span: 18 },
                          xxl: { span: 19 },
                        }}
                        label="Policy Status"
                        name="PolicyStatus"
                        required
                        valuePropName="checked"
                      >
                        <Space direction="horizontal">
                          <Space direction="vertical">
                            <Controller
                              control={control}
                              name="PolicyStatusActive"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusCancelled &&
                                      !currentValues.PolicyStatusExpired &&
                                      !currentValues.PolicyStatusRenewed;

                                    if (!isAllPoliciesUnchecked) {
                                      setValue(
                                        "PolicyStatusActive",
                                        isChecked,
                                        {
                                          shouldValidate: true,
                                          shouldDirty: true,
                                        }
                                      );
                                    }
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusActive"
                                >
                                  Active
                                </Checkbox>
                              )}
                            />
                            <Controller
                              control={control}
                              name="PolicyStatusExpired"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusCancelled &&
                                      !currentValues.PolicyStatusActive &&
                                      !currentValues.PolicyStatusRenewed;

                                    if (isAllPoliciesUnchecked) {
                                      setValue("PolicyStatusActive", true, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      });
                                    }
                                    setValue("PolicyStatusExpired", isChecked, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusExpired"
                                >
                                  Expired
                                </Checkbox>
                              )}
                            />
                          </Space>
                          <Space direction="vertical">
                            <Controller
                              control={control}
                              name="PolicyStatusRenewed"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusCancelled &&
                                      !currentValues.PolicyStatusActive &&
                                      !currentValues.PolicyStatusExpired;

                                    if (isAllPoliciesUnchecked) {
                                      setValue("PolicyStatusActive", true, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      });
                                    }
                                    setValue("PolicyStatusRenewed", isChecked, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusRenewed"
                                >
                                  Renewed
                                </Checkbox>
                              )}
                            />
                            <Controller
                              control={control}
                              name="PolicyStatusCancelled"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusExpired &&
                                      !currentValues.PolicyStatusActive &&
                                      !currentValues.PolicyStatusRenewed;

                                    if (isAllPoliciesUnchecked) {
                                      setValue("PolicyStatusActive", true, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      });
                                    }
                                    setValue(
                                      "PolicyStatusCancelled",
                                      isChecked,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      }
                                    );
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusCancelled"
                                >
                                  Cancelled
                                </Checkbox>
                              )}
                            />
                          </Space>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <ExtraReportFilterContainer>
                    <Row>
                      <Col {...colSize}>
                        <Form.Item
                          name="ProducerCode1Keys"
                          label="Producer 1"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode1Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={1}
                            taskKey={ViewRetentionData}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ProducerCode2Keys"
                          label="Producer 2"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode2Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={2}
                            taskKey={ViewRetentionData}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colSize}>
                        <SearchMultiSelectEx
                          allowClear
                          control={control}
                          layout={defaultLayout}
                          formLabel="Vehicle Type(s)"
                          name="VehicleTypes"
                          data={vehicleTypes ?? []}
                          setValue={setValue}
                          maxTagCount={4}
                        />
                      </Col>
                    </Row>
                  </ExtraReportFilterContainer>

                  <Row>
                    <Col span={24}>
                      <DrillDownSortFieldSelectEx
                        fieldOptions={retentionSummaryDrillDownSortOptions}
                        control={control}
                        trigger={trigger}
                        isSubmitted={isSubmitted}
                      />
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformRetentionSummaryInput(getValues()),
                              reportName,
                              entityType.RETENTION
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          reset(initialDefaultValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default RetentionSummaryReport;
