import {
  ShopOutlined,
  UserOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Col,
  Dropdown,
  Row,
  Space,
  Typography,
  Drawer,
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useCustomOidc } from "./oidc/customOidcUtils";
import { useOidcUser } from "@axa-fr/react-oidc";
import HelpContent from "./HelpContent";
import { SwitchBroker } from "./SwitchBroker";

const { Text } = Typography;
const ActorMenu = () => {
  const [helpContentOpen, setHelpContentOpen] = useState(false);
  const [switchSupportOpen, setSwitchSupportOpen] = useState(false);
  //
  const { logout } = useCustomOidc();
  const { oidcUser } = useOidcUser();

  //
  const actor = useSelector((state) => state.actor);
  const actorDetails = actor.details.data;
  const brokerTextFull = actorDetails ? (
    <>
      <div style={{ height: "64px", width: "16px", paddingTop: "1px" }}>
        <ShopOutlined
          style={{
            fontSize: "16px",
            color: "#1c4658",
            strokeWidth: "30",
            stroke: "#1c4658",
          }}
        />
      </div>
      <Text> {actorDetails.AgencyFullName}</Text>
    </>
  ) : (
    ""
  );

  const brokerTextReduced = actorDetails ? (
    <>
      <div style={{ height: "64px", width: "16px", paddingTop: "1px" }}>
        <ShopOutlined
          style={{
            fontSize: "16px",
            color: "#1c4658",
            strokeWidth: "30",
            stroke: "#1c4658",
          }}
        />
      </div>

      <Text>{" ... "}</Text>
    </>
  ) : (
    ""
  );

  const menu = Boolean(actorDetails?.IsAdmin)
    ? {
        items: [
          {
            key: "switch-broker",
            label: "Switch Broker",
          },

          {
            key: "logout-button",
            label: "Logout",
          },
        ],
        onClick: ({ key }) => {
          if (key === "logout-button") {
            logout({ hasPressedLogout: true });
          } else if (key === "switch-broker") {
            setSwitchSupportOpen(true);
          }
        },
      }
    : {
        items: [
          {
            key: "logout-button",
            label: "Logout",
          },
        ],
        onClick: ({ key }) => {
          if (key === "logout-button") {
            logout({ hasPressedLogout: true });
          }
        },
      };
  const helpButton = (
    <>
      <div style={{ height: "64px", width: "22px", paddingTop: "2px" }}>
        <QuestionCircleTwoTone
          onClick={() => setHelpContentOpen(true)}
          style={{
            fontSize: "20px",
          }}
          twoToneColor={"#ec3a00"}
        />
      </div>
    </>
  );

  return (
    <>
      <Drawer
        open={helpContentOpen}
        onClose={() => setHelpContentOpen(false)}
        width={450}
        title={"Contact Support"}
      >
        <HelpContent />
      </Drawer>

      <Drawer
        open={switchSupportOpen}
        onClose={() => setSwitchSupportOpen(false)}
        width={450}
        title={"Switch Broker"}
        destroyOnClose={true}
      >
        <SwitchBroker
          onCloseHandler={() => setSwitchSupportOpen(false)}
          focusOnOpen={switchSupportOpen}
        />
      </Drawer>
      <Row justify="end" style={{ paddingRight: "7px" }}>
        <Col>
          <Row>
            <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
              <Space>
                {process.env.REACT_APP_ENV !== "production" && (
                  <Alert
                    message={`${process.env.REACT_APP_ENV} environment`}
                    showIcon
                    type="warning"
                  />
                )}
                {brokerTextFull}
                <Dropdown menu={menu}>
                  <Button
                    style={{ padding: "3px 11px 5px" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <Space>
                      <div
                        style={{
                          height: "22px",
                          width: "22px",
                        }}
                      >
                        <Avatar
                          size="small"
                          style={{
                            backgroundColor: "#ec3a00",
                            verticalAlign: "middle",
                            height: "100% !important",
                            width: "100% !important",
                            padding: "0",
                            bottom: "1px",
                          }}
                          icon={<UserOutlined />}
                        />
                      </div>

                      {oidcUser?.name ?? ""}
                    </Space>
                  </Button>
                </Dropdown>
                {helpButton}
              </Space>
            </Col>
            <Col xs={0} sm={0} md={0} lg={24} xl={0} xxl={0}>
              <Space>
                {brokerTextFull}
                <Dropdown menu={menu}>
                  <Button
                    style={{ padding: "3px 11px 5px" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <Space>
                      <div style={{ height: "22px", width: "22px" }}>
                        <Avatar
                          size="small"
                          style={{
                            backgroundColor: "#ec3a00",
                            verticalAlign: "middle",
                            height: "100% !important",
                            width: "100% !important",
                            padding: "0",
                            bottom: "1px",
                          }}
                          icon={<UserOutlined />}
                        />
                      </div>

                      {oidcUser?.name ?? ""}
                    </Space>
                  </Button>
                </Dropdown>
                {helpButton}
              </Space>
            </Col>
            <Col xs={0} sm={24} md={24} lg={0} xl={0} xxl={0}>
              <Space>
                {brokerTextReduced}
                <Dropdown menu={menu}>
                  <Button
                    style={{
                      padding: "3px 11px 5px",
                      maxWidth: "calc(100vw - 275px)",
                    }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <Space>
                      <div style={{ height: "22px", width: "22px" }}>
                        <Avatar
                          size="small"
                          style={{
                            backgroundColor: "#ec3a00",
                            verticalAlign: "middle",
                            height: "100% !important",
                            width: "100% !important",
                            padding: "0",
                            bottom: "1px",
                          }}
                          icon={<UserOutlined />}
                        />
                      </div>

                      {oidcUser?.name ?? ""}
                    </Space>
                  </Button>
                </Dropdown>
                {helpButton}
              </Space>
            </Col>
            <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
              <Dropdown menu={menu}>
                <Button
                  style={{
                    padding: "3px 11px 5px",
                    maxWidth: "calc(100vw - 215px)",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Space>
                    <div style={{ height: "22px", width: "22px" }}>
                      <Avatar
                        size="small"
                        style={{
                          backgroundColor: "#ec3a00",
                          verticalAlign: "middle",
                          height: "100% !important",
                          width: "100% !important",
                          padding: "0",
                          bottom: "1px",
                        }}
                        icon={<UserOutlined />}
                      />
                    </div>
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "calc(100vw - 275px)",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {oidcUser?.name ?? ""}
                    </div>
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ActorMenu;
