import { InfoCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { isEmpty, isNull, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { listAgenciesCompactForBatchingAction } from "../../../store/agency/agencyActions";
import {
  clearDocumentsInclusionStateAction,
  clearMultiLicencePoliciesAction,
  clearNewBatchDataAction,
  clearSelectedBatchDocumentKeysAction,
  getNextBatchNumberAction,
  listMultiLicencePoliciesAction,
  setNewBatchCriteriaAction,
  setProcessBatchCurrentStepAction,
} from "../../../store/batching/batchingActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { mediumInputStyle } from "../../shared/form/formInputStyles";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import {
  dateFormat,
  toEndOfDay,
  toTZNeutralFormat,
} from "../../shared/formatting";
import { ManageDocuments } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import MultiLicencePolicies from "../table/MultiLicencePolicies";
import { createBatchStep1Schema } from "./batchSchemas";
import batchTypes from "./batchTypes";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";

const { Text } = Typography;
const { Panel } = Collapse;

const RegularBatchType = "Regular";
const MultilicenceBatchType = "Multilicence";

const initialValues = {
  AgencyKey: "",
  BatchType: RegularBatchType,
  SubmissionDate: "",
};

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 9 },
    xl: { span: 8 },
    xxl: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 15 },
    xl: { span: 16 },
    xxl: { span: 15 },
  },
};

const CreateBatchStep1 = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(createBatchStep1Schema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const batchingState = useSelector((state) => state.batching);
  const list = batchingState.newBatch.multiLicencePolicies.data;
  const loading = batchingState.newBatch.multiLicencePolicies.loading;
  const nextBatchNumber = batchingState.newBatch.nextBatchNumber;
  const selectedMultiLicencePolicies =
    batchingState.newBatch.multiLicencePolicies.selectedRows;
  const criteria = batchingState.newBatch.criteria;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactForBatchingAction(brokerId, ManageDocuments));
    }
  }, [brokerId]);

  useEffect(() => {
    if (!isNull(criteria) && defaultValues.AgencyKey == "") {
      reset(criteria);
    }
  }, [criteria]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      if (isNull(criteria)) {
        var initialAgency = getInitialAgency(homeAgency, agencies);
        reset({ ...initialValues, AgencyKey: initialAgency });
      }
    }
  }, [agencies, homeAgency]);

  const onSubmissionDateChangeHandler = (date) => {
    var values = getValues();
    if (!isUndefined(values)) {
      if (!isNull(values.AgencyKey) && !isUndefined(values.AgencyKey)) {
        dispatch(
          getNextBatchNumberAction({
            AgencyKey: values.AgencyKey,
            SubmissionDate: date,
          })
        );
      }
      listMultiLicencePolicies({
        SubmissionDate: date,
        AgencyKey: values.AgencyKey,
        BatchType: values.BatchType,
      });
    }
  };

  const onAgencyChangeHandler = (agencyKey) => {
    var values = getValues();
    if (!isUndefined(values)) {
      var submissionDate = toEndOfDay(values.SubmissionDate);
      if (submissionDate != "") {
        dispatch(
          getNextBatchNumberAction({
            AgencyKey: agencyKey,
            SubmissionDate: submissionDate,
          })
        );
      }
      listMultiLicencePolicies({
        SubmissionDate: submissionDate,
        AgencyKey: agencyKey,
        BatchType: getValues().BatchType,
      });
    }
  };

  const listMultiLicencePolicies = (data) => {
    dispatch(setNewBatchCriteriaAction({ ...data }));
    if (
      data.BatchType === MultilicenceBatchType &&
      !isNull(data.AgencyKey) &&
      !isUndefined(data.AgencyKey) &&
      data.SubmissionDate != ""
    ) {
      dispatch(
        listMultiLicencePoliciesAction({
          AgencyKey: data.AgencyKey,
          SubmissionDate: data.SubmissionDate,
        })
      );
    } else {
      dispatch(clearMultiLicencePoliciesAction());
    }
  };

  const onSubmitHandler = (values) => {
    dispatch(clearNewBatchDataAction());
    dispatch(clearDocumentsInclusionStateAction());
    dispatch(clearSelectedBatchDocumentKeysAction());
    dispatch(
      setNewBatchCriteriaAction({
        AgencyKey: values.AgencyKey,
        BatchType: values.BatchType,
        ToCreatedDate: toEndOfDay(values.SubmissionDate),
        FromCreatedDate: toTZNeutralFormat(
          agencies?.find((a) => a.Id === values.AgencyKey)
            ?.UnbatchedDocumentsStartingDate
        ),
        AgencyBatch: nextBatchNumber,
        ICBCBatch: isNull(criteria) ? "" : criteria.ICBCBatch,
        SubmissionDate: toEndOfDay(values.SubmissionDate),
        HideExcludedDocuments: false,
        FleetGaragePolicyNumbers:
          values.BatchType === MultilicenceBatchType
            ? selectedMultiLicencePolicies
            : [],
      })
    );
    dispatch(setProcessBatchCurrentStepAction(1));
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandler)()}
          >
            <Collapse defaultActiveKey={[1, 2]}>
              <Panel key={1} header="Batch Criteria">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                    <AgencySelectEx
                      layout={{
                        labelCol: {
                          xs: { span: 24 },
                          sm: { span: 7 },
                          md: { span: 6 },
                          lg: { span: 9 },
                          xl: { span: 8 },
                          xxl: { span: 4 },
                        },
                        wrapperCol: {
                          xs: { span: 24 },
                          sm: { span: 17 },
                          md: { span: 18 },
                          lg: { span: 15 },
                          xl: { span: 16 },
                          xxl: { span: 18 },
                        },
                      }}
                      formLabel="Agency"
                      name="AgencyKey"
                      required={true}
                      autoFocus={true}
                      formStyle={{ marginBottom: 0 }}
                      agencies={currentAgencies}
                      isLoading={defaultValues.AgencyKey === ""}
                      onChange={(value) => onAgencyChangeHandler(value)}
                      control={control}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                    <Form.Item
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 7 },
                        md: { span: 6 },
                        lg: { span: 9 },
                        xl: { span: 8 },
                        xxl: { span: 8 },
                      }}
                      wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 17 },
                        md: { span: 18 },
                        lg: { span: 15 },
                        xl: { span: 16 },
                        xxl: { span: 16 },
                      }}
                      label="Batch Type"
                      name="BatchType"
                      required
                      style={{ marginBottom: 0 }}
                    >
                      <Controller
                        control={control}
                        name="BatchType"
                        render={({ field }) => (
                          <Radio.Group
                            {...field}
                            name="BatchType"
                            onChange={(e) => {
                              field.onChange(e);
                              listMultiLicencePolicies({
                                BatchType: e.target.value,
                                AgencyKey: getValues().AgencyKey,
                                SubmissionDate: toEndOfDay(
                                  getValues().SubmissionDate
                                ),
                              });
                            }}
                            options={batchTypes}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                    <CustomDatePicker
                      formItem={{
                        layout: layout,
                        label: "Submission Date",
                        style: { marginBottom: 0 },
                        required: true,
                      }}
                      control={control}
                      name="SubmissionDate"
                      style={mediumInputStyle}
                      format={dateFormat}
                      disabledDate={(current) =>
                        current && current > dayjs().endOf("day")
                      }
                      onChange={(datestring) => {
                        if (datestring != "") {
                          onSubmissionDateChangeHandler(datestring);
                        }
                      }}
                    />

                    <Row>
                      <Col
                        xs={{ offset: 0 }}
                        sm={{ offset: 7 }}
                        md={{ offset: 6 }}
                        lg={{ offset: 9 }}
                        xl={{ offset: 8 }}
                        xxl={{ offset: 9 }}
                      >
                        <Text type="secondary">
                          <InfoCircleOutlined />
                          As shown on ICBC batch draft
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={4}>
                    <Form.Item
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 7 },
                        md: { span: 6 },
                        lg: { span: 9 },
                        xl: { span: 8 },
                        xxl: { span: 16 },
                      }}
                      wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 17 },
                        md: { span: 18 },
                        lg: { span: 15 },
                        xl: { span: 16 },
                        xxl: { span: 8 },
                      }}
                      label="Next Agency Batch"
                      name="NextBatchNumber"
                      style={{ marginBottom: 0 }}
                    >
                      <Text>{nextBatchNumber}</Text>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>

              {criteria && criteria.BatchType === MultilicenceBatchType && (
                <Panel key={2} header="Open multi-license batches">
                  <Row>
                    <Col span={24}>
                      <MultiLicencePolicies
                        loading={loading}
                        list={list}
                        handleParentRefresh={() =>
                          dispatch(listMultiLicencePoliciesAction(criteria))
                        }
                      />
                    </Col>
                  </Row>
                </Panel>
              )}
            </Collapse>

            <Row style={{ paddingTop: 16 }}>
              <Col offset={16} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        loading={loading}
                        id="processButton"
                        htmlType="submit"
                        disabled={
                          isNull(nextBatchNumber) ||
                          (getValues().BatchType === MultilicenceBatchType
                            ? selectedMultiLicencePolicies.length === 0 ||
                              !isValid
                            : !isValid)
                        }
                      >
                        Next
                        <RightCircleOutlined />
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateBatchStep1;
