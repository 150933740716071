import React from "react";
import { Button, Divider } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setExtraFiltersVisibility } from "../../../store/report/reportActions";

const ExtraReportFiltersContainer = ({ values, children }) => {
  const extraFiltersVisible = useSelector(
    (state) => state.report.extraFiltersVisible
  );
  const dispatch = useDispatch();

  return (
    <>
      {extraFiltersVisible ? (
        <>
          <Divider orientation="left" style={{ marginTop: 0 }}>
            <Button
              onClick={() => dispatch(setExtraFiltersVisibility(false))}
              type="dashed"
            >
              <FilterOutlined /> Hide Additional Filtering Options
            </Button>
          </Divider>
          <div>{children}</div>
        </>
      ) : (
        <Divider orientation="left" style={{ marginTop: 0 }}>
          <Button
            onClick={() => dispatch(setExtraFiltersVisibility(true))}
            type="dashed"
          >
            <FilterOutlined /> Show Additional Filtering Options
          </Button>
        </Divider>
      )}
    </>
  );
};

export default ExtraReportFiltersContainer;
