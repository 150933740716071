export const AuthorizationNotRequired = 0;
export const AccessCommissionDataFeed = 28;
export const ReportConfirmationOfInsurance = 27;
export const ReportScheduleOfVehicles = 26;
export const ManageLeaseRenewalData = 25;
export const UpdateLeasePolicies = 24;
export const ViewLeasePolicies = 23;
export const UpdateLeaseCompanies = 22;
export const ViewLeaseCompanies = 21;
export const UpdateDocuments = 20;
export const UpdateRenewalData = 19;
export const ViewDealerBillingData = 18;
export const MassChangeICBCPasswords = 17;
export const AmendDocumentBatch = 16;
export const ReportCustomerDataCollection = 29;
export const ManageProducers = 15;
export const ViewRetentionData = 13;
export const ViewProductivityDataFullAccess = 12;
export const ViewRenewalData = 11;
export const DownloadDocuments = 10;
export const ViewDocuments = 9;
export const ManageDocuments = 8;
export const ViewInventory = 7;
export const ManageInventory = 6;
export const ViewCommissionsLimitedAccess = 5;
export const ViewCommissionsFullAccess = 4;
export const ManageCommissions = 3;
export const ManageAgencies = 2;
export const AdminTasks = 1;
export const ViewProductivityDataLimitedAccess = 14;
export const RunLoanCalculator = 30;
