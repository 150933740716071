import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  readAction,
  updateBatchHeaderAction,
} from "../../../store/batching/batchingActions";
import { setTablePreserveOnReload } from "../../../store/ui/uiActions";
import { tableNames } from "../../shared/table/tableNames";
import BatchForm from "./BatchForm";

const UpdateBatchForm = ({ handleParentRefresh }) => {
  const { batchKey } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTablePreserveOnReload(tableNames.BatchView, true));
    dispatch(readAction(batchKey));
  }, [batchKey, dispatch]);
  const onSubmitHandler = (values) =>
    dispatch(updateBatchHeaderAction({ ...values, BatchKey: batchKey }));

  return (
    <BatchForm
      onSubmitHandler={onSubmitHandler}
      handleParentRefresh={handleParentRefresh}
    />
  );
};

export default UpdateBatchForm;
