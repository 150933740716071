import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Button, Space, Popconfirm } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  SearchableTable,
  isTableFilteredInfoNotFiltered,
} from "../shared/table/SearchableTable";
import {
  listBrokerAgenciesByUserRoleAction,
  enableRoleForAgenciesAction,
  disableRoleForAgenciesAction,
  clearOperationResultAction,
} from "../../store/user/security/securityActions";
import { columnTypes } from "../shared/table/columnTypes";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { tableNames } from "../shared/table/tableNames";
import { PreservableStatePageWrapper } from "../shared/PreservableStatePageWrapper";
import { every, isNull } from "lodash";
import { setTableRestorableData } from "../../store/ui/uiActions";

const { Text } = Typography;

const UserAgencyRole = ({ roleId, userKey }) => {
  return (
    <PreservableStatePageWrapper
      tableNames={[tableNames.UserAgencyRole]}
      onCleanup={() => {}}
    >
      <UserAgencyRoleBody roleId={roleId} userKey={userKey} />
    </PreservableStatePageWrapper>
  );
};

const UserAgencyRoleBody = ({ roleId, userKey }) => {
  const dispatch = useDispatch();
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  //
  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.UserAgencyRole)
  );

  const filteredInfo = tableData?.filteredInfo;

  const isNotFiltered = isTableFilteredInfoNotFiltered(filteredInfo);

  useEffect(() => {
    dispatch(listBrokerAgenciesByUserRoleAction(roleId, userKey, brokerId));
  }, [dispatch, brokerId, roleId, userKey]);

  const loading = useSelector((state) => state.user.security.loading);
  const agencies = useSelector(
    (state) => state.user.security.selectedUserRoleAgencies
  );

  const operationResult = useSelector(
    (state) => state.user.security.operationResult
  );
  useEffect(() => {
    openNotificationOperationResult(operationResult, () => {
      dispatch(clearOperationResultAction());
    });
  }, [operationResult, dispatch]);

  const [allAgencyIds, setAllAgencyIds] = useState([]);

  useEffect(() => {
    let agencyIds = [];

    agencyIds = isNotFiltered
      ? agencies?.map((a) => a.AgencyId)
      : tableData?.extra.currentDataSource.map((a) => a.AgencyId);

    setAllAgencyIds(agencyIds);
  }, [agencies, isNotFiltered]);

  const tableHeader = { loading } ? (
    <Space>
      <InfoCircleOutlined type="primary" />
      <Text strong>
        {`Enabled in 
                        ${
                          agencies?.filter((a) => a.RoleEnabled === true).length
                        }
                         out of ${agencies.length} ${
          agencies.length === 1 ? "agency" : "agencies"
        }.`}
      </Text>
    </Space>
  ) : null;
  const columns = [
    {
      title: "Agency #",
      dataIndex: "AgencyNumber",
      type: columnTypes.TEXT,
      width: 75,
    },
    {
      title: "Agency Name",
      dataIndex: "AgencyName",
      type: columnTypes.TEXT,
      width: 250,
    },
    {
      title: "Active",
      dataIndex: "AgencyActive",
      type: columnTypes.BOOLEAN,
      width: 65,
    },
    {
      title: "Enabled",
      dataIndex: "action",
      type: columnTypes.ACTION,
      width: 50,
      render: (text, record) => (
        <>
          {record.RoleEnabled ? (
            <Button
              shape="circle"
              size="small"
              className="included-doc"
              style={{ padding: 0 }}
              onClick={() => {
                dispatch(
                  disableRoleForAgenciesAction(
                    {
                      RoleId: roleId,
                      UserId: userKey,
                      AgencyIds: [record.AgencyId],
                      BrokerId: brokerId,
                    },
                    true
                  )
                );
              }}
            >
              <CheckOutlined />
            </Button>
          ) : (
            <Button
              shape="circle"
              size="small"
              className="excluded-doc"
              style={{ padding: 0 }}
              onClick={() => {
                dispatch(
                  enableRoleForAgenciesAction(
                    {
                      RoleId: roleId,
                      UserId: userKey,
                      AgencyIds: [record.AgencyId],
                      BrokerId: brokerId,
                    },
                    true
                  )
                );
              }}
            >
              <CloseOutlined />
            </Button>
          )}
        </>
      ),
    },
  ];

  const buttons = [
    <Popconfirm
      title={
        isNotFiltered
          ? "Enable role in all agencies?"
          : "Enable role in filtered agencies?"
      }
      onConfirm={() => {
        dispatch(
          enableRoleForAgenciesAction({
            RoleId: roleId,
            UserId: userKey,
            AgencyIds: allAgencyIds,
            BrokerId: brokerId,
          })
        );
      }}
    >
      <Button key="EnableRoles" size="small">
        <CheckOutlined />
        Enable {isNotFiltered ? "All" : "All (filtered)"}
      </Button>
    </Popconfirm>,
    <Popconfirm
      title={
        isNotFiltered
          ? "Disable role in all agencies?"
          : "Disable role in filtered agencies?"
      }
      onConfirm={() => {
        dispatch(
          disableRoleForAgenciesAction({
            RoleId: roleId,
            UserId: userKey,
            AgencyIds: allAgencyIds,
            BrokerId: brokerId,
          })
        );
      }}
    >
      <Button key="DisableRoles" size="small">
        <CloseOutlined />
        Disable {isNotFiltered ? "All" : "All (filtered)"}
      </Button>
    </Popconfirm>,
  ];

  return (
    <SearchableTable
      title={() => tableHeader}
      bordered
      dataSource={agencies}
      columns={columns}
      loading={loading}
      buttons={buttons}
      size="small"
      rowKey={(r) => r.AgencyId}
      tableName={tableNames.UserAgencyRole}
      scroll={{ y: 700, x: 445 }}
      pagination={false}
    />
  );
};

export default UserAgencyRole;
