import { Typography } from "antd";
import { isEmpty, isNull, isUndefined } from "lodash";
import React from "react";

export const getFieldsErrorDescriptionForDocumentForm = (errors) => {
  const formItems = {
    EntryDateTime: "Transaction Date",
    PolicyEffectiveDate: "Effective Date",
    CustomerName1: "Customer Name 1",
    PolicyNumber: "Policy",
    DCID: "Timestamp",
    ProducerCode1: "Producer Code 1",
    ProducerCode2: "Producer Code 2",
    ParentPolicyNumber: "Parent Policy",
    PlateNumber: "Plate",
    DocumentNumber: "Document",
    RegNumber: "Registration",
    ProvinceCode: "Province",
    PostalCode: "Postal Code",
    VehicleYear: "Year",
    CDF: "CDF",
    ComprDeductible: "Compr. Deductible",
    CollisionDeductible: "Collision Deductible",
    FleetDiscount: "Fleet Discount",
    FleetSurcharge: "Fleet Surcharge %",
    VIN: "VIN",
    CustomerEmail: "Email",
    DriversLicense: "Driver's License",
    PricipalDriversLicense: "Principal DL",
    LesseeDriversLicense: "Lessee DL",
    FleetNumber: "Fleet",
    VehicleColorCode: "Color",
    VehicleIDCode: "VIC",
    TransactionType: "Transaction Type",
  };

  const result = [];
  const errorsArray = Object.entries(errors);

  if (formItems != null && !isEmpty(formItems)) {
    errorsArray.forEach((e) => {
      const item = formItems[e[0]];
      if (!isNull(item) && !isUndefined(item)) {
        result.push(
          <Typography.Text
            style={{ paddingBottom: 0 }}
            key={item}
          >{`${item}: ${e[1].message}`}</Typography.Text>
        );
      }
    });
  }

  return result;
};
