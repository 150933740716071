import * as yup from "yup";
import {
  requiredMessage,
  dateRangeRequiredMessage,
} from "../../components/shared/form/formValidatonRules";

export const productionByDayHourReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  HourOfDay: yup.array().required().min(2, requiredMessage),
  TransactionDate: yup.array().required(dateRangeRequiredMessage).nullable(),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  Include: yup
    .boolean()
    .when(
      [
        "IncludeCancellations",
        "IncludeNonFleetNonGarage",
        "IncludeFleetGarage",
      ],
      {
        is: false,
        then: yup
          .boolean()
          .required("At least one of the options is required."),
      }
    ),
});
