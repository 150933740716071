import React from "react";
import { Alert, Typography } from "antd";

const { Text } = Typography;
const NoBatchEditPermissionAlert = () => {
  return (
    <Alert
      showIcon
      message={
        <>
          {"You have only "}
          <Text strong>View Documents</Text>
          {" permission, which allows you to view a batch, but not amend it. "}
        </>
      }
      type="info"
      closable
    />
  );
};

export default NoBatchEditPermissionAlert;
