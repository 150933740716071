import { LeftCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Row, Space } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearNewBatchAction,
  setProcessBatchCurrentStepAction,
} from "../../../store/batching/batchingActions";
import { fetchReport, resetReportState } from "../../../store/report/reportActions";
import PdfViewer from "../../shared/PdfViewer";
import "./CreateBatchStep3.css";
const CreateBatchStep3 = () => {
  const dispatch = useDispatch();

  const result = useSelector(
    (state) =>
      state.batching &&
      state.batching.newBatch &&
      state.batching.newBatch.closingResult
  );
  const batchHeader = useSelector(
    (state) =>
      state.batching &&
      state.batching.newBatch &&
      state.batching.newBatch.data &&
      state.batching.newBatch.data.BatchHeader
  );

  useEffect(() => {
    dispatch(resetReportState());
  }, []);

  let status;
  let title;

  useEffect(() => {
    if (batchHeader != null && batchHeader.DocumentBatchKey != null) {
      dispatch(
        fetchReport(
          (_) => {
            return {
              BatchKey: batchHeader.DocumentBatchKey,
              FileName: `Batch_${batchHeader.AgencyBatchNo.toString()}_${batchHeader.ICBCBatchNo.toString()}`,
            };
          },
          entityType.BATCH,
          "PrintBatch"
        )
      );
    }
  }, [batchHeader]);
  if (result && result.Outcome === "Success") {
    status = "success";
    title = "Processing completed succesfully";
  } else if (result && result.Outcome === "Error") {
    status = "error";
    title = result.Message;
  }

  return (
    <div style={{ padding: 4 }}>
      <Row style={{ paddingBottom: 8 }}>
        <Col span={24}>
          <Alert message={title} type={status} showIcon />
        </Col>
      </Row>
      <Row>
        <Col>
          <Space>
            {result && result.Outcome !== "Success" && (
              <Button
                size="small"
                onClick={() => dispatch(setProcessBatchCurrentStepAction(1))}
              >
                <LeftCircleOutlined />
                Previous
              </Button>
            )}
            <Button
              size="small"
              onClick={() => dispatch(clearNewBatchAction())}
            >
              Process Another Batch
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider style={{ marginTop: 0, marginBottom: 4 }} />
      <Row>
        <Col span={24}>
          <PdfViewer />
        </Col>
      </Row>
    </div>
  );
};

export default CreateBatchStep3;
