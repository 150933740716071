import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAllocatedToAgentsAction } from "../../../store/inventory/inventoryActions";
import SearchSelectEx from "../../shared/form/SearchSelectEx";

// Constants
const allAgentsOption = [
  { label: "(All Agents)", value: 0, filter: "(All Agents)" },
];

const AllocatedToAgentSelect = (props) => {
  //
  const { agencyKey, includeAllOption } = props;
  const propsToUse = { ...props };
  delete propsToUse.includeAllOption;
  delete propsToUse.agencyKey;
  delete propsToUse.setValue;

  //
  const dispatch = useDispatch();

  //
  const [options, setOptions] = useState([]);

  //
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const allocatedToAgents = useSelector(
    (state) => state.inventory.allocatedToAgents.data
  );
  const allocatedToAgentsLoading = useSelector(
    (state) => state.inventory.allocatedToAgents.loading
  );

  useEffect(() => {
    if (agencyKey != null && agencyKey != "") {
      dispatch(listAllocatedToAgentsAction(agencyKey));
    } else {
      if (options != []) {
        setOptions([]);
      }
    }
  }, [agencyKey]);

  useEffect(() => {
    var newOptions = allocatedToAgents != null ? allocatedToAgents : [];

    if (maskSensitiveData === true) {
      const maskedOptions = newOptions.map((item) => {
        return {
          label: <div className="blurry-text">{item.label}</div>,
          filter: item.label,
          value: item.value,
        };
      });
      newOptions = maskedOptions;
    }

    if (includeAllOption && allocatedToAgents && allocatedToAgents.length > 0) {
      newOptions = allAgentsOption.concat(newOptions);
    }

    if (options != newOptions) {
      if (props.setValue != null && newOptions != [] && !isEmpty(newOptions)) {
        props.setValue("AllocatedToAgentKey", newOptions[0].value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setOptions(newOptions);
    }
  }, [allocatedToAgents]);

  return (
    <>
      {!allocatedToAgentsLoading && !isEmpty(allocatedToAgents) && (
        <SearchSelectEx
          formLabel="Allocated To"
          {...propsToUse}
          name="AllocatedToAgentKey"
          loading={allocatedToAgentsLoading}
          hideErrorsOnDisabled={true}
          options={options}
          optionFilterProp={maskSensitiveData === true ? "filter" : "label"}
          filterOption={
            maskSensitiveData === true
              ? (input, option) => {
                  const label = option.filter ? option.filter : option.children;
                  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
              : null
          }
          placeholder={
            !allocatedToAgents || allocatedToAgents.length === 0
              ? "No Agents Found"
              : "Select an agent"
          }
          disabled={
            !allocatedToAgents ||
            allocatedToAgents.length === 0 ||
            allocatedToAgentsLoading
          }
        />
      )}
    </>
  );
};

export default AllocatedToAgentSelect;
