import produce from "immer";
import { Actions } from "./onlineRenewalsConstants";

const initialBulkUpdate = {
  loading: false,
  operationResult: null,
};

const initialState = {
  data: null,
  loading: false,
  operationResult: null,
  selectedRows: [],
  bulkUpdate: { ...initialBulkUpdate },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_ONLINE_RENEWALS:
        draft = initialState;
        return draft;
      case Actions.LIST_ONLINE_RENEWALS:
        draft.loading = true;
        return draft;
      case Actions.LIST_ONLINE_RENEWALS_SUCCEEDED:
        draft.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.loading = false;
        return draft;
      case Actions.SET_SELECTED_ROWS:
        draft.selectedRows = action.payload.selectedRows;
        return draft;
      case Actions.CLEAR_SELECTED_ROWS:
        draft.selectedRows = [];
        return draft;
      case Actions.BULK_UPDATE_CODES:
        draft.bulkUpdate.loading = true;
        return draft;
      case Actions.BULK_UPDATE_CODES_SUCCEEDED:
        draft.bulkUpdate.loading = false;
        draft.bulkUpdate.operationResult = action.payload.Operation;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.bulkUpdate.operationResult = null;
        return draft;
      case Actions.CLEAR_BULK_UPDATE_DATA:
        draft.bulkUpdate = initialBulkUpdate;
        return draft;
      default:
        return state;
    }
  });
};
