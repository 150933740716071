import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
} from "antd";
import dayjs from "dayjs";
import { isArray, isNull, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCloseBatchOperationResultAction,
  clearInlineEditOperationResultAction,
  clearNewBatchDataAction,
  closeBatchAction,
  getNewBatchAction,
  setProcessBatchCurrentStepAction,
  updateBatchCriteriaWithAction,
  updateInclusionExclusionStateAction,
  updateTableWithExclusionAndProceed,
} from "../../../store/batching/batchingActions";
import {
  clearOperationResult,
  resetReportState,
} from "../../../store/report/reportActions";
import { PdfViewerModal } from "../../shared/PdfViewer";
import {
  dateTimeInputStyle,
  shortInputStyle,
} from "../../shared/form/formInputStyles";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import {
  dateTimeFormat,
  formatCurrency,
  formatDate,
  toEndOfDay,
  toTZNeutralFormat,
} from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import NewBatchDocuments from "../table/NewBatchDocuments";
import { createBatchStep2Schema } from "./batchSchemas";
import batchTypes from "./batchTypes";

const { Panel } = Collapse;

// Constants
const INITIAL_DATA_STATE = { INITIAL: 0, FETCHED: 1, SET: 2 };
const emptyInitialValues = {
  FromCreatedDate: "",
  ToCreatedDate: "",
  ICBCBatch: "",
  AgencyBatch: "",
};

const pickerLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 18 },
    xl: { span: 18 },
    xxl: { span: 18 },
  },
};

const CreateBatchStep2 = () => {
  // Essential hooks
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setFocus,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: emptyInitialValues,
    resolver: yupResolver(createBatchStep2Schema),
  });

  // State
  const [initialDataState, setInitialDataState] = useState(
    INITIAL_DATA_STATE.INITIAL
  );

  const [headerStatistics, setHeaderStatistics] = useState(null);

  // Selectors
  const batchHeader = useSelector(
    (state) =>
      state.batching.newBatch.data && state.batching.newBatch.data.BatchHeader
  );
  const list = useSelector(
    (state) =>
      state.batching.newBatch.data && state.batching.newBatch.data.Documents
  );
  const closingLoading = useSelector(
    (state) => state.batching.newBatch.closingLoading
  );
  const closingResult = useSelector(
    (state) => state.batching.newBatch.closingResult
  );
  const inlineEditOperationResult = useSelector(
    (state) => state.batching.inlineEditOperationResult
  );
  const criteria = useSelector((state) => state.batching.newBatch.criteria);
  const batchType = criteria.BatchType;
  const agencyKey = criteria.AgencyKey;
  const submissionDate = criteria.SubmissionDate;
  const batchTypeDescription = batchTypes.filter(
    (t) => t.value === batchType
  )[0].label;
  const agencyListCompact = useSelector((state) => state.agency.listCompact);
  const selectedAgency =
    agencyListCompact?.filter((a) => a.Id === agencyKey) ?? null;
  const agency =
    selectedAgency != null
      ? isArray(selectedAgency)
        ? selectedAgency[0]
        : selectedAgency
      : null;
  const reportOperationResult = useSelector(
    (state) => state.report.operationResult
  );
  const selectedDocumentKeys = useSelector(
    (state) => state.batching.newBatch.ui.selectedDocumentKeys
  );
  const firstElementKey = useSelector(
    (state) => state.batching.newBatch.ui.firstElementKey
  );
  const documentsInclusionState = useSelector(
    (state) => state.batching.newBatch.ui.documentsInclusionState
  );

  // Effect hooks
  useEffect(() => {
    openNotificationOperationResult(reportOperationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [reportOperationResult]);

  useEffect(() => {
    openNotificationOperationResult(inlineEditOperationResult, () =>
      dispatch(clearInlineEditOperationResultAction())
    );
  }, [inlineEditOperationResult]);

  useEffect(() => {
    dispatch(resetReportState());
  }, []);

  useEffect(() => {
    if (!isUndefined(criteria)) {
      if (initialDataState != INITIAL_DATA_STATE.FETCHED) {
        dispatch(
          getNewBatchAction({
            ...criteria,
            PreventInclusionRefresh: true,
            UpdateAllKeysList: true,
          })
        );
      } else {
        setInitialDataState(INITIAL_DATA_STATE.SET);
        reset({
          ...criteria,
        });
        trigger();
      }
    }
  }, [criteria]);

  useEffect(() => {
    closingResult && closingResult.Outcome === "Success"
      ? dispatch(setProcessBatchCurrentStepAction(2))
      : openNotificationOperationResult(closingResult, () =>
          dispatch(clearCloseBatchOperationResultAction(), true, true)
        );
  }, [closingResult]);

  useEffect(() => {
    if (
      !isNull(batchHeader) &&
      initialDataState == INITIAL_DATA_STATE.INITIAL
    ) {
      setInitialDataState(INITIAL_DATA_STATE.FETCHED);
    }
  }, [batchHeader]);

  useEffect(() => {
    if (initialDataState == INITIAL_DATA_STATE.FETCHED) {
      dispatch(
        updateBatchCriteriaWithAction({
          FromCreatedDate: toTZNeutralFormat(batchHeader.FromDate),
          ToCreatedDate: toTZNeutralFormat(batchHeader.ToDate),
        })
      );
    } else if (initialDataState == INITIAL_DATA_STATE.SET) {
      if (setFocus != null) {
        setFocus("ICBCBatch");
      }
    }
  }, [initialDataState]);

  useEffect(() => {
    var stats = {
      DocCount: 0,
      TotalCashChequePayment: 0,
      TotalAmountFinanced: 0,
      TotalDebitCreditPayment: 0,
      TotalOnlineCreditPayment: 0,
      TotalAmountDue: 0,
    };

    if (
      documentsInclusionState != null &&
      Object.keys(documentsInclusionState).length > 0 &&
      list != null
    ) {
      list.forEach((d) => {
        if (documentsInclusionState[d.DocumentKey] === true) {
          stats.DocCount += 1;
          stats.TotalCashChequePayment += d.CashChequePayment ?? 0;
          stats.TotalAmountFinanced += d.FinancedAmt ?? 0;
          stats.TotalDebitCreditPayment += d.DebitCreditPayment ?? 0;
          stats.TotalOnlineCreditPayment += d.OnlineCreditPayment ?? 0;
          stats.TotalAmountDue +=
            d.AmountDue != null &&
            (batchTypeDescription != batchTypes[1].label || // If it's not a Multi-license batch
              d.DocumentType == "C" ||
              d.DocumentType == "F") // Or if it's a payment
              ? d.AmountDue
              : 0;
        }
      });
    }

    setHeaderStatistics(stats);
  }, [documentsInclusionState]);

  // Other variables & functions
  const formItemStyle = { marginBottom: 0 };

  const onSubmitHandler = (values) => {
    dispatch(
      updateTableWithExclusionAndProceed(
        closeBatchAction({
          SubmissionDate: toEndOfDay(submissionDate),
          AgencyKey: agencyKey,
          ICBCBatchNo: values.ICBCBatch,
          AgencyBatchNo: values.AgencyBatch,
          DocumentKeys: selectedDocumentKeys,
        })
      )
    );
  };

  const redirectFocusOnTab = (e) => {
    if (e.key === "Tab") {
      if (
        list != null &&
        Array.isArray(list) &&
        list.length &&
        firstElementKey != ""
      ) {
        const element = document.getElementById(
          `checkboxButton${firstElementKey}`
        );
        if (element != null) {
          e.preventDefault();
          element.focus();
        }
      } else {
        const element = document.getElementById(`batchStep2Previous`);
        if (element != null) {
          e.preventDefault();
          element.focus();
        }
      }
    }
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" onFinish={() => {}}>
            <Collapse defaultActiveKey={[1, 2]}>
              <Panel
                key={1}
                header={
                  <Row justify="space-evenly">
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Agency:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {agency && agency.FullName}
                      </span>
                    </Col>
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Batch Type:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {batchTypeDescription}
                      </span>
                    </Col>
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Submission Date:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {formatDate(submissionDate)}
                      </span>
                    </Col>
                  </Row>
                }
              >
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <CustomDatePicker
                      formItem={{
                        layout: pickerLayout,
                        label: "From",
                        style: formItemStyle,
                        required: true,
                      }}
                      control={control}
                      name="FromCreatedDate"
                      disabled={initialDataState != INITIAL_DATA_STATE.SET}
                      showTime={true}
                      showNow={false}
                      style={dateTimeInputStyle}
                      format={dateTimeFormat}
                      allowClear={false}
                      onChange={(datestring) => {
                        dispatch(
                          getNewBatchAction({
                            ...getValues(),
                            FromCreatedDate: datestring,
                            UpdateAllKeysList: true,
                          })
                        );
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <CustomDatePicker
                      formItem={{
                        layout: pickerLayout,
                        label: "To",
                        style: formItemStyle,
                        required: true,
                      }}
                      control={control}
                      name="ToCreatedDate"
                      format={dateTimeFormat}
                      allowClear={false}
                      showNow={false}
                      showTime={true}
                      disabled={initialDataState != INITIAL_DATA_STATE.SET}
                      style={dateTimeInputStyle}
                      onChange={(datestring) => {
                        dispatch(
                          getNewBatchAction({
                            ...getValues(),
                            ToCreatedDate: datestring,
                            UpdateAllKeysList: true,
                          })
                        );
                      }}
                      disabledDate={(current) =>
                        current && current > dayjs(submissionDate)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <Form.Item
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 7 },
                        md: { span: 12 },
                        lg: { span: 8 },
                        xl: { span: 11 },
                        xxl: { span: 10 },
                      }}
                      wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 17 },
                        md: { span: 12 },
                        lg: { span: 16 },
                        xl: { span: 13 },
                        xxl: { span: 14 },
                      }}
                      label="ICBC Batch"
                      style={formItemStyle}
                      validateStatus={
                        errors && errors["ICBCBatch"] ? "error" : ""
                      }
                      help={errors.ICBCBatch?.message}
                      required
                    >
                      <Controller
                        control={control}
                        name="ICBCBatch"
                        render={({ field }) => (
                          <Input
                            {...field}
                            autoFocus
                            disabled={
                              initialDataState != INITIAL_DATA_STATE.SET
                            }
                            name="ICBCBatch"
                            style={{
                              ...shortInputStyle,
                              textTransform: "uppercase",
                            }}
                            onKeyDown={(e) => redirectFocusOnTab(e)}
                            maxLength={7}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <Controller
                      control={control}
                      name="AgencyBatch"
                      render={({ field }) => (
                        <Form.Item
                          labelCol={{
                            xs: { span: 24 },
                            sm: { span: 7 },
                            md: { span: 12 },
                            lg: { span: 8 },
                            xl: { span: 11 },
                            xxl: { span: 10 },
                          }}
                          wrapperCol={{
                            xs: { span: 24 },
                            sm: { span: 17 },
                            md: { span: 12 },
                            lg: { span: 16 },
                            xl: { span: 13 },
                            xxl: { span: 14 },
                          }}
                          label="Agency Batch"
                          style={formItemStyle}
                          required
                          validateStatus={
                            errors && errors["AgencyBatch"] ? "error" : ""
                          }
                          help={errors.AgencyBatch?.message}
                        >
                          <Input
                            {...field}
                            disabled={
                              initialDataState != INITIAL_DATA_STATE.SET
                            }
                            tabIndex={-1}
                            name="AgencyBatch"
                            style={shortInputStyle}
                            maxLength={4}
                            onKeyDown={(e) => redirectFocusOnTab(e)}
                          />
                        </Form.Item>
                      )}
                    />
                  </Col>
                </Row>
              </Panel>
              <Panel
                collapsible="disabled"
                showArrow={false}
                key={2}
                header={
                  <Row justify="space-evenly">
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Document Count:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {headerStatistics && headerStatistics.DocCount}
                      </span>
                    </Col>
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Cash / Cheque:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {headerStatistics &&
                          formatCurrency(
                            headerStatistics.TotalCashChequePayment
                          )}
                      </span>
                    </Col>
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Debit / Credit:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {headerStatistics &&
                          formatCurrency(
                            headerStatistics.TotalDebitCreditPayment
                          )}
                      </span>
                    </Col>
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Online Credit:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {headerStatistics &&
                          formatCurrency(
                            headerStatistics.TotalOnlineCreditPayment
                          )}
                      </span>
                    </Col>
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Financed:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {headerStatistics &&
                          formatCurrency(headerStatistics.TotalAmountFinanced)}
                      </span>
                    </Col>
                    <Col style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Amount Due:{" "}
                      <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        {headerStatistics &&
                          formatCurrency(headerStatistics.TotalAmountDue)}
                      </span>
                    </Col>
                  </Row>
                }
                style={{ paddingBottom: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <FormProvider getValues={getValues}>
                      <NewBatchDocuments headerStatistics={headerStatistics} />
                    </FormProvider>
                  </Col>
                </Row>
              </Panel>
            </Collapse>

            <Row style={{ paddingTop: 16 }}>
              <Col offset={16} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        size="small"
                        id="batchStep2Previous"
                        onClick={() => {
                          const values = {
                            FromCreatedDate: toTZNeutralFormat(
                              getValues().FromCreatedDate
                            ),
                            ToCreatedDate: toTZNeutralFormat(
                              getValues().ToCreatedDate
                            ),
                            ICBCBatch: getValues().ICBCBatch,
                            AgencyBatch: getValues().AgencyBatch,
                          };

                          dispatch(updateInclusionExclusionStateAction());
                          dispatch(updateBatchCriteriaWithAction(values));
                          dispatch(clearNewBatchDataAction());
                          dispatch(setProcessBatchCurrentStepAction(0));
                        }}
                      >
                        <LeftCircleOutlined />
                        Previous
                      </Button>
                      <Space>
                        <Popconfirm
                          title="This batch will now be processed and closed. Would you like to proceed?"
                          onConfirm={() => handleSubmit(onSubmitHandler)()}
                          okText="Yes"
                          cancelText="No"
                          disabled={
                            batchHeader == null ||
                            headerStatistics == null ||
                            !isValid ||
                            headerStatistics.DocCount == 0
                          }
                        >
                          <Button
                            id="processButton"
                            type="primary"
                            size="small"
                            disabled={
                              batchHeader == null ||
                              headerStatistics == null ||
                              !isValid ||
                              headerStatistics.DocCount == 0
                            }
                            tabIndex={1}
                            loading={closingLoading}
                          >
                            Process
                            <RightCircleOutlined />
                          </Button>
                        </Popconfirm>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <PdfViewerModal />
    </div>
  );
};

export default CreateBatchStep2;
