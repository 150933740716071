import {
  FileExcelOutlined,
  InfoCircleTwoTone,
  MailOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Image,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty, isNull } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../store/agency/agencyActions";
import {
  clearOperationResult,
  downloadCustomFile,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../store/report/reportActions";
import {
  listLabelFormats,
  listPolicyProducts,
  transformRenewalLettersInput,
  transformRenewalListInput,
} from "../../store/retention/retentionActions";
import { clearUploadedFile } from "../../store/ui/uiActions";
import { listVehicleTypesAction } from "../../store/vehicle/vehicleActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import { FileUpload } from "../shared/FileUpload";
import PdfViewer from "../shared/PdfViewer";
import ReportParamPanelHeader from "../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import ExtraReportFilterContainer from "../shared/form/ExtraReportFiltersContainer";
import ProducerSelectEx from "../shared/form/ProducerSelectEx";
import SearchMultiSelectEx from "../shared/form/SearchMultiSelectEx";
import SortGroupFieldSelectEx from "../shared/form/SortGroupFieldSelectEx";
import { fixedDateRanges } from "../shared/form/fixedDateRanges";
import {
  fleetGarageCommaListMessage,
  postalCodesCommaListMessage,
} from "../shared/form/formValidatonRules";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";
import CustomCheckbox from "../shared/form/reactHookForm/CustomCheckbox";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import CustomInputCurrency from "../shared/form/reactHookForm/CustomInputCurrency";
import CustomInputNumber from "../shared/form/reactHookForm/CustomInputNumber";
import CustomRadioGroup from "../shared/form/reactHookForm/CustomRadioGroup";
import CustomSelect from "../shared/form/reactHookForm/CustomSelect";
import {
  dateFormat,
  toEndOfDay,
  toStartOfDay,
  toTZNeutralFormat,
} from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { ViewRenewalData } from "../shared/securityRoleTasks";
import { agenciesListEqual } from "../shared/sorting";
import {
  renewalListReportTypeOptions,
  renewalListReportTypes,
  renewalReportFields,
  renewalReportSortGroupOptions,
  renewalStatusReportSortGroupOptions,
} from "./reportParameters";
import { renewalReportSchema } from "./reportSchema";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKeys: [],
  ReportType: renewalListReportTypes.DETAIL,
  FixedDateRange: fixedDateRanges.NEXT_MONTH,
  ExpiryDate: ["", ""],
  IncludeAutoplanPolicies: true,
  IncludeSpecialCoveragePolicies: true,
  IncludeNonFleetNonGarage: true,
  IncludeFleet: false,
  IncludeGarage: false,
  CustomerConsentYes: true,
  CustomerConsentNo: true,
  CustomerConsentBlank: true,
  SortGroupFields: [
    {
      Name: renewalReportFields.EXPIRY_DATE,
      SortAscending: true,
      ToGroup: false,
      NewPage: false,
    },
  ],
  PostalCodes: "",
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
  LabelKey: null,
  ProductKeys: [],
  VehicleTypes: [],
  TPLLimitFrom: 0,
  TPLLimitTo: 10000000,
  ColDedFrom: 0,
  ColDedTo: 15000,
  CompDedFrom: 0,
  CompDedTo: 15000,
  VehicleYearFrom: null,
  VehicleYearTo: null,
  FleetGaragePolicyNumbers: "",
  PolicyStatusActive: true,
  PolicyStatusExpired: false,
  PolicyStatusCancelled: false,
  PolicyStatusRenewed: false,
};

const colSize = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 12,
  xxl: 12,
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const specialLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 9 },
    xl: { span: 7 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 15 },
    xl: { span: 17 },
    xxl: { span: 19 },
  },
};

const fleetGarageLayout = {
  labelCol: {
    xs: { span: 9 },
    sm: { span: 9 },
    md: { span: 11 },
    lg: { span: 11 },
    xl: { span: 8 },
    xxl: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 },
    md: { span: 13 },
    lg: { span: 13 },
    xl: { span: 16 },
    xxl: { span: 17 },
  },
};

const formItemStyle = {
  display: "inline-block",
};
const inputStyle = {
  width: 120,
  textAlign: "right",
};

const inputNumberStyle = {
  width: 80,
  textAlign: "right",
};

const uploadName = "MailMerge";

const MailMergeModal = ({ isPreviewMode, setIsPreviewMode }) => {
  return (
    <>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          Do not have a template?&nbsp;
          <a
            href={process.env.PUBLIC_URL + "/kb/AutolinkICBCRenewalLetter.dotx"}
          >
            Click here to download a sample
          </a>
        </Col>
      </Row>
      <Row justify="space-evenly" style={{ marginBottom: "20px" }}>
        <Col span={24}>
          <div>
            <FileUpload
              entity={entityType.RETENTION}
              action="UploadMailMergeTemplate"
              uploadName={uploadName}
              accept=".dot,.dotx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.template"
              customMessage="The supported file formats for mail merge are currently limited to .dot and .dotx."
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            defaultChecked={isPreviewMode}
            checked={isPreviewMode}
            onChange={() => {
              setIsPreviewMode(!isPreviewMode);
            }}
          >
            Preview mode (merge first 5 records only)
          </Checkbox>
        </Col>
      </Row>
    </>
  );
};

const RenewalListReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { isValid, isDirty, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(renewalReportSchema),
  });

  //
  const reportType = watch("ReportType");
  const policyStatusExpired = watch("PolicyStatusExpired");
  const policyStatusCancelled = watch("PolicyStatusCancelled");
  const policyStatusRenewed = watch("PolicyStatusRenewed");

  //
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();

  //
  const openedModalInstance = useRef(null);

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const labelFormats = useSelector((state) => state.retention.labelFormats);
  const uploadedFile = useSelector((state) =>
    state?.ui?.uploads?.find((u) => u.name === uploadName)
  );
  const isUploaded = uploadedFile?.isUploaded;
  const reportState = useSelector((state) => state.report);
  const isExporting = reportState.isExporting;
  const isPrintingLetters = reportState.isDownloadingCustomFile;
  const operationResult = reportState.operationResult;
  const isViewerVisible = reportState.isViewerVisible;
  const isFetching = reportState.isFetching;
  const policyProducts = useSelector((state) => state.retention.policyProducts);
  const vehicleTypes = useSelector((state) => state.vehicle.data);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewRenewalData));
    }
  }, [brokerId]);

  useEffect(() => {
    if (isEmpty(policyProducts)) {
      dispatch(listPolicyProducts());
    }
  }, [policyProducts]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: [initialAgency],
        ExpiryDate: [
          toStartOfDay(dayjs().add(1, "month").startOf("month")),
          toEndOfDay(dayjs().add(1, "month").endOf("month")),
        ],
      };

      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult]);

  useEffect(() => {
    if (labelFormats == null) {
      dispatch(listLabelFormats());
    }
  }, [labelFormats]);

  useEffect(() => {
    if (isNull(vehicleTypes)) {
      dispatch(listVehicleTypesAction());
    }
  }, [vehicleTypes]);

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(clearUploadedFile(uploadName));
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  useEffect(() => {
    if (openedModalInstance.current != null) {
      openedModalInstance.current.update({
        content: (
          <MailMergeModal
            isPreviewMode={isPreviewMode}
            setIsPreviewMode={setIsPreviewMode}
          />
        ),
        okButtonProps: {
          disabled: isUploaded !== true,
        },
        onOk() {
          dispatch(
            downloadCustomFile(
              transformRenewalLettersInput(
                getValues(),
                uploadName,
                isPreviewMode
              ),
              "PrintRenewalLetters",
              entityType.RETENTION,
              "RenewalLetters",
              ".docx"
            )
          );
        },
      });
    }
  }, [isUploaded, isPreviewMode]);

  const handleReportTypeChanged = (e) => {
    if (
      e.target.value === renewalListReportTypes.STATUS &&
      getValues().FixedDateRange === fixedDateRanges.NEXT_MONTH
    ) {
      setValue("FixedDateRange", fixedDateRanges.LAST_MONTH);
      setValue("ExpiryDate", [
        toTZNeutralFormat(dayjs().subtract(1, "month").startOf("month")),
        toTZNeutralFormat(dayjs().subtract(1, "month").endOf("month")),
      ]);
    } else if (
      e.target.value !== renewalListReportTypes.STATUS &&
      getValues().FixedDateRange === fixedDateRanges.LAST_MONTH
    ) {
      setValue("FixedDateRange", fixedDateRanges.NEXT_MONTH);
      setValue("ExpiryDate", [
        toTZNeutralFormat(dayjs().add(1, "month").startOf("month")),
        toTZNeutralFormat(dayjs().add(1, "month").endOf("month")),
      ]);
    }
    var currentSortGroupFields = getValues().SortGroupFields;
    var newSortGroupFields =
      currentSortGroupFields != null
        ? currentSortGroupFields.filter(
            (item) => item.Name !== "PolicyStatusDesc"
          )
        : null;
    setValue("SortGroupFields", newSortGroupFields);
    trigger();
  };

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformRenewalListInput(values, vehicleTypes, policyProducts),
        entityType.RETENTION,
        values.ReportType === renewalListReportTypes.STATUS
          ? "PrintRenewalStatus"
          : values.ReportType === renewalListReportTypes.MAILING_LABELS
          ? "PrintMailingLabels"
          : "PrintRenewalList",
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="RenewalsReport">
      {getValues().ReportType === renewalListReportTypes.MAILING_LABELS && (
        <Row>
          <Col span={24} style={{ marginBottom: "8px" }}>
            <Alert
              showIcon
              type="info"
              message={
                <>
                  When printing the report, please ensure to set the scale to
                  'Actual Size' (Edge) or 'Default' (Chrome) in your printer
                  settings, as shown in the{" "}
                  <a
                    onClick={() => {
                      setPreviewVisible(true);
                    }}
                  >
                    following screenshot.
                  </a>
                </>
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col {...colSize}>
                      <CustomRadioGroup
                        control={control}
                        layout={specialLayout}
                        formLabel="Report Type"
                        name="ReportType"
                        required={true}
                        afterChange={handleReportTypeChanged}
                      >
                        {renewalListReportTypeOptions}
                      </CustomRadioGroup>
                    </Col>
                    <Col {...colSize}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        name="ExpiryDate"
                        format={dateFormat}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSize}>
                      <AgencyMultiSelectEx
                        layout={specialLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                      />
                    </Col>
                    <Col {...colSize}>
                      <Form.Item
                        labelCol={{
                          xs: { span: 5 },
                          sm: { span: 7 },
                          md: { span: 6 },
                          lg: { span: 8 },
                          xl: { span: 6 },
                          xxl: { span: 5 },
                        }}
                        wrapperCol={{
                          xs: { span: 19 },
                          sm: { span: 17 },
                          md: { span: 18 },
                          lg: { span: 16 },
                          xl: { span: 18 },
                          xxl: { span: 19 },
                        }}
                        label="Include"
                        name="Include"
                        required
                      >
                        <Space direction="vertical" size={4}>
                          <Controller
                            control={control}
                            name="IncludeNonFleetNonGarage"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  const currentValues = getValues();
                                  const isChecked = e.target.checked;
                                  const isAllUnchecked =
                                    !isChecked &&
                                    !currentValues.IncludeFleet &&
                                    !currentValues.IncludeGarage;

                                  if (!isAllUnchecked) {
                                    setValue(
                                      "IncludeNonFleetNonGarage",
                                      isChecked,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      }
                                    );
                                  }
                                }}
                                inputRef={field.ref}
                                name="IncludeNonFleetNonGarage"
                              >
                                Non-Fleet and Non-Garage Policies
                              </Checkbox>
                            )}
                          />

                          <Controller
                            control={control}
                            name="IncludeFleet"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  const currentValues = getValues();
                                  const isChecked = e.target.checked;
                                  const isAllUnchecked =
                                    !isChecked &&
                                    !currentValues.IncludeNonFleetNonGarage &&
                                    !currentValues.IncludeGarage;

                                  if (!isAllUnchecked) {
                                    setValue("IncludeFleet", isChecked, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                }}
                                inputRef={field.ref}
                                name="IncludeFleet"
                              >
                                Fleet Policies
                              </Checkbox>
                            )}
                          />
                          <Controller
                            control={control}
                            name="IncludeGarage"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  const currentValues = getValues();
                                  const isChecked = e.target.checked;
                                  const isAllUnchecked =
                                    !isChecked &&
                                    !currentValues.IncludeNonFleetNonGarage &&
                                    !currentValues.IncludeFleet;

                                  if (!isAllUnchecked) {
                                    setValue("IncludeGarage", isChecked, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                }}
                                inputRef={field.ref}
                                name="IncludeGarage"
                              >
                                Garage Policies
                              </Checkbox>
                            )}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSize}>
                      <Form.Item
                        labelCol={{
                          xs: { span: 5 },
                          sm: { span: 7 },
                          md: { span: 6 },
                          lg: { span: 9 },
                          xl: { span: 7 },
                          xxl: { span: 5 },
                        }}
                        wrapperCol={{
                          xs: { span: 19 },
                          sm: { span: 17 },
                          md: { span: 18 },
                          lg: { span: 15 },
                          xl: { span: 17 },
                          xxl: { span: 19 },
                        }}
                        label="Policy Type"
                        name="PolicyType"
                        required
                      >
                        <Space direction="vertical" size={4}>
                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            formStyle={{ marginBottom: 0 }}
                            name="IncludeAutoplanPolicies"
                            onChange={(e) => {
                              if (
                                !e.target.checked &&
                                !getValues().IncludeSpecialCoveragePolicies
                              ) {
                                setValue(
                                  "IncludeSpecialCoveragePolicies",
                                  true,
                                  {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  }
                                );
                              }
                            }}
                          >
                            Autoplan Policies
                          </CustomCheckbox>

                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            formStyle={{ marginBottom: 0 }}
                            name="IncludeSpecialCoveragePolicies"
                            onChange={(e) => {
                              if (
                                !e.target.checked &&
                                !getValues().IncludeAutoplanPolicies
                              ) {
                                setValue("IncludeAutoplanPolicies", true, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }
                            }}
                          >
                            Special Coverage Policies
                          </CustomCheckbox>
                        </Space>
                      </Form.Item>
                      <Form.Item
                        labelCol={{
                          xs: { span: 5 },
                          sm: { span: 7 },
                          md: { span: 6 },
                          lg: { span: 8 },
                          xl: { span: 6 },
                          xxl: { span: 5 },
                        }}
                        wrapperCol={{
                          xs: { span: 19 },
                          sm: { span: 17 },
                          md: { span: 18 },
                          lg: { span: 16 },
                          xl: { span: 18 },
                          xxl: { span: 19 },
                        }}
                        label="Customer Consent"
                        name="CustomerConsent"
                        required
                        style={{ marginBottom: 0 }}
                      >
                        <Space name="CustomerConsent">
                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            name="CustomerConsentYes"
                            //formStyle={{ marginBottom: 0 }}
                          >
                            Yes
                          </CustomCheckbox>
                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            name="CustomerConsentNo"
                            //formStyle={{ marginBottom: 0 }}
                          >
                            No
                          </CustomCheckbox>
                          <CustomCheckbox
                            control={control}
                            formLabel=""
                            name="CustomerConsentBlank"
                          >
                            Blank
                          </CustomCheckbox>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col {...colSize}>
                      <Form.Item
                        labelCol={{
                          xs: { span: 5 },
                          sm: { span: 7 },
                          md: { span: 6 },
                          lg: { span: 8 },
                          xl: { span: 6 },
                          xxl: { span: 5 },
                        }}
                        wrapperCol={{
                          xs: { span: 19 },
                          sm: { span: 17 },
                          md: { span: 18 },
                          lg: { span: 16 },
                          xl: { span: 18 },
                          xxl: { span: 19 },
                        }}
                        label="Policy Status"
                        name="PolicyStatus"
                        required
                        valuePropName="checked"
                      >
                        <Space direction="horizontal">
                          <Space direction="vertical" size={4}>
                            <Controller
                              control={control}
                              name="PolicyStatusActive"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusCancelled &&
                                      !currentValues.PolicyStatusExpired &&
                                      !currentValues.PolicyStatusRenewed;

                                    if (!isAllPoliciesUnchecked) {
                                      setValue(
                                        "PolicyStatusActive",
                                        isChecked,
                                        {
                                          shouldValidate: true,
                                          shouldDirty: true,
                                        }
                                      );
                                    }
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusActive"
                                >
                                  Active
                                </Checkbox>
                              )}
                            />
                            <Controller
                              control={control}
                              name="PolicyStatusExpired"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusCancelled &&
                                      !currentValues.PolicyStatusActive &&
                                      !currentValues.PolicyStatusRenewed;

                                    if (isAllPoliciesUnchecked) {
                                      setValue("PolicyStatusActive", true, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      });
                                    }
                                    setValue("PolicyStatusExpired", isChecked, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusExpired"
                                >
                                  Expired
                                </Checkbox>
                              )}
                            />
                          </Space>
                          <Space direction="vertical" size={4}>
                            <Controller
                              control={control}
                              name="PolicyStatusRenewed"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusCancelled &&
                                      !currentValues.PolicyStatusActive &&
                                      !currentValues.PolicyStatusExpired;

                                    if (isAllPoliciesUnchecked) {
                                      setValue("PolicyStatusActive", true, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      });
                                    }
                                    setValue("PolicyStatusRenewed", isChecked, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusRenewed"
                                >
                                  Renewed
                                </Checkbox>
                              )}
                            />
                            <Controller
                              control={control}
                              name="PolicyStatusCancelled"
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    const currentValues = getValues();
                                    const isChecked = e.target.checked;
                                    const isAllPoliciesUnchecked =
                                      !isChecked &&
                                      !currentValues.PolicyStatusExpired &&
                                      !currentValues.PolicyStatusActive &&
                                      !currentValues.PolicyStatusRenewed;

                                    if (isAllPoliciesUnchecked) {
                                      setValue("PolicyStatusActive", true, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      });
                                    }
                                    setValue(
                                      "PolicyStatusCancelled",
                                      isChecked,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      }
                                    );
                                  }}
                                  inputRef={field.ref}
                                  name="PolicyStatusCancelled"
                                >
                                  Cancelled
                                </Checkbox>
                              )}
                            />
                          </Space>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <ExtraReportFilterContainer>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                        <Form.Item
                          name="ProducerCode1Keys"
                          label="Producer 1"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode1Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={1}
                            taskKey={ViewRenewalData}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ProducerCode2Keys"
                          label="Producer 2"
                          {...defaultLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode2Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={2}
                            taskKey={ViewRenewalData}
                          />
                        </Form.Item>
                        <CustomInput
                          control={control}
                          layout={defaultLayout}
                          formTooltip={postalCodesCommaListMessage}
                          formLabel="Postal Code(s)"
                          name="PostalCodes"
                        />
                        <Form.Item
                          {...defaultLayout}
                          name="VehicleYear"
                          label="Vehicle Year"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <CustomInputNumber
                            formStyle={formItemStyle}
                            control={control}
                            style={inputNumberStyle}
                            name="VehicleYearFrom"
                            afterChange={() => trigger()}
                          />
                          {" - "}
                          <CustomInputNumber
                            formStyle={formItemStyle}
                            control={control}
                            name="VehicleYearTo"
                            style={inputNumberStyle}
                            afterChange={() => trigger()}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                        <SearchMultiSelectEx
                          formLabel="ICBC Product(s)"
                          layout={defaultLayout}
                          control={control}
                          name="ProductKeys"
                          data={policyProducts ?? []}
                          setValue={setValue}
                        />
                        <SearchMultiSelectEx
                          allowClear
                          control={control}
                          layout={defaultLayout}
                          formLabel="Vehicle Type(s)"
                          name="VehicleTypes"
                          data={vehicleTypes ?? []}
                          setValue={setValue}
                          maxTagCount={4}
                        />
                        <CustomInput
                          control={control}
                          layout={fleetGarageLayout}
                          formTooltip={fleetGarageCommaListMessage}
                          formLabel="Fleet # or Garage Policy #"
                          name="FleetGaragePolicyNumbers"
                          disabled={
                            !getValues().IncludeFleet &&
                            !getValues().IncludeGarage
                          }
                        />
                      </Col>
                    </Row>
                    <Divider orientation="left" plain style={{ marginTop: 0 }}>
                      Limits / Deductibles
                    </Divider>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={13}>
                        <Form.Item
                          {...specialLayout}
                          name="TPLLimit"
                          label="Third Party Liability"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <CustomInputCurrency
                            formStyle={formItemStyle}
                            control={control}
                            style={inputStyle}
                            name="TPLLimitFrom"
                            afterChange={() => trigger()}
                          />
                          {" - "}
                          <CustomInputCurrency
                            formStyle={formItemStyle}
                            control={control}
                            name="TPLLimitTo"
                            style={inputStyle}
                            afterChange={() => trigger()}
                          />
                        </Form.Item>

                        <Form.Item
                          {...specialLayout}
                          name="ColDed"
                          label="Collision"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <CustomInputCurrency
                            formStyle={formItemStyle}
                            control={control}
                            style={inputStyle}
                            name="ColDedFrom"
                            afterChange={() => trigger()}
                          />
                          {" - "}
                          <CustomInputCurrency
                            formStyle={formItemStyle}
                            control={control}
                            name="ColDedTo"
                            style={inputStyle}
                            afterChange={() => trigger()}
                          />
                        </Form.Item>
                        <Form.Item
                          {...specialLayout}
                          name="CompDed"
                          label="Comprehensive"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <CustomInputCurrency
                            formStyle={formItemStyle}
                            control={control}
                            style={inputStyle}
                            name="CompDedFrom"
                            afterChange={() => trigger()}
                          />
                          {" - "}
                          <CustomInputCurrency
                            formStyle={formItemStyle}
                            control={control}
                            name="CompDedTo"
                            style={inputStyle}
                            afterChange={() => trigger()}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </ExtraReportFilterContainer>
                  <Row>
                    <Col span={24}>
                      <SortGroupFieldSelectEx
                        fieldOptions={
                          getValues().ReportType ===
                          renewalListReportTypes.STATUS
                            ? renewalStatusReportSortGroupOptions
                            : renewalReportSortGroupOptions
                        }
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        isSubmitted={isSubmitted}
                        hideGroupingFor={[
                          "CustomerName",
                          "PlateNumber",
                          "Make",
                          "VehicleYear",
                          "PolicyEffectiveDate",
                        ]}
                      />
                    </Col>
                  </Row>
                  {getValues().ReportType ===
                    renewalListReportTypes.MAILING_LABELS && (
                    <>
                      <Divider
                        dashed
                        style={{ marginTop: 16, marginBottom: 16 }}
                      />
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                          <CustomSelect
                            formLabel="Label Format"
                            layout={{
                              labelCol: {
                                xs: { span: 5 },
                                sm: { span: 7 },
                                md: { span: 6 },
                                lg: { span: 9 },
                                xl: { span: 7 },
                                xxl: { span: 5 },
                              },
                              wrapperCol: {
                                xs: { span: 19 },
                                sm: { span: 17 },
                                md: { span: 18 },
                                lg: { span: 15 },
                                xl: { span: 17 },
                                xxl: { span: 19 },
                              },
                            }}
                            control={control}
                            name="LabelKey"
                            required={true}
                            loading={labelFormats == null}
                            options={labelFormats}
                            showSearch={true}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      {(reportType === renewalListReportTypes.DETAIL ||
                        reportType === renewalListReportTypes.COMPACT) &&
                        (policyStatusCancelled ||
                          policyStatusExpired ||
                          policyStatusRenewed) && (
                          <Popconfirm
                            title="You selected a policy status other than “Active”. Are you sure you want to continue?"
                            onCancel={() => {
                              var instance = modal.confirm({
                                title: "Specify mail merge template",
                                okButtonProps: {
                                  disabled: isUploaded !== true,
                                },
                                width: 600,
                                content: (
                                  <MailMergeModal
                                    isPreviewMode={isPreviewMode}
                                    setIsPreviewMode={setIsPreviewMode}
                                  />
                                ),
                                closable: true,
                                okText: "Continue",
                                icon: <InfoCircleTwoTone />,
                                onCancel() {
                                  openedModalInstance.current = null;
                                },
                                onOk() {
                                  dispatch(
                                    downloadCustomFile(
                                      transformRenewalLettersInput(
                                        getValues(),
                                        uploadName,
                                        isPreviewMode
                                      ),
                                      "PrintRenewalLetters",
                                      entityType.RETENTION,
                                      "RenewalLetters",
                                      ".docx"
                                    )
                                  );
                                },
                              });
                              openedModalInstance.current = instance;
                            }}
                            okText="Cancel"
                            cancelText="Continue"
                            disabled={
                              !(
                                reportType === renewalListReportTypes.DETAIL ||
                                reportType === renewalListReportTypes.COMPACT
                              ) || !isValid
                            }
                          >
                            <Button
                              style={{
                                // background: "#fff0e6",
                                // color: "#ec3a00",
                                // borderColor: "#fff0e6",
                                marginRight: "32px",
                              }}
                              loading={isPrintingLetters}
                              disabled={
                                !(
                                  reportType ===
                                    renewalListReportTypes.DETAIL ||
                                  reportType === renewalListReportTypes.COMPACT
                                ) || !isValid
                              }
                              onClick={() => {}}
                            >
                              <MailOutlined />
                              Mail Merge
                            </Button>
                          </Popconfirm>
                        )}
                      {(reportType === renewalListReportTypes.DETAIL ||
                        reportType === renewalListReportTypes.COMPACT) &&
                        !(
                          policyStatusCancelled ||
                          policyStatusExpired ||
                          policyStatusRenewed
                        ) && (
                          <Button
                            style={{
                              // background: "#fff0e6",
                              // color: "#ec3a00",
                              // borderColor: "#fff0e6",
                              marginRight: "32px",
                            }}
                            loading={isPrintingLetters}
                            disabled={
                              !(
                                reportType === renewalListReportTypes.DETAIL ||
                                reportType === renewalListReportTypes.COMPACT
                              ) || !isValid
                            }
                            onClick={() => {
                              var instance = modal.confirm({
                                title: "Specify mail merge template",
                                okButtonProps: {
                                  disabled: isUploaded !== true,
                                },
                                width: 600,
                                content: (
                                  <MailMergeModal
                                    isPreviewMode={isPreviewMode}
                                    setIsPreviewMode={setIsPreviewMode}
                                  />
                                ),
                                closable: true,
                                okText: "Continue",
                                icon: <InfoCircleTwoTone />,
                                onCancel() {
                                  openedModalInstance.current = null;
                                },
                                onOk() {
                                  dispatch(
                                    downloadCustomFile(
                                      transformRenewalLettersInput(
                                        getValues(),
                                        uploadName,
                                        isPreviewMode
                                      ),
                                      "PrintRenewalLetters",
                                      entityType.RETENTION,
                                      "RenewalLetters",
                                      ".docx"
                                    )
                                  );
                                },
                              });
                              openedModalInstance.current = instance;
                            }}
                          >
                            <MailOutlined />
                            Mail Merge
                          </Button>
                        )}
                      {reportType === renewalListReportTypes.MAILING_LABELS &&
                      (policyStatusCancelled ||
                        policyStatusExpired ||
                        policyStatusRenewed) ? (
                        <Popconfirm
                          title="You selected a policy status other than “Active”. Are you sure you want to continue?"
                          onCancel={() => handleSubmit(onSubmitHandler)()}
                          okText="Cancel"
                          cancelText="Continue"
                          disabled={!isValid}
                        >
                          <Button
                            disabled={!isValid}
                            type="primary"
                            onClick={() => {}}
                            loading={isFetching}
                          >
                            <PrinterOutlined /> Preview
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Button
                          disabled={!isValid}
                          autoFocus={true}
                          id="processButton"
                          type="primary"
                          htmlType="submit"
                          loading={isFetching}
                        >
                          <PrinterOutlined /> Preview
                        </Button>
                      )}
                      <Button
                        disabled={
                          !isValid ||
                          getValues().ReportType ===
                            renewalListReportTypes.MAILING_LABELS
                        }
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformRenewalListInput(getValues()),
                              getValues().ReportType ===
                                renewalListReportTypes.STATUS
                                ? "RenewalStatusReport"
                                : "RenewalListReport",
                              entityType.RETENTION
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          const reportType = getValues().ReportType;
                          const resetValues = {
                            ...initialDefaultValues,
                            ReportType: reportType,
                            LabelKey: getValues().LabelKey,
                          };
                          reset(resetValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
      <div>
        <Image
          width={0}
          src={require("./printer-settings-help.png")}
          preview={{
            visible: isPreviewVisible,
            onVisibleChange: (visible, _) => setPreviewVisible(visible),
          }}
        />
      </div>
      {contextHolder}
    </PermissionWrapper>
  );
};
export default RenewalListReport;
