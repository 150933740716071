import { DatePicker, Form, Input, Select, Space } from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import React, { useEffect } from "react";
import { useController, useWatch } from "react-hook-form";
import {
  dateFormat,
  toEndOfDay,
  toStartOfDay,
  unspecifiedTZFormat,
} from "../../formatting";
import { fixedDateRangeOptions, fixedDateRanges } from "../fixedDateRanges";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";
dayjs.extend(quarterOfYear);

const AdvancedRangePicker = (props) => {
  const {
    name,
    disableFutureDates,
    setValue,
    control,
    formItem,
    onChange,
    afterChange,
  } = props;
  const fixedDateRange = useWatch({ control, name: "FixedDateRange" });
  const numberOfDays = useWatch({ control, name: "NumberOfDays" });

  const {
    field,
    fieldState: { error },
  } = useController({
    name: name,
    control: control,
  });

  const showNumberOfDaysValues = [
    fixedDateRanges.IN_X_DAYS,
    fixedDateRanges.X_DAYS_AGO,
    fixedDateRanges.LAST_X_DAYS,
    fixedDateRanges.NEXT_X_DAYS,
  ];

  const getCurrentPeriodRange = (periodName) => [
    dayjs().startOf(periodName).format(unspecifiedTZFormat),
    dayjs().endOf(periodName).format(unspecifiedTZFormat),
  ];

  const getPeriodToDateRange = (periodName) => [
    dayjs().startOf(periodName).format(unspecifiedTZFormat),
    dayjs().endOf("day").format(unspecifiedTZFormat),
  ];

  const getLastPeriodRange = (periodName) => [
    dayjs()
      .subtract(1, periodName)
      .startOf(periodName)
      .format(unspecifiedTZFormat),
    dayjs()
      .subtract(1, periodName)
      .endOf(periodName)
      .format(unspecifiedTZFormat),
  ];

  const getNextPeriodRange = (periodName) => [
    dayjs().add(1, periodName).startOf(periodName).format(unspecifiedTZFormat),
    dayjs().add(1, periodName).endOf(periodName).format(unspecifiedTZFormat),
  ];

  const setValueEx = (valueName, values, extras) => {
    onChange && onChange(values);
    setValue(valueName, values, extras);
    if (props.afterChange != null) {
      props.afterChange(values);
    }
  };

  const setCustomRange = (days, rangeType) => {
    switch (rangeType) {
      case fixedDateRanges.IN_X_DAYS:
        setValueEx(
          name,
          [
            dayjs()
              .add(
                days == null || isNaN(days) || days < 0
                  ? 0
                  : days > 735
                  ? 735
                  : days,
                "day"
              )
              .startOf("day")
              .format(unspecifiedTZFormat),
            dayjs()
              .add(
                days == null || isNaN(days) || days < 0
                  ? 0
                  : days > 735
                  ? 735
                  : days,
                "day"
              )
              .endOf("day")
              .format(unspecifiedTZFormat),
          ],
          { shouldValidate: true, shouldDirty: true }
        );
        break;
      case fixedDateRanges.X_DAYS_AGO:
        setValueEx(
          name,
          [
            dayjs()
              .subtract(
                days == null || isNaN(days) || days < 0
                  ? 1
                  : days > 735
                  ? 735
                  : days,
                "day"
              )
              .startOf("day")
              .format(unspecifiedTZFormat),
            dayjs()
              .subtract(
                days == null || isNaN(days) || days < 0
                  ? 0
                  : days > 735
                  ? 735
                  : days,
                "day"
              )
              .endOf("day")
              .format(unspecifiedTZFormat),
          ],
          { shouldValidate: true, shouldDirty: true }
        );
        break;
      case fixedDateRanges.NEXT_X_DAYS:
        setValueEx(
          name,
          [
            dayjs().add(1, "d").startOf("day").format(unspecifiedTZFormat),
            dayjs()
              .add(
                days == null ||
                  isNaN(days) ||
                  days == 0 ||
                  days == 1 ||
                  days < 0
                  ? 1
                  : days > 735
                  ? 735
                  : days,
                "day"
              )
              .endOf("day")
              .format(unspecifiedTZFormat),
          ],
          { shouldValidate: true, shouldDirty: true }
        );
        break;
      case fixedDateRanges.LAST_X_DAYS:
        setValueEx(
          name,
          [
            days == null || isNaN(days) || days == 0 || days == 1 || days < 0
              ? dayjs().startOf("day").format(unspecifiedTZFormat)
              : days > 735
              ? dayjs()
                  .subtract(735, "day")
                  .startOf("day")
                  .format(unspecifiedTZFormat)
              : dayjs()
                  .subtract(days - 1, "day")
                  .startOf("day")
                  .format(unspecifiedTZFormat),
            dayjs().endOf("day").format(unspecifiedTZFormat),
          ],
          { shouldValidate: true, shouldDirty: true }
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onFixedRangeSelect(fixedDateRange);
  }, []);

  const onFixedRangeSelect = (value) => {
    switch (value) {
      case fixedDateRanges.TODAY:
        setValueEx(name, getCurrentPeriodRange("day"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.IN_X_DAYS:
      case fixedDateRanges.X_DAYS_AGO:
      case fixedDateRanges.NEXT_X_DAYS:
      case fixedDateRanges.LAST_X_DAYS:
        setCustomRange(numberOfDays != null ? numberOfDays : 1, value);
        break;
      case fixedDateRanges.THIS_MONTH:
        setValueEx(name, getCurrentPeriodRange("month"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.NEXT_MONTH:
        setValueEx(name, getNextPeriodRange("month"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.LAST_MONTH:
        setValueEx(name, getLastPeriodRange("month"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.MTD:
        setValueEx(name, getPeriodToDateRange("month"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.QTD:
        setValueEx(name, getPeriodToDateRange("quarter"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.THIS_QUARTER:
        setValueEx(name, getCurrentPeriodRange("quarter"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.LAST_QUARTER:
        setValueEx(name, getLastPeriodRange("quarter"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.NEXT_QUARTER:
        setValueEx(name, getNextPeriodRange("quarter"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.THIS_YEAR:
        setValueEx(name, getCurrentPeriodRange("year"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.LAST_YEAR:
        setValueEx(name, getLastPeriodRange("year"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      case fixedDateRanges.YTD:
        setValueEx(name, getPeriodToDateRange("year"), {
          shouldValidate: true,
          shouldDirty: true,
        });
        break;
      default:
        // code block
        break;
    }
  };

  return (
    <Form.Item
      {...formItem}
      {...formItem?.layout}
      name={name}
      validateStatus={error != null ? "error" : ""}
      help={error != null ? error.message : null}
    >
      <Space size={8} style={{ height: 24 }}>
        <FixedDateRangeSelection
          control={control}
          disableFutureDates={disableFutureDates}
          onFixedRangeSelect={onFixedRangeSelect}
        />
        {fixedDateRange && showNumberOfDaysValues.includes(fixedDateRange) && (
          <NumberOfDays
            control={control}
            onChangeNumberOfDays={(e) =>
              setCustomRange(e.target.value, fixedDateRange)
            }
          />
        )}

        <DatePicker.RangePicker
          ref={field.ref}
          allowClear={false}
          disableFutureDates={props.disableFutureDates}
          disabledDate={props.disabledDate}
          format={dateFormat}
          value={[
            field?.value?.[0] ? dayjs(field.value[0]) : "",
            field?.value?.[1] ? dayjs(field.value[1]) : "",
          ]}
          onChange={(dates) => {
            var transformedDates = [
              toStartOfDay(dates?.[0] ?? ""),
              toEndOfDay(dates?.[1] ?? ""),
            ];
            dates && onChange && onChange(transformedDates);
            field.onChange(transformedDates);
            dates && afterChange && afterChange(transformedDates);
            setValue("FixedDateRange", fixedDateRanges.NONE);
          }}
        />
      </Space>
    </Form.Item>
  );
};

export default AdvancedRangePicker;

const FixedDateRangeSelection = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: "FixedDateRange",
    control: props.control,
  });

  return (
    <FixedDateRangeSelectionContent
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    >
      {props.children}
    </FixedDateRangeSelectionContent>
  );
};

const FixedDateRangeSelectionContent = React.memo((props) => {
  return (
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Select
        {...props.field}
        name="FixedDateRange"
        options={fixedDateRangeOptions(props.disableFutureDates)}
        style={{ width: 152 }}
        allowClear={props.allowClear}
        placeholder="Fixed range"
        onSelect={props.onFixedRangeSelect}
        showSearch
        virtual={false}
        optionFilterProp="label"
        filterOption={(input, option) => {
          const label = option.label ? option.label : option.children;
          return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      />
    </span>
  );
}, checkHookFormFieldPropsEquality);

const NumberOfDays = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: "NumberOfDays",
    control: props.control,
  });

  return (
    <NumberOfDaysContent
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    >
      {props.children}
    </NumberOfDaysContent>
  );
};

const NumberOfDaysContent = React.memo((props) => {
  return (
    <Input
      {...props.field}
      name="NumberOfDays"
      style={{ width: 40 }}
      maxLength={3}
      // type="number"
      onChange={(e) => {
        props.field.onChange(e);
        props.onChangeNumberOfDays(e);
      }}
    />
  );
}, checkHookFormFieldPropsEquality);
