import { FilterOutlined } from "@ant-design/icons";
import { Button, Col, Select, Row, Space } from "antd";
import { get } from "lodash";
import { matchSorter } from "match-sorter";

export const getTextMultiFilterColumnSearchProps = (
  dataIndex,
  title,
  options = [],
  columnRefArray,
  i
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys = [],
    confirm,
    clearFilters,
  }) => (
    <div
      style={{ padding: 8, width: 400 }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Row>
        <Col span={24}>
          <Select
            ref={(el) => (columnRefArray.current[i] = el)}
            mode="multiple"
            maxTagCount={4}
            allowClear
            style={{ width: "100%", marginBottom: 8 }}
            placeholder={`Select ${title}`}
            placement="topLeft"
            value={selectedKeys}
            onChange={(values) => setSelectedKeys(values)}
          >
            {options.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
              icon={<FilterOutlined />}
            >
              Filter
            </Button>
            <Button
              onClick={() => {
                setSelectedKeys([]);
                clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  ),

  onFilter: (value, record) => {
    return (
      matchSorter([get(record, dataIndex)], value ?? "", {
        threshold: matchSorter.rankings.EQUAL,
      }).length > 0
    );
  },
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => {
        if (columnRefArray.current[i] != null) {
          columnRefArray.current[i].focus();
        }
      }, 100);
    }
  },
});
