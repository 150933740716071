import {
  CheckOutlined,
  CloseOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  getNewBatchFromCriteriaAction,
  getNewBatchFromCriteriaFiltered,
  setDocumentsInclusionStateAction,
  setDocumentsItemNumbers,
  setSelectedBatchDocumentKeysAction,
  transformDraftInput,
  updateTableWithExclusionAndProceed,
} from "../../../store/batching/batchingActions";
import {
  fetchReport,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import { Actions } from "../../../store/report/reportConstants";
import { setTableRowBookmark } from "../../../store/ui/uiActions";
import { isEqual } from "lodash";
import { toTZNeutralFormat } from "../../shared/formatting";

export const ToggleExcludedDocumentsButton = React.memo(
  ({ getValues, hideExcludedDocuments, setHideExcludedDocuments }) => {
    const dispatch = useDispatch();
    return (
      <Button
        tabIndex={-1}
        size="small"
        onClick={() => {
          // Refresh included/excluded status
          dispatch(
            getNewBatchFromCriteriaAction({
              FromCreatedDate: toTZNeutralFormat(
                getValues().FromCreatedDate
              ),
              ToCreatedDate: toTZNeutralFormat(getValues().ToCreatedDate),
              ICBCBatch: getValues().ICBCBatch,
              AgencyBatch: getValues().AgencyBatch,
              HideExcludedDocuments: !hideExcludedDocuments,
            })
          );
          setHideExcludedDocuments(!hideExcludedDocuments);
        }}
      >
        {hideExcludedDocuments ? "Show Excluded" : "Hide Excluded"}
      </Button>
    );
  },
  (prevProps, nextProps) =>
    prevProps.hideExcludedDocuments === nextProps.hideExcludedDocuments
);

export const ExcludeAllButton = React.memo(
  ({
    allDocumentKeys,
    getValues,
    hideExcludedDocuments,
    isFilteredOnUi,
    filteredDocumentKeys,
  }) => {
    const dispatch = useDispatch();
    return (
      <Button
        onClick={() => {
          if (isFilteredOnUi) {
            dispatch(
              getNewBatchFromCriteriaFiltered({
                FromCreatedDate: toTZNeutralFormat(
                  getValues().FromCreatedDate
                ),
                ToCreatedDate: toTZNeutralFormat(
                  getValues().ToCreatedDate
                ),
                ICBCBatch: getValues().ICBCBatch,
                AgencyBatch: getValues().AgencyBatch,
                IncludeAllDocuments: false,
                ExcludeAllDocuments: false,
                IncludedDocumentKeys: [],
                ExcludedDocumentKeys: filteredDocumentKeys,
                HideExcludedDocuments: hideExcludedDocuments,
              })
            );
          } else {
            dispatch(
              getNewBatchFromCriteriaAction({
                FromCreatedDate: toTZNeutralFormat(
                  getValues().FromCreatedDate
                ),
                ToCreatedDate: toTZNeutralFormat(
                  getValues().ToCreatedDate
                ),
                ICBCBatch: getValues().ICBCBatch,
                AgencyBatch: getValues().AgencyBatch,
                IncludeAllDocuments: false,
                ExcludeAllDocuments: true,
                IncludedDocumentKeys: [],
                ExcludedDocumentKeys: [],
                PreventInclusionRefresh: true,
                HideExcludedDocuments: hideExcludedDocuments,
              })
            );
          }
        }}
        tabIndex={-1}
        size={"small"}
        disabled={allDocumentKeys && allDocumentKeys.length === 0}
      >
        <CloseOutlined />
        {isFilteredOnUi ? "Exclude All (filtered)" : "Exclude All"}
      </Button>
    );
  },
  (prevProps, nextProps) =>
    prevProps.allDocumentKeys != null &&
    nextProps.allDocumentKeys != null &&
    prevProps.allDocumentKeys.length != null &&
    nextProps.allDocumentKeys.length != null &&
    prevProps.allDocumentKeys.length === nextProps.allDocumentKeys.length &&
    prevProps.hideExcludedDocuments === nextProps.hideExcludedDocuments &&
    prevProps.isFilteredOnUi === nextProps.isFilteredOnUi &&
    isEqual(prevProps.filteredDocumentKeys, nextProps.filteredDocumentKeys)
);

export const IncludeAllButton = React.memo(
  ({
    allDocumentKeys,
    getValues,
    hideExcludedDocuments,
    isFilteredOnUi,
    filteredDocumentKeys,
  }) => {
    const dispatch = useDispatch();
    return (
      <Button
        onClick={() => {
          if (isFilteredOnUi) {
            dispatch(
              getNewBatchFromCriteriaFiltered({
                FromCreatedDate: toTZNeutralFormat(
                  getValues().FromCreatedDate
                ),
                ToCreatedDate: toTZNeutralFormat(
                  getValues().ToCreatedDate
                ),
                ICBCBatch: getValues().ICBCBatch,
                AgencyBatch: getValues().AgencyBatch,
                IncludeAllDocuments: false,
                ExcludeAllDocuments: false,
                IncludedDocumentKeys: filteredDocumentKeys,
                ExcludedDocumentKeys: [],
                HideExcludedDocuments: hideExcludedDocuments,
              })
            );
          } else {
            dispatch(
              getNewBatchFromCriteriaAction({
                FromCreatedDate: toTZNeutralFormat(
                  getValues().FromCreatedDate
                ),
                ToCreatedDate: toTZNeutralFormat(
                  getValues().ToCreatedDate
                ),
                ICBCBatch: getValues().ICBCBatch,
                AgencyBatch: getValues().AgencyBatch,
                IncludeAllDocuments: true,
                ExcludeAllDocuments: false,
                IncludedDocumentKeys: [],
                ExcludedDocumentKeys: [],
                PreventInclusionRefresh: true,
                HideExcludedDocuments: hideExcludedDocuments,
              })
            );
          }
        }}
        tabIndex={-1}
        size={"small"}
        disabled={allDocumentKeys && allDocumentKeys.length === 0}
      >
        <CheckOutlined />

        {isFilteredOnUi ? "Include All (filtered)" : "Include All "}
      </Button>
    );
  },
  (prevProps, nextProps) =>
    prevProps.allDocumentKeys != null &&
    nextProps.allDocumentKeys != null &&
    prevProps.allDocumentKeys.length != null &&
    nextProps.allDocumentKeys.length != null &&
    prevProps.allDocumentKeys.length === nextProps.allDocumentKeys.length &&
    prevProps.hideExcludedDocuments === nextProps.hideExcludedDocuments &&
    prevProps.isFilteredOnUi === nextProps.isFilteredOnUi &&
    isEqual(prevProps.filteredDocumentKeys, nextProps.filteredDocumentKeys)
);

export const PrintButton = React.memo(
  ({ headerStatistics, getValues }) => {
    const dispatch = useDispatch();
    const isFetching = useSelector((state) => state.report.isFetching);
    const criteria = useSelector(
      (state) =>
        state.batching.newBatch.data && state.batching.newBatch.criteria
    );
    return (
      <Button
        tabIndex={-1}
        size="small"
        disabled={
          headerStatistics == null ||
          headerStatistics.DocCount == null ||
          headerStatistics.DocCount === 0 ||
          headerStatistics.DocCount <= 0
        }
        loading={isFetching}
        onClick={() => {
          dispatch(
            updateTableWithExclusionAndProceed(
              {
                type: Actions.FETCH_REPORT_REQUEST,
                payload: { input: null },
              },
              fetchReport(
                transformDraftInput({
                  ...criteria,
                  FromCreatedDate: toTZNeutralFormat(
                    getValues().FromCreatedDate
                  ),
                  ToCreatedDate: toTZNeutralFormat(
                    getValues().ToCreatedDate
                  ),
                }),
                entityType.BATCH,
                "PrintDraft"
              )
            )
          );

          dispatch(setReportModalVisibility(true));
        }}
      >
        <PrinterOutlined />
        Preview Draft Report
      </Button>
    );
  },
  (prevProps, nextProps) =>
    (prevProps.headerStatistics == null &&
      nextProps.headerStatistics == null) ||
    (prevProps.headerStatistics != null &&
      nextProps.headerStatistics != null &&
      prevProps.headerStatistics.DocCount ===
        nextProps.headerStatistics.DocCount)
);

export const CheckedRowButton = React.memo(
  ({ checked, documentKey, hideExcludedDocuments, tableName, tooltipText }) => {
    const dispatch = useDispatch();
    return (
      <Tooltip
        placement="bottomLeft"
        title={!checked && tooltipText ? tooltipText : ""}
      >
        <Button
          shape="circle"
          size="small"
          className={checked ? "included-doc" : "excluded-doc"}
          onClick={() => {
            // TODO: Dispatch these actions together
            dispatch(setSelectedBatchDocumentKeysAction(documentKey, checked));
            dispatch(setDocumentsInclusionStateAction(documentKey, checked));

            if (hideExcludedDocuments) {
              dispatch(setDocumentsItemNumbers(documentKey));
            }
          }}
          onFocus={() => {
            dispatch(setTableRowBookmark(tableName, documentKey));
          }}
          style={{
            padding: 0,
            height: "22px",
            width: "22px",
            minWidth: "22px",
          }}
          id={`checkboxButton${documentKey}`}
        >
          {checked ? (
            <CheckOutlined style={{ fontSize: "12px" }} />
          ) : (
            <CloseOutlined style={{ fontSize: "12px" }} />
          )}
        </Button>
      </Tooltip>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps != null &&
      nextProps != null &&
      prevProps.checked === nextProps.checked &&
      prevProps.documentKey === nextProps.documentKey &&
      prevProps.hideExcludedDocuments === nextProps.hideExcludedDocuments
    );
  }
);
