import produce from "immer";
import { Actions } from "./dataDownloadConstants";

const initialDetails = {
  loading: false,
  data: null,
  operationResult: null,
};

const initialRequest = {
  loading: false,
  datesLoading: false,
  dates: null,
  operationResult: null,
};

const initialState = {
  searchValues: null,
  dataDownloadLog: null,
  loading: false,
  operationResult: null,
  details: { ...initialDetails },
  request: { ...initialRequest },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_DATA_DOWNLOAD_LOG:
        draft = initialState;
        return draft;
      case Actions.CLEAR_DATA_DOWNLOAD_DETAILS:
        draft.details = initialDetails;
        return draft;
      case Actions.LIST_DATA_DOWNLOAD_LOG:
        draft.searchValues = action.searchValues;
        draft.loading = true;
        return draft;
      case Actions.LIST_DATA_DOWNLOAD_LOG_SUCCEEDED:
        draft.dataDownloadLog = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.loading = false;
        return draft;
      case Actions.GET_DATA_DOWNLOAD_DETAILS:
        draft.details.loading = true;
        return draft;
      case Actions.GET_DATA_DOWNLOAD_DETAILS_SUCCEEDED:
        draft.details.data = action.payload.Data;
        draft.details.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.details.loading = false;
        return draft;
      case Actions.GET_LATEST_DATE_RANGES:
        draft.request.datesLoading = true;
        return draft;
      case Actions.GET_LATEST_DATE_RANGES_SUCCEEDED:
        draft.request.dates = action.payload.Data;
        draft.request.datesLoading = false;
        return draft;
      case Actions.SUBMIT_DOWNLOAD_REQUEST:
        draft.request.loading = true;
        return draft;
      case Actions.SUBMIT_DOWNLOAD_REQUEST_SUCCEEDED:
        draft.request.loading = false;
        draft.request.operationResult = action.payload.Operation;
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.request.operationResult = null;
        return draft;
      case Actions.CLEAR_DOWNLOAD_REQUEST_TAB:
        draft.request = initialRequest;
        return draft;
      default:
        return state;
    }
  });
};
