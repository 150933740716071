import { RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Form, Row, Space } from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { listAgenciesCompactAction } from "../../../store/agency/agencyActions";
import {
  getNewInventoryMovementAction,
  readAction,
  setMovementOptionAction,
  setReceiveInventoryCurrentStepAction,
  setSelectedPendingMovementKeyAction,
} from "../../../store/inventory/inventoryActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import { ManageInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import { step1Schema } from "../shared/moveInventorySchemas";
import PendingShipments from "./PendingShipments";
import {
  newICBCShipment,
  pendingBrokerShipment,
  pendingICBCShipment,
} from "./receiveOptions";

//
const initialValues = {
  PendingBrokerShipmentKey: null,
  PendingICBCShipmentKey: null,
  AgencyKey: "",
  InventoryMovementType: 8,
  InventoryMovementOption: newICBCShipment.value,
};

const ReceiveInventoryStep1 = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(step1Schema),
  });

  const agencyKey = watch("AgencyKey");
  const inventoryMovementOption = watch("InventoryMovementOption");
  const pendingICBCShipmentKey = watch("PendingICBCShipmentKey");
  const pendingBrokerShipmentKey = watch("PendingBrokerShipmentKey");

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const header = useSelector(
    (state) =>
      state.inventory.moveInventory.newMovement.entity &&
      state.inventory.moveInventory.newMovement.entity.Header
  );
  const selectedPendingMovementKey = useSelector(
    (state) =>
      state.inventory.moveInventory.newMovement.selectedPendingMovementKey
  );
  const movementOption = useSelector(
    (state) => state.inventory.moveInventory.newMovement.movementOption
  );
  const loading = useSelector(
    (state) => state.inventory.moveInventory.newMovement.loading
  );

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ManageInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      if (!isNull(header)) {
        if (agencies.find((a) => a.Id === header.AgencyKey)) {
          initialAgency = header.AgencyKey;
        }
      }

      var defaultMovementType = !isNull(header)
        ? header.InventoryMovementType
        : initialValues.InventoryMovementType;

      var defaultMovementOption = !isNull(movementOption)
        ? movementOption
        : initialValues.InventoryMovementOption;

      var defaultPendingICBCShipmentKey =
        !isNull(movementOption) && movementOption === pendingICBCShipment.value
          ? selectedPendingMovementKey
          : initialValues.PendingICBCShipmentKey;

      var defaultPendingBrokerShipmentKey =
        !isNull(movementOption) &&
        movementOption === pendingBrokerShipment.value
          ? selectedPendingMovementKey
          : initialValues.PendingBrokerShipmentKey;

      reset({
        InventoryMovementType: defaultMovementType,
        InventoryMovementOption: defaultMovementOption,
        PendingICBCShipmentKey: defaultPendingICBCShipmentKey,
        PendingBrokerShipmentKey: defaultPendingBrokerShipmentKey,
        AgencyKey: initialAgency,
      });

      trigger();
    }
  }, [agencies, homeAgency]);

  const onSubmitHandler = (values) => {
    if (values.InventoryMovementOption === newICBCShipment.value) {
      dispatch(getNewInventoryMovementAction(values, "RECEIVE_INVENTORY"));
    } else if (values.InventoryMovementOption === pendingICBCShipment.value) {
      dispatch(readAction(values.PendingICBCShipmentKey, "RECEIVE_INVENTORY"));
    } else {
      dispatch(
        readAction(values.PendingBrokerShipmentKey, "RECEIVE_INVENTORY")
      );
    }
    dispatch(setMovementOptionAction(values.InventoryMovementOption));
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandler)()}
          >
            <AgencySelectEx
              layout={{
                labelCol: { span: 6, lg: 4, xl: 3, xxl: 3 },
                wrapperCol: { span: 8, lg: 8, xl: 8, xxl: 8 },
              }}
              formLabel="Agency"
              name="AgencyKey"
              required={true}
              autoFocus={true}
              agencies={currentAgencies}
              isLoading={defaultValues.AgencyKey === ""}
              control={control}
              onChange={() => {
                setValue(
                  "InventoryMovementOption",
                  initialValues.InventoryMovementOption
                );
                setValue("PendingICBCShipmentKey", null);
                setValue("PendingBrokerShipmentKey", null);
              }}
            />

            <PendingShipments
              control={control}
              layout={{
                labelCol: { span: 6, lg: 4, xl: 3, xxl: 3 },
                wrapperCol: { span: 18, lg: 20, xl: 21, xxl: 21 },
              }}
              agencyKey={agencyKey}
              inventoryMovementOption={inventoryMovementOption}
              setValue={setValue}
            />

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <Row style={{ paddingTop: 16 }}>
              <Col offset={16} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        size="small"
                        loading={loading}
                        disabled={
                          !isValid ||
                          (inventoryMovementOption ===
                            pendingBrokerShipment.value &&
                            isNull(pendingBrokerShipmentKey)) ||
                          (inventoryMovementOption ===
                            pendingICBCShipment.value &&
                            isNull(pendingICBCShipmentKey))
                        }
                      >
                        Next
                        <RightCircleOutlined />
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ReceiveInventoryStep1;
