import React from "react";
import {
  HomeOutlined,
  TeamOutlined,
  ShopOutlined,
  FileSearchOutlined,
  FileAddOutlined,
  SearchOutlined,
  GroupOutlined,
  PlusSquareOutlined,
  DatabaseOutlined,
  SwapOutlined,
  SwapRightOutlined,
  SwapLeftOutlined,
  UserAddOutlined,
  EditOutlined,
  PlusOutlined,
  FileOutlined,
  BarChartOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  DownloadOutlined,
  ReloadOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";

const pageTitleMap = {
  "": { name: "Home", icon: <HomeOutlined /> },
  "/": { name: "Home", icon: <HomeOutlined /> },
  "/home": { name: "Home", icon: <HomeOutlined /> },
  "/users": {
    name: "Users",
    icon: <TeamOutlined />,
    path: "Administration /",
  },
  "/legacyUsers": {
    name: "Legacy Autolink Users",
    icon: <TeamOutlined />,
    path: "Administration /",
  },
  "/users/createuserform": {
    name: "Add User",
    icon: <UserAddOutlined />,
    path: "Administration / Users /",
  },
  "/users/updateuserform": {
    name: "User Details",
    icon: <EditOutlined />,
    path: "Administration / Users /",
  },
  "/agencies": {
    name: "Agencies",
    icon: <ShopOutlined />,
    path: "Administration /",
  },
  "/agencies/createagencyform": {
    name: "Add Agency",
    icon: <PlusOutlined />,
    path: "Administration / Agencies /",
  },
  "/agencies/updateagencyform": {
    name: "Agency Details",
    icon: <EditOutlined />,
    path: "Administration / Agencies /",
  },
  "/documents": {
    name: "Search Documents",
    icon: <FileSearchOutlined />,
    path: "Documents /",
  },
  "/batches": {
    name: "Search Batches",
    icon: <SearchOutlined />,
    path: "Batching /",
  },
  "/manualdocument": {
    name: "Add Manual Document",
    icon: <FileAddOutlined />,
    path: "Documents /",
  },
  "/downloads": {
    name: "Downloads",
    icon: <DownloadOutlined />,
  },
  "/onlinerenewals": {
    name: "Online Renewals",
    icon: <ReloadOutlined />,
  },
  "/movedocuments": {
    name: "Move Documents",
    icon: <SwapOutlined />,
  },
  "/loancalculator": {
    name: "Loan Calculator",
    icon: <CalculatorOutlined />,
  },
  "/managerenewals": {
    name: "Manage Renewals",
    icon: <SettingOutlined />,
  },
  "/createBatchForm": {
    name: "Process New Batch",
    icon: <PlusSquareOutlined />,
    path: "Batching /",
  },
  "/documents/updatedocumentform": {
    name: "Document Details",
    icon: <FileOutlined />,
  },
  "/manualdocument/createdocumentform": {
    name: "Document Details",
    icon: <FileOutlined />,
  },
  "/batches/updatebatchform": {
    name: "Batch Details",
    icon: <GroupOutlined />,
  },
  "/inventoryItems": {
    name: "Search Inventory",
    icon: <SearchOutlined />,
    path: "Inventory / Search /",
  },
  "/movements": {
    name: "Search Inventory Movements",
    icon: <SearchOutlined />,
    path: "Inventory / Search /",
  },
  "/receiveInventorySteps": {
    name: "Receive Inventory",
    icon: <SwapRightOutlined />,
    path: "Inventory / Move /",
  },
  "/transferInventorySteps": {
    name: "Transfer Inventory",
    icon: <SwapLeftOutlined />,
    path: "Inventory / Move /",
  },
  "/returnToICBCSteps": {
    name: "Return Inventory To ICBC",
    icon: <SwapLeftOutlined />,
    path: "Inventory / Move /",
  },
  "/allocateInventorySteps": {
    name: "Allocate Inventory",
    icon: <SwapOutlined />,
    path: "Inventory / Move /",
  },
  "/movement": {
    name: "Inventory Movement",
    icon: <SwapOutlined />,
    path: "Inventory /",
  },
  "/maintainInventory": {
    name: "Maintain Inventory",
    icon: <DatabaseOutlined />,
    path: "Inventory /",
  },
  "/onHandInventoryReport": {
    name: "On-hand Inventory Report",
    icon: <BarChartOutlined />,
    path: "Inventory / Reports /",
  },
  "/inventoryAuditReport": {
    name: "Inventory Audit Report",
    icon: <BarChartOutlined />,
    path: "Inventory / Reports /",
  },
  "/inventoryLogReport": {
    name: "Inventory Log Report",
    icon: <BarChartOutlined />,
    path: "Inventory / Reports /",
  },
  "/roadAgentDailyStock": {
    name: "Road Agent's Daily Stock",
    icon: <BarChartOutlined />,
    path: "Inventory / Reports /",
  },
  "/commissionReport": {
    name: "Commission Report",
    path: "Commissions /",
    icon: <BarChartOutlined />,
  },
  "/productionReport": {
    name: "Production Report",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/productionByDayHourReport": {
    name: "Production Summary by Day/Hour Report",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/coverageSummaryReport": {
    name: "Coverage Summary Report",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/dealerBillingReport": {
    name: "Dealer Billing Report",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/dataCollectionReport": {
    name: "Customer Data Collection Report",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/fleetGarageCommissionReport": {
    name: "Fleet and Garage Commission Report",
    icon: <BarChartOutlined />,
    path: "Commissions /",
  },
  "/textSummaryReport": {
    name: "Text Reminder Summary By Reply Type",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/bcarRetentionReport": {
    name: "BCautorenew Retention Report",
    icon: <BarChartOutlined />,
    path: "Renewals / Retention /",
  },
  "/retentionSummaryReport": {
    name: "Retention Summary Report",
    icon: <BarChartOutlined />,
    path: "Renewals / Retention /",
  },
  "/renewalListReport": {
    name: "Renewal List Report",
    icon: <BarChartOutlined />,
    path: "Renewals /",
  },
  "/scheduleOfVehiclesReport": {
    name: "Schedule Of Vehicles Report",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/confirmationOfInsuranceReport": {
    name: "Confirmation Of Insurance Report",
    icon: <BarChartOutlined />,
    path: "Reports /",
  },
  "/producers": {
    name: "Producer Codes",
    icon: <SettingOutlined />,
  },
  "/producers/updateproducercode": {
    name: "Update Producer Code",
    icon: <SettingOutlined />,
  },
  "/producers/createproducercode": {
    name: "Create Producer Code",
    icon: <SettingOutlined />,
  },
  "/commissionrates": {
    name: "Commission Rates",
    icon: <DollarCircleOutlined />,
  },
  "/commissionrates/updatecommissionrate": {
    name: "Update Commission Rate",
    icon: <DollarCircleOutlined />,
  },
  "/commissionrates/createcommissionrate": {
    name: "Create Commission Rate",
    icon: <DollarCircleOutlined />,
  },
  "/announcements": {
    name: "Announcements",
    icon: <SettingOutlined />,
  },
  "/announcements/updateannouncement": {
    name: "Update Announcement",
    icon: <SettingOutlined />,
  },
  "/announcements/createannouncement": {
    name: "Create Announcement",
    icon: <SettingOutlined />,
  },
  "/appLogs": {
    name: "App Logs",
    icon: <SettingOutlined />,
  },
  "/appLogs/appLogForm": {
    name: "App Log Details",
    icon: <SettingOutlined />,
  },
  "/fleetPolicyToClientMap": {
    name: "Fleet / Policy To Epic Client Map",
    icon: <SettingOutlined />,
  },
};

export default pageTitleMap;
