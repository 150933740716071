import { Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import { clearMoveDocuments } from "../../../store/moveDocuments/moveDocumentsActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { ManageDocuments } from "../../shared/securityRoleTasks";
import MoveDocumentsForm from "./MoveDocumentsForm";
import { StopOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";

const MoveDocuments = () => {
  //
  const dispatch = useDispatch();

  //
  const [filteredAgencies, setFilteredAgencies] = useState(null);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const isAdmin = useSelector((state) => state.actor.details.data.IsAdmin);
  const moveDocumentsAgencyList = useSelector(
    (state) => state.actor.details.data.MoveDocumentsAgencyList
  );

  const agencies = useSelector((state) => state.agency.listCompact);
  const agenciesLoading = useSelector((state) => state.agency.loading);

  //
  const pageLoaded =
    brokerId != null &&
    agencies != null &&
    !agenciesLoading &&
    filteredAgencies != null;

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ManageDocuments));
    }
  }, [brokerId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearListCompactAction());
      dispatch(clearMoveDocuments());
    };
  }, [dispatch]);

  useEffect(() => {
    if (agencies == null || isEmpty(agencies)) {
      setFilteredAgencies(null);
    } else {
      var filtered = agencies.filter(
        (a) => moveDocumentsAgencyList?.includes(a.Number) ?? false
      );
      setFilteredAgencies(filtered);
    }
  }, [agencies]);

  return (
    <PermissionWrapper entityName="MoveDocuments">
      <Spin spinning={!pageLoaded} delay={500}>
        {pageLoaded && filteredAgencies.length > 1 && (
          <MoveDocumentsForm agencies={filteredAgencies}></MoveDocumentsForm>
        )}
        {pageLoaded &&
          isAdmin &&
          !isEmpty(agencies) &&
          filteredAgencies.length < 2 && (
            <Typography.Title type="danger" level={5}>
              <Space>
                <StopOutlined />
                Please switch support account to Reliance Insurance brokerage!
              </Space>
            </Typography.Title>
          )}
      </Spin>
    </PermissionWrapper>
  );
};
export default MoveDocuments;
