import produce from "immer";
import { Actions } from "./moveDocumentsConstants";

const initialState = {
  data: null,
  loading: false,
  operationResult: null,
  selectedRows: [],
  movement: {
    loading: false,
    operationResult: null,
  },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_MOVE_DOCUMENTS:
        draft = initialState;
        return draft;
      case Actions.LIST_MOVE_DOCUMENTS:
        draft.loading = true;
        return draft;
      case Actions.LIST_MOVE_DOCUMENTS_SUCCEEDED:
        draft.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.loading = false;
        return draft;
      case Actions.SET_SELECTED_ROWS:
        draft.selectedRows = action.payload.selectedRows;
        return draft;
      case Actions.MOVE_DOCUMENTS_TO_AGENCY:
        draft.movement.loading = true;
        return draft;
      case Actions.MOVE_DOCUMENTS_TO_AGENCY_SUCCEEDED:
        draft.movement.loading = false;
        draft.movement.operationResult = action.payload.Operation;
        return draft;
      case Actions.CLEAR_SELECTED_ROWS:
        draft.selectedRows = [];
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.movement.operationResult = null;
        draft.operationResult = null;
        return draft;
      default:
        return state;
    }
  });
};
