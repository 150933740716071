import React from "react";
import { columnTypes } from "../../shared/table/columnTypes";
import { useDispatch } from "react-redux";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { removeInventoryMovementItemAction } from "../../../store/inventory/inventoryActions";
import { drop } from "lodash";

const shortColumn = 80;
const mediumColumn = 100;
const iconColumn = 30;

export const moveInventoryItemsColumns = (
  movementKey,
  hideRemoveItemColumn
) => {
  const dispatch = useDispatch();

  const initialColumns = [
    {
      type: columnTypes.ACTION,
      key: "RemoveItem",
      render: (record) => (
        <Popconfirm
          title="Remove selected item?"
          onConfirm={() =>
            dispatch(
              removeInventoryMovementItemAction(
                movementKey,
                record.InventoryMovementDetailKey
              )
            )
          }
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="link" size="small" style={{ padding: 0 }}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      fixed: "left",
      width: 36,
    },
    {
      title: "Series From",
      dataIndex: "ItemNumberFrom",
      type: columnTypes.TEXT,
      width: 105,
    },
    {
      title: "Series To",
      dataIndex: "ItemNumberTo",
      type: columnTypes.TEXT,
      width: 105,
    },
    {
      title: "Description",
      dataIndex: "ItemDescription",
      type: columnTypes.TEXT,
      width: 220,
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      type: columnTypes.NUMBER,
      width: 85,
      align: "right"
    },
  ];

  const columns = hideRemoveItemColumn
    ? drop(initialColumns, 1)
    : initialColumns;

  return columns;
};
