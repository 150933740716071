import { customGet, customPost } from "../../../api/customApi";
import entityType from "../../../api/entityType";
import genericActionType from "../../../api/genericActionType";
import Actions from "./userConstants";

export const listUsersAction = (agencyKeys) => async (dispatch) => {
  dispatch({ type: Actions.LIST_USERS });
  let trasformedValues = agencyKeys;
  if (agencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    const allAgencies = !agencies ? [] : agencies.map((a) => a.Id);
    trasformedValues = allAgencies;
  }
  const payload = await customPost(entityType.USER, "List", trasformedValues);
  dispatch({ type: Actions.LIST_USERS_SUCCEEDED, payload });
};

export const listLegacyUsersAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.LIST_LEGACY_USERS });
  let trasformedValues = values;
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    const allAgencies = !agencies ? [] : agencies.map((a) => a.Id);
    trasformedValues = { ...values, AgencyKeys: allAgencies };
  }
  const payload = await customPost(
    entityType.USER,
    "ListLegacyUsers",
    trasformedValues
  );
  dispatch({ type: Actions.LIST_LEGACY_USERS_SUCCEEDED, payload });
};

export const createUserAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.CREATE_USER });
  const payload = await customPost(entityType.USER, "Create", values);
  dispatch({ type: Actions.CREATE_USER_SUCCEEDED, payload });
};

export const resendEmailConfirmationAction = (userId) => async (dispatch) => {
  dispatch({ type: Actions.RESEND_EMAIL_CONFIRMATION });
  let data = {
    UserId: userId,
  };
  const payload = await customPost(
    entityType.IDENTITY,
    "ResendEmailConfirmation",
    data,
    { identity: true }
  );
  dispatch({ type: Actions.RESEND_EMAIL_CONFIRMATION_SUCCEEDED, payload });
};

export const updateUserAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.UPDATE_USER });
  const payload = await customPost(entityType.USER, "Update", values);
  dispatch({ type: Actions.UPDATE_USER_SUCCEEDED, payload });
};

export const readUserAction = (userKey) => async (dispatch) => {
  dispatch({ type: Actions.READ_USER });
  const payload = await customGet(entityType.USER, genericActionType.READ, {
    params: {
      userKey: userKey,
    },
  });
  dispatch({ type: Actions.READ_USER_SUCCEEDED, payload });
};

export const activateLegacyUser = (userKey) => async (dispatch) => {
  dispatch({ type: Actions.ACITVATE_LEGACY_USER });
  const payload = await customGet(entityType.USER, "ActivateLegacyUser", {
    params: {
      userKey: userKey,
    },
  });
  dispatch({ type: Actions.ACITVATE_LEGACY_USER_SUCCEEDED, payload });
};

export const unlockUserAccount = (id4UserId) => async (dispatch) => {
  dispatch({ type: Actions.UNLOCK_USER });
  const payload = await customGet(entityType.USER, "UnlockAccount", {
    params: {
      id4UserId: id4UserId,
    },
  });
  dispatch({ type: Actions.UNLOCK_USER_SUCCEEDED, payload });
};

export const deleteUser = (model) => async (dispatch) => {
  dispatch({ type: Actions.DELETE_USER });
  const payload = await customPost(entityType.USER, "Delete", model);
  dispatch({ type: Actions.DELETE_USER_SUCCEEDED, payload });
};

export const bulkDeleteUsers = (model) => async (dispatch) => {
  dispatch({ type: Actions.BULK_DELETE_USERS });
  const payload = await customPost(entityType.USER, "BulkDelete", model);
  dispatch({ type: Actions.BULK_DELETE_USERS_SUCCEEDED, payload });
};

export const toggleUserEnabledAction = (id4UserId) => async (dispatch) => {
  dispatch({ type: Actions.TOGGLE_USER_ENABLED });
  const payload = await customGet(entityType.USER, "ToggleEnabled", {
    params: {
      id4UserId: id4UserId,
    },
  });
  dispatch({ type: Actions.TOGGLE_USER_ENABLED_SUCCEEDED, payload });
};

export const clearDeleteOperationResultsAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_DELETE_OPERATION_RESULTS });
};

export const clearBulkDeleteOperationResults = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_BULK_DELETE_OPERATION_RESULT });
};

export const clearIdentityOperationResult = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_IDENTITY_OPERATION_RESULT });
};

export const clearSelectedUserAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_USER });
};

export const clearSelectedLegacyUserAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_LEGACY_USER });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const clearLegacyOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_LEGACY_OPERATION_RESULT });
};
