import produce from "immer";
import { Actions } from "./reportConstants";

const initialState = {
  url: null,
  isFetching: false,
  isExporting: false,
  isDownloading: false,
  isDownloadingCustomFile: false,
  isModalVisible: false,
  isViewerVisible: false,
  operationResult: null,
  input: null,
  extraFiltersVisible: false,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // FETCHING
      case Actions.FETCH_REPORT_REQUEST:
        draft.isViewerVisible = true;
        draft.isFetching = true;
        draft.input = action.payload.input;
        return draft;
      case Actions.FETCH_REPORT_SUCCESS:
        draft.isFetching = false;
        draft.url = action.payload.url;
        return draft;
      case Actions.FETCH_REPORT_FAILURE:
        draft.isFetching = false;
        draft.operationResult = "";
        draft.url = null;
        return draft;

      // EXPORTING
      case Actions.EXPORT_REPORT_REQUEST:
        draft.isExporting = true;
        return draft;
      case Actions.EXPORT_REPORT_SUCCESS:
        draft.isExporting = false;
        return draft;
      case Actions.EXPORT_REPORT_FAILURE:
        draft.isExporting = false;
        draft.operationResult = "";
        return draft;

      // DOWNLOADING PDF REPORT
      case Actions.DOWNLOAD_REPORT_REQUEST:
        draft.isDownloading = true;
        if (action.payload != null && action.payload.input != null) {
          draft.input = action.payload.input;
        }
        return draft;
      case Actions.DOWNLOAD_REPORT_SUCCESS:
        draft.isDownloading = false;
        return draft;
      case Actions.DOWNLOAD_REPORT_FAILURE:
        draft.isDownloading = false;
        draft.operationResult = "";
        return draft;

      // DOWNLOADING CUSTOM FILE
      case Actions.DOWNLOAD_CUSTOM_FILE_REQUEST:
        draft.isDownloadingCustomFile = true;
        return draft;
      case Actions.DOWNLOAD_CUSTOM_FILE_SUCCESS:
        draft.isDownloadingCustomFile = false;
        return draft;
      case Actions.DOWNLOAD_CUSTOM_FILE_FAILURE:
        draft.isDownloadingCustomFile = false;
        draft.operationResult = {
          Outcome: "Error",
          Message: action.payload.message ?? "An unexpected error occurred.",
        };
        return draft;

      // OTHER
      case Actions.PRINTING_FAILURE:
        draft.operationResult = {
          Outcome: "Error",
          Message:
            "Something went wrong while opening the print window so the file has been downloaded instead. Please contact our support team if this issue persists.",
        };
        return draft;
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.SET_MODAL_VISIBILITY:
        draft.isModalVisible = action.payload.visible;
        return draft;
      case Actions.SET_EXTRA_FILTERS_VISIBILITY:
        draft.extraFiltersVisible = action.payload.visible;
        return draft;
      case Actions.RESET_REPORT_STATE:
        draft = initialState;
        return draft;
      default:
        return state;
    }
  });
};
