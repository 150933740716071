import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  clearSelectedAction,
  listAgenciesAction,
  clearOperationResultAction,
} from "../../store/agency/agencyActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import {
  PreservableStateLink,
  PreservableStatePageWrapper,
  usePreservableNavigate,
} from "../shared/PreservableStatePageWrapper";
import { SearchableTable } from "../shared/table/SearchableTable";
import { columnTypes } from "../shared/table/columnTypes";
import { tableNames } from "../shared/table/tableNames";
import CreateAgencyForm from "./CreateAgencyForm";
import UpdateAgencyForm from "./UpdateAgencyForm";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";

const Agencies = () => {
  //
  const dispatch = useDispatch();

  //
  return (
    <PreservableStatePageWrapper
      onCleanup={() => dispatch(clearSelectedAction())}
      tableNames={[tableNames.Agencies]}
    >
      <AgenciesBody />
    </PreservableStatePageWrapper>
  );
};

const AgenciesBody = () => {
  //
  const dispatch = useDispatch();
  const preservableNavigate = usePreservableNavigate();

  //
  const list = useSelector((state) => state.agency.list);
  const loading = useSelector((state) => state.agency.loading);
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const isAdmin = useSelector((state) => state.actor.details.data.IsAdmin);
  const operationResult = useSelector((state) => state.agency.operationResult);

  //
  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult]);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesAction(brokerId));
    }
  }, [brokerId, dispatch]);

  const columns = [
    {
      title: "Agency Number",
      dataIndex: "AgencyNumber",
      type: columnTypes.TEXT,
      width: 90,
      render: (value, record) => (
        <PreservableStateLink
          to={`/agencies/updateagencyform/${record.AgencyKey}`}
        >
          {value}
        </PreservableStateLink>
      ),
    },

    {
      title: "Agency Name",
      dataIndex: "AgencyName",
      width: 250,
      type: columnTypes.TEXT,
    },
    {
      title: "Address 1",
      dataIndex: "Address1",
      width: 250,
      type: columnTypes.TEXT,
    },
    {
      title: "Address 2",
      dataIndex: "Address2",
      width: 175,
      type: columnTypes.TEXT,
    },
    {
      title: "City",
      dataIndex: "City",
      width: 150,
      type: columnTypes.TEXT,
    },
    {
      title: "Province",
      dataIndex: "StateProvinceCode",
      width: 90,
      type: columnTypes.TEXT,
    },
    {
      title: "Postal Code",
      dataIndex: "ZipPostalCode",
      width: 90,
      type: columnTypes.TEXT,
    },

    {
      title: "Active",
      dataIndex: "Active",
      width: 65,
      type: columnTypes.BOOLEAN,
    },
    {
      title: "Automated Download",
      dataIndex: "AutomatedDownload",
      width: 100,
      type: columnTypes.BOOLEAN,
    },
  ];

  const addButton = (
    <Button
      size={"small"}
      type="primary"
      onClick={() => preservableNavigate("/agencies/createagencyform")}
    >
      <PlusOutlined />
      Add New
    </Button>
  );
  const toolbarButtons = [addButton];

  return (
    <PermissionWrapper entityName="Agencies">
      <Routes>
        <Route
          path=""
          element={
            <Row>
              <Col>
                <SearchableTable
                  dataSource={list}
                  columns={columns}
                  loading={loading}
                  buttons={isAdmin === true ? toolbarButtons : null}
                  size="medium"
                  rowKey={(r) => r.AgencyKey}
                  tableName={tableNames.Agencies}
                  scroll={{ y: 650, x: 1265 }}
                />
              </Col>
            </Row>
          }
        />
        <Route path="createagencyform" element={<CreateAgencyForm />} />
        <Route
          path="updateagencyform/:agencyKey"
          element={<UpdateAgencyForm />}
        />
      </Routes>
    </PermissionWrapper>
  );
};
export default Agencies;
