import { Button, Col, Divider, Form, Row } from "antd";
import React, { useEffect, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import {
  clearBatchListAction,
  listAction,
  setSearchCriteriaAction,
} from "../../../store/batching/batchingActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import { extraShortInputStyle } from "../../shared/form/formInputStyles";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import { dateFormat, toEndOfDay, toStartOfDay } from "../../shared/formatting";
import { ViewDocuments } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import { searchBatchesSchema } from "./batchSchemas";

// Constants
const initialValues = {
  AgencyKey: "",
  ICBCBatch: "",
  AgencyBatch: "",
  FixedDateRange: fixedDateRanges.YTD,
  SubmittedDate: ["", ""],
};

const firstLayout = {
  labelCol: { span: 6, sm: 5, md: 5, lg: 8, xl: 6, xxl: 5 },
  wrapperCol: { span: 18, sm: 19, md: 19, lg: 15, xl: 17, xxl: 18 },
};

const secondLayout = {
  labelCol: { xs: 10, sm: 10, md: 10, lg: 14, xl: 14, xxl: 12 },
  wrapperCol: { xs: 14, sm: 14, md: 14, lg: 10, xl: 10, xxl: 12 },
};

const SearchBatchesForm = ({ loading, setClearAll, searchCriteria }) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: searchCriteria != null ? searchCriteria : initialValues,
    resolver: yupResolver(searchBatchesSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewDocuments));
    }
  }, [brokerId]);

  useEffect(() => {
    return () => {
      // Clear the list of all agencies
      dispatch(clearListCompactAction());
    };
  }, []);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      if (searchCriteria == null) {
        resetFieldsToDefault();
      }
    }
  }, [agencies, homeAgency]);

  const resetFieldsToDefault = () => {
    var initialAgency = getInitialAgency(homeAgency, agencies);
    reset({
      ...initialValues,
      AgencyKey: initialAgency,
      SubmittedDate: [
        toStartOfDay(dayjs().startOf("year")),
        toEndOfDay(dayjs().endOf("day")),
      ],
    });
    trigger();
    dispatch(setSearchCriteriaAction(null));
  };

  const onSubmitHandler = () => {
    dispatch(listAction(getValues()));
    dispatch(setSearchCriteriaAction(getValues()));
    reset(getValues());
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandler)()}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={15} xl={13} xxl={10}>
                <AgencySelectEx
                  isLoading={defaultValues.AgencyKey === ""}
                  control={control}
                  layout={firstLayout}
                  name="AgencyKey"
                  required={true}
                  formLabel="Agency"
                  agencies={currentAgencies}
                />

                <AdvancedRangePicker
                  formItem={{
                    layout: firstLayout,
                    required: true,
                    label: "Submitted",
                  }}
                  control={control}
                  allowClear={true}
                  disableFutureDates={true}
                  name="SubmittedDate"
                  format={dateFormat}
                  setValue={setValue}
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                />
              </Col>

              <Col xs={24} sm={24} md={24} lg={9} xl={6} xxl={4}>
                <Row>
                  <Col span={12} lg={24} xl={24} xxl={24}>
                    <CustomInput
                      control={control}
                      layout={secondLayout}
                      formLabel="ICBC Batch"
                      name="ICBCBatch"
                      style={extraShortInputStyle}
                      maxLength={7}
                    />
                  </Col>
                  <Col span={12} lg={24} xl={24} xxl={24}>
                    <CustomInput
                      control={control}
                      layout={secondLayout}
                      formLabel="Agency Batch"
                      name="AgencyBatch"
                      maxLength={4}
                      style={extraShortInputStyle}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={5} xxl={4}>
                <Row justify="center" gutter={8}>
                  <Col>
                    <Button
                      disabled={!isValid}
                      autoFocus={true}
                      id="processButton"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      <SearchOutlined />
                      Search
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        resetFieldsToDefault();
                        dispatch(clearBatchListAction());
                        setClearAll(true);
                      }}
                    >
                      Clear Results
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Divider style={{ marginTop: 16, marginBottom: 8 }} />
    </div>
  );
};
export default SearchBatchesForm;
