import { isEmpty, isNull, isUndefined } from "lodash";
import React from "react";
import Highlighter from "react-highlight-words";
import { PreservableStateLink } from "../../shared/PreservableStatePageWrapper";
import { columnTypes } from "../../shared/table/columnTypes";
import { MoreActionsMenu } from "./MoreActionsMenu";
import { useSelector } from "react-redux";

export const getDocumentColumns = (
  searchByColumns,
  additionalColumns,
  searchText,
  setQuickEdit
) => {
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const renderSearchByColumn = (text) => (
    <Highlighter
      highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ""}
    />
  );

  const renderSearchByLinkColumn = (value, record) => (
    <PreservableStateLink
      to={`/documents/updatedocumentform/${record.DocumentKey}`}
    >
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={value ? value.toString() : ""}
      />
    </PreservableStateLink>
  );

  const fixedColumns = [
    {
      title: "Policy",
      dataIndex: "PolicyNumber",
      type: columnTypes.TEXT,
      fixed: "left",
      width: 72,
      render: (value, record) => renderSearchByLinkColumn(value, record),
    },
    {
      title: "Insured",
      dataIndex: "InsuredName",
      type: columnTypes.TEXT,
      width: 220,
      fixed: "left",
      render: (value, record) =>
        maskSensitiveData === true ? (
          <PreservableStateLink
            to={`/documents/updatedocumentform/${record.DocumentKey}`}
          >
            <div className="blurry-text-link">{value}</div>
          </PreservableStateLink>
        ) : (
          renderSearchByLinkColumn(value, record)
        ),
    },
    {
      title: "Plate",
      dataIndex: "PlateNumber",
      type: columnTypes.TEXT,
      width: 72,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Txn Date",
      dataIndex: "EntryDateTime",
      type: columnTypes.DATE,
      width: 95,
    },
    {
      title: "Effective",
      dataIndex: "PolicyEffectiveDate",
      type: columnTypes.DATE,
      width: 95,
    },
    {
      title: "Expiry",
      dataIndex: "PolicyExpiryDate",
      type: columnTypes.DATE,
      width: 95,
    },

    {
      title: "Transaction",
      dataIndex: "TransactionTypeDesc",
      type: columnTypes.TEXT,
      width: 115,
    },

    {
      title: "Timestamp",
      dataIndex: "DCID",
      type: columnTypes.TEXT,
      width: 115,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "PC1",
      dataIndex: "ProducerCode1",
      type: columnTypes.TEXT,
      width: 60,
    },
    {
      title: "PC2",
      dataIndex: "ProducerCode2",
      type: columnTypes.TEXT,
      width: 60,
    },

    {
      title: "Vehicle",
      dataIndex: "Vehicle",
      type: columnTypes.TEXT,
      width: 200,
    },

    {
      title: "Reg Num",
      dataIndex: "RegNumber",
      type: columnTypes.TEXT,
      width: 75,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Fleet",
      dataIndex: "FleetNumber",
      type: columnTypes.TEXT,
      width: 65,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Fleet Unit",
      dataIndex: "FleetUnitNumber",
      type: columnTypes.TEXT,
      width: 120,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Product",
      dataIndex: "Product",
      type: columnTypes.TEXT,
      width: 85,
    },
    {
      title: "Batch",
      dataIndex: "AgencyBatchNo",
      type: columnTypes.TEXT,
      width: 85,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Amount",
      dataIndex: "AmountDue",
      type: columnTypes.CURRENCY,
      width: 95,
    },
    {
      title: "Channel",
      dataIndex: "Channel",
      type: columnTypes.TEXT,
      width: 100,
    },
    {
      title: "Document Number",
      dataIndex: "DocumentNumber",
      type: columnTypes.TEXT,
      width: 115,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Agency",
      dataIndex: "AgencyNumberAndName",
      type: columnTypes.TEXT,
      width: 270,
    },
    {
      title: "",
      type: columnTypes.ACTION,
      render: (record) => (
        <MoreActionsMenu setQuickEdit={setQuickEdit} record={record} />
      ),
      fixed: "right",
      width: 36,
      align: "center",
    },
  ];

  const columnsNotDisplayed = [
    {
      title: "DL",
      dataIndex: "DriversLicense",
      type: columnTypes.TEXT,
      width: 115,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Principal DL",
      dataIndex: "PrincipalDriversLicense",
      type: columnTypes.TEXT,
      width: 115,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Lessee DL",
      dataIndex: "LesseeDriversLicense",
      type: columnTypes.TEXT,
      width: 115,
      render: (text) => renderSearchByColumn(text),
    },
    {
      title: "Lessor",
      dataIndex: "LessorName",
      type: columnTypes.TEXT,
      width: 220,
      render: (text) =>
        maskSensitiveData === true ? (
          <div className="blurry-text">{text}</div>
        ) : (
          renderSearchByColumn(text)
        ),
    },
    {
      title: "VIN",
      dataIndex: "VIN",
      type: columnTypes.TEXT,
      width: 160,
      render: (text) => renderSearchByColumn(text),
    },
  ];

  const displayColumns = [...fixedColumns];
  if (!isNull(searchByColumns) && !isUndefined(searchByColumns)) {
    const searchByPolicyIndex = searchByColumns.findIndex(
      (c) => c === "PolicyNumber"
    );
    const searchByInsuredName = searchByColumns.findIndex(
      (c) => c === "InsuredName"
    );
    const searchAndExtraColumns = !isEmpty(additionalColumns)
      ? searchByColumns.concat(additionalColumns)
      : searchByColumns;
    searchAndExtraColumns.forEach((column, index) => {
      if (column !== "PolicyNumber" && column !== "InsuredName") {
        const columnIndex = fixedColumns.findIndex(
          (c) => c.dataIndex === column
        );
        let newIndex = index + 2;
        newIndex =
          searchByPolicyIndex !== -1 && index > searchByPolicyIndex
            ? newIndex - 1
            : newIndex;
        newIndex =
          searchByInsuredName !== -1 && index > searchByInsuredName
            ? newIndex - 1
            : newIndex;

        if (columnIndex > 0) {
          //if in the fixed list, move to the 3rd column
          const columnToMove = fixedColumns[columnIndex];
          columnToMove.fixed = "left";
          displayColumns.splice(columnIndex, 1);
          displayColumns.splice(newIndex, 0, columnToMove);
        } else {
          const columnToAdd = columnsNotDisplayed.find(
            (c) => c.dataIndex === column
          );
          if (!isUndefined(columnToAdd)) {
            columnToAdd.fixed = "left";
            displayColumns.splice(newIndex, 0, columnToAdd);
          }
        }
      }
    });
  }
  return displayColumns;
};
