import { Form, InputNumber } from "antd";
import React from "react";

import { useController } from "react-hook-form";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";

const CustomInputNumber = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = React.memo((props) => {
  const { field, error } = props;

  var inputProps = { ...props };
  delete inputProps.control;
  delete inputProps.formLabel;
  delete inputProps.layout;
  delete inputProps.formTooltip;
  delete inputProps.field;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  delete inputProps.error;
  delete inputProps.required;

  return (
    <Form.Item
      {...props.layout}
      label={props.formLabel}
      name={props.name}
      style={props.formStyle}
      validateStatus={error != null ? "error" : ""}
      help={error != null ? error.message : null}
      tooltip={props.formTooltip}
      required={props.required}
    >
      <div>
        <InputNumber
          {...field}
          {...inputProps}
          controls={false}
          onChange={(v, o) => {
            field.onChange(v, o);
            if (props.afterChange != null) {
              props.afterChange(v);
            }
          }}
        />
      </div>
    </Form.Item>
  );
}, checkHookFormFieldPropsEquality);
export default CustomInputNumber;
