import React from "react";
import { Tooltip, Typography } from "antd";

const { Paragraph, Text } = Typography;

const SearchTip = () => {
  const content = (
    <div>
      <div>
        To search by{" "}
        <Text underline style={{ color: "white" }}>
          Insured
        </Text>{" "}
        enter the full or partial name starting with Last (or Company) Name.
      </div>
      <div>
        <Paragraph />
      </div>
      {/* <div>To search by other criteria type the search value in full.</div>
      <div>
        <Paragraph />
      </div> */}
      <div>
        To search by{" "}
        <Text underline style={{ color: "white" }}>
          Plate
        </Text>
        ,{" "}
        <Text underline style={{ color: "white" }}>
          Policy
        </Text>
        ,{" "}
        <Text underline style={{ color: "white" }}>
          Timestamp
        </Text>
        ,{" "}
        <Text underline style={{ color: "white" }}>
          Driver's License
        </Text>
        ,{" "}
        <Text underline style={{ color: "white" }}>
          Registration
        </Text>
        ,{" "}
        <Text underline style={{ color: "white" }}>
          Vin
        </Text>
        ,{" "}
        <Text underline style={{ color: "white" }}>
          Fleet
        </Text>{" "}
        or{" "}
        <Text underline style={{ color: "white" }}>
          Document Number
        </Text>{" "}
        enter the value in full.
      </div>
    </div>
  );
  return <Tooltip>{content}</Tooltip>;
};
export default SearchTip;
