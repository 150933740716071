import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { Actions } from "./producerConstants";

export const listPCode1ForReportParameters =
  (values) => async (dispatch, getState) => {
    dispatch({ type: Actions.LIST_PCODE1_FOR_REPORT_PARAMETERS });

    const actor = getState().actor;
    const brokerId = actor && actor.details.data.BrokerId;

    const payload = await customPost(
      entityType.PRODUCER,
      "ListForReportParameters",
      { ...values, ProducerNumber: 1, BrokerId: brokerId }
    );

    dispatch({
      type: Actions.LIST_PCODE1_FOR_REPORT_PARAMETERS_SUCCEEDED,
      payload,
    });
  };

export const listPCode2ForReportParameters =
  (values) => async (dispatch, getState) => {
    dispatch({ type: Actions.LIST_PCODE2_FOR_REPORT_PARAMETERS });

    const actor = getState().actor;
    const brokerId = actor && actor.details.data.BrokerId;

    const payload = await customPost(
      entityType.PRODUCER,
      "ListForReportParameters",
      { ...values, ProducerNumber: 2, BrokerId: brokerId }
    );

    dispatch({
      type: Actions.LIST_PCODE2_FOR_REPORT_PARAMETERS_SUCCEEDED,
      payload,
    });
  };

export const listProducerCodes = (values) => async (dispatch) => {
  dispatch({ type: Actions.LIST_PRODUCER_CODES });
  let trasformedValues = values;
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    const allAgencies = !agencies ? [] : agencies.map((a) => a.Id);
    trasformedValues = { ...values, AgencyKeys: allAgencies };
  }
  const payload = await customPost(
    entityType.PRODUCER,
    genericActionType.LIST,
    trasformedValues
  );
  dispatch({ type: Actions.LIST_PRODUCER_CODES_SUCCEEDED, payload });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const deleteProducerCode = (values) => async (dispatch) => {
  dispatch({ type: Actions.DELETE_PRODUCER_CODE });
  let trasformedValues = values;
  if (values.AgencyKeys == [0]) {
    const state = getState();
    const agencies = state.agency.listCompact;
    const allAgencies = !agencies ? [] : agencies.map((a) => a.Id);
    trasformedValues = { ...values, AgencyKeys: allAgencies };
  }
  const payload = await customPost(
    entityType.PRODUCER,
    genericActionType.DELETE,
    trasformedValues
  );
  dispatch({
    type: Actions.DELETE_PRODUCER_CODE_SUCCEEDED,
    payload,
  });
};

export const readProducerCode = (producerCodeKey) => async (dispatch) => {
  dispatch({ type: Actions.READ_PRODUCER_CODE });
  const payload = await customGet(entityType.PRODUCER, genericActionType.READ, {
    params: {
      producerCodeKey: producerCodeKey,
    },
  });
  dispatch({
    type: Actions.READ_PRODUCER_CODE_SUCCEEDED,
    payload,
  });
};

export const clearSelectedProducerCode = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED_PRODUCER_CODE });
};

export const updateProducerCode = (values) => async (dispatch) => {
  dispatch({ type: Actions.UPDATE_PRODUCER_CODE });
  const payload = await customPost(
    entityType.PRODUCER,
    genericActionType.UPDATE,
    values
  );
  dispatch({ type: Actions.UPDATE_PRODUCER_CODE_SUCCEEDED, payload });
};

export const createProducerCode = (values) => async (dispatch) => {
  dispatch({ type: Actions.CREATE_PRODUCER_CODE });
  const payload = await customPost(
    entityType.PRODUCER,
    genericActionType.CREATE,
    values
  );
  dispatch({ type: Actions.CREATE_PRODUCER_CODE_SUCCEEDED, payload });
};

export const listUsersForProducerCodes = (brokerId) => async (dispatch) => {
  dispatch({ type: Actions.LIST_USERS_FOR_PRODUCER_CODES });
  const payload = await customGet(
    entityType.USER,
    "ListCompactForAllowedAgencies",
    {
      params: {
        brokerId,
      },
    }
  );
  dispatch({
    type: Actions.LIST_USERS_FOR_PRODUCER_CODES_SUCCEEDED,
    payload,
  });
};

export const listAgenciesForProducerCodes =
  (brokerId, taskId) => async (dispatch) => {
    dispatch({ type: Actions.LIST_AGENCIES_FOR_PRODUCER_CODES });
    const payload = await customGet(
      entityType.AGENCY,
      "ListAgenciesCompactForProducerCodes",
      {
        params: {
          brokerId,
          taskId,
        },
      }
    );
    dispatch({
      type: Actions.LIST_AGENCIES_FOR_PRODUCER_CODES_SUCCEEDED,
      payload,
    });
  };

export const setSaveAndAddFlag = (flag) => async (dispatch) => {
  dispatch({ type: Actions.SET_SAVE_AND_ADD_FLAG, flag });
};

export const clearCopyAddInitialValues = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_COPY_ADD_INITIAL_VALUES });
};

export const setCopyAddInitialValues = (values) => async (dispatch) => {
  dispatch({ type: Actions.SET_COPY_ADD_INITIAL_VALUES, values });
};

export const clearUsersForProducerCodes = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_USERS_FOR_PRODUCER_CODES });
};

export const setSelectedCodeNumber = (number) => async (dispatch) => {
  dispatch({ type: Actions.SET_SELECTED_CODE_NUMBER, number });
};
