export const Actions = {
  LIST_PCODE1_FOR_REPORT_PARAMETERS: "LIST_PCODE1_FOR_REPORT_PARAMETERS",
  LIST_PCODE1_FOR_REPORT_PARAMETERS_SUCCEEDED:
    "LIST_PCODE1_FOR_REPORT_PARAMETERS_SUCCEEDED",
  LIST_PCODE2_FOR_REPORT_PARAMETERS: "LIST_PCODE2_FOR_REPORT_PARAMETERS",
  LIST_PCODE2_FOR_REPORT_PARAMETERS_SUCCEEDED:
    "LIST_PCODE2_FOR_REPORT_PARAMETERS_SUCCEEDED",
  LIST_PRODUCER_CODES: "LIST_PRODUCER_CODES",
  LIST_PRODUCER_CODES_SUCCEEDED: "LIST_PRODUCER_CODES_SUCCEEDED",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  DELETE_PRODUCER_CODE: "DELETE_PRODUCER_CODE",
  DELETE_PRODUCER_CODE_SUCCEEDED: "DELETE_PRODUCER_CODE_SUCCEEDED",
  READ_PRODUCER_CODE: "READ_PRODUCER_CODE",
  READ_PRODUCER_CODE_SUCCEEDED: "READ_PRODUCER_CODE_SUCCEEDED",
  CLEAR_SELECTED_PRODUCER_CODE: "CLEAR_SELECTED_PRODUCER_CODE",
  UPDATE_PRODUCER_CODE: "UPDATE_PRODUCER_CODE",
  UPDATE_PRODUCER_CODE_SUCCEEDED: "UPDATE_PRODUCER_CODE_SUCCEEDED",
  CREATE_PRODUCER_CODE: "CREATE_PRODUCER_CODE",
  CREATE_PRODUCER_CODE_SUCCEEDED: "CREATE_PRODUCER_CODE_CODE_SUCCEEDED",
  LIST_USERS_FOR_PRODUCER_CODES: "LIST_USERS_FOR_PRODUCER_CODES",
  LIST_USERS_FOR_PRODUCER_CODES_SUCCEEDED:
    "LIST_USERS_FOR_PRODUCER_CODES_SUCCEEDED",
  LIST_AGENCIES_FOR_PRODUCER_CODES: "LIST_AGENCIES_FOR_PRODUCER_CODES",
  LIST_AGENCIES_FOR_PRODUCER_CODES_SUCCEEDED:
    "LIST_AGENCIES_FOR_PRODUCER_CODES_SUCCEEDED",
  SET_SAVE_AND_ADD_FLAG: "SET_SAVE_AND_ADD_FLAG",
  SET_COPY_ADD_INITIAL_VALUES: "SET_COPY_ADD_INITIAL_VALUES",
  CLEAR_COPY_ADD_INITIAL_VALUES: "CLEAR_COPY_ADD_INITIAL_VALUES",
  CLEAR_USERS_FOR_PRODUCER_CODES: "CLEAR_USERS_FOR_PRODUCER_CODES",
  SET_SELECTED_CODE_NUMBER: "SET_SELECTED_CODE_NUMBER",
};
