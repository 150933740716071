export const Actions = {
  LIST_AGENCIES: "LIST_AGENCIES",
  LIST_AGENCIES_SUCCEEDED: "LIST_AGENCIES_SUCCEDED",
  CREATE_AGENCY: "CREATE_AGENCY",
  CREATE_AGENCY_SUCCEEDED: "CREATE_AGENCY_SUCCEEDED",
  READ_AGENCY: "READ_AGENCY",
  READ_AGENCY_SUCCEEDED: "READ_AGENCY_SUCCEEDED",
  UPDATE_AGENCY: "UPDATE_AGENCY",
  UPDATE_AGENCY_SUCCEEDED: "UPDATE_AGENCY_SUCCEEDED",
  LIST_AGENCIES_COMPACT: "LIST_AGENCIES_COMPACT",
  LIST_AGENCIES_COMPACT_SUCCEEDED: "LIST_AGENCIES_COMPACT_SUCCEEDED",
  LIST_AGENCIES_COMPACT_FOR_BATCHING: "LIST_AGENCIES_COMPACT_FOR_BATCHING",
  LIST_AGENCIES_COMPACT_FOR_BATCHING_SUCCEEDED:
    "LIST_AGENCIES_COMPACT_SUCCEEDED",
  CLEAR_OPERATION_RESULT: "CLEAR_AGENCY_OPERATION_RESULT",
  CLEAR_SELECTED: "CLEAR_SELECTED_AGENCY",
  CLEAR_LIST_COMPACT: "CLEAR_LIST_COMPACT",
  CLEAR_LIST_COMPACT_FOR_SUPPORT: "CLEAR_LIST_COMPACT_FOR_SUPPORT",
  CLEAR_BROKER_LIST_COMPACT: "CLEAR_BROKER_LIST_COMPACT",
  LIST_BROKERS_COMPACT: "LIST_BROKERS",
  LIST_BROKERS_COMPACT_SUCCEEDED: "LIST_BROKERS__SUCCEEDED",
  LIST_AGENCIES_COMPACT_FOR_SUPPORT: "LIST_AGENCIES_COMPACT_FOR_SUPPORT",
  LIST_AGENCIES_COMPACT_FOR_SUPPORT_SUCCEEDED:
    "LIST_AGENCIES_COMPACT_FOR_SUPPORT_SUCCEEDED",
};
