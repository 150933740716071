export const required = (value) =>
  value || typeof value === "number" ? undefined : "Required";
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

export const fleetGarageCommaListRegex = "^([a-zA-Z0-9.]{6,6},?s*)+$";
export const fleetGarageCommaListRegexOrEmptyString =
  "(^([a-zA-Z0-9.]{6,6},?s*)+$)|(^$)";
export const postalCodesCommaListRegex = "^([a-zA-Z0-9.]{6,6},?s*)+$";
export const postalCodesCommaListRegexOrEmptyString = "(^([a-zA-Z0-9.]{6,6},?s*)+$)|(^$)";
export const alphanumericRegex = "^[a-zA-Z0-9]*$";
export const alphanumericMessage = "Only letters / digits";
export const numericRegex = "^[0-9]*$";
export const numericRegexOrEmpty = "^[0-9]*$|(^$)";
export const numericMessage = "Only digits";
export const lettersUpperRegex = "^[A-Z]*$";
export const lengthMessage = "Must be ${length} characters";
export const minMessage = "Shorter than ${min} characters";
export const maxMessage = "Longer than ${max} characters";
export const minNumberMessage = "Less than ${min}";
export const maxNumberMessage = "Greater than ${max}";
export const lettersUpperMessage = "Upper case letters only";
export const requiredMessage = "Required";
export const dateRangeRequiredMessage =
  "Required, please select a fixed and/or a date range.";
export const fleetGarageCommaListMessage =
  "Enter 6-digit fleet number(s) and/or 6 character fleet/garage policy numbers (e.g. 68.1GS). Separate multiple entries by comma";
export const fleetCommaListMessage =
  "For multiple fleets use a comma-separated list. Each value must be 6 characters long.";
export const postalCodesCommaListMessage =
  "For multiple postal codes use a comma-separated list. Each value must be 6 characters long.";
export const lessOrEqualOneYearMessage =
  "Please select a period less or equal to one year.";
export const mailingLabelFormatRequiredMessage =
  "Required, please select a mailing label format.";

export const checkHookFormFieldPropsEquality = (prevProps, nextProps) => {
  return (
    prevProps.isDirty === nextProps.isDirty &&
    prevProps.isTouched === nextProps.isTouched &&
    prevProps.field.value === nextProps.field.value &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.linkedValue === nextProps.linkedValue
  );
};
