export const Actions = {
  CLEAR_MANAGE_RENEWALS: "CLEAR_MANAGE_RENEWALS",
  LIST_MANAGE_RENEWALS: "LIST_MANAGE_RENEWALS",
  LIST_MANAGE_RENEWALS_SUCCEEDED: "LIST_MANAGE_RENEWALS_SUCCEEDED",
  SET_SELECTED_ROWS: "SET_SELECTED_ROWS_MANAGE",
  CLEAR_SELECTED_ROWS: "CLEAR_SELECTED_ROWS_MANAGE",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT_MANAGE",
  BULK_UPDATE_MANAGE_CODES: "BULK_UPDATE_MANAGE_CODES",
  BULK_UPDATE_MANAGE_CODES_SUCCEEDED: "BULK_UPDATE_MANAGE_CODES_SUCCEEDED",
  CLEAR_BULK_UPDATE_MANAGE_DATA: "CLEAR_BULK_UPDATE_MANAGE_DATA",
};
