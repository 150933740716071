import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import { transformCommissionInput } from "../../../store/commission/commissionActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import PdfViewer from "../../shared/PdfViewer";
import ReportParamPanelHeader from "../../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../../shared/form/AgencyMultiSelectEx";
import BatchRangeSelectEx from "../../shared/form/BatchRangeSelectEx";
import { DrillDownSortField } from "../../shared/form/DrillDownSortField";
import DrillDownSortFieldSelectEx from "../../shared/form/DrillDownSortFieldSelectEx";
import ExtraReportFilterContainer from "../../shared/form/ExtraReportFiltersContainer";
import ProducerSelectEx from "../../shared/form/ProducerSelectEx";
import { SortGroupField } from "../../shared/form/SortGroupField";
import SortGroupFieldSelectEx from "../../shared/form/SortGroupFieldSelectEx";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toEndOfDay, toStartOfDay } from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import {
  ManageCommissions,
  ViewCommissionsFullAccess,
  ViewCommissionsLimitedAccess,
} from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import { commissionReportSchema } from "./commissionSchemas";
import {
  commissionReportTypeOptions,
  commissionReportTypes,
  sortGroupDrillDownFieldOptions,
  sortGroupDrillDownFields,
} from "./reportParameters";

const { Panel } = Collapse;

const partialInitialValues = {
  AgencyKeys: [],
  ReportType: commissionReportTypes.DETAIL,
  FixedDateRange: fixedDateRanges.LAST_MONTH,
  TransactionDate: ["", ""],
  IncludeUnbatched: true,
  IncludeAdjustments: true,
  //for array of bljects use this object notation, otherwise the deep copy of initial values does not work.
  SortGroupFields: [
    {
      Name: sortGroupDrillDownFields.BATCH_NUMBER,
      SortAscending: true,
      ToGroup: false,
      NewPage: false,
    },
  ],
  DrillDownSortFields: [
    {
      Name: sortGroupDrillDownFields.BATCH_NUMBER,
      SortAscending: true,
    },
  ],
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
  BatchNumberFrom: null,
  BatchNumberTo: null,
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const specialLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 5 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 19 },
    xxl: { span: 19 },
  },
};

const CommissionReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(commissionReportSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const actorTasks = useSelector((state) => state.actor.details.data.Tasks);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const isExporting = useSelector((state) => state.report.isExporting);
  const operationResult = useSelector((state) => state.report.operationResult);
  const isFetching = useSelector((state) => state.report.isFetching);
  const isViewerVisible = useSelector((state) => state.report.isViewerVisible);
  const taskKey = actorTasks.includes(ManageCommissions)
    ? ManageCommissions
    : actorTasks.includes(ViewCommissionsFullAccess)
    ? ViewCommissionsFullAccess
    : ViewCommissionsLimitedAccess;

  useEffect(() => {
    if (brokerId != null) {
      dispatch(
        listAgenciesCompactAction(brokerId, ViewCommissionsLimitedAccess)
      );
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: [initialAgency],
        TransactionDate: [
          toStartOfDay(dayjs().subtract(1, "month").startOf("month")),
          toEndOfDay(dayjs().subtract(1, "month").endOf("month")),
        ],
      };

      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();

      // Reset default options for drilldown
      getExtraDisableDefaultOptions([initialAgency]);
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const getExtraDisableDefaultOptions = (agencyKeys) => {
    const fieldName =
      getValues().ReportType === commissionReportTypes.DETAIL
        ? "SortGroupFields"
        : "DrillDownSortFields";
    const fieldValue =
      getValues().ReportType === commissionReportTypes.DETAIL
        ? new SortGroupField()
        : new DrillDownSortField();
    if (agencyKeys.length === 1 && agencyKeys[0] !== 0) {
      fieldValue.Name = sortGroupDrillDownFields.BATCH_NUMBER;
    } else if (
      (agencyKeys.length === 1 && agencyKeys[0] === 0) ||
      agencyKeys.length > 1
    ) {
      fieldValue.Name = sortGroupDrillDownFields.AGENCY_NAME;
    }
    setValue(fieldName, [fieldValue]);
  };

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformCommissionInput(values),
        entityType.COMMISSION,
        `Print${values.ReportType}`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="Commission">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <Form.Item
                        {...defaultLayout}
                        label="Report Type"
                        name="ReportType"
                        required
                      >
                        <Controller
                          control={control}
                          name="ReportType"
                          render={({ field }) => (
                            <Radio.Group {...field} name="ReportType">
                              {commissionReportTypeOptions}
                            </Radio.Group>
                          )}
                        />
                      </Form.Item>

                      <AgencyMultiSelectEx
                        layout={defaultLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                        onChange={(value) => {
                          var oldLength = getValues().AgencyKeys.length;
                          var newLength = value.length;
                          // We only need to update default options in some cases
                          if (
                            newLength != oldLength &&
                            ((oldLength < 2 && newLength > oldLength) ||
                              newLength < 2)
                          ) {
                            getExtraDisableDefaultOptions(value);
                          }
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        disableFutureDates={true}
                        name="TransactionDate"
                        format={dateFormat}
                        disabledDate={(
                          current // Should we allow users to select over a year?
                        ) => current && current > dayjs().endOf("day")}
                      />

                      <Form.Item
                        {...defaultLayout}
                        label="Include"
                        name="Include"
                        required
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Space direction="vertical">
                          <Controller
                            control={control}
                            name="IncludeUnbatched"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  setValue(
                                    "IncludeUnbatched",
                                    e.target.checked,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                                inputRef={field.ref}
                                name="IncludeUnbatched"
                              >
                                Unbatched Documents
                              </Checkbox>
                            )}
                          />

                          <Controller
                            control={control}
                            name="IncludeAdjustments"
                            render={({ field }) => (
                              <Checkbox
                                inputRef={field.ref}
                                checked={field.value}
                                name="IncludeAdjustments"
                                onChange={(e) => {
                                  setValue(
                                    "IncludeAdjustments",
                                    e.target.checked,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                              >
                                Adjustments for Cancellations
                              </Checkbox>
                            )}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <ExtraReportFilterContainer>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={10} xl={11} xxl={12}>
                        <Form.Item
                          name="ProducerCode1Keys"
                          label="Producer 1"
                          {...specialLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode1Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={1}
                            taskKey={taskKey}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ProducerCode2Keys"
                          label="Producer 2"
                          {...specialLayout}
                        >
                          <ProducerSelectEx
                            name="ProducerCode2Keys"
                            setValue={setValue}
                            control={control}
                            producerNumber={2}
                            taskKey={taskKey}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="BatcNumber"
                          label="Batch"
                          {...specialLayout}
                        >
                          <BatchRangeSelectEx
                            agencyKeys={getValues().AgencyKeys}
                            transactionDate={getValues().TransactionDate}
                            setValue={setValue}
                            control={control}
                            useICBCStmtLogic={true}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row></Row>
                  </ExtraReportFilterContainer>

                  <Row>
                    <Col span={24}>
                      {getValues().ReportType ===
                      commissionReportTypes.DETAIL ? (
                        <SortGroupFieldSelectEx
                          fieldOptions={sortGroupDrillDownFieldOptions}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      ) : (
                        <DrillDownSortFieldSelectEx
                          fieldOptions={sortGroupDrillDownFieldOptions}
                          control={control}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      )}
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined />
                        Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformCommissionInput(getValues()),
                              `Commission${getValues().ReportType}Report`,
                              entityType.COMMISSION
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          const reportType = getValues().ReportType;
                          const resetValues = {
                            ...initialDefaultValues,
                            ReportType: reportType,
                          };
                          reset(resetValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default CommissionReport;
