import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row, Table, Typography } from "antd";
import { every, isNull } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useVT } from "virtualizedtableforantd4";
import { setInlineEditOperationResultAction } from "../../../store/batching/batchingActions";
import {
  clearTableRestorableData,
  removeTableToPreserve,
  setTablePreserveOnReload,
  setTableRestorableData,
  setTableRowBookmark,
} from "../../../store/ui/uiActions";
import "./SearchableBatchTable.css";
import { ClearFilters } from "./components/ClearFilters";
import { columnMapper } from "./components/columnMapper";
import { transformSearchableColumns } from "./inlineEdit/transformSearchableColumns";
const { Text } = Typography;

const defaultPageSize = 100;
const defaultScrollY = 450;
const defaultScrollX = 750;

export const isTableFilteredInfoNotFiltered = (info) => {
  if (info == null) {
    return true;
  }
  // The first column "UniqueFilterColumn" is used to trigger onChange. It shouldn't count as a filter
  var filteredInfoCopy = { ...info };
  if (filteredInfoCopy["UniqueFilterColumn"] != null) {
    delete filteredInfoCopy["UniqueFilterColumn"];
  }
  return every(filteredInfoCopy, isNull);
};

/***
 *
 * How to preserve scroll & page after data reload?
 *
 * dispatch the following action before reloading your data `setTablePreserveOnReload(TABLE_NAME, true))`;
 *
 * How to use inline editing?
 *
 * - Set enableInlineEdit={true}
 * - Pass validationSchema, onInlineEditSubmitHandler, & resetValuesOnRowEdit (if needed)
 * - You need stricly 1 columnTypes.ACTION column (not more, not less)
 * - The action column will be set to width=60 when it's editing, so please take that into consideration
 * - To make a column editable you can use `editable: (record) => true` in your column definition
 * - Currently, only CustomInput (text) is supported so ideally your editable columns should be columnTypes.TEXT
 * - You cannot have any complex render logic for an editable column. Ideally don't modify the `render` prop.
 * - You can pass your own props to CustomInput for each cell by adding inputProps: {...} in your column definition
 * For an example, view NewBatchDocuments.js
 ***/

export const SearchableTable = (props) => {
  //
  const {
    columns,
    buttons,
    loading,
    dataSource,
    clearAll,
    setClearAll,
    pageSize,
    excludedFromFiltering,
    specialPageSizeOptions,
    tableName,
    bookmarkRowExtra,
    rowKey,
    hideClearFiltersButtons,
    rowClassNameExtended, // () => (record, defaultFunction), use this if you want to give a custom class to specific rows for styling purposes
    enableInlineEdit, // Set this to true to make sure inline edit is properly enabled
    validationSchema, // If you're using editable cells, you must provide a validation schema
    useDelayedLoading, // Do you want to delay freshly loaded dataSource? (i.e: you need to complete other operations)
    loadingDelay = 500, // Delay in ms, only applies when useDelayedLoading === true
    alternativeTotal, // Provide your own total for records
    useAlternativeTotal, // Set to true if you want to use "alternativeTotal"
    customRef, // customRef.current.refreshTotals() will trigger onChange. Pass an empty function as a paremeter to prevent it from jumping to page 1 (view NewBatchDocuments.js)
    resetValuesOnRowEdit, // To set initialValues when a row is being edited resetValuesOnRowEdit(record) => initialValues
    onInlineEditSubmitHandler, // Triggered when user presses save & there are no errors, onInlineEditSubmitHandler(values)
  } = props;

  // Form
  const { handleSubmit, reset, control } = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: validationSchema != null ? yupResolver(validationSchema) : null,
  });

  // Import hooks
  const location = useLocation();
  const dispatch = useDispatch();

  // Refs
  const isFirstLoadCompleted = useRef(false);
  const timerRef = useRef(null);
  const filteredCount = useRef(0);
  const columnRefArray = useRef([]);
  const triggerOnChangeRef = useRef(null);
  const postTriggerFunctionRef = useRef(null);

  // Use state
  const [delayedDataSource, setDelayedDataSource] = useState(null);
  const [delayedLoading, setDelayedLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [focusedRow, setFocusedRow] = useState("");

  // Helpers for restoring state
  const tablesToPreserve = useSelector(
    (state) => state?.ui?.preservable?.tables
  );
  const uniqueTableName = `${location.key}_${tableName ?? ""}`;
  const shouldPreserve =
    tablesToPreserve && tablesToPreserve.includes(uniqueTableName);
  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableName)
  );
  const bookmarkedRowKey = tableData?.bookmarkedRowKey;
  const preserveOnReload = tableData?.preserveOnReload;

  // Restorable values
  const [filteredInfo, setFilteredInfo] = useState(
    shouldPreserve ? tableData?.filteredInfo ?? {} : {}
  );
  const [sortedInfo, setSortedInfo] = useState(
    shouldPreserve ? tableData?.sortedInfo ?? {} : {}
  );
  const [currentPage, setCurrentPage] = useState(
    shouldPreserve ? tableData?.currentPage ?? 1 : 1
  );
  const [currentPageSize, setCurrentPageSize] = useState(
    shouldPreserve
      ? tableData?.currentPageSize ?? pageSize ?? defaultPageSize
      : pageSize ?? defaultPageSize
  );
  const [extra, setExtra] = useState(
    shouldPreserve ? tableData?.extra ?? {} : {}
  );

  // Copy of restorable values
  const currentScroll = useRef(
    shouldPreserve ? tableData?.currentScroll ?? 0 : 0
  );
  const filteredInfoRef = useRef(filteredInfo);
  const sortedInfoRef = useRef(sortedInfo);
  const currentPageSizeRef = useRef(currentPageSize);
  const currentPageRef = useRef(currentPage);
  const extraRef = useRef(extra);

  //
  const sortedKeyName = sortedInfo?.columnKey ?? "";
  const sortedKeyOrder = sortedInfo?.order ?? "";
  const searchableColumns = useMemo(
    () =>
      columnMapper(
        columns,
        sortedInfo,
        filteredInfo,
        columnRefArray,
        triggerOnChangeRef
      ),
    [sortedKeyName, sortedKeyOrder, filteredInfo, columns]
  );

  //
  const isNotFiltered = isTableFilteredInfoNotFiltered(filteredInfo);
  const isNotFilteredAndSorted =
    isNotFiltered &&
    (every(sortedInfo, isNull) ? true : sortedInfo.column === undefined);

  // We're using custom virtual lists because virtual={true} affects scroll bars
  // Other viable packages would be virtuallist-antd & react-window
  const [vComponents, _, tableRef] = useVT(
    () => ({
      scroll: { y: props?.scroll?.y != null ? props.scroll.y : defaultScrollY },
      onScroll: ({ top }) => {
        currentScroll.current = top;
      },
    }),
    []
  );

  // Helper functions
  const triggerOnChange = (postTriggerFunction) => {
    if (triggerOnChangeRef?.current != null) {
      postTriggerFunctionRef.current = postTriggerFunction;
      triggerOnChangeRef.current();
    }
  };

  if (customRef != null) {
    customRef.current = { refreshTotals: triggerOnChange };
  }

  const bookmarkRow = (rowKey) => {
    bookmarkRowExtra && bookmarkRowExtra(rowKey);
    dispatch(setTableRowBookmark(tableName, rowKey));
  };

  const scrollToOffset = (offset) => {
    tableRef && tableRef?.current && tableRef.current?.scrollTo(offset);
  };

  const afterLoadingAction = (shouldDelayScroll) => {
    const page =
      !isFirstLoadCompleted.current && shouldPreserve === true
        ? tableData?.currentPage
        : preserveOnReload === true
        ? currentPage
        : 1;

    const scroll =
      !isFirstLoadCompleted.current && shouldPreserve === true
        ? tableData?.currentScroll ?? 0
        : preserveOnReload === true
        ? currentScroll.current ?? 0
        : 0;

    const postTriggerFunction = () => {
      setCurrentPage(page ?? 1);
      setTimeout(
        () => {
          scrollToOffset(scroll);
        },
        shouldDelayScroll ? 100 : 0
      );
    };

    isFirstLoadCompleted.current = true;
    triggerOnChange(postTriggerFunction);

    if (preserveOnReload === true) {
      dispatch(setTablePreserveOnReload(tableName, false));
    }
  };

  const setDelayedData = (dataSource, loading, isDelayed) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    const toExecute = () => {
      setDelayedDataSource(dataSource);
      setDelayedLoading(loading);
    };
    timerRef.current = setTimeout(toExecute, isDelayed ? loadingDelay : 0);
  };

  const clearAllFilters = () => {
    setFilteredInfo({});
  };

  const clearAllFiltersAndSorting = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const onSubmitHandler = (values) => {
    setIsEditing(null);
    setFocusedRow("");

    if (onInlineEditSubmitHandler != null) {
      onInlineEditSubmitHandler(values);
    }
  };

  const onInvalidSubmit = (values) => {
    var message = "Invalid input.";
    for (const [_, value] of Object.entries(values)) {
      message += `\n - ${value.message}`;
    }
    const operation = {
      Outcome: "Error",
      Message: <div className="invalidSearchableTable">{message}</div>,
    };
    dispatch(setInlineEditOperationResultAction(operation));
  };

  const onCancel = () => {
    setIsEditing(null);
    setFocusedRow("");
  };

  // Effects
  useEffect(() => {
    if (focusedRow != "") {
      document.getElementById(`inlineEditInput_${focusedRow}`)?.focus();
    }
  }, [focusedRow]);

  useEffect(() => {
    if (shouldPreserve !== true) {
      dispatch(clearTableRestorableData(tableName));
    }
  }, [uniqueTableName]);

  useEffect(() => {
    if (
      (useDelayedLoading === true &&
        !delayedLoading &&
        delayedDataSource != null) ||
      (!useDelayedLoading && !loading && dataSource != null)
    ) {
      sortedInfoRef.current = sortedInfo;
      filteredInfoRef.current = filteredInfo;
      currentPageSizeRef.current = currentPageSize;
      currentPageRef.current = currentPage;
      extraRef.current = extra;
      dispatch(
        setTableRestorableData(tableName, {
          sortedInfo: sortedInfoRef.current,
          filteredInfo: filteredInfoRef.current,
          currentPageSize: currentPageSizeRef.current,
          currentScroll: currentScroll.current,
          currentPage: currentPageRef.current,
          extra: extraRef.current,
        })
      );
    }
  }, [sortedInfo, filteredInfo, currentPageSize, currentPage, extra]);

  useEffect(() => {
    return () => {
      dispatch(
        setTableRestorableData(tableName, {
          sortedInfo: sortedInfoRef.current,
          filteredInfo: filteredInfoRef.current,
          currentPageSize: currentPageSizeRef.current,
          currentScroll: currentScroll.current,
          currentPage: currentPageRef.current,
          extra: extraRef.current,
        })
      );
      dispatch(removeTableToPreserve(uniqueTableName));
    };
  }, []);

  useEffect(() => {
    if (
      useDelayedLoading === true &&
      !delayedLoading &&
      delayedDataSource != null
    ) {
      afterLoadingAction(isFirstLoadCompleted?.current !== true);
    }
  }, [delayedDataSource, delayedLoading]);

  useEffect(() => {
    if (useDelayedLoading === true) {
      const shouldDelay = !loading && dataSource != null;
      setDelayedData(dataSource, loading, shouldDelay);
    } else if (!loading && dataSource != null) {
      afterLoadingAction();
    }
  }, [dataSource, loading]);

  useEffect(() => {
    if (clearAll) {
      clearAllFiltersAndSorting();
      setClearAll(false);
    }
  }, [clearAll, setClearAll]);

  // When pagination, filters, sorter & extras change, we need to keep our internal state updated
  const handleChange = (pagination, filters, sorter, extras) => {
    setCurrentPageSize(pagination?.pageSize ?? 1);
    if (filters != null && excludedFromFiltering != null) {
      for (var i = 0; i < excludedFromFiltering.length; i++) {
        filters[excludedFromFiltering[i]] = null;
      }
    }
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (extras != extra) {
      setExtra(extras);
    }
    filteredCount.current = extras?.currentDataSource?.length ?? 0;

    // When handleChange is triggered by triggerOnChange(), we can execute a custom function
    if (postTriggerFunctionRef.current != null) {
      postTriggerFunctionRef.current();
      postTriggerFunctionRef.current = null;
    }
  };

  const getTableSummary = () => {
    let result;
    let total =
      useAlternativeTotal === true ? alternativeTotal : dataSource?.length ?? 0;

    if (isNotFiltered) {
      result = `Records: ${total}`;
    } else {
      result = `Filtered: ${filteredCount.current} out of ${total}`;
    }
    return result;
  };

  const transformedSearchableColumns =
    enableInlineEdit === true
      ? transformSearchableColumns({
          control,
          searchableColumns,
          isEditing,
          onCancel,
          onSubmit: () => handleSubmit(onSubmitHandler, onInvalidSubmit)(),
          resetValuesOnRowEdit,
          reset,
          setFocusedRow,
          setIsEditing,
        })
      : searchableColumns;

  const rowClassName = (record) =>
    rowKey(record) === bookmarkedRowKey
      ? "ant-table-row-selected"
      : "no-selection";

  return (
    <div className="custom-table-component">
      <Row style={{ marginBottom: 8 }}>
        {/* render page specific buttons */}
        <Col span={14}>
          <Row>
            {buttons &&
              buttons.map((button, index) =>
                props.shouldButtonsFillWidth === true ? (
                  <Col key={index} style={{ marginRight: 5 }} span={24}>
                    {button}
                  </Col>
                ) : (
                  <Col key={index} style={{ marginRight: 5 }}>
                    {button}
                  </Col>
                )
              )}
          </Row>
        </Col>
        {!hideClearFiltersButtons && (
          <Col span={10}>
            <Row justify="end">
              <Col style={{ marginRight: 5 }}>
                <Text strong>{getTableSummary()}</Text>
              </Col>
              <ClearFilters
                clearAllFilters={clearAllFilters}
                isNotFiltered={isNotFiltered}
              />
              <ClearFilters
                clearAllFilters={clearAllFiltersAndSorting}
                isNotFiltered={isNotFilteredAndSorted}
                includeSorting={true}
              />
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            name="inlineEdit"
            onFinish={() => {}}
          >
            <Table
              {...props}
              sticky={false}
              components={vComponents}
              scroll={{
                y: props?.scroll?.y ?? defaultScrollY,
                x: props?.scroll?.x ?? defaultScrollX,
                scrollToFirstRowOnChange: false,
              }}
              columns={transformedSearchableColumns}
              onRow={(record, _) => {
                return {
                  onClick: (_) => bookmarkRow(rowKey(record)),
                };
              }}
              rowClassName={(record, _) =>
                rowClassNameExtended
                  ? rowClassNameExtended(record, rowClassName)
                  : rowClassName(record)
              }
              bordered={true}
              onChange={handleChange}
              loading={useDelayedLoading === true ? delayedLoading : loading}
              showSorterTooltip={false}
              dataSource={
                useDelayedLoading === true ? delayedDataSource : dataSource
              }
              size="small"
              pagination={
                props.pagination === false
                  ? false
                  : {
                      current: currentPage,
                      defaultCurrent: currentPage,
                      pageSize: currentPageSize,
                      hideOnSinglePage: true,
                      pageSizeOptions: specialPageSizeOptions ?? [
                        10, 20, 50, 100, 500, 1000,
                      ],
                      onChange: (page) => {
                        if (!postTriggerFunctionRef.current) {
                          setCurrentPage(page);
                          scrollToOffset(0);
                        }
                      },
                    }
              }
            />
          </Form>
        </Col>
      </Row>
    </div>
  );
};
