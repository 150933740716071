export const fixedDateRanges = {
  NONE: null,
  TODAY: "TODAY",
  IN_X_DAYS: "IN_X_DAYS",
  X_DAYS_AGO: "X_DAYS_AGO",
  NEXT_X_DAYS: "NEXT_X_DAYS",
  LAST_X_DAYS: "LAST_X_DAYS",
  MTD: "MTD",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  NEXT_MONTH: "NEXT_MONTH",
  QTD: "QTD",
  THIS_QUARTER: "THIS_QUARTER",
  LAST_QUARTER: "LAST_QUARTER",
  NEXT_QUARTER: "NEXT_QUARTER",
  YTD: "YTD",
  THIS_YEAR: "THIS_YEAR",
  LAST_YEAR: "LAST_YEAR",
};

export const fixedDateRangeOptions = (disableFutueDates) =>
  disableFutueDates
    ? [
        { value: fixedDateRanges.TODAY, label: "Today" },
        { value: fixedDateRanges.X_DAYS_AGO, label: "... day(s) ago" },
        { value: fixedDateRanges.LAST_X_DAYS, label: "Last ... day(s)" },
        { value: fixedDateRanges.MTD, label: "Month To Date" },
        { value: fixedDateRanges.LAST_MONTH, label: "Last Month" },
        { value: fixedDateRanges.QTD, label: "Quarter To Date" },
        { value: fixedDateRanges.LAST_QUARTER, label: "Last Quarter" },
        { value: fixedDateRanges.YTD, label: "Year To Date" },
        { value: fixedDateRanges.LAST_YEAR, label: "Last Year" },
      ]
    : [
        { value: fixedDateRanges.TODAY, label: "Today" },
        {
          value: fixedDateRanges.IN_X_DAYS,
          label: "In ... day(s)",
          disabled: disableFutueDates,
        },
        { value: fixedDateRanges.X_DAYS_AGO, label: "... day(s) ago" },
        {
          value: fixedDateRanges.NEXT_X_DAYS,
          label: "Next ... day(s)",
          disabled: disableFutueDates,
        },
        { value: fixedDateRanges.LAST_X_DAYS, label: "Last ... day(s)" },
        { value: fixedDateRanges.MTD, label: "Month To Date" },
        { value: fixedDateRanges.THIS_MONTH, label: "This Month" },
        { value: fixedDateRanges.LAST_MONTH, label: "Last Month" },
        {
          value: fixedDateRanges.NEXT_MONTH,
          label: "Next Month",
          disabled: disableFutueDates,
        },
        { value: fixedDateRanges.QTD, label: "Quarter To Date" },
        { value: fixedDateRanges.THIS_QUARTER, label: "This Quarter" },
        { value: fixedDateRanges.LAST_QUARTER, label: "Last Quarter" },
        {
          value: fixedDateRanges.NEXT_QUARTER,
          label: "Next Quarter",
          disabled: disableFutueDates,
        },
        { value: fixedDateRanges.YTD, label: "Year To Date" },
        { value: fixedDateRanges.THIS_YEAR, label: "This Year" },
        { value: fixedDateRanges.LAST_YEAR, label: "Last Year" },
      ];
