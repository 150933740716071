import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PreservableStateLink } from "../../shared/PreservableStatePageWrapper";
import { columnTypes } from "../../shared/table/columnTypes";
import { deleteCommissionRate } from "../../../store/commission/commissionActions";

const { Paragraph } = Typography;

const getCommissionRateColumns = (agencyKeys) => {
  const dispatch = useDispatch();
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  return [
    {
      title: "PC1",
      dataIndex: "ProducerCode1",
      type: columnTypes.TEXT,
      render: (value, record) => (
        <PreservableStateLink to={`updatecommissionrate/${record.Id}`}>
          {value}
        </PreservableStateLink>
      ),
      width: 65,
    },
    {
      title: "PC2",
      dataIndex: "ProducerCode2",
      type: columnTypes.TEXT,
      render: (value, record) => (
        <PreservableStateLink to={`updatecommissionrate/${record.Id}`}>
          {value}
        </PreservableStateLink>
      ),
      width: 65,
    },
    {
      title: "Description 1",
      dataIndex: "Description1",
      width: 150,
      type: columnTypes.TEXT,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Description 2",
      dataIndex: "Description2",
      width: 150,
      type: columnTypes.TEXT,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Rate %",
      dataIndex: "RatePercent",
      type: columnTypes.NUMBER,
      width: 70,
    },

    {
      title: "Agency 1",
      dataIndex: "Agency1NumberAndName",
      type: columnTypes.TEXT,
      width: 200,
    },

    {
      title: "Agency 2",
      dataIndex: "Agency2NumberAndName",
      type: columnTypes.TEXT,
      width: 200,
    },
    {
      type: columnTypes.ACTION,
      render: (record) => (
        <Popconfirm
          title={
            <>
              <Paragraph>This will delete the commission rate.</Paragraph>
              <Paragraph style={{ marginBottom: 0 }}>
                Are you sure you want to proceed?
              </Paragraph>
            </>
          }
          onConfirm={() =>
            dispatch(deleteCommissionRate({ ...agencyKeys, Key: record.Id }))
          }
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" size="small" style={{ padding: 0 }}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      fixed: "right",
      width: 30,
    },
  ];
};

export default getCommissionRateColumns;
