import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { Actions } from "./agencyConstants";

export const listAgenciesAction = (brokerId) => async (dispatch) => {
  dispatch({ type: Actions.LIST_AGENCIES });

  const payload = await customGet(entityType.AGENCY, genericActionType.LIST, {
    params: { brokerId: brokerId },
  });

  dispatch({ type: Actions.LIST_AGENCIES_SUCCEEDED, payload });
};

export const listAgenciesCompactAction =
  (brokerId, taskid, listCustomEndpoint = "ListAgenciesCompact") =>
  async (dispatch) => {
    dispatch({ type: Actions.LIST_AGENCIES_COMPACT });

    const payload = await customGet(entityType.AGENCY, listCustomEndpoint, {
      params: {
        brokerId: brokerId,
        taskid: taskid,
      },
    });

    dispatch({ type: Actions.LIST_AGENCIES_COMPACT_SUCCEEDED, payload });
  };

export const listAgenciesCompactForSupport = (brokerId) => async (dispatch) => {
  dispatch({ type: Actions.LIST_AGENCIES_COMPACT_FOR_SUPPORT });

  const payload = await customGet(
    entityType.AGENCY,
    "ListAgenciesCompactForSupportAccount",
    {
      params: {
        brokerId: brokerId,
      },
    }
  );

  dispatch({
    type: Actions.LIST_AGENCIES_COMPACT_FOR_SUPPORT_SUCCEEDED,
    payload,
  });
};

export const listBrokersCompact = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_BROKERS_COMPACT });

  const payload = await customGet(entityType.BROKER, "ListCompact");

  dispatch({ type: Actions.LIST_BROKERS_COMPACT_SUCCEEDED, payload });
};

export const listAgenciesCompactForBatchingAction =
  (brokerId, taskid, listCustomEndpoint = "ListAgenciesCompactForBatching") =>
  async (dispatch) => {
    dispatch({ type: Actions.LIST_AGENCIES_COMPACT_FOR_BATCHING });

    const payload = await customGet(entityType.AGENCY, listCustomEndpoint, {
      params: {
        brokerId: brokerId,
        taskid: taskid,
      },
    });

    dispatch({
      type: Actions.LIST_AGENCIES_COMPACT_FOR_BATCHING_SUCCEEDED,
      payload,
    });
  };

export const readAction = (agencyKey) => async (dispatch) => {
  dispatch({ type: Actions.READ_AGENCY });
  const payload = await customGet(entityType.AGENCY, genericActionType.READ, {
    params: {
      agencyKey: agencyKey,
    },
  });
  dispatch({ type: Actions.READ_AGENCY_SUCCEEDED, payload });
};

export const updateAction = (values) => async (dispatch) => {
  dispatch({ type: Actions.UPDATE_AGENCY });
  const payload = await customPost(
    entityType.AGENCY,
    genericActionType.UPDATE,
    values
  );
  dispatch({ type: Actions.UPDATE_AGENCY_SUCCEEDED, payload });
};

export const createAction = (values) => async (dispatch, getState) => {
  dispatch({ type: Actions.CREATE_AGENCY });
  values.CompanyKey = getState().actor.details.data.BrokerId;
  const payload = await customPost(
    entityType.AGENCY,
    genericActionType.CREATE,
    values
  );
  dispatch({ type: Actions.CREATE_AGENCY_SUCCEEDED, payload });
};

export const clearOperationResultAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const clearSelectedAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_SELECTED });
};

export const clearListCompactAction = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_LIST_COMPACT });
};

export const clearListCompactForSupport = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_LIST_COMPACT_FOR_SUPPORT });
};

export const clearBrokerListCompact = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_BROKER_LIST_COMPACT });
};
