export const Actions = {
  FETCH_REPORT_REQUEST: "FETCH_REPORT_REQUEST",
  FETCH_REPORT_SUCCESS: "FETCH_REPORT_SUCCESS",
  FETCH_REPORT_FAILURE: "FETCH_REPORT_FAILURE",
  EXPORT_REPORT_REQUEST: "EXPORT_REPORT_REQUEST",
  EXPORT_REPORT_SUCCESS: "EXPORT_REPORT_SUCCESS",
  EXPORT_REPORT_FAILURE: "EXPORT_REPORT_FAILURE",
  DOWNLOAD_REPORT_REQUEST: "DOWNLOAD_REPORT_REQUEST",
  DOWNLOAD_REPORT_SUCCESS: "DOWNLOAD_REPORT_SUCCESS",
  DOWNLOAD_REPORT_FAILURE: "DOWNLOAD_REPORT_FAILURE",
  DOWNLOAD_CUSTOM_FILE_REQUEST: "DOWNLOAD_CUSTOM_FILE_REQUEST",
  DOWNLOAD_CUSTOM_FILE_SUCCESS: "DOWNLOAD_CUSTOM_FILE_SUCCESS",
  DOWNLOAD_CUSTOM_FILE_FAILURE: "DOWNLOAD_CUSTOM_FILE_FAILURE",
  PRINTING_FAILURE: "PRINTING_FAILURE",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  SET_MODAL_VISIBILITY: "SET_MODAL_VISIBILITY",
  RESET_REPORT_STATE: "RESET_REPORT_STATE",
  SET_EXTRA_FILTERS_VISIBILITY: "SET_EXTRA_FILTERS_VISIBILITY",
};
