import { Radio } from "antd";

export const loanTypes = {
  NEW: "N",
  RENEWAL: "R",
  CHANGE: "C",
};

export const loanCalculationMethod = {
  FLAT: 0,
  DECLINING: 1,
};

export const loanTypeOptions = [
  <Radio value={loanTypes.NEW} key={loanTypes.NEW}>
    New Loan Application
  </Radio>,
  <Radio value={loanTypes.RENEWAL} key={loanTypes.RENEWAL}>
    Loan Renewal
  </Radio>,
  <Radio value={loanTypes.CHANGE} key={loanTypes.CHANGE}>
    Mid-Term Change
  </Radio>,
];

export const downpaymentOptions = [
  <Radio value={"M"} key="M">
    Months
  </Radio>,
  <Radio value={"A"} key="A">
    Amount
  </Radio>,
];

export const insuranceTypeOptions = [
  <Radio value={"P"} key="P">
    Personal
  </Radio>,
  <Radio value={"C"} key="C">
    Commercial
  </Radio>,
  <Radio value={"S"} key="S">
    Special Risk
  </Radio>,
  <Radio value={"M"} key="M">
    Marine
  </Radio>,
];

export const provinceOptions = [
  { label: "Alberta", value: "Alberta" },
  { label: "British Columbia", value: "British Columbia" },
  { label: "Saskatchewan", value: "Saskatchewan" },
  { label: "Manitoba", value: "Manitoba" },
  { label: "Ontario", value: "Ontario" },
  { label: "Quebec", value: "Quebec" },
  { label: "Nova Scotia", value: "Nova Scotia" },
  { label: "New Brunswick", value: "New Brunswick" },
  { label: "Prince Edward Island", value: "Prince Edward Island" },
  { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
  { label: "Northwest Territories", value: "Northwest Territories" },
  { label: "Yukon", value: "Yukon" },
  { label: "Nunavut", value: "Nunavut" },
];
