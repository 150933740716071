import {
  findCurrentAgencies,
  findCurrentAgency,
  findCurrentBroker,
} from "../report/reportActions";

export const transformScheduleOfVehiclesInput = (values) => (state) => {
  const agency = findCurrentAgencies(state, values.AgencyKeys);
  const brokerName = findCurrentBroker(state);
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FileName: `ScheduleOfVehicles_${agency.Number}`,
    BrokerName: brokerName,
  };
};

export const transformConfirmationOfInsuranceInput = (values) => (state) => {
  const agency = findCurrentAgency(state, values.AgencyKey);
  const brokerName = findCurrentBroker(state);
  return {
    ...values,
    AgencyNameAndNumber: agency.FullName,
    FileName: `ConfirmationOfInsurance_${agency.Number}`,
    BrokerName: brokerName,
  };
};
