import { Form, Select } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import InfoForMultiSelect from "./InfoForMultiSelect";

const DynamicProducerSelect = ({
  name,
  setValue,
  control,
  afterChange,
  producerCodesList,
  formLabel,
  formLayout,
  isProducerCodesListLoading,
}) => {
  const selectedProducerCodes =
    useWatch({
      control,
      name: name,
      defaultValue: [],
    }) ?? [];

  //
  const [options, setOptions] = useState([]);
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  //
  useEffect(() => {
    // When the producer codes list changes, we need to update the currently selected codes to only keep those that match
    if (
      selectedProducerCodes != null &&
      selectedProducerCodes != [] &&
      !isEmpty(selectedProducerCodes)
    ) {
      const newValue = producerCodesList
        .filter((i) => selectedProducerCodes.includes(i.Id))
        .map((i) => i.Id);

      setValue(name, newValue);
    }

    // Update the list of options to choose from in the <Select /> component
    var updatedOptions =
      producerCodesList != []
        ? producerCodesList.map((p) => {
            return {
              label:
                p.Description != null ? (
                  maskSensitiveData === true ? (
                    <div style={{ display: "inline" }}>
                      {p.ProducerCode != "" ? p.ProducerCode + " - " : ""}
                      <div
                        style={{
                          color: "transparent",
                          textShadow: "0 0 8px #000",
                          userSelect: "none",
                          display: "inline",
                        }}
                      >
                        {p.Description}
                      </div>
                    </div>
                  ) : (
                    `${p.ProducerCode != "" ? p.ProducerCode + " - " : ""}${
                      p.Description
                    }`
                  )
                ) : (
                  `${p.ProducerCode}`
                ),
              value: p.ProducerCodeKey,
              filter:
                p.Description != null
                  ? `${p.ProducerCode != "" ? p.ProducerCode + " - " : ""}${
                      p.Description
                    }`
                  : `${p.ProducerCode}`,
            };
          })
        : [];

    if (producerCodesList.length > 1) {
      updatedOptions.unshift({
        label: `(Select all ${producerCodesList.length} producers)`,
        value: 0,
        filter: `(Select all ${producerCodesList.length} producers)`,
      });
    }
    setOptions(updatedOptions);
  }, [producerCodesList]);

  return (
    <Form.Item name={name} label={formLabel} {...formLayout}>
      <div>
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Select
                {...field}
                name={name}
                showSearch
                optionFilterProp="filter"
                filterOption={(input, option) => {
                  const filter = option.filter
                    ? option.filter
                    : option.children;
                  return filter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                options={options}
                loading={isProducerCodesListLoading}
                mode="multiple"
                onChange={(v) => {
                  if (v != null && v.includes(0)) {
                    var newValues =
                      producerCodesList != []
                        ? producerCodesList.map((i) => {
                            return i.Id;
                          })
                        : [];
                    field.onChange(newValues);
                    afterChange && afterChange(newValues);
                  } else {
                    field.onChange(v);
                    afterChange && afterChange(v);
                  }
                }}
                allowClear
                maxTagCount={3}
                virtual={false}
                placeholder="Select one or multiple producer codes"
              />
            )}
          />
        </span>
        <InfoForMultiSelect
          data={producerCodesList}
          currentValue={selectedProducerCodes}
        />
      </div>
    </Form.Item>
  );
};
export default DynamicProducerSelect;
