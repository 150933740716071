import fileDownload from "js-file-download";
import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { Actions } from "./reportConstants";

const blobToBase64 = async (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const findCurrentBroker = (state) => state.actor.details.data.BrokerName;

export const findCurrentBrokerId = (state) => state.actor.details.data.BrokerId;

export const findCurrentAgency = (state, agencyKey) => {
  const agenciesList = state.agency.listCompact;
  const agency = agenciesList.find((a) => a.Id == agencyKey);
  return agency;
};

export const findAllocatedToAgent = (state, agentKey) => {
  const agentsList = state.inventory.allocatedToAgents;
  const agent =
    agentKey == 0
      ? "(All Agents)"
      : agentsList.data.find((a) => a.value == agentKey).label;
  return agent;
};

export const findCurrentAgencies = (state, agencyKeys) => {
  const agenciesList = state.agency.listCompact;
  const agency =
    agencyKeys.length === 1
      ? agenciesList.find((a) => a.Id == agencyKeys[0])
      : agencyKeys.length === agenciesList.length
      ? { FullName: "All Agencies", Number: "All_Agencies" }
      : { FullName: "Multiple Agencies", Number: "Multiple_Agencies" };
  return agency;
};

export const findCurrentBatchLabel = (state, value) => {
  return value != null
    ? state.batching.reporting.batches.data
        .find((b) => b.value == value)
        .label.substring(0, 4)
    : "(Any)";
};

export const findProducerNames = (state, producer, keys) => {
  const data =
    state.producer.listCompact[producer == 1 ? "pCode1" : "pCode2"].data;
  if (
    keys == [] ||
    keys == null ||
    (keys.length == 0 && data.length > 1) ||
    (keys.length == data.length && keys.length > 1)
  ) {
    return "All";
  } else if (keys.length == 0 && data.length == 1) {
    const prod = data[0];
    if (prod.Description != null) {
      return "(" + prod.ProducerCode + ") " + prod.Description;
    } else {
      return prod.ProducerCode;
    }
  }
  let names = "";
  const results = data.filter(({ Id }) => keys.includes(Id));
  results.forEach((producer) => {
    if (names != "") {
      names += ",";
    }

    if (producer.Description != null) {
      names += "(" + producer.ProducerCode + ") " + producer.Description;
    } else {
      names += producer.ProducerCode;
    }
  });
  return names;
};

export const selectReportFileName = (state) =>
  state.report.input != null && state.report.input.FileName;

export const setReportModalVisibility = (visible) => async (dispatch) => {
  dispatch({
    type: Actions.SET_MODAL_VISIBILITY,
    payload: { visible: visible },
  });
};

export const setExtraFiltersVisibility = (visible) => async (dispatch) => {
  dispatch({
    type: Actions.SET_EXTRA_FILTERS_VISIBILITY,
    payload: { visible: visible },
  });
};

export const fetchReport =
  (inputAction, entity, action, outputAction) => async (dispatch, getState) => {
    try {
      const data = inputAction(getState());
      dispatch({
        type: Actions.FETCH_REPORT_REQUEST,
        payload: { input: data },
      });

      const request = await customPost(entity, action, data, {
        blob: true,
        shouldThrow: true,
      });

      if (!request.ok) {
        throw new Error();
      }

      const blob = await request.blob();
      const url = await blobToBase64(blob);

      if (outputAction != null) {
        outputAction();
      }
      return dispatch({
        type: Actions.FETCH_REPORT_SUCCESS,
        payload: { url: url },
      });
    } catch (e) {
      console.log(e);
      if (outputAction != null) {
        outputAction();
      }
      return dispatch({ type: Actions.FETCH_REPORT_FAILURE });
    }
  };

export const exportReport =
  (inputAction, reportName, entity) => async (dispatch, getState) => {
    dispatch({ type: Actions.EXPORT_REPORT_REQUEST });

    try {
      const data = inputAction(getState());
      const request = await customPost(entity, `Export${reportName}`, data, {
        blob: true,
        shouldThrow: true,
      });

      if (!request.ok) {
        throw new Error();
      }

      const response = await request.blob();
      fileDownload(response, `${data.FileName}.csv`);
      return dispatch({ type: Actions.EXPORT_REPORT_SUCCESS });
    } catch (e) {
      console.log(e);
      return dispatch({ type: Actions.EXPORT_REPORT_FAILURE });
    }
  };

export const exportListData =
  (data, param, reportName, entity) => async (dispatch) => {
    dispatch({ type: Actions.EXPORT_REPORT_REQUEST });

    try {
      const request = await customPost(entity, `Export${reportName}`, data, {
        params: param,
        blob: true,
        shouldThrow: true,
      });

      if (!request.ok) {
        throw new Error();
      }

      const response = await request.blob();
      fileDownload(response, `${data.FileName}.csv`);
      return dispatch({ type: Actions.EXPORT_REPORT_SUCCESS });
    } catch (e) {
      console.log(e);
      return dispatch({ type: Actions.EXPORT_REPORT_FAILURE });
    }
  };

export const downloadCustomFile =
  (inputAction, action, entity, fileName, extension) =>
  async (dispatch, getState) => {
    dispatch({ type: Actions.DOWNLOAD_CUSTOM_FILE_REQUEST });

    try {
      const data = inputAction(getState());
      const request = await customPost(entity, action, data, {
        blob: true,
        shouldThrow: true,
      });

      if (!request.ok) {
        const errorObject = await request.json();
        throw { message: errorObject?.Message };
      }
      const response = await request.blob();
      fileDownload(response, `${fileName}${extension}`);
      return dispatch({ type: Actions.DOWNLOAD_CUSTOM_FILE_SUCCESS });
    } catch (e) {
      console.log(e);
      return dispatch({
        type: Actions.DOWNLOAD_CUSTOM_FILE_FAILURE,
        payload: { message: e?.message },
      });
    }
  };

export const downloadReport = (input) => async (dispatch, getState) => {
  try {
    if (input.url == null && input.fileName == null) {
      // Get the report from the server
      const data = input.inputAction(getState());
      dispatch({
        type: Actions.DOWNLOAD_REPORT_REQUEST,
        payload: { input: data },
      });

      const request = await customPost(input.entity, input.action, data, {
        blob: true,
        shouldThrow: true,
      });

      if (!request.ok) {
        throw new Error();
      }

      const blob = await request.blob();
      fileDownload(blob, `${data.FileName}.pdf`);
    } else {
      // Get the report directly from the available URL
      dispatch({ type: Actions.DOWNLOAD_REPORT_REQUEST });
      const request = await fetch(input.url);
      const response = await request.blob();
      fileDownload(response, `${input.fileName}.pdf`);
    }

    return dispatch({ type: Actions.DOWNLOAD_REPORT_SUCCESS });
  } catch (e) {
    console.log(e);
    return dispatch({ type: Actions.DOWNLOAD_REPORT_FAILURE });
  }
};
export const clearOperationResult = () => async (dispatch) => {
  dispatch({ type: Actions.CLEAR_OPERATION_RESULT });
};

export const resetReportState = () => async (dispatch) => {
  dispatch({ type: Actions.RESET_REPORT_STATE });
};
