import { Actions } from "../appLog/appLogConstants";
import produce from "immer";

const initialState = {
  data: [],
  loading: false,
  selected: null,
  selectedLoading: false,
  operationResult: null,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.LIST_APP_LOGS:
        draft.loading = true;
        return draft;
      case Actions.LIST_APP_LOGS_SUCCEEDED:
        draft.loading = false;
        draft.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.READ_APP_LOGS:
        draft.selectedLoading = true;
        return draft;

      case Actions.READ_APP_LOGS_SUCCEEDED:
        draft.selected = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.selectedLoading = false;
        return draft;
      case Actions.CLEAR_SELECTED_APP_LOG:
        draft.operationResult = null;
        draft.selected = null;
        draft.selectedLoading = false;
        return draft;
      default:
        return state;
    }
  });
};
