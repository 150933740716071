import { customGet } from "../../api/customApi";
import entityType from "../../api/entityType";
import genericActionType from "../../api/genericActionType";
import { Actions } from "./territoryConstants";

export const listTerritoriesAction = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_TERRITORIES });

  const payload = await customGet(entityType.TERRITORY, genericActionType.LIST);

  dispatch({ type: Actions.LIST_TERRITORIES_SUCCEEDED, payload });
};
