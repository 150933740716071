import React, { useState } from "react";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { columnTypes } from "../../shared/table/columnTypes";
import { tableNames } from "../../shared/table/tableNames";
import { useSelector } from "react-redux";

const ItemHistoryTable = ({ list, loading }) => {
  const [clearAll, setClearAll] = useState(false);
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const columns = [
    {
      title: "Event",
      dataIndex: "Message",
      type: columnTypes.TEXT,
      width: 230,
      render: (text) => {
        if (maskSensitiveData === true) {
          const eventMsg = text.toString().toLowerCase();
          if (
            eventMsg.startsWith("issued to") ||
            eventMsg.startsWith("un-issued from") ||
            eventMsg.startsWith("allocated from") ||
            eventMsg.startsWith("returned to")
          ) {
            return <div className="blurry-text">{text}</div>;
          } else {
            return text;
          }
        }
        return text;
      },
    },
    {
      title: "Changed On",
      dataIndex: "DateChanged",
      type: columnTypes.DATETIME,
      width: 170,
    },
    {
      title: "Changed By",
      dataIndex: "ChangedByUserName",
      type: columnTypes.TEXT,
      width: 160,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
  ];

  return (
    <SearchableTable
      tableName={tableNames.ItemHistoryTable}
      setClearAll={setClearAll}
      clearAll={clearAll}
      scroll={{ y: 650, x: 560 }}
      sticky
      dataSource={list}
      columns={columns}
      loading={loading}
      size="small"
      rowKey={(r) => r.InventoryItemHistoryKey}
    />
  );
};

export default ItemHistoryTable;
