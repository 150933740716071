import * as yup from "yup";
import {
  requiredMessage,
  dateRangeRequiredMessage,
  maxMessage,
  lessOrEqualOneYearMessage,
} from "../shared/form/formValidatonRules";
import dayjs from "dayjs";

export const dataCollectionReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  TransactionDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  Keyword: yup.string().max(50, maxMessage),
});
