import { isEmpty } from "lodash";

export const getInitialAgency = (homeAgency, agencies) => {
  let initialAgency = null;

  if (!isEmpty(agencies)) {
    initialAgency = agencies.some((a) => a.Id === homeAgency)
      ? homeAgency
      : agencies[0].Id;
  }
  return initialAgency;
};
