import dayjs from "dayjs";
import * as yup from "yup";
import {
  alphanumericMessage,
  alphanumericRegex,
  dateRangeRequiredMessage,
  lessOrEqualOneYearMessage,
  maxMessage,
  requiredMessage,
} from "../../../components/shared/form/formValidatonRules";

export const onlineRenewalsSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  TransactionDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  ShowOpenDocuments: yup.boolean().required(requiredMessage),
  ShowClosedDocuments: yup.boolean().required(requiredMessage),
  Show: yup.boolean().when(["ShowOpenDocuments", "ShowClosedDocuments"], {
    is: false,
    then: yup.boolean().required("At least one of the options is required."),
  }),
});

export const onlineRenewalsBulkUpdateSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  UpdatePC1: yup.boolean().required(),
  UpdatePC2: yup.boolean().required(),
});
