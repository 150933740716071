import { ReloadOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row } from "antd";
import dayjs from "dayjs";
import { remove } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  backupBatchingData,
  getNewBatchFromCriteriaAction,
  updateBatchCriteriaWithAction,
  updateInclusionExclusionStateAction,
  updateInlineEditChangesAction,
} from "../../../store/batching/batchingActions";
import { clearSelectedAction } from "../../../store/document/documentActions";
import { setTablePreserveOnReload } from "../../../store/ui/uiActions";
import QuickEditDocumentForm from "../../document/form/QuickEditDocumentForm";
import UpdateDocumentForm from "../../document/form/UpdateDocumentForm";
import { inlineEditDocumentSchema } from "../../document/form/documentSchemas";
import {
  SearchableTable,
  isTableFilteredInfoNotFiltered,
} from "../../shared/table/SearchableTable";
import { tableNames } from "../../shared/table/tableNames";
import { newBatchDocumentColumns } from "./batchingColumns";
import {
  CheckedRowButton,
  ExcludeAllButton,
  IncludeAllButton,
  PrintButton,
  ToggleExcludedDocumentsButton,
} from "./tableButtons";
import { toTZNeutralFormat } from "../../shared/formatting";

export const NewBatchDocuments = ({ headerStatistics }) => {
  const { getValues } = useFormContext();
  return (
    <NewBatchDocumentsParent
      headerStatistics={headerStatistics}
      getValues={getValues}
    />
  );
};

export default NewBatchDocuments;

const NewBatchDocumentsParent = React.memo(
  ({ headerStatistics, getValues }) => {
    const dispatch = useDispatch();
    const [quickEdit, setQuickEdit] = useState(false);
    const [hideExcludedDocuments, setHideExcludedDocuments] = useState(false);

    const quickEditOnCloseHandler = (clearSelected) => {
      setQuickEdit(false);
      if (clearSelected) {
        dispatch(clearSelectedAction());
      }
    };

    const editParentRefreshAction = () => {
      // Make sure that current scrolling and current page get preserved after data reload
      dispatch(setTablePreserveOnReload(tableNames.BatchCreateStep2, true));
      dispatch(
        getNewBatchFromCriteriaAction({
          FromCreatedDate: toTZNeutralFormat(getValues().FromCreatedDate),
          ToCreatedDate: toTZNeutralFormat(getValues().ToCreatedDate),
          ICBCBatch: getValues().ICBCBatch,
          AgencyBatch: getValues().AgencyBatch,
          HideExcludedDocuments: hideExcludedDocuments,
        })
      );
    };

    const saveChanges = () => {
      const values = {
        FromCreatedDate: toTZNeutralFormat(getValues().FromCreatedDate),
        ToCreatedDate: toTZNeutralFormat(getValues().ToCreatedDate),
        ICBCBatch: getValues().ICBCBatch,
        AgencyBatch: getValues().AgencyBatch,
      };

      dispatch(updateInclusionExclusionStateAction());
      dispatch(updateBatchCriteriaWithAction(values));
      dispatch(backupBatchingData());
    };

    return (
      <div>
        <Drawer
          title={
            <>
              <SnippetsOutlined />
              Document Quick Edit
            </>
          }
          width={680}
          onClose={quickEditOnCloseHandler}
          open={quickEdit}
          styles={{ body: { paddingTop: 4 } }}
          destroyOnClose={true}
        >
          <QuickEditDocumentForm
            onCloseHandler={quickEditOnCloseHandler}
            parentRefreshAction={editParentRefreshAction}
            saveChanges={saveChanges}
            tableName={tableNames.BatchCreateStep2}
          />
        </Drawer>
        <Routes>
          <Route
            path=""
            element={
              <Row>
                <Col>
                  <NewBatchDocumentsBody
                    headerStatistics={headerStatistics}
                    getValues={getValues}
                    hideExcludedDocuments={hideExcludedDocuments}
                    setHideExcludedDocuments={setHideExcludedDocuments}
                    setQuickEdit={setQuickEdit}
                    saveChanges={saveChanges}
                    parentRefreshAction={editParentRefreshAction}
                  />
                </Col>
              </Row>
            }
          />

          <Route
            path="updatedocumentform/:documentKey"
            element={<UpdateDocumentForm />}
          />
        </Routes>
      </div>
    );
  },
  (prevProps, nextProps) =>
    (prevProps.headerStatistics == null &&
      nextProps.headerStatistics == null) ||
    (prevProps.headerStatistics != null &&
      nextProps.headerStatistics != null &&
      prevProps.headerStatistics.DocCount ===
        nextProps.headerStatistics.DocCount)
);

const NewBatchDocumentsBody = React.memo(
  ({
    headerStatistics,
    getValues,
    hideExcludedDocuments,
    setHideExcludedDocuments,
    setQuickEdit,
    saveChanges,
    parentRefreshAction,
  }) => {
    //
    const dispatch = useDispatch();

    //
    const [clearAll, setClearAll] = useState(false);
    const tableRef = useRef(null);
    const originalTimestamp = useRef("");
    const tableData = useSelector((state) =>
      state.ui.tables.find((b) => b.name === tableNames.BatchCreateStep2)
    );

    const filteredInfo = tableData?.filteredInfo;

    const isFilteredOnUi = !isTableFilteredInfoNotFiltered(filteredInfo);

    const filteredDocumentKeys = isFilteredOnUi
      ? tableData?.extra?.currentDataSource?.map((d) => d.DocumentKey) ?? []
      : [];

    const selectedDocumentKeys = useSelector(
      (state) => state.batching.newBatch.ui.selectedDocumentKeys
    );

    const documentsItemNumbers = useSelector(
      (state) => state.batching.newBatch.ui.documentsItemNumbers
    );

    const list = useSelector(
      (state) =>
        state.batching.newBatch.data && state.batching.newBatch.data.Documents
    );

    const allDocumentKeys = useSelector(
      (state) =>
        state.batching.newBatch.ui && state.batching.newBatch.ui.allKeysList
    );
    const loading = useSelector((state) => state.batching.newBatch.loading);
    const criteria = useSelector(
      (state) =>
        state.batching.newBatch.data && state.batching.newBatch.criteria
    );
    const batchType = criteria && criteria.BatchType;

    useEffect(() => {
      tableRef?.current?.refreshTotals &&
        tableRef?.current?.refreshTotals(() => {}); // Passing an empty function as a parameter prevents it from jumping to page 1
    }, [headerStatistics?.DocCount]);

    const columns =
      batchType !== "Regular"
        ? remove(
            newBatchDocumentColumns(
              hideExcludedDocuments,
              selectedDocumentKeys,
              documentsItemNumbers,
              setQuickEdit,
              saveChanges
            ),
            (e) =>
              e.dataIndex !== "OnlineCreditPayment" && e.dataIndex !== "Channel"
          )
        : remove(
            newBatchDocumentColumns(
              hideExcludedDocuments,
              selectedDocumentKeys,
              documentsItemNumbers,
              setQuickEdit,
              saveChanges
            ),
            (e) => e.dataIndex !== "FleetNumber"
          );

    const toolbarButtons = [
      <Button size="small" onClick={parentRefreshAction}>
        <ReloadOutlined />
        Refresh
      </Button>,
      <IncludeAllButton
        allDocumentKeys={allDocumentKeys}
        hideExcludedDocuments={hideExcludedDocuments}
        getValues={getValues}
        isFilteredOnUi={isFilteredOnUi}
        filteredDocumentKeys={filteredDocumentKeys}
      />,
      <ExcludeAllButton
        getValues={getValues}
        allDocumentKeys={allDocumentKeys}
        hideExcludedDocuments={hideExcludedDocuments}
        isFilteredOnUi={isFilteredOnUi}
        filteredDocumentKeys={filteredDocumentKeys}
      />,
      <ToggleExcludedDocumentsButton
        setHideExcludedDocuments={setHideExcludedDocuments}
        getValues={getValues}
        hideExcludedDocuments={hideExcludedDocuments}
      />,
      <PrintButton headerStatistics={headerStatistics} getValues={getValues} />,
    ];

    const resetValuesOnRowEdit = (record) => {
      // If there's no timestamp, prefill the field
      originalTimestamp.current = record.DCID ?? "";
      var customTimestamp =
        record.IsUserAllowedToSave &&
        (!record.IsDownloaded || record.IsFinancial) &&
        (record.DCID == null || record.DCID == "") &&
        record.EntryDateTime != null
          ? dayjs(record.EntryDateTime).format("YYYYMMDD")
          : record.DCID;
      var initialValues = { ...record, DCID: customTimestamp };
      return initialValues;
    };

    const onInlineEditSubmitHandler = (values) => {
      // For documents where Timestamp edit is on
      var newTimestamp = values.DCID;
      var canEditTimestamp =
        values.IsUserAllowedToSave &&
        (!values.IsDownloaded || values.IsFinancial);
      if (canEditTimestamp) {
        newTimestamp =
          values.DCID != null && values.DCID.length > 8
            ? values.DCID
            : originalTimestamp.current;
      }

      const shouldValidateAndUpdateTimestamp =
        !canEditTimestamp || newTimestamp != originalTimestamp.current;
      dispatch(
        updateInlineEditChangesAction(
          { ...values, DCID: newTimestamp },
          false,
          shouldValidateAndUpdateTimestamp,
          parentRefreshAction
        )
      );
    };

    return (
      <SearchableTable
        enableInlineEdit={true}
        onInlineEditSubmitHandler={onInlineEditSubmitHandler}
        resetValuesOnRowEdit={resetValuesOnRowEdit}
        useDelayedLoading={true}
        loadingDelay={200}
        tableName={tableNames.BatchCreateStep2}
        setClearAll={setClearAll}
        clearAll={clearAll}
        scroll={{ y: 400, x: batchType !== "Regular" ? 1500 : 1630 }}
        sticky
        dataSource={list}
        columns={columns}
        validationSchema={inlineEditDocumentSchema}
        loading={loading}
        pageSize={1000}
        size="small"
        rowKey={(r) => r.DocumentKey}
        buttons={toolbarButtons}
        excludedFromFiltering={["DocumentKey"]}
        useRefreshState={true}
        customRef={tableRef}
        alternativeTotal={headerStatistics?.DocCount ?? 0}
        useAlternativeTotal={hideExcludedDocuments == true}
        rowSelection={{
          columnWidth: 40,
          selectedRowKeys: selectedDocumentKeys,
          fixed: true,
          hideSelectAll: true,
          renderCell: (checked, record, _, __) => (
            <CheckedRowButton
              checked={checked}
              documentKey={record.DocumentKey}
              hideExcludedDocuments={hideExcludedDocuments}
              tableName={tableNames.BatchCreateStep2}
              tooltipText={record.ExcludedReason}
            />
          ),
        }}
        rowClassNameExtended={(record, defaultFunction) => {
          if (
            selectedDocumentKeys.includes(record["DocumentKey"]) !== true &&
            record.ExcludedReason
          ) {
            return "batching-table-excluded-reason";
          }
          return defaultFunction(record);
        }}
        parentRefreshAction={parentRefreshAction}
      />
    );
  },
  (prevProps, nextProps) =>
    (prevProps.headerStatistics == null &&
      nextProps.headerStatistics == null) ||
    (prevProps.headerStatistics != null &&
      nextProps.headerStatistics != null &&
      prevProps.headerStatistics.DocCount ===
        nextProps.headerStatistics.DocCount &&
      prevProps.hideExcludedDocuments === nextProps.hideExcludedDocuments)
);
