import React from "react";
import { Alert, Typography } from "antd";

const { Text } = Typography;
const NoEditPermissionAlert = () => {
  return (
    <Alert
      showIcon
      message={
        <>
          {"You only have "}
          <Text strong>View Documents</Text>
          {" permission."}
        </>
      }
      type="info"
      closable
    />
  );
};

export default React.memo(NoEditPermissionAlert);
