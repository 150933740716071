import React, { useEffect } from "react";
import { createAction } from "../../store/agency/agencyActions";
import { useDispatch, useSelector } from "react-redux";
import AgencyForm from "./AgencyForm";
import { useLocation, useNavigate } from "react-router-dom";

const CreateAgencyForm = () => {
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  //
  const onSubmitHandler = (values) => dispatch(createAction(values));
  const initialValues = {
    AgencyNumber: "",
    Address2: "",
    Active: true,
    StateProvinceCode: "BC",
    AutomatedDownload: false,
    ShowExpiryDateOnLabels: false,
    ShowPlateNumberOnLabels: false,
    ShowLessorNameOnLabels: false,
    SmuserId: "",
    ExtranetPassword: "",
    AutomatedDownload: false,
  };
  const agency = useSelector((state) => state.agency.selected);

  //
  useEffect(() => {
    agency &&
      navigate(`/agencies/updateagencyform/${agency.AgencyKey}`, {
        state: location.state,
        replace: true,
      });
  }, [agency, navigate]);

  return (
    <AgencyForm
      onSubmitHandler={onSubmitHandler}
      initialValues={initialValues}
      isNew={true}
    />
  );
};

export default CreateAgencyForm;
