import { PrinterOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Row, Space } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import { transformPaymentDetailsInput } from "../../store/loanCalculator/loanCalculatorActions";
import {
  fetchReport,
  setReportModalVisibility,
} from "../../store/report/reportActions";
import { formatCurrency } from "../shared/formatting";
import PaymentTable from "./PaymentTable";

const PaymentSchedule = () => {
  //
  const dispatch = useDispatch();

  //
  const data = useSelector(
    (state) => state.loanCalculator.paymentDetails?.data
  );
  const header = useSelector(
    (state) => state.loanCalculator.paymentDetails?.header
  );
  const isFetching = useSelector((state) => state.report.isFetching);

  //
  const descriptionItems = useMemo(
    () => [
      {
        key: "1",
        label: "Monthly Payment",
        children: <div>{formatCurrency(data?.MonthlyPayment ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
      {
        key: "2",
        label: "Total Monthly Payments",
        children: <div>{formatCurrency(data?.TotalMonthlyPayment ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
      {
        key: "3",
        label: "Downpayment",
        children: <div>{formatCurrency(data?.DownPaymentCalculated ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
      {
        key: "4",
        label: "Total Downpayment",
        children: <div>{formatCurrency(data?.AmountOfCheque ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
      {
        key: "5",
        label: "Processing Charge",
        children: <div>{formatCurrency(header?.ProcessingCharge ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
      {
        key: "6",
        label: "Total Interest",
        children: <div>{formatCurrency(data?.TotalInterestPaid ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
      {
        key: "7",
        label: "Policy Fees",
        children: <div>{formatCurrency(header?.PolicyFees ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
      {
        key: "8",
        label: "Amount Financed",
        children: <div>{formatCurrency(data?.AmountFinanced ?? 0)}</div>,
        contentStyle: { textAlign: "right" },
      },
    ],
    [data, header]
  );

  return (
    <div>
      <Row gutter={[16, 16]} justify="space-between">
        <Col flex="600px">
          <Descriptions
            bordered
            size="small"
            column={2}
            items={descriptionItems}
          />
        </Col>
        <Col>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button
                  loading={isFetching}
                  type="primary"
                  size="small"
                  onClick={() => {
                    dispatch(
                      fetchReport(
                        transformPaymentDetailsInput({
                          header: header,
                          data: data,
                        }),
                        entityType.LOAN_CALCULATOR,
                        "PrintPaymentTable"
                      )
                    );
                    dispatch(setReportModalVisibility(true));
                  }}
                >
                  <PrinterOutlined />
                  Create Payment Schedule
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <PaymentTable />
    </div>
  );
};

export default PaymentSchedule;
