import * as yup from "yup";
import { requiredMessage } from "../../shared/form/formValidatonRules";

export const commissionRateSchema = yup.object().shape({
  RatePercent: yup
    .number()
    .min(0, "Value must be between 0 and 100")
    .max(100, "Value must be between 0 and 100")
    .nullable(),
});

export const commissionRatesSchema = yup.object().shape({
  AgencyKeys: yup
    .array()
    .required(requiredMessage)
    .min(1, requiredMessage)
    .nullable(),
});
