import { Actions } from "../rateClass/rateClassConstants";
import produce from "immer";

const initialState = {
  data: null,
  loading: false,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_RATE_CLASSES:
        draft.loading = true;
        return draft;
      case Actions.LIST_RATE_CLASSES_SUCCEEDED:
        draft.loading = false;
        draft.data = action.payload;
        return draft;
      default:
        return state;
    }
  });
};
