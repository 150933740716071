import {
  DeleteOutlined,
  DownOutlined,
  PrinterOutlined,
  SaveOutlined,
  UndoOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space } from "antd";
import { isEmpty, isNull } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearSelectedWarningMessageAction,
  deleteAction,
  setExpandSectionsAction,
  setIgnoreSelectedWarningAction,
  transformDocumentInput,
} from "../../../store/document/documentActions";
import {
  fetchReport,
  setReportModalVisibility,
} from "../../../store/report/reportActions";
import { BackButton } from "../../shared/BackButton";
import specialViewProductKeys from "./specialViewProductKeys";

const AllTopRowButtons = (props) => (
  <Row style={{ height: 30 }}>
    <Col span={16}>
      <ControlButtons {...props} />
    </Col>
    <Col span={8}>
      <ExpandCollapseButtons
        DocumentKey={props?.selected?.DocumentKey}
        isUserAllowedToDelete={props?.selected?.IsUserAllowedToDelete}
        isBatched={
          props.selected &&
          !(
            isEmpty(props.selected.AgencyBatchNo) ||
            isNull(props.selected.AgencyBatchNo)
          )
        }
      />
    </Col>
  </Row>
);

const ExpandCollapseButtons = React.memo(
  ({ DocumentKey, isUserAllowedToDelete, isBatched }) => {
    const dispatch = useDispatch();
    return (
      <Row justify="end">
        <Col>
          <Space>
            {DocumentKey != null &&
              !(DocumentKey === 0) &&
              isUserAllowedToDelete &&
              !isBatched && (
                <Popconfirm
                  title="Delete Document"
                  description="This action cannot be undone. Continue?"
                  onConfirm={() => dispatch(deleteAction(DocumentKey))}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="default" danger={true}>
                    <DeleteOutlined />
                    Delete
                  </Button>
                </Popconfirm>
              )}
            <Button
              type="dashed"
              onClick={() => dispatch(setExpandSectionsAction(true))}
            >
              <DownOutlined />
              Expand All
            </Button>
            <Button
              type="dashed"
              onClick={() => dispatch(setExpandSectionsAction(false))}
            >
              <UpOutlined />
              Collapse All
            </Button>
          </Space>
        </Col>
      </Row>
    );
  },
  (prevProps, nextProps) =>
    prevProps.DocumentKey === nextProps.DocumentKey &&
    prevProps.isUserAllowedToDelete === nextProps.isUserAllowedToDelete &&
    prevProps.isBatched === nextProps.isBatched
);

const ControlButtons = (props) => {
  const {
    reset,
    trigger,
    formState: { isDirty },
  } = useFormContext();

  return (
    <ComponentBody
      {...props}
      reset={reset}
      trigger={trigger}
      isDirty={isDirty}
    />
  );
};

const ComponentBody = React.memo(
  (props) => {
    const {
      onCloseHandler,
      hasPressedSave,
      selected,
      setSaved,
      isDirty,
      reset,
      isNew,
    } = props;

    const dispatch = useDispatch();

    return (
      <Row>
        <Col>
          <Space>
            <BackButton
              isDirty={isDirty}
              onGoBackHandler={onCloseHandler}
              name={isNew ? "Add Manual Document" : null}
            />

            <SaveButtons setSaved={setSaved} hasPressedSave={hasPressedSave} />
            <Button
              size="small"
              disabled={!isDirty}
              type="dashed"
              onClick={() => {
                var newDefaults = { ...selected };

                // Setup RoadStarAndRoadSidePlusPremium field
                if (newDefaults.RoadStarRoadSidePlusIndicator === 4) {
                  newDefaults.RoadStarAndRoadSidePlusPremium =
                    newDefaults.RoadStarPremium;
                } else if (newDefaults.RoadStarRoadSidePlusIndicator === 5) {
                  newDefaults.RoadStarAndRoadSidePlusPremium =
                    newDefaults.RoadSidePlusPremium;
                } else if (newDefaults.RoadStarPremium > 0) {
                  newDefaults.RoadStarAndRoadSidePlusPremium =
                    newDefaults.RoadStarPremium;
                } else if (newDefaults.RoadSidePlusPremium > 0) {
                  newDefaults.RoadStarAndRoadSidePlusPremium =
                    newDefaults.RoadSidePlusPremium;
                } else {
                  newDefaults.RoadStarAndRoadSidePlusPremium = 0;
                }

                reset(newDefaults);
                dispatch(setIgnoreSelectedWarningAction(false));
                dispatch(clearSelectedWarningMessageAction());
              }}
            >
              <UndoOutlined />
              Reset Values
            </Button>
            <Button
              size="small"
              disabled={
                selected == null ||
                selected.DocumentKey == null ||
                selected.DocumentKey === 0 ||
                selected.ProductKey === specialViewProductKeys.DEBT ||
                selected.ProductKey === specialViewProductKeys.PAYMENT
              }
              onClick={() => {
                dispatch(
                  fetchReport(
                    transformDocumentInput(selected),
                    entityType.DOCUMENT,
                    "PrintDocument"
                  )
                );
                dispatch(setReportModalVisibility(true));
              }}
            >
              <PrinterOutlined />
              Preview
            </Button>
          </Space>
        </Col>
      </Row>
    );
  },
  (prevProps, nextProps) =>
    prevProps.hasPressedSave === nextProps.hasPressedSave &&
    prevProps.isDirty === nextProps.isDirty &&
    ((prevProps.selected == null && nextProps.selected == null) ||
      (prevProps.selected != null &&
        nextProps.selected != null &&
        prevProps.selected.DocumentKey === nextProps.selected.DocumentKey))
);
export default AllTopRowButtons;

const SaveButtons = (props) => {
  const { setSaved, hasPressedSave } = props;
  const {
    trigger,
    formState: { isDirty, isValid, isValidating, isSubmitting, dirtyFields },
  } = useFormContext();

  return (
    <>
      {!hasPressedSave ? (
        <Button
          loading={isSubmitting}
          type="primary"
          disabled={!isDirty || isEmpty(dirtyFields) || isSubmitting}
          onClick={async () => {
            setSaved();
            if (!isValidating) {
              const res = await trigger();

              // If there are no errors we submit the form
              if (res === true) {
                // handleSubmit(onSubmit)();
              }
            }
          }}
        >
          <SaveOutlined />
          Save
        </Button>
      ) : (
        <Button
          id="processButton"
          type="primary"
          htmlType="submit"
          disabled={!isValid || !isDirty}
          loading={isSubmitting}
        >
          <SaveOutlined />
          Save
        </Button>
      )}
    </>
  );
};
