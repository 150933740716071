import { SendOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Form, Row, Space } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearOperationResultAction,
  getLatestDateRanges,
  listDataDownloadLog,
  submitDownloadRequest,
} from "../../../store/dataDownload/dataDownloadActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { dateTimeInputStyle } from "../../shared/form/formInputStyles";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import {
  dateTimeFormat,
  toStartOfDay,
  toTZNeutralFormat,
} from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { downloadRequestSchema } from "./dataDownloadSchemas";

//
const initialValues = {
  AgencyKey: "",
  FromDate: toStartOfDay(dayjs()),
  ToDate: toTZNeutralFormat(dayjs()),
};

const defaultLayout = {
  labelCol: {
    span: 8,
    sm: { span: 8 },
    md: { span: 8 },
    lg: { span: 8 },
    xl: { span: 8 },
    xxl: { span: 8 },
  },
  wrapperCol: {
    span: 16,
    sm: { span: 16 },
    md: { span: 16 },
    lg: { span: 16 },
    xl: { span: 16 },
    xxl: { span: 16 },
  },
};

const SubmitDownloadRequest = ({ agencies, resetActiveKey }) => {
  // Form
  const {
    reset,
    control,
    trigger,
    handleSubmit,
    getValues,
    formState: { defaultValues, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(downloadRequestSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector((state) => state.dataDownload.request.loading);
  const datesLoading = useSelector(
    (state) => state.dataDownload.request.datesLoading
  );
  const dates = useSelector((state) => state.dataDownload.request.dates);
  const operationResult = useSelector(
    (state) => state.dataDownload.request.operationResult
  );
  const [isInitiated, setIsInitiated] = useState(false);

  //
  useEffect(() => {
    if (agencies != null && !isEmpty(agencies) && !isInitiated) {
      var initialAgency = getInitialAgency(homeAgency, agencies);
      reset({
        ...initialValues,
        AgencyKey: initialAgency,
      });
      setIsInitiated(true);
      dispatch(getLatestDateRanges(initialAgency));
      trigger();
    }
  }, [agencies, homeAgency, isInitiated]);

  useEffect(() => {
    if (dates != null) {
      reset({
        AgencyKey: getValues().AgencyKey,
        FromDate: toTZNeutralFormat(dates.FromDate),
        ToDate: toTZNeutralFormat(dates.ToDate),
      });
      trigger();
    }
  }, [dates]);

  useEffect(() => {
    if (operationResult != null) {
      const currentValues = getValues();
      var newOperation = { ...operationResult };
      if (newOperation.Outcome === "Success") {
        const name = agencies.find(
          (a) => a.Id == currentValues.AgencyKey
        ).FullName;
        newOperation.Message = `Download request successfully submitted for ${name}.`;
        newOperation.Keep = true; // Don't automatically remove the success notification
        setIsInitiated(true);
        dispatch(getLatestDateRanges(currentValues.AgencyKey));
        dispatch(listDataDownloadLog());
        resetActiveKey && resetActiveKey();
      } else {
        reset({ ...currentValues });
        trigger();
      }
      openNotificationOperationResult(newOperation, () =>
        dispatch(clearOperationResultAction())
      );
    }
  }, [operationResult, dispatch]);

  //
  const onSubmitHandler = (values) => {
    dispatch(submitDownloadRequest(values));
  };

  return (
    <Form
      size="small"
      autoComplete="off"
      onFinish={() => handleSubmit(onSubmitHandler)()}
    >
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Row>
            <Col span={16}>
              <AgencySelectEx
                layout={defaultLayout}
                formLabel="Agency"
                name="AgencyKey"
                required={true}
                agencies={agencies}
                isLoading={defaultValues.AgencyKey == null}
                control={control}
                onChange={(value) => {
                  setIsInitiated(true);
                  dispatch(getLatestDateRanges(value));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <CustomDatePicker
                formItem={{
                  layout: defaultLayout,
                  label: "From",
                  required: true,
                }}
                control={control}
                name="FromDate"
                disabled={datesLoading}
                showTime={true}
                style={dateTimeInputStyle}
                format={dateTimeFormat}
                allowClear={false}
                disabledDate={(current) =>
                  current &&
                  (current > dayjs().endOf("day") ||
                    current < dayjs().endOf("day").subtract(41, "day"))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <CustomDatePicker
                formItem={{
                  layout: defaultLayout,
                  label: "To",
                  required: true,
                }}
                control={control}
                name="ToDate"
                disabled={datesLoading}
                showTime={true}
                style={dateTimeInputStyle}
                format={dateTimeFormat}
                allowClear={false}
                disabledDate={(current) => {
                  return (
                    current &&
                    (current > dayjs().endOf("day") ||
                      current < dayjs().endOf("day").subtract(41, "day"))
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
      <Row justify="end">
        <Col>
          <Space align="end">
            <Button
              disabled={!isValid || datesLoading || loading}
              autoFocus={true}
              id="processButton"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              <SendOutlined /> Submit
            </Button>
            <Button
              disabled={datesLoading || loading}
              icon={<UndoOutlined />}
              onClick={() => {
                setIsInitiated(true);
                dispatch(getLatestDateRanges(getValues().AgencyKey));
              }}
            >
              Reset Date
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
export default SubmitDownloadRequest;
