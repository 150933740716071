import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Popconfirm,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import {
  clearOperationResultAction,
  listMaintainInventoryItemRangesAction,
  markAsIssuedAction,
  markAsLostStolenAction,
  removeFromInventoryAction,
  undoMarkAsIssuedAction,
  undoMarkAsLostStolenAction,
} from "../../../store/inventory/inventoryActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomAutoComplete from "../../shared/form/reactHookForm/CustomAutoComplete";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { ManageInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import {
  getItemNumberFromFieldValue,
  getItemNumberToFieldValue,
  renderRangeOptions,
} from "../shared/itemRangeHelpers";
import maintainInventorySchema from "./maintainInventorySchema";
import maintainInventoryTasks from "./maintainInventoryTasks";
import NoPermissionNavigaton from "../../navigation/PermissionWrapper";

const { Text } = Typography;

// Constants
const initialValues = {
  AgencyKey: "", //getInitialAgency(homeAgency, agencies),
  Task: maintainInventoryTasks.RemoveFromInventory.value,
  ItemNumberFrom: "",
  ItemNumberTo: "",
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const seriesFormItemStyle = {
  display: "inline-block",
  width: "calc(33% - 22px)",
};

const MaintainInventory = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    setValue,
    getValues,
    formState: { isValid, isDirty, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(maintainInventorySchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [itemNumberFromOptions, setItemNumberFromOptions] = useState([]);
  const [itemNumberToOptions, setItemNumberToOptions] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector(
    (state) => state.inventory.maintainInventory.loading
  );
  const itemRanges = useSelector(
    (state) => state.inventory.maintainInventory.itemRanges
  );

  const itemRangesLoading = useSelector(
    (state) => state.inventory.maintainInventory.itemRangesLoading
  );

  //
  const listItemRanges = (agencyKey, task) => {
    dispatch(
      listMaintainInventoryItemRangesAction({
        AgencyKey: agencyKey,
        Task: task,
      })
    );
  };

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ManageInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);

      listItemRanges(initialAgency, initialValues.Task);
      reset({ ...initialValues, AgencyKey: initialAgency });

      trigger();
    }
  }, [agencies, homeAgency]);

  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  const clearAllData = () => {
    if (operationResult && operationResult.Outcome === "Information") {
      reset();
      trigger();

      var initialAgency = getInitialAgency(homeAgency, agencies);
      listItemRanges(initialAgency, initialValues.Task);
    }

    dispatch(clearOperationResultAction());
  };

  useEffect(() => {
    openNotificationOperationResult(operationResult, () => clearAllData());
  }, [operationResult]);

  useEffect(() => {
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  useEffect(() => {
    var itemRangesTypes =
      itemRanges == null || isEmpty(itemRanges)
        ? []
        : [...new Set(itemRanges.map((item) => item.ItemDescription))];

    var itemFrom =
      itemRangesTypes != null && !isEmpty(itemRangesTypes)
        ? itemRangesTypes.map((t) => renderRangeOptions(t, itemRanges, true))
        : [];

    var itemTo =
      itemRangesTypes != null && !isEmpty(itemRangesTypes)
        ? itemRangesTypes.map((t) => renderRangeOptions(t, itemRanges, false))
        : [];

    setItemNumberToOptions(itemTo);
    setItemNumberFromOptions(itemFrom);

    setValue("ItemNumberTo", "", {
      shouldValidate: true,
    });
    setValue("ItemNumberFrom", "", {
      shouldValidate: true,
    });
  }, [itemRanges]);

  const onSubmitHandler = (values) => {
    let action;
    switch (values.Task) {
      case maintainInventoryTasks.RemoveFromInventory.value:
        action = removeFromInventoryAction;
        break;
      case maintainInventoryTasks.MarkAsIssued.value:
        action = markAsIssuedAction;
        break;
      case maintainInventoryTasks.UndoMarkAsIssued.value:
        action = undoMarkAsIssuedAction;
        break;
      case maintainInventoryTasks.MarkAsLostStolen.value:
        action = markAsLostStolenAction;
        break;
      case maintainInventoryTasks.UndoMarkAsLostStolen.value:
        action = undoMarkAsLostStolenAction;
        break;
      default:
        action = null;
        break;
    }

    dispatch(action(values));
  };

  return (
    <NoPermissionNavigaton entityName="MaintainInventory">
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" requiredMark={true}>
            <Card
              title={
                <Row>
                  <Col span={8}>
                    <Text>Update Inventory Items</Text>
                  </Col>
                  <Col span={4} offset={12}>
                    <Space>
                      <Popconfirm
                        title="Proceed with changes?"
                        onConfirm={() => handleSubmit(onSubmitHandler)()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="primary"
                          disabled={!isValid || !isDirty}
                          loading={loading}
                        >
                          Confirm
                        </Button>
                      </Popconfirm>
                      <Button
                        onClick={() => {
                          reset();
                          trigger();
                        }}
                      >
                        Reset Values
                      </Button>
                    </Space>
                  </Col>
                </Row>
              }
              size="small"
              bordered={true}
            >
              <Spin spinning={loading}>
                <Row>
                  <Col span={8}>
                    <AgencySelectEx
                      layout={layout}
                      formLabel="Agency"
                      name="AgencyKey"
                      required={true}
                      formStyle={{ marginBottom: 0 }}
                      agencies={currentAgencies}
                      isLoading={defaultValues.AgencyKey === ""}
                      control={control}
                      onChange={(value) =>
                        listItemRanges(value, getValues().Task)
                      }
                    />
                  </Col>
                </Row>
                <Divider stlyle={{ marginTop: 0, marginBottom: 8 }} />
                <Row>
                  <Col span={8}>
                    <CustomRadioGroup
                      control={control}
                      layout={layout}
                      formLabel="Task"
                      name="Task"
                      required={true}
                      afterChange={(event) =>
                        listItemRanges(
                          getValues().AgencyKey,
                          event.target.value
                        )
                      }
                    >
                      <Space direction="vertical">
                        <Radio
                          value={
                            maintainInventoryTasks.RemoveFromInventory.value
                          }
                        >
                          {maintainInventoryTasks.RemoveFromInventory.label}
                        </Radio>
                        <Radio
                          value={maintainInventoryTasks.MarkAsIssued.value}
                        >
                          {maintainInventoryTasks.MarkAsIssued.label}
                        </Radio>
                        <Radio
                          value={maintainInventoryTasks.UndoMarkAsIssued.value}
                        >
                          {maintainInventoryTasks.UndoMarkAsIssued.label}
                        </Radio>
                        <Radio
                          value={maintainInventoryTasks.MarkAsLostStolen.value}
                        >
                          {maintainInventoryTasks.MarkAsLostStolen.label}
                        </Radio>
                        <Radio
                          value={
                            maintainInventoryTasks.UndoMarkAsLostStolen.value
                          }
                        >
                          {maintainInventoryTasks.UndoMarkAsLostStolen.label}
                        </Radio>
                      </Space>
                    </CustomRadioGroup>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      labelCol={{ span: 5 }}
                      wrapperCol={{ span: 29 }}
                      style={{ marginBottom: 0 }}
                      label="Series"
                      name="Series"
                      required
                    >
                      <CustomAutoComplete
                        loading={itemRangesLoading}
                        control={control}
                        required={true}
                        formStyle={seriesFormItemStyle}
                        name="ItemNumberFrom"
                        options={itemNumberFromOptions}
                        filterOption={true}
                        allowClear
                        style={{ width: 230 }}
                        onSelect={(value) =>
                          setValue(
                            "ItemNumberTo",
                            getItemNumberToFieldValue(itemRanges, value),
                            {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            }
                          )
                        }
                        afterChange={(value) => {
                          if (isEmpty(value)) {
                            setValue("ItemNumberTo", "", {
                              shouldValidate: true,
                            });
                            setValue("ItemNumberFrom", "", {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />

                      <span
                        style={{
                          display: "inline-block",
                          width: "24px",
                          textAlign: "center",
                        }}
                      >
                        -
                      </span>

                      <CustomAutoComplete
                        loading={itemRangesLoading}
                        control={control}
                        required={true}
                        formStyle={seriesFormItemStyle}
                        allowClear
                        name="ItemNumberTo"
                        options={itemNumberToOptions}
                        filterOption={true}
                        style={{ width: 230 }}
                        onSelect={(value) =>
                          setValue(
                            "ItemNumberFrom",
                            getItemNumberFromFieldValue(itemRanges, value),
                            {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            }
                          )
                        }
                        afterChange={(value) => {
                          if (isEmpty(value)) {
                            setValue("ItemNumberTo", "", {
                              shouldValidate: true,
                            });
                            setValue("ItemNumberFrom", "", {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Form>
        </Col>
      </Row>
    </NoPermissionNavigaton>
  );
};

export default MaintainInventory;
