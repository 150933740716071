import { LeftCircleTwoTone, RightCircleTwoTone } from "@ant-design/icons";
import { Card, Col, ConfigProvider, Layout, Row } from "antd";

import dayjs from "dayjs";
import { isNull } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import version from "../../version.json";
import ActorMenu from "./ActorMenu";
import "./MainLayout.css";
import PageTitle from "./PageTitle";
import SideMenu from "./SideMenu";

const { Header, Sider, Footer } = Layout;

const defaultComponentConfig = {
  lineWidth: 2,
  colorBorder: "#d5dde6",
  colorTextDisabled: "rgba(0, 0, 0, 0.88)",
};

const LayoutUserLoggedIn = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [broken, setBroken] = useState(false);
  const actorData = useSelector((state) => state.actor.details.data);

  return (
    <Layout>
      <Header className="header-layout-parent">
        <Row>
          <Col flex="200px">
            <div
              style={{
                width: "200px",
                verticalAlign: "middle",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div className="logoImage"></div>
            </div>
          </Col>
          <Col flex="auto">
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                },
                components: {
                  Button: {
                    colorPrimaryHover: "#ec3a00",
                    colorPrimaryActive: "#ec3a00",
                  },
                },
              }}
            >
              <ActorMenu />
            </ConfigProvider>
          </Col>
        </Row>
      </Header>

      <Layout hasSider={true}>
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 8,
              colorPrimary: "#ec3a00",
            },
            components: {
              Button: {
                colorPrimaryHover: "#ec3a00",
                colorPrimaryActive: "#ec3a00",
              },
            },
          }}
        >
          <Sider
            collapsible
            style={{
              height: "calc(100vh - 65px)",
              paddingTop: "20px",
              background: "#fafbfc",
              borderRight: "2px solid #d5dde6",
            }}
            collapsedWidth={collapsedWidth}
            collapsed={collapsed}
            breakpoint="md"
            onBreakpoint={(b) => {
              setBroken(b);
              setCollapsed(b);
              setCollapsedWidth(b ? 0 : 80);
            }}
            zeroWidthTriggerStyle={{
              backgroundColor: "#ffffff",
              background: "#ffffff",
              border: "1px solid #d5dde6",
              top: "auto",
              bottom: "184px",
            }}
            onCollapse={(_) => {
              if ((!broken && collapsed) || (broken && collapsedWidth == 0)) {
                if (broken) {
                  setCollapsedWidth(80);
                } else {
                  setCollapsed(!collapsed);
                }
              } else {
                if (broken) {
                  setCollapsedWidth(0);
                } else {
                  setCollapsed(!collapsed);
                }
              }
            }}
            trigger={
              (!broken && collapsed) || (broken && collapsedWidth == 0) ? (
                <RightCircleTwoTone
                  twoToneColor="#ec3a00"
                  className="trigger"
                />
              ) : (
                <LeftCircleTwoTone twoToneColor="#ec3a00" className="trigger" />
                // 888d1d
              )
            }
          >
            <SideMenu />
          </Sider>
        </ConfigProvider>

        <Layout className="body-layout-parent">
          <div className="scroll-body-layout">
            <Header className="body-header">
              <PageTitle />
            </Header>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#78a1cf",
                  borderRadius: 8,
                },
                components: {
                  Input: defaultComponentConfig,
                  Select: defaultComponentConfig,
                  Checkbox: defaultComponentConfig,
                  Radio: defaultComponentConfig,
                  Pagination: defaultComponentConfig,
                  InputNumber: defaultComponentConfig,
                  DatePicker: defaultComponentConfig,
                  Table: {
                    cellPaddingInline: 4,
                    cellPaddingBlock: 4,
                    cellFontSize: 13,
                    cellFontSizeMD: 13,
                    cellFontSizeSM: 13,
                    cellPaddingBlockMD: 4,
                    cellPaddingBlockSM: 4,
                    cellPaddingInlineMD: 4,
                    cellPaddingInlineSM: 4,
                  },
                },
              }}
            >
              <div
                style={{
                  background: "#f5f5f5",
                  flex: 1,
                }}
              >
                <div style={{ background: "#f5f5f5" }}>
                  <Card className="body-card-content">
                    {!isNull(actorData) && props.children}
                  </Card>
                </div>
              </div>
            </ConfigProvider>

            <Footer
              style={{
                textAlign: "center",
                background: "#f5f5f5",
                color: "grey",
                minHeight: "54px",
                padding: "12px 24px",
              }}
            >
              ©{new Date().getFullYear()} Created by broker link software inc. -
              Version {version.major}.{version.minor}.{version.build} (Last
              Updated on{" "}
              {dayjs(version.date).format(
                process.env.REACT_APP_ENV !== "production"
                  ? "DD-MMM-YYYY hh:mm A"
                  : "DD-MMM-YYYY"
              )}
              )
            </Footer>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutUserLoggedIn;
