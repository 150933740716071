import * as yup from "yup";
import {
  dateRangeRequiredMessage,
  fleetCommaListMessage,
  fleetGarageCommaListRegexOrEmptyString,
  maxMessage,
  requiredMessage,
} from "../shared/form/formValidatonRules";
import { searchByFields } from "./reportParameters";

export const scheduleOfVehiclesSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  ExpiryDate: yup.array().required(dateRangeRequiredMessage).nullable(),
  EffectiveAsOfDate: yup.string().required(requiredMessage).nullable(),
  SortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  InsuredName: yup.string().max(70, maxMessage),
  Customer: yup
    .string()
    .max(70, maxMessage)
    .when("SearchBy", {
      is: searchByFields.CUSTOMER_NAME,
      then: yup.string().required("Required"),
    }),
  ReportRecipient: yup.string().max(200, maxMessage),
  InsuredAddress: yup.string().max(200, maxMessage),
  FleetNumbers: yup
    .string()
    .max(70, maxMessage)
    .matches(fleetGarageCommaListRegexOrEmptyString, fleetCommaListMessage)
    .when("SearchBy", {
      is: searchByFields.FLEET_NUMBERS,
      then: yup.string().required("Required"),
    }),
});

export const confirmationOfInsuranceSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  ExpiryDate: yup.string().required(requiredMessage).nullable(),
  EffectiveAsOfDate: yup.string().required(requiredMessage).nullable(),
  SortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  InsuredName: yup.string().max(70, maxMessage),
  Customer: yup
    .string()
    .max(70, maxMessage)
    .when("SearchBy", {
      is: searchByFields.CUSTOMER_NAME,
      then: yup.string().required("Required"),
    }),
  ReportRecipient: yup.string().max(200, maxMessage),
  InsuredAddress: yup.string().max(200, maxMessage),
  FleetNumbers: yup
    .string()
    .max(70, maxMessage)
    .matches(fleetGarageCommaListRegexOrEmptyString, fleetCommaListMessage)
    .when("SearchBy", {
      is: searchByFields.FLEET_NUMBERS,
      then: yup.string().required("Required"),
    }),
});
