import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { setMultiLicencePoliciesSelectedRowsAction } from "../../../store/batching/batchingActions";
import UpdateDocumentForm from "../../document/form/UpdateDocumentForm";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { multiLicencePoliciesColumns } from "./batchingColumns";
import { tableNames } from "../../shared/table/tableNames";

const MultiLicencePolicies = ({ list, loading }) => {
  const [clearAll, setClearAll] = useState(false);
  const dispatch = useDispatch();

  const selectedPoliciesRows = useSelector(
    (state) => state.batching.newBatch.multiLicencePolicies.selectedRows
  );

  const onSelectChange = (selectedRowKeys) => {
    dispatch(setMultiLicencePoliciesSelectedRowsAction(selectedRowKeys));
  };

  const selectAllButton = (
    <Button
      onClick={() => {
        const selectedKeys = list.map((l) => l.PolicyNumber);
        dispatch(setMultiLicencePoliciesSelectedRowsAction(selectedKeys));
      }}
      tabIndex={-1}
      size={"small"}
      disabled={list == null || list.length === 0}
    >
      <CheckOutlined />
      Select All
    </Button>
  );
  const deselectAllButton = (
    <Button
      onClick={() => {
        dispatch(setMultiLicencePoliciesSelectedRowsAction([]));
      }}
      tabIndex={-1}
      size={"small"}
      disabled={list == null || list.length === 0}
    >
      <CloseOutlined />
      Deselect All
    </Button>
  );

  const toolbarButtons = [selectAllButton, deselectAllButton];

  const columns = multiLicencePoliciesColumns();
  return (
    <div>
      <Routes>
        <Route
          path=""
          element={
            <Row>
              <Col>
                <SearchableTable
                  bordered
                  setClearAll={setClearAll}
                  clearAll={clearAll}
                  scroll={{ x: 550, y: "40vh" }}
                  sticky
                  dataSource={list}
                  columns={columns}
                  loading={loading}
                  size="small"
                  rowKey={(r) => r.PolicyNumber}
                  rowSelection={{
                    columnWidth: 30,
                    selectedRowKeys: selectedPoliciesRows,
                    onChange: onSelectChange,
                    fixed: true,
                    hideSelectAll: true,
                  }}
                  buttons={toolbarButtons}
                  tableName={tableNames.BatchCreateStep1}
                />
              </Col>
            </Row>
          }
        />
        <Route
          path="updatedocumentform/:documentKey"
          element={<UpdateDocumentForm />}
        />
      </Routes>
    </div>
  );
};
export default MultiLicencePolicies;
