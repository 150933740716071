export const types = {
  AllocationFromOfficeToRoadAgent: 1,
  ReturnFromRoadAgentToOffice: 2,
  TransferToAnotherAgency: 3,
  DefectiveStockReturn: 5,
  UnusedStockReturn: 6,
  AllocationFromRoadAgentToRoadAgent: 7,
  ReceiptFromICBC: 8,
  ReceiptFromAnotherAgency: 9,
};

export const movementOptions = [
  { value: types.ReceiptFromICBC, label: "Receive stock from ICBC" },
  {
    value: types.ReceiptFromAnotherAgency,
    label: "Receive stock transferred by another broker",
  },
  {
    value: types.AllocationFromOfficeToRoadAgent,
    label: "Allocate from office to road agent",
  },
  {
    value: types.AllocationFromRoadAgentToRoadAgent,
    label: "Allocate from road agent to road agent",
  },
  {
    value: types.ReturnFromRoadAgentToOffice,
    label: "Return from road agent to office",
  },
  {
    value: types.TransferToAnotherAgency,
    label: "Transfer stock to another broker",
  },

  { value: types.UnusedStockReturn, label: "Return to ICBC - unused stock" },
  {
    value: types.DefectiveStockReturn,
    label: "Return to ICBC - defective stock",
  },
];
