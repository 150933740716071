import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DownloadOutlined,
  ExclamationCircleTwoTone,
  PrinterOutlined,
  UnorderedListOutlined,
  WarningTwoTone,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  InputNumber,
  Modal,
  Progress,
  Row,
  Space,
  Spin,
  Tooltip,
  Tree,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import PDFJSWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
import * as pdfjsViewer from "pdfjs-dist/legacy/web/pdf_viewer";
import "pdfjs-dist/legacy/web/pdf_viewer.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Bookmark } from "../../assets/bookmark.svg";
import {
  downloadReport,
  selectReportFileName,
  setReportModalVisibility,
} from "../../store/report/reportActions";
import { logPrintDialogError } from "../../store/ui/uiActions";
import "./PdfViewer.css";
import { printPdfFile } from "./printer/print";

const { Text } = Typography;
pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;

const DEFAULT_PAGE_NUMBER = 1;
const MAX_ZOOM = 2.0;
const MIN_ZOOM = 0.3;
const MODAL_WIDTH = 1240;
const PRINT_UNITS = 150 / 72.0;
const PRINT_SCALE = 1.5;
const PRINT_DELAY = 1000;

const BookmarksMenu = (props) => {
  return (
    <div
      className={
        props.isDarkMode ? "pdf-bookmarks-menu-dark" : "pdf-bookmarks-menu"
      }
    >
      <Tree
        showIcon
        style={{ background: "#e8e8e8" }}
        onSelect={props.onClick}
        treeData={props.content}
        defaultExpandAll={true}
        icon={<Bookmark />}
      />
    </div>
  );
};

function useForceUpdate() {
  const [value, setValue] = useState(1);
  const shouldFocusNumber = value;
  const forceUpdate = (shouldFocus) => {
    var isFocused = shouldFocusNumber % 2 === 0;
    var increment =
      (isFocused && shouldFocus === true) || (!isFocused && !shouldFocus)
        ? 2
        : 1;
    setValue((value) => value + increment);
  };

  return { forceUpdate, shouldFocusNumber };
}

export default function PdfViewer(props) {
  const isModal =
    props != null && props.isModalViewer != null && props.isModalViewer == true;
  const dispatch = useDispatch();
  const pdfViewerRef = useRef();
  const isMounted = useRef();
  const { forceUpdate, shouldFocusNumber } = useForceUpdate();
  const pageNumberTimeout = useRef(null);
  const windowPrintTimeout = useRef(null);

  const eventBus = new pdfjsViewer.EventBus();
  const pdfLink = new pdfjsViewer.PDFLinkService({
    eventBus,
  });

  pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;

  let recentlyUnmounted = false;

  const [pdfViewerObject, setPdfViewerObject] = useState({});
  const [pdfLinkService, setPdfLinkService] = useState(pdfLink);
  const [loadedPdf, setLoadedPdf] = useState(null); // current pdf ref
  const [contentTable, setContentTable] = useState([]);
  const [bookmarksVisible, setBookmarksVisible] = useState(false);
  const [hasFailedLoading, setHasFailedLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showLoadingBar, setShowLoadingBar] = useState(false);

  const isDirty = props.isDirty || hasFailedLoading === true;

  // Report state variables
  const url = useSelector((state) => state.report.url);
  const reportFileName = useSelector((state) => selectReportFileName(state));
  const isFetching = useSelector((state) => state.report.isFetching);
  const isDownloading = useSelector((state) => state.report.isDownloading);
  const isDarkMode = useSelector((state) => state.actor.details.darkMode);

  //
  const deletePrintContainer = () => {
    //
    var printContainer = document.getElementById("printContainer");
    var printContainerStyle = document.getElementById("printContainerStyle");

    //
    if (printContainer) {
      printContainer.innerHTML = "";
      printContainer.textContent = "";
      printContainer.remove();
    }

    //
    if (printContainerStyle) {
      printContainerStyle.textContent = "";
      printContainerStyle.remove();
    }
  };

  // To avoid memory leaks
  useEffect(() => {
    document.body.setAttribute("data-pdfjsprinting", true);
    window.addEventListener("afterprint", onAfterPrint);
    return () => {
      isMounted.current = false;
      if (loadedPdf != null) {
        loadedPdf.destroy();
      }
      setLoadedPdf(null);
      setPdfViewerObject({});
      setIsPrinting(false);
      recentlyUnmounted = true;
      clearTimeout(pageNumberTimeout.current);
      clearTimeout(windowPrintTimeout.current);
      window.removeEventListener("afterprint", onAfterPrint);
      document.body.removeAttribute("data-pdfjsprinting");
      deletePrintContainer();
    };
  }, []);

  useEffect(() => {
    if (pdfViewerRef != null && pdfViewerRef.current != null) {
      if (recentlyUnmounted) {
        recentlyUnmounted = false;
      } else {
        const currentRef = pdfViewerRef.current;
        setPdfViewerObject(
          new pdfjsViewer.PDFViewer({
            container: currentRef,
            eventBus,
            linkService: pdfLinkService,
            enableScripting: true, // Only necessary in PDF.js version 2.10.377 and below.
          })
        );
      }
    }
  }, [pdfViewerRef]);

  useEffect(() => {
    if (
      pdfViewerObject != null &&
      pdfViewerObject != {} &&
      !isEmpty(pdfViewerObject)
    ) {
      pdfLinkService.setViewer(pdfViewerObject);
    }
  }, [pdfViewerObject]);

  useEffect(() => {
    if (contentTable?.data?.length > 0 && isMounted) {
      setBookmarksVisible(true);
    } else if (isMounted) {
      setBookmarksVisible(false);
    }
  }, [contentTable]);

  useEffect(() => {
    let intervalId;
    if (isFetching === true) {
      setProgress(0);
      setShowLoadingBar(true);
      intervalId = setInterval(() => {
        setProgress((progress) => {
          if (progress >= 80) {
            clearInterval(intervalId);
            return progress;
          } else if (progress >= 60) {
            return progress + 0.1;
          } else if (progress >= 50) {
            return progress + 0.125;
          } else if (progress >= 40) {
            return progress + 0.25;
          } else if (progress >= 30) {
            return progress + 0.5;
          } else {
            return progress + 1;
          }
        });
      }, 80);
    } else if (
      isFetching === false &&
      showLoadingBar === true &&
      url === null
    ) {
      setShowLoadingBar(false);
      setProgress(80);
      setHasFailedLoading(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isMounted) {
      if (url != null && pdfViewerObject != {} && !isEmpty(pdfViewerObject)) {
        setHasFailedLoading(false);
        setBookmarksVisible(false);

        let hideLoadingBarTimer;

        const loadPdfDocument = async () => {
          try {
            if (isMounted) {
              setProgress(90);
            }
            const pdf = await pdfjsLib.getDocument(url).promise;
            if (isMounted) {
              setProgress(100);
            }
            if (isMounted && loadedPdf != null) {
              loadedPdf.destroy();
            }
            if (isMounted) {
              setLoadedPdf(pdf);
            }
            if (isMounted) {
              pdfLinkService.setDocument(pdf, null);
              pdfViewerObject.currentPageNumber = 1;
              pdfViewerObject.setDocument(pdf);
            }
            if (isMounted) {
              fetchBookmarks(pdf);
            }
            hideLoadingBarTimer = setTimeout(() => {
              setShowLoadingBar(false);
              setProgress(0);
            }, 1200);
          } catch (e) {
            if (isMounted && loadedPdf != null) {
              loadedPdf.destroy();
            }
            if (isMounted) {
              setLoadedPdf(null);
            }
            if (isMounted) {
              setHasFailedLoading(true);
            }
          }
        };
        loadPdfDocument();
        return () => clearTimeout(hideLoadingBarTimer);
      }
    }
  }, [url]);

  useEffect(() => {
    if (shouldFocusNumber % 2 === 0) {
      document.getElementById("pdf-viewer-input-page-number").focus();
    }
  }, [shouldFocusNumber]);

  const onAfterPrint = () => {
    deletePrintContainer();
    setIsPrinting(false);
  };

  const printAllPages = (pdf, numPages, scratchCanvas, canvasContext) => {
    var currentPage = 0;
    const printNextPage = async (resolve, reject) => {
      if (currentPage >= numPages) {
        resolve();
        return;
      }
      currentPage++;
      try {
        await preparePage(pdf, currentPage, scratchCanvas, canvasContext);
      } catch (_) {
        reject();
        return;
      }

      appendImageToPrintContainer(scratchCanvas).then(function () {
        printNextPage(resolve, reject);
      }, reject);
    };
    return new Promise(printNextPage);
  };

  const preparePage = async (pdf, i, scratchCanvas, canvasContext) => {
    // Get page data
    const page = await pdf.getPage(i);
    const viewport = page.getViewport({ scale: PRINT_SCALE });
    const width = viewport.width;
    const height = viewport.height;

    // Set print window dimensions (orientation)
    if (i == 1) {
      // Create print container style
      const printContainerStyle = document.createElement("style");
      printContainerStyle.id = "printContainerStyle";
      printContainerStyle.textContent = `@page { size: ${width}pt ${height}pt;}`;
      document.body.appendChild(printContainerStyle);
    }

    scratchCanvas.width = Math.floor(width * PRINT_UNITS);
    scratchCanvas.height = Math.floor(height * PRINT_UNITS);
    canvasContext.save();
    canvasContext.fillStyle = "rgb(255, 255, 255)";
    canvasContext.fillRect(0, 0, scratchCanvas.width, scratchCanvas.height);
    canvasContext.restore();

    // Render page on canvas
    await page.render({
      canvasContext: canvasContext,
      transform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
      viewport: viewport,
      intent: "print",
    }).promise;
  };

  const appendImageToPrintContainer = (scratchCanvas) => {
    // Create image
    const img = document.createElement("img");
    if ("toBlob" in scratchCanvas) {
      scratchCanvas.toBlob(function (blob) {
        img.src = URL.createObjectURL(blob);
      });
    } else {
      img.src = scratchCanvas.toDataURL();
    }

    // Append image to print container
    const wrapper = document.createElement("div");
    wrapper.className = "printedPage";
    wrapper.append(img);
    printContainer.append(wrapper);
    return new Promise(function (resolve, reject) {
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  const printDocument = async (pdf, numPages) => {
    // Clear previously printed document
    deletePrintContainer();

    // Create print container
    const printContainer = document.createElement("div");
    printContainer.id = "printContainer";
    document.body.appendChild(printContainer);

    // Create reusable canvas
    var scratchCanvas = document.createElement("canvas");
    var canvasContext = scratchCanvas.getContext("2d");

    // Print all pages
    printAllPages(pdf, numPages, scratchCanvas, canvasContext)
      .then(() => {
        if (windowPrintTimeout.current != null) {
          clearTimeout(windowPrintTimeout.current);
        }

        windowPrintTimeout.current = setTimeout(() => {
          window.print();
        }, PRINT_DELAY);
      })
      .catch((_) => {
        onAfterPrint();
      });
  };

  const parseBookmarks = async (pdf, outline, parentId = "") => {
    const parent = { data: [], keys: [] };
    if (outline && !(outline.length == 1 && outline[0].title == "Outline")) {
      for (let i = 0; i < outline.length; i++) {
        const dest = outline[i].dest;
        const keyPrefix = parentId + i.toString();
        const pageIndex = await pdf.getPageIndex(dest[0]);
        const pageId = parseInt(pageIndex) + 1;
        let children = { data: [], keys: [] };
        if (outline[i].items.length > 0) {
          children = await parseBookmarks(
            pdf,
            outline[i].items,
            keyPrefix + "-"
          );
        }
        parent.data.push({
          title: outline[i].title == "Outline" ? "N/A" : outline[i].title,
          key: keyPrefix + " " + pageId.toString(),
          children: children.data,
        });
        parent.keys.push(keyPrefix + " " + pageId.toString());
      }
    }
    return parent;
  };

  const fetchBookmarks = async (pdf) => {
    if (pdf != null) {
      try {
        const outline = await pdf.getOutline();
        const content = await parseBookmarks(pdf, outline, "");
        if (isMounted) {
          setContentTable(content);
        }
        return content;
      } catch (e) {}
    }
  };

  function onPrint() {
    const numPages = loadedPdf.numPages;
    if (numPages > 0) {
      printPdfFile({
        printable: url,
        onPrintDialogClose: () => {
          if (isMounted) {
            setIsPrinting(false);
          }
        },
        onError: (e) => {
          console.log(e);
          if (isMounted) {
            dispatch(logPrintDialogError((e ?? "").toString().slice(0, 256)));
            printDocument(loadedPdf, numPages);
          }
        },
        onLoadingStart: () => {
          if (isMounted) {
            setIsPrinting(true);
          }
        },
      });
    }
  }

  function onBookmarkClicked(e) {
    forceSetPdfViewerObject((previous) => {
      const separator = e[0]?.indexOf(" ");
      if (separator != null) {
        previous.currentPageNumber = parseInt(e[0].substring(separator + 1));
      }
      return previous;
    });
    setBookmarksVisible(true);
  }

  function forceSetPdfViewerObject(action, shouldRefocus = false) {
    if (isMounted) {
      action(pdfViewerObject);
    }
    if (isMounted) {
      forceUpdate(shouldRefocus);
    }
  }

  eventBus.on("pagesinit", function () {
    forceUpdate();
  });

  eventBus.on("pagechanging", function () {
    forceUpdate();
  });

  const ZoomOut = (props) => (
    <Tooltip title="Zoom-Out">
      <Button
        type="primary"
        shape="circle"
        size={props.size ?? "middle"}
        icon={<ZoomOutOutlined />}
        disabled={
          pdfViewerObject.currentScale <= MIN_ZOOM ||
          loadedPdf == null ||
          url == null ||
          isFetching ||
          isDirty === true
        }
        onClick={() => {
          forceSetPdfViewerObject((previous) => {
            previous.currentScale -= 0.1;
            return previous;
          });
        }}
      />
    </Tooltip>
  );

  const ZoomIn = (props) => (
    <Tooltip title="Zoom-In">
      <Button
        type="primary"
        shape="circle"
        size={props.size ?? "middle"}
        icon={<ZoomInOutlined />}
        disabled={
          pdfViewerObject.currentScale >= MAX_ZOOM ||
          loadedPdf == null ||
          url == null ||
          isFetching ||
          isDirty === true
        }
        onClick={() => {
          forceSetPdfViewerObject((previous) => {
            previous.currentScale += 0.1;
            return previous;
          });
        }}
      />
    </Tooltip>
  );

  const ToggleBookmarks = (props) => (
    <Tooltip title="Bookmarks">
      <Button
        type="default"
        shape="round"
        size={props.size ?? "middle"}
        icon={<UnorderedListOutlined />}
        disabled={
          contentTable?.data?.length <= 0 || isFetching || isDirty === true
            ? true
            : false
        }
        onClick={() => setBookmarksVisible(!bookmarksVisible)}
      />
    </Tooltip>
  );

  const PreviousPage = (props) => (
    <Tooltip title="Previous">
      <Button
        type="primary"
        shape="circle"
        size={props.size ?? "middle"}
        icon={<CaretLeftOutlined />}
        disabled={
          pdfViewerObject.currentPageNumber == null ||
          pdfViewerObject.currentPageNumber == 1 ||
          loadedPdf == null ||
          url == null ||
          isFetching ||
          isDirty === true
        }
        onClick={() =>
          forceSetPdfViewerObject((previous) => {
            previous.currentPageNumber -= 1;
            return previous;
          })
        }
      />
    </Tooltip>
  );

  const NextPage = (props) => (
    <Tooltip title="Next">
      <Button
        type="primary"
        shape="circle"
        size={props.size ?? "middle"}
        icon={<CaretRightOutlined />}
        disabled={
          pdfViewerObject.currentPageNumber == null ||
          pdfViewerObject.currentPageNumber == pdfViewerObject.pagesCount ||
          loadedPdf == null ||
          url == null ||
          isFetching ||
          isDirty === true
        }
        onClick={() =>
          forceSetPdfViewerObject((previous) => {
            previous.currentPageNumber += 1;
            return previous;
          })
        }
      />
    </Tooltip>
  );

  const InputPageNumber = () => (
    <InputNumber
      id="pdf-viewer-input-page-number"
      controls={false}
      disabled={isDirty === true}
      style={{ width: "60px" }}
      min={1}
      max={pdfViewerObject.pagesCount}
      defaultValue={DEFAULT_PAGE_NUMBER}
      value={pdfViewerObject.currentPageNumber}
      onChange={(value) => {
        if (pageNumberTimeout.current != null) {
          clearTimeout(pageNumberTimeout.current);
        }
        if (value && value >= 0 && value <= pdfViewerObject.pagesCount) {
          pageNumberTimeout.current = setTimeout(
            forceSetPdfViewerObject((previous) => {
              previous.currentPageNumber = value;
              return previous;
            }, true),
            1000
          );
        }
      }}
    />
  );

  const Print = (props) => (
    <Tooltip title="Print">
      <Button
        type="default"
        shape="round"
        size={props.size ?? "middle"}
        disabled={
          url == null ||
          isFetching ||
          reportFileName == null ||
          isDirty === true ||
          isDownloading
        }
        loading={isPrinting}
        icon={<PrinterOutlined />}
        onClick={onPrint}
      />
    </Tooltip>
  );

  const Download = (props) => (
    <Tooltip title="Download">
      <Button
        type="default"
        shape="round"
        size={props.size ?? "middle"}
        icon={<DownloadOutlined />}
        loading={isDownloading}
        disabled={
          url == null ||
          isFetching ||
          reportFileName == null ||
          isDirty === true
        }
        onClick={() => {
          dispatch(downloadReport({ url: url, fileName: reportFileName }));
        }}
      ></Button>
    </Tooltip>
  );

  return (
    <div
      className={
        isModal
          ? isDarkMode
            ? "pdf-viewer-parent-dark modal-viewer"
            : "pdf-viewer-parent modal-viewer"
          : isDarkMode
          ? "pdf-viewer-parent-dark original-viewer"
          : "pdf-viewer-parent original-viewer"
      }
    >
      <Row>
        <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
          <Row>
            <Col span={7} offset={0} style={{ padding: "5px 0px 5px 0px" }}>
              <Row>
                {contentTable?.data?.length > 0 && (
                  <Col span={5} offset={3}>
                    <ToggleBookmarks />
                  </Col>
                )}
                <Col
                  span={contentTable?.data?.length > 0 ? 16 : 24}
                  offset={contentTable?.data?.length > 0 ? 0 : 4}
                  style={{ padding: "5px 0px 5px 0px" }}
                >
                  <Space>
                    {isDirty === true && !isFetching && progress < 1 && (
                      <ExclamationCircleTwoTone
                        twoToneColor="#faad14"
                        style={{ fontSize: "20px" }}
                      />
                    )}
                    <Text ellipsis={true} strong={true}>
                      {reportFileName && url ? reportFileName.toString() : ""}
                    </Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="center" align="middle">
                <Col span={2} style={{ padding: "5px 0px 5px 0px" }}>
                  <PreviousPage />
                </Col>
                <Col span={4}>
                  <Space>
                    {loadedPdf != null && pdfViewerObject.pagesCount != null ? (
                      <InputPageNumber />
                    ) : (
                      <Text>{"--"}</Text>
                    )}
                    <Text> / {pdfViewerObject.pagesCount || "--"}</Text>
                  </Space>
                </Col>
                <Col span={2}>
                  <NextPage />
                </Col>
                <Col span={1}>{/* <LineOutlined rotate={90} /> */}</Col>
                <Col style={{ padding: "5px 0px 5px 0px" }} span={2}>
                  <ZoomOut />
                </Col>
                <Col span={2}>
                  <Text>
                    {isNaN(pdfViewerObject.currentScale)
                      ? ""
                      : Math.round(
                          pdfViewerObject.currentScale * 100
                        ).toString() + "%"}
                  </Text>
                </Col>

                <Col span={2}>
                  <ZoomIn />
                </Col>
              </Row>
            </Col>
            <Col span={4} style={{ padding: "5px 0px 5px 0px" }}>
              <Row>
                <Col span={isModal ? 7 : 6} offset={isModal ? 0 : 6}>
                  <Print />
                </Col>
                <Col span={isModal ? 7 : 10} offset={isModal ? 0 : 2}>
                  <Download />
                </Col>
                {isModal && (
                  <Col span={4} offset={6}>
                    <Tooltip title="Close">
                      <Button
                        type="default"
                        shape="round"
                        icon={<CloseOutlined />}
                        onClick={props.handleClose}
                      ></Button>
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={0} lg={24} xl={0} xxl={0}>
          <Row>
            <Col span={6} style={{ padding: "5px 0px 5px 0px" }}>
              <Row>
                {contentTable?.data?.length > 0 && (
                  <Col span={5} offset={3}>
                    <ToggleBookmarks />
                  </Col>
                )}
                <Col
                  span={contentTable?.data?.length > 0 ? 16 : 24}
                  offset={contentTable?.data?.length > 0 ? 0 : 4}
                  style={{ padding: "5px 0px 5px 0px" }}
                >
                  <Space>
                    {isDirty === true && !isFetching && progress < 1 && (
                      <ExclamationCircleTwoTone
                        twoToneColor="#faad14"
                        style={{ fontSize: "20px" }}
                      />
                    )}
                    <Text ellipsis={true} strong={true}>
                      {reportFileName && url ? reportFileName.toString() : ""}
                    </Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="center" align="middle">
                <Col span={2} style={{ padding: "5px 0px 5px 0px" }}>
                  <PreviousPage />
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  <Space>
                    {loadedPdf != null && pdfViewerObject.pagesCount != null ? (
                      <Text>{pdfViewerObject.pagesCount}</Text>
                    ) : (
                      <Text>{"--"}</Text>
                    )}
                    <Text> / {pdfViewerObject.pagesCount || "--"}</Text>
                  </Space>
                </Col>
                <Col span={2}>
                  <NextPage />
                </Col>
                <Col span={4}>{/* <LineOutlined rotate={90} /> */}</Col>
                <Col style={{ padding: "5px 0px 5px 0px" }} span={2}>
                  <ZoomOut />
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  <Text>
                    {isNaN(pdfViewerObject.currentScale)
                      ? ""
                      : Math.round(
                          pdfViewerObject.currentScale * 100
                        ).toString() + "%"}
                  </Text>
                </Col>

                <Col span={2}>
                  <ZoomIn />
                </Col>
              </Row>
            </Col>
            <Col span={6} style={{ padding: "5px 0px 5px 0px" }}>
              <Row>
                <Col span={isModal ? 7 : 6} offset={isModal ? 0 : 6}>
                  <Print />
                </Col>
                <Col span={isModal ? 7 : 10} offset={isModal ? 0 : 2}>
                  <Download />
                </Col>
                {isModal && (
                  <Col span={4} offset={6}>
                    <Tooltip title="Close">
                      <Button
                        type="default"
                        shape="round"
                        icon={<CloseOutlined />}
                        onClick={props.handleClose}
                      ></Button>
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={24} lg={0} xl={0} xxl={0}>
          <Row>
            <Col span={3} style={{ padding: "5px 0px 5px 0px" }}>
              {contentTable?.data?.length > 0 && <ToggleBookmarks />}
            </Col>
            <Col span={11}>
              <Row justify="center" align="middle">
                <Col span={5} style={{ padding: "5px 0px 5px 0px" }}>
                  <PreviousPage />
                </Col>

                <Col span={5}>
                  <NextPage />
                </Col>
                <Col span={4}>{/* <LineOutlined rotate={90} /> */}</Col>
                <Col style={{ padding: "5px 0px 5px 0px" }} span={5}>
                  <ZoomOut />
                </Col>

                <Col span={5}>
                  <ZoomIn />
                </Col>
              </Row>
            </Col>
            <Col span={10} style={{ padding: "5px 0px 5px 0px" }}>
              <Row>
                <Col span={isModal ? 7 : 6} offset={isModal ? 0 : 6}>
                  <Print />
                </Col>
                <Col span={isModal ? 7 : 10} offset={isModal ? 0 : 2}>
                  <Download />
                </Col>
                {isModal && (
                  <Col span={4} offset={4}>
                    <Tooltip title="Close">
                      <Button
                        type="default"
                        shape="round"
                        icon={<CloseOutlined />}
                        onClick={props.handleClose}
                      ></Button>
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={24} md={0} lg={0} xl={0} xxl={0}>
          <Row>
            <Col span={4}>
              {contentTable?.data?.length > 0 && (
                <ToggleBookmarks size="small" />
              )}
            </Col>
            <Col span={6}>
              <Space align="center">
                <PreviousPage size="small" />
                <NextPage size="small" />
              </Space>
            </Col>
            <Col span={6}>
              <Space align="center">
                <ZoomOut size="small" />
                <ZoomIn size="small" />
              </Space>
            </Col>
            <Col span={7}>
              <Space align="center">
                <Print size="small" />
                <Download size="small" />
                {isModal && (
                  <Tooltip title="Close">
                    <Button
                      type="default"
                      shape="round"
                      size="small"
                      icon={<CloseOutlined />}
                      onClick={props.handleClose}
                    ></Button>
                  </Tooltip>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
          <Row>
            <Col span={24}>
              <Space align="center" split={<Divider type="vertical" />}>
                {contentTable?.data?.length > 0 && (
                  <ToggleBookmarks size="small" />
                )}

                <PreviousPage size="small" />
                <NextPage size="small" />

                <ZoomOut size="small" />
                <ZoomIn size="small" />

                <Print size="small" />
                <Download size="small" />
                {isModal && (
                  <Tooltip title="Close">
                    <Button
                      type="default"
                      shape="round"
                      size="small"
                      icon={<CloseOutlined />}
                      onClick={props.handleClose}
                    ></Button>
                  </Tooltip>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      {showLoadingBar && (
        <div className="loading-bar-container">
          <Progress
            percent={progress}
            status={progress < 100 ? "active" : "success"}
            showInfo={false}
            strokeLinecap="butt"
          />
        </div>
      )}
      <Spin
        spinning={isFetching || isDirty === true}
        indicator={<div />}
        className="pdf-loading-style"
      >
        <Row>
          {bookmarksVisible && (
            <Col span={6}>
              <div
                className={
                  isDarkMode
                    ? "pdf-viewer-bookmarks-container-dark"
                    : "pdf-viewer-bookmarks-container"
                }
              >
                <BookmarksMenu
                  isDarkMode={isDarkMode}
                  onClick={onBookmarkClicked}
                  content={contentTable?.data}
                  expandedKeys={contentTable?.keys}
                />
              </div>
            </Col>
          )}

          <Col span={bookmarksVisible ? 18 : 24}>
            <div
              ref={pdfViewerRef}
              className={
                isDarkMode
                  ? "pdf-viewer-ref-container-dark"
                  : "pdf-viewer-ref-container"
              }
            >
              {hasFailedLoading && (
                <Row justify="center" align="middle">
                  <Col>
                    <Text type="warning" strong>
                      <WarningTwoTone twoToneColor="#faad14"></WarningTwoTone>{" "}
                      An error occurred while loading this file, please try
                      again.
                    </Text>
                  </Col>
                </Row>
              )}
              <div id="viewer" className="pdfViewer"></div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export function PdfViewerModal() {
  const dispatch = useDispatch();
  const isModalVisible = useSelector((state) => state.report.isModalVisible);
  const handleClose = () => {
    dispatch(setReportModalVisibility(false));
  };
  const isDarkMode = useSelector((state) => state.actor.details.darkMode);

  return (
    <Modal
      className={
        isDarkMode ? "pdf-viewer-modal-parent-dark" : "pdf-viewer-modal-parent"
      }
      open={isModalVisible}
      width={MODAL_WIDTH}
      closable={false}
      footer={null}
      onCancel={handleClose}
    >
      <PdfViewer isModalViewer={true} handleClose={handleClose} />
    </Modal>
  );
}
