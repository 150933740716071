import { OidcClient } from "@axa-fr/oidc-client";
import { OidcUserStatus, useOidc, useOidcUser } from "@axa-fr/react-oidc";
import { Modal, message } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router";
import {
  prewarmServer,
  readActorAction,
  setSessionExpiring,
} from "../../../store/actor/actorActions";
import { logCustomMessage } from "../../../store/ui/uiActions";
import ErrorBoundary from "../ErrorBoundary";
import LayoutUserLoggedIn from "../LayoutUserLoggedIn";
import { LoadingComponent } from "./LoadingComponent";
import { Stored, useCustomOidc } from "./customOidcUtils";

const OidcSecureEx = ({
  children,
  callbackPath = null,
  extras = null,
  configurationName = "default",
}) => {
  const dispatch = useDispatch();
  const getOidc = OidcClient.get;
  const oidc = getOidc(configurationName);
  const location = useLocation();
  useEffect(() => {
    if (!oidc.tokens && location.pathname === "/home") {
      dispatch({ type: "CLEAR_REDUX_STORE" });
      var currentTimestamp = Date.now();
      oidc.loginAsync(callbackPath, { timestamp: currentTimestamp });
    }
  }, [configurationName, callbackPath, extras, location]);

  if (!oidc.tokens) {
    if (location.pathname !== "/home") {
      window.location.replace(`${process.env.REACT_APP_URI}/home`);
    }
    return null;
  }
  return <>{children}</>;
};

// This allows us to secure our routes and make sure actor data gets retrieved
export const CustomOidcSecure = ({ children }) => {
  return (
    <OidcSecureEx callbackPath={`${process.env.REACT_APP_URI}/`}>
      <LoadActorDataComponent>
        <LayoutUserLoggedIn>
          <SessionInactiveTimeoutComponent>
            {children ? children : <Outlet />}
          </SessionInactiveTimeoutComponent>
        </LayoutUserLoggedIn>
      </LoadActorDataComponent>
    </OidcSecureEx>
  );
};

const SessionInactiveTimeoutComponent = ({ children }) => {
  //
  const dispatch = useDispatch();
  const { logout } = useCustomOidc();

  //
  const timeoutInMinutes = process.env.REACT_APP_TIMEOUT;
  const promptTimeoutInMinutes = process.env.REACT_APP_PROMPT_TIMEOUT;

  const sessionExpiring = useSelector(
    (state) => state.actor.details.sessionExpiring
  );

  const { getRemainingTime, activate, pause } = useIdleTimer({
    leaderElection: true,
    crossTab: true,
    onPrompt: () => {
      console.log("Idle timer prompt displayed");
      dispatch(setSessionExpiring(true));
    },
    onIdle: () => {
      console.log("Idle timer");
      logout({ hasPressedLogout: false, stopSessionExpiring: true });
    },
    onAction: (_, idleTimer) => {
      if (sessionExpiring === true) {
        if (!idleTimer.isPrompted()) {
          dispatch(setSessionExpiring(false));
        }
        console.log("Action detected while prompt is displayed");
      }
    },
    timeout: 1000 * 60 * timeoutInMinutes,
    promptBeforeIdle: 1000 * 60 * promptTimeoutInMinutes,
    debounce: 500,
    syncTimers: 500,
  });

  //
  useEffect(() => {
    activate && activate();
    if (sessionExpiring === true) {
      console.log("Hiding modal");
      dispatch(setSessionExpiring(false));
    }
    return () => {
      pause && pause();
    };
  }, []);

  return (
    <>
      <ErrorBoundary>{children}</ErrorBoundary>
      <Modal
        title="Your session is about to expire"
        open={sessionExpiring}
        okText="Log out now"
        cancelText="Stay logged in"
        onOk={() => {
          dispatch(setSessionExpiring(false));
          logout({ hasPressedLogout: true });
        }}
        onCancel={() => {
          dispatch(setSessionExpiring(false));
          activate && activate();
        }}
        closable={false}
        destroyOnClose={true}
      >
        <Countdown
          date={Date.now() + getRemainingTime()}
          renderer={({ minutes, seconds }) => (
            <div>
              You will be automatically logged out in {zeroPad(minutes)}:
              {zeroPad(seconds)}
            </div>
          )}
        />
      </Modal>
    </>
  );
};

const LoadActorDataComponent = ({ children }) => {
  //
  const dispatch = useDispatch();
  const { logout, renewTokens } = useCustomOidc();
  const { isAuthenticated } = useOidc();
  const { oidcUser, oidcUserLoadingState, reloadOidcUser } = useOidcUser();
  const [messageApi, contextHolder] = message.useMessage();

  //
  const shouldFetchActorData = useRef(false);
  const hasLoadingError = useRef(false);
  const [checkForReattempt, setCheckForReattempt] = useState(0);

  //
  const actorData = useSelector((state) => state.actor.details.data);
  const actorLoading = useSelector((state) => state.actor.details.loading);

  //
  const createLogEntry = (type) => {
    dispatch(
      logCustomMessage(
        "LogLoginLoop",
        oidcUser?.sub,
        "User stuck in login loop",
        `isAuthenticated: ${isAuthenticated}, oidcUserLoadingState: ${oidcUserLoadingState}, hasActorData: ${
          actorData == null
        }, isActorLoading: ${actorLoading == true}, type: ${type}`
      )
    );
  };

  //
  useEffect(() => {
    console.log("Current oidc state", oidcUserLoadingState);
    if (oidcUserLoadingState === OidcUserStatus.LoadingError) {
      console.log("Oidc loading error, reloading user data");
      hasLoadingError.current = true;
      reloadOidcUser();
    } else if (oidcUserLoadingState === OidcUserStatus.Loaded) {
      shouldFetchActorData.current = true;
    }
  }, [oidcUserLoadingState]);

  useEffect(() => {
    if (shouldFetchActorData.current === true && oidcUser?.sub != null) {
      sessionStorage.removeItem(Stored.REATTEMPT_LOGIN);
      hasLoadingError.current = false;
      if (actorData == null) {
        if (
          sessionStorage.getItem(Stored.LOAD_ACTOR) === Stored.DEFAULT_VALUE
        ) {
          console.log("Loading actor data");
          sessionStorage.removeItem(Stored.LOAD_ACTOR);
          shouldFetchActorData.current = false;
          dispatch(readActorAction(oidcUser.sub));
          dispatch(prewarmServer(oidcUser.sub));
        } else {
          console.log(
            actorLoading !== true
              ? "No actor data & not loading"
              : "Actor data already loading"
          );
          const time =
            (actorLoading !== true ? 0 : 25000) +
            (Math.floor(Math.random() * 10) + 1) *
              (actorLoading !== true ? 500 : 1000);
          const refetchTimeout = setTimeout(() => {
            console.log(
              actorLoading !== true
                ? "Loading actor data forcefully"
                : "Actor data stalled for 30sec, reload attempt"
            );
            shouldFetchActorData.current = false;
            dispatch(readActorAction(oidcUser.sub));
            dispatch(prewarmServer(oidcUser.sub));
          }, time);
          return () => {
            clearTimeout(refetchTimeout);
          };
        }
      } else {
        shouldFetchActorData.current = false;
      }
    }
  }, [actorData, actorLoading, dispatch, oidcUser]);

  //
  useEffect(() => {
    if (actorData == null) {
      // This timer checks if we're stuck in a loop
      const isReattemptLogin =
        sessionStorage.getItem(Stored.REATTEMPT_LOGIN) === Stored.DEFAULT_VALUE;
      const randomInterval =
        (isReattemptLogin ? 8000 : 24000) +
        ((Math.floor(Math.random() * 10) % 4) + 1) * 1000 +
        (Math.floor(Math.random() * 10) + 1) * 100;

      const reattemptTimeout = setTimeout(() => {
        setCheckForReattempt(isReattemptLogin ? 2 : 1);
      }, randomInterval);

      //
      const messageTimeout = setTimeout(() => {
        messageApi.open({
          type: "loading",
          content: "Loading in progress...",
          duration: 8,
        });
      }, 12000);

      //
      const unresponsiveTimeout = setTimeout(() => {
        setCheckForReattempt(3);
      }, 70000);

      return () => {
        clearTimeout(messageTimeout);
        clearTimeout(reattemptTimeout);
        clearTimeout(unresponsiveTimeout);
      };
    }
  }, [actorData]);

  useEffect(() => {
    if (
      checkForReattempt === 1 &&
      (oidcUserLoadingState === OidcUserStatus.Unauthenticated ||
        oidcUserLoadingState === OidcUserStatus.LoadingError ||
        hasLoadingError.current === true)
    ) {
      console.log("First user data loading attempt failed");
      createLogEntry(1);
      sessionStorage.setItem(Stored.REATTEMPT_LOGIN, Stored.DEFAULT_VALUE);
      sessionStorage.setItem(Stored.LOAD_ACTOR, Stored.DEFAULT_VALUE);
      renewTokens()
        .then(() => {})
        .catch(() => {});
      window.location.replace(`${process.env.REACT_APP_URI}/logout`);
    } else if (
      checkForReattempt === 2 &&
      sessionStorage.getItem(Stored.REATTEMPT_LOGIN) === Stored.DEFAULT_VALUE
    ) {
      console.log("Second user data loading attempt failed");
      createLogEntry(2);
      sessionStorage.removeItem(Stored.REATTEMPT_LOGIN);
      logout({ hasPressedLogout: false });
    } else if (checkForReattempt === 3) {
      console.log("Stalled on user data loading page for 1 minute, login out");
      createLogEntry(3);
      logout({ hasPressedLogout: false });
    }
  }, [checkForReattempt]);

  if (actorData == null) {
    return (
      <>
        {contextHolder}
        <MemoizedLoadingComponent />
      </>
    );
  } else {
    return children;
  }
};

const MemoizedLoadingComponent = memo(
  () => <LoadingComponent type={1} />,
  () => true
);
