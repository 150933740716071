import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import { transformFleetGarageCommissionInput } from "../../../store/commission/commissionActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import PdfViewer from "../../shared/PdfViewer";
import ReportParamPanelHeader from "../../shared/ReportParamPanelHeader";
import AgencyMultiSelectEx from "../../shared/form/AgencyMultiSelectEx";
import { DrillDownSortField } from "../../shared/form/DrillDownSortField";
import DrillDownSortFieldSelectEx from "../../shared/form/DrillDownSortFieldSelectEx";
import { SortGroupField } from "../../shared/form/SortGroupField";
import SortGroupFieldSelectEx from "../../shared/form/SortGroupFieldSelectEx";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toEndOfDay, toStartOfDay } from "../../shared/formatting";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { ViewCommissionsFullAccess } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import { commissionReportSchema } from "./commissionSchemas";
import {
  commissionReportTypeOptions,
  commissionReportTypes,
  fleetGarageDrillDownOptions,
  fleetGarageFields,
  fleetGarageSortGroupOptions,
} from "./reportParameters";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  ReportType: commissionReportTypes.SUMMARY,
  FixedDateRange: fixedDateRanges.LAST_MONTH,
  TransactionDate: ["", ""],
  IncludeFleetPolicies: true,
  IncludeGaragePolicies: true,
  SortGroupFields: [
    {
      Name: fleetGarageFields.AGENCY_NAME,
      SortAscending: true,
      ToGroup: false,
      NewPage: false,
    },
  ],
  DrillDownSortFields: [
    {
      Name: fleetGarageFields.AGENCY_NAME,
      SortAscending: true,
    },
  ],
  AgencyKeys: [], // by default select all agencies
};

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const specialLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 5 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 19 },
    xxl: { span: 19 },
  },
};

const FleetGarageCommissionReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, isSubmitted, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(commissionReportSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const isExporting = useSelector((state) => state.report.isExporting);
  const operationResult = useSelector((state) => state.report.operationResult);
  const isFetching = useSelector((state) => state.report.isFetching);
  const isViewerVisible = useSelector((state) => state.report.isViewerVisible);

  useEffect(() => {
    if (brokerId != null) {
      dispatch(
        listAgenciesCompactAction(
          brokerId,
          ViewCommissionsFullAccess,
          "ListAgenciesCompactForCustomCapriReport"
        )
      );
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var allAgenciesId = agencies.map((i) => i.Id);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKeys: allAgenciesId,
        TransactionDate: [
          toStartOfDay(dayjs().subtract(1, "month").startOf("month")),
          toEndOfDay(dayjs().subtract(1, "month").endOf("month")),
        ],
      };
      setInitialDefaultValues(cloneDeep(fullInitialValues));
      reset(fullInitialValues);
      trigger();

      // Reset default options for drilldown
      getExtraDisableDefaultOptions(allAgenciesId);
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const getExtraDisableDefaultOptions = (agencyKeys) => {
    let fieldValues = [];
    const fieldName =
      getValues().ReportType === commissionReportTypes.DETAIL
        ? "SortGroupFields"
        : "DrillDownSortFields";
    const fieldValue =
      getValues().ReportType === commissionReportTypes.DETAIL
        ? new SortGroupField()
        : new DrillDownSortField();
    if (
      (agencyKeys.length === 1 && agencyKeys[0] === 0) ||
      agencyKeys.length > 1
    ) {
      fieldValue.Name = fleetGarageFields.AGENCY_NAME;
      fieldValues = [fieldValue];
    }
    setValue(fieldName, fieldValues);
  };

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformFleetGarageCommissionInput(values),
        entityType.COMMISSION,
        `PrintFleetGarage${values.ReportType}`,
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="FleetGarageCommission">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <Form.Item
                        {...defaultLayout}
                        label="Report Type"
                        name="ReportType"
                        required
                      >
                        <Controller
                          control={control}
                          name="ReportType"
                          render={({ field }) => (
                            <Radio.Group {...field} name="ReportType">
                              {commissionReportTypeOptions}
                            </Radio.Group>
                          )}
                        />
                      </Form.Item>

                      <AgencyMultiSelectEx
                        layout={defaultLayout}
                        formLabel="Agency"
                        required={true}
                        agencies={currentAgencies}
                        isLoading={isEmpty(defaultValues.AgencyKeys)}
                        setValue={setValue}
                        control={control}
                        name="AgencyKeys"
                        onChange={(value) => {
                          var oldLength = getValues().AgencyKeys.length;
                          var newLength = value.length;
                          // We only need to update default options in some cases
                          if (
                            newLength != oldLength &&
                            ((oldLength < 2 && newLength > oldLength) ||
                              newLength < 2)
                          ) {
                            getExtraDisableDefaultOptions(value);
                          }
                        }}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                      <AdvancedRangePicker
                        formItem={{
                          labelCol: {
                            xs: { span: 5 },
                            sm: { span: 7 },
                            md: { span: 6 },
                            lg: { span: 8 },
                            xl: { span: 6 },
                            xxl: { span: 5 },
                          },
                          wrapperCol: {
                            xs: { span: 19 },
                            sm: { span: 17 },
                            md: { span: 18 },
                            lg: { span: 16 },
                            xl: { span: 18 },
                            xxl: { span: 19 },
                          },
                          required: true,
                          label: "Date",
                        }}
                        control={control}
                        setValue={setValue}
                        allowClear={true}
                        disableFutureDates={true}
                        name="TransactionDate"
                        format={dateFormat}
                        disabledDate={(
                          current // Should we allow users to select over a year?
                        ) => current && current > dayjs().endOf("day")}
                      />

                      <Form.Item
                        {...defaultLayout}
                        label="Include"
                        name="Include"
                        required
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Space direction="vertical">
                          <Controller
                            control={control}
                            name="IncludeFleetPolicies"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  setValue(
                                    "IncludeFleetPolicies",
                                    e.target.checked,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    }
                                  );
                                  if (
                                    !getValues().IncludeGaragePolicies &&
                                    !e.target.checked
                                  ) {
                                    setValue("IncludeGaragePolicies", true, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                }}
                                inputRef={field.ref}
                                name="IncludeFleetPolicies"
                              >
                                Fleet Policies
                              </Checkbox>
                            )}
                          />

                          <Controller
                            control={control}
                            name="IncludeGaragePolicies"
                            render={({ field }) => (
                              <Checkbox
                                inputRef={field.ref}
                                checked={field.value}
                                name="IncludeGaragePolicies"
                                onChange={(e) => {
                                  setValue(
                                    "IncludeGaragePolicies",
                                    e.target.checked,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    }
                                  );
                                  if (
                                    !getValues().IncludeFleetPolicies &&
                                    !e.target.checked
                                  ) {
                                    setValue("IncludeFleetPolicies", true, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                  }
                                }}
                              >
                                Garage policies
                              </Checkbox>
                            )}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {getValues().ReportType ===
                      commissionReportTypes.DETAIL ? (
                        <SortGroupFieldSelectEx
                          fieldOptions={fleetGarageSortGroupOptions}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      ) : (
                        <DrillDownSortFieldSelectEx
                          fieldOptions={fleetGarageDrillDownOptions}
                          control={control}
                          trigger={trigger}
                          isSubmitted={isSubmitted}
                        />
                      )}
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformFleetGarageCommissionInput(getValues()),
                              `FleetGarage${getValues().ReportType}Report`,
                              entityType.COMMISSION
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          const reportType = getValues().ReportType;
                          const resetValues = {
                            ...initialDefaultValues,
                            ReportType: reportType,
                          };
                          reset(resetValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default FleetGarageCommissionReport;
