import { PlusOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Button, Row, Col, Form, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { SearchableTable } from "../../shared/table/SearchableTable";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { tableNames } from "../../shared/table/tableNames";
import {
  PreservableStatePageWrapper,
  usePreservableNavigate,
} from "../../shared/PreservableStatePageWrapper";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import {
  listCommissionRates,
  clearOperationResultAction,
} from "../../../store/commission/commissionActions";
import UpdateCommissionRate from "./UpdateCommissionRate";
import CreateCommissionRate from "./CreateCommissionRate";
import { exportListData } from "../../../store/report/reportActions";
import entityType from "../../../api/entityType";
import getCommissionRateColumns from "./getCommissionRateColumns";
import { useForm } from "react-hook-form";
import AgencyMultiSelectEx from "../../shared/form/AgencyMultiSelectEx";
import { isEmpty } from "lodash";
import {
  listAgenciesCompactAction,
  clearListCompactAction,
} from "../../../store/agency/agencyActions";
import { AdminTasks } from "../../shared/securityRoleTasks";
import { yupResolver } from "@hookform/resolvers/yup";
import { commissionRatesSchema } from "./ratesSchema";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import { agenciesListEqual } from "../../shared/sorting";
import { exportDataToExcel } from "../../shared/exportToExcel";

const defaultLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const CommissionRates = () => {
  return (
    <PreservableStatePageWrapper
      tableNames={[tableNames.CommissionRates]}
      onCleanup={() => {}}
    >
      <CommissionRatesBody />
    </PreservableStatePageWrapper>
  );
};

const CommissionRatesBody = () => {
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const data = useSelector((state) => state.commission.data);
  const loading = useSelector((state) => state.commission.loading);
  const operationResult = useSelector(
    (state) => state.commission.operationResult
  );
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);

  const dispatch = useDispatch();
  const preservableNavigate = usePreservableNavigate();

  const {
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(commissionRatesSchema),
  });

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, AdminTasks));
    }
  }, [brokerId]);

  useEffect(() => {
    return () => {
      // Clear the list of all agencies
      dispatch(clearListCompactAction());
    };
  }, []);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult]);

  const [currentAgencies, setCurrentAgencies] = useState([]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);

      var initialAgency = getInitialAgency(homeAgency, agencies);
      initialAgency &&
        dispatch(listCommissionRates({ AgencyKeys: [initialAgency] }));

      const initialValues = {
        AgencyKeys: [initialAgency],
      };
      reset(initialValues);

      trigger();
    }
  }, [agencies, homeAgency]);

  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.CommissionRates)
  );

  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const [isExporting, setIsExporting] = useState(false);

  const exportTableData = () => {
    setIsExporting(true);
    exportDataToExcel(
      tableData?.extra.currentDataSource,
      [
        "Id",
        "ProducerCommRateKey",
        "ProducerCode1Key",
        "ProducerCode2Key",
        "Agency1Key",
        "Agency2Key",
        "Agency1Name",
        "Agency2Name",
      ],
      maskSensitiveData,
      "CommissionRates.csv"
    );
    setIsExporting(false);
  };

  const addButton = (
    <Button
      size={"small"}
      type="primary"
      onClick={() => {
        preservableNavigate(`createcommissionrate`);
      }}
    >
      <PlusOutlined />
      Add New
    </Button>
  );

  const exportButton = (
    <Button
      size={"small"}
      loading={isExporting}
      onClick={() => exportTableData()}
    >
      <FileExcelOutlined />
      Export
    </Button>
  );

  const toolbarButtons = [addButton, exportButton];

  return (
    <PermissionWrapper entityName="CommissionRates">
      <Routes>
        <Route
          path=""
          element={
            <>
              <Row>
                <Col span={24}>
                  <Form size="small" autoComplete="off">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                        <AgencyMultiSelectEx
                          layout={defaultLayout}
                          formLabel="Agency"
                          agencies={currentAgencies}
                          isLoading={isEmpty(agencies)}
                          setValue={setValue}
                          control={control}
                          name="AgencyKeys"
                          required={true}
                          onChange={(newAgencyKeys) => {
                            dispatch(
                              listCommissionRates({ AgencyKeys: newAgencyKeys })
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0 }} />
              <Row>
                <Col span={24}>
                  <SearchableTable
                    dataSource={data}
                    columns={getCommissionRateColumns(getValues())}
                    loading={loading}
                    buttons={toolbarButtons}
                    rowKey={(r) => r.Id}
                    tableName={tableNames.CommissionRates}
                    pageSize={100}
                    size="small"
                    scroll={{ y: 650, x: 935 }}
                  />
                </Col>
              </Row>
            </>
          }
        />

        <Route
          path="createcommissionrate"
          element={
            <CreateCommissionRate
              parentRefreshHandler={() =>
                dispatch(listCommissionRates(getValues()))
              }
            />
          }
        />

        <Route
          path="updatecommissionrate/:key"
          element={
            <UpdateCommissionRate
              parentRefreshHandler={() =>
                dispatch(listCommissionRates(getValues()))
              }
            />
          }
        />
      </Routes>
    </PermissionWrapper>
  );
};
export default CommissionRates;
