export const tplLimit = [
  { value: 0, label: "No coverage" },
  { value: 200000, label: "$ 200,000" },
  { value: 300000, label: "$ 300,000" },
  { value: 500000, label: "$ 500,000" },
  { value: 1000000, label: "$ 1,000,000" },
  { value: 2000000, label: "$ 2,000,000" },
  { value: 3000000, label: "$ 3,000,000" },
  { value: 5000000, label: "$ 5,000,000" },
  { value: 10000000, label: "$ 10,000,000" },
];

export const extensionUmpLimit = [
  { value: 0, label: "No coverage" },
  { value: 10000000, label: "$ 1,000,000" },
  { value: 20000000, label: "$ 2,000,000" },
  { value: 30000000, label: "$ 3,000,000" },
  { value: 40000000, label: "$ 4,000,000" },
  { value: 50000000, label: "$ 5,000,000" },
];

export const louLimit = [
  { value: 0, label: "No coverage" },
  { value: 300, label: "$ 300" },
  { value: 400, label: "$ 400" },
  { value: 500, label: "$ 500" },
  { value: 600, label: "$ 600" },
  { value: 700, label: "$ 700" },
  { value: 800, label: "$ 800" },
  { value: 900, label: "$ 900" },
  { value: 1000, label: "$ 1,000" },
  { value: 1500, label: "$ 1,500" },
  { value: 2000, label: "$ 2,000" },
  { value: 3000, label: "$ 3,000" },
  { value: 4000, label: "$ 4,000" },
  { value: 5000, label: "$ 5,000" },
  { value: 6000, label: "$ 6,000" },
  { value: 7000, label: "$ 7,000" },
  { value: 8000, label: "$ 8,000" },
  { value: 9000, label: "$ 9,000" },
];

export const incomeTupLimit = [
  { value: 0, label: "No coverage" },
  { value: 10000, label: "$ 10,000" },
  { value: 20000, label: "$ 20,000" },
  { value: 30000, label: "$ 30,000" },
  { value: 40000, label: "$ 40,000" },
  { value: 50000, label: "$ 50,000" },
  { value: 60000, label: "$ 60,000" },
  { value: 70000, label: "$ 70,000" },
  { value: 80000, label: "$ 80,000" },
  { value: 90000, label: "$ 90,000" },
  { value: 100000, label: "$ 100,000" },
];

export const hitAndRunDeductible = [
  { value: 0, label: "No coverage" },
  { value: 750, label: "$ 750" },
];

export const offHighwayTplLimit = [
  { value: 0, label: "No coverage" },
  { value: 200000, label: "$ 200,000" },
];