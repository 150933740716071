export const Actions = {
  CLEAR_DATA_DOWNLOAD_LOG: "CLEAR_DATA_DOWNLOAD_LOG",
  LIST_DATA_DOWNLOAD_LOG: "LIST_DATA_DOWNLOAD_LOG",
  LIST_DATA_DOWNLOAD_LOG_SUCCEEDED: "LIST_DATA_DOWNLOAD_LOG_SUCCEEDED",
  GET_DATA_DOWNLOAD_DETAILS: "GET_DATA_DOWNLOAD_DETAILS",
  GET_DATA_DOWNLOAD_DETAILS_SUCCEEDED: "GET_DATA_DOWNLOAD_DETAILS_SUCCEEDED",
  CLEAR_DATA_DOWNLOAD_DETAILS: "CLEAR_DATA_DOWNLOAD_DETAILS",
  GET_LATEST_DATE_RANGES: "GET_LATEST_DATE_RANGES",
  GET_LATEST_DATE_RANGES_SUCCEEDED: "GET_LATEST_DATE_RANGES_SUCCEEDED",
  SUBMIT_DOWNLOAD_REQUEST: "SUBMIT_DOWNLOAD_REQUEST",
  SUBMIT_DOWNLOAD_REQUEST_SUCCEEDED: "SUBMIT_DOWNLOAD_REQUEST_SUCCEEDED",
  CLEAR_OPERATION_RESULT: "CLEAR_OPERATION_RESULT",
  CLEAR_DOWNLOAD_REQUEST_TAB: "CLEAR_DOWNLOAD_REQUEST_TAB",
};
