import { SaveOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
} from "antd";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { shortInputStyle } from "../../shared/form/formInputStyles";
import { tailLayout } from "../../shared/form/formLayout";
import CustomCheckbox from "../../shared/form/reactHookForm/CustomCheckbox";
import CustomInput from "../../shared/form/reactHookForm/CustomInput";
import { onlineRenewalsBulkUpdateSchema } from "./onlineRenewalsSchemas";
import {
  bulkUpdateOnlineRenewalsCodes,
  clearBulkUpdateData,
  clearOperationResultAction,
} from "../../../store/onlineRenewals/onlineRenewalsActions";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";

// Constants
const initialValues = {
  ProducerCode1: "",
  ProducerCode2: "",
  UpdatePC1: false,
  UpdatePC2: false,
};

const OnlineRenewalsBulkUpdateForm = ({ onCloseHandler }) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(onlineRenewalsBulkUpdateSchema),
  });

  //
  const UpdatePC1 = watch("UpdatePC1");
  const UpdatePC2 = watch("UpdatePC2");
  const ProducerCode1 = watch("ProducerCode1");
  const ProducerCode2 = watch("ProducerCode2");

  //
  const dispatch = useDispatch();

  //
  const loading = useSelector(
    (state) => state.onlineRenewals.bulkUpdate.loading
  );
  const operationResult = useSelector(
    (state) => state.onlineRenewals.bulkUpdate.operationResult
  );

  //
  useEffect(() => {
    return () => {
      dispatch(clearBulkUpdateData());
    };
  }, []);

  useEffect(() => {
    if (operationResult != null) {
      openNotificationOperationResult(operationResult, () =>
        dispatch(clearOperationResultAction())
      );
      if (operationResult.Outcome !== "Success") {
        reset({ ...initialValues });
        trigger();
      } else {
        onCloseHandler(true);
      }
    }
  }, [operationResult, dispatch]);

  //
  const onSubmitHandler = (values) => {
    dispatch(
      bulkUpdateOnlineRenewalsCodes({
        ProducerCode1: values.ProducerCode1 ?? "",
        ProducerCode2: values.ProducerCode2 ?? "",
        UpdatePC1: values.UpdatePC1,
        UpdatePC2: values.UpdatePC2,
      })
    );
  };

  return (
    <Form
      size="small"
      autoComplete="off"
      onFinish={() => handleSubmit(onSubmitHandler)()}
      colon={false}
      style={{ marginTop: 16 }}
    >
      <Row justify="center">
        <Col>
          <Form.Item
            label={
              <CustomCheckbox
                control={control}
                hasFeedback={false}
                required={false}
                formLabel=""
                name="UpdatePC1"
              >
                Update Producer Code 1 to:
              </CustomCheckbox>
            }
            name="UpdatePC1Parent"
            style={{ marginBottom: 0 }}
            className="change-form-colon-padding"
          >
            <div>
              <CustomInput
                formLabel=""
                control={control}
                name="ProducerCode1"
                maxLength={3}
                disabled={!UpdatePC1}
                style={shortInputStyle}
                hasFeedback={false}
                required={false}
                autoComplete="off"
              />{" "}
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Form.Item
            label={
              <CustomCheckbox
                control={control}
                hasFeedback={false}
                required={false}
                formLabel=""
                name="UpdatePC2"
              >
                Update Producer Code 2 to:
              </CustomCheckbox>
            }
            name="UpdatePC2Parent"
            className="change-form-colon-padding"
            style={{ marginBottom: 0 }}
          >
            <div>
              <CustomInput
                formLabel=""
                control={control}
                name="ProducerCode2"
                maxLength={3}
                disabled={!UpdatePC2}
                style={shortInputStyle}
                hasFeedback={false}
                required={false}
                autoComplete="off"
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 16, marginTop: 0 }} />
      <Row justify="center">
        <Col style={{ paddingRight: "8px" }}>
          {isDirty &&
          isValid &&
          !loading &&
          ((UpdatePC1 && ProducerCode1 == "") ||
            (UpdatePC2 && ProducerCode2 == "")) ? (
            <Popconfirm
              title={
                <>
                  {UpdatePC1 &&
                  ProducerCode1 == "" &&
                  UpdatePC2 &&
                  ProducerCode2 == ""
                    ? "Producer Codes 1 and 2"
                    : UpdatePC1 && ProducerCode1 == ""
                    ? "Producer Code 1"
                    : "Producer Code 2"}{" "}
                  will be set to blank. Continue?
                </>
              }
              onConfirm={() => handleSubmit(onSubmitHandler)()}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                type="primary"
                loading={loading}
                disabled={
                  !isDirty || !isValid || loading || (!UpdatePC1 && !UpdatePC2)
                }
              >
                <SaveOutlined />
                Save
              </Button>
            </Popconfirm>
          ) : (
            <Button
              size="small"
              type="primary"
              loading={loading}
              disabled={
                !isDirty || !isValid || loading || (!UpdatePC1 && !UpdatePC2)
              }
              id="processButton"
              htmlType="submit"
            >
              <SaveOutlined />
              Save
            </Button>
          )}
        </Col>
        <Col style={{ paddingRight: "8px" }}>
          <Button
            size="small"
            type="dashed"
            disabled={!isDirty || loading}
            onClick={() => {
              reset({ ...initialValues });
              trigger();
            }}
          >
            Reset Values
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={() => onCloseHandler(false)}
            type="dashed"
          >
            Close
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OnlineRenewalsBulkUpdateForm;
