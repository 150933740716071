export const newICBCShipment = {
  label: "New ICBC Shipment",
  value: "newICBCShipment",
};

export const pendingICBCShipment = {
  emptyLabel: "Empty - No Draft ICBC Shipments",
  label: "Previously Started ICBC Shipment",
  value: "pendingICBCShipment",
};

export const pendingBrokerShipment = {
  emptyLabel: "Empty - No Shipments From Other Brokers",
  label: "New Shipment From Another Broker",
  value: "pendingBrokerShipment",
};
