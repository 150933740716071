import { Form, Select } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useController, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { checkHookFormFieldPropsEquality } from "./formValidatonRules";
import InfoForMultiSelect from "./InfoForMultiSelect";

const onMultiSelectHandler = (value, list, fieldName, onSelect, setValue) => {
  let newValue = value;
  if (value === 0) {
    newValue = list
      ? list.map((i) => {
          return i.Id;
        })
      : [];
    setValue(fieldName, newValue);
  }
  onSelect && onSelect(newValue);
};

const AgencyMultiSelectEx = (props) => {
  const { agencies, isLoading } = props;

  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  const propToWatch = useWatch({ control: props.control, name: props.name });

  const agenciesLoading = useSelector((state) => state.agency.loading);
  const onSelectHandler = (value) => {
    onMultiSelectHandler(
      value,
      agencies,
      props.name,
      props.onSelect,
      props.setValue
    );
  };

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
      isLoading={isLoading}
      agenciesLoading={agenciesLoading}
      agencies={agencies}
      onSelectHandler={onSelectHandler}
      propToWatch={propToWatch}
    >
      {props.children}
    </Content>
  );
};

const Content = React.memo(
  (props) => {
    const { field, error, agencies, isLoading, agenciesLoading } = props;

    var inputProps = { ...props };
    delete inputProps.control;
    delete inputProps.formLabel;
    delete inputProps.layout;
    delete inputProps.required;
    delete inputProps.isTouched;
    delete inputProps.isDirty;
    delete inputProps.error;
    delete inputProps.field;
    delete inputProps.children;
    delete inputProps.formStyle;
    delete inputProps.autoFocus;
    delete inputProps.agenciesLoading;
    delete inputProps.isLoading;
    delete inputProps.agencies;
    delete inputProps.onSelectHandler;
    delete inputProps.propToWatch;
    delete inputProps.setValue;

    const agencyOptions = agencies
      ? agencies.map((a) => {
          return { label: a.FullName, value: a.Id };
        })
      : [];

    if (agencies && agencies.length > 1) {
      agencyOptions.unshift({
        label: `(Select all ${agencies.length} agencies)`,
        value: 0,
      });
    }

    return (
      <Form.Item
        {...props.layout}
        label={props.formLabel}
        name={props.name}
        validateStatus={error != null ? "error" : ""}
        help={error != null ? error.message : null}
        required={props.required}
        autoFocus={props.autoFocus}
        style={props.formStyle}
      >
        <div>
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Select
              {...field}
              {...inputProps}
              name={props.name}
              onChange={(v, o) => {
                if (props.onChange != null) {
                  var newAgencies = v;
                  if (
                    v != null &&
                    v.length > 0 &&
                    (v[v.length - 1] === 0 || v.length > agencies.length)
                  ) {
                    newAgencies = agencies.map((i) => {
                      return i.Id;
                    });
                  }
                  props.onChange(newAgencies);
                }
                field.onChange(v, o);
              }}
              options={agencyOptions}
              loading={agenciesLoading || isLoading || isEmpty(agencies)}
              onSelect={props.onSelectHandler}
              maxTagCount={4}
              allowClear={true}
              mode="multiple"
              showSearch
              virtual={false}
              optionFilterProp="label"
              filterOption={(input, option) => {
                const label = option.label ? option.label : option.children;
                return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
          </span>
          <InfoForMultiSelect
            data={agencies}
            currentValue={props.propToWatch}
          />
        </div>
      </Form.Item>
    );
  },
  (prevProps, nextProps) =>
    checkHookFormFieldPropsEquality(prevProps, nextProps) &&
    prevProps.agenciesLoading === nextProps.agenciesLoading &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.agencies.length === nextProps.agencies.length &&
    prevProps.propToWatch === nextProps.propToWatch
);

export default AgencyMultiSelectEx;
