import React from "react";
import { Route, Routes } from "react-router";
import Agencies from "../agency/Agencies";
import Announcements from "../announcement/Announcements";
import AppLogs from "../appLogs/AppLogs";
import CreateBatchForm from "../batching/form/CreateBatchForm";
import Batches from "../batching/table/Batches";
import FleetGarageCommissionReport from "../commission//reports/FleetGarageCommissionReport";
import CommissionRates from "../commission/rates/CommissionRates";
import CommissionReport from "../commission/reports/CommissionReport";
import CoverageSummaryReport from "../coverage/CoverageSummaryReport";
import DataCollectionReport from "../dataCollection/DataCollectionReport";
import DealerBillingReport from "../dealerBilling/DealerBillingReport";
import Downloads from "../document/downloads/Downloads";
import ManualDocument from "../document/form/ManualDocument";
import MoveDocuments from "../document/move/MoveDocuments";
import OnlineRenewals from "../document/renewals/OnlineRenewals";
import Documents from "../document/table/Documents";
import ConfirmationOfInsuranceReport from "../fleet/ConfirmationOfInsuranceReport";
import ScheduleOfVehiclesReport from "../fleet/ScheduleOfVehiclesReport";
import FleetPolicyToClientMap from "../fleetPolicyCustomMapping/FleetPolicyToClientMap";
import AllocateInventorySteps from "../inventory/allocate/AllocateInventorySteps";
import MaintainInventory from "../inventory/maintain/MaintainInventory";
import ReceiveInventorySteps from "../inventory/receive/ReceiveInventorySteps";
import InventoryAuditReport from "../inventory/reports/InventoryAuditReport";
import InventoryLogReport from "../inventory/reports/InventoryLogReport";
import OnHandInventoryReport from "../inventory/reports/OnHandInventoryReport";
import RoadAgentDailyStock from "../inventory/reports/RoadAgentDailyStock";
import ReturnToICBCSteps from "../inventory/returnToICBC/ReturnToICBCSteps";
import InventoryItems from "../inventory/searchInventory/InventoryItems";
import Movement from "../inventory/searchMovements/Movement";
import Movements from "../inventory/searchMovements/Movements";
import TransferInventorySteps from "../inventory/transfer/TransferInventorySteps";
import Producers from "../producer/Producers";
import ProductionReport from "../production/ProductionReport";
import ProductionByDayHourReport from "../productionByDayHour/ProductionByDayHourReport";
import BcarRetentionReport from "../retention/BcarRetentionReport";
import RenewalListReport from "../retention/RenewalListReport";
import RetentionSummaryReport from "../retention/RetentionSummaryReport";
import TextSummaryReport from "../retention/TextSummaryReport";
import ManageRenewals from "../retention/manage/ManageRenewals";
import LegacyUsers from "../user/LegacyUsers";
import Users from "../user/Users";
import Home from "./Home";
import { Logout } from "./Logout";
import NotFoundPage from "./NotFoundPage";
import { CustomOidcSecure } from "./oidc/CustomOidcSecure";
import LoanCalculator from "../loanCalculator/LoanCalculator";

const EmptyRoute = () => {
  window.location.replace(`${process.env.REACT_APP_URI}/home`);
  return null;
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={<EmptyRoute />} />
      <Route element={<CustomOidcSecure />}>
        {/* Temporary fix, we need to add a rewriting rule to the web.config file for IIS */}
        <Route path="/home" element={<Home />} />
        <Route path="/documents/*" element={<Documents />} />
        <Route path="/batches/*" element={<Batches />} />
        <Route path="/users/*" element={<Users />} />
        <Route path="/legacyUsers" element={<LegacyUsers />} />
        <Route path="/agencies/*" element={<Agencies />} />
        <Route path="/manualdocument/*" element={<ManualDocument />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/onlinerenewals" element={<OnlineRenewals />} />
        <Route path="/movedocuments" element={<MoveDocuments />} />
        <Route path="/loancalculator" element={<LoanCalculator />} />
        <Route path="/managerenewals" element={<ManageRenewals />} />
        <Route path="/createBatchForm" element={<CreateBatchForm />} />
        <Route path="/inventoryItems/*" element={<InventoryItems />} />
        <Route
          path="/receiveInventorySteps"
          element={<ReceiveInventorySteps />}
        />
        <Route
          path="/transferInventorySteps"
          element={<TransferInventorySteps />}
        />
        <Route path="/returnToICBCSteps" element={<ReturnToICBCSteps />} />
        <Route
          path="/allocateInventorySteps"
          element={<AllocateInventorySteps />}
        />
        <Route path="/movements" element={<Movements />} />
        <Route
          path="/inventoryAuditReport"
          element={<InventoryAuditReport />}
        />
        <Route
          path="/onHandInventoryReport"
          element={<OnHandInventoryReport />}
        />
        <Route path="/inventoryLogReport" element={<InventoryLogReport />} />
        <Route path="/maintainInventory" element={<MaintainInventory />} />
        <Route path="/movement/:movementKey" element={<Movement />} />
        <Route path="/commissionReport" element={<CommissionReport />} />
        <Route path="/roadAgentDailyStock" element={<RoadAgentDailyStock />} />
        <Route path="/productionReport" element={<ProductionReport />} />
        <Route
          path="/productionByDayHourReport"
          element={<ProductionByDayHourReport />}
        />
        <Route path="/dealerBillingReport" element={<DealerBillingReport />} />
        <Route
          path="/coverageSummaryReport"
          element={<CoverageSummaryReport />}
        />
        <Route
          path="/fleetGarageCommissionReport"
          element={<FleetGarageCommissionReport />}
        />
        <Route
          path="/dataCollectionReport"
          element={<DataCollectionReport />}
        />
        <Route path="/textSummaryReport" element={<TextSummaryReport />} />
        <Route path="/bcarRetentionReport" element={<BcarRetentionReport />} />
        <Route
          path="/retentionSummaryReport"
          element={<RetentionSummaryReport />}
        />
        <Route path="/renewalListReport" element={<RenewalListReport />} />
        <Route
          path="/ScheduleOfVehiclesReport"
          element={<ScheduleOfVehiclesReport />}
        />
        <Route
          path="/ConfirmationOfInsuranceReport"
          element={<ConfirmationOfInsuranceReport />}
        />
        <Route
          path="/fleetPolicyToClientMap"
          element={<FleetPolicyToClientMap />}
        />
        <Route path="/producers/*" element={<Producers />} />
        <Route path="/announcements/*" element={<Announcements />} />
        <Route path="/appLogs/*" element={<AppLogs />} />
        <Route path="/commissionrates/*" element={<CommissionRates />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
