import { Col, Collapse, Row } from "antd";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setExpandSectionsAction } from "../../../../store/document/documentActions";
import NoExpiryDocumentSummary from "../NoExpiryDocumentSummary";
import DebtDocumentPanel from "./DebtDocumentPanel";
import DebtPaymentPanel from "./DebtPaymentPanel";

const { Panel } = Collapse;

const DebtTransactionView = ({ isNew }) => {
  const { control } = useFormContext();
  const dispatch = useDispatch();
  const [activeKeys, setActiveKeys] = useState(["1", "2", "3"]);

  const expandSections = useSelector(
    (state) => state.document.selectedUi.expandSections
  );

  useEffect(() => {
    if (!isNull(expandSections)) {
      dispatch(setExpandSectionsAction(null));
      setActiveKeys(expandSections ? ["1", "2", "3"] : []);
    }
  }, [expandSections]);

  const onActiveKeyChangeHandler = (keys) => {
    setActiveKeys(keys);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Collapse
            activeKey={activeKeys}
            onChange={onActiveKeyChangeHandler}
            type="card"
            size="small"
          >
            <Panel header="Summary" key={1}>
              <NoExpiryDocumentSummary isNew={isNew} />
            </Panel>
            <Panel header="Document" key={2}>
              <DebtDocumentPanel control={control} isNew={isNew} />
            </Panel>

            <Panel header="Payment" key={3}>
              <DebtPaymentPanel control={control} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default DebtTransactionView;
