import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProducerCode } from "../../store/producer/producerActions";
import { PreservableStateLink } from "../shared/PreservableStatePageWrapper";
import { columnTypes } from "../shared/table/columnTypes";

const { Paragraph } = Typography;

const getProducerCodeColumns = (values) => {
  const dispatch = useDispatch();
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  return [
    {
      title: "Code #",
      dataIndex: "ProducerNumber",
      type: columnTypes.TEXT,
      width: 65,
    },
    {
      title: "Code",
      dataIndex: "ProducerCode",
      type: columnTypes.TEXT,
      render: (value, record) => (
        <PreservableStateLink to={`updateproducercode/${record.Id}`}>
          {value}
        </PreservableStateLink>
      ),
      width: 75,
    },
    {
      title: "Description",
      dataIndex: "Description",
      width: 180,
      type: columnTypes.TEXT,
      render: (text) => (
        <div className={maskSensitiveData === true ? "blurry-text" : ""}>
          {text}
        </div>
      ),
    },
    {
      title: "Linked to User",
      dataIndex: "IsLinkedToUser",
      type: columnTypes.BOOLEAN,
      width: 90,
    },

    {
      title: "Applies in all Agencies",
      dataIndex: "AppliesInAllAgencies",
      type: columnTypes.BOOLEAN,
      width: 100,
    },

    {
      title: "Home Agency",
      dataIndex: "AgencyNumberAndName",
      type: columnTypes.TEXT,
      width: 270,
    },
    {
      type: columnTypes.ACTION,
      render: (record) => (
        <Popconfirm
          title={
            <>
              <Paragraph>
                This will delete the producer code and any commission rate
                associated with it.
              </Paragraph>
              <Paragraph style={{ marginBottom: 0 }}>
                Are you sure you want to proceed?
              </Paragraph>
            </>
          }
          onConfirm={() =>
            dispatch(
              deleteProducerCode({ ProducerCodeKey: record.Id, ...values })
            )
          }
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" size="small" style={{ padding: 0 }}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      fixed: "right",
      width: 36,
    },
  ];
};

export default getProducerCodeColumns;
