import { SaveTwoTone, StopTwoTone } from "@ant-design/icons";
import { columnTypes } from "../columnTypes";
import { EditableTableCell } from "./EditableTableCell";
import { Tooltip } from "antd";

export const transformSearchableColumns = ({
  searchableColumns,
  control,
  isEditing,
  onCancel,
  onSubmit,
  resetValuesOnRowEdit,
  reset,
  setFocusedRow,
  setIsEditing,
}) => {
  return searchableColumns.map((col) => {
    if (col.type == columnTypes.ACTION) {
      // Make the action column into Save/Cancel
      return {
        ...col,
        width: 60,
        render: (record) => {
          if (isEditing === record.DocumentKey) {
            return (
              <span>
                <Tooltip title="Save">
                  <SaveTwoTone
                    id="inlineEditButton_Save"
                    onClick={onSubmit}
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
                <Tooltip title="Cancel">
                  <StopTwoTone
                    id="inlineEditButton_Cancel"
                    onClick={() => {
                      onCancel();
                    }}
                    twoToneColor="#ff7875"
                  />
                </Tooltip>
              </span>
            );
          }
          return col.render(record);
        },
      };
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      render: (text, record, index) => {
        return (
          <EditableTableCell
            record={record}
            rowIndex={index}
            inputProps={col.inputProps}
            isEditing={isEditing}
            setEditingRow={() => {
              if (resetValuesOnRowEdit != null) {
                const initialValues = resetValuesOnRowEdit(record);
                reset(initialValues);
              }

              setIsEditing(record.DocumentKey);
              setFocusedRow(
                col.inputProps.name + "_" + record.DocumentKey.toString()
              );
            }}
            editable={col.editable(record)}
            control={control}
            onPressEnter={onSubmit}
            onPressEscape={onCancel}
          >
            {text}
          </EditableTableCell>
        );
      },
    };
  });
};
