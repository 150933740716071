import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createProducerCode,
  clearSelectedProducerCode,
  setSaveAndAddFlag,
  setCopyAddInitialValues,
} from "../../store/producer/producerActions";
import ProducerCodeForm from "./ProducerCodeForm";
import { Spin } from "antd";

const CreateProducerCode = ({ parentRefreshHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const homeAgencyKey = useSelector(
    (state) => state.actor.details.data.AgencyKey
  );

  const selectedCodeNumber = useSelector(
    (state) => state.producer.selectedCodeNumber
  );

  const initialValues = {
    ProducerNumber: selectedCodeNumber === 0 ? 1 : selectedCodeNumber,
    ProducerCode: "",
    AgencyKey: homeAgencyKey,
    AppliesInAllAgencies: false,
    IsLinkedToUser: false,
    UserKey: null,
    Description: "",
    EPICCode: "",
  };

  const code = useSelector((state) => state.producer.selected);
  const saveAndAddFlag = useSelector((state) => state.producer.saveAndAddFlag);
  const loading = useSelector((state) => state.producer.selectedLoading);

  const onSubmitHandler = (values) => {
    dispatch(createProducerCode(values));
  };

  useEffect(() => {
    if (code && !saveAndAddFlag) {
      navigate(`../updateproducercode/${code.Id}`, {
        state: location.state,
        replace: true,
      });
    } else if (code && saveAndAddFlag) {
      const copyAddInitialValues = {
        ProducerNumber: code.ProducerNumber,
        ProducerCode: "",
        AgencyKey: code.AgencyKey,
        AppliesInAllAgencies: code.AppliesInAllAgencies,
        IsLinkedToUser: code.IsLinkedToUser,
        UserKey: null,
        Description: "",
        EPICCode: "",
      };
      dispatch(setCopyAddInitialValues(copyAddInitialValues));
      dispatch(clearSelectedProducerCode());
      dispatch(setSaveAndAddFlag(false));
    }
  }, [code, saveAndAddFlag, navigate]);

  return (
    <Spin spinning={loading} delay={500}>
      <ProducerCodeForm
        onSubmitHandler={onSubmitHandler}
        initialValues={initialValues}
        handleParentRefresh={parentRefreshHandler}
        isNew={true}
      />
    </Spin>
  );
};

export default CreateProducerCode;
