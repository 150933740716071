import Actions from "./userConstants";
import produce from "immer";

const initialState = {
  list: [],
  identityResult: null,
  operationResult: null,
  selected: null,
  loading: false,
  isDeleted: null,
  isDeleteLoading: false,
  bulkDeleteOperationResult: null,
  isBulkDeleting: false,
  legacy: {
    list: [],
    selected: null,
    loading: false,
    operationResult: null,
    selectedLoading: false,
  },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_USERS:
      case Actions.CREATE_USER:
      case Actions.UPDATE_USER:
      case Actions.READ_USER:
      case Actions.UNLOCK_USER:
        draft.loading = true;
        return draft;

      case Actions.LIST_USERS_SUCCEEDED:
        draft.loading = false;
        draft.list = action.payload.Data;
        return draft;

      case Actions.LIST_LEGACY_USERS:
        draft.legacy.loading = true;
        return draft;

      case Actions.DELETE_USER:
        draft.isDeleteLoading = true;
        return draft;
      case Actions.BULK_DELETE_USERS:
        draft.isBulkDeleting = true;
        return draft;

      case Actions.LIST_LEGACY_USERS_SUCCEEDED:
        draft.legacy.loading = false;
        draft.legacy.list = action.payload.Data;
        return draft;

      case Actions.UNLOCK_USER_SUCCEEDED:
        draft.identityResult = action.payload.Operation;
        draft.loading = false;
        return draft;

      case Actions.UPDATE_USER_SUCCEEDED:
      case Actions.CREATE_USER_SUCCEEDED:
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        }
        draft.operationResult = action.payload.Operation;
        draft.loading = false;
        return draft;

      case Actions.READ_USER_SUCCEEDED:
        draft.selected = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.loading = false;
        return draft;

      case Actions.DELETE_USER_SUCCEEDED:
        draft.isDeleteLoading = false;
        draft.operationResult = action.payload.Operation;
        draft.isDeleted = action.payload.Operation.Outcome == "Success";
        return draft;

      case Actions.BULK_DELETE_USERS_SUCCEEDED:
        draft.isBulkDeleting = false;
        draft.bulkDeleteOperationResult = action.payload.Operation;
        return draft;

      case Actions.CLEAR_BULK_DELETE_OPERATION_RESULT:
        draft.bulkDeleteOperationResult = null;
        draft.isBulkDeleting = false;
        return draft;

      case Actions.ACITVATE_LEGACY_USER:
        draft.legacy.loading = true;
        return draft;

      case Actions.ACITVATE_LEGACY_USER_SUCCEEDED:
        draft.legacy.loading = false;
        draft.legacy.operationResult = action.payload.Operation;
        return draft;

      case Actions.CLEAR_IDENTITY_OPERATION_RESULT:
        draft.identityResult = null;
        draft.loading = false;
        return draft;

      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        draft.isDeleted = null;
        return draft;

      case Actions.CLEAR_LEGACY_OPERATION_RESULT:
        draft.legacy.operationResult = null;
        return draft;

      case Actions.CLEAR_SELECTED_USER:
        draft.operationResult = null;
        draft.selected = null;
        draft.isDeleteLoading = false;
        draft.isDeleted = null;
        draft.isBulkDeleting = false;
        return draft;

      default:
        return state;
    }
  });
};
