import * as yup from "yup";
import { requiredMessage } from "../../shared/form/formValidatonRules";

const searchFormSchema = yup.object().shape({
  SearchValue: yup
    .string()
    .required(requiredMessage)
    .min(2, "Shorter than 2 characters")
    .max(60, "Longer than 60 characters"),
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  Column: yup.string().required(requiredMessage),
});

export default searchFormSchema;
