import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormState,
  useWatch,
} from "react-hook-form";
import { DrillDownSortField } from "./DrillDownSortField";
import { disableSelectOptions } from "./selectHelpers";

const DrillDownSortFieldSelectEx = ({
  fieldOptions,
  extraDisableOptions,
  control,
  trigger,
  isSubmitted,
}) => {
  const { errors } = useFormState({ control });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "DrillDownSortFields",
  });

  const drillDownSortFields = useWatch({
    control,
    name: "DrillDownSortFields",
  });

  const prevIsSubmittedRef = useRef(false);

  useEffect(() => {
    if (!isSubmitted && prevIsSubmittedRef.current) {
      drillDownSortFields.map((item, index) => {
        update(index, new DrillDownSortField(item.Name, item.SortAscending));
      });
    }
    prevIsSubmittedRef.current = isSubmitted;
  }, [isSubmitted]);

  return (
    <>
      {!isEmpty(drillDownSortFields) && (
        <Divider orientation="left" plain style={{ marginTop: 0 }}>
          Drill Down
        </Divider>
      )}

      <div>
        {fields.map((item, index) => (
          <div key={item.id}>
            <Form.Item
              labelCol={{
                xs: { span: 4 },
                sm: { span: 4 },
                md: { span: 3 },
                lg: { span: 3 },
                xl: { span: 2 },
                xxl: { span: 2 },
              }}
              wrapperCol={{
                xs: { span: 20 },
                sm: { span: 20 },
                md: { span: 21 },
                lg: { span: 21 },
                xl: { span: 22 },
                xxl: { span: 22 },
              }}
              label={index === 0 ? "By" : "Then By"}
              name={`DrillDownSortFields[${index}].Name`}
              style={{ marginBottom: 4 }}
              required
            >
              <Row>
                <Col xs={24} sm={18} md={8} lg={8} xl={7} xxl={6}>
                  <Form.Item
                    name={`${index}`}
                    style={{
                      width: "97%",
                      marginBottom: 0,
                    }}
                    validateStatus={
                      errors != null &&
                      errors.DrillDownSortFields != null &&
                      errors.DrillDownSortFields[index] != null &&
                      errors.DrillDownSortFields[index].Name != null
                        ? "error"
                        : ""
                    }
                    help={
                      errors != null &&
                      errors.DrillDownSortFields != null &&
                      errors.DrillDownSortFields[index] != null &&
                      errors.DrillDownSortFields[index].Name != null &&
                      errors.DrillDownSortFields[index].Name.message != null
                        ? errors.DrillDownSortFields[index].Name.message
                        : ""
                    }
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Controller
                        control={control}
                        name={`DrillDownSortFields[${index}].Name`}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onBlur={() => {
                              field.onBlur();
                              if (trigger != null) {
                                trigger(`DrillDownSortFields[${index}].Name`);
                              }
                            }}
                            name={`DrillDownSortFields[${index}].Name`}
                            showSearch
                            virtual={false}
                            optionFilterProp="label"
                            filterOption={(input, option) => {
                              const label = option.label
                                ? option.label
                                : option.children;
                              return (
                                label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {disableSelectOptions(
                              fieldOptions,
                              drillDownSortFields,
                              extraDisableOptions
                            )}
                          </Select>
                        )}
                      />
                    </span>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={17} xxl={18}>
                  <Space wrap={true}>
                    <Form.Item
                      name={`DrillDownSortFields[${index}].SortAscending`}
                      style={{ marginBottom: 0 }}
                    >
                      <Controller
                        control={control}
                        name={`DrillDownSortFields[${index}].SortAscending`}
                        render={({ field }) => (
                          <Radio.Group
                            {...field}
                            name={`DrillDownSortFields[${index}].SortAscending`}
                            options={[
                              {
                                value: true,
                                label: "Sort Ascending",
                              },
                              { value: false, label: "Sort Descending" },
                            ]}
                          ></Radio.Group>
                        )}
                      />
                    </Form.Item>

                    <Form.Item
                      name={`Buttons[${index}]`}
                      style={{ marginBottom: 0 }}
                    >
                      <Row>
                        <Col>
                          <Tooltip title="Delete Drill Down">
                            <Button
                              type="text"
                              onClick={() => remove(index)}
                              style={{ padding: 0 }}
                            >
                              <MinusCircleOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                        {drillDownSortFields.length === index + 1 &&
                          drillDownSortFields.length < 4 && (
                            <Col>
                              <Tooltip title="Add Drill Down">
                                <Button
                                  type="text"
                                  disabled={isEmpty(
                                    drillDownSortFields[index].Name
                                  )}
                                  onClick={() => {
                                    append(new DrillDownSortField());
                                    if (trigger != null) {
                                      trigger("DrillDownSortFields");
                                    }
                                  }}
                                >
                                  <PlusCircleOutlined />
                                </Button>
                              </Tooltip>
                            </Col>
                          )}
                      </Row>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form.Item>
          </div>
        ))}
        {isEmpty(drillDownSortFields) && (
          <Divider plain orientation="left" style={{ marginTop: 0 }}>
            <Button
              onClick={() => {
                append(new DrillDownSortField());
                if (trigger != null) {
                  trigger("DrillDownSortFields");
                }
              }}
              type="dashed"
            >
              <PlusOutlined /> Add Drill Down
            </Button>
          </Divider>
        )}
      </div>
    </>
  );
};

export default DrillDownSortFieldSelectEx;
