import { Select } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  listPCode1ForReportParameters,
  listPCode2ForReportParameters,
} from "../../../store/producer/producerActions";
import InfoForMultiSelect from "./InfoForMultiSelect";
import { UpdateDocuments } from "../securityRoleTasks";

const ProducerSelectEx = (props) => {
  const { producerNumber, name, setValue, control, onSelect, taskKey } = props;

  //
  const dispatch = useDispatch();

  //
  const selectedProducerCodes =
    useWatch({
      control,
      name: name,
      defaultValue: [],
    }) ?? [];

  const agencyKeys =
    useWatch({
      control,
      name: "AgencyKeys",
      defaultValue: [],
    }) ?? [];

  //
  const [options, setOptions] = useState([]);

  // The producer codes list comes from redux
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const producerCodeState = useSelector((state) =>
    state?.producer?.listCompact != null
      ? state.producer.listCompact[producerNumber === 1 ? "pCode1" : "pCode2"]
      : null
  );
  const producerCodesList = producerCodeState?.data ?? [];
  const isProducerCodesListLoading = producerCodeState?.loading;

  //
  useEffect(() => {
    // When we get new agency keys, we need to refresh the list of producers
    const dispatchData = { AgencyKeys: agencyKeys, TaskKey: taskKey };
    dispatch(
      producerNumber === 1
        ? listPCode1ForReportParameters(dispatchData)
        : listPCode2ForReportParameters(dispatchData)
    );

    // If there are no agencies selected, we need to clear all selected producer codes
    if (agencyKeys == null || isEmpty(agencyKeys)) {
      setValue(name, []);
    }
  }, [agencyKeys]);

  useEffect(() => {
    // When the producer codes list changes, we need to update the currently selected codes to only keep those that match
    if (
      selectedProducerCodes != null &&
      selectedProducerCodes != [] &&
      !isEmpty(selectedProducerCodes)
    ) {
      const newValue = producerCodesList
        .filter((i) => selectedProducerCodes.includes(i.Id))
        .map((i) => i.Id);

      setValue(name, newValue);
    }

    // Update the list of options to choose from in the <Select /> component
    var updatedOptions =
      producerCodesList != []
        ? producerCodesList.map((p) => {
            return {
              label:
                p.Description != null ? (
                  maskSensitiveData === true ? (
                    <div style={{ display: "inline" }}>
                      {p.ProducerCode} -
                      <div
                        style={{
                          color: "transparent",
                          textShadow: "0 0 8px #000",
                          userSelect: "none",
                          display: "inline",
                        }}
                      >
                        {" "}{p.Description}
                      </div>
                    </div>
                  ) : (
                    `${p.ProducerCode} - ${p.Description}`
                  )
                ) : (
                  `${p.ProducerCode}`
                ),
              value: p.ProducerCodeKey,
              filter:
                p.Description != null
                  ? `${p.ProducerCode} - ${p.Description}`
                  : `${p.ProducerCode}`,
            };
          })
        : [];

    if (producerCodesList.length > 1) {
      updatedOptions.unshift({
        label: `(Select all ${producerCodesList.length} producers)`,
        value: 0,
        filter: `(Select all ${producerCodesList.length} producers)`,
      });
    }
    setOptions(updatedOptions);
  }, [producerCodesList]);

  return (
    <>
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Controller
          control={control}
          name={props.name}
          render={({ field }) => (
            <Select
              {...field}
              name={props.name}
              showSearch
              optionFilterProp="filter"
              filterOption={(input, option) => {
                const filter = option.filter ? option.filter : option.children;
                return filter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              options={options}
              loading={isProducerCodesListLoading}
              mode="multiple"
              onSelect={(value) => {
                let newValue = value;
                // If they chose the option to select all producer codes
                if (value === 0) {
                  newValue =
                    producerCodesList != []
                      ? producerCodesList.map((i) => {
                          return i.Id;
                        })
                      : [];
                  setValue(name, newValue);
                }
                onSelect && onSelect(newValue);
              }}
              allowClear
              maxTagCount={3}
              virtual={false}
            />
          )}
        />
      </span>
      <InfoForMultiSelect
        data={producerCodesList}
        currentValue={selectedProducerCodes}
      />
    </>
  );
};
export default ProducerSelectEx;
