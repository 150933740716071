import {
  FileExcelOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Collapse, Divider, Form, Row, Space, Spin } from "antd";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../../api/entityType";
import {
  clearListCompactAction,
  listAgenciesCompactAction,
} from "../../../store/agency/agencyActions";
import { transformOnHandInput } from "../../../store/inventory/inventoryActions";
import {
  clearOperationResult,
  exportReport,
  fetchReport,
  resetReportState,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import PdfViewer from "../../shared/PdfViewer";
import ReportParamPanelHeader from "../../shared/ReportParamPanelHeader";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomCheckbox from "../../shared/form/reactHookForm/CustomCheckbox";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { ViewInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import AllocatedToAgentSelect from "./AllocatedToAgentSelect";
import { onHandInventoryReportSchema } from "./reportSchemas";

const { Panel } = Collapse;

// Constants
const partialInitialValues = {
  AgencyKey: "",
  AllocatedToAgentKey: null,
  IncludeAllocatedToAgent: false,
  GroupByAgent: false,
};

const reportName = "OnHandInventoryReport";

const OnHandInventoryReport = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { isValid, isDirty, defaultValues, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: partialInitialValues,
    resolver: yupResolver(onHandInventoryReportSchema),
  });

  const agencyKey = watch("AgencyKey");
  const includeAllocatedToAgent = watch("IncludeAllocatedToAgent");
  const allocatedToAgentKey = watch("AllocatedToAgentKey");

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [initialDefaultValues, setInitialDefaultValues] =
    useState(partialInitialValues);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const isFetching = useSelector((state) => state.report.isFetching);
  const isViewerVisible = useSelector((state) => state.report.isViewerVisible);
  const isExporting = useSelector((state) => state.report.isExporting);
  const reportOperationResult = useSelector(
    (state) => state.report.operationResult
  );
  const operationResult = useSelector(
    (state) => state.inventory.operationResult
  );

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ViewInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      const fullInitialValues = {
        ...partialInitialValues,
        AgencyKey: initialAgency,
      };
      setInitialDefaultValues(cloneDeep(fullInitialValues));

      reset(fullInitialValues);
      trigger();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    openNotificationOperationResult(reportOperationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [reportOperationResult, dispatch]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult, dispatch]);

  useEffect(() => {
    dispatch(resetReportState());
    return () => {
      dispatch(clearListCompactAction());
    };
  }, []);

  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformOnHandInput(values),
        entityType.INVENTORY,
        "PrintOnHand",
        () => {
          reset(values);
        }
      )
    );
  };

  return (
    <PermissionWrapper entityName="InventoryReports">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              key="1"
              header={
                <ReportParamPanelHeader
                  paramsDirty={
                    isDirty && isViewerVisible && !isFetching && !isSubmitted
                  }
                />
              }
            >
              <Form
                size="small"
                autoComplete="off"
                onFinish={() => handleSubmit(onSubmitHandler)()}
              >
                <Spin spinning={isFetching} indicator={<div />}>
                  <Row>
                    <Col span={8}>
                      <AgencySelectEx
                        layout={{
                          labelCol: {
                            span: 4,
                          },
                          wrapperCol: {
                            span: 19,
                          },
                        }}
                        formLabel="Agency"
                        name="AgencyKey"
                        required={true}
                        formStyle={{ marginBottom: 0 }}
                        agencies={currentAgencies}
                        isLoading={defaultValues.AgencyKey === ""}
                        control={control}
                        onSelect={(_) => {
                          setValue("AllocatedToAgentKey", null);
                          trigger();
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <AllocatedToAgentSelect
                        control={control}
                        agencyKey={agencyKey}
                        includeAllOption={true}
                        allowClear={true}
                        layout={{
                          labelCol: {
                            span: 8,
                          },
                          wrapperCol: {
                            span: 15,
                          },
                        }}
                        formStyle={{ marginBottom: 0 }}
                        setValue={setValue}
                      />
                    </Col>
                    <Col span={3}>
                      {allocatedToAgentKey !== null && (
                        <CustomCheckbox
                          control={control}
                          layout={{
                            labelCol: { span: 19 },
                            wrapperCol: { span: 4 },
                          }}
                          formLabel="Include Agent(s)"
                          name="IncludeAllocatedToAgent"
                          style={{ marginBottom: 0 }}
                          onChange={(e) => {
                            if (e.target.checked === false) {
                              setValue("GroupByAgent", false);
                            }
                          }}
                        />
                      )}
                    </Col>
                    <Col span={3}>
                      {allocatedToAgentKey !== null &&
                        includeAllocatedToAgent && (
                          <CustomCheckbox
                            control={control}
                            formLabel="Group By Agent"
                            name="GroupByAgent"
                            style={{ marginBottom: 0 }}
                            disabled={!includeAllocatedToAgent}
                          />
                        )}
                    </Col>
                  </Row>
                </Spin>
                <Row justify="end">
                  <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
                  <Col>
                    <Space align="end">
                      <Button
                        disabled={!isValid || isExporting}
                        autoFocus={true}
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        loading={isFetching}
                      >
                        <PrinterOutlined /> Preview
                      </Button>
                      <Button
                        disabled={!isValid}
                        loading={isExporting}
                        onClick={() =>
                          dispatch(
                            exportReport(
                              transformOnHandInput(getValues()),
                              reportName,
                              entityType.INVENTORY
                            )
                          )
                        }
                      >
                        <FileExcelOutlined />
                        Export
                      </Button>
                      <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                          reset(initialDefaultValues);
                          dispatch(resetReportState());
                        }}
                      >
                        Restore Default
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          {isViewerVisible && <PdfViewer isDirty={isDirty} />}
        </Col>
      </Row>
    </PermissionWrapper>
  );
};
export default OnHandInventoryReport;
