import { PrinterOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Row, Space } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import entityType from "../../api/entityType";
import { transformPOPAuthFormInput } from "../../store/loanCalculator/loanCalculatorActions";
import {
  fetchReport,
  setReportModalVisibility,
} from "../../store/report/reportActions";
import CustomDatePicker from "../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import CustomRadioGroup from "../shared/form/reactHookForm/CustomRadioGroup";
import CustomSelect from "../shared/form/reactHookForm/CustomSelect";
import { dateFormat, toStartOfDay } from "../shared/formatting";
import { insuranceTypeOptions, provinceOptions } from "./constants";
import { popAuthorizationFormSchema } from "./loanCalculatorSchemas";

export const POPAuthorizationForm = () => {
  //
  const data = useSelector(
    (state) => state.loanCalculator.paymentDetails?.data
  );
  const header = useSelector(
    (state) => state.loanCalculator.paymentDetails?.header
  );
  const isFetching = useSelector((state) => state.report.isFetching);

  //
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      PolicyNo: "",
      PolicyEffectiveDate: header?.ContractEffectiveDate
        ? toStartOfDay(header.ContractEffectiveDate)
        : toStartOfDay(dayjs()),
      PolicyExpiryDate: header?.ContractEffectiveDate
        ? toStartOfDay(dayjs(header.ContractEffectiveDate).add(1, "year"))
        : toStartOfDay(dayjs().add(1, "year")),
      ClientNo: "",
      Insurer: "",
      InsuranceType: null,
      StreetNo: "",
      StreetName: "",
      City: "",
      Province: "British Columbia",
      PostalCode: "",
      PhoneNo: "",
      Email: "",
    },
    resolver: yupResolver(popAuthorizationFormSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const onSubmitHandler = (values) => {
    dispatch(
      fetchReport(
        transformPOPAuthFormInput({
          header: header,
          data: data,
          values: values,
        }),
        entityType.LOAN_CALCULATOR,
        "PrintPOPAuthForm"
      )
    );
    dispatch(setReportModalVisibility(true));
  };

  return (
    <div>
      <Form
        size="small"
        autoComplete="off"
        onFinish={() => handleSubmit(onSubmitHandler)()}
        layout="vertical"
      >
        <Row gutter={[0, 8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Card title="Verify / Enter Policy Details">
              <Row gutter={8}>
                <Col span={8}>
                  <CustomInput
                    control={control}
                    formLabel="Policy No."
                    name="PolicyNo"
                    style={{ maxWidth: 190 }}
                  />
                </Col>
                <Col span={8}>
                  <CustomDatePicker
                    formItem={{ label: "Policy Effective Date" }}
                    control={control}
                    name="PolicyEffectiveDate"
                    format={dateFormat}
                    timeOfDay="start"
                  />
                </Col>
                <Col span={8}>
                  <CustomDatePicker
                    formItem={{ label: "Policy Expiry Date" }}
                    control={control}
                    name="PolicyExpiryDate"
                    format={dateFormat}
                    timeOfDay="start"
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={8}>
                  <CustomInput
                    control={control}
                    formLabel="Client No."
                    name="ClientNo"
                    style={{ maxWidth: 190 }}
                  />
                </Col>
                <Col span={8}>
                  <CustomInput
                    control={control}
                    formLabel="Insurer"
                    name="Insurer"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CustomRadioGroup
                    control={control}
                    formLabel="Insurance Type"
                    name="InsuranceType"
                  >
                    {insuranceTypeOptions}
                  </CustomRadioGroup>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Row>
              <Col xs={0} sm={0} md={0} lg={0} xl={1}></Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                <Card title="Enter Client Contact Details">
                  <Row gutter={8}>
                    <Col span={6}>
                      <CustomInput
                        control={control}
                        formLabel="Street No."
                        name="StreetNo"
                        style={{ maxWidth: 120 }}
                      />
                    </Col>
                    <Col span={9}>
                      <CustomInput
                        control={control}
                        formLabel="Street Name"
                        name="StreetName"
                        style={{ maxWidth: 225 }}
                      />
                    </Col>
                    <Col span={9}>
                      <CustomInput
                        control={control}
                        formLabel="City/Town"
                        name="City"
                        style={{ maxWidth: 225 }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={6}>
                      <CustomInput
                        formLabel="Postal Code"
                        control={control}
                        name="PostalCode"
                        autoComplete="off"
                        style={{ maxWidth: 100 }}
                      />
                    </Col>
                    <Col span={9}>
                      <CustomSelect
                        control={control}
                        formLabel="Province"
                        name="Province"
                        options={provinceOptions}
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ maxWidth: 225 }}
                      />
                    </Col>

                    <Col span={9}>
                      <CustomInput
                        control={control}
                        formLabel="Phone Number"
                        name="PhoneNo"
                        autoComplete="off"
                        style={{ maxWidth: 150 }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <CustomInput
                        control={control}
                        formLabel="Email"
                        name="Email"
                        autoComplete="off"
                        style={{ maxWidth: 400 }}
                      />
                    </Col>
                  </Row>
                </Card>
                <Row justify="end" style={{ marginTop: 12 }}>
                  <Col>
                    <Space align="end">
                      <Button
                        loading={isFetching}
                        autoFocus={true}
                        id="processButton"
                        disabled={!isValid && isSubmitted}
                        type="primary"
                        htmlType="submit"
                      >
                        <PrinterOutlined />
                        Create POP Authorization Form
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
