import Actions from "./dashboardConstants";
import produce from "immer";

const initialState = {
  unbatchedDocsSummary: { loading: false, data: [], operationResult: null },
  production: {
    agencySummary: { loading: false, data: [], operationResult: null },
    txnDateSummary: { loading: false, data: [], operationResult: null },
  },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.GET_UNBATCHED_DOCS_SUMMARY:
        draft.unbatchedDocsSummary.loading = true;
        return draft;
      case Actions.GET_UNBATCHED_DOCS_SUMMARY_SUCCEEDED:
        draft.unbatchedDocsSummary.data = action.payload.Data;
        draft.unbatchedDocsSummary.operationResult = action.payload.Operation;
        draft.unbatchedDocsSummary.loading = false;
        return draft;
      default:
        return state;
    }
  });
};
