import { get } from "lodash";
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  formatPhone,
} from "../../formatting";
import { compareDates, compareNumbers, compareStrings } from "../../sorting";
import { columnTypes } from "../columnTypes";
import { getColumnSearchProps } from "./getColumnSearchProps";
import { getCurrencySearchProps } from "./getCurrencySearchProps";
import { getDateColumnSearchProps } from "./getDateColumnSearchProps";
import { getNumberColumnSearchProps } from "./getNumberColumnSearchProps";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CaretDownFilled,
  CaretUpFilled,
  SearchOutlined,
} from "@ant-design/icons";

import { getTextMultiFilterColumnSearchProps } from "./getTextMultiFilterColumnSearchProps";

export const columnMapper = (
  columns,
  sortedInfo,
  filteredInfo,
  searchInput,
  triggerOnChangeRef
) => {
  const columnsLength = Object.keys(columns).length;
  searchInput.current = new Array(columnsLength).fill(null);
  var i = -1;

  var mappedColumns = columns.map((column) => {
    i++;
    let mappedColumn = {
      key: column.dataIndex,
      filteredValue: filteredInfo[column.dataIndex] || null,
      sortOrder: sortedInfo.columnKey === column.dataIndex && sortedInfo.order,
      sortIcon: ({ sortOrder }) => {
        return (
          <>
            {sortOrder === "descend" ? (
              <ArrowDownOutlined
                style={{ color: "#78A1CF", marginLeft: "2px" }}
              />
            ) : sortOrder === "ascend" ? (
              <ArrowUpOutlined
                style={{ color: "#78A1CF", marginLeft: "2px" }}
              />
            ) : (
              ""
            )}
          </>
        );
      },
      // <CaretUpFilled
      ...column,
    };

    switch (column.type) {
      case columnTypes.TEXT:
        mappedColumn = {
          ...mappedColumn,
          sorter: (a, b) =>
            compareStrings(get(a, column.dataIndex), get(b, column.dataIndex)),
          ...getColumnSearchProps(
            column.dataIndex,
            column.title,
            searchInput,
            i
          ),
        };
        break;
      case columnTypes.TEXT_MULTI_FILTER:
        mappedColumn = {
          ...mappedColumn,
          sorter: (a, b) =>
            compareStrings(get(a, column.dataIndex), get(b, column.dataIndex)),
          ...getTextMultiFilterColumnSearchProps(
            column.dataIndex,
            column.title,
            column.filterOptions,
            searchInput,
            i
          ),
        };
        break;
      case columnTypes.TEXT_CUSTOM_SORTER:
        mappedColumn = {
          ...getColumnSearchProps(
            column.dataIndex,
            column.title,
            searchInput,
            i
          ),
          ...mappedColumn,
        };
        break;
      case columnTypes.NUMBER:
        mappedColumn = {
          ...mappedColumn,
          sorter: (a, b) =>
            compareNumbers(get(a, column.dataIndex), get(b, column.dataIndex)),
          ...getNumberColumnSearchProps(
            column.dataIndex,
            column.title,
            searchInput,
            i
          ),
        };
        break;
      case columnTypes.NUMBER_NO_FILTER:
        mappedColumn = {
          ...mappedColumn,
          key: column.dataIndex,
          sorter: (a, b) =>
            compareNumbers(get(a, column.dataIndex), get(b, column.dataIndex)),
          defaultFilteredValue: null,
          filteredValue: null,
          filterResetToDefaultFilteredValue: true,
          filtered: false,
          filterIcon: <div></div>,
          filterDropdown: () => <div></div>,
        };
        break;
      case columnTypes.PHONE:
        mappedColumn = {
          render: (value) => formatPhone(value),
          ...mappedColumn,
          sorter: (a, b) =>
            compareStrings(get(a, column.dataIndex), get(b, column.dataIndex)),
          ...getColumnSearchProps(
            column.dataIndex,
            column.title,
            searchInput,
            i
          ),
        };
        break;
      case columnTypes.DATE:
        mappedColumn = {
          render: (value) => formatDate(value),
          ...mappedColumn,
          sorter: (a, b) =>
            compareDates(get(a, column.dataIndex), get(b, column.dataIndex)),
          ...getDateColumnSearchProps(column.dataIndex),
        };
        break;
      case columnTypes.DATETIME:
        mappedColumn = {
          render: (value) => formatDateTime(value),
          ...mappedColumn,
          sorter: (a, b) =>
            compareDates(get(a, column.dataIndex), get(b, column.dataIndex)),
          ...getDateColumnSearchProps(column.dataIndex),
        };
        break;
      case columnTypes.BOOLEAN:
        mappedColumn = {
          render: (value) => (value ? "Yes" : "No"),
          ...mappedColumn,
          sortOrder: null,
          filters: [
            { text: "Yes", value: true },
            { text: "No", value: false },
          ],
          onFilter: (value, record) => record[column.dataIndex] === value,
        };
        break;
      case columnTypes.STATUS:
        mappedColumn = {
          ...mappedColumn,
          sortOrder: null,
          render: (value) => (value === "A" ? "Active" : "Inactive"),
          filters: [
            { text: "Active", value: "A" },
            { text: "Inactive", value: "I" },
          ],
          onFilter: (value, record) => record[column.dataIndex] === value,
        };
        break;

      case columnTypes.CURRENCY:
        mappedColumn = {
          render: (value) => formatCurrency(value),
          ...mappedColumn,
          sorter: (a, b) =>
            compareNumbers(get(a, column.dataIndex), get(b, column.dataIndex)),
          ...getCurrencySearchProps(
            column.dataIndex,
            column.title,
            searchInput,
            i
          ),
          align: "right",
        };
        break;
      case columnTypes.ACTION_WITHOUT_FILTER:
        mappedColumn = {
          ...column,
        };
        break;
      default:
        mappedColumn = {
          key: column.dataIndex,
          ...column,
        };
        break;
    }
    return mappedColumn;
  });

  /***
   *  We add an invisible column called UniqueFilterColumn
   *  This allows us to expose triggerOnChangeRef which is used to trigger the onChange property of <Table /> component
   *  Triggering onChange is useful when we want to know the filtered data source.
   *  We have to use this trick because currentDataSource from onChange() is not exposed by ant design
   ***/
  mappedColumns = [
    {
      key: "UniqueFilterColumn",
      title: "",
      filterDropdown: ({ setSelectedKeys, confirm }) => {
        triggerOnChangeRef.current = () => {
          setSelectedKeys([""]);
          confirm();
        };
        return <></>;
      },
      filteredValue: null,
      render: () => {
        return <div className="unique-filter-column"></div>;
      },
      onFilter: () => {
        return true;
      },
      filterIcon: () => {
        return <></>;
      },
      width: "0.00001%",
      fixed: "left",
    },
    ...mappedColumns,
  ];

  return mappedColumns;
};
