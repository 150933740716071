import { customGet } from "../../api/customApi";
import entityType from "../../api/entityType";
import { toTZNeutralFormat } from "../../components/shared/formatting";
import Actions from "./dashboardConstants";

export const getUnbatchedDocSummary =
  (brokerId, submissionDate) => async (dispatch) => {
    dispatch({ type: Actions.GET_UNBATCHED_DOCS_SUMMARY });

    const payload = await customGet(
      entityType.BATCH,
      "GetUnbatchedDocsSummary",
      {
        params: {
          brokerId: brokerId,
          submissionDate: submissionDate,
        },
      }
    );
    dispatch({ type: Actions.GET_UNBATCHED_DOCS_SUMMARY_SUCCEEDED, payload });
  };
