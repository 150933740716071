import { Col, Empty, Row } from "antd";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  clearBatchListAction,
  clearOperationResultAction,
  listAction,
  setSearchCriteriaAction,
} from "../../../store/batching/batchingActions";
import {
  clearOperationResult,
  resetReportState,
} from "../../../store/report/reportActions";
import PermissionWrapper from "../../navigation/PermissionWrapper";
import { PdfViewerModal } from "../../shared/PdfViewer";
import { PreservableStatePageWrapper } from "../../shared/PreservableStatePageWrapper";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { tableNames } from "../../shared/table/tableNames";
import SearchBatchesForm from "../form/SearchBatchesForm";
import UpdateBatchForm from "../form/UpdateBatchForm";
import { batchColumns } from "./batchingColumns";

const Batches = () => {
  {
    const batchingState = useSelector((state) => state.batching);

    const operationResult = batchingState.operationResult;
    const dispatch = useDispatch();

    const reportOperationResult = useSelector(
      (state) => state.report.operationResult
    );

    useEffect(() => {
      dispatch(resetReportState());
    }, []);

    useEffect(() => {
      openNotificationOperationResult(reportOperationResult, () =>
        dispatch(clearOperationResult())
      );
    }, [reportOperationResult, dispatch]);

    useEffect(() => {
      openNotificationOperationResult(operationResult, () =>
        dispatch(clearOperationResultAction()), true, true
      );
    }, [operationResult, dispatch]);

    return (
      <div>
        <Routes>
          <Route
            path=""
            element={
              <PreservableStatePageWrapper
                onCleanup={() => {
                  dispatch(clearBatchListAction());
                  dispatch(setSearchCriteriaAction(null));
                }}
                tableNames={[tableNames.BatchSearch]}
              >
                <SearchBatchesBody />
              </PreservableStatePageWrapper>
            }
          />

          <Route
            path="updatebatchform/:batchKey"
            element={
              <PreservableStatePageWrapper
                onCleanup={() => {}}
                tableNames={[tableNames.BatchView]}
              >
                <UpdateBatchBody />
              </PreservableStatePageWrapper>
            }
          />
        </Routes>
        <PdfViewerModal />
      </div>
    );
  }
};

const SearchBatchesBody = () => {
  const [clearAll, setClearAll] = useState(false);
  const batchingState = useSelector((state) => state.batching);
  const searchCriteria = batchingState.searchCriteria;
  const data = batchingState.data;
  const loading = batchingState.loading;

  const columns = batchColumns(searchCriteria);

  const emptyTableMessage = isNull(searchCriteria)
    ? "No data"
    : `No matches found. Please review your search criteria and try again.`;

  return (
    <PermissionWrapper entityName="Batching">
      <Row>
        <Col span={24}>
          <SearchBatchesForm
            loading={loading}
            setClearAll={setClearAll}
            searchCriteria={searchCriteria}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchableTable
            setClearAll={setClearAll}
            clearAll={clearAll}
            scroll={{
              x: 1035,
              y: 550,
            }}
            sticky
            dataSource={data}
            bordered={true}
            columns={columns}
            loading={loading}
            size="small"
            rowKey={(r) => r.DocumentBatchKey}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={emptyTableMessage}
                />
              ),
            }}
            tableName={tableNames.BatchSearch}
          />
        </Col>
      </Row>
    </PermissionWrapper>
  );
};

const UpdateBatchBody = () => {
  const dispatch = useDispatch();
  const searchCriteria = useSelector((state) => state.batching.searchCriteria);
  return (
    <UpdateBatchForm
      handleParentRefresh={() => dispatch(listAction(searchCriteria))}
      searchCriteria={searchCriteria}
    />
  );
};

export default Batches;
