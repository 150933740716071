import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Form, Row } from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addItemInventoryMovementAction } from "../../../store/inventory/inventoryActions";
import CustomAutoComplete from "../../shared/form/reactHookForm/CustomAutoComplete";
import {
  getItemNumberFromFieldValue,
  getItemNumberToFieldValue,
  renderRangeOptions,
} from "./itemRangeHelpers";
import { transferPlatesSchema } from "./moveInventorySchemas";
import { types as movementTypes } from "./movementTypes";

//
const initialValues = {
  PlateItemNumberFrom: undefined,
  PlateItemNumberTo: undefined,
};

const AllocatePlatesForm = ({ headerValues, focusPlateFromInput }) => {
  // Form
  const {
    reset,
    control,
    setValue,
    clearErrors,
    getValues,
    setFocus,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(transferPlatesSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [plateRanges, setPlateRanges] = useState([]);
  const [plateFromNumberOptions, setPlateFromNumberOptions] = useState([]);
  const [plateToNumberOptions, setPlateToNumberOptions] = useState([]);

  //
  const availableItemRanges = useSelector(
    (state) => state.inventory.moveInventory.newMovement.availableItemRanges
  );

  const availableItemRangesLoading = useSelector(
    (state) =>
      state.inventory.moveInventory.newMovement.availableItemRangesLoading
  );

  const newMovementEntity = useSelector(
    (state) => state.inventory.moveInventory.newMovement.entity
  );
  const loading = useSelector(
    (state) => state.inventory.moveInventory.newMovement.loading
  );

  useEffect(() => {
    if (availableItemRanges != null && !isEmpty(availableItemRanges)) {
      var pRanges = availableItemRanges
        ? availableItemRanges.filter((r) => !isNull(r.PlateTypeKey))
        : [];

      var pTypes = [...new Set(pRanges.map((item) => item.ItemDescription))];

      var pFromNumber = pTypes
        ? pTypes.map((t) => renderRangeOptions(t, pRanges, true))
        : [];

      var pToNumber = pTypes
        ? pTypes.map((t) => renderRangeOptions(t, pRanges, false))
        : [];

      setPlateRanges(pRanges);
      setPlateFromNumberOptions(pFromNumber);
      setPlateToNumberOptions(pToNumber);
    }
  }, [availableItemRanges, availableItemRangesLoading]);

  useEffect(() => {
    !isEmpty(plateRanges) &&
      focusPlateFromInput &&
      setFocus("PlateItemNumberFrom");
  }, [setFocus, plateRanges, focusPlateFromInput]);

  const onAddItemsHandler = (values) => {
    dispatch(
      addItemInventoryMovementAction({
        ItemNumberFrom: values.PlateItemNumberFrom,
        ItemNumberTo: values.PlateItemNumberTo,
        AgencyKey: newMovementEntity.Header.AgencyKey,
        InventoryMovementType: newMovementEntity.Header.InventoryMovementType,
        InventoryMovementKey: newMovementEntity.Header.InventoryMovementKey,
        TransactionDate: headerValues.TransactionDate,
        StockCentreConfNo: headerValues.StockCentreConfNo,
        TransferredToAgencyNumber: headerValues.TransferredToAgencyNumber,
      })
    );
  };

  return (
    <div>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" layout="inline">
            <Row>
              <CustomAutoComplete
                loading={availableItemRangesLoading}
                control={control}
                formLabel="Plate Series From"
                formTooltip={
                  newMovementEntity.Header.InventoryMovementType ==
                  movementTypes.DefectiveStockReturn
                    ? "The selection list only contains unissued inventory. If you would like to return inventory not in the list, please key in the range instead of selecting it from the list."
                    : ""
                }
                required={true}
                formStyle={{ marginBottom: 0 }}
                autoClearSearchValue={true}
                name="PlateItemNumberFrom"
                options={plateFromNumberOptions}
                filterOption={true}
                onClear={() => clearErrors()}
                style={{ width: 230 }}
                allowClear
                onSelect={(value) =>
                  setValue(
                    "PlateItemNumberTo",
                    getItemNumberToFieldValue(availableItemRanges, value),
                    {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    }
                  )
                }
                onBlur={() => {
                  if (isEmpty(getValues().PlateItemNumberFrom)) {
                    clearErrors();
                  }
                }}
                afterChange={(value) => {
                  if (isEmpty(value)) {
                    reset();
                  }
                }}
              />

              <CustomAutoComplete
                loading={availableItemRangesLoading}
                control={control}
                required={true}
                formLabel="To"
                formStyle={{ marginBottom: 0 }}
                autoClearSearchValue={true}
                name="PlateItemNumberTo"
                options={plateToNumberOptions}
                filterOption={true}
                style={{ width: 230 }}
                allowClear
                onClear={() => clearErrors()}
                onBlur={() => {
                  if (isEmpty(getValues().PlateItemNumberTo)) {
                    clearErrors();
                  }
                }}
                onSelect={(value) =>
                  setValue(
                    "PlateItemNumberFrom",
                    getItemNumberFromFieldValue(availableItemRanges, value),
                    {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    }
                  )
                }
                afterChange={(value) => {
                  if (isEmpty(value)) {
                    reset();
                  }
                }}
              />

              <Form.Item name="AddPlateButton">
                <Button
                  size="small"
                  disabled={!isValid || !isDirty}
                  id="processButton"
                  type="primary"
                  onClick={() => {
                    onAddItemsHandler(getValues());
                    reset();
                  }}
                  loading={loading}
                >
                  Add Plates
                </Button>
              </Form.Item>
            </Row>
            <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AllocatePlatesForm;
