import React, { useEffect, useState } from "react";
import { List, Button, Space, Typography, Collapse } from "antd";
import { listAnnouncements } from "../../store/announcement/announcementActions";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormat } from "../shared/formatting";
import dayjs from "dayjs";
import { announcementType } from "./announcementType";
import { WarningIcon, InfoIcon, ErrorIcon } from "./announcementIcons";
import { NotificationOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const { Text } = Typography;

const AnnouncementFeed = () => {
  const data = useSelector((state) => state.announcement.data);
  const loading = useSelector((state) => state.announcement.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listAnnouncements());
  }, []);

  const items = [
    {
      key: "1",
      label: (
        <Space size="small">
          <NotificationOutlined
            style={{ fontSize: 16, color: "rgb(236, 58, 0)" }}
          />
          <Text strong>Latest Announcements</Text>
        </Space>
      ),
      children: (
        <div style={{ height: "20vh", overflowY: "auto" }}>
          <List
            bordered={false}
            locale={{ emptyText: "No New Announcements" }}
            size="small"
            loading={loading}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item
                style={{ padding: "0px 2px 0px 0px" }}
                actions={[
                  <Button
                    type="link"
                    onClick={() => window.open(item.Link, item.LinkText)}
                  >
                    {item.LinkText ? item.LinkText : item.Link}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={
                    item.Type === announcementType.Error ? (
                      <Space>
                        <ErrorIcon />
                        {item.Subject}
                      </Space>
                    ) : item.Type === announcementType.Warning ? (
                      <Space>
                        <WarningIcon />
                        {item.Subject}
                      </Space>
                    ) : (
                      <Space>
                        <InfoIcon />
                        {item.Subject}
                      </Space>
                    )
                  }
                  description={`[${dayjs(item.AnnounceOnDate).format(
                    dateTimeFormat
                  )}]: ${item.Description}`}
                />
              </List.Item>
            )}
          />
        </div>
      ),
      showArrow: false,
    },
  ];
  return <Collapse defaultActiveKey={["1"]} items={items}></Collapse>;
};

export default AnnouncementFeed;
