export const Actions = {
  SET_SELECTED_MAPPING: "SET_SELECTED_MAPPING",
  CLEAR_SELECTED_MAPPING: "CLEAR_SELECTED_MAPPING",
  CREATE_MAPPING: "CREATE_MAPPING",
  CREATE_MAPPING_SUCCESS: "CREATE_MAPPING_SUCCESS",
  LIST_MAPPING: "LIST_MAPPING",
  LIST_MAPPING_SUCCESS: "LIST_MAPPING_SUCCESS",
  UPDATE_MAPPING: "UPDATE_MAPPING",
  UPDATE_MAPPING_SUCCESS: "UPDATE_MAPPING_SUCCESS",
  DELETE_MAPPING: "DELETE_MAPPING",
  DELETE_MAPPING_SUCCESS: "DELETE_MAPPING_SUCCESS",
  CLEAR_OPERATION_RESULT: "CLEAR_AGENCY_OPERATION_RESULT",
};
