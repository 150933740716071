import { Radio } from "antd";

export const sortGroupDrillDownFields = {
  AGENCY_NAME: "AgencyNameAndNo",
  BATCH_NUMBER: "BatchNo",
  PRODUCER_1_NAME: "ProducerName1",
  PRODUCER_2_NAME: "ProducerName2",
  PRODUCER_1_CODE: "ProducerCode1",
  PRODUCER_2_CODE: "ProducerCode2",
  FLEET_GARAGE_NUMBER: "FleetGarageNumber",
  TRANSACTIION_DATE: "TransactionDate",
};
export const drillDownSortFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Producer 1 Name", value: sortGroupDrillDownFields.PRODUCER_1_NAME },
  { label: "Producer 2 Name", value: sortGroupDrillDownFields.PRODUCER_2_NAME },
  { label: "Producer 1 Code", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2 Code", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
  {
    label: "Fleet/Garage Number",
    value: sortGroupDrillDownFields.FLEET_GARAGE_NUMBER,
  },
];

export const sortGroupFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Producer 1 Name", value: sortGroupDrillDownFields.PRODUCER_1_NAME },
  { label: "Producer 2 Name", value: sortGroupDrillDownFields.PRODUCER_2_NAME },
  { label: "Producer 1 Code", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2 Code", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
  {
    label: "Fleet/Garage Number",
    value: sortGroupDrillDownFields.FLEET_GARAGE_NUMBER,
  },
  { label: "Batch Number", value: sortGroupDrillDownFields.BATCH_NUMBER },
  {
    label: "Transaction Date & Time",
    value: sortGroupDrillDownFields.TRANSACTIION_DATE,
  },
];

export const reportTypes = {
  SUMMARY: "Summary",
  DETAIL: "Detail",
};

export const reportTypeOptions = [
  <Radio value={reportTypes.DETAIL} key="0">
    Detail
  </Radio>,
  <Radio value={reportTypes.SUMMARY} key="1">
    Summary
  </Radio>,
];
