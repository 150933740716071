import produce from "immer";
import { Actions } from "./loanCalculatorConstants";

const initialState = {
  agencies: {
    loading: false,
    data: [],
  },
  operationResult: null,
  paymentDetails: {
    loading: false,
    data: [],
    header: null,
  },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_LOAN_CALCULATOR:
        draft = initialState;
        return draft;
      case Actions.CLEAR_LOAN_CALCULATOR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.LIST_LOAN_CALCULATOR_AGENCIES:
        draft.agencies.loading = true;
        return draft;
      case Actions.LIST_LOAN_CALCULATOR_AGENCIES_SUCCEEDED:
        draft.agencies.data = action.payload.Data;
        draft.agencies.loading = !(
          action.payload.Operation.Outcome === "Success"
        );
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.GET_LOAN_PAYMENT_DETAILS:
        draft.paymentDetails.loading = true;
        return draft;
      case Actions.GET_LOAN_PAYMENT_DETAILS_SUCCEEDED:
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.paymentDetails.header = action.payload?.Data?.header ?? null;
        draft.paymentDetails.data = action.payload?.Data?.data ?? null;
        draft.paymentDetails.loading = false;
        return draft;
      default:
        return state;
    }
  });
};
