import { Actions } from "../announcement/announcementConstants";
import produce from "immer";

const initialState = {
  dataForAdmin: [],
  data: [],
  loading: false,
  selected: null,
  selectedForAlert: null,
  selectedLoading: false,
  operationResult: null,
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;
      case Actions.LIST_ANNOUNCEMENTS:
      case Actions.LIST_ANNOUNCEMENTS_FOR_ADMIN:
      case Actions.DELETE_ANNOUNCEMENT:
        draft.loading = true;
        return draft;
      case Actions.LIST_ANNOUNCEMENTS_SUCCEEDED:
        draft.loading = false;
        draft.data = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.LIST_ANNOUNCEMENTS_FOR_ADMIN_SUCCEEDED:
        draft.loading = false;
        draft.dataForAdmin = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        return draft;
      case Actions.DELETE_ANNOUNCEMENT_SUCCEEDED:
        draft.loading = false;
        if (action.payload.Operation.Outcome === "Success") {
          draft.dataForAdmin = action.payload.Data;
        } else {
          draft.operationResult = action.payload.Operation;
        }
        return draft;
      case Actions.CREATE_ANNOUNCEMENT:
      case Actions.READ_ANNOUNCEMENT:
      case Actions.UPDATE_ANNOUNCEMENT:
        draft.selectedLoading = true;
        return draft;
      case Actions.READ_ANNOUNCEMENT_SUCCEEDED:
        draft.selected = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.selectedLoading = false;
        return draft;
      case Actions.READ_ANNOUNCEMENT_FOR_ALERT_SUCCEEDED:
        if (action.payload.Operation.Outcome === "Success") {
          draft.selectedForAlert = action.payload.Data;
        }
        return draft;
      case Actions.UPDATE_ANNOUNCEMENT_SUCCEEDED:
        draft.selectedLoading = false;
        draft.operationResult = action.payload.Operation;
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        }
        return draft;
      case Actions.CREATE_ANNOUNCEMENT_SUCCEEDED:
        draft.selectedLoading = false;
        draft.operationResult = action.payload.Operation;
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        } else if (action.payload.Operation.Outcome === "Error") {
          draft.saveAndAddFlag = false;
        }
        return draft;
      case Actions.CLEAR_SELECTED_ANNOUNCEMENT:
        draft.operationResult = null;
        draft.selected = null;
        draft.selectedLoading = false;
        return draft;

      case Actions.ACKNOWLEDGE_ANNOUNCEMENT:
      case Actions.ACKNOWLEDGE_ANNOUNCEMENT_SUCCEEDED:
        draft.selectedForAlert = null;
        return draft;
      default:
        return state;
    }
  });
};
