import { RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Form, Radio, Row, Space } from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { listAgenciesCompactAction } from "../../../store/agency/agencyActions";
import {
  getNewInventoryMovementAction,
  setReturnToICBCCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import { ManageInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import { step1Schema } from "../shared/moveInventorySchemas";
import { movementOptions, types } from "../shared/movementTypes";

//
const initialValues = {
  AgencyKey: "",
  InventoryMovementType: types.DefectiveStockReturn,
};

const ReturnToICBCStep1 = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(step1Schema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector(
    (state) => state.inventory.moveInventory.newMovement.loading
  );
  const header = useSelector(
    (state) =>
      state.inventory.moveInventory.newMovement.entity &&
      state.inventory.moveInventory.newMovement.entity.Header
  );

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ManageInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      if (!isNull(header)) {
        if (agencies.find((a) => a.Id === header.AgencyKey)) {
          initialAgency = header.AgencyKey;
        }
      }

      var defaultMovementType = !isNull(header)
        ? header.InventoryMovementType
        : initialValues.InventoryMovementType;

      reset({
        AgencyKey: initialAgency,
        InventoryMovementType: defaultMovementType,
      });

      trigger();
    }
  }, [agencies, homeAgency]);

  const onSubmitHandler = (values) => {
    dispatch(getNewInventoryMovementAction(values, "RETURN_ICBC"));
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandler)()}
          >
            <AgencySelectEx
              layout={{ labelCol: { span: 3 }, wrapperCol: { span: 8 } }}
              formLabel="Agency"
              name="AgencyKey"
              required={true}
              autoFocus={true}
              agencies={currentAgencies}
              isLoading={defaultValues.AgencyKey === ""}
              control={control}
            />

            <CustomRadioGroup
              control={control}
              layout={{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
              formLabel="Return Type"
              name="InventoryMovementType"
              required={true}
            >
              <Space direction="vertical">
                <Radio value={types.UnusedStockReturn}>
                  {
                    movementOptions.filter(
                      (o) => o.value === types.UnusedStockReturn
                    )[0].label
                  }
                </Radio>
                <Radio value={types.DefectiveStockReturn}>
                  {
                    movementOptions.filter(
                      (o) => o.value === types.DefectiveStockReturn
                    )[0].label
                  }
                </Radio>
              </Space>
            </CustomRadioGroup>

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <Row style={{ paddingTop: 16 }}>
              <Col offset={16} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        size="small"
                        loading={loading}
                        disabled={!isValid}
                      >
                        Next
                        <RightCircleOutlined />
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ReturnToICBCStep1;
