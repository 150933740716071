import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { usePreservableState } from "./PreservableStatePageWrapper";

interface BackButtonProps {
  isDirty: boolean;
  onGoBackHandler: () => void;
  name: string | React.ReactNode | undefined;
}

export const BackButton: React.FC<BackButtonProps> = ({
  isDirty = false,
  onGoBackHandler = () => {},
  name = undefined,
}) => {
  //
  const { isRestorable, restorePreviousPage, getPreviousPageName } =
    usePreservableState();
  const navigate = useNavigate();
  const location = useLocation();

  //
  const onClose = useCallback(() => {
    isRestorable && restorePreviousPage();
    onGoBackHandler();
    navigate(-1);
  }, [isRestorable, onGoBackHandler]);

  //
  const buttonName: any = name != null ? name : getPreviousPageName();

  return location.key !== "default" && isRestorable === true ? (
    <>
      {isDirty === true ? (
        <Popconfirm
          title="You have unsaved changes. Discard these changes and close?"
          onConfirm={onClose}
          okText="Yes"
          cancelText="No"
        >
          <Button size ="small">
            <ArrowLeftOutlined />
            {buttonName}
          </Button>
        </Popconfirm>
      ) : (
        <Button onClick={onClose} size="small">
          <ArrowLeftOutlined />
          {buttonName}
        </Button>
      )}
    </>
  ) : (
    <></>
  );
};
