const maintainInventoryTasks = {
  RemoveFromInventory: {
    value: "RemoveFromInventory",
    label: "Remove from inventory",
  },
  MarkAsIssued: { value: "MarkAsIssued", label: 'Mark as "Issued"' },
  UndoMarkAsIssued: {
    value: "UndoMarkAsIssued",
    label: 'Undo "Mark as Issued"',
  },
  MarkAsLostStolen: {
    value: "MarkAsLostStolen",
    label: 'Mark as "Lost or Stolen"',
  },
  UndoMarkAsLostStolen: {
    value: "UndoMarkAsLostStolen",
    label: 'Undo "Mark as Lost or Stolen"',
  },
};

export default maintainInventoryTasks;
