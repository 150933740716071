import { ReloadOutlined, SwapOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Empty, Form, Popconfirm, Row } from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMoveDocumentsOperationResults,
  clearSelectedMoveDocumentsRows,
  listMoveDocuments,
  moveDocumentsToAgency,
  setSelectedMoveDocumentsRows,
} from "../../../store/moveDocuments/moveDocumentsActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import openNotificationOperationResult from "../../shared/openNotificationOperationResult";
import { agenciesListEqual } from "../../shared/sorting";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { tableNames } from "../../shared/table/tableNames";
import { moveDocumentsColumns } from "./moveDocumentsColumns";
import { moveDocumentsSchema } from "./moveDocumentsSchema";

//
const initialValues = {
  FromAgency: null,
  ToAgency: null,
};

const firstLayout = {
  labelCol: {
    span: { span: 10 },
    sm: { span: 8 },
    md: { span: 8 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  },
  wrapperCol: {
    span: { span: 14 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 9 },
    xl: { span: 9 },
    xxl: { span: 8 },
  },
};

const MoveDocumentsForm = ({ agencies }) => {
  // Form
  const {
    reset,
    control,
    setValue,
    trigger,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(moveDocumentsSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [toAgencyNumber, setToAgencyNumber] = useState("");
  const [toAgencies, setToAgencies] = useState([]);
  const timerRef = useRef(null);
  const preserveSelectedKeys = useRef(false);

  //
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector((state) => state.moveDocuments.loading);
  const list = useSelector((state) => state.moveDocuments.data);
  const selectedRows = useSelector((state) => state.moveDocuments.selectedRows);
  const moveLoading = useSelector(
    (state) => state.moveDocuments.movement.loading
  );
  const listOperationResult = useSelector(
    (state) => state.moveDocuments.operationResult
  );
  const moveOperationResult = useSelector(
    (state) => state.moveDocuments.movement.operationResult
  );

  //
  useEffect(() => {
    openNotificationOperationResult(listOperationResult, () =>
      dispatch(clearMoveDocumentsOperationResults())
    );
  }, [listOperationResult, dispatch]);

  useEffect(() => {
    if (moveOperationResult != null) {
      openNotificationOperationResult(moveOperationResult, () =>
        dispatch(clearMoveDocumentsOperationResults())
      );
      if (moveOperationResult.Outcome === "Success") {
        refreshData(getValues());
      }
    }
  }, [moveOperationResult, dispatch]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      resetFieldsToDefault();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    if (list != null) {
      if (preserveSelectedKeys.current === true) {
        preserveSelectedKeys.current = false;
      } else {
        dispatch(clearSelectedMoveDocumentsRows());
      }
    }
  }, [list]);

  //
  const resetFieldsToDefault = () => {
    var initialAgency = getInitialAgency(homeAgency, agencies);
    var toAgenciesList = agencies.filter((a) => a.Id != initialAgency);
    setToAgencies(toAgenciesList);
    setToAgencyNumber((toAgenciesList?.[0]?.Number ?? "").toString());
    var newValues = {
      FromAgency: initialAgency,
      ToAgency: toAgenciesList?.[0]?.Id ?? null,
    };
    reset(newValues);
    trigger();
    refreshData(newValues);
  };

  const refreshData = (values, preserveKeys) => {
    // Clear the previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (preserveKeys === true) {
      preserveSelectedKeys.current = true;
    }

    //
    dispatch(listMoveDocuments(values.FromAgency)); //
  };

  const dispatchWithDelay = () => {
    // Clear the previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Schedule a new dispatch after 750 milliseconds
    timerRef.current = setTimeout(() => {
      trigger().then(
        (e) => e && dispatch(listMoveDocuments(getValues().FromAgency))
      );
    }, 750);
  };

  const onSelectChange = (selectedRowKeys) => {
    dispatch(setSelectedMoveDocumentsRows(selectedRowKeys));
  };

  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.MoveDocuments)
  );

  const tableCurrentDataSource = tableData?.extra?.currentDataSource;

  useEffect(() => {
    //if a filter is applied when multiple rows selected, set selectedRows only for the records visible on ui
    let currentDataSourceSelectedRowKeys = [];
    if (
      !isEmpty(tableCurrentDataSource) &&
      list?.length !== tableCurrentDataSource?.length
    ) {
      const currentDataSourceRowKeys = tableCurrentDataSource.map((r) => r.Id);
      currentDataSourceSelectedRowKeys = selectedRows.filter((r) =>
        currentDataSourceRowKeys.includes(r)
      );
    }
    dispatch(setSelectedMoveDocumentsRows(currentDataSourceSelectedRowKeys));
  }, [tableCurrentDataSource, list]);

  return (
    <div>
      <Form
        size="small"
        autoComplete="off"
        onFinish={() => {}}
        style={{ marginTop: "16px" }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <AgencySelectEx
              layout={firstLayout}
              formLabel="Move From Agency"
              required={true}
              agencies={currentAgencies}
              isLoading={isNull(currentAgencies) || isEmpty(currentAgencies)}
              disabled={loading || moveLoading}
              setValue={setValue}
              control={control}
              name="FromAgency"
              onChange={(key) => {
                var toAgenciesList = agencies.filter((a) => a.Id != key);
                var newValues = {
                  FromAgency: key,
                  ToAgency: toAgenciesList?.[0]?.Id ?? null,
                };
                setToAgencies(toAgenciesList);
                setToAgencyNumber(
                  (toAgenciesList?.[0]?.Number ?? "").toString()
                );
                reset(newValues);
                trigger();
              }}
              afterChange={() => dispatchWithDelay()}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <AgencySelectEx
              layout={firstLayout}
              disabled={loading || moveLoading}
              formLabel="Move To Agency"
              required={true}
              agencies={toAgencies}
              isLoading={isNull(toAgencies) || isEmpty(toAgencies)}
              setValue={setValue}
              control={control}
              name="ToAgency"
              onChange={(key) => {
                var newAgency = toAgencies.find((e) => e.Id == key);
                setToAgencyNumber((newAgency?.Number ?? "").toString());
              }}
            />
          </Col>
        </Row>
      </Form>
      <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
      <Row style={{ paddingTop: "16px" }}>
        <Col>
          <SearchableTable
            tableName={tableNames.MoveDocuments}
            keyColumn="DocumentKey"
            scroll={{ y: 550, x: 1185 }}
            sticky
            dataSource={list}
            columns={moveDocumentsColumns()}
            loading={loading}
            size="small"
            pageSize={1000}
            buttons={[
              <Button
                size={"small"}
                disabled={!isValid || loading || moveLoading}
                onClick={() => {
                  refreshData(getValues());
                }}
              >
                <ReloadOutlined />
                Refresh Table
              </Button>,
              <Popconfirm
                title={
                  <>
                    You are about to move{" "}
                    {(selectedRows?.length ?? 0).toString()} documents to agency
                    #{toAgencyNumber}. Continue?
                  </>
                }
                onConfirm={() => {
                  dispatch(
                    moveDocumentsToAgency({
                      ToAgency: getValues().ToAgency,
                      DocumentKeys: selectedRows,
                    })
                  );
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  size={"small"}
                  disabled={isEmpty(selectedRows)}
                  loading={loading || moveLoading}
                >
                  <SwapOutlined />
                  Move Documents
                </Button>
              </Popconfirm>,
            ]}
            rowSelection={{
              columnWidth: 30,
              selectedRowKeys: selectedRows,
              onChange: onSelectChange,
              fixed: true,
              hideSelectAll: false,
            }}
            rowKey={(r) => r.DocumentKey}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No data"}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default MoveDocumentsForm;
