import * as yup from "yup";

import {
  alphanumericRegex,
  alphanumericMessage,
  numericRegex,
  numericMessage,
  lettersUpperRegex,
  minMessage,
  maxMessage,
  minNumberMessage,
  maxNumberMessage,
  lettersUpperMessage,
  requiredMessage,
  numericRegexOrEmpty,
} from "../../shared/form/formValidatonRules";
import specialViewProductKeys from "./specialViewProductKeys";

export const debtSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  DCID: yup.string().when("IsDownloaded", {
    is: (val) => val == false,
    then: (schema) =>
      schema
        .required(requiredMessage)
        .min(14, minMessage)
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
    otherwise: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
  }),
  CustomerName1: yup.string().required(requiredMessage),
});

export const paymentSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  DCID: yup.string().when("IsDownloaded", {
    is: (val) => val == false,
    then: (schema) =>
      schema
        .required(requiredMessage)
        .min(14, minMessage)
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
    otherwise: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
  }),
  PolicyNumber: yup
    .string()
    .required(requiredMessage)
    .min(6, minMessage)
    .max(6, maxMessage),
  CustomerName1: yup.string().required(requiredMessage),
});

export const documentSchema = yup.object().shape({
  TransactionType: yup
    .number()
    .when(["IsDownloaded", "IsRegistration", "ProductKey"], {
      is: (IsDownloaded, IsRegistration, ProductKey) =>
        !IsDownloaded &&
        !IsRegistration &&
        ProductKey != 41 &&
        ProductKey != specialViewProductKeys.DEBT &&
        ProductKey != specialViewProductKeys.PAYMENT,
      then: (schema) =>
        schema
          .required(requiredMessage)
          .test("is-select", requiredMessage, (value) => value),
      otherwise: (schema) => schema.nullable(),
    }),

  PolicyEffectiveDate: yup.string().when(["IsDownloaded", "IsRegistration"], {
    is: (IsDownloaded, IsRegistration) =>
      IsDownloaded == false && IsRegistration == false,
    then: (schema) => schema.nullable().required(requiredMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  CustomerName1: yup.string().required(requiredMessage),
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  EntryDateTime: yup.string().when("ProductKey", {
    is: (ProductKey) => ProductKey === 41,
    then: (schema) => schema.nullable().required(requiredMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  DCID: yup
    .string()
    .when(["IsDownloaded", "ProductKey"], {
      is: (IsDownloaded, ProductKey) => !IsDownloaded && ProductKey != 41,
      then: (schema) =>
        schema
          .required(requiredMessage)
          .min(14, minMessage)
          .max(14, maxMessage)
          .matches(numericRegexOrEmpty, numericMessage),
      otherwise: (schema) => schema,
    })
    .when(["IsDownloaded", "ProductKey"], {
      is: (IsDownloaded, ProductKey) => IsDownloaded && ProductKey != 41,
      then: (schema) =>
        schema
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .min(14, minMessage)
          .max(14, maxMessage)
          .matches(numericRegexOrEmpty, numericMessage),
      otherwise: (schema) => schema,
    })
    .when("ProductKey", {
      is: (ProductKey) => ProductKey === 41,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema,
    }),
  PolicyNumber: yup.string().when(["IsRegistration", "ProductKey"], {
    is: (IsRegistration, ProductKey) =>
      IsRegistration == false && ProductKey != 41,
    then: (schema) =>
      schema.required(requiredMessage).min(6, minMessage).max(6, maxMessage),
    otherwise: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .min(6, minMessage)
        .max(6, maxMessage),
  }),
  ParentPolicyNumber: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(6, minMessage)
    .max(6, maxMessage),
  PlateNumber: yup.string().when("IsPlatedPolicy", {
    is: (val) => val == true,
    then: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .min(2, minMessage)
        .max(6, maxMessage)
        .matches(alphanumericRegex, alphanumericMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  DocumentNumber: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(7, minMessage)
    .max(8, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  RegNumber: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(8, minMessage)
    .max(8, maxMessage)
    .matches(numericRegex, numericMessage),
  ProvinceCode: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(2, minMessage)
    .max(2, maxMessage)
    .matches(lettersUpperRegex, lettersUpperMessage),
  PostalCode: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(6, minMessage)
    .max(6, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  VehicleYear: yup
    .number()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(1900, minNumberMessage)
    .max(3000, maxNumberMessage),
  CDF: yup
    .number()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(0.4, minNumberMessage)
    .max(10, maxNumberMessage),
  ComprDeductible: yup
    .number()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(0, minNumberMessage)
    .max(100000, maxNumberMessage),
  CollisionDeductible: yup
    .number()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(0, minNumberMessage)
    .max(100000, maxNumberMessage),
  FleetDiscount: yup
    .number()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(0, minNumberMessage)
    .max(63, maxNumberMessage),
  FleetSurcharge: yup
    .number()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(0, minNumberMessage)
    .max(63, maxNumberMessage),
  VIN: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(17, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  CustomerEmail: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .email("Invalid email"),
  DriversLicense: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(8, minMessage)
    .max(8, maxMessage)
    .matches(numericRegex, numericMessage),
  PricipalDriversLicense: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(8, minMessage)
    .max(8, maxMessage)
    .matches(numericRegex, numericMessage),
  LesseeDriversLicense: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(8, minMessage)
    .max(8, maxMessage)
    .matches(numericRegex, numericMessage),
  FleetNumber: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(6, minMessage)
    .max(6, maxMessage)
    .matches(numericRegex, numericMessage),
  VehicleColorCode: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(3, minMessage)
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  VehicleIDCode: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(6, minMessage)
    .max(6, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
});

export const quickEditSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  EntryDateTime: yup.string().when("ProductKey", {
    is: (ProductKey) => ProductKey === 41,
    then: (schema) => schema.nullable().required(requiredMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  DCID: yup
    .string()
    .when(["IsDownloaded", "ProductKey"], {
      is: (IsDownloaded, ProductKey) => !IsDownloaded && ProductKey != 41,
      then: (schema) =>
        schema
          .required(requiredMessage)
          .min(14, minMessage)
          .max(14, maxMessage)
          .matches(numericRegexOrEmpty, numericMessage),
      otherwise: (schema) => schema,
    })
    .when(["IsDownloaded", "ProductKey"], {
      is: (IsDownloaded, ProductKey) => IsDownloaded && ProductKey != 41,
      then: (schema) =>
        schema
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .min(14, minMessage)
          .max(14, maxMessage)
          .matches(numericRegexOrEmpty, numericMessage),
      otherwise: (schema) => schema,
    })
    .when("ProductKey", {
      is: (ProductKey) => ProductKey === 41,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema,
    }),
  PolicyNumber: yup
    .string()
    .required(requiredMessage)
    .min(6, minMessage)
    .max(6, maxMessage),
  PlateNumber: yup.string().when("IsPlatedPolicy", {
    is: (val) => val == true,
    then: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .min(2, minMessage)
        .max(6, maxMessage)
        .matches(alphanumericRegex, alphanumericMessage),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const quickEditRegistrationSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  DCID: yup.string().when("IsDownloaded", {
    is: (val) => val == false,
    then: (schema) =>
      schema
        .required(requiredMessage)
        .min(14, minMessage)
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
    otherwise: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
  }),
});

export const quickEditDebtSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  DCID: yup.string().when("IsDownloaded", {
    is: (val) => val == false,
    then: (schema) =>
      schema
        .required(requiredMessage)
        .min(14, minMessage)
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
    otherwise: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
  }),
});

export const quickEditPaymentSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  DCID: yup.string().when("IsDownloaded", {
    is: (val) => val == false,
    then: (schema) =>
      schema
        .required(requiredMessage)
        .min(14, minMessage)
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
    otherwise: (schema) =>
      schema
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .max(14, maxMessage)
        .matches(numericRegexOrEmpty, numericMessage),
  }),
  PolicyNumber: yup
    .string()
    .required(requiredMessage)
    .min(6, minMessage)
    .max(6, maxMessage),
});

export const inlineEditDocumentSchema = yup.object().shape({
  ProducerCode1: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, "PC1 is too long")
    .min(1, "PC1 is too short")
    .matches(alphanumericRegex, "PC1 contains invalid characters"),
  ProducerCode2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .max(3, "PC2 is too long")
    .min(1, "PC2 is too short")
    .matches(alphanumericRegex, "PC2 contains invalid characters"),
  DCID: yup
    .string()
    .when(["IsDownloaded", "ProductCode"], {
      is: (IsDownloaded, ProductCode) => !IsDownloaded && ProductCode != "APV7",
      then: (schema) =>
        schema
          .required("Timestamp is required")
          .max(14, "Timestamp is too long")
          .min(14, "Timestamp is too short")
          .matches(
            numericRegexOrEmpty,
            "Timestamp should only contain numbers"
          ),
      otherwise: (schema) => schema,
    })
    .when(["IsDownloaded", "ProductCode"], {
      is: (IsDownloaded, ProductCode) => IsDownloaded && ProductCode != "APV7",
      then: (schema) =>
        schema
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .matches(numericRegexOrEmpty, "Timestamp should only contain numbers")
          .max(14, "Timestamp is too long"),
      otherwise: (schema) => schema,
    })
    .when("ProductCode", {
      is: (ProductCode) => ProductCode === "APV7",
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema,
    }),
});
