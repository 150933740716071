import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  clearNewInventoryMovementAction,
  processInventoryMovementAction,
  removeInventoryMovementAction,
  setReceiveInventoryCurrentStepAction,
} from "../../../store/inventory/inventoryActions";
import CustomDatePicker from "../../shared/form/reactHookForm/CustomDatePicker";
import MoveInventoryItems from "../shared/MoveInventoryItems";
import { receiveStockFromICBCSchema } from "../shared/moveInventorySchemas";
import AddPlatesForm from "./AddPlatesForm";
const ReceiveICBCShipment = ({
  newMovement,
  movementTypeDescription,
  operationResult,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      StockCentreConfNo: "",
      TransactionDate: "",
    },
    resolver: yupResolver(receiveStockFromICBCSchema),
  });

  const dispatch = useDispatch();

  const [isDataSet, setIsDataSet] = useState(false);

  const loading = newMovement.loading;
  const movementItems = newMovement.entity && newMovement.entity.Items;
  const entityHeader = newMovement.entity && newMovement.entity.Header;

  const wTransactionDate = watch("TransactionDate");
  const wStockCentreConfNo = watch("StockCentreConfNo");

  useEffect(() => {
    if (entityHeader != null && !isDataSet) {
      setIsDataSet(true);
      reset(entityHeader);
    }
  }, [entityHeader]);

  const processInventoryMovement = (values) => {
    dispatch(
      processInventoryMovementAction({
        InventoryMovementKey: newMovement.entity.Header.InventoryMovementKey,
        TransactionDate: values.TransactionDate,
        StockCentreConfNo: values.StockCentreConfNo,
      })
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form size="small" autoComplete="off" onFinish={() => {}}>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            {/* <Row>{JSON.stringify(errors)}</Row> */}
            <Row>
              <Col span={8}>
                <Descriptions column={1}>
                  <DescriptionsItem label="Movement Type">
                    {movementTypeDescription}
                  </DescriptionsItem>
                  <DescriptionsItem label="Agency" style={{ padding: 0 }}>
                    {newMovement.entity &&
                      newMovement.entity.Header.AgencyNumberAndName}
                  </DescriptionsItem>
                </Descriptions>
              </Col>

              <Col span={12}>
                <CustomDatePicker
                  formItem={{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 14 },
                    label: "Transaction Date",
                    required: true,
                  }}
                  control={control}
                  name="TransactionDate"
                  disabled={!isDataSet}
                />
                
                <Controller
                  control={control}
                  name="StockCentreConfNo"
                  render={({ field }) => (
                    <Form.Item
                      label="Stock Center Confirmation"
                      labelCol={{ span: 14 }}
                      wrapperCol={{ span: 10 }}
                      style={{ marginBottom: 0 }}
                      validateStatus={
                        errors && errors["StockCentreConfNo"] ? "error" : ""
                      }
                      help={errors.StockCentreConfNo?.message}
                    >
                      <Input
                        {...field}
                        name="StockCentreConfNo"
                        style={{ width: 150 }}
                      />
                    </Form.Item>
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <AddPlatesForm
            headerValues={{
              TransactionDate: wTransactionDate,
              StockCentreConfNo: wStockCentreConfNo,
            }}
            operationResult={operationResult}
          />
          <MoveInventoryItems />
          <Row style={{ paddingTop: 16 }}>
            <Col span={8}>
              {newMovement.entity.Header.InventoryMovementKey ? (
                <Popconfirm
                  title="Keep this Draft ICBC Shipment?"
                  onConfirm={() => {
                    dispatch(setReceiveInventoryCurrentStepAction(0));
                  }}
                  onCancel={() => {
                    dispatch(
                      removeInventoryMovementAction(
                        newMovement.entity.Header.InventoryMovementKey
                      )
                    );
                    dispatch(clearNewInventoryMovementAction());
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small" danger>
                    Cancel
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  danger
                  size="small"
                  onClick={() => {
                    dispatch(setReceiveInventoryCurrentStepAction(0));
                  }}
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col offset={8} span={8}>
              <Row justify="end">
                <Col>
                  <Space>
                    <Button
                      size="small"
                      onClick={() => {
                        dispatch(setReceiveInventoryCurrentStepAction(0));
                      }}
                    >
                      <LeftCircleOutlined />
                      Previous
                    </Button>

                    <Popconfirm
                      title="This inventory movement will now be completed. Would you like to proceed?"
                      onConfirm={() => handleSubmit(processInventoryMovement)()}
                      okText="Yes"
                      cancelText="No"
                      disabled={
                        !isValid ||
                        (movementItems && movementItems.length === 0)
                      }
                    >
                      <Button
                        id="processButton"
                        type="primary"
                        size="small"
                        disabled={
                          !isValid ||
                          (movementItems && movementItems.length === 0)
                        }
                        loading={loading}
                      >
                        Complete
                        <RightCircleOutlined />
                      </Button>
                    </Popconfirm>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ReceiveICBCShipment;
