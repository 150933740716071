import { Col, Row, Table, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SearchableTable } from "../shared/table/SearchableTable";
import { columnTypes } from "../shared/table/columnTypes";
import { tableNames } from "../shared/table/tableNames";
import { formatCurrency, formatDate } from "../shared/formatting";

const PaymentTable = () => {
  //
  const [clearAll, setClearAll] = useState(false);

  //
  const paymentSchedule = useSelector(
    (state) => state.loanCalculator.paymentDetails?.data?.PaymentSchedule
  );
  const loading = useSelector(
    (state) => state.loanCalculator.paymentDetails.loading
  );

  //
  const columns = [
    {
      title: "Payment Number",
      dataIndex: "PaymentNumber",
      type: columnTypes.ACTION_WITHOUT_FILTER,
      width: 135,
    },
    {
      title: "Payment Date",
      dataIndex: "PaymentDate",
      type: columnTypes.ACTION_WITHOUT_FILTER,
      render: (value) => formatDate(value),
      width: 175,
    },
    {
      title: "Monthly Payment",
      dataIndex: "MonthlyPaymentRounded",
      type: columnTypes.ACTION_WITHOUT_FILTER,
      width: 150,
      render: (value) => formatCurrency(value),
    },
  ];

  return (
    <div style={{ marginTop: 16 }}>
      <Row>
        <Col flex="500px">
          <Table
            hideClearFiltersButtons={true}
            title={() => (
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  // color: "#78a1cf",
                }}
              >
                Payment Schedule
              </div>
            )}
            pagination={{ hideOnSinglePage: true, pageSize: 120 }}
            dataSource={paymentSchedule}
            columns={columns}
            loading={loading}
            size="small"
            rowKey={(r) => r.PaymentNumber}
            bordered={true}
            scroll={{ y: 300, x: 470 }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PaymentTable;
